import * as Yup from "yup";

export const interestFormSchema = Yup.object().shape({
  title: Yup.mixed().test(
    "English language required",
    "Title is require for english language",
    function () {
      const {
        title: { en },
      } = this.parent;

      return en;
    }
  ),
  status: Yup.string().required("Status is required"),
  fileIcon: Yup.array().required("File Icon is required"),
  categoryIds: Yup.array().required("Category is required"),
});
