import React from "react";
import { useSettings } from "./hooks";
import EditConfig from "./edit-config";
import DataTable from "../../../components/table";
import { columns } from "./constant";
import AddConfig from "./add-config";

function Settings() {
  const { configs, loadingConfig, count, filters } = useSettings();

  const configureTableData = () => {
    try {
      let results = configs.map((config) => {
        let newConfig = Object.assign({}, config);
        newConfig["key"] = config?.key;
        newConfig["description"] = config?.description || "NA";
        newConfig["action"] = <EditConfig config={config} />;

        return newConfig;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DataTable
      title="Settings"
      addBtn={<AddConfig />}
      CreateTableData={configureTableData}
      withFilter={false}
      count={count}
      skip={filters?.skip}
      handleSetFilter={() => {}}
      isLoading={loadingConfig}
    />
  );
}

export default Settings;
