import { TextField } from "@material-ui/core";
import React, { useMemo } from "react";
import DatePicker from "react-multi-date-picker";

import PropTypes from "prop-types";

const MultiDatePicker = ({
  dates,
  onChange,
  placeholder,
  label,
  mapDays,
  position,
  zIndex,
  ...rest
}) => {
  const dateValue = useMemo(() => {
    let value = "";
    if (dates.length) {
      dates.forEach((dt, i) => {
        value += `${i > 0 ? "," : ""}${dt
          .format("DD/MM/YYYY")
          .toLocaleString()}`;
      });
    }

    return value;
  }, [dates]);
  return (
    <DatePicker
      {...rest}
      calendarPosition={position}
      zIndex={zIndex}
      multiple
      placeholder={placeholder}
      onChange={onChange}
      render={(value, openCalendar) => {
        return (
          <TextField
            className="material-form__field"
            placeholder={placeholder}
            label={label}
            value={dateValue}
            onClick={openCalendar}
          />
        );
      }}
      mapDays={mapDays}
    />
  );
};

MultiDatePicker.propTypes = {
  dates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  mapDays: PropTypes.func.isRequired,
  position: PropTypes.string,
  zIndex: PropTypes.number,
};

MultiDatePicker.defaultProps = {
  placeholder: "Date",
  label: "Date",
  position: "bottom-center",
  zIndex: 999,
  mapDays: () => {},
};

export default MultiDatePicker;
