import { useEffect, useMemo, useState } from "react";
import { getContentModerationData } from "./api";
import { types } from "./constant";

export const useConfidenceScore = (props) => {
  const { trackableType, data, className } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLaoding] = useState(false);
  const [isOpenAcc, setIsOpenAcc] = useState("");
  const [activeTab, setActiveTab] = useState("");

  const params = useMemo(() => {
    return {
      trackable_type: trackableType,
      trackable_id: data?.id,
    };
  }, [trackableType, data]);
  const [confidenceScore, setConfidenceScore] = useState([]);

  const { hasPhoto, hasVideo, hasText } = useMemo(() => {
    const hasText = confidenceScore.find((score) => score?.type === types.TEXT);
    const hasPhoto = confidenceScore.find(
      (score) => score?.type === types.PHOTO
    );
    const hasVideo = confidenceScore.find(
      (score) => score?.type === types.VIDEO
    );

    setActiveTab((prev) =>
      prev
        ? prev
        : (hasText && types.TEXT) ||
          (hasPhoto && types.PHOTO) ||
          (hasVideo && types.VIDEO)
    );

    return { hasPhoto, hasVideo, hasText };
  }, [confidenceScore]);

  const onChangeAcc = (val) => {
    setIsOpenAcc((prev) => (prev === val ? "" : val));
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const toggleTab = (e) => {
    setActiveTab(e.target.id);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLaoding(true);
        const data = await getContentModerationData(params);
        if (data?.data?.is_success) {
          setConfidenceScore(data?.data?.content_moderation_results);
          setIsLaoding(false);
        }
      } catch (error) {
        setIsLaoding(false);
      } finally {
        setIsLaoding(false);
      }
    };

    if (isOpenModal) {
      getData();
    }

    return () => {
      setIsOpenAcc("");
    };
  }, [params, isOpenModal]);

  return {
    isOpenModal,
    toggleModal,
    activeTab,
    toggleTab,
    isOpenAcc,
    onChangeAcc,
    trackableType,
    data,
    className,
    confidenceScore,
    isLoading,
    hasPhoto,
    hasVideo,
    hasText,
  };
};
