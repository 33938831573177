import React from "react";
import DataTable from "../../../../components/table";
import { useSections } from "./hooks";
import { columns, statusOptions, sectionTypeOptions } from "./constant";
import SelectFilter from "../../../../components/filters/select-filter";
import DeleteSection from "./delete-section";
import AddSection from "./add-section";
import EditSection from "./edit-section";

const SectionList = () => {
  const { sections, loadingSections, setFilter, filters, sectionCount } =
    useSections();

  const configureTableData = () => {
    try {
      let results = sections.map((section) => {
        let newSection = Object.assign({}, section);
        sectionTypeOptions.filter((type) =>
          newSection.type === type.value
            ? (newSection.type = type.displayText)
            : ""
        );
        newSection["action"] = (
          <div className="section__container">
            <EditSection section={section} />
            <DeleteSection section={section?.id} />
          </div>
        );
        return newSection;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const sectionFilter = () => (
    <>
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        filterKey="status"
        options={statusOptions}
        defaultOption="All Statuses"
        filterTitle="Status"
      />
    </>
  );
  return (
    <DataTable
      title="Sections"
      addBtn={<AddSection />}
      CreateTableData={configureTableData}
      withFilter={false}
      filter={sectionFilter}
      handleSetFilter={setFilter}
      count={sectionCount}
      skip={filters?.skip}
      isLoading={loadingSections}
    />
  );
};

export default SectionList;
