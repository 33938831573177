import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getStoriesData,
  resetStoryFilter,
  setStoryFilter,
  updateStoryStatus,
} from "./actions";
import { getCount, getStories, getStoriesFilters } from "./selectors";

export const useStories = () => {
  const [loadingStories, setLoadingStories] = useState(false);
  const stories = useSelector(getStories);
  const filters = useSelector(getStoriesFilters);
  const count = useSelector(getCount);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setLoadingStories(true);
        const stories = await dispatch(getStoriesData(filters));
        if (stories) {
          setLoadingStories(false);
        }
      } catch (error) {
        setLoadingStories(false);
      } finally {
        setLoadingStories(false);
      }
    })();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setStoryFilter({ key, value }));
  };

  const updateStory = async (id, payload) => {
    const { is_success } = await dispatch(updateStoryStatus(id, payload));
    if (is_success) {
      await dispatch(getStoriesData(filters));
    }
  };

  const publishStory = async (id) => {
    const { is_success } = await dispatch(
      updateStoryStatus(id, { status: "Published" })
    );
    if (is_success) {
      await dispatch(getStoriesData(filters));
    }
  };
  const resetFilter = () => {
    dispatch(resetStoryFilter());
  };

  const handleReportClick = (e) => {
    history.push(`/stories/${e.target.id}/reports`);
  };

  return {
    stories,
    filters,
    count,
    setFilter,
    resetFilter,
    loadingStories,
    updateStory,
    publishStory,
    handleReportClick,
  };
};
