import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Sections from '../../../CMS/blog/sections';
import AddSection from '../../../CMS/blog/sections/add-section';
import EditSection from '../../../CMS/blog/sections/edit-section';

export default () => (
  <Switch>
    <Route path="/sections" component={Sections} />
    <Route path="/add-section" component={AddSection} />
    <Route path="/edit-section/:section_id" component={EditSection} />
  </Switch>
);
