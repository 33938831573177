import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../../components/modal";
import DeleteConfirmation from "../../../../components/delete-confirmation";
import { useDeleteSubcategory } from "./hooks";
import { useUser } from "../../../../common-hooks/user";

const DeleteSubcategory = ({ id, category_id }) => {
  const {
    deleteSubcategoryLoading,
    handleDeleteSubcategory,
    isOpenModal,
    toggleModal,
  } = useDeleteSubcategory(id, category_id);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <>
      <Tooltip title="Delete" placement="bottom">
        <i
          className={`fa fa-trash icon-btn-action text-danger `}
          onClick={toggleModal}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeleteSubcategory}
              isLoading={deleteSubcategoryLoading}
            />
          }
          title="Delete Subcategory"
        />
      ) : null}
    </>
  );
};

export default DeleteSubcategory;
