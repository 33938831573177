import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../utils/upload-file/actions";
import { getPartnersList, addPartner } from "../actions";
import { getPartnerFilter } from "../selectors";

export const useAddPartner = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [addPartnerLoading, setAddPartnerLoading] = useState(false);
  const filter = useSelector(getPartnerFilter);
  const files = useSelector(({ fileReducer }) => fileReducer.files);

  const handleAddPartner = async (values) => {
    const payload = {
      name: values.brand_name,
      strip_color_code: values.color_code,
      status: values.status,
      files,
    };

    setAddPartnerLoading(true);
    const is_success = await dispatch(addPartner(payload));
    setAddPartnerLoading(false);
    if (is_success) {
      dispatch(getPartnersList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
  };

  return {
    addPartnerLoading,
    handleAddPartner,
    isOpenModal,
    toggleModal,
  };
};
