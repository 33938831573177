import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { useEventCard } from "./hooks";
import ProfileImage from "../../../../../components/ProfileImage";
import { colors } from "../../../../../constants/colors";
import Text from "../../../../../components/text";
import moment from "moment";
// import LazyImage from "../../../../../components/lazy-image";

const EventCard = (props) => {
  const {
    handleCardClick,
    title,
    host,
    // slug,
    starts_at,
    // id,
    url,
  } = useEventCard(props);
  if (!props?.data) return null;
  return (
    <div onClick={handleCardClick} className={styles.event_card_container}>
      <img src={url} width={200} height={104} alt="session" />

      <div className={styles.event_info_container}>
        <Text
          size="15px"
          fontWeight={500}
          color={colors.voilet}
          className={styles.time}
          lineheight="180x"
        >
          {moment(starts_at).format("DD-MM-YYYY @ hh:mm A")}
        </Text>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.host_info}>
          {
            <ProfileImage
              src={
                host?.files?.profile_picture?.url
                  ? host.files?.profile_picture?.url
                  : ""
              }
              width={30}
              height={30}
              className={styles.profile_image}
              title={host?.name}
              initialsClassName={styles.initialsClassName}
            />
          }

          <p> {host?.name}</p>
        </div>
      </div>
    </div>
  );
};

EventCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EventCard;
