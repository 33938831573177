import {
  SET_POLICY_LIST,
  SET_POLICY_FILTER,
  RESET_POLICY_FILTER,
} from "./actions";

const initialPolicies = {
  policies: [],
  count: 0,
  filter: {
    text: "",
    limit: 10,
    skip: 0,
  },
};

const policyReducer = (state = initialPolicies, action) => {
  switch (action.type) {
    case SET_POLICY_LIST:
      return {
        ...state,
        policies: action.payload.policies,
        count: action.payload.count || 0,
      };
    case SET_POLICY_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case RESET_POLICY_FILTER:
      return { ...state, filter: initialPolicies.filter || [] };
    default:
      return state;
  }
};

export default policyReducer;
