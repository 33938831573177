import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLanguage } from "../../../utils/auth";
import { clearFiles } from "../../../utils/upload-file/actions";
import { addUser, getUserList } from "../actions";
import { userRoles } from "../../../constants";

export const useAddUser = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(({ userReducer }) => userReducer.filter);
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const [addUserLoading, setAddUserLoading] = useState(false);
  const { CO_ORDINATOR } = userRoles;

  const handleAddUser = async (values) => {
    setAddUserLoading(true);
    let payload = {
      user: {
        country_code: "+91",
        mobile_number: values.mobile_number,
        name: values.name,
        role: values.role,
        is_active: values.is_active,
      },
      profile: {
        title: values.title,
        about_me: values.about_me,
        designation: values.designation,
        specialities: values.specialities,
        highlights: values.highlights,
        gender: values.gender,
        address: values.address,
        pincode: values.pincode,
        emergency_mobile_number: values.emergency_mobile_number,
        is_popular: values.role === CO_ORDINATOR ? values.is_popular : false,
        tag: values.role === CO_ORDINATOR ? values.tag : "",
        short_about_me: values.short_about_me,
        age_group: values.age_group,
      },
      profile_pic:
        files.find((file) => file?.file_context === "Profile Picture") || {},
      profile_banner:
        files.find((file) => file?.file_context === "Profile Banner") || {},
      profile_video:
        files.find((file) => file?.file_context === "Profile Video") || {},
    };

    if (values.email) {
      payload = {
        ...payload,
        ...payload.profile,
        user: {
          ...payload.user,
          email: values.email,
        },
      };
    }

    if (values.date_of_birth) {
      payload = {
        ...payload,
        ...payload.user,
        profile: {
          ...payload.profile,
          date_of_birth: values.date_of_birth,
        },
      };
    }

    const is_success = await dispatch(addUser(payload));
    setAddUserLoading(false);
    if (is_success) {
      dispatch(getUserList(filters));
      toggleModal();
    }
  };

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    addUserLoading,
    handleAddUser,
    isOpenModal,
    toggleModal,
  };
};
