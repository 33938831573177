import { useState } from "react";

export const useAccordion = () => {
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse((prevState) => !prevState);
  };

  return { toggle, collapse };
};
