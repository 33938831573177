import React from "react";

import NoticeForm from "../form/notice-form";
import { useEditNotice } from "./hooks";
import Modals from "../../../components/modal";
import Tooltip from "@material-ui/core/Tooltip";
import { useUser } from "../../../common-hooks/user";

const EditNotice = ({ notice }) => {
  const {
    editNoticeLoading,
    handleEditNotice,
    isOpenModal,
    toggleModal,
    handleNotice,
    events,
    selectedNotice,
    isEdit,
  } = useEditNotice(notice);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Tooltip title="Edit" placement="bottom">
        <i className="fa fa-edit icon-btn-action" onClick={handleNotice}></i>
      </Tooltip>

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <NoticeForm
              handlenoticeForm={handleEditNotice}
              btn="Save"
              isLoading={editNoticeLoading}
              eventList={events}
              fieldValues={selectedNotice}
              isEdit={isEdit}
            />
          }
          title="Edit Notice"
        />
      ) : null}
    </div>
  );
};

export default EditNotice;
