import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePlanStatusAction, getPlanList } from "../actions";
import { getPlansFilter } from "../selectors";
import { planStatusAction } from "../constant";

export const useChangePlanStatus = ({ id, status }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [changePlanStatusLoading, setChangePlanStatusLoading] = useState(false);
  const filter = useSelector(getPlansFilter);
  const [planStatus, setPlanStatus] = useState(status);
  const { Published, Unpublished } = planStatusAction;

  const handleChangePlanStatus = async () => {
    setChangePlanStatusLoading(true);
    const payload = {
      status: planStatus === Published ? Unpublished : Published,
    };
    const is_success = await dispatch(changePlanStatusAction(id, payload));
    setChangePlanStatusLoading(false);
    if (is_success) {
      dispatch(getPlanList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    changePlanStatusLoading,
    handleChangePlanStatus,
    planStatusAction,
    isOpenModal,
    toggleModal,
    planStatus,
    setPlanStatus,
  };
};
