import React from "react";
import { useAddReason } from "./hooks";
import Modals from "../../../../components/modal";
import { Button } from "reactstrap";
import ReasonForm from "../form/reason-form";

const AddUser = (props) => {
  const { addReasonLoading, handleAddReason, isOpenModal, toggleModal } =
    useAddReason(props);

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          toggleModal(!isOpenModal);
        }}
      >
        <i className="fa fa-plus"></i> Add Reason
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <ReasonForm
              handleReasonForm={handleAddReason}
              btn="Add"
              isLoading={addReasonLoading}
            />
          }
          title="Add Reason"
        />
      ) : null}
    </div>
  );
};

export default AddUser;
