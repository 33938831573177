import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";

const TimePickers = ({
  time,
  onChange,
  name,
  format,
  placeholder,
  customInput,
  ...rest
}) => {
  return (
    <div className="date-picker">
      <ReactDatePicker
        {...rest}
        name={name}
        className="form__form-group-datepicker"
        selected={time}
        onChange={onChange}
        dateFormat={format}
        placeholderText={placeholder}
        customInput={customInput}
        timeIntervals={15}
        showTimeSelect
        showTimeSelectOnly
      />
    </div>
  );
};

TimePickers.propTypes = {
  time: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

TimePickers.defaultProps = {
  name: "time",
  format: "hh:mm aa",
  placeholder: "Select Time",
};

export default TimePickers;
