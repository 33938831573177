import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVideoFilter,
  getVideos,
  getVideoCount,
  getCategoriesSelector,
  getUserFilter,
  getUsers,
} from "./selectors";
import {
  setVideoFilter,
  clearVideoFilter,
  getVideoList,
  getUserList,
} from "./actions";
import { clearLanguage } from "../../../../utils/auth";
import _ from "lodash";

export const useVideos = () => {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const [loadingVideos, setLoadingVideos] = useState(false);

  const filters = useSelector(getVideoFilter);
  const videos = useSelector(getVideos);
  const videoCount = useSelector(getVideoCount);
  const categories = useSelector(getCategoriesSelector);
  const userFilters = useSelector(getUserFilter);

  useEffect(() => {
    clearLanguage();
    setLoadingVideos(true);

    (async () => {
      await dispatch(getVideoList(filters));
    })();

    setLoadingVideos(false);
  }, [dispatch, filters]);

  const setFilter = (key, value) => {
    dispatch(setVideoFilter({ key, value }));
  };

  const resetFilter = () => {
    dispatch(clearVideoFilter());
  };

  const setUserFilters = useCallback(
    _.debounce((value) => {
      dispatch(getUserList({ ...userFilters, text: value }));
    }, 600),
    []
  );

  return {
    setFilter,
    filters,
    resetFilter,
    videos,
    videoCount,
    users,
    categories,
    loadingVideos,
    setUserFilters,
  };
};
