import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnlockedOfferFilters,
  getUnlockedOffers,
  getUnlockedOffersCount,
} from "./selectors";

import {
  getUnlockedOfferList,
  setUnlockedOfferFilter,
  resetUnlockedOfferFilter,
} from "./actions";

export const useUnlockedOffers = (props) => {
  const [loadingUnlockedOffers, setLoadingUnlockedOffers] = useState(false);
  const filters = useSelector(getUnlockedOfferFilters);
  const unlockedOffers = useSelector(getUnlockedOffers);
  const count = useSelector(getUnlockedOffersCount);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setLoadingUnlockedOffers(true);
      await dispatch(getUnlockedOfferList(filters));
      setLoadingUnlockedOffers(false);
    })();
  }, [dispatch, filters]);

  const setFilter = (key, value) => {
    dispatch(setUnlockedOfferFilter({ key, value }));
  };

  const resetFilter = useCallback(() => {
    dispatch(resetUnlockedOfferFilter());
  }, [dispatch]);

  return {
    unlockedOffers,
    loadingUnlockedOffers,
    setFilter,
    filters,
    count,
    resetFilter,
  };
};
