import { useState, useEffect } from "react";
import {
  initialLanguageArrayValue,
  initialLanguageFieldValue,
} from "../../../constants";
import { fieldsName } from "../constant";

const formField = {
  category: "",
  subcategory: "",
  title: initialLanguageFieldValue,
  description: initialLanguageFieldValue,
  short_description: initialLanguageFieldValue,
  status: "",
  is_popular: false,
  requirements: initialLanguageArrayValue,
  url: "",
  faq_category: [],
  language: "en",
  slug: "",
  attendees_limit: "",
  current_attendees_limit: "",
  exceeds_percentage: "",
  increment_percentage: "",
  broadcast_mins: "",
  after_popup_broadcast_mins: "",
  max_join_broadcast_attempts: "",
};

const { requirements } = fieldsName;

export const useEventForm = ({
  fieldValues,
  handleEventForm,
  btn,
  categories,
  subcategories,
  isLoading,
  faqCategories,
  handleLangugeData,
}) => {
  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [currentPopularBannerWeb, setCurrentPopularBannerWeb] = useState([]);
  const [currentPopularBannerApp, setCurrentPopularBannerApp] = useState([]);
  const [currentVideoBanner, setcurrentVideoBanner] = useState([]);

  useEffect(() => {
    if (btn === "Add") {
      setFormvalues({
        category: "",
        subcategory: "",
        title: initialLanguageFieldValue,
        description: initialLanguageFieldValue,
        short_description: initialLanguageFieldValue,
        status: "",
        is_popular: false,
        requirements: initialLanguageArrayValue,
        url: "",
        faq_category: [],
        slug: "",
        attendees_limit: "",
        current_attendees_limit: "",
        exceeds_percentage: 90,
        increment_percentage: 50,
      });
    }
  }, [btn]);

  const handleFormUpdate = (key, value) => {
    if (key === "category") {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        subcategory: "",
        category_id: "",
      }));
    }
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));
  };

  //to update only Requirements object field values
  const handleFormUpdateRequirements = (key, value, index, rest) => {
    let newRequirements = {
      ...rest.values.requirements,
    };

    newRequirements[rest.values.language][index][key] = value || "";
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      requirements: newRequirements,
    }));
  };

  // handle click to remove requirement
  const handleRemoveClickRequirements = (rest, setFieldValue, index) => {
    const list = { ...rest.values.requirements };
    list[rest.values.language].splice(index, 1);
    setFieldValue(requirements, list);
    handleFormUpdate(requirements, list);
  };

  // handle click to Add new requirement
  const handleAddClickRequirements = (rest, setFieldValue, language) => {
    const list = {
      ...rest?.values?.requirements,
      [language]: [
        ...rest?.values?.requirements[language],
        { title: "", description: rest?.values?.is_course ? [] : "" },
      ],
    };

    setFieldValue(requirements, list);
    handleFormUpdate(requirements, list);
  };

  const handleIsCourseChange = (rest, setFieldValue, value) => {
    const list = { ...rest?.values?.requirements };

    list[rest.values.language].forEach((ele) => {
      ele.description = value ? [] : "";
    });
    setFieldValue(requirements, list);
    handleFormUpdate(requirements, list);
  };

  const handleTextfieldChange = (event, data, setFieldValue, name, rest, i) => {
    if (["Enter", "Tab"].includes(event.key)) {
      event.preventDefault();
      var value = event.target.value.trim();

      if (data.includes(value)) {
        event.target.value = "";
        return;
      }

      if (value) {
        setFieldValue(name, [...data, value]);
        const list = {
          ...rest.values.requirements,
        };

        list[rest.values.language][i]["description"] = [
          ...list[rest.values.language][i]["description"],
          value,
        ];
        setFieldValue(requirements, list);
        setFormvalues((prev) => ({
          ...prev,
          requirements: list,
        }));

        event.target.value = "";
      }
    }
  };

  const handleRemoveDescription = (
    rest,
    data,
    setFieldValue,
    value,
    name,
    i
  ) => {
    let list = { ...rest.values.requirements };
    let filteredData = data.filter((val) => val !== value);
    list[rest.values.language][i]["description"] = filteredData;
    handleFormUpdate(requirements, list);
    // setFieldValue(name, value)
  };

  const handleLanguge = (key, language, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: {
        ...prevFieldValue[key],
        [language]: value,
      },
    }));
  };

  const handleCourseDescriptionField = (rest, value, i, j, setFieldValue) => {
    let list = { ...rest.values.requirements };
    list[rest.values.language][i]["description"][j] = value;
    setFieldValue(requirements, list);
    handleFormUpdate(requirements, list);
  };

  const handleSubcategoryText = (rest) => {
    return subcategories.filter(
      (subcategory) => subcategory?.category_id === rest?.values?.category?.id
    ).length === 0
      ? `No subcategories are added for category "${rest?.values?.category?.title}". Please add subcategory for corresponding category to proceed ahead.`
      : "No Data";
  };

  return {
    formValues,
    handleFormUpdate,
    handleFormUpdateRequirements,
    handleRemoveClickRequirements,
    handleAddClickRequirements,
    handleIsCourseChange,
    handleEventForm,
    btn,
    categories,
    subcategories,
    isLoading,
    faqCategories,
    handleTextfieldChange,
    handleRemoveDescription,
    handleLanguge,
    handleCourseDescriptionField,
    handleLangugeData,
    setFormvalues,
    currentBanner,
    setCurrentBanner,
    currentPopularBannerWeb,
    setCurrentPopularBannerWeb,
    currentVideoBanner,
    setcurrentVideoBanner,
    currentPopularBannerApp,
    setCurrentPopularBannerApp,
    handleSubcategoryText,
  };
};
