import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { useUser } from "../../common-hooks/user";
import {
  getCategoriesList,
  getScheduleEventList,
  setScheduleEventFilter,
  getUserList,
  resetScheduleEventFilter,
  startEvent,
  downloadScheduleEvents,
  getEventList,
  clearScheduleEventFilter,
  setScheduleEventList,
} from "./actions";
import {
  getScheduleEventsSelector,
  getScheduleEventsCount,
  getScheduleEventsFilter,
  getCategoriesSelector,
  getUsersSelector,
  getCategoryFilter,
  getEventsFilterSelector,
} from "./selectors";
import { useHistory } from "react-router-dom";
import { clearLanguage } from "../../utils/auth";
import { socket } from "../../api/socket";
import _ from "lodash";

export const useScheduleEvent = () => {
  const dispatch = useDispatch();
  const scheduleEvents = useSelector(getScheduleEventsSelector);
  let filters = useSelector(getScheduleEventsFilter);
  const categories = useSelector(getCategoriesSelector);
  const users = useSelector(getUsersSelector);
  const scheduleEventCount = useSelector(getScheduleEventsCount);
  const categoryFilter = useSelector(getCategoryFilter);
  const [loadingScheduleEvent, setLoadingScheduleEvent] = useState(false);
  const [isStartingEvent, setIsStartingEvent] = useState(false);
  const eventsFilter = useSelector(getEventsFilterSelector);

  const { isAdmin, id, isCoordinator, isModerator, isCSSpecialist } = useUser();
  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    clearLanguage();
    const fetchScheduleEvents = async () => {
      setLoadingScheduleEvent(true);
      await dispatch(getScheduleEventList(filters));
      setLoadingScheduleEvent(false);
    };
    fetchScheduleEvents();
  }, [filters, isCoordinator, id, dispatch]);

  const handleSocketEvent = useCallback(
    async (param) => {
      if (param) {
        const { id } = param;
        let newScheduleEvents = scheduleEvents.map((event) => {
          if (event.id === id) {
            Object.keys(param).map((key) => (event[key] = param[key]));
          }
          return event;
        });
        await dispatch(
          setScheduleEventList({
            event_schedules: newScheduleEvents,
            count: scheduleEventCount,
          })
        );
      }
    },
    // eslint-disable-next-line
    [scheduleEvents]
  );

  useEffect(() => {
    socket.on("eventSchedule", handleSocketEvent);

    return () => {
      socket.off("eventSchedule", handleSocketEvent);
    };
  }, [handleSocketEvent]);

  const getAutocompleteValues = () => {
    if (isAdmin) {
      dispatch(getUserList());
    }
    dispatch(getCategoriesList(categoryFilter));
  };

  const setFilter = (key, value) => {
    dispatch(setScheduleEventFilter({ key, value }));
  };

  const joinMeeting = async (id) => {
    setIsStartingEvent(true);
    const zoom_meeting = await dispatch(startEvent(id));
    if (zoom_meeting) {
      let win = window.open(zoom_meeting.start_url, "_blank");
      win.focus();
      setIsStartingEvent(false);
    }
  };

  const downloadScheduleEventsReport = async () => {
    const exportData = await dispatch(downloadScheduleEvents(filters));
    if (exportData) {
      var blob = new Blob([exportData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `ScheduleEvents.xlsx`);
    }
  };

  const resetFilter = useCallback(() => {
    dispatch(resetScheduleEventFilter());
  }, [dispatch]);

  const clearFilter = () => {
    dispatch(clearScheduleEventFilter());
  };

  useEffect(() => {
    resetFilter();
  }, [pathname, resetFilter]);

  const handleEventsFilter = useCallback(
    _.debounce((value) => {
      dispatch(getEventList({ ...eventsFilter, text: value }));
    }, 600),
    []
  );

  return {
    scheduleEvents,
    loadingScheduleEvent,
    setFilter,
    filters,
    categories,
    users,
    getAutocompleteValues,
    resetFilter,
    joinMeeting,
    scheduleEventCount,
    isAdmin,
    isModerator,
    isCSSpecialist,
    id,
    isCoordinator,
    isStartingEvent,
    downloadScheduleEventsReport,
    clearFilter,
    handleEventsFilter,
  };
};
