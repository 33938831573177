import * as Yup from "yup";
import { validationRegex } from "../../../utils/validation";
import { checkFields } from "../../../utils/validation";

const requiredFields = [
  "offer_text",
  "how_to_use",
  "description",
  "plan_offer_text",
  "my_offer_text",
];

export const offerFormSchema = Yup.object()
.shape({
  offer_text: Yup.mixed().test(
    "Offer Text required",
    "Enter valid offer text for all languages",
    function () {
      const {
        offer_text: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "offer_text");
    }
  ),
  plan_offer_text: Yup.mixed().test(
    "Plan offer text required",
    "Enter valid plan offer text for all languages",
    function () {
      const {
        plan_offer_text: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "plan_offer_text");
    }
  ),
  my_offer_text: Yup.mixed().test(
    "My offer text required",
    "Enter valid my offer text for all languages",
    function () {
      const {
        plan_offer_text: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "my_offer_text");
    }
  ),
  description: Yup.mixed().test(
    "Description required",
    "Enter valid description for all languages",
    function () {
      const {
        description: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "description");
    }
  ),
  validity: Yup.number().required("Validity is required"),
  dynamic_coupon_code: Yup.boolean().required(
    "Dynamic Coupon code is required"
  ),
  no_of_coupon_codes: Yup.number().when("dynamic_coupon_code", {
    is: true,
    then: Yup.number().required("Number of coupon codes are required"),
  }),

  how_to_use: Yup.mixed().test(
    "How to use required",
    "Enter valid how to use data for all languages",
    function () {
      const {
        how_to_use: { en },
      } = this.parent;
      if (!en.length) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "how_to_use");
    }
  ),
  terms_and_conditions: Yup.array().required(
    "Terms and conditions is required"
  ),
  status: Yup.string().required("Status is required"),
  amount: Yup.number().required("Amount is required"),
  partner_id: Yup.object().required("Partner is required").nullable(),
  cta_name: Yup.object(),
  cta_url: Yup.mixed().test({
    test: (value) => {
      return value ? validationRegex.partner_url.test(value) : true;
    },
    message: "Invalid URL",
  }),
});
