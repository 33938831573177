import React from "react";

import { Tooltip } from "@material-ui/core";
export const InputProps = (title) => {
  return {
    endAdornment: (
      <Tooltip
        classes={{
          tooltip: "form__info",
        }}
        title={title}
        placement="bottom"
      >
        <i className="fa fa-info-circle icon-file-info mt-1"></i>
      </Tooltip>
    ),
  };
};
