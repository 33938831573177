import {
  SET_CATEGORIES_LIST,
  SET_VIDEO_FILTER,
  CLEAR_VIDEO_FILTER,
  SET_VIDEO_LIST,
  SET_USER_FILTER,
  SET_USER_LIST,
  CLEAR_USER_FILTER,
} from "./actions";

import * as moment from "moment";

const initialState = {
  categories: [],
  videos: [],
  users: [],
  count: 0,

  categoryFilter: {
    type: "Video",
    status: "Published",
  },
  filter: {
    id: null,
    title: "",
    status: "Published",
    limit: 10,
    skip: 0,
    published_at: {
      from: "",
      to: "",
    },
    utc_offset: moment().utcOffset(),
  },
  user_filter: {
    roles: ["Admin", "Moderator", "Coordinator", "Member"],
    limit: 10,
    skip: 0,
    text: "",
  },
  common_filter: { text: "", limit: 0, skip: 0 },
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES_LIST:
      return { ...state, categories: action.payload || [] };
    case SET_VIDEO_LIST:
      return {
        ...state,
        videos: action.payload.videos,
        count: action.payload.count,
      };
    case SET_VIDEO_FILTER: {
      const { key, value } = action.payload;
      if (key === "id") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value ? parseInt(value) : null,
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case CLEAR_VIDEO_FILTER:
      return { ...state, filter: initialState.filter };

    case SET_USER_LIST:
      return { ...state, users: action.payload.users };

    case SET_USER_FILTER: {
      const { key, value } = action.payload;

      return { ...state, user_filter: { ...state.user_filter, [key]: value } };
    }

    case CLEAR_USER_FILTER:
      return { ...state, user_filter: initialState.user_filter };

    default:
      return state;
  }
};

export default videoReducer;
