export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Name",
    accessor: "commenter.name",
    width: 30,
  },
  {
    Header: "Type",
    accessor: "trackable_type",
    width: 30,
  },
  {
    Header: "Post Caption",
    accessor: "caption",
    disableGlobalFilter: true,
    width: 350,
  },
  {
    Header: "Reported Comment",
    accessor: "reported_comment",
    width: 350,
  },
  {
    Header: "Reported Reply",
    accessor: "reported_reply",
    width: 350,
  },
  {
    Header: "Reported Counts",
    accessor: "reports",
    width: 150,
  },
  {
    Header: "Created At",
    accessor: "created_at",
    width: 200,
  },
  {
    Header: "Updated At",
    accessor: "updated_at",
    width: 200,
  },
  {
    Header: "Created By",
    accessor: "created_by",
    width: 80,
  },
  {
    Header: "Updated By",
    accessor: "updated_by",
    width: 80,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 50,
  },
  {
    Header: "Reported Reason",
    accessor: "reason",
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];

export const userRoles = [
  {
    id: 1,
    value: "Admin",
    displayText: "Admin",
  },
  {
    id: 2,
    value: "Member",
    displayText: "Member",
  },
  {
    id: 3,
    value: "Coordinator",
    displayText: "Coordinator",
  },
  {
    id: 4,
    value: "Moderator",
    displayText: "Moderator",
  },
  {
    id: 5,
    value: "Customer Support Specialist",
    displayText: "Customer Support Specialist",
  },
];

export const commentAndRepliesFilterStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
  {
    id: 3,
    value: "In Review",
    displayText: "In Review",
  },
];

export const reportsFilterOption = [
  {
    id: 1,
    value: "All",
    displayText: "All Posts",
  },
  {
    id: 2,
    value: "Yes",
    displayText: "Reported Posts",
  },
];

export const egTaggedFilter = [
  {
    id: 1,
    value: "All",
    displayText: "All comments or replies",
  },
  {
    id: 2,
    value: "Yes",
    displayText: "EG Tagged comments or replies",
  },
];

export const statuses = {
  PUBLISHED: "Published",
  UNPUBLISHED: "Unpublished",
  PENDING: "Pending",
  IN_REVIEW:'In Review'
};
