import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editPage, getPageData, getPageList, getSectionList } from "../actions";
import { getCommonFilter, getPageFilter } from "../selectors";

export const useEditPage = ({ id }) => {
  const dispatch = useDispatch();
  const [editPageLoading, setEditPageLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState();
  const [selectedPageSections, setSelectedPageSections] = useState();
  const [sectionData, setSections] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const commom_filters = useSelector(getCommonFilter);
  const filters = useSelector(getPageFilter);

  const handleEditClick = async () => {
    setEditPageLoading(true);
    const { page, sections } = await dispatch(getPageData(id));
    if (page) {
      setSelectedPage(page)
    }
    if (sections) {
      setSelectedPageSections(sections)
    }
    fetchSections()
    setEditPageLoading(false);
  }

  const fetchSections = async () => {
    const { sections } = await dispatch(getSectionList(commom_filters));
    if (sections) {
      setSections(sections)
      toggleModal();
    }
  }

  const handleEditPage = async (values) => {
    try {
      setEditPageLoading(true);
      let finalSectionList = values.sectionList.map(function (item) {
        const obj = Object.assign({}, { 'id': item.id.id, 'limit': parseInt(item.limit), 'position': item.position });
        return obj;
      });
      const payload = {
        page: {
          name: values.name,
          description: values.description,
        },
        sections: finalSectionList,
      };
      setEditPageLoading(false);
      const is_success = await dispatch(editPage(payload, id));
      if (is_success) {
        dispatch(getPageList(filters));
        toggleModal();
      }
      setEditPageLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    editPageLoading,
    handleEditPage,
    selectedPage,
    selectedPageSections,
    sectionData,
    toggleModal,
    isOpenModal,
    handleEditClick,
  };
};
