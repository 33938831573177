import {
  SET_TESTIMONIAL_LIST,
  SET_TESTIMONIAL_FILTER,
  RESET_TESTIMONIAL_FILTER,
} from "./actions";

const initialTestimonials = {
  testimonials: [],
  count: 0,
  filter: {
    status: "",
    limit: 10,
    skip: 0,
  },
};

const testimonialReducer = (state = initialTestimonials, action) => {
  switch (action.type) {
    case SET_TESTIMONIAL_LIST:
      return {
        ...state,
        testimonials: action.payload.testimonials,
        count: action.payload.count || 0,
      };
    case SET_TESTIMONIAL_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case RESET_TESTIMONIAL_FILTER:
      return { ...state, filter: initialTestimonials.filter || [] };
    default:
      return state;
  }
};

export default testimonialReducer;
