import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBanner, getBannerList } from "../action";
import { getBanners, getBannerFilter } from "../selectors";

export const useAddBanner = () => {
  const dispatch = useDispatch();
  const filter = useSelector(getBannerFilter);
  const banners = useSelector(getBanners);

  const [addBannerLoading, setAddBannerLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleAddBanner = async (values) => {
    try {
      const payload = {
        ...values,
        banners: [],
      };

      values.banners.map((banner, i) =>
        banner.event || banner.blog
          ? payload.banners.push({
              ...banner.file,
              sequence_no: i + 1,
              redirect_to: banner?.redirect_to,
              trackable_id: banner.event ? banner.event.id : banner.blog.id,
              trackable_type: banner.event
                ? "Event Schedule"
                : banner.blog
                ? "Blog"
                : "Other",
            })
          : payload.banners.push({
              ...banner.file,
              sequence_no: i + 1,
              redirect_to: banner?.redirect_to,
              trackable_type: "Other",
            })
      );

      setAddBannerLoading(true);
      const is_success = await dispatch(addBanner(payload));
      setAddBannerLoading(false);
      if (is_success) {
        dispatch(getBannerList(filter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    addBannerLoading,
    banners,
    handleAddBanner,
    isOpenModal,
    toggleModal,
    filter,
  };
};
