import { useState } from "react";
import { initialLanguageFieldValue } from "../../../../constants";
import { setLanguage } from "../../../../utils/auth";
import { handleFormValueChange } from "../../../../utils/form";

const formField = {
  title: initialLanguageFieldValue,
  description: initialLanguageFieldValue,
  type: "",
  status: "",
  language: "en",
};

export const useSubcategoryForm = ({
  fieldValues,
  handleCategoryForm,
  btn,
  isLoading,
  category_type,
  getSubcategoryData,
}) => {
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = (event) => {
    let { name, value } = event.target;
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  const handleLanguge = (key, language, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: {
        ...prevFieldValue[key],
        [language]: value,
      },
    }));
  };

  const handleOnChange = (event, rest, key) => {
    if (key === "language") {
      const { value } = event.target;
      handleFormValueChange(rest, key);
      handleFormUpdate(event);
      if (!rest.values.title[value]) {
        handleLanguge("title", value, "");
      }
      if (!rest.values.description[value]) {
        handleLanguge("description", value, "");
      }
      if (btn === "Save") {
        setLanguage(value);
        getSubcategoryData(rest, setFormvalues);
      }
    } else if (key === "status") {
      handleFormValueChange(rest, key);
      handleFormUpdate(event);
    } else {
      handleFormValueChange(rest, key);
      handleLanguge(key, rest.values.language, event.target.value);
    }
  };

  return {
    formValues,
    handleCategoryForm,
    btn,
    isLoading,
    category_type,
    handleFormUpdate,
    handleLanguge,
    handleOnChange,
  };
};
