import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import Modals from "../../../components/modal";
import ManageOfferForm from "../manage-offer-form/form";
import { useManageOffers } from "./hooks";
import { setLanguage } from "../../../utils/auth";
import { useUser } from "../../../common-hooks/user";

const ManageOffer = ({ plan }) => {
  const {
    editManangeOffersLoading,
    handleManageOffer,
    isOpenModal,
    toggleModal,
    getPlanFieldData,
    selectedPlan,
    handleLangugeData,
    isDisable,
  } = useManageOffers(plan);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Tooltip title="Manage Offers" placement="bottom">
        <i
          className="fa fa-gift icon-btn-action"
          onClick={() => {
            setLanguage("en");
            getPlanFieldData();
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <ManageOfferForm
              fieldValues={selectedPlan}
              btn="Save"
              isLoading={editManangeOffersLoading}
              handleManageOffer={handleManageOffer}
              handleLangugeData={handleLangugeData}
              isDisable={isDisable}
            />
          }
          title={`Manage Offers`}
        />
      ) : null}
    </>
  );
};

export default ManageOffer;
