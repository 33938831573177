import React from "react";
import Modals from "../../../../../components/modal";
import { useUploadBlog } from "./hooks";
import UploadForm from "../upload-blogs/form/upload-form";
import { useUser } from "../../../../../common-hooks/user";
import ActionButton from "../../../../../components/action-button";
const UploadBlog = () => {
  const {
    handleUploadBlogs,
    isOpenModal,
    toggleModal,
    uploadBlogsLoading,
    errorData,
    downloadSampleBlogFile,
  } = useUploadBlog();

  const { isAdmin, isCSSpecialist } = useUser();

  if (!isAdmin && !isCSSpecialist) {
    return null;
  }

  return (
    <>
      <ActionButton
        title="Upload"
        className="upload__blog_report"
        onClick={toggleModal}
        iconClassName={`fa fa-upload icon-btn-action`}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <UploadForm
              handleUploadBlogForm={handleUploadBlogs}
              btn="Upload"
              isLoading={uploadBlogsLoading}
              errorData={errorData}
              downloadSampleBlogFile={downloadSampleBlogFile}
            />
          }
          title="Upload Blogs"
        />
      ) : null}
    </>
  );
};

export default UploadBlog;
