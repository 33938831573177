import React from "react";
import DataTable from "../../../../components/table";
import { useLabels } from "./hooks";
import { columns } from "./constant";
import DeleteLabel from "./delete-label";
import AddLabel from "./add-label";
import EditLabel from "./edit-label";

const LabelList = () => {
  const { labels, loadingLabels, setFilter, filters, labelCount } = useLabels();

  const configureTableData = () => {
    try {
      let results = labels.map((label) => {
        let newLabel = Object.assign({}, label);
        newLabel["background_color"] = (
          <div className="label__container">
            <p
              className="custom__background__color"
              style={{ backgroundColor: `${label?.bg_color_code}` }}
            >
              {" "}
              {label?.bg_color_code}
            </p>
          </div>
        );
        newLabel["text_color"] = (
          <div className="label__container">
            <p
              className="custom__background__color"
              style={{ backgroundColor: `${label?.text_color_code}` }}
            >
              {" "}
              {label?.text_color_code}
            </p>
          </div>
        );
        newLabel["action"] = (
          <div className="label__container">
            <EditLabel label={label} />
            <DeleteLabel label={label?.id} />
          </div>
        );
        return newLabel;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DataTable
      title="Labels"
      addBtn={<AddLabel />}
      CreateTableData={configureTableData}
      withFilter={false}
      handleSetFilter={setFilter}
      count={labelCount}
      skip={filters?.skip}
      isLoading={loadingLabels}
    />
  );
};

export default LabelList;
