import {
  SET_REPORT_OPTIONS_LIST,
  SET_REPORT_OPTIONS_FILTER,
  CLEAR_REPORT_OPTIONS_FILTER,
} from "./actions";
import * as moment from "moment";

const initialList = {
  reasons: [],
  count: 0,
  filter: {
    reason: "",
    utc_offset: moment().utcOffset(),
    limit: 10,
    skip: 0,
    title: "",
  },
};

const reportOptionsReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_REPORT_OPTIONS_LIST:
      return {
        ...state,
        reasons: action.payload.reasons,
        count: action.payload.count || 0,
      };
    case SET_REPORT_OPTIONS_FILTER: {
      const { key, value } = action.payload;

      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }

    case CLEAR_REPORT_OPTIONS_FILTER:
      return {
        ...state,
        filter: initialList.filter,
      };
    default:
      return state;
  }
};

export default reportOptionsReducer;
