export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Name",
    accessor: "owner.name",
    width: 30,
  },
  {
    Header: "Caption",
    accessor: "caption",
    disableGlobalFilter: true,
    width: 200,
  },
  {
    Header: "Media",
    accessor: "url",
  },
  {
    Header: "Reported Data",
    accessor: "reports",
    width: 150,
  },
  {
    Header: "Created At",
    accessor: "created_at",
    width: 200,
  },
  {
    Header: "Updated At",
    accessor: "updated_at",
    width: 200,
  },
  {
    Header: "Created By",
    accessor: "owner.id",
    width: 80,
  },
  {
    Header: "Updated By",
    accessor: "updated_by",
    width: 80,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 50,
  },
  {
    Header: "Unpublish Reason",
    accessor: "reason",
  },

  {
    Header: "Background Color",
    accessor: "background_color",
  },

  {
    Header: "Reactions",
    accessor: "reactions",
  },
  {
    Header: "Views",
    accessor: "viewers",
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];

export const userRoles = [
  {
    id: 1,
    value: "Admin",
    displayText: "Admin",
  },
  {
    id: 2,
    value: "Member",
    displayText: "Member",
  },
  {
    id: 3,
    value: "Coordinator",
    displayText: "Coordinator",
  },
  {
    id: 4,
    value: "Moderator",
    displayText: "Moderator",
  },
  {
    id: 5,
    value: "Customer Support Specialist",
    displayText: "Customer Support Specialist",
  },
];

export const storyFilterStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
  {
    id: 3,
    value: "In Review",
    displayText: "In Review",
  },
];

export const reportsFilterOption = [
  {
    id: 1,
    value: "All",
    displayText: "All Stories",
  },
  {
    id: 2,
    value: "Yes",
    displayText: "Reported Stories",
  },
];
