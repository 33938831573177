import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteFaq, getFaqsList } from "../actions";
import { getFaqsFilter } from "../selectors";

export const useDeleteFaq = ({ id }) => {  
  const dispatch = useDispatch();
  const filters = useSelector(getFaqsFilter);

  const [deleteFaqLoading, setDeleteFaqLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  
  const handleDeleteFaq = async () => {
    setDeleteFaqLoading(true);
    const is_success = await dispatch(deleteFaq(id));
    setDeleteFaqLoading(false);
    if (is_success) {
      dispatch(getFaqsList(filters));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteFaqLoading,
    handleDeleteFaq,
    isOpenModal,
    toggleModal,
  };
};
