import React from "react";
import { Formik, ErrorMessage } from "formik";
import { MenuItem, TextField } from "@material-ui/core";
import { formSchema } from "./validation-schema";
import { DataLoader } from "../../../../components/loader";
import DateTimePicker from "../../../../components/date-picker";
import {
  scheduleEventExportStatus,
  ExportFieldsName,
} from "../../constant";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../utils/form";

const ExportForm = ({ downloadScheduleEventsReport, isLoading, dateError, setDateError }) => {
  const {
    focusField,
    handleFocusField,
    handleBlurField,
  } = useHandleFieldFocus();

  const {
    startsAt,
    endsAt,
    status,
  } = ExportFieldsName;

  return (
    <Formik
      validateOnMount
      validationSchema={formSchema}
      initialValues={{
        startsAt: "",
        endsAt: "",
        status: "",
      }}
      onSubmit={downloadScheduleEventsReport}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <DateTimePicker
                  selectedDate={rest.values.startsAt}
                  handleChange={(value) => {
                    setFieldValue(startsAt, value)
                    setDateError('')
                  }}
                  id="startsAt"
                  name={startsAt}
                  label="Starts At"
                  customInput={
                    <TextField
                      className="material-form__field"
                      id="startsAt"
                      label="From"
                      autoComplete="off"
                    />
                  }
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={startsAt}
                />
              </div>
              <div className="materila-form_inline-child">
                <DateTimePicker
                  selectedDate={rest.values.endsAt}
                  handleChange={(value) => {
                    setFieldValue(endsAt, value)
                    setDateError('')
                  }}
                  id="endsAt"
                  name={endsAt}
                  label="Ends At"
                  customInput={
                    <TextField
                      className="material-form__field"
                      id="endsAt"
                      label="To"
                      autoComplete="off"
                    />
                  }
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={endsAt}
                />
              </div>
            </div>
            {dateError ?
              <div className="material-form__error">{dateError}</div> :
              null
            }

            <div >
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                onChange={handleFormValueChange(rest, status)}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                value={rest.values.status}
              >
                {scheduleEventExportStatus.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>

            <button type="submit" disabled={dateError ? true : false} className="btn btn-success btn-right btn-sm">
              Export
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default ExportForm;
