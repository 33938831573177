import { useState } from "react";
import { useDispatch } from "react-redux";
import { getSharedPostData } from "../../action";

const LIMIT_COUNT = 10;

export const useSharedData = ({ post }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [dataParams, setDataParams] = useState({
    limit: LIMIT_COUNT,
    skip: 0,
  });

  const handleDataParams = () => {
    setDataParams((prev) => ({
      ...prev,
      skip: prev?.skip + LIMIT_COUNT,
    }));
    fetchData();
  };

  const fetchData = async () => {
    setIsLoading(true);
    const data = await dispatch(getSharedPostData(post?.id, dataParams));
    if (data?.is_success) {
      setData((prev) => {
        if (prev?.data) {
          return [...prev?.data, ...data?.post_shared_by];
        }

        return data?.post_shared_by;
      });
      setTotalCount(data?.count || 0);
      setIsOpenModal(true);
    }
    setIsLoading(false);
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    data,
    isLoading,
    fetchData,
    isOpenModal,
    toggleModal,
    totalCount,
    handleDataParams,
    post,
  };
};
