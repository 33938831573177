/* eslint-disable */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../common-hooks/user";
import {
  updateStatus,
  setCommentAndReplyFilter,
  resetCommentAndReplyFilter,
  getCommentAndReplyList,
} from "./action";
import {
  getCommentsAndReplies,
  getCommentsAndRepliesFilters,
  getCount,
} from "./selectors";

export const useCommentAndReplies = (props) => {
  const dispatch = useDispatch();
  const filters = useSelector(getCommentsAndRepliesFilters);
  const commentsAndReplies = useSelector(getCommentsAndReplies);
  const count = useSelector(getCount);
  const user = useSelector(({ authReducer }) => authReducer?.loggedInUser);
  const { isAdmin, isModerator, isCSSpecialist } = useUser();
  const history = useHistory();

  const [loadingList, setLoadingList] = useState(false);

  useEffect(() => {
    setLoadingList(true);
    const fetchData = async () => {
      try {
        const data = await dispatch(getCommentAndReplyList(filters));
        if (data?.is_success) {
          setLoadingList(false);
        }
      } catch (error) {
        setLoadingList(false);
      } finally {
        setLoadingList(false);
      }
    };
    fetchData();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setCommentAndReplyFilter({ key, value }));
  };

  const resetFilter = async () => {
    dispatch(resetCommentAndReplyFilter());
  };

  const updateCommentStatus = async (id, payload) => {
    const { is_success } = await dispatch(updateStatus(id, payload));
    if (is_success) {
      await dispatch(getCommentAndReplyList(filters));
    }
  };

  const handleReportClick = (e) => {
    history.push(`/comments-and-replies/${e.target.id}/reports`);
  };

  return {
    filters,
    setFilter,
    resetFilter,
    commentsAndReplies,
    count,
    user,
    updateCommentStatus,
    loadingList,
    isAdmin,
    isModerator,
    isCSSpecialist,
    handleReportClick,
  };
};
