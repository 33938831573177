import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLoggedInUser, getConfigData } from "../auth/actions";
import { getUserData, logOut } from "../../utils/auth";

export const useLayout = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(
    ({ authReducer }) => authReducer.loggedInUser
  );
  useEffect(() => {
    const fetchLoggedInUser = async () => {
      const user = await getUserData();
      if (user) {
        const { id } = JSON.parse(user);
        const { userDetails: { user: { is_active = true } = {} } = {} } =
          await dispatch(getLoggedInUser(id));
        !is_active && logOut();
        await dispatch(getConfigData());
      } else {
        logOut();
      }
    };
    fetchLoggedInUser();
  }, [dispatch]);

  return { loggedInUser };
};
