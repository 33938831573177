import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialLanguageFieldValue } from "../../../constants";
import { clearLanguage, getLanguage } from "../../../utils/auth";
import { editFaq, getCategoryList, getFaq, getFaqsList } from "../actions";
import { categoryParams } from "../constant";
import { getFaqsFilter, getCategories } from "../selectors";

export const useEditFaq = ({ id }) => {
  const dispatch = useDispatch();
  const filter = useSelector(getFaqsFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editFaqLoading, setEditFaqLoading] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState();
  const categories = useSelector(getCategories);

  const getFaqFieldData = async () => {
    const selectedLanguage = getLanguage();
    setEditFaqLoading(true);
    const data = await dispatch(getFaq(id));
    const is_category_success = await dispatch(getCategoryList(categoryParams));
    setEditFaqLoading(false);
    if (data && is_category_success) {
      data.question = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data.question,
      };
      data.answer = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data.answer,
      };
      setSelectedFaq(data);
      toggleModal();
    }
  };

  const handleLangugeData = async (rest, setFieldValue) => {
    const selectedLanguage = getLanguage();
    setEditFaqLoading(true);
    const data = await dispatch(getFaq(id));
    setEditFaqLoading(false);
    if (data) {
      let que = {
        ...rest.values.question,
        [selectedLanguage]:
          rest.values.question[selectedLanguage] || data.question || "",
      };
      data.question = que;
      setFieldValue("question", que);

      let ans = {
        ...rest.values.answer,
        [selectedLanguage]:
          rest.values.answer[selectedLanguage] || data.answer || "",
      };
      data.answer = ans;
      setFieldValue("answer", ans);
      setSelectedFaq(data);
    }
    setEditFaqLoading(false);
  };

  const handleEditFaq = async (values) => {
    try {
      const payload = {
        question: values.question,
        answer: values.answer,
        status: values.status,
        category_id: values.category,
      };
      setEditFaqLoading(true);
      const is_success = await dispatch(editFaq(payload, id));
      setEditFaqLoading(false);
      if (is_success) {
        dispatch(getFaqsList(filter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editFaqLoading,
    handleEditFaq,
    isOpenModal,
    toggleModal,
    getFaqFieldData,
    selectedFaq,
    categories,
    handleLangugeData,
  };
};
