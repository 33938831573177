import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMetaData, setMetaDataFilter } from "./actions";
import {
  getMetaDataCount,
  getMetaDataFilter,
  getMetaDataList,
} from "./selectors";

export const useMetaData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const metaData = useSelector(getMetaDataList);
  const count = useSelector(getMetaDataCount);
  const filters = useSelector(getMetaDataFilter);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        await dispatch(getMetaData(filters));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dispatch, filters]);

  const handleSearch = (key, value) => {
    dispatch(setMetaDataFilter({ key, value }));
  };
  return {
    metaData,
    count,
    filters,
    handleSearch,
    isLoading,
  };
};
