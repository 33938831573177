import {
  SET_FILES,
  SET_SINGLE_FILE,
  CLEAR_FILES,
  DELETE_A_FILE,
  CLEAR_FILE_CONTEXT,
  UPDATE_FILES,
} from "./actions";

const initialState = {
  files: [],
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILES: {
      const fileIndex = state.files.findIndex(
        (file) =>
          file.original_file_name === action.payload.original_file_name &&
          file.file_context === action.payload.file_context
      );
      if (fileIndex !== -1) {
        return {
          ...state,
          files: state.files.map((file, index) =>
            index === fileIndex ? action.payload : file
          ),
        };
      }
      return { ...state, files: [...state.files, action.payload] || [] };
    }

    case SET_SINGLE_FILE: {
      return { ...state, files: [action.payload] };
    }
    case CLEAR_FILES:
      return { ...state, files: [] };
    case DELETE_A_FILE: {
      let newFiles = state.files.filter(
        (file) => file.uuid_file_name !== action.payload
      );
      return { ...state, files: newFiles };
    }
    case CLEAR_FILE_CONTEXT: {
      let newFiles = state.files.filter(
        (file) => file.file_context !== action.payload
      );
      return { ...state, files: newFiles };
    }
    case UPDATE_FILES: {
      return { ...state, files: action.payload };
    }
    default:
      return state;
  }
};

export default fileReducer;
