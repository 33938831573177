import React, { useState } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import TopbarMenuLink from "./TopbarMenuLink";
import { logOut } from "../../../utils/auth";
import userLogo from "../../../assets/images/user-icon.png";

const TopbarProfile = ({ loggedInUser }) => {
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={toggle}>
        <img
          className="topbar__avatar-img"
          src={loggedInUser?.profile_picture?.url || userLogo}
          alt="avatar"
        />
        <p className="topbar__avatar-name">
          {loggedInUser?.user?.name ||
            loggedInUser?.user?.mobile_number ||
            "Unknown"}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {collapse && (
        <button type="button" className="topbar__back" onClick={toggle} />
      )}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title="Account" icon="user" path="/users/me" />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Log Out"
            icon="exit"
            path="/log_in"
            logOut={logOut}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;
