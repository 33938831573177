const namespace = "components/snackbar";

export const SNACKBAR_SHOW = `${namespace}/SNACKBAR_SHOW`;
export const SNACKBAR_CLEAR = `${namespace}/SNACKBAR_CLEAR`;

export const showSnackbar = (payload) => ({
  type: SNACKBAR_SHOW,
  payload,
});

export const clearSnackbar = () => ({
  type: SNACKBAR_CLEAR,
});
