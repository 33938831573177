export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Status",
    accessor: "status",
    width: 60,
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    width: 60,
  },
];

export const testimonialStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
];

export const fieldsName = {
  status: "status",
  description: "description",
  desktop_banner: "desktop_banner",
  mobile_banner: "mobile_banner",
};
