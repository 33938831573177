import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

const SelectFilter = ({
  handleSelect,
  selectedValue,
  filterKey,
  options,
  defaultOption,
  filterTitle,
  defaultOptionValue,
}) => {
  return (
    <div className="table__search">
      <Tooltip title={filterTitle} placement="bottom">
        <select
          className={`${
            selectedValue !== "All" && selectedValue.length
              ? "table__active_filter"
              : ""
          } form-control form__field-table-filter`}
          onChange={(e) => handleSelect(filterKey, e.target.value)}
          value={selectedValue || ""}
          name="selectFilter"
          id="selectFilter"
        >
          {defaultOption ? (
            <option value={defaultOptionValue || ""}>{defaultOption}</option>
          ) : null}

          {options.map((option) => (
            <option key={option.id} value={option.value}>
              {option.displayText}
            </option>
          ))}
        </select>
      </Tooltip>
    </div>
  );
};

SelectFilter.propTypes = {
  selectedValue: PropTypes.any,
  handleSelect: PropTypes.func.isRequired,
  filterKey: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  defaultOption: PropTypes.string,
  filterTitle: PropTypes.string,
};

SelectFilter.defaultProps = {
  selectedValue: "",
  defaultOption: "",
  filterTitle: "",
};

export default SelectFilter;
