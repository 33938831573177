import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";

const namespace = "containers/vouchers";

export const SET_VOUCHER_LIST = `${namespace}/SET_VOUCHER_LIST`;
export const SET_VOUCHER_FILTER = `${namespace}/SET_VOUCHER_FILTER`;
export const RESET_VOUCHER_FILTER = `${namespace}/RESET_VOUCHER_FILTER`;

export const SET_CODES_LIST = `${namespace}/SET_CODES_LIST`;
export const SET_CODES_FILTER = `${namespace}/SET_CODES_FILTER`;
export const RESET_CODES_FILTER = `${namespace}/RESET_CODES_FILTER`;

const setVoucherList = (payload) => ({
  type: SET_VOUCHER_LIST,
  payload,
});

export const setVoucherFilter = (payload) => ({
  type: SET_VOUCHER_FILTER,
  payload,
});

export const resetVoucherFilter = () => ({
  type: RESET_VOUCHER_FILTER,
});

const setCodesList = (payload) => ({
  type: SET_CODES_LIST,
  payload,
});

export const setCodesFilter = (payload) => ({
  type: SET_CODES_FILTER,
  payload,
});

export const resetCodesFilter = () => ({
  type: RESET_CODES_FILTER,
});

export const getVouchersList = (params) => async (dispatch) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_VOUCHER_BASE_URL}${apiEndPoints.coupons}`,
      {
        params: { params },
      }
    );
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setVoucherList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addVoucher = (payload) => async (dispatch) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_VOUCHER_BASE_URL}${apiEndPoints.coupons}`,
      payload
    );
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return is_success;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteCoupon = (id) => async (dispatch) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_VOUCHER_BASE_URL}${apiEndPoints.deleteCoupon(
        id
      )}`
    );

    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
  } catch (error) {
    return false;
  }
};

export const getCodesList = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_VOUCHER_BASE_URL}${apiEndPoints.getCouponCodes(
        id
      )}`,
      {
        params: { params },
      }
    );
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setCodesList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadCodes = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_VOUCHER_BASE_URL}${apiEndPoints.downloadCodes(
        id
      )}`,
      {
        responseType: "arraybuffer",
        params: { params },
      }
    );
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
