import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoriesList,
  getInterestList,
  getUserList,
  getBlogData,
  editBlog,
  getLabelList,
  getAuthorData,
  setBlogFilter,
  getBlogList,
  clearBlogFilter,
} from "../actions";
import { clearFiles, setFiles } from "../../../../../utils/upload-file/actions";
import {
  getCategoriesSelector,
  getInterestsSelector,
  getCommonFilter,
  getCategoryFilter,
  getUserFilter,
  getBlogFilter,
} from "../selectors";
import { useHistory } from "react-router-dom";
import { clearLanguage, getLanguage } from "../../../../../utils/auth";
import { initialLanguageFieldValue } from "../../../../../constants";
import _ from "lodash";

export const useEditBlog = ({
  match: {
    params: { blog_id },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [editBlogLoading, setEditBlogLoading] = useState(false);
  const categories = useSelector(getCategoriesSelector);
  const interests = useSelector(getInterestsSelector);
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const [users, setUsers] = useState([]);
  const [labels, setLabels] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState();
  const filters = useSelector(getCommonFilter);
  const categoryFilter = useSelector(getCategoryFilter);
  const userFilter = useSelector(getUserFilter);
  const [blogAuthor, setBlogAuthor] = useState([]);
  const blogFilter = useSelector(getBlogFilter);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const getRequiredData = async () => {
      setEditBlogLoading(true);
      dispatch(clearFiles());
      clearLanguage();
      const { blog, interest_ids, files } = await dispatch(
        getBlogData(blog_id)
      );
      const author = await dispatch(getAuthorData(blog?.author_id));
      setUsers((prev) => [...prev, author]);
      setBlogAuthor(author);

      if (blog) {
        blog.interest_ids = interest_ids;
        blog.title = {
          ...initialLanguageFieldValue,
          en: blog.title || "",
        };
        blog.description = {
          ...initialLanguageFieldValue,
          en: blog.description ? `<p>${blog.description}</p>` : "",
        };
        setSelectedBlog(blog);
        if (blog.next_blog_id !== 0) {
          const { blog: nextBlog = {} } = await dispatch(
            getBlogData(blog.next_blog_id)
          );
          setBlogs([nextBlog]);
        }
      }
      files.forEach((file) => dispatch(setFiles(file)));
      await dispatch(getCategoriesList(categoryFilter));
      await dispatch(getInterestList());

      const { labels } = await dispatch(getLabelList(filters));
      if (labels) {
        setLabels(labels);
      }
      setEditBlogLoading(false);
    };
    getRequiredData();
  }, [blog_id, dispatch, filters, categoryFilter]);

  useEffect(() => {
    (async () => {
      const data = await dispatch(getUserList(userFilter));
      if (data) {
        setUsers((prev) => [blogAuthor, ...data]);
      }
    })();
  }, [userFilter, blogAuthor, dispatch]);

  const handleLangugeData = async (rest, setFormValues) => {
    const selectedLanguage = getLanguage();
    setEditBlogLoading(true);
    const { blog, interest_ids } = await dispatch(getBlogData(blog_id));
    if (blog) {
      blog.interest_ids = interest_ids;
      blog.title = {
        ...rest.values.title,
        [selectedLanguage]:
          rest.values.title[selectedLanguage] || blog?.title || "",
      };
      blog.description = {
        ...rest.values.description,
        [selectedLanguage]:
          rest.values.description[selectedLanguage] || blog?.description || "",
      };
      blog.language = selectedLanguage;
      setSelectedBlog(blog);
      setFormValues(blog);
    }

    setEditBlogLoading(false);
  };

  const handleEditBlog = async (values) => {
    try {
      setEditBlogLoading(true);
      let interestIDS = values.interestIds.map(function (item) {
        return item["id"];
      });
      const payload = {
        blog: {
          title: values.title,
          description: values.description,
          author_id: values.author_id.id,
          tags: values.tags,
          category_id: values.category_id.id,
          is_popular: values.is_popular,
          label_id: values.label_id ? values.label_id?.id : 0,
          published_at: values.published_at,
          time_to_read: parseInt(values.time_to_read),
          status: values.status,
          next_blog_id: values.next_blog_id ? values.next_blog_id?.id : 0,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          meta_keywords: values.meta_keywords,
          slug: values.slug,
        },
        interest_ids: interestIDS,
        files,
      };
      const is_success = await dispatch(editBlog(payload, blog_id));

      setEditBlogLoading(false);
      if (is_success) {
        dispatch(clearFiles());
        dispatch(clearBlogFilter());
        history.push("/blogs");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setSearchText = useCallback(
    _.debounce((key, value) => {
      dispatch(setBlogFilter({ key, value }));
    }, 600),
    []
  );

  useEffect(() => {
    (async () => {
      const blogList = await dispatch(getBlogList(blogFilter));
      if (blogList) {
        await setBlogs(blogList);
      }
    })();
  }, [dispatch, blogFilter]);

  return {
    editBlogLoading,
    handleEditBlog,
    categories,
    interests,
    users,
    blogs,
    labels,
    selectedBlog,
    setSearchText,
    handleLangugeData,
  };
};
