import moment from "moment";
import React from "react";
import { Loader } from "../../../components/loader";
import DataTable from "../../../components/table";
import { codesColumns } from "../constant";
import { useCodes } from "./hooks";

const Codes = () => {
  const { codesLoading, codes, count, filters, setFilter } = useCodes();

  const configureTableData = () => {
    try {
      let results = codes.map((code) => {
        let newCode = Object.assign({}, code);
        newCode["created_at"] = moment(code?.created_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );
        newCode["updated_at"] = moment(code?.updated_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );

        newCode["created_by"] = code.created_by || "NA";
        newCode["updated_by"] = code.updated_by || "NA";
        newCode["status"] = code.status || "NA";

        return newCode;
      });
      const reactTableData = {
        tableHeaderData: codesColumns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return codesLoading ? (
    <Loader />
  ) : (
    <div>
      <DataTable
        title="Codes"
        CreateTableData={configureTableData}
        withFilter={false}
        // filter={voucherFilter}
        handleSetFilter={setFilter}
        count={count}
        skip={filters?.skip}
      />
    </div>
  );
};

export default Codes;
