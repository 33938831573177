import PropTypes from "prop-types";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";

const CustomModal = ({
  children,
  isOpen,
  isFullscreen,
  onCloseCallback,
  width,
  contentRootClassName,
  paperClassName,
  rootClass,
}) => {
  const useStyles = makeStyles(() => ({
    paper: {
      minWidth: width,
      borderRadius: 8,
      overflow: "hidden",
      "& MuiDialog-scrollBody": {
        overflow: "hidden !important",
      },
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      fullScreen={isFullscreen}
      onClose={onCloseCallback}
      scroll={"body"}
      classes={{
        paper: `${classes.paper} ${paperClassName}`,
        root: `${classes.rootClass}`,
      }}
    >
      <DialogContent classes={{ root: contentRootClassName }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isFullscreen: PropTypes.bool,
  onCloseCallback: PropTypes.func.isRequired,
  width: PropTypes.string,
  contentRootClassName: PropTypes.string,
  paperClassName: PropTypes.string,
};

CustomModal.defaultProps = {
  width: "720px",
  contentRootClassName: "",
  paperClassName: "",
  overflow: "hidden",
};

export default CustomModal;
