import * as Yup from "yup";
import { checkFields } from "../../../../utils/validation";

const requiredFields = ["title", "description"];

export const categoriesFormSchema = Yup.object().shape({
  title: Yup.mixed().test(
    "Title required",
    "Enter valid title for all languages",
    function () {
      const {
        title: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "title");
    }
  ),
  description: Yup.mixed().test(
    "Description required",
    "Enter valid description for all languages",
    function () {
      const {
        description: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "description");
    }
  ),
  language: Yup.string().required("Language is required"),
  status: Yup.string().required("Status is required"),
});
