/* eslint-disable */
import React from "react";
import { useUser } from "../../common-hooks/user";
import Modals from "../modal";
import DataTable from "../table";
import { useConfidenceScore } from "./hooks";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane, Col } from "reactstrap";
import Accordion from "../Accordion";
import { DataLoader } from "../loader";
import TableEyeIcon from "mdi-react/TableEyeIcon";
import { Tooltip } from "@material-ui/core";
import { languages, types } from "./constant";
import ReactPlayer from "react-player";

const ConfidenceScore = (props) => {
  const {
    isOpenModal,
    toggleModal,
    activeTab,
    toggleTab,
    isOpenAcc,
    onChangeAcc,
    className,
    confidenceScore,
    isLoading,
    hasPhoto,
    hasVideo,
    hasText,
  } = useConfidenceScore(props);
  const { isAdmin } = useUser();
  const { TEXT, PHOTO, VIDEO } = types;

  if (!isAdmin) return null;

  const configureTableData = () => {
    try {
      const data = confidenceScore?.find((data, i) => {
        const { type = "" } = data;
        if (type === TEXT) {
          return `${type} ${i}` === isOpenAcc;
        }

        if (type === PHOTO) {
          return isOpenAcc === `Image ${i}`;
        }

        if (type === "VIDEO") {
          return isOpenAcc === `Video ${i}`;
        }
      })?.output;

      const columns = [];
      const results = [];

      data !== -1
        ? data?.map((item, indx) => {
            item?.classes?.map((score, i) => {
              let newScore = Object.assign({}, score);
              newScore[score.class] = score.score;
              if (isOpenAcc?.includes("Video") && i === 0) {
                columns[i] = {
                  Header: "time",
                  accessor: "time",
                };

                results[indx] = {
                  ...results[indx],
                  ["time"]: (
                    <div
                      style={{
                        background: score?.color_code || "#fff",
                        color: score?.color_code ? "#fff" : "#000",
                        width: "fitContent",
                      }}
                    >
                      {item?.time}
                    </div>
                  ),
                };
              } else {
                columns[i] = {
                  Header: score.class,
                  accessor: score.class,
                  width: 250,
                };
                results[indx] = {
                  ...results[indx],
                  [score.class]: (
                    <div
                      style={{
                        background: score?.color_code || "#fff",
                        color: score?.color_code ? "#fff" : "#000",
                        width: "fit-content",
                      }}
                    >
                      {score?.score}
                    </div>
                  ),
                };
              }
              return newScore;
            });
          })
        : [];

      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };

      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Tooltip
        className={`cursor_pointer ${className}`}
        placement="bottom"
        title="View Confidence Score"
      >
        <div>
          <TableEyeIcon onClick={toggleModal} />
        </div>
      </Tooltip>

      <Modals
        isOpenModal={isOpenModal}
        setIsOpenModal={toggleModal}
        className="confidence_score_container"
        message={
          <>
            <div
              className={`tabs tabs--bordered-bottom ${
                isLoading ? "confidence_score_tabs" : ""
              } `}
            >
              {isLoading ? (
                <DataLoader />
              ) : !confidenceScore?.length ? (
                <div className="d-flex justify-content-center align-items-center confidence_score_tabs bold-text">
                  No content moderation results found!
                </div>
              ) : (
                <div className="tabs__wrap">
                  <Nav tabs>
                    {hasText && (
                      <NavItem>
                        <NavLink
                          id={TEXT}
                          className={classnames({
                            active: activeTab === TEXT,
                          })}
                          onClick={toggleTab}
                        >
                          {TEXT}
                        </NavLink>
                      </NavItem>
                    )}
                    {hasPhoto && (
                      <NavItem>
                        <NavLink
                          id={PHOTO}
                          className={classnames({
                            active: activeTab === PHOTO,
                          })}
                          onClick={toggleTab}
                        >
                          {PHOTO}
                        </NavLink>
                      </NavItem>
                    )}
                    {hasVideo && (
                      <NavItem>
                        <NavLink
                          id={VIDEO}
                          className={classnames({
                            active: activeTab === VIDEO,
                          })}
                          onClick={toggleTab}
                        >
                          {VIDEO}
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>

                  <TabContent
                    className="confidence__score_tab_content"
                    activeTab={activeTab}
                  >
                    {confidenceScore?.map((data, i) => {
                      const {
                        type = "",
                        input: { text = "", language = "" } = {},
                      } = data;
                      return (
                        <TabPane key={i} tabId={type}>
                          <Col md={12} lg={12} xl={12}>
                            <Accordion
                              isOpen={
                                isOpenAcc ===
                                ((type === TEXT && `${TEXT} ${i}`) ||
                                  (type === PHOTO
                                    ? `Image ${i}`
                                    : `Video ${i}`))
                              }
                              onToggle={() =>
                                onChangeAcc(
                                  (type === TEXT && `${TEXT} ${i}`) ||
                                    (type === PHOTO
                                      ? `Image ${i}`
                                      : `Video ${i}`)
                                )
                              }
                              className="user__tabs_collapse"
                              title={
                                <>
                                  {type === TEXT ? (
                                    <div className="mb-1">
                                      Language :{" "}
                                      {languages[language] || "Not Detected"}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {type === TEXT ? (
                                    <>
                                      Caption :{" "}
                                      {text?.length > 100
                                        ? `${text.slice(0, 100)}...`
                                        : text}
                                    </>
                                  ) : (
                                    type
                                  )}
                                </>
                              }
                              titleClassName="confidence__score-title"
                            >
                              {type === TEXT && (
                                <div className="ml-1 p-2">
                                  {data?.input?.text}
                                </div>
                              )}

                              {type !== TEXT ? (
                                <div className="d-flex justify-content-center">
                                  {type === PHOTO ? (
                                    <img
                                      src={data?.input?.media?.url}
                                      alt="user media file"
                                      className="confidence__score_img"
                                    />
                                  ) : (
                                    <ReactPlayer
                                      controls
                                      width="100%"
                                      url={data?.input?.media?.url}
                                    />
                                  )}
                                </div>
                              ) : null}

                              <DataTable
                                CreateTableData={configureTableData}
                                withFilter={false}
                                count={1}
                                skip={0}
                                isLoading={false}
                                handleSetFilter={() => {}}
                              />
                            </Accordion>
                          </Col>
                        </TabPane>
                      );
                    })}
                  </TabContent>
                </div>
              )}
            </div>
          </>
        }
        title="Confidence Score"
      />
    </>
  );
};

export default ConfidenceScore;
