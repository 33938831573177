import React from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useRowSelect,
} from "react-table";
import ReactTableHeader from "./ReactTableHeader";
import BodyReactTable from "./ReactTableBody";
import ReactTableFooter from "./ReactTableFooter";
import ReactTableFilter from "./ReactTableFilter";
import ReactTablePagination from "./ReactTablePagination";

const ReactTable = ({
  tableConfig,
  arrayForTable,
  handleSetFilter,
  count,
  skip,
}) => {
  const {
    isEditable,
    isResizable,
    isSortable,
    withSearchEngine,
    manualPageSize,
    placeholder,
    withPagination,
    withManualPageSize,
  } = tableConfig;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    setPageSize,
    setGlobalFilter,
    withDragAndDrop,
    updateData,
    dataLength,
    state: { pageIndex, pageSize },
  } = useTable(
    ...arrayForTable,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  return (
    <div>
      {withSearchEngine && (
        <ReactTableFilter
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          placeholder={placeholder}
          rows={rows}
          dataLength={dataLength}
        />
      )}
      <div
        className={
          withPagination
            ? "table react-table"
            : "table react-table table--not-pagination"
        }
      >
        <table
          {...getTableProps()}
          className={
            isEditable ? "editable-table" : "react-table resizable-table"
          }
        >
          <ReactTableHeader
            headerGroups={headerGroups}
            isSortable={isSortable}
            isResizable={isResizable}
          />
          <BodyReactTable
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            updateData={updateData}
            isEditable={isEditable}
            withDragAndDrop={withDragAndDrop}
          />
          {(pageCount === pageIndex + 1 ||
            (!withPagination && rows.length !== 0)) && (
            <ReactTableFooter footerGroups={footerGroups} />
          )}
        </table>
      </div>
      {withPagination && rows.length > 0 && (
        <ReactTablePagination
          page={page}
          pageSize={pageSize}
          setPageSize={setPageSize}
          manualPageSize={manualPageSize}
          withManualPageSize={withManualPageSize}
          handleSetFilter={handleSetFilter}
          count={count}
          skip={skip}
        />
      )}
    </div>
  );
};

ReactTable.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.string,
  }),
  arrayForTable: PropTypes.array.isRequired,
};

ReactTable.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
  },
  arrayForTable: [
    {
      columns: [],
      data: [],
      isEditable: PropTypes.bool,
      updateData: () => {},
      defaultColumn: [],
      withDragAndDrop: false,
      dataLength: null,
      autoResetPage: false,
      disableSortBy: false,
      manualSortBy: false,
      manualGlobalFilter: false,
      manualPagination: false,
      initialState: {
        pageIndex: null,
        pageSize: null,
      },
    },
  ],
};

export default ReactTable;
