/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import ReactPlayer from "react-player";
import { useUploadFiles } from "../../../../utils/upload-file/hooks";
import { useHandleFieldFocus } from "../../../../utils/form";
import {
  fileContext,
  supportedPostImageFormat,
  supportedPostVideoFormat,
  trackableTypes,
} from "../../../../constants";
import ProgressChip from "../../../../components/progress-chip";
import { postByList } from "../../constant";
import { MenuItem, TextField } from "@material-ui/core";
import styles from "../styles.module.scss";
import CaptionInput from "../../../social/posts/components/caption-input-multiline";
import { useUser } from "../../../../common-hooks/user";
const formField = {
  caption: "",
  ranges: [],
  plainTextValue: "",
  post_by: "Me",
};

const DiscussionForm = ({
  fieldValues,
  handlePostForm,
  btn,
  isLoading,
  isEdit,
}) => {
  const {
    files,
    handleDeleteAFile,
    isFileUploadData,
    uploadProgress,
    handleDiscussionFiles,
  } = useUploadFiles();

  const { focusField } = useHandleFieldFocus();
  const { isAdmin } = useUser();

  //To use it when form reinitialized

  const [formValues, setFormvalues] = useState(fieldValues || formField);

  useEffect(() => {
    if (fieldValues?.post_by) {
      setFormvalues(fieldValues);
    }
  }, [fieldValues]);

  const handleMentions = (mentions, newPlainTextValue) => {
    setFormvalues((prev) => ({
      ...prev,
      ranges: mentions,
      plainTextValue: newPlainTextValue,
    }));
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        caption: formValues?.caption || "",
        ranges: formValues?.ranges || "",
        plainTextValue: formValues?.plainTextValue || "",
        post_by: formValues?.post_by || "Me",
        files,
      }}
      onSubmit={handlePostForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="material-form_inline-fields">
              <div
                style={{ width: "100%" }}
                className="materila-form_inline-child"
              >
                <div className={styles.comment_input_div}>
                  <div className={styles.input_field}>
                    <CaptionInput
                      placeholder="Caption"
                      caption={fieldValues?.caption || ""}
                      onCaptionChange={handleMentions}
                      mentions={fieldValues?.ranges}
                      trackableType={trackableTypes.DISCUSSION}
                    />
                  </div>
                </div>

                {focusField === "caption" ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={"caption"}
                  />
                )}
              </div>
            </div>

            {isAdmin && (
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={"post_by"}
                  placeholder="Post By"
                  label="Post By"
                  disabled={isEdit}
                  select
                  value={rest.values.post_by}
                  onChange={(event) => {
                    setFormvalues((prev) => ({
                      ...prev,
                      ["post_by"]: event.target.value,
                    }));
                    setFieldValue("post_by", event.target.value);
                  }}
                  defaultValue={rest.values.post_by}
                >
                  {postByList.map((post_by) => (
                    <MenuItem
                      key={post_by.id}
                      className="material-form__option"
                      value={post_by.value}
                    >
                      {post_by.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === "post_by" ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={"post_by"}
                  />
                )}
              </div>
            )}

            <div className="form__form-group-field form__margin-bottom">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label
                    className="border border-white pl-0 bg-white"
                    htmlFor="files"
                  >
                    <div className="btn btn-outline-success btn-sm mt-2">
                      Add Photo / Video
                    </div>
                  </label>
                  <span>
                    {files.filter(
                      (file) =>
                        file.file_context === fileContext.DISCUSSION_IMAGES ||
                        file.file_context === fileContext.DISCUSSION_VIDEOS
                    ).length ? (
                      <div className={"discussion_images_container"}>
                        {files
                          .filter(
                            (file) =>
                              file.file_context ===
                                fileContext.DISCUSSION_IMAGES ||
                              file.file_context ===
                                fileContext.DISCUSSION_VIDEOS
                          )
                          .map((img, i) => (
                            <div
                              className={"img_container"}
                              key={img?.uuid_file_name}
                            >
                              {img.file_context ===
                              fileContext.DISCUSSION_VIDEOS ? (
                                <ReactPlayer
                                  width={93}
                                  height={62}
                                  url={
                                    img?.ts_url || img?.url || img.public_url
                                  }
                                  playing={true}
                                  muted={true}
                                  loop={true}
                                  style={{ display: "inline-block" }}
                                />
                              ) : (
                                <img src={img?.url || img.public_url} />
                              )}
                              <img
                                src={"/img/close-filled.svg"}
                                alt="close"
                                id={i}
                                className={"img_close_icon"}
                                onClick={(e) => {
                                  handleDeleteAFile(img);
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    ) : null}

                    {isFileUploadData
                      .filter(
                        (file) =>
                          file.file_context === fileContext.DISCUSSION_IMAGES ||
                          file.file_context === fileContext.DISCUSSION_VIDEOS
                      )
                      .map((x, i) => {
                        return (
                          <div key={i}>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </div>
                        );
                      })}
                  </span>
                  <input
                    accept={[
                      ...supportedPostImageFormat,
                      supportedPostVideoFormat,
                    ].join(", ")}
                    multiple
                    onBlur={rest.handleBlur}
                    type="file"
                    name={"files"}
                    id="files"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleDiscussionFiles(event.target.files);
                      setFieldValue(
                        "files",
                        files.filter(
                          (file) => file.file_context === fileContext.POST
                        )
                      );
                    }}
                  />
                </div>
                {rest.errors.files && (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={"files"}
                  />
                )}
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-success btn-right btn-sm mt-4"
              disabled={!rest.values.plainTextValue.trim() && !files?.length}
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default DiscussionForm;
