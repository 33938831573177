export const getEvents = ({ postsReducer: { events = [] } = {} }) => events;
export const getEventsFilters = ({
  postsReducer: { event_filter = {} } = {},
}) => event_filter;
export const getBlogs = ({ postsReducer: { blogs = [] } = {} }) => blogs;
export const getBlogsFilters = ({ postsReducer: { blog_filter = {} } = {} }) =>
  blog_filter;

export const getPosts = ({ postsReducer: { posts = [] } = {} }) => posts;
export const getPostsFilters = ({ postsReducer: { filter = {} } = {} }) =>
  filter;
export const getCount = ({ postsReducer: { posts_count = 0 } = {} }) =>
  posts_count;

  export const getCourses = ({ postsReducer: { courses = [] } = {} }) => courses;
export const getCoursesFilters = ({ postsReducer: { course_filter = {} } = {} }) =>
  course_filter;