import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import EventForm from "../form/event-form";
import { useEditEvent } from "./hooks";
import Modals from "../../../components/modal";
import { setLanguage } from "../../../utils/auth";
import { useUser } from "../../../common-hooks/user";

const EditEvent = ({ event }) => {
  const {
    handleEditEvent,
    isOpenModal,
    toggleModal,
    selectedEvent,
    categories,
    subcategories,
    getEventFieldData,
    editEventLoading,
    faqCategories,
    handleLangugeData,
  } = useEditEvent(event);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action`}
          onClick={() => {
            setLanguage("en");
            getEventFieldData();
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <EventForm
              handleEventForm={handleEditEvent}
              btn="Save"
              categories={categories}
              subcategories={subcategories}
              faqCategories={faqCategories}
              fieldValues={selectedEvent}
              isLoading={editEventLoading}
              handleLangugeData={handleLangugeData}
            />
          }
          title="Edit Event"
        />
      ) : null}
    </div>
  );
};

export default EditEvent;
