import React from 'react'
import { Button } from "reactstrap";
import { useAddLabel } from './hooks'
import LabelForm from "../form/label-form";
import Modals from "../../../../../components/modal";


const AddLabel = (props) => {
  const {
    handleAddLabel,
    addLabelLoading,
    isOpenModal,
    toggleModal,
  } = useAddLabel(props);
  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          toggleModal();
        }}
      >
        <i className="fa fa-plus"></i> Add Label
     </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <LabelForm
              handleLabelForm={handleAddLabel}
              btn="Add"
              isLoading={addLabelLoading}
            />
          }
          title="Add Label"
        />
      ) : null}
    </div>
  )
}

export default AddLabel
