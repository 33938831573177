import React from "react";
import DataTable from "../../../components/table";
import { columns } from "./constant";
import AddBaner from "./add-banner";
import { useBanner } from "./hooks";
import moment from "moment";
import EditBanner from "./edit-banner";
import DeleteBanner from "./delete-banner";
function Banner() {
  const { bannerCount, filters, banners, setFilter, loadingBanners } =
    useBanner();

  const configureTableData = () => {
    try {
      let results = banners.map((banner) => {
        let newBanner = Object.assign({}, banner);
        newBanner["created_at"] = moment(banner.created_at).format(
          "DD-MM-YYYY @ hh:mm A"
        );

        newBanner["action"] = (
          <div className="d-flex justify-content-around">
            <EditBanner banner={banner} />
            <DeleteBanner banner={banner} />
          </div>
        );

        return newBanner;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DataTable
      title="Banners"
      addBtn={<AddBaner />}
      CreateTableData={configureTableData}
      skip={filters.skip}
      count={bannerCount}
      handleSetFilter={setFilter}
      isLoading={loadingBanners}
    />
  );
}

export default Banner;
