import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAddedReactionList, getRemovedReactionList } from "../../utils";
import { getSinglePost } from "../action";
import { statuses } from "../constant";

export const captionReadMoreLength = 150;

export const usePost = (props) => {
  const [readMore, setReadMore] = useState(false);
  const [isAddComment, setIsAddComment] = useState(false);
  const [isViewComments, setIsViewComments] = useState(true);
  const [totalComments, setTotalComments] = useState(0);
  const [postDetails, setPostDetails] = useState({});
  const [reactionsCountList, setReactionsCountList] = useState([]);
  const [myReaction, setMyReaction] = useState({});
  const [moreOption, setMoreOptions] = useState(false);
  const [egReaction, setEgReaction] = useState({});

  const { post_id } = useParams();
  const dispatch = useDispatch();
  const ref = useRef(null);

  const user = useSelector((state) => state.authReducer?.loggedInUser);
  const { PUBLISHED } = statuses;
  const { owner: { is_active = true } = {} } = postDetails;

  const disabled = useMemo(() => {
    return postDetails?.status !== PUBLISHED || !is_active;
  }, [postDetails.status, PUBLISHED, is_active]);

  const handleClickOutside = (e) => {
    if (ref.current && ref.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setMoreOptions(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const details = await dispatch(getSinglePost(post_id));
      if (details?.is_success) {
        setPostDetails(details?.post);
        setTotalComments(details?.post?.stats?.comments_count);
        if (details?.post?.stats?.reactions_counts?.length > 0) {
          setReactionsCountList(details?.post?.stats?.reactions_counts);
        }
        if (details?.post?.my_reaction?.id) {
          setMyReaction(details?.post?.my_reaction);
        }

        if (details?.post?.eg_reaction?.id) {
          setEgReaction(details?.post?.eg_reaction);
        }

        if (details?.post?.caption?.length > captionReadMoreLength) {
          setReadMore(true);
        }
      }
    })();
  }, [post_id, dispatch]);

  const handleReadMoreClick = () => {
    setReadMore(false);
  };

  const handleAddComment = () => {
    !disabled && setIsAddComment(!isAddComment);
  };

  const handleViewComments = () => {
    setIsViewComments(!isViewComments);
  };

  const handleTotalComments = (key, count) => {
    setTotalComments((prev) => {
      if (key === "add") {
        return prev + 1;
      } else if (prev - count > 0) {
        return prev - count;
      }

      return 0;
    });
  };

  const reactionsCount = useMemo(() => {
    if (reactionsCountList.length === 0) {
      return 0;
    }
    const { count } = reactionsCountList.reduce((a, b) => ({
      count: parseInt(a.count) + parseInt(b.count),
    }));
    return count;
  }, [reactionsCountList]);

  const handleRemoveReactSuccess = (
    _reactionId,
    selectedReaction,
    isEgReaction
  ) => {
    const reactionsList = getRemovedReactionList(
      reactionsCountList,
      selectedReaction
    );
    setReactionsCountList(reactionsList);
    isEgReaction ? setEgReaction({}) : setMyReaction({});
  };

  const handleReactSuccess = (submittedReaction, isEgReaction) => {
    const reaction = isEgReaction ? egReaction : myReaction;
    const reactionsList = getAddedReactionList(
      reactionsCountList,
      submittedReaction,
      reaction
    );
    setReactionsCountList(reactionsList);

    isEgReaction
      ? setEgReaction(submittedReaction)
      : setMyReaction(submittedReaction);
  };

  const handleMoreOptions = () => {
    setMoreOptions(!moreOption);
  };

  return {
    handleReadMoreClick,
    readMore,
    user,
    handleAddComment,
    handleViewComments,
    isAddComment,
    isViewComments,
    postDetails,
    handleTotalComments,
    totalComments,
    reactionsCount,
    handleReactSuccess,
    handleRemoveReactSuccess,
    myReaction,
    moreOption,
    handleMoreOptions,
    ref,
    reactionsCountList,
    egReaction,
    disabled,
  };
};
