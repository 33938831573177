/* eslint-disable */

import moment from "moment";

export const TRIGGER_TRACKABLE_TYPE_MAP = {
  "@": "USER",
  "#": "HASHTAG",
};
export const TRACKABLE_TYPE_TRIGGER_MAP = {
  USER: "@",
  HASHTAG: "#",
};

export const getValue = (value, ranges) => {
  if (!value) {
    return value;
  }
  if (!ranges?.length) {
    let urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    return value.replace(urlRegex, function (url) {
      return `<a style="color:black; text-decoration:underline" href="${url}" target="_blank">${url}</a>`;
    });
  }

  let mentiontext = "";
  ranges.forEach((element) => {
    const ss = mentiontext ? mentiontext : value;
    const str = value.substr(element.offset, element.length);
    const trigger = TRACKABLE_TYPE_TRIGGER_MAP[element.trackable_type];
    mentiontext = ss.replace(
      str,
      `${trigger}[${element.name}](${element.trackable_id})`
    );
  });

  return convertToHTML(mentiontext);
};

const convertToHTML = (mentiontext) => {
  let pattern =
    /(?<original>(?<trigger>.)\[(?<name>([^[]*))]\((?<id>([\d\w-]*))\))/gi;
  let arr = mentiontext?.match(pattern);

  if (!arr || arr.length === 0) {
    return mentiontext;
  }
  let str = mentiontext;
  arr.forEach((mention) => {
    let id = mention.split("(").pop().replace(")", "");
    if (mention.startsWith("@")) {
      let array = [...mention.matchAll(/@\[(.*?)]\((\d+)\)/g)];
      str = str?.replace(
        mention,
        `<a target="_blank" href='/users/${id}'>${array[0][1]}</a>`
      );
    } else {
      let array = [...mention.matchAll(/#\[(.*?)]\((\d+)\)/g)];

      str = str?.replace(
        mention,
        `<span id=${array[0][1]}>#${array[0][1]}</span>`
      );
    }
  });

  let urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  return str.replace(urlRegex, function (url) {
    return `<a style="color:black; text-decoration:underline" href="${url}" target="_blank">${url}</a>`;
  });

  // return str;
};

export const getMentionText = (caption, ranges) => {
  if (!caption || !ranges.length) {
    return caption;
  }
  let mentiontext = "";
  ranges.forEach((element) => {
    const ss = mentiontext ? mentiontext : caption;
    const str = caption.substr(element.offset, element.length);
    const trigger = element?.trackable_type === "USER" ? "@" : "#";
    mentiontext = ss.replace(
      str,
      `${trigger}[${element.name}](${element.trackable_id})`
    );
  });
  return mentiontext;
};

export const getEventStatus = (starts_at, ends_at) => {
  let isLive;
  const timeDiff = moment.utc(moment(starts_at).diff(moment()));
  if (
    moment().diff(starts_at, "minutes") >= 0 &&
    moment().diff(ends_at, "minutes") <= 0
  ) {
    isLive = "LIVE";
  }
  if (moment().diff(starts_at, "minutes") < 0) {
    isLive = moment.duration(timeDiff).asSeconds();
  }
  return isLive;
};

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};
