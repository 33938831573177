import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, Chip, MenuItem } from "@material-ui/core";
import { fieldNames, statusOptions } from "../constant";
import { DataLoader } from "../../../../../components/loader";
import { videoFormSchema } from "./validation-schema";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { useUploadFiles } from "../../../../../utils/upload-file/hooks";
import {
  fileContext,
  imageDimensions,
  initialLanguageFieldValue,
  languages,
} from "../../../../../constants";
import DatePicker from "../../../../../components/date-picker";
import ProgressChip from "../../../../../components/progress-chip";
import TextEditor from "../../../../../components/text-editor";
import { Tooltip } from "@material-ui/core";
import { setLanguage } from "../../../../../utils/auth";
import Croppier from "../../../../../components/image-croppier";

const formField = {
  title: initialLanguageFieldValue,
  description: initialLanguageFieldValue,
  category_id: "",
  status: "",
  author_id: "",
  published_at: "",
  url: "",
  video_icon: [],
  video_banner: [],
  video: [],
};

const VideoForm = ({
  fieldValues,
  handleVideoForm,
  btn,
  isLoading,
  users,
  categories,
  handleLanguageData,
  setUserFilters,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    uploadError,
    videoBannerError,
    handleDeleteAFile,
    files,
    uploadProgress,
    isFileUploadData,
    handleVideoFile,
    videoIconFileError,
    handleVideoBannerFile,
    handleVideoIconFile,
    isUploading,
    handleCropping,
  } = useUploadFiles();

  const {
    title,
    description,
    category_id,
    status,
    author_id,
    published_at,
    video_banner,
    video,
    url,
    video_icon,
    language,
  } = fieldNames;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const [videoBanner, setVideoBanner] = useState([]);
  const { blog_video_banner } = imageDimensions;

  const handleFormUpdate = async (key, value) => {
    if (key === url) {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        videoBanner: [],
        video: [],
        [key]: value,
      }));
    } else {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [key]: value,
      }));
    }
  };

  const handleLanguage = (key, language, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: {
        ...prevFieldValue[key],
        [language]: value,
      },
    }));
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={videoFormSchema}
      initialValues={{
        title: formValues?.title || initialLanguageFieldValue,
        description: formValues?.description || initialLanguageFieldValue,
        language: formValues?.language || "en",
        category_id:
          (formValues?.category_id &&
            categories.find(
              (category) => category.id === formValues?.category_id
            )) ||
          (formValues?.category_id?.id &&
            categories.find(
              (category) => category.id === formValues?.category_id?.id
            )) ||
          null,
        author_id:
          (formValues?.author_id &&
            users.find((user) => user.id === formValues?.author_id)) ||
          (formValues?.author_id?.id &&
            users.find((user) => user.id === formValues?.author_id?.id)) ||
          null,
        url: formValues?.url || "",
        published_at:
          (formValues?.published_at && new Date(formValues.published_at)) || "",

        status: formValues?.status || "",
        video_icon: files.filter(
          (file) => file.file_context === fileContext.VIDEO_ICON
        ),
        video_banner: files.filter(
          (file) => file.file_context === fileContext.VIDEO_BANNER
        ),
        video: files.filter((file) => file.file_context === fileContext.VIDEO),
      }}
      onSubmit={handleVideoForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <div style={{ minHeight: "100vh" }}>
          {isLoading ? (
            <DataLoader />
          ) : (
            <form
              className="material-form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              {!isLoading && (
                <div className="materila-form_inline-child sticky__element">
                  <TextField
                    className="material-form__field"
                    name={language}
                    placeholder="Language"
                    label="Language"
                    select
                    value={rest.values.language}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      if (!rest.values.title[value]) {
                        handleLanguage(title, value, "");
                      }
                      if (!rest.values.description[value]) {
                        handleLanguage(description, value, "");
                      }
                      handleFormValueChange(rest, language);
                      handleFormUpdate(name, value);
                      if (btn === "Save") {
                        setLanguage(value);
                        handleLanguageData(rest, setFormvalues);
                      }
                    }}
                    onFocus={() => handleFocusField(language)}
                    onBlur={() => handleBlurField()}
                    defaultValue={rest.values.language}
                  >
                    {languages.map((language) => (
                      <MenuItem
                        key={language.id}
                        className="material-form__option"
                        value={language.value}
                      >
                        {language.displayText}
                      </MenuItem>
                    ))}
                  </TextField>
                  {focusField === language ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={language}
                    />
                  )}
                </div>
              )}
              <div className="material-form_inline-fields d-flex align-items-center">
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={title}
                    placeholder="Title"
                    label="Title"
                    type="text"
                    value={rest.values.title[rest.values.language]}
                    onChange={(event) => {
                      handleFormValueChange(rest, title);
                      handleLanguage(
                        title,
                        rest.values.language,
                        event.target.value
                      );
                    }}
                    onFocus={() => handleFocusField(title)}
                    onBlur={() => handleBlurField()}
                  />
                  {focusField === title ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={title}
                    />
                  )}
                </div>
                <div className="col-md-2 form__language">
                  <Tooltip title="Language" placement="bottom">
                    <div>
                      {
                        languages.find(
                          (lang) => lang.value === rest.values.language
                        ).displayText
                      }
                    </div>
                  </Tooltip>
                </div>
              </div>

              <div className="video__container">
                <div className="material-form_inline-fields d-flex align-items-center">
                  <div className="materila-form_inline-child">
                    <p className="custom__title_video">Description</p>
                  </div>
                  <div className="col-md-2 form__language">
                    <Tooltip title="Language" placement="bottom">
                      <div>
                        {
                          languages.find(
                            (lang) => lang.value === rest.values.language
                          ).displayText
                        }
                      </div>
                    </Tooltip>
                  </div>
                </div>

                <TextEditor
                  initialValues={
                    rest.values.description[rest.values.language] || ""
                  }
                  onChange={(value) => {
                    handleLanguage("description", rest.values.language, value);
                  }}
                  language={rest.values.language}
                />
                {focusField === description ? null : (
                  <>
                    <br />
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={description}
                    />
                  </>
                )}
              </div>

              <div className="matrial-form material-form_inline-fields">
                <div className="materila-form_inline-child">
                  <Autocomplete
                    options={categories}
                    getOptionLabel={(category) =>
                      category.title ? category.title : ""
                    }
                    value={rest.values.category_id}
                    name={category_id}
                    onChange={(event, value) => {
                      setFieldValue(category_id, value);
                      handleFormUpdate(category_id, value);
                    }}
                    onFocus={() => handleFocusField(category_id)}
                    onBlur={() => handleBlurField()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="material-form__field"
                        name={category_id}
                        placeholder="Select Category"
                        label="Select Category"
                        variant="standard"
                      />
                    )}
                  />
                  {focusField === category_id ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={category_id}
                    />
                  )}
                </div>

                <div className="materila-form_inline-child">
                  <Autocomplete
                    options={users}
                    getOptionLabel={(user) => (user.name ? user.name : "")}
                    value={rest.values.author_id}
                    name={author_id}
                    onChange={(event, value) => {
                      setFieldValue(author_id, value);
                      handleFormUpdate(author_id, value);
                      !value && setUserFilters("");
                    }}
                    onFocus={() => handleFocusField(author_id)}
                    onBlur={() => handleBlurField()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="material-form__field"
                        name={author_id}
                        placeholder="Select Author"
                        label="Select Author"
                        variant="standard"
                        onChange={(e) => setUserFilters(e.target.value)}
                      />
                    )}
                  />
                  {focusField === author_id ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={author_id}
                    />
                  )}
                </div>
              </div>

              {rest.values?.video.length === 0 &&
                rest.values?.video_banner.length === 0 && (
                  <div>
                    <TextField
                      className="material-form__field"
                      name={url}
                      placeholder="YouTube URL"
                      label="YouTube URL"
                      type="text"
                      value={rest.values.url}
                      onChange={(event) => {
                        handleFormValueChange(rest, url);
                        handleFormUpdate(url, event.target.value);
                      }}
                      onFocus={() => handleFocusField(url)}
                      onBlur={() => handleBlurField()}
                    />
                    {focusField === url ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={url}
                      />
                    )}
                  </div>
                )}
              <div className="matrial-form material-form_inline-fields">
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={status}
                    placeholder="Status"
                    label="Select Status"
                    select
                    value={rest.values.status}
                    onChange={(event) => {
                      handleFormValueChange(rest, status);
                      handleFormUpdate(status, event.target.value);
                    }}
                    onFocus={() => handleFocusField(status)}
                    onBlur={() => handleBlurField()}
                    defaultValue={rest.values.status}
                  >
                    {statusOptions.map((status) => (
                      <MenuItem
                        key={status.id}
                        className="material-form__option"
                        value={status.value}
                      >
                        {status.displayText}
                      </MenuItem>
                    ))}
                  </TextField>
                  {focusField === status ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={status}
                    />
                  )}
                </div>

                <div className="materila-form_inline-child">
                  <DatePicker
                    selectedDate={rest.values.published_at}
                    handleChange={(value) => {
                      setFieldValue(published_at, value);
                      handleFormUpdate(published_at, value);
                    }}
                    id="published_at"
                    name={published_at}
                    label="Published At"
                    customInput={
                      <TextField
                        className="material-form__field"
                        id="published_at"
                        label="Published At"
                        autoComplete="off"
                      />
                    }
                  />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={published_at}
                  />
                </div>
              </div>

              {!rest.values.url && (
                <div className="form__form-group-field form__margin-bottom">
                  <div className="form__form-group-input-wrap">
                    <div className="form__form-group-file">
                      <label htmlFor="video">Choose Video</label>
                      {files
                        .filter(
                          (file) => file.file_context === fileContext.VIDEO
                        )
                        .map((x) => {
                          return (
                            <Chip
                              className="selected-file"
                              key={x.uuid_file_name}
                              label={x.original_file_name}
                              onDelete={() => handleDeleteAFile(x)}
                              variant="outlined"
                            />
                          );
                        })}
                      {isFileUploadData
                        .filter(
                          (file) => file.fileContext === fileContext.VIDEO
                        )
                        .map((x, i) => {
                          return (
                            <div key={i}>
                              {x.isUpload && (
                                <ProgressChip
                                  fileName={x.fileName}
                                  uploadProgress={uploadProgress}
                                />
                              )}
                            </div>
                          );
                        })}

                      <input
                        accept="video/mp4,video/x-m4v,video/*"
                        onBlur={rest.handleBlur}
                        type="file"
                        name={video}
                        id="video"
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        onChange={(event) => {
                          handleVideoFile(event.currentTarget.files[0]);
                          setFieldValue(
                            video,
                            files.filter(
                              (file) => file.file_context === fileContext.VIDEO
                            )
                          );
                        }}
                      />
                    </div>

                    {uploadError ? (
                      <div className="material-form-error-image">
                        {uploadError}
                      </div>
                    ) : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form-error-image">{msg}</div>
                        )}
                        name={video}
                      />
                    )}
                  </div>
                </div>
              )}

              <div className="form__form-group-field form__margin-bottom">
                <div className="form__form-group-input-wrap">
                  <div className="form__form-group-file">
                    <label htmlFor="video_banner">Choose Banner</label>
                    <Tooltip
                      title="Preferred size is 375px * 300px"
                      placement="bottom"
                    >
                      <i className="fa fa-info-circle icon-file-info mt-1"></i>
                    </Tooltip>
                    <br />
                    {files
                      .filter(
                        (file) => file.file_context === fileContext.VIDEO_BANNER
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}
                    {isFileUploadData
                      .filter(
                        (file) => file.fileContext === fileContext.VIDEO_BANNER
                      )
                      .map((x, i) => {
                        return (
                          <div key={i}>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </div>
                        );
                      })}
                    <input
                      accept="image/*"
                      multiple
                      type="file"
                      name={video_banner}
                      id="video_banner"
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      onChange={(event) => {
                        handleCropping(
                          event.currentTarget.files,
                          setFieldValue,
                          video_banner,
                          fileContext.VIDEO_BANNER,
                          handleVideoBannerFile,
                          videoBanner,
                          setVideoBanner
                        );
                      }}
                    />
                  </div>

                  {videoBannerError || uploadError ? (
                    <div className="material-form-error-image">
                      {videoBannerError || uploadError}
                    </div>
                  ) : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form-error-image">{msg}</div>
                      )}
                      name={video_banner}
                    />
                  )}
                </div>
              </div>

              {videoBanner?.length > 0 &&
                videoBanner?.map((banner, i) => (
                  <div key={i}>
                    <Croppier
                      image={banner}
                      setFieldValue={setFieldValue}
                      name={video_banner}
                      context={fileContext.VIDEO_BANNER}
                      dimensions={blog_video_banner}
                      handleUploadFile={handleVideoBannerFile}
                      setCurrentBanner={setVideoBanner}
                      index={i}
                    />
                  </div>
                ))}

              <div className="form__form-group-field form__margin-bottom">
                <div className="form__form-group-input-wrap">
                  <div className="form__form-group-file">
                    <label htmlFor="video_icon">Choose Icon</label>
                    {files
                      .filter(
                        (file) => file.file_context === fileContext.VIDEO_ICON
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}

                    {isFileUploadData
                      .filter(
                        (file) => file.fileContext === fileContext.VIDEO_ICON
                      )
                      .map((x, i) => {
                        return (
                          <div key={i}>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </div>
                        );
                      })}

                    <input
                      accept="image/x-png"
                      onBlur={rest.handleBlur}
                      type="file"
                      name={video_icon}
                      id="video_icon"
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      onChange={(event) => {
                        handleVideoIconFile(event.currentTarget.files[0]);
                        setFieldValue(
                          video_icon,
                          files.filter(
                            (file) =>
                              file.file_context === fileContext.VIDEO_ICON
                          )
                        );
                      }}
                    />
                  </div>
                  {videoIconFileError || uploadError ? (
                    <div className="material-form-error-image">
                      {videoIconFileError || uploadError}
                    </div>
                  ) : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form-error-image">{msg}</div>
                      )}
                      name={video_icon}
                    />
                  )}
                </div>
              </div>

              <button
                disabled={isUploading}
                type="submit"
                className="btn btn-success btn-right btn-sm"
              >
                {btn}
              </button>
            </form>
          )}
        </div>
      )}
    </Formik>
  );
};

export default VideoForm;
