import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import Modals from "../../../../components/modal";
import TestimonialForm from "../form/testimonial-form";
import { useEditTestimonial } from "./hooks";

const Testimonial = ({ testimonial }) => {
  const {
    editTestimonialLoading,
    handleEditTestimonial,
    isOpenModal,
    toggleModal,
    getTestimonialFieldData,
    selectedTestimonial,
  } = useEditTestimonial(testimonial);

  return (
    <>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action `}
          onClick={getTestimonialFieldData}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <TestimonialForm
              fieldValues={selectedTestimonial}
              handleTestinialForm={handleEditTestimonial}
              btn="Save"
              isLoading={editTestimonialLoading}
            />
          }
          title="Edit Testimonial"
        />
      ) : null}
    </>
  );
};

export default Testimonial;
