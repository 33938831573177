import React from "react";
import ScheduleEventForm from "../form/schedule-form";
import { useAddScheduleEvent } from "./hooks";
import Modals from "../../../components/modal";
import { Button } from "reactstrap";
import { useUser } from "../../../common-hooks/user";

const AddScheduleEvent = (props) => {
  const {
    addScheduleEventLoading,
    handleAddScheduleEvent,
    isOpenModal,
    toggleModal,
    events,
    users,
  } = useAddScheduleEvent(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          toggleModal(!isOpenModal);
        }}
      >
        <i className="fa fa-plus"></i> Schedule
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <ScheduleEventForm
              handleEventForm={handleAddScheduleEvent}
              btn="Schedule"
              events={events}
              users={users}
              isLoading={addScheduleEventLoading}
              handleEventsFilter={props.handleEventsFilter}
            />
          }
          title="Schedule Event"
        />
      ) : null}
    </div>
  );
};

export default AddScheduleEvent;
