import React from "react";
import { useDeleteComment } from "./hooks";
import DeleteConfirmation from "../../../../../components/delete-confirmation";
import Modals from "../../../../../components/modal";
import ActionButton from "../../../../../components/action-button";
import { useUser } from "../../../../../common-hooks/user";

const DeleteComment = (props) => {
  const {
    isOpenModal,
    toggleModal,
    handleDeleteComment,
    deleteCommentLoading,
    comment,
  } = useDeleteComment(props);

  const { isAdmin, id } = useUser();

  if (
    id === parseInt(process.env.REACT_APP_EGC_ID) ||
    (id === comment?.created_by && isAdmin)
  ) {
    return (
      <>
        <ActionButton
          title="Delete"
          className={props.className}
          iconClassName="fa fa-trash icon-btn-action text-danger"
          onClick={toggleModal}
        />

        {isOpenModal ? (
          <Modals
            isOpenModal
            setIsOpenModal={toggleModal}
            message={
              <DeleteConfirmation
                message="Are you sure you want to delete?"
                handleDelete={handleDeleteComment}
                isLoading={deleteCommentLoading}
              />
            }
            title="Delete Comment"
          />
        ) : null}
      </>
    );
  }

  return null;
};

export default DeleteComment;
