import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import BaseReactTable from "../../shared/components/table/BaseReactTable";
import { TableLoader } from "../loader";

const DataTable = ({
  title,
  addBtn,
  searchPlaceholder,
  CreateTableData,
  withFilter,
  filter,
  handleSetFilter,
  count,
  skip,
  isLoading,
}) => {
  const reactTableData = CreateTableData();

  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: false,
    withPagination: true,
    withSearchEngine: false,
    withManualPageSize: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: searchPlaceholder,
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper mb-3">
            <div className="card__title card__title__flex ">
              <h3 className="bold-text">{title}</h3>
              <div className="add-btn">{addBtn}</div>
            </div>
            <div className="d-lg-flex d-md-flex">
              {withFilter ? filter() : null}
            </div>
          </div>
          {isLoading ? (
            <TableLoader />
          ) : (
            <BaseReactTable
              columns={reactTableData.tableHeaderData}
              data={reactTableData.tableRowsData}
              tableConfig={tableConfig}
              handleSetFilter={handleSetFilter}
              count={count}
              skip={skip}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default DataTable;
