import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { MenuItem, TextField } from "@material-ui/core";
import { labelFieldsName } from "../constant";
import { DataLoader } from "../../../../../components/loader";
import { formSchema } from "./validation-schema";
import SketchColorPickerField from "../../../../../components/color-picker";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { initialLanguageFieldValue, languages } from "../../../../../constants";
import { Tooltip } from "@material-ui/core";
import { setLanguage } from "../../../../../utils/auth";

const formField = {
  title: initialLanguageFieldValue,
  bg_color_code: "",
  text_color_code: "",
  language: "en",
};

const LabelForm = ({
  fieldValues,
  handleLabelForm,
  btn,
  isLoading,
  handleLanguageData,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const { title, bg_color_code, text_color_code, language } = labelFieldsName;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));
  };

  const handleLanguage = (key, language, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: {
        ...prevFieldValue[key],
        [language]: value,
      },
    }));
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={formSchema}
      initialValues={{
        title: formValues?.title || initialLanguageFieldValue,
        bg_color_code: formValues?.bg_color_code || "#f8e71c",
        text_color_code: formValues?.text_color_code || "#000000",
        language: formValues?.language || "en",
      }}
      onSubmit={handleLabelForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={language}
                placeholder="Language"
                label="Language"
                select
                value={rest.values.language}
                onChange={(event) => {
                  const { name, value } = event.target;
                  handleFormValueChange(rest, name);
                  handleFormUpdate(name, value);
                  handleFormValueChange(rest, name);
                  if (!rest.values.title[value]) {
                    handleLanguage(title, value, "");
                  }
                  if (btn === "Save") {
                    setLanguage(value);
                    handleLanguageData(rest, setFormvalues);
                  }
                }}
                onFocus={() => handleFocusField(language)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.language}
              >
                {languages.map((language) => (
                  <MenuItem
                    key={language.id}
                    className="material-form__option"
                    value={language.value}
                  >
                    {language.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === language ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={language}
                />
              )}
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  type="text"
                  value={rest.values.title[rest.values.language]}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    handleLanguage(name, rest.values.language, value);
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div>
              <SketchColorPickerField
                value={rest?.values?.bg_color_code}
                onChange={(backgroundColor) => {
                  handleFormValueChange(rest, bg_color_code);
                  handleFormUpdate(bg_color_code, backgroundColor);
                }}
                name={bg_color_code}
                label={"Background Color"}
              />
            </div>
            <br />
            <div>
              <SketchColorPickerField
                value={rest?.values?.text_color_code}
                onChange={(backgroundColor) => {
                  handleFormValueChange(rest, text_color_code);
                  handleFormUpdate(text_color_code, backgroundColor);
                }}
                name={text_color_code}
                label={"Text Color"}
              />
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default LabelForm;
