export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Key",
    accessor: "key",
  },
  {
    Header: "Value",
    accessor: "value",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];

export const settingsMenu = [
  {
    id: 1,
    displayText: "Referral Bonus Session",
    value: "BONUS_REFERRAL_SESSIONS",
  },
  {
    id: 2,
    displayText: "Age Limit",
    value: "AGE_LIMIT",
  },
];

export const fieldNames = {
  key: "key",
  value: "value",
  description: "description",
};
