import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLanguage } from "../../../../utils/auth";
import { setSectionFilter, getSectionList } from "./actions";
import { getSections, getSectionFilter, getSectionsCount } from "./selectors";

export const useSections = () => {
  const dispatch = useDispatch();
  const sections = useSelector(getSections);
  const filters = useSelector(getSectionFilter);
  const sectionCount = useSelector(getSectionsCount);
  const [loadingSections, setLoadingSections] = useState(false);

  useEffect(() => {
    clearLanguage();
    const fetchSections = async () => {
      setLoadingSections(true);
      await dispatch(getSectionList(filters));
      setLoadingSections(false);
    };
    fetchSections();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setSectionFilter({ key, value }));
  };

  return {
    setFilter,
    loadingSections,
    filters,
    sections,
    sectionCount,
  };
};
