import * as Yup from "yup";
import { checkFields } from "../../../utils/validation";

const requiredFields = ["title", "description"];

export const eventFormSchema = Yup.object().shape({
  category: Yup.string().required("Category is required").nullable(),
  subcategory: Yup.string().required("Subcategory is required").nullable(),
  title: Yup.mixed().test(
    "Title required",
    "Enter valid title for all languages",
    function () {
      const {
        title: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "title");
    }
  ),
  description: Yup.mixed().test(
    "Description required",
    "Enter valid description for all languages",
    function () {
      const {
        description: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "description");
    }
  ),
  status: Yup.string().required("Status is required"),
  is_popular: Yup.boolean(),
  filePopularBanner: Yup.array().when("is_popular", {
    is: true,
    then: Yup.array().required("File Popular Banner is required"),
  }),
  filePopularBannerWeb: Yup.array().when("is_popular", {
    is: true,
    then: Yup.array().required("File Popular Banner Web is required"),
  }),
  faq_category: Yup.array(),
  fileBanner: Yup.array().required("File Banner is required"),
  videos: Yup.array(),
  type: Yup.string().required("Type is required"),
  requirements: Yup.object(),
  video_banners: Yup.mixed().when("videos", {
    is: (val) => val,
    then: Yup.mixed().test(
      "Should equal to videos",
      "You must add one banner to each video",
      function () {
        const { videos, video_banners } = this.parent;

        return videos.length === video_banners.length;
      }
    ),
  }),
  slug: Yup.string().required("Slug is required"),
  exceeds_percentage: Yup.number()
    .required("Threshold % for changing Displayed Seats Count is required")
    .min(1, "This field should have non zero value.")
    .test(
      "is-percentage",
      "Value must not be greater than 100",
      function (exceeds_percentage) {
        return exceeds_percentage <= 100;
      }
    ),
  increment_percentage: Yup.number()
    .required("% for updating new Displayed Seats Count is required")
    .min(1, "This field should have non zero value.")
    .test(
      "is-percentage",
      "Value must not be greater than 100",
      function (increment_percentage) {
        return increment_percentage <= 100;
      }
    ),
  attendees_limit: Yup.number()
    .min(5, "Minimum Optimum Batch Size is 5.")
    .required("Optimum Batch Size is required"),

  current_attendees_limit: Yup.number()
    .min(1, "Minimum Displayed Count is 1")
    .required("Displayed Seats Count limit is required")
    .test(
      "is-less-than",
      "Displayed Seats Count limit must be less than Optimum Batch Size",
      function () {
        const { current_attendees_limit, attendees_limit } = this.parent;
        return current_attendees_limit <= attendees_limit;
      }
    ),
  broadcast_mins: Yup.number()
    .required("View Live Session is required")
    .min(1, "This field should have non zero value."),

  after_popup_broadcast_mins: Yup.number()
    .required("Rewatch Live Session is required")
    .min(1, "This field should have non zero value.")
    .test(
      "is-less-than",
      "Rewatch Live Sessiom must be less than View Live Session",
      function () {
        const { broadcast_mins, after_popup_broadcast_mins } = this.parent;
        return after_popup_broadcast_mins < broadcast_mins;
      }
    ),
  max_join_broadcast_attempts: Yup.number()
    .required("Number of Times Second Pop-Up Shows value is required")
    .min(1, "This field should have non zero value."),
});
