import {
  SET_PLAN_LIST,
  SET_PLAN_FILTER,
  RESET_PLAN_FILTER,
  SET_PARTNER_OFFER_FILTER,
  SET_PARTNER_OFFER_LIST,
} from "./actions";

const initialList = {
  plans: [],
  count: 0,
  filter: {
    status: "Published",
    limit: 10,
    skip: 0,
  },
  partner_offers: [],
  partner_offer_filter: {
    partner_ids: [],
    name: "",
    status: "Published",
    limit: 1000,
    skip: 0,
  },
};

const planReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_PLAN_LIST:
      return {
        ...state,
        plans: action.payload.plans,
        count: action.payload.count || [],
      };
    case SET_PARTNER_OFFER_LIST:
      return {
        ...state,
        partner_offers: action.payload.partner_offers,
      };
    case SET_PLAN_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case SET_PARTNER_OFFER_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        partner_offer_filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case RESET_PLAN_FILTER:
      return { ...state, filter: initialList.filter || [] };
    default:
      return state;
  }
};

export default planReducer;
