import React from "react";
import DataTable from "../../components/table";
import { columns, partnerStatus } from "./constant";
import { usePartners } from "./hooks";
import SearchFilter from "../../components/filters/search-filter";
import SelectFilter from "../../components/filters/select-filter";
import Tooltip from "@material-ui/core/Tooltip";
import AddPartner from "./add-partner";
import EditPartner from "./edit-partner";

function Partners() {
  const {
    filters,
    loadingPartner,
    partners,
    partnersCount,
    resetFilter,
    setFilter,
  } = usePartners();

  const configureTableData = () => {
    try {
      let results = partners.map((partner) => {
        let newPartner = Object.assign({}, partner);
        newPartner["strip_color_code"] = (
          <div
            style={{
              backgroundColor: partner.strip_color_code,
              display: "inline-block",
              padding: "5px",
              color: "#111",
            }}
          >
            {partner.strip_color_code}
          </div>
        );
        newPartner["action"] = <EditPartner partner={partner} />;

        return newPartner;
      });

      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const partnerFilter = () => (
    <>
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Brand Name"
        filterKey="name"
        filterTitle="Brand Name"
        searchedValue={filters?.name}
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        filterKey="status"
        options={partnerStatus}
        defaultOption="All"
        filterTitle="Status"
      />
      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={resetFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  return (
    <DataTable
      title="Partners"
      addBtn={<AddPartner />}
      CreateTableData={configureTableData}
      withFilter={true}
      filter={partnerFilter}
      handleSetFilter={setFilter}
      count={partnersCount}
      skip={filters?.skip}
      isLoading={loadingPartner}
    />
  );
}

export default Partners;
