import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSubategory, getSubategoryList } from "../actions";
import { clearLanguage } from "../../../../utils/auth";

export const useAddSubcategory = ({ category_id, category_type }) => {
  const dispatch = useDispatch();

  const [addSubcategoryLoading, setAddSubategoryLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { filter } = useSelector(
    ({ subCategoryReducer }) => subCategoryReducer
  );

  const handleAddSubcategory = async (values) => {
    const payload = {
      sub_category: {
        category_id: parseInt(category_id),
        title: values.title,
        description: values.description,
        type: values.type,
        status: values.status,
      },
    };
    setAddSubategoryLoading(true);
    const is_success = await dispatch(addSubategory(payload));
    setAddSubategoryLoading(false);

    if (is_success) {
      await dispatch(getSubategoryList(category_id, filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    category_type,
    handleAddSubcategory,
    addSubcategoryLoading,
    isOpenModal,
    toggleModal,
  };
};
