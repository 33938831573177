export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Type",
    accessor: "type",
    disableGlobalFilter: true,
    // width: 60,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    // width: 60,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 30,
  },
];

export const eventStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
];

export const isPopularOptions = [
  {
    id: 1,
    value: "true",
    displayText: "Yes",
  },
  {
    id: 2,
    value: "false",
    displayText: "No",
  },
];

export const fieldOptions = [
  {
    id: 1,
    value: "blog_id",
    displayText: "Blog",
  },
  {
    id: 2,
    value: "category_id",
    displayText: "Category",
  },
];

export const modelBlogOptions = [
  {
    id: 1,
    value: "id",
    displayText: "Id",
  },
  {
    id: 2,
    value: "category_id",
    displayText: "Cateogory Id",
  },
  {
    id: 3,
    value: "published_at",
    displayText: "Published At",
  },
  {
    id: 4,
    value: "is_popular",
    displayText: "Is Popular",
  },
  {
    id: 5,
    value: "related_blog",
    displayText: "Related Blog",
  },
  {
    id: 6,
    value: "user_interest",
    displayText: "User Interest",
  },
  {
    id: 7,
    value: "exclude_id",
    displayText: "Exclude Id",
  },
];

export const modelEventOptions = [
  {
    id: 1,
    value: "related_event",
    displayText: "Related Event",
  },
];

export const modelCategoryOptions = [
  {
    id: 1,
    value: "id",
    displayText: "Id",
  },
];

export const modelVideoOptions = [
  {
    id: 1,
    value: "id",
    displayText: "Id",
  },
  {
    id: 2,
    value: "category_id",
    displayText: "Category Id",
  },
];

export const sectionFieldsName = {
  title: "title",
  type: "type",
  status: "status",
  model: "model",
  criteria: "criteria",
  field: "field",
  op: "op",
  value: "value",
  language: "language",
};

export const allOperatorOptions = [
  {
    id: 1,
    value: "in",
    displayText: "In",
  },
  {
    id: 2,
    value: "equals",
    displayText: "Equals",
  },
];

export const onlyEqualOperatorOption = [
  {
    id: 1,
    value: "equals",
    displayText: "Equals",
  },
];

export const sectionTypeOptions = [
  {
    id: 1,
    value: "card",
    displayText: "Card",
  },
  {
    id: 2,
    value: "horizontalCards",
    displayText: "Horizontal Cards",
  },
  {
    id: 3,
    value: "horizontalEventCards",
    displayText: "Horizontal Event Cards",
  },
  {
    id: 4,
    value: "horizontalRoundedCards",
    displayText: "Horizontal Rounded Cards",
  },
  {
    id: 5,
    value: "horizontalSquareCards",
    displayText: "Horizontal Square Cards",
  },
  {
    id: 6,
    value: "verticalCards",
    displayText: "Vertical Cards",
  },
  {
    id: 7,
    value: "videoCards",
    displayText: "Video Cards",
  },
  {
    id: 8,
    value: "horizontalVideoCards",
    displayText: "Horizontal Video Cards",
  },
];

export const statusOptions = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
  {
    id: 3,
    value: "In Active",
    displayText: "In Active",
  },
];

export const modelOptions = [
  {
    id: 1,
    value: "Blog",
    displayText: "Blog",
  },
  {
    id: 2,
    value: "Event",
    displayText: "Event",
  },
  {
    id: 3,
    value: "Category",
    displayText: "Category",
  },
  {
    id: 4,
    value: "Video",
    displayText: "Video",
  },
];
