import React from "react";
import { useRouteMatch } from "react-router";
import DataTable from "../../../components/table";
import SelectFilter from "../../../components/filters/select-filter";
import { useSubcategory } from "./hooks";
import { columns, subcategoryStatus } from "./constants";
import { Loader } from "../../../components/loader";
import AddSubcategory from "./add-subcategories";
import EditSubcategory from "./edit-subcategories";
import DeleteSubcategory from "./delete-categories";
import { useUser } from "../../../common-hooks/user";

const Subcategories = () => {
  const match = useRouteMatch("/category/:category_id");
  const {
    loadingSubcategory,
    subcategories,
    category_id,
    category,
    subcategoryCount,
    filters,
    setFilter,
  } = useSubcategory({ match });

  const { isCSSpecialist } = useUser();

  const configureTableData = () => {
    try {
      let results = subcategories.map((subcategory) => {
        let newCategory = Object.assign({}, subcategory);
        newCategory["action"] = (
          <div className="categories__list-action" key={subcategory?.id}>
            <EditSubcategory
              subcategory={subcategory}
              category_id={category_id}
            />

            <DeleteSubcategory id={subcategory?.id} category_id={category_id} />
          </div>
        );

        return newCategory;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {}
  };

  const subcategoryFilter = () => (
    <SelectFilter
      handleSelect={setFilter}
      selectedValue={filters?.status}
      filterKey="status"
      options={subcategoryStatus}
      filterTitle="Status"
    />
  );

  return (
    <div>
      {loadingSubcategory ? (
        <Loader />
      ) : (
        <DataTable
          title={`Subcategories (${category?.id}. ${category?.title})`}
          addBtn={
            !isCSSpecialist ? (
              <AddSubcategory
                category_id={category_id}
                category_type={category?.type}
              />
            ) : null
          }
          CreateTableData={configureTableData}
          withFilter={true}
          filter={subcategoryFilter}
          handleSetFilter={setFilter}
          count={subcategoryCount}
          skip={filters?.skip}
        />
      )}
    </div>
  );
};

export default Subcategories;
