import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, Chip, MenuItem } from "@material-ui/core";

import { bannerFormSchema } from "./validation-schema";
import { fieldsName, landingPageOptions, bannerPlatform } from "../constant";
import { DataLoader } from "../../../../components/loader";
import { useUploadFiles } from "../../../../utils/upload-file/hooks";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../utils/form";
import { fileContext } from "../../../../constants";
import ProgressChip from "../../../../components/progress-chip";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Tooltip from "@material-ui/core/Tooltip";
import { useBannerForm } from "./hooks";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "../../../../components/date-picker";

import moment from "moment";

const BannerForm = ({ fieldValues, handleBannerForm, btn, isLoading }) => {
  const {
    uploadError,
    fileHomePageBannerError,
    handleHomePageBannerFile,
    files,
    isFileUploadData,
    uploadProgress,
    handleDeleteAFile,
  } = useUploadFiles();

  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    formValues,
    updateDraggableData,
    handleFormUpdate,
    handleAddBanner,
    bannerError,
    getScreenName,
    setBannerError,
    handleDeleteBanner,
    events,
    blogs,
    setEventListFilter,
    setSearchText,
  } = useBannerForm({ fieldValues, handleBannerForm, btn, isLoading });

  const {
    platform,
    screen,
    banner_file,
    banners,
    landing_page,
    event_date,
    event,
    blog,
  } = fieldsName;

  return (
    <Formik
      validationSchema={bannerFormSchema}
      validateOnMount
      enableReinitialize
      initialValues={{
        platform: formValues?.platform || "",
        position: "Top",
        screen: "Home",
        banners: formValues?.banners.length > 0 ? formValues?.banners : [],
        landing_page: formValues?.landing_page || "",
        event: formValues?.event || "",
        blog: formValues?.blog || "",
        event_date: (formValues?.event_date && formValues?.event_date) || "",
        blog_date: (formValues?.blog_date && formValues?.blog_date) || "",
        banner_file: files.filter(
          (file) => file.file_context === fileContext.HOME_PAGE_BANNER
        ),
      }}
      onSubmit={handleBannerForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={platform}
                  placeholder="Platform"
                  label="Platform"
                  select
                  value={rest.values.platform}
                  onChange={(event) => {
                    handleFormValueChange(rest, platform);
                    const { name, value } = event.target;
                    handleFormUpdate(name, value);
                  }}
                  onFocus={() => handleFocusField(platform)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.platform}
                >
                  {bannerPlatform.map((platform) => (
                    <MenuItem
                      key={platform.id}
                      className="material-form__option"
                      value={platform.value}
                    >
                      {platform.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === platform ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={platform}
                  />
                )}
              </div>
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={screen}
                  select
                  placeholder="Screen"
                  label="Screen"
                  type="text"
                  value={rest.values.screen}
                  onChange={(event) => {
                    handleFormValueChange(rest, screen);
                    const { name, value } = event.target;
                    handleFormUpdate(name, value);
                  }}
                  onFocus={() => handleFocusField(screen)}
                  onBlur={() => handleBlurField()}
                >
                  {landingPageOptions.map(
                    (page) =>
                      page.displayText === "Home Page" && (
                        <MenuItem
                          key={page.id}
                          className="material-form__option"
                          value={page.value}
                        >
                          {page.displayText}
                        </MenuItem>
                      )
                  )}
                </TextField>
                {focusField === screen ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={screen}
                  />
                )}
              </div>
            </div>

            <div className="form__inline-items">
              <div className="col-11 event_left_pad0">
                <p className="bold-text">Banners</p>
              </div>
            </div>

            <span className="banner-form__dragbale-content">
              <DragDropContext onDragEnd={updateDraggableData}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {rest.values.banners.map((x, i) => (
                        <Draggable
                          key={x?.file?.uuid_file_name}
                          draggableId={x?.file?.uuid_file_name}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="d-flex justify-content-between align-items-center"
                            >
                              {x?.redirect_to_url ? (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={x?.redirect_to_url || "#"}
                                >
                                  <div className="mb-3">
                                    <p className="mr-3">{`${
                                      i + 1
                                    }.${getScreenName(x?.redirect_to)}`}</p>
                                    <Chip
                                      className="selected-file mt-1"
                                      key={x?.file?.uuid_file_name}
                                      label={`${x?.file?.original_file_name}`}
                                      variant="outlined"
                                    />
                                  </div>
                                </a>
                              ) : (
                                <div className="mb-3">
                                  <p className="mr-3">{`${
                                    i + 1
                                  }.${getScreenName(x?.redirect_to)} ${
                                    x?.event?.title
                                      ? `(${x?.event?.title})`
                                      : ""
                                  } ${
                                    x?.blog?.title ? `(${x?.blog?.title})` : ""
                                  }`}</p>
                                  <Chip
                                    className="selected-file mt-1"
                                    key={x?.file?.uuid_file_name}
                                    label={`${x?.file?.original_file_name}`}
                                    variant="outlined"
                                  />
                                </div>
                              )}

                              <div>
                                <Tooltip
                                  title="Delete"
                                  placement="bottom"
                                  className=""
                                >
                                  <i
                                    className={`fa fa-trash icon-btn-action text-danger`}
                                    onClick={() => handleDeleteBanner(x)}
                                  ></i>
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </span>

            <div className="material-form_inline-fields align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={landing_page}
                  select
                  placeholder="Landing Page"
                  label="Landing Page"
                  type="text"
                  value={rest.values.landing_page}
                  onChange={(event) => {
                    handleFormValueChange(rest, landing_page);
                    const { name, value } = event.target;
                    handleFormUpdate(name, value);
                  }}
                  onFocus={() => handleFocusField(landing_page)}
                  onBlur={() => handleBlurField()}
                >
                  {landingPageOptions.map(
                    (page) =>
                      page.displayText !== "Home Page" && (
                        <MenuItem
                          key={page.id}
                          className="material-form__option"
                          value={page.value}
                        >
                          {page.displayText}
                        </MenuItem>
                      )
                  )}
                </TextField>
              </div>

              <div className="col-1 event_right_pad0">
                <Tooltip title="Add" placement="bottom" className="">
                  <i
                    className={`fa fa-plus icon-btn-action text-success`}
                    onClick={() => handleAddBanner(rest)}
                  ></i>
                </Tooltip>
              </div>
            </div>

            {rest.values.landing_page === "EventDetail" && (
              <>
                <div className="materila-form_inline-child">
                  <DatePicker
                    selectedDate={rest.values.event_date}
                    handleChange={(value) => {
                      setFieldValue(event_date, value);
                      handleFormUpdate(event_date, value);
                      handleFormUpdate(event, "");
                      setEventListFilter(
                        event_date,
                        moment(value).format("DD-MM-YYYY")
                      );
                    }}
                    id={event_date}
                    name={event_date}
                    label="Event Date"
                    customInput={
                      <TextField
                        className="material-form__field"
                        id="event_date"
                        label="Event Date"
                        autoComplete="off"
                      />
                    }
                  />
                </div>
                <div>
                  <Autocomplete
                    options={events}
                    getOptionLabel={(event) => (event.title ? event.title : "")}
                    getOptionSelected={(section) =>
                      section.title ? section.title : ""
                    }
                    value={rest.values.event}
                    onChange={(e, value) => {
                      setFieldValue(e, value);
                      handleFormUpdate(event, value);
                      setBannerError("");
                    }}
                    onFocus={() => handleFocusField(event)}
                    onBlur={() => {
                      handleBlurField();
                      //rest.handleBlur();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="material-form__field"
                        name={event}
                        placeholder="Select Event"
                        label="Select Event"
                        variant="standard"
                      />
                    )}
                  />
                  {focusField === event ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={event}
                    />
                  )}
                </div>
              </>
            )}

            {rest.values.landing_page === "BlogDetail" && (
              <>
                <div>
                  <Autocomplete
                    options={blogs}
                    getOptionLabel={(blog) => (blog.title ? blog.title : "")}
                    getOptionSelected={(blog) => (blog.title ? blog.title : "")}
                    value={rest.values.blog}
                    onChange={(event, value) => {
                      setFieldValue(blog, value);
                      handleFormUpdate(blog, value);
                      setBannerError("");
                      !value && setSearchText("title", "");
                    }}
                    onFocus={() => handleFocusField(blog)}
                    onBlur={() => {
                      handleBlurField();
                      //rest.handleBlur();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="material-form__field"
                        onChange={(e) => setSearchText("title", e.target.value)}
                        name={blog}
                        placeholder="Select Blog"
                        label="Select Blog"
                        variant="standard"
                      />
                    )}
                  />
                  {focusField === blog ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={blog}
                    />
                  )}
                </div>
              </>
            )}

            <div className="form__form-group-field form__margin-bottom">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="banner_file">Choose Banner</label>
                  <Tooltip
                    title={
                      <div>
                        <p>Preferrd Size for Web - 616 * 249 px (2x)</p>
                        <p>Preferrd Size for App - 640 * 360 px</p>
                      </div>
                    }
                    placement="bottom"
                  >
                    <i className="fa fa-info-circle icon-file-info mt-1"></i>
                  </Tooltip>
                  <br />
                  {files
                    .filter(
                      (file) =>
                        file.file_context === fileContext.HOME_PAGE_BANNER
                    )
                    .map((x) => {
                      return (
                        <Chip
                          className="selected-file"
                          key={x.uuid_file_name}
                          label={x.original_file_name}
                          onDelete={() => handleDeleteAFile(x)}
                          variant="outlined"
                        />
                      );
                    })}
                  {isFileUploadData
                    .filter(
                      (file) =>
                        file.fileContext === fileContext.HOME_PAGE_BANNER
                    )
                    .map((x, i) => {
                      return (
                        <>
                          {x.isUpload && (
                            <ProgressChip
                              fileName={x.fileName}
                              uploadProgress={uploadProgress}
                            />
                          )}
                        </>
                      );
                    })}
                  <input
                    accept="image/*"
                    type="file"
                    name={banner_file}
                    id="banner_file"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleHomePageBannerFile(event.currentTarget.files);
                      setFieldValue(
                        banner_file,
                        files.filter(
                          (file) =>
                            file.file_context === fileContext.HOME_PAGE_BANNER
                        )
                      );
                      setBannerError("");
                    }}
                  />
                </div>

                {rest.errors.banner_file ? (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form-error-image">{msg}</div>
                    )}
                    name={banner_file}
                  />
                ) : (
                  <div className="material-form-error-image">
                    {fileHomePageBannerError || uploadError}
                  </div>
                )}

                {bannerError && (
                  <div className="material-form-error-image mt-1">
                    {bannerError}
                  </div>
                )}

                {focusField === banners ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error mt-2">{msg}</div>
                    )}
                    name={banners}
                  />
                )}
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-success btn-right btn-sm mt-4"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default BannerForm;
