import { api, apiEndPoints } from "../../../api";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";

const namespace = "containers/CMS/policy";

export const SET_POLICY_LIST = `${namespace}/SET_POLICY_LIST`;
export const SET_POLICY_FILTER = `${namespace}/SET_POLICY_FILTER`;
export const RESET_POLICY_FILTER = `${namespace}/RESET_POLICY_FILTER`;

export const setPolicyList = (payload) => ({
  type: SET_POLICY_LIST,
  payload,
});

export const setPolicyFilter = (payload) => ({
  type: SET_POLICY_FILTER,
  payload,
});

export const resetPolicyFilter = () => ({
  type: RESET_POLICY_FILTER,
});

export const getPoliciesList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPolicies, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setPolicyList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addPolicy = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createPolicy, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getPolicy = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPolicy(id));
    const { data: { is_success, policy } = {} } = response;
    if (is_success) {
      return policy;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editPolicy = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updatePolicy(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deletePolicy = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deletePolicy(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};
