import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField } from "@material-ui/core";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../utils/form";
import { settingsSchema } from "./validation-schema";
import { fieldNames } from "../constant";

const SettingsForm = ({ fieldValues, btn, isEdit, handleSetting }) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();
  const { key, value, description } = fieldNames;

  return (
    <Formik
      validateOnMount
      validationSchema={settingsSchema}
      initialValues={{
        value: fieldValues?.value || "",
        key: fieldValues?.key || "",
        description: fieldValues?.description || "",
      }}
      onSubmit={handleSetting}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <TextField
              className="material-form__field"
              name={key}
              placeholder="Key e.g AGE LIMIT"
              label="Key"
              type="text"
              disabled={isEdit}
              value={rest.values.key}
              onChange={(event) => {
                const { name, value } = event.target;
                handleFormValueChange(rest, name);
                setFieldValue(name, value);
              }}
              onFocus={() => handleFocusField(key)}
              onBlur={() => handleBlurField()}
            />
            {focusField === key ? null : (
              <ErrorMessage
                render={(msg) => (
                  <div className="material-form__error">{msg}</div>
                )}
                name={key}
              />
            )}

            <TextField
              className="material-form__field"
              name={value}
              placeholder="Value"
              label="Value"
              type="text"
              value={rest.values.value}
              onChange={(event) => {
                const { name, value } = event.target;
                handleFormValueChange(rest, name);
                setFieldValue(name, value);
              }}
              onFocus={() => handleFocusField(value)}
              onBlur={() => handleBlurField()}
            />
            {focusField === value ? null : (
              <ErrorMessage
                render={(msg) => (
                  <div className="material-form__error">{msg}</div>
                )}
                name={value}
              />
            )}

            <TextField
              className="material-form__field"
              name={description}
              placeholder="Description"
              label="Description"
              type="text"
              value={rest.values.description}
              onChange={(event) => {
                const { name, value } = event.target;
                handleFormValueChange(rest, name);
                setFieldValue(name, value);
              }}
              onFocus={() => handleFocusField(description)}
              onBlur={() => handleBlurField()}
            />
            {focusField === description ? null : (
              <ErrorMessage
                render={(msg) => (
                  <div className="material-form__error">{msg}</div>
                )}
                name={description}
              />
            )}

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default SettingsForm;
