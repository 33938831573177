import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../utils/upload-file/actions";
import { addEventNote, getEventNote } from "../actions";
export const useAddNote = ({ eventScheduleId, index }) => {
  const dispatch = useDispatch();
  const [addNoteLoading, setaddNoteLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const files = useSelector(({ fileReducer }) => fileReducer.files);

  const handleAddNote = async () => {
    try {
      setaddNoteLoading(true);
      const payload = {
        file: { ...files[0] },
      };

      const is_success = await dispatch(addEventNote(payload, eventScheduleId));

      setaddNoteLoading(false);
      if (is_success) {
        dispatch(clearFiles());
        setIsUpdating(false);
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearScheduleEventNote = async () => {
    dispatch(clearFiles());
  };

  const handleGetNote = async () => {
    try {
      const note = await dispatch(getEventNote(eventScheduleId));
      if (note) {
        setIsUpdating(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    isOpenModal,
    toggleModal,
    handleAddNote,
    handleGetNote,
    files,
    handleClearScheduleEventNote,
    addNoteLoading,
    index,
    isUpdating
  };
};
