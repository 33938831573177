import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editInterest, getInterest, getCategoryList } from "../actions";
import { clearFiles, setFiles } from "../../../utils/upload-file/actions";
import { getCategorieList, getCategoryFilter } from "../selectors";
import { initialLanguageFieldValue } from "../../../constants";
import { clearLanguage, getLanguage } from "../../../utils/auth";

export const useEditInterest = ({ id }) => {
  const dispatch = useDispatch();
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const categories = useSelector(getCategorieList);
  const categoryFilter = useSelector(getCategoryFilter);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editInterestLoading, setEditInterestLoading] = useState(false);
  const [selectedInterest, setSelectedInterest] = useState();

  const getInterestFieldData = async () => {
    const selectedLanguage = getLanguage();
    dispatch(clearFiles());
    setEditInterestLoading(true);
    const data = await dispatch(getInterest(id));
    const is_success = await dispatch(getCategoryList(categoryFilter));
    const { files } = data;
    if (data && is_success) {
      data.title = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data?.interest?.title,
      };
      files.forEach((file) => dispatch(setFiles(file)));
      setSelectedInterest(data);
      setEditInterestLoading(false);
      toggleModal();
    }
  };

  const handleLangugeData = async (rest, setFormvalues) => {
    const selectedLanguage = getLanguage();
    setEditInterestLoading(true);
    const data = await dispatch(getInterest(id));

    if (data) {
      data.title = {
        ...rest.values.title,
        [selectedLanguage]:
          rest.values.title[selectedLanguage] || data?.interest?.title || "",
      };
      data.language = selectedLanguage;
      setSelectedInterest(data);
      setFormvalues(data);
      setEditInterestLoading(false);
    }
  };

  const handleEditInterest = async (values) => {
    try {
      const payload = {
        interest: {
          title: values.title,
          status: values.status,
        },
        category_ids: values.categoryIds.map((category) => category.id),
        files,
      };
      setEditInterestLoading(true);
      const is_success = await dispatch(editInterest(payload, id));
      setEditInterestLoading(false);
      if (is_success) {
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editInterestLoading,
    handleEditInterest,
    isOpenModal,
    toggleModal,
    selectedInterest,
    getInterestFieldData,
    categories,
    handleLangugeData,
  };
};
