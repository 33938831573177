import * as Yup from "yup";
import { validationRegex } from "../../../../utils/validation";
export const metaFormSchema = Yup.object().shape({
  meta_title: Yup.string().required("Meta Title is required"),
  meta_description: Yup.string().required("Meta Description is required"),
  meta_keywords: Yup.string().required("Meta Keywords is required"),
  page_url: Yup.mixed()
    .required("Page URL is required")
    .test({
      test: function (value) {
        if (!value) {
          return false;
        }
        return validationRegex.urlRegex.test(value);
      },
      message: "Invalid URL",
    }),
});
