export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Platform",
    accessor: "platform",
  },
  {
    Header: "Postion",
    accessor: "position",
    disableGlobalFilter: true,
  },
  {
    Header: "Screen",
    accessor: "screen",
    disableGlobalFilter: true,
  },
  {
    Header: "Created At",
    accessor: "created_at",
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    width: 60,
  },
];

export const bannerStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
];

export const fieldsName = {
  banners: "banners",
  platform: "platform",
  screen: "screen",
  status: "status",
  redirect_to: "redirect_to",
  sequence_no: "sequence_no",
  banner_file: "banner_file",
  landing_page: "landing_page",
  blog_date: "blog_date",
  event_date: "event_date",
  event: "event",
  blog: "blog",
};

export const bannerPlatform = [
  {
    id: 1,
    value: "Web",
    displayText: "Web",
  },
  {
    id: 2,
    value: "App",
    displayText: "Mobile App",
  },
];

export const landingPageOptions = [
  {
    id: 1,
    value: "Home",
    displayText: "Home Page",
  },
  {
    id: 2,
    value: "MySessions",
    displayText: "My Session Page",
  },
  {
    id: 3,
    value: "Profile",
    displayText: "My Profile Page",
  },

  {
    id: 4,
    value: "Events",
    displayText: "Event Calendar Page",
  },
  {
    id: 5,
    value: "Notifications",
    displayText: "Notice Board Page",
  },
  {
    id: 6,
    value: "Blogs",
    displayText: "Blog Page",
  },
  {
    id: 7,
    value: "MyPlan",
    displayText: "My Plan Page",
  },
  {
    id: 8,
    value: "BlogDetail",
    displayText: "Blog Detail",
  },
  {
    id: 9,
    value: "EventDetail",
    displayText: "Event Detail",
  },
  {
    id: 10,
    value: "SubscriptionPage",
    displayText: "Subscription Landing Page",
  },
  {
    id: 11,
    value: "Referral",
    displayText: "Referral Page",
  },
  {
    id: 12,
    value: "Help",
    displayText: "Help Page",
  },
  {
    id: 13,
    value: "PlansAndTopUp",
    displayText: "Subscription Plans & TopUps",
  },
  {
    id: 14,
    value: "AllOffers",
    displayText: "All Offers",
  },
  {
    id: 15,
    value: "MyOffers",
    displayText: "My Offers",
  },
  {
    id: 16,
    value: "MyCourses",
    displayText: "My Courses",
  },
  {
    id: 17,
    value: "TermsAndConditions",
    displayText: "Terms and Conditions",
  },
];
