import {
  SET_POPULAR_KEYWORD_LIST,
  SET_POPULAR_KEYWORD_FILTER
} from "./actions";

const initialState = {
  popular_keywords: [],
  count: 0,
  filter: {
    status: "",
    limit: 10,
    skip: 0,
  },
};

const popularKeywordReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POPULAR_KEYWORD_LIST:
      return {
        ...state,
        popular_keywords: action.payload.popular_keywords,
        count: action.payload.count,
      };
    case SET_POPULAR_KEYWORD_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    };
    default:
      return state;
  }
};

export default popularKeywordReducer;
