import { api, apiEndPoints } from "../../api";

const namespace = `/containers/reports`;

export const SET_REPORTS_LIST = `${namespace}/SET_REPORTS_LIST`;
export const SET_REPORTS_FILTERS = `${namespace}/SET_REPORTS_FILTERS`;

export const setReportList = (payload) => ({
  type: SET_REPORTS_LIST,
  payload,
});

export const setReportFilter = (payload) => ({
  type: SET_REPORTS_FILTERS,
  payload,
});

export const getPostReports = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPostReports(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setReportList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getStoriesReports = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getStoriesReports(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setReportList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getCommentsReports = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCommentReports(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setReportList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
