import React from 'react'
import { Button } from "reactstrap";
import { useAddPopularKeyword } from './hooks'
import PopularKeywordForm from "../form/popular-keyword-form";
import Modals from "../../../../../components/modal";


const AddPopularKeyword = (props) => {
  const {
    handleAddPopularKeyword,
    addPopularKeywordLoading,
    isOpenModal,
    toggleModal,
  } = useAddPopularKeyword(props);
  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          toggleModal();
        }}
      >
        <i className="fa fa-plus"></i> Add Popular Keyword
     </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PopularKeywordForm
              handlePopularKeywordForm={handleAddPopularKeyword}
              btn="Add"
              isLoading={addPopularKeywordLoading}
            />
          }
          title="Add Popular Keyword"
        />
      ) : null}
    </div>
  )
}

export default AddPopularKeyword
