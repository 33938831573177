import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addInterest, getCategoryList } from "../actions";
import { clearFiles } from "../../../utils/upload-file/actions";
import { getCategorieList, getCategoryFilter } from "../selectors";

export const useAddInterest = () => {
  const dispatch = useDispatch();
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const categories = useSelector(getCategorieList);
  const categoryFilter = useSelector(getCategoryFilter);

  const [addInterestLoading, setAddInterestLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const getCategories = async () => {
    setAddInterestLoading(true);
    const is_success = await dispatch(getCategoryList(categoryFilter));
    if (is_success) {
      toggleModal();
    }
    setAddInterestLoading(false);
  };

  const handleAddInterest = async (values) => {
    try {
      const payload = {
        interest: {
          title: values.title,
          status: values.status,
        },
        category_ids: values.categoryIds.map((category) => category.id),
        files,
      };
      setAddInterestLoading(true);
      const is_success = await dispatch(addInterest(payload));
      setAddInterestLoading(false);
      if (is_success) {
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
  };

  return {
    addInterestLoading,
    handleAddInterest,
    isOpenModal,
    toggleModal,
    getCategories,
    categories,
  };
};
