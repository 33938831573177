import React from "react";
import PropTypes from "prop-types";
import { useReplies, useReply } from "./hooks";
import styles from "./styles.module.scss";
import Reaction from "../reaction";
import ProfileImage from "../../../../../components/ProfileImage";
import Text from "../../../../../components/text";
import { Button, MenuItem, TextField, Tooltip } from "@material-ui/core";
import { colors } from "../../../../../constants/colors";
import moment from "moment";
import UnpublishPost from "../../unpublish-post";
import Reactions from "../reactions";
import { postByList } from "../../constant";
import { fileContext, trackableTypes } from "../../../../../constants";
import ReactPlayer from "react-player";
import ImageViewer from "../../../../../components/image-viewer";
import CaptionInput from "../caption-input-multiline";
import PostCaption from "../post-caption";
import { useUser } from "../../../../../common-hooks/user";
import DeleteComment from "../delete-comment";
import ConfidenceScore from "../../../../../components/confidence-score";

const Reply = ({
  data,
  handleDeleteReply,
  toggleReplyInput,
  updateComment,
  trackableType,
  isDisabled,
  handleDelete,
}) => {
  const {
    userProfileImg,
    reactionsCount,
    handleReactSuccess,
    handleRemoveReactSuccess,
    myReaction,
    imageViewer,
    handleImageViewer,
    user,
    plainTextValue,
    handleIsEdit,
    postBy,
    isEdit,
    handleChange,
    profile_picture,
    handleUpdateComment,
    files,
    clearInput,
    handleClearInput,
    egReaction,
    disabled,
  } = useReply({
    data,
    updateComment,
    trackableType,
    handleDelete,
    isDisabled,
  });

  const { isAdmin, isModerator } = useUser();

  return (
    <div className={styles.comment_content}>
      <div>
        <ProfileImage
          src={userProfileImg}
          title={data?.commenter?.name}
          width={40}
          height={40}
          initialsClassName={styles.profile_img_initials}
          className={styles.profile_img}
        />
      </div>
      <div className={styles.comment_box}>
        <div className={styles.comment_detail}>
          <div className={styles.commenter}>
            <Text className={styles.comment_title} size="16px" fontWeight={600}>
              {data?.commenter?.name}
            </Text>
            <div className="d-flex flex-column align-items-end">
              <Text fontWeight={400} size="12px" color={"grey"}>
                {moment(data?.created_at).fromNow()}
              </Text>
              <div className="mt-2 d-inline-flex align-items-center">
                {isAdmin && (
                  <>
                    <UnpublishPost
                      handleSubmit={handleDeleteReply}
                      data={data}
                      isDisabled={disabled}
                      className="mr-3"
                      status={data?.status}
                    />
                  </>
                )}

                <ConfidenceScore
                  data={data}
                  trackableType={trackableTypes.COMMENT}
                  className="mr-3"
                />

                {user?.id === data?.created_by && isAdmin && (
                  <Tooltip title="Edit" placement="bottom">
                    <i
                      className={`fa fa-edit icon-btn-action mt-1 ${
                        disabled && styles.disabled_reply
                      }`}
                      onClick={() => !disabled && handleIsEdit()}
                    ></i>
                  </Tooltip>
                )}
                <DeleteComment
                  handleDelete={handleDelete}
                  comment={data}
                  className="ml-3"
                  isComment={false}
                />
              </div>
            </div>
          </div>
          <PostCaption captionText={data?.text} ranges={data?.ranges} />

          {data?.attachments?.length ? (
            <div className={"discussion_images_container bg-transparent mt-3"}>
              {data?.attachments?.map((img, i) => (
                <div
                  onClick={handleImageViewer}
                  className={"img_container"}
                  key={i}
                >
                  {img.file_context.includes(fileContext.DISCUSSION_VIDEOS) ? (
                    <ReactPlayer
                      width={80}
                      height={80}
                      url={img?.ts_url || img.url || img?.public_url}
                      playing={true}
                      muted={true}
                      loop={true}
                      style={{ display: "inline-block" }}
                    />
                  ) : (
                    <img alt="file" src={img.url || img?.public_url} />
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </div>

        {imageViewer && (
          <ImageViewer
            files={data?.attachments}
            handleViewer={handleImageViewer}
          />
        )}

        <div className={styles.comment_reaction_box}>
          {isAdmin && (
            <>
              <div className="mr-3">
                <Reaction
                  trackableId={data.id}
                  trackableType={trackableTypes.COMMENT}
                  reactionId={egReaction?.id}
                  reactionName={egReaction?.name}
                  onReactSuccess={handleReactSuccess}
                  onRemoveReactSuccess={handleRemoveReactSuccess}
                  disabled={disabled}
                  isEgReaction
                />
              </div>
              <div className={`${styles.dot} mr-3`} />
            </>
          )}

          <div className="mr-3">
            <Reaction
              onReactSuccess={handleReactSuccess}
              onRemoveReactSuccess={handleRemoveReactSuccess}
              trackableType={trackableTypes.COMMENT}
              trackableId={data.id}
              reactionId={myReaction?.id}
              reactionName={myReaction?.name}
              disabled={disabled}
            >
              <Text
                className="cursor_pointer"
                color={colors.lightgray3}
                size="14px"
                fontWeight={600}
              >
                React
              </Text>
            </Reaction>
          </div>
          {(isAdmin || isModerator) && (
            <>
              <div className={`${styles.dot} mr-3`} />
              <Text
                className={`${
                  disabled ? `${styles.disabled_reply}` : "cursor_pointer"
                } mr-3`}
                color={colors.grayDark2}
                size="15px"
                fontWeight={500}
                onClick={() => !disabled && toggleReplyInput(data)}
              >
                Reply
              </Text>
            </>
          )}

          {!!reactionsCount && (
            <>
              <div className={styles.dot} />
              <Reactions
                id={data?.id}
                trackableType={trackableTypes.COMMENT}
                totalReactions={reactionsCount}
              />
            </>
          )}
        </div>

        {isEdit && (
          <div className={`mt-3`}>
            {files?.length ? (
              <div className={"discussion_images_container mt-3"}>
                {files
                  .filter(
                    (file) =>
                      file.file_context ===
                        `${fileContext.DISCUSSION_IMAGES}-${data?.id}` ||
                      file.file_context ===
                        `${fileContext.DISCUSSION_VIDEOS}-${data?.id}`
                  )
                  .map((img, i) => (
                    <div className={"img_container"} key={i}>
                      {img.file_context ===
                      `${fileContext.DISCUSSION_VIDEOS}-${data?.id}` ? (
                        <ReactPlayer
                          width={80}
                          height={80}
                          url={img?.ts_url || img.public_url || img.url}
                          playing={true}
                          muted={true}
                          loop={true}
                          style={{ display: "inline-block" }}
                        />
                      ) : (
                        <img alt="media" src={img.public_url || img.url} />
                      )}
                    </div>
                  ))}
              </div>
            ) : null}
            <div className={`${styles.add_a_comment_div} mt-3`}>
              <ProfileImage
                src={profile_picture?.url}
                title={user?.name}
                width={40}
                height={40}
                className={`${styles.profile_img} mr-2`}
                initialsClassName={styles.profile_img_initials}
              />
              <div className={styles.comment_input_div}>
                <div className={styles.input_field}>
                  <CaptionInput
                    onCaptionChange={handleChange}
                    caption={data?.text}
                    mentions={data?.ranges}
                    placeholder="reply..."
                    trackableType={trackableType}
                    singleLine
                    clear={clearInput}
                    onClearSuccess={handleClearInput}
                  />
                </div>
              </div>

              {isAdmin && (
                <div className="material-form_inline-fields mr-4">
                  <TextField
                    style={{ width: "100px" }}
                    className="material-form__field"
                    name={"post_by"}
                    placeholder="Post By"
                    label="Post By"
                    select
                    value={postBy}
                    disabled
                    defaultValue={"postBy"}
                  >
                    {postByList.map((post_by) => (
                      <MenuItem
                        key={post_by.id}
                        className="material-form__option"
                        value={post_by.value}
                      >
                        {post_by.displayText}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              <div className={styles.comment_button_div}>
                <Button
                  disabled={!plainTextValue}
                  onClick={handleUpdateComment}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Replies = (props) => {
  const {
    replyText,
    user,
    userProfileImg,
    handleReplySubmit,
    replies,
    totalReplies,
    replyVisible,
    toggleReplyInput,
    handleViewMoreReplies,
    handleDeleteComment,
    handleCommentChange,
    postBy,
    handlePostBy,
    reply,
    taggedMentionsCopy,
    clearInput,
    handleClearInput,
    updateComment,
    isDisabled,
    isAdmin,
    handleDeleteAComment,
    disabled,
  } = useReplies(props);

  return (
    <div>
      {replies.map((data) => {
        return (
          <Reply
            key={data.id}
            data={data}
            toggleReplyInput={toggleReplyInput}
            handleDeleteReply={handleDeleteComment}
            updateComment={updateComment}
            trackableType={props?.trackableType}
            isEgReaction={props.isEgReaction}
            isDisabled={disabled}
            handleDelete={handleDeleteAComment}
          />
        );
      })}
      {replies?.length < totalReplies && (
        <div
          onClick={handleViewMoreReplies}
          className={styles.view_more_replies}
        >
          <Text color={colors.lightgray3} fontWeight={700} size="12px">
            View more replies
          </Text>
        </div>
      )}
      {replyVisible && (
        <>
          {reply?.id && (
            <>
              <div className={styles.container}>
                <ProfileImage
                  src={userProfileImg}
                  title={user?.name}
                  width={40}
                  height={40}
                  className={styles.profile_img}
                  initialsClassName={styles.profile_img_initials}
                />
                <div className={styles.reply_box}>
                  <Text
                    className={styles.replier_name}
                    size="16px"
                    fontWeight={600}
                  >
                    {user?.name}
                  </Text>
                  <div className={styles.reply_input_container}>
                    <div className={styles.comment_input_div}>
                      <div className={styles.input_field}>
                        {replyText && (
                          <CaptionInput
                            placeholder="Write a reply..."
                            caption={replyText}
                            onCaptionChange={handleCommentChange}
                            isAttendee={false}
                            mentions={taggedMentionsCopy}
                            singleLine
                            trackableType={props?.trackableType}
                            clear={clearInput}
                            onClearSuccess={handleClearInput}
                          />
                        )}

                        {!replyText && (
                          <CaptionInput
                            placeholder="Write a reply..."
                            onCaptionChange={handleCommentChange}
                            isAttendee={false}
                            singleLine
                            trackableType={props?.trackableType}
                            clear={clearInput}
                            onClearSuccess={handleClearInput}
                          />
                        )}
                      </div>
                    </div>

                    {isAdmin && (
                      <div className="material-form_inline-fields mr-4">
                        <TextField
                          style={{ width: "100px" }}
                          className="material-form__field"
                          name={"post_by"}
                          placeholder="Post By"
                          label="Post By"
                          select
                          value={postBy}
                          onChange={(event) => {
                            handlePostBy(event.target.value);
                          }}
                          defaultValue={postBy}
                        >
                          {postByList.map((post_by) => (
                            <MenuItem
                              key={post_by.id}
                              className="material-form__option"
                              value={post_by.value}
                            >
                              {post_by.displayText}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    )}

                    <div>
                      <Button
                        disabled={isDisabled}
                        onClick={handleReplySubmit}
                        className={
                          isDisabled
                            ? styles.disabled_button
                            : styles.reply_button
                        }
                      >
                        <Text color={colors.white} fontWeight={600} size="14px">
                          REPLY
                        </Text>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

Replies.propTypes = {
  commentId: PropTypes.number.isRequired,
};

export default Replies;
