import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";

import { TextField, Chip, MenuItem, Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DataLoader } from "../../../components/loader";
import { useUploadFiles } from "../../../utils/upload-file/hooks";
import { interestFormSchema } from "./validation-schema";
import { interestStatus, fieldsName } from "../constant";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";
import {
  fileContext,
  initialLanguageFieldValue,
  languages,
} from "../../../constants";
import ProgressChip from "../../../components/progress-chip";
import { setLanguage } from "../../../utils/auth";

const formField = {
  title: initialLanguageFieldValue,
  status: "",
  categoryIds: "",
  language: "en",
};

const InterestForm = ({
  fieldValues,
  handleInterestForm,
  btn,
  isLoading,
  categories,
  handleLangugeData,
}) => {
  const {
    handleIconFile,
    uploadError,
    fileIconError,
    handleDeleteAFile,
    files,
    isFileUploadData,
    uploadProgress,
  } = useUploadFiles();

  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const { title, status, fileIcon, categoryIds, language } = fieldsName;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = (event) => {
    const { name, value } = event.target;
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  const handleCategoryUpdate = (name, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  const handleLanguage = (key, language, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: {
        ...prevFieldValue[key],
        [language]: value,
      },
    }));
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={interestFormSchema}
      initialValues={{
        title: formValues?.title || initialLanguageFieldValue,
        status: formValues?.status || formValues?.interest?.status || "",
        language: formValues?.language || "en",
        fileIcon: files,
        categoryIds:
          (formValues?.category_ids &&
            categories.filter((category) =>
              formValues?.category_ids.find((id) => id === category.id)
            )) ||
          (formValues?.categoryIds &&
            categories.filter((category) =>
              formValues?.categoryIds.find(
                (selectedCategory) => selectedCategory.id === category.id
              )
            )) ||
          [],
      }}
      onSubmit={handleInterestForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={language}
                placeholder="Language"
                label="Language"
                select
                value={rest.values.language}
                onChange={(event) => {
                  const { name, value } = event.target;
                  handleFormValueChange(rest, name);
                  handleFormUpdate(event);
                  setFieldValue(name, value);
                  if (!rest.values.title[value]) {
                    handleLanguage(title, value, "");
                  }
                  if (btn === "Save") {
                    setLanguage(value);
                    handleLangugeData(rest, setFormvalues);
                  }
                }}
                onFocus={() => handleFocusField(language)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.language}
              >
                {languages.map((language) => (
                  <MenuItem
                    key={language.id}
                    className="material-form__option"
                    value={language.value}
                  >
                    {language.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === language ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={language}
                />
              )}
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  type="text"
                  value={rest.values.title[rest.values.language]}
                  onChange={(event) => {
                    handleFormValueChange(rest, title);
                    handleLanguage(
                      title,
                      rest.values.language,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={(event) => {
                  handleFormValueChange(rest, status);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {interestStatus.map((interest) => (
                  <MenuItem
                    key={interest.id}
                    className="material-form__option"
                    value={interest.value}
                  >
                    {interest.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>
            <div>
              <Autocomplete
                multiple
                options={categories}
                getOptionLabel={(category) =>
                  category.title ? category.title : ""
                }
                value={rest.values.categoryIds}
                name={categoryIds}
                onChange={(event, value) => {
                  setFieldValue(categoryIds, value);
                  handleCategoryUpdate(categoryIds, value);
                }}
                onFocus={() => handleFocusField(categoryIds)}
                onBlur={() => handleBlurField()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={categoryIds}
                    placeholder="Select Categories"
                    label="Select Categories"
                    variant="standard"
                  />
                )}
              />
              {focusField === categoryIds ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={categoryIds}
                />
              )}
            </div>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="fileIcon">Choose Icon</label>
                  <span>
                    {files.map((x) => {
                      return (
                        <Chip
                          className="selected-file"
                          key={x.uuid_file_name}
                          label={x.original_file_name}
                          onDelete={() => handleDeleteAFile(x)}
                          variant="outlined"
                        />
                      );
                    })}

                    {isFileUploadData
                      .filter((file) => file.fileContext === fileContext.ICON)
                      .map((x, i) => {
                        return (
                          <>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </>
                        );
                      })}
                  </span>
                  <input
                    accept="image/x-png"
                    onBlur={rest.handleBlur}
                    type="file"
                    name={fileIcon}
                    id="fileIcon"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleIconFile(event.currentTarget.files[0]);
                      setFieldValue({ fileIcon }, files);
                    }}
                  />
                </div>
                {fileIconError || uploadError ? (
                  <div className="material-form__error">
                    {fileIconError || uploadError}
                  </div>
                ) : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={fileIcon}
                  />
                )}
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-success btn-right btn-sm interest__marginTop30"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default InterestForm;
