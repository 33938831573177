import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSection, getSectionList } from "../actions";
import { getSectionFilter } from "../selectors";

export const useAddSection = () => {
  const dispatch = useDispatch();
  const [addSectionLoading, setAddSectionLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const filters = useSelector(getSectionFilter)

  const handleAddSection = async (values) => {
    try {
      setAddSectionLoading(true);
      const payload = {
        title: values.title,
        type: values.type,
        status: values.status,
        model: values.model,
        criteria: values.criteria,
      };
      setAddSectionLoading(false);
      const is_success = await dispatch(addSection(payload));
      setAddSectionLoading(false);
      if (is_success) {
        dispatch(getSectionList(filters))
        toggleModal()
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    addSectionLoading,
    handleAddSection,
    toggleModal,
    isOpenModal,
  };
};
