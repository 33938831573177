import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelScheduledEvent,
  getRefundDetails,
  getUserEventScheduleAction,
  cancelUserCourse,
  getCoursesData,
} from "../../actions";
import {
  getUserEventFilter,
  getRefundInfo,
  getCoursesFilter,
} from "../../selectors";

export const useCancelEvent = ({ event, user_id, is_course }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const event_filter = useSelector(getUserEventFilter);
  const courses_filter = useSelector(getCoursesFilter);
  const refundDetails = useSelector(getRefundInfo);

  const dispatch = useDispatch();
  const payload = {
    [is_course ? "courseId" : "scheduleEventId"]: event.id,
    userId: user_id,
  };

  const getRefund = async () => {
    const refund = await dispatch(getRefundDetails(payload));
    if (refund) {
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleCancelEvent = async (values) => {
    let is_success;
    if (is_course) {
      is_success = await dispatch(cancelUserCourse(payload, values));
    } else {
      is_success = await dispatch(cancelScheduledEvent(payload, values));
    }
    if (is_success) {
      if (is_course) {
        dispatch(getCoursesData(user_id, courses_filter));
      } else {
        await dispatch(getUserEventScheduleAction(user_id, event_filter));
      }
      toggleModal();
    }
  };

  return {
    isOpenModal,
    toggleModal,
    handleCancelEvent,
    getRefund,
    refundDetails,
    is_course,
  };
};
