import React from "react";
import DataTable from "../../../components/data-table";
import {
  columns,
  commentAndRepliesFilterStatus,
  egTaggedFilter,
  statuses,
  userRoles,
} from "./constant";
import { useCommentAndReplies } from "./hooks";
import DateRangeFilter from "../../../components/filters/date-range-filter";
import SearchFilter from "../../../components/filters/search-filter";
import SelectFilter from "../../../components/filters/select-filter";
import { Tooltip } from "@material-ui/core";
import UnpublishPost from "../posts/unpublish-post";
import { useHistory } from "react-router-dom";
import moment from "moment";
import PostCaption from "../posts/components/post-caption";
import { TABLE_CAPTION_CHAR_LENGTH, trackableTypes } from "../../../constants";
import ActionButton from "../../../components/action-button";
import ConfidenceScore from "../../../components/confidence-score";

const Posts = (props) => {
  const {
    filters,
    setFilter,
    resetFilter,
    commentsAndReplies,
    count,
    updateCommentStatus,
    loadingList,
    isAdmin,
    handleReportClick,
  } = useCommentAndReplies(props);

  const history = useHistory();
  const configureTableData = () => {
    try {
      let results = commentsAndReplies.map((comment) => {
        let newComment = Object.assign({}, comment);
        newComment["caption"] =
          comment?.trackable_details?.caption ||
          comment?.trackable_details?.text ? (
            <PostCaption
              className="mentioned_data"
              captionText={
                comment?.trackable_details?.caption ||
                comment?.trackable_details?.text
              }
              ranges={comment?.trackable_details?.ranges}
              charLength={TABLE_CAPTION_CHAR_LENGTH}
            />
          ) : (
            "NA"
          );

        newComment["reports"] = comment?.reports_count ? (
          <Tooltip title="View Reports">
            <span
              id={comment?.id}
              className="schedule__booked-info"
              onClick={handleReportClick}
            >
              {comment?.reports_count}{" "}
              {comment?.reports_count > 1 ? "reports" : "report"}
            </span>
          </Tooltip>
        ) : (
          "NA"
        );
        newComment["created_at"] = moment(comment?.created_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );
        newComment["updated_at"] = moment(comment?.updated_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );
        newComment["reason"] = comment?.reason || "NA";

        newComment["name"] = comment?.owner?.name || "NA";
        newComment["reported_comment"] = !comment?.parent_comment_id ? (
          <PostCaption
            className="mentioned_data"
            captionText={comment?.text}
            ranges={comment?.ranges}
            charLength={TABLE_CAPTION_CHAR_LENGTH}
          />
        ) : (
          "NA"
        );
        newComment["reported_reply"] = comment?.parent_comment_id ? (
          <PostCaption
            className="mentioned_data"
            captionText={comment?.text}
            ranges={comment?.ranges}
            charLength={TABLE_CAPTION_CHAR_LENGTH}
          />
        ) : (
          "NA"
        );

        newComment["action"] = (
          <div className="d-flex">
            <ConfidenceScore
              data={comment}
              trackableType={trackableTypes.COMMENT}
              className="mr-3"
            />
            {isAdmin && (
              <div className="mr-3">
                <UnpublishPost
                  handleSubmit={updateCommentStatus}
                  data={comment}
                  status={comment?.status}
                  isDisabled={
                    comment?.replyTo?.status === statuses.UNPUBLISHED ||
                    comment?.trackable_details?.status === statuses.UNPUBLISHED
                  }
                />
              </div>
            )}
            {comment?.trackable_type === trackableTypes.POST ? (
              <ActionButton
                className="mr-3"
                iconClassName="fa fa-eye icon-btn-action"
                onClick={() =>
                  history.push({
                    pathname: `/post/${comment?.trackable_id}`,
                  })
                }
                title="View"
              />
            ) : null}
          </div>
        );

        return newComment;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const commentAndRepliesFilter = () => (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex">
          <DateRangeFilter
            handleChange={setFilter}
            selectedValue={filters.created_at}
            filterKey="created_at"
            filterTitle="Published Date"
          />
          <SearchFilter
            handleSearch={setFilter}
            searchPlaceholder="User ID"
            filterKey="user_id"
            filterTitle="User ID"
            searchedValue={filters.user_id}
          />
          <SearchFilter
            handleSearch={setFilter}
            searchPlaceholder="Username"
            filterKey="name"
            filterTitle="Username"
            searchedValue={filters.name}
          />

          <SearchFilter
            handleSearch={setFilter}
            searchPlaceholder="Comment or reply Caption"
            filterKey="text"
            filterTitle="Comment or reply Caption"
            searchedValue={filters.text}
          />

          <SearchFilter
            handleSearch={setFilter}
            searchPlaceholder="Hashtag"
            filterKey="hashtag"
            filterTitle="Hashtag"
            searchedValue={filters.hashtag}
          />
          <SearchFilter
            handleSearch={setFilter}
            searchPlaceholder="Mobile Number"
            filterKey="mobile_number"
            filterTitle="Mobile Number"
            searchedValue={filters.mobile_number}
          />
        </div>

        <div className="d-flex mt-2">
          <SelectFilter
            handleSelect={setFilter}
            selectedValue={filters.evergreen_club_tagged}
            filterKey="evergreen_club_tagged"
            options={egTaggedFilter}
            filterTitle="EG Tagged"
          />

          <SelectFilter
            handleSelect={setFilter}
            selectedValue={filters.roles}
            filterKey="roles"
            options={userRoles}
            defaultOption="All Roles"
            filterTitle="Roles"
          />

          <SelectFilter
            handleSelect={setFilter}
            selectedValue={filters.status}
            filterKey="status"
            options={commentAndRepliesFilterStatus}
            defaultOption="All"
            filterTitle="Status"
          />
        </div>
      </div>

      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={resetFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  return (
    <DataTable
      title="Reported Comments and Replies"
      CreateTableData={configureTableData}
      withFilter={true}
      filter={commentAndRepliesFilter}
      handleSetFilter={setFilter}
      count={count}
      skip={filters?.skip}
      isLoading={loadingList}
    />
  );
};

export default Posts;
