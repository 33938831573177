import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import Modals from "../../../components/modal";
import FaqForm from "../form/faq-form";
import { useEditFaq } from "./hooks";
import { setLanguage } from "../../../utils/auth";

const EditFaq = ({ faq }) => {
  const {
    handleEditFaq,
    isOpenModal,
    toggleModal,
    getFaqFieldData,
    selectedFaq,
    editFaqLoading,
    categories,
    handleLangugeData,
  } = useEditFaq(faq);
  return (
    <>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action `}
          onClick={() => {
            setLanguage("en");
            getFaqFieldData();
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <FaqForm
              fieldValues={selectedFaq}
              handleFaqForm={handleEditFaq}
              btn="Save"
              isLoading={editFaqLoading}
              categoryList={categories}
              handleLangugeData={handleLangugeData}
            />
          }
          title="Edit FAQ"
        />
      ) : null}
    </>
  );
};

export default EditFaq;
