import React from "react";
import PropTypes from "prop-types";
import styles from "./course-card.module.scss";
import { useCourseCard } from "./hooks";
import ProfileImage from "../../../../../components/ProfileImage";
import Text from "../../../../../components/text";
import { colors } from "../../../../../constants/colors";
import moment from "moment";

const CourseCard = ({ data, className }) => {
  const { handleCourseCardClick } = useCourseCard(data);
  if (!data) return null;

  const { id, title, host, files, slug, commence_at } = data;

  return (
    <div
      className={`${styles.container} ${className}`}
      onClick={() => handleCourseCardClick(id, slug)}
    >
      <img src={files?.banner?.url} width={200} height={104} alt="banner" />

      <div className={styles.detail_container}>
        <div className={styles.title}>
          <Text
            size="15px"
            fontWeight={500}
            color={colors.voilet}
            className={styles.time}
            lineheight="180x"
          >
            {moment(commence_at).format("DD-MM-YYYY @ hh:mm A")}
          </Text>
          <h3 className={styles.title}>{title}</h3>
        </div>
        <div className={styles.host_info}>
          <ProfileImage
            src={host?.profile_picture?.url ? host.profile_picture?.url : ""}
            width="30px"
            height="30px"
            className={styles.profile_image}
            title={host?.name}
            initialsClassName={styles.initialsClassName}
          />
          <p> {host?.name}</p>
        </div>
      </div>
    </div>
  );
};

CourseCard.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

CourseCard.defaultProps = {
  className: "",
};

export default CourseCard;
