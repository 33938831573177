import React from "react";
import LazyImage from "../../../../../components/lazy-image";
import Text from "../../../../../components/text";
import { colors } from "../../../../../constants/colors";
import styles from "./styles.module.scss";

const BlogCard = ({ blog }) => {
  const { id, banner, title, time_to_read } = blog;

  return (
    <>
      <div className={styles.blog_container}>
        <div key={`${id}`}>
          <div className={styles.blog} key={id}>
            <div className={styles.image_container}>
              <LazyImage src={banner?.url} className={styles.blog_image} />
              {time_to_read ? (
                <div className={styles.tag_container}>
                  <Text
                    color={colors.black}
                    fontWeight={500}
                    size="16px"
                    lineHeight="22px"
                  >
                    {`${time_to_read} mins read`}
                  </Text>
                </div>
              ) : null}
            </div>
            <div className={styles.blog_title} title={title}>
              {title}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
