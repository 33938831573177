import moment from "moment";
import React from "react";
import ActionButton from "../../components/action-button";
import DataTable from "../../components/table";
import { TABLE_CAPTION_CHAR_LENGTH, trackableTypes } from "../../constants";
import PostCaption from "../social/posts/components/post-caption";
import { columns } from "./constant";
import { useReports } from "./hooks";

const Reports = () => {
  const {
    filters,
    reports,
    count,
    loadingReports,
    trackableType,
    setFilter,
    handleViewClick,
    trackableID,
  } = useReports();

  const configureTableData = () => {
    try {
      let results = reports.map((report) => {
        let newReport = Object.assign({}, report);

        newReport["user_id"] = report?.user?.id || "NA";
        newReport["name"] = report?.user?.name || "NA";
        newReport["reason"] = report?.report?.reason || "NA";
        newReport["detailed_reason"] = report?.detailed_reason ? (
          <PostCaption
            className="mentioned__data"
            captionText={report?.detailed_reason}
            charLength={TABLE_CAPTION_CHAR_LENGTH}
            ranges={[]}
          />
        ) : (
          "NA"
        );

        newReport["reported_at"] = moment(report?.created_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );
        newReport["action"] =
          trackableType === trackableTypes.POST ? (
            <ActionButton
              onClick={() => handleViewClick(report?.post_id)}
              iconClassName={`fa fa-eye icon-btn-action`}
              title={`View ${trackableType}`}
            />
          ) : null;

        return newReport;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DataTable
      title={`${
        trackableID ? `${trackableID} :-` : ""
      } ${trackableType} Reports`}
      CreateTableData={configureTableData}
      handleSetFilter={setFilter}
      count={count}
      skip={filters?.skip}
      isLoading={loadingReports}
    />
  );
};

export default Reports;
