/* eslint-disable */

import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles, setFiles } from "../../../../utils/upload-file/actions";
import { MENTION_TYPES, statuses } from "../../../social/posts/constant";
import {
  getAddedReactionList,
  getRemovedReactionList,
} from "../../../social/utils";
import { useUser } from "../../../../common-hooks/user";

export const useDiscussionCard = ({
  discussion,
  handleUpdateDiscussion,
  index,
  handleDeleteDiscussion,
}) => {
  const [myReaction, setMyReaction] = useState({});
  const [egReaction, setEgReaction] = useState({});

  const [reactionsCountList, setReactionsCountList] = useState([]);
  const [isAddComment, setIsAddComment] = useState(false);
  const [isViewComments, setIsViewComments] = useState(false);
  const [totalComments, setTotalComments] = useState(0);
  const [moreOptions, setMoreOptions] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openViwer, setOpenViewer] = useState(false);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedDiscussion, setSelectedDiscussion] = useState({});
  const dispatch = useDispatch();

  const ref = useRef();
  const user = useSelector(({ authReducer }) => authReducer?.loggedInUser);
  const files = useSelector((state) => state.fileReducer);
  const { isAdmin } = useUser();
  const { PUBLISHED } = statuses;

  const disabled = useMemo(() => {
    return discussion?.status !== PUBLISHED || !discussion?.user?.is_active;
  }, [discussion?.status, PUBLISHED, discussion?.user?.is_active]);

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
  };

  const toggleDeleteModal = () => {
    clearFiles();
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const getSelectedDiscussion = async () => {
    toggleModal();

    if (discussion?.attachments?.length) {
      discussion?.attachments?.forEach((file) => {
        dispatch(setFiles(file));
      });
    }

    setSelectedDiscussion({
      caption: discussion?.text,
      plainTextValue: discussion?.text,
      ranges: discussion?.ranges,
      post_by:
        discussion?.commenter?.id == process.env.REACT_APP_EGC_ID
          ? "EvergreenClub"
          : "Me",
    });
  };

  const handleClickOutside = (e) => {
    if (ref.current && ref.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setMoreOptions(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const reactionsCount = useMemo(() => {
    if (reactionsCountList?.length === 0) {
      return 0;
    }
    const { count } = reactionsCountList?.reduce((a, b) => ({
      count: parseInt(a.count) + parseInt(b.count),
    }));
    return count;
  }, [reactionsCountList]);

  useEffect(() => {
    setTotalComments(discussion?.stats?.comments_count);
    setReactionsCountList(discussion?.stats?.reactions_counts);
    if (discussion?.eg_reaction) {
      setEgReaction(discussion?.eg_reaction);
    }
    if (discussion?.my_reaction) {
      setMyReaction(discussion?.my_reaction);
    }
  }, [
    discussion.stats.comments_count,
    discussion.stats.reactions_counts,
    discussion.my_reaction,
  ]);

  const handleRemoveReactSuccess = (
    _reactionId,
    selectedReaction,
    isEgReaction
  ) => {
    const reactionsList = getRemovedReactionList(
      reactionsCountList,
      selectedReaction
    );
    setReactionsCountList(reactionsList);
    isEgReaction ? setEgReaction({}) : setMyReaction({});
  };

  const handleReactSuccess = (submittedReaction, isEgReaction) => {
    const reaction = isEgReaction ? egReaction : myReaction;
    const reactionsList = getAddedReactionList(
      reactionsCountList,
      submittedReaction,
      reaction
    );
    setReactionsCountList(reactionsList);

    isEgReaction
      ? setEgReaction(submittedReaction)
      : setMyReaction(submittedReaction);
  };

  const handleAddComment = () => {
    !disabled && setIsAddComment(!isAddComment);
  };

  const handleViewComments = () => {
    setIsViewComments(!isViewComments);
  };

  const handleTotalComments = (key, count) => {
    setTotalComments((prev) => {
      if (key === "add") {
        return parseInt(prev) + 1;
      } else if (parseInt(prev) - count > 0) {
        return parseInt(prev) - count;
      }

      return 0;
    });
  };

  const handleMoreOptions = () => {
    setMoreOptions(!moreOptions);
  };

  const handleUpdateDiscussions = async (values) => {
    let ranges = [];

    values?.ranges?.forEach((mention) => {
      if (mention?.type) {
        ranges.push({
          trackable_type:
            mention?.type === "#"
              ? MENTION_TYPES.hashtag
              : mention?.type === "@"
              ? MENTION_TYPES.user
              : "",
          trackable_id: mention.id,
          name: mention.name,
          offset: mention.offset,
          length: mention.length,
        });
      }
    });
    const payload = {
      ...discussion,
      attachments: files?.files || [],
      text: values?.plainTextValue || values?.caption,
      ranges,
    };

    const is_success = await handleUpdateDiscussion(
      payload,
      discussion?.id,
      index
    );
    if (is_success) {
      toggleModal();
    }
  };

  const handleDeleteDiscussions = (values) => {
    const is_success = handleDeleteDiscussion(values, discussion?.id);
    if (is_success) {
      toggleDeleteModal();
    }
  };

  const handleOpenViewer = () => {
    setOpenViewer(!openViwer);
  };

  const publishDiscussion = async () => {
    const payload = {
      ...discussion,
      status: "Published",
      reason: "",
    };

    await handleDeleteDiscussion(payload, discussion?.id);
  };

  return {
    handleReactSuccess,
    myReaction,
    handleRemoveReactSuccess,
    reactionsCount,
    discussion,
    handleAddComment,
    isAddComment,
    handleViewComments,
    isViewComments,
    totalComments,
    handleTotalComments,
    moreOptions,
    handleMoreOptions,
    ref,
    user,
    getSelectedDiscussion,
    selectedDiscussion,
    isOpenModal,
    toggleModal,
    handleUpdateDiscussions,
    handleDeleteDiscussions,
    isOpenDeleteModal,
    toggleDeleteModal,
    openViwer,
    handleOpenViewer,
    reactionsCountList,
    publishDiscussion,
    isAdmin,
    egReaction,
    disabled,
  };
};
