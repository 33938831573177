export const getScheduleEventsSelector = ({
  scheduleEventReducer: { scheduleEvents = [] } = {},
}) => scheduleEvents;

export const getScheduleEventsFilter = ({
  scheduleEventReducer: { filter = {} } = {},
}) => filter;

export const getScheduleEventsCount = ({
  scheduleEventReducer: { count = 0 } = {},
}) => count;

export const getCategoriesSelector = ({
  scheduleEventReducer: { categories = [] } = {},
}) => categories;

export const getUsersSelector = ({
  scheduleEventReducer: { users = [] } = {},
}) => users;

export const getEventsSelector = ({
  scheduleEventReducer: { events = [] } = {},
}) => events;

export const getEventsFilterSelector = ({
  scheduleEventReducer: { eventsFilter = [] } = {},
}) => eventsFilter;

export const getEventNoteDetails = ({
  scheduleEventReducer: { scheduleEventNote = {} } = {},
}) => scheduleEventNote;

export const getScheduleEventReviewDetails = ({
  scheduleEventReducer: { scheduleEventsReview = {} } = {},
}) => scheduleEventsReview;

export const getCategoryFilter = ({
  scheduleEventReducer: { categoryFilter = {} } = {},
}) => categoryFilter;

export const getDiscussionSelector = ({
  scheduleEventReducer: { discussions = [] } = {},
}) => discussions;

export const getDiscusiionsFilter = ({
  scheduleEventReducer: { discussionFilter = {} } = {},
}) => discussionFilter;

export const getDiscussionsCount = ({
  scheduleEventReducer: { discussion_count = 0 } = {},
}) => discussion_count;
