import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import BlogForm from "../form/blog-form";
import { useEditBlog } from "./hooks";
import { useRouteMatch } from "react-router-dom";
import { Loader } from "../../../../../components/loader";
const EditBlog = () => {
  const match = useRouteMatch("/edit-blog/:blog_id");
  const {
    handleEditBlog,
    editBlogLoading,
    categories,
    interests,
    users,
    blogs,
    labels,
    selectedBlog,
    setSearchText,
    handleLangugeData,
  } = useEditBlog({ match });
  return (
    <div>
      {editBlogLoading ? (
        <Loader />
      ) : (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title card__title__flex">
                  <h3 className="bold-text">EDIT BLOG</h3>
                </div>
              </div>
              {selectedBlog && (
                <BlogForm
                  fieldValues={selectedBlog}
                  handleBlogForm={handleEditBlog}
                  btn="Save"
                  categories={categories}
                  interests={interests}
                  users={users}
                  blogs={blogs}
                  labels={labels}
                  isLoading={editBlogLoading}
                  handleLangugeData={handleLangugeData}
                  setSearchText={setSearchText}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      )}
    </div>
  );
};

export default EditBlog;
