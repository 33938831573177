import React from "react";
import { Formik, ErrorMessage } from "formik";
import { Chip } from "@material-ui/core";
import { formSchema } from "./validation-schema";
import { useHandleFieldFocus } from "../../../../../utils/form";
import { DataLoader } from "../../../../../components/loader";

const CodeUploadForm = ({ btn, handleAddCode, isLoading }) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={formSchema}
      initialValues={{
        file: "",
      }}
      onSubmit={handleAddCode}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="form__form-group-field form__margin-bottom">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="codeFile">Choose Code</label>

                  {rest?.values?.file?.name && (
                    <Chip
                      className="selected-file"
                      key={rest?.values?.file?.name}
                      label={rest?.values?.file?.name}
                      variant="outlined"
                      onDelete={() => setFieldValue("file", "")}
                    />
                  )}

                  <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    name={"file"}
                    id="codeFile"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    onFocus={() => handleFocusField("file")}
                    onBlur={() => handleBlurField()}
                  />
                  {focusField === "file" ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error blog__margin__top5">
                          {msg}
                        </div>
                      )}
                      name={"file"}
                    />
                  )}
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default CodeUploadForm;
