import moment from "moment";
import * as Yup from "yup";
import { validationRegex } from "../../../utils/validation";

export const userFormSchema = Yup.object().shape({
  mobile_number: Yup.mixed()
    .required("Mobile Number is required")
    .test({
      test: (value) => {
        if (!value) {
          return false;
        }
        return validationRegex.mobile.test(value);
      },
      message: "Invalid Mobile Number",
    }),
  name: Yup.string().required("Name is required"),
  email: Yup.mixed().test({
    test: (value) => (!value ? true : validationRegex.email.test(value)),
    message: "Invalid Email",
  }),
  role: Yup.string().required("Role is required"),
  designation: Yup.object(),
  about_me: Yup.object(),
  // short_about_me: Yup.object().test(
  //   "English language required",
  //   "Short about me is required for english language",
  //   function () {
  //     const {
  //       short_about_me: { en },
  //     } = this.parent;

  //     return en;
  //   }
  // ),
  short_about_me: Yup.object().when("role", {
    is: (val) => val === "Coordinator" || val === "Moderator",
    then: Yup.object().test(
      "English language required",
      "Short about me is required for english language",
      function () {
        const {
          short_about_me: { en },
        } = this.parent;
        return en;
      }
    ),
  }),

  specialities: Yup.object(),
  highlights: Yup.object(),
  date_of_birth: Yup.string()
    .test("is-less-than", "Uers's age must be greater than 50", function () {
      const { date_of_birth, role } = this.parent;
      let birthDate = moment(date_of_birth).format("YYYY-MM-DD");
      let userAge = moment().diff(birthDate, "years");
      if (date_of_birth && role === "Member") {
        return userAge > 50;
      } else return true;
    })
    .test(
      "is-less-than",
      "Date of Birth should match selected Age Group",
      function () {
        const { date_of_birth, age_group } = this.parent;
        let birthDate = moment(date_of_birth).format("YYYY-MM-DD");
        let userAge = moment().diff(birthDate, "years");
        if (date_of_birth && userAge > 50) {
          if (age_group === "51-65") {
            return userAge >= 51 && userAge <= 65;
          } else if (age_group === "66-80") {
            return userAge >= 66 && userAge <= 80;
          } else if (age_group === "80+") {
            return userAge > 80;
          } else return false;
        } else return true;
      }
    ),

  age_group: Yup.string().required("Age Group is required"),
  gender: Yup.string(),
  address: Yup.string(),
  tag: Yup.string().when("role", {
    is: "Coordinator",
    then: Yup.string().required("Tag is required"),
  }),
  pincode: Yup.mixed().test({
    test: (value) => (!value ? true : validationRegex.numeric.test(value)),
    message: "Invalid Pincode",
  }),
  emergency_mobile_number: Yup.mixed().test({
    test: (value) => (!value ? true : validationRegex.mobile.test(value)),
    message: "Invalid Mobile Number",
  }),
});
