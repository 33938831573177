import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePost, getPosts } from "../action";
import { getPostsFilters } from "../selectors";

export const useDeletePost = ({ id }) => {
  const dispatch = useDispatch();
  const filter = useSelector(getPostsFilters);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deletePostLoading, setDeletePostLoading] = useState(false);

  const handleDeletePost = async () => {
    setDeletePostLoading(true);
    const is_success = await dispatch(deletePost(id));
    setDeletePostLoading(false);
    if (is_success) {
      dispatch(getPosts(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deletePostLoading,
    handleDeletePost,
    isOpenModal,
    toggleModal,
  };
};
