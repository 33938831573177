import React from "react";
import { Route, Switch } from "react-router-dom";

import Testimonials from "../../../CMS/testimonials";

export default () => (
  <Switch>
    <Route path="/testimonials" component={Testimonials} />
  </Switch>
);
