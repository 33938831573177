import React from "react";
import { Button } from "reactstrap";

import CategoriesForm from "../form/categories-form";
import { useAddCategory } from "./hooks";
import Modals from "../../../components/modal";
import { useUser } from "../../../common-hooks/user";

const AddCategory = (props) => {
  const {
    handleAddCategory,
    isOpenModal,
    toggleModal,
    addCategoryLoading,
    getInterests,
    interests,
  } = useAddCategory(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          getInterests();
        }}
      >
        <i className="fa fa-plus"></i> Add Category
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <CategoriesForm
              handleCategoryForm={handleAddCategory}
              btn="Add"
              isLoading={addCategoryLoading}
              interests={interests}
            />
          }
          title="Add Category"
        />
      ) : null}
    </div>
  );
};

export default AddCategory;
