import React from "react";
import { useUserDetails } from "./hooks";
import { useRouteMatch } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import UserProfile from "./user-profile";
import UserTabs from "./user-tabs";
import UserIterests from "./user-interests";

import { Loader } from "../../../components/loader";
import UserSettings from "./user-settings";
import UserSource from "./user-source";
import UserPlan from "./user-plan";
import UserDevice from "./user-device";
const UserDetails = () => {
  const match = useRouteMatch("/users/:user_id");
  const {
    selectedUser = {},
    viewUserLoading,
    interests,
    fullAddress,
    source,
    plan,
    device,
    accordingBox,
    handleAccordionBox,
  } = useUserDetails({ match });
  return (
    <>
      {viewUserLoading ? (
        <Loader />
      ) : (
        <Container>
          <div className="profile">
            <Row>
              <Col md={12} lg={12} xl={4}>
                <Row>
                  <UserProfile
                    selectedUser={selectedUser}
                    fullAddress={fullAddress}
                  />
                  <UserPlan
                    plan={plan}
                    accordingBox={accordingBox}
                    handleAccordionBox={handleAccordionBox}
                  />
                  <UserIterests data={interests} />
                  <UserSettings selectedUser={selectedUser} />
                  <UserSource source={source} />
                  <UserDevice device={device} />
                </Row>
              </Col>
              {match.params.user_id && selectedUser?.user?.role && (
                <UserTabs
                  user_id={match.params.user_id}
                  user_role={selectedUser?.user?.role}
                  selectedUser={selectedUser}
                />
              )}
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};

export default UserDetails;
