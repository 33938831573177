import {
  RESET_OFFER_FILTER,
  SET_OFFER_FILTER,
  SET_OFFER_LIST,
  SET_PARTNER_LIST,
  SET_PARTNER_FILTER,
} from "./actions";

const initialList = {
  offers: [],
  partners: [],
  count: 0,
  partner_filter: {
    name: "",
    status: "Published",
    limit: 1000,
    skip: 0,
  },
  filter: {
    partner_ids: [],
    offer_text: "",
    partner_name: "",
    status: "",
    limit: 10,
    skip: 0,
  },
};

const partnerOfferReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_OFFER_LIST:
      return {
        ...state,
        offers: action.payload.partner_offers,
        count: action.payload.count || [],
      };
    case SET_PARTNER_LIST:
      return {
        ...state,
        partners: action.payload.partners,
      };
    case SET_OFFER_FILTER: {
      const { key, value } = action.payload;
      if (key === "offer_text" || key === "partner_name") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value,
            skip: 0,
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }

    case SET_PARTNER_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        partner_filter: {
          ...state.partner_filter,
          [key]: value,
        },
      };
    }

    case RESET_OFFER_FILTER:
      return { ...state, filter: initialList.filter || [] };
    default:
      return state;
  }
};

export default partnerOfferReducer;
