import React from "react";
import DataTable from "../../components/table";
import { usePlan } from "./hooks";
import AddPlan from "./add-plan";
import EditPlan from "./edit-plan";
import DeletePlan from "./delete-plan";
import { columns, planStatus } from "./constant";
import SelectFilter from "../../components/filters/select-filter";
import { useUser } from "./../../common-hooks/user";
import ChangePlanStatus from "./change-status";
import ManageOffers from "./manage-offers";

const Plans = () => {
  const { plans, loadingPlans, setFilter, filters, planCount } = usePlan();
  const { isModerator } = useUser();

  if (isModerator && columns.length > 7) {
    columns.pop();
  }

  const configureTableData = () => {
    try {
      let results = plans.map((plan) => {
        let newPlans = Object.assign({}, plan);
        newPlans["action"] = (
          <div className="categories__list-action">
            <EditPlan plan={plan} />
            <ChangePlanStatus plan={plan} />
            <DeletePlan plan={plan} />
            <ManageOffers plan={plan} />
          </div>
        );
        return newPlans;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const planFilter = () => (
    <>
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters.status}
        filterKey="status"
        options={planStatus}
        filterTitle="Status"
      />
    </>
  );

  return (
    <DataTable
      title="Plans"
      addBtn={<AddPlan />}
      CreateTableData={configureTableData}
      withFilter={true}
      filter={planFilter}
      handleSetFilter={setFilter}
      count={planCount}
      skip={filters?.skip}
      isLoading={loadingPlans}
    />
  );
};

export default Plans;
