import React from "react";
import { Button } from "reactstrap";
import { useAddBanner } from "./hooks";
import Modals from "../../../../components/modal";
import BannerForm from "../form/banner-form";
import { useUser } from "../../../../common-hooks/user";

const AddBanner = (props) => {
  const { AddBannerLoading, handleAddBanner, isOpenModal, toggleModal } =
    useAddBanner(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }
  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Add Banner
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <BannerForm
              handleBannerForm={handleAddBanner}
              btn="Add"
              isLoading={AddBannerLoading}
            />
          }
          title="Add Banner"
        />
      ) : null}
    </div>
  );
};

export default AddBanner;
