import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialLanguageFieldValue } from "../../../../../constants";
import { clearLanguage, getLanguage } from "../../../../../utils/auth";
import { editLabel, getLabelData, getLabelList } from "../actions";
import { getLabelFilter } from "../selectors";

export const useAddLabel = ({ id }) => {
  const dispatch = useDispatch();
  const [EditLabelLoading, setEditLabelLoading] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const filters = useSelector(getLabelFilter);

  const getLableById = async () => {
    const selectedLanguage = getLanguage();
    setEditLabelLoading(true);
    const { label } = await dispatch(getLabelData(id));
    if (label) {
      label.title = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: label.title,
      };
      setSelectedLabel(label);
      toggleModal();
    }
    setEditLabelLoading(false);
  };

  const handleLanguageData = async (rest, setFormValues) => {
    const selectedLanguage = getLanguage();
    setEditLabelLoading(true);
    const { label } = await dispatch(getLabelData(id));
    if (label) {
      label.title = {
        ...rest.values.title,
        [selectedLanguage]:
          rest.values.title[selectedLanguage] || label.title || "",
      };
      label.language = selectedLanguage;
      setSelectedLabel(label);
      setFormValues(label);
    }
    setEditLabelLoading(false);
  };

  const handleEditLabel = async (values) => {
    try {
      setEditLabelLoading(true);
      const payload = {
        title: values.title,
        bg_color_code: values.bg_color_code,
        text_color_code: values.text_color_code,
      };
      setEditLabelLoading(false);
      const is_success = await dispatch(editLabel(payload, id));
      if (is_success) {
        dispatch(getLabelList(filters));
        toggleModal();
      }
      setEditLabelLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    EditLabelLoading,
    handleEditLabel,
    selectedLabel,
    getLableById,
    toggleModal,
    isOpenModal,
    handleLanguageData,
  };
};
