import React from "react";
import { Formik, ErrorMessage } from "formik";
import { Chip } from "@material-ui/core";
import { formSchema } from "./validation-schema";
import { useHandleFieldFocus } from "../../../../../utils/form";
import { useUploadFiles } from "../../../../../utils/upload-file/hooks";
import { fileContext } from "../../../../../constants";
import ProgressChip from "../../../../../components/progress-chip";

const NoteUploadForm = ({ btn, handleAddNote, files, isUpdating }) => {
  const {
    handleNoteFile,
    isFileUploadData,
    uploadProgress,
    handleDeleteAFile,
  } = useUploadFiles();
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={formSchema}
      initialValues={{
        file: files.filter((file) => file.file_context === fileContext.NOTE),
        isUpdating,
      }}
      onSubmit={handleAddNote}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="form__form-group-field form__margin-bottom">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="noteFile">Choose Note</label>

                  {files
                    .filter(
                      (item) => item.file_context === `${fileContext.NOTE}`
                    )
                    .map((item) => (
                      <Chip
                        key={item?.original_file_name}
                        label={
                          <a href={item?.url || "#"}>
                            {item?.original_file_name}
                          </a>
                        }
                        variant="outlined"
                        onDelete={() => {
                          handleDeleteAFile(item);
                          setFieldValue("file", "");
                        }}
                      />
                    ))}

                  {isFileUploadData
                    .filter(
                      (file) => file.fileContext === `${fileContext.NOTE}`
                    )
                    .map((x, i) => {
                      return (
                        <div key={x + i}>
                          {x.isUpload && (
                            <ProgressChip
                              fileName={x.fileName}
                              uploadProgress={uploadProgress}
                            />
                          )}
                        </div>
                      );
                    })}

                  <input
                    type="file"
                    accept="application/pdf"
                    name={"file"}
                    id="noteFile"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      setFieldValue(
                        "file",
                        files.filter(
                          (file) => file.file_context === fileContext.NOTE
                        )
                      );
                      handleNoteFile(event.target.files[0]);
                    }}
                    onFocus={() => handleFocusField("file")}
                    onBlur={() => handleBlurField()}
                  />
                  {focusField === "file" ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error blog__margin__top5">
                          {msg}
                        </div>
                      )}
                      name={"file"}
                    />
                  )}
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default NoteUploadForm;
