import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActivePlans } from "../../actions";
import { getActivePlansList, getPaymentLink } from "../../selectors";

const formField = {
  code: "",
  plan_variants: "",
  price: "",
};

export const useGeneratePaymentLinkForm = () => {
  const dispatch = useDispatch();
  const plans = useSelector(getActivePlansList);
  const payment_link = useSelector(getPaymentLink);
  const [formValues, setFormvalues] = useState(formField);

  const handleFormUpdate = (event) => {
    const { name, value } = event.target;
    if (name === "code") {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        plan_variants: "",
        type: "",
        [name]: value,
      }));
    } else if (name === "type") {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        plan_variants: "",
        [name]: value,
      }));
    } else {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [name]: value,
      }));
    }
  };

  const fetchPlans = async (code) => {
    await dispatch(getActivePlans({ codes: [code] }));
  };

  return {
    plans,
    payment_link,
    fetchPlans,
    handleFormUpdate,
    formValues,
  };
};
