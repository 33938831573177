import React from "react";
import Scrollbar from "react-smooth-scrollbar";
import classNames from "classnames";
import { Input } from "reactstrap";
import { useChats } from "./hooks";
import moment from "moment";
import userLogo from "./../../../../../assets/images/user-icon.png";
import Col from "reactstrap/lib/Col";
import SearchFilter from "../../../../../components/filters/search-filter";
import SelectFilter from "../../../../../components/filters/select-filter";
import { commentsStatusOptions, pageSize } from "./../../comments/constant";
import { TextField, Tooltip } from "@material-ui/core";
import Modals from "../../../../../components/modal";
import { Loader } from "../../../../../components/loader";
import ReactTablePagination from "../../../../../shared/components/table/components/ReactTablePagination";
import { useUser } from "../../../../../common-hooks/user";
const Chat = () => {
  const {
    comments,
    replies,
    mainComment,
    updateCommentStatus,
    setFilter,
    filters,
    currentUser,
    toggleModal,
    isOpenModal,
    reason,
    setReason,
    replyId,
    commentId,
    loadingComments,
    openContacts,
    id,
    onOpenChat,
    reply,
    handleSendClick,
    onOpenContacts,
    handleOnChange,
    labelCount,
  } = useChats();

  const { isAdmin, isModerator } = useUser();

  const commentFilter = () => (
    <>
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search Blog Title"
        filterKey="trackable_title"
        filterTitle=" Blog Title"
        searchedValue={filters?.trackable_title}
      />
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search Blog ID"
        filterKey="trackable_id"
        filterTitle="Blog ID"
        searchedValue={filters?.trackable_id}
      />
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        filterKey="status"
        options={commentsStatusOptions}
        defaultOption="All Statuses"
        filterTitle="Status"
      />
    </>
  );

  const chatClass = classNames({
    chat: true,
    "chat--open": openContacts,
  });

  const contactsClass = classNames({
    "chat__contact-list": true,
    "chat__contact-list--open": openContacts,
  });

  return (
    <>
      <Col md={12}>
        <div className="react-table__wrapper">
          <div className="card__title card__title__flex">
            <h3 className="page-title">
              <div className="bold-text">COMMENTS</div>
            </h3>
            {commentFilter()}
            {/* <div className="add-btn">{addBtn}</div> */}
          </div>
        </div>
      </Col>
      {comments.length === 0 && (
        <div className="table__no-result-found">
          No results found. Please try with different criteria.
        </div>
      )}
      <div
        style={{ display: "flex" }}
        className={chatClass}
        onClick={openContacts ? onOpenContacts : null}
        role="presentation"
      >
        {loadingComments ? (
          <Loader />
        ) : (
          <div className={contactsClass}>
            <div className="chat__contacts">
              <Scrollbar
                className="scroll chat__contacts-scroll"
                alwaysShowTracks
              >
                {comments.length > 0 &&
                  comments.map((c, i) => (
                    <button
                      key={c?.id}
                      type="button"
                      onClick={(e) => {
                        onOpenChat(c?.replyTo?.id || c?.id);
                      }}
                    >
                      <div
                        className={`chat__contact ${
                          c?.id === mainComment?.id
                            ? "chat__contact--active"
                            : null
                        }`}
                      >
                        <div className="chat__contact-avatar">
                          <img
                            src={c?.commenter?.profile_picture?.url || userLogo}
                            alt="ava"
                          />
                        </div>
                        <div className="chat__contact-preview">
                          <p className="chat__contact-name">
                            {c?.commenter?.name}
                          </p>
                          <p className="chat__contact-last-message">
                            {c?.text}
                          </p>
                          <p className="chat__contact-last-message">
                            <b>{c?.trackable_details?.name}</b>
                          </p>
                        </div>
                      </div>
                    </button>
                  ))}
              </Scrollbar>
            </div>
          </div>
        )}
        {!mainComment ? null : (
          <div className="chat__dialog">
            {mainComment?.status && (
              <div className="chat__topbar">
                <div className="chat__topbar-contact">
                  <p className="chat__topbar-contact-name">
                    {mainComment?.commenter?.name}
                  </p>
                  <p className="chat__topbar-contact-post">
                    {mainComment?.trackable_details?.name}
                  </p>
                </div>
              </div>
            )}
            <Scrollbar className="scroll chat__scroll" alwaysShowTracks>
              <div className="chat__dialog-messages-wrap">
                {!mainComment ? null : (
                  <>
                    <div className="chat__dialog-messages" id="testVishal">
                      {mainComment?.status && (
                        <div
                          className={`chat__bubble ${
                            mainComment?.commenter?.id === id
                              ? "chat__bubble--active"
                              : null
                          }`}
                        >
                          <div className="row">
                            <div className="chat__bubble-avatar">
                              <img
                                src={
                                  mainComment.commenter?.profile_picture?.url ||
                                  userLogo
                                }
                                alt="ava"
                              />
                            </div>
                            {(isAdmin || isModerator) && (
                              <div className="col-10">
                                {mainComment?.status === "Pending" ? (
                                  <div className="pull-right">
                                    <Tooltip
                                      title={"Click to Publish"}
                                      placement="bottom"
                                    >
                                      <i
                                        className="fa fa-check-circle table__icon-active icon-btn-action"
                                        onClick={() => {
                                          updateCommentStatus(
                                            "Published",
                                            mainComment?.id,
                                            mainComment?.replyTo?.id ||
                                              mainComment?.id
                                          );
                                        }}
                                      ></i>
                                    </Tooltip>
                                    <Tooltip
                                      title="Click to Unpublish"
                                      placement="bottom"
                                    >
                                      <i
                                        className="fa fa-times-circle table__icon-deactive icon-btn-action pad__left10"
                                        onClick={() => {
                                          updateCommentStatus(
                                            "Unpublished",
                                            mainComment?.id,
                                            mainComment?.replyTo?.id ||
                                              mainComment?.id
                                          );
                                        }}
                                      ></i>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  <div className="pull-right">
                                    <Tooltip
                                      onClick={() => {
                                        updateCommentStatus(
                                          mainComment?.status === "Published"
                                            ? "Unpublished"
                                            : "Published",
                                          mainComment?.id,
                                          mainComment?.replyTo?.id ||
                                            mainComment?.id
                                        );
                                      }}
                                      title={`${
                                        mainComment?.status === "Published"
                                          ? "Click to Unpublish"
                                          : "Click to Publish"
                                      }`}
                                      placement="bottom"
                                    >
                                      <i
                                        className={`fa fa-${
                                          mainComment?.status === "Published"
                                            ? "check"
                                            : "times"
                                        }-circle table__icon-${
                                          mainComment?.status === "Published"
                                            ? "active"
                                            : "deactive"
                                        } icon-btn-action `}
                                      ></i>
                                    </Tooltip>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="chat__bubble-message-wrap">
                            <p className="chat__bubble-contact-name">
                              {mainComment?.commenter?.name}
                            </p>
                            <p className="chat__bubble-message">
                              {mainComment?.text}
                            </p>
                            <p className="chat__bubble-date">
                              {moment(mainComment?.created_at).format("LL LT")}
                            </p>
                          </div>
                        </div>
                      )}
                      {replies.map((m, i) => (
                        <div
                          key={m?.id}
                          className={`chat__bubble ${
                            m?.commenter?.id === id
                              ? "chat__bubble--active"
                              : null
                          }`}
                        >
                          <div className="row">
                            <div className="chat__bubble-avatar">
                              <img
                                src={
                                  m?.commenter?.profile_picture?.url || userLogo
                                }
                                alt="ava"
                              />
                            </div>
                            <div className="col-10" style={{ float: "right" }}>
                              {m?.status === "Pending" ? (
                                <div className="pull-right">
                                  <Tooltip
                                    title="Click to Publish"
                                    placement="bottom"
                                  >
                                    <i
                                      className="fa fa-check-circle table__icon-active icon-btn-action"
                                      onClick={() => {
                                        updateCommentStatus(
                                          "Published",
                                          m?.id,
                                          mainComment?.replyTo?.id ||
                                            mainComment?.id
                                        );
                                      }}
                                    ></i>
                                  </Tooltip>
                                  <Tooltip
                                    title="Click to Unpublish"
                                    placement="bottom"
                                  >
                                    <i
                                      className="fa fa-times-circle table__icon-deactive icon-btn-action pad__left10"
                                      onClick={() => {
                                        updateCommentStatus(
                                          "Unpublished",
                                          m?.id,
                                          mainComment?.replyTo?.id ||
                                            mainComment?.id
                                        );
                                      }}
                                    ></i>
                                  </Tooltip>
                                </div>
                              ) : (
                                <div className="pull-right">
                                  <Tooltip
                                    onClick={() => {
                                      updateCommentStatus(
                                        m?.status === "Published"
                                          ? "Unpublished"
                                          : "Published",
                                        m?.id,
                                        mainComment?.replyTo?.id ||
                                          mainComment?.id
                                      );
                                    }}
                                    title={`${
                                      m?.status === "Published"
                                        ? "Click to Unpublish"
                                        : "Click to Publish"
                                    }`}
                                    placement="bottom"
                                  >
                                    <i
                                      className={`fa fa-${
                                        m?.status === "Published"
                                          ? "check"
                                          : "times"
                                      }-circle table__icon-${
                                        m?.status === "Published"
                                          ? "active"
                                          : "deactive"
                                      } icon-btn-action`}
                                    ></i>
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="chat__bubble-message-wrap">
                            <p className="chat__bubble-contact-name">
                              {m?.commenter?.name}
                            </p>
                            <p className="chat__bubble-message">{m?.text}</p>
                            <p className="chat__bubble-date">
                              {moment(m?.created_at).format("LL LT")}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </Scrollbar>
            {currentUser && (
              <div className="chat__text-field">
                <form onSubmit={handleSendClick} className="chat__form">
                  <Input
                    className="chat__field-input"
                    placeholder="Type here…"
                    value={reply}
                    onChange={handleOnChange}
                  />
                  <div className="chat__field-controls">
                    <button type="submit" className="chat__icon-button">
                      <i className="fa fa-send"></i>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
      </div>

      <Col style={{ marginTop: "20px" }} md={5}>
        <ReactTablePagination
          page={comments}
          pageSize={pageSize}
          count={labelCount}
          skip={filters?.skip}
          handleSetFilter={setFilter}
          setPageSize={() => {}}
        />
      </Col>

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <>
              <form className="material-form">
                <div>
                  <TextField
                    className="material-form__field"
                    name="reason"
                    multiline
                    placeholder="Reason"
                    label="Reason"
                    type="text"
                    value={reason}
                    onChange={(event) => {
                      setReason(event.target.value);
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={(e) => {
                    updateCommentStatus("Unpublished", replyId, commentId);
                  }}
                  className="btn btn-success btn-right btn-sm"
                  disabled={!reason.trim()}
                >
                  Unpublish Comment
                </button>
              </form>
            </>
          }
          title="Reason for Unpublish Comment"
        />
      ) : null}
    </>
  );
};

export default Chat;
