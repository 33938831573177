import React from "react";
import {
  columns,
  reportsFilterOption,
  storyFilterStatus,
  userRoles,
} from "./constant";
import Tooltip from "@material-ui/core/Tooltip";
import { useStories } from "./hooks";
import SelectFilter from "../../../components/filters/select-filter";
import SearchFilter from "../../../components/filters/search-filter";
import DataTable from "../../../components/data-table";
import UnpublishStory from "../posts/unpublish-post";
import Reactions from "../posts/components/reactions";
import ViewersList from "../../users/user-details/viewers-list";
import moment from "moment";
import { useUser } from "../../../common-hooks/user";
import ConfidenceScore from "../../../components/confidence-score";
import { trackableTypes } from "../../../constants";

const Stories = () => {
  const {
    loadingStories,
    stories,
    count,
    filters,
    setFilter,
    resetFilter,
    updateStory,
    handleReportClick,
  } = useStories();

  const { isAdmin, isModerator } = useUser();
  const configureTableData = () => {
    try {
      let results = stories.map((story) => {
        let newStory = Object.assign({}, story);
        newStory["caption"] = story?.caption ? story?.caption : "NA";
        newStory["action"] =
          isAdmin || isModerator ? (
            <div className="d-flex">
              <ConfidenceScore
                data={story}
                trackableType={trackableTypes.STORY}
                className="mr-3"
              />
              <UnpublishStory
                handleSubmit={updateStory}
                data={story}
                status={story?.status}
              />
            </div>
          ) : null;

        newStory["reports"] = story?.stats?.reports_count ? (
          <Tooltip title="View Reports">
            <span
              id={story?.id}
              className="schedule__booked-info"
              onClick={handleReportClick}
            >
              {story?.stats?.reports_count}{" "}
              {story?.stats?.reports_count > 1 ? "reports" : "report"}
            </span>
          </Tooltip>
        ) : (
          "NA"
        );

        newStory["background_color"] = story?.background_color ? (
          <div className="label__container">
            <p
              className="custom__background__color"
              style={{
                backgroundColor: `${story?.background_color}`,
                color: "#fff",
              }}
            >
              {story?.background_color}
            </p>
          </div>
        ) : (
          "NA"
        );

        newStory["reactions"] = newStory["reactions"] = story?.stats
          ?.reactions_count ? (
          <Reactions
            id={story?.id}
            totalReactions={story?.stats?.reactions_count}
            trackableType="STORY"
            type="STORY"
          />
        ) : (
          "NA"
        );

        newStory["viewers"] = <ViewersList story={story} />;

        newStory["created_at"] = moment(story?.created_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );
        newStory["updated_at"] = moment(story?.updated_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );

        newStory["url"] = story?.file?.url ? (
          <a
            href={story?.file?.url || ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            {story?.file?.url}
          </a>
        ) : (
          "NA"
        );

        newStory["reason"] = story?.reason || "NA";

        return newStory;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const storyFilter = () => (
    <>
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="User ID"
        filterKey="user_id"
        filterTitle="User ID"
        searchedValue={filters?.user_id}
      />

      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Username"
        filterKey="name"
        filterTitle="Username"
        searchedValue={filters?.name}
      />

      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Mobile Number"
        filterKey="mobile_number"
        filterTitle="Mobile Number"
        searchedValue={filters?.mobile_number}
      />

      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Caption"
        filterKey="caption"
        filterTitle="Caption"
        searchedValue={filters?.caption}
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters.reported}
        filterKey="reported"
        options={reportsFilterOption}
        filterTitle="Reported Stories"
        defaultOptionValue={false}
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.role}
        filterKey="role"
        options={userRoles}
        defaultOption="All Roles"
        filterTitle="Roles"
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        filterKey="status"
        options={storyFilterStatus}
        defaultOption="All"
        filterTitle="Status"
      />

      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={resetFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );
  return (
    <DataTable
      title="Stories"
      CreateTableData={configureTableData}
      withFilter={true}
      filter={storyFilter}
      handleSetFilter={setFilter}
      count={count}
      skip={filters?.skip}
      isLoading={loadingStories}
    />
  );
};

export default Stories;
