import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotice, getNoticeList } from "../actions";
import { getNoticesFilter } from "../selectors";

export const useDeleteNotice = ({ id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [deleteNoticeLoading, setDeleteNoticeLoading] = useState(false);
  const filter = useSelector(getNoticesFilter);

  const handleDeleteNotice = async () => {
    setDeleteNoticeLoading(true);
    const is_success = await dispatch(deleteNotice(id));
    setDeleteNoticeLoading(false);
    if (is_success) {
      dispatch(getNoticeList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteNoticeLoading,
    handleDeleteNotice,
    isOpenModal,
    toggleModal,
  };
};
