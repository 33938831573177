import { api, apiEndPoints } from "../../../api";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";

const namespace = "containers/commentAndReplies";

export const SET_COMMENT_AND_REPLY_LIST = `${namespace}/SET_COMMENT_AND_REPLY_LIST`;
export const SET_COMMENT_AND_REPLY_FILTER = `${namespace}/SET_COMMENT_AND_REPLY_FILTER`;
export const CLEAR_COMMENT_AND_REPLY_FILTER = `${namespace}/CLEAR_COMMENT_AND_REPLY_FILTER`;

export const setCommentAndReplyList = (payload) => ({
  type: SET_COMMENT_AND_REPLY_LIST,
  payload,
});

export const setCommentAndReplyFilter = (payload) => ({
  type: SET_COMMENT_AND_REPLY_FILTER,
  payload,
});

export const resetCommentAndReplyFilter = (payload) => ({
  type: CLEAR_COMMENT_AND_REPLY_FILTER,
  payload,
});

export const getCommentAndReplyList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCommentsAndReplies, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setCommentAndReplyList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updateStatus = (id, payload) => async (dispatch) => {
  try {
    const response = await api.patch(
      apiEndPoints.changeCommentStatus(id),
      payload
    );
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};
