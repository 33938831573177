import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFaq, getCategoryList, getFaqsList } from "../actions";
import { categoryParams } from "../constant";
import { getFaqsFilter, getCategories } from "../selectors";

export const useAddFaq = () => {
  const dispatch = useDispatch();
  const filter = useSelector(getFaqsFilter);
  const categories = useSelector(getCategories);

  const [addFaqLoading, setAddFaqLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleAddFaq = async (values) => {
    try {
      const payload = {
        question: values.question,
        answer: values.answer,
        status: values.status,
        category_id: values.category,
      };
      setAddFaqLoading(true);
      const is_success = await dispatch(addFaq(payload));
      setAddFaqLoading(false);
      if (is_success) {
        dispatch(getFaqsList(filter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFetchCategory = async () => {
    const is_success = await dispatch(getCategoryList(categoryParams));
    if (is_success) {
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    addFaqLoading,
    categories,
    handleAddFaq,
    isOpenModal,
    toggleModal,
    handleFetchCategory,
  };
};
