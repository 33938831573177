import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import * as moment from "moment";

function DashboardCard({ title, details, handleChangeCard }) {
  return (
    <Col className="finance__dashboard-card" md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody
          onClick={() => handleChangeCard(details?.from, details?.to)}
          className="dashboard__card-widget"
        >
          <div className="card__title">
            <h5 className="bold-text">{title}</h5>
            <h5 className="subhead">
              {details?.from
                ? `${moment(new Date(details?.from)).format(
                    "DD-MM-YYYY"
                  )} to ${moment(new Date(details?.to)).format("DD-MM-YYYY")}`
                : "NA"}
            </h5>
          </div>
          <div className="dashboard__total dashboard__total--area">
            <p className="dashboard__total-stat">
              {details?.amount === 0 || details?.amount
                ? `₹ ${details?.amount?.toFixed(2)}`
                : "NA"}
            </p>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default DashboardCard;
