import {
  SET_EVENT_FILTER,
  CLEAR_EVENT_FILTER,
  SET_USER_EVENT_LIST,
  SET_USER_TRANSACTION_LIST,
  SET_TRANSACTION_FILTER,
  CLEAR_TRANSACTION_FILTER,
  SET_PAYMENT_FILTER,
  CLEAR_PAYMENT_FILTER,
  SET_USER_PAYMENT_LIST,
  SET_SESSIONS_COUNT,
  SET_REVIEWS_LIST,
  SET_REVIEWS_FILTER,
  CLEAR_REVIEWS_FILTER,
  SET_WAITLIST_FILTER,
  CLEAR_WAITLIST_FILTER,
  SET_USER_WAITLIST,
  SET_REFUND_DETAILS,
  SET_EVENT_LIST,
  SET_SCHEDULED_EVENT_TIMING,
  SET_REFERAL_LIST,
  SET_ACTIVE_PLANS,
  SET_PAYMENT_LINK,
  CLEAR_PAYMENT_LINK,
  SET_REFERRALS_FILTER,
  CLEAR_REFERRALS_FILTER,
  SET_PARTNER_OFFER_LIST,
  SET_OFFER_LIST_FILTER,
  CLEAR_OFFER_LIST_FILTER,
  SET_COURSE_LIST,
  SET_COURSE_FILTER,
  CLEAR_COURSE_FILTER,
  SET_POST_FILTER,
  SET_POST_LIST,
  CLEAR_POST_FILTER,
  SET_USER_STORIES,
  SET_FRIENDS_FILTER,
  SET_FRIENDS_LIST,
  CLEAR_FRIENDS_FILTER,
  CLEAR_PRIVACY_SETTINGS_FILTER,
  SET_PRIVACY_SETTINGS_FILTER,
  SET_PRIVACY_SETTINGS_LIST,
  SET_NOTIFICATIONS_FILTER,
  SET_NOTIFICATIONS_LIST,
  CLEAR_NOTIFICATIONS_FILTER,
  SET_STORIES_FILTER,
  CLEAR_STORIES_FILTER,
  SET_INSTRUCTORS_LIST,
  SET_INSTRUCTORS_FILTER,
  CLEAR_INSTRUCTORS_FILTER,
  SET_BOOKEVENT_FILTER,
  CLEAR_BLOCKED_TO_FILTER,
  CLEAR_REPORTS_FILTER,
  SET_BLOCKED_TO_FILTER,
  SET_BLOCKED_TO_LIST,
  SET_REPORTS_FILTER,
  SET_REPORTS_LIST,
} from "./actions";
import * as moment from "moment";

const initialList = {
  event_filter: {
    status: "Past",
    booking_statuses: ["Attended", "Booked", "Cancelled"],
    utc_offset: moment().utcOffset(),
    limit: 10,
    skip: 0,
    is_free: undefined,
    exclude_course_schedules: true,
  },

  book_events_list: [],
  event_timeslots_list: [],
  book_event_filter: {
    status: "Published",
    limit: 10,
    skip: 0,
    text: "",
  },

  events: [],
  event_count: 0,
  transactions: [],
  transaction_count: 0,

  transaction_filter: {
    created_at: {
      start: "",
      end: "",
    },
    transaction_type: "",
    type: "Regular",
    utc_offset: moment().utcOffset(),
    limit: 10,
    skip: 0,
  },

  refundDetails: {},

  payments: [],
  payment_count: 0,
  payment_filter: {
    limit: 10,
    skip: 0,
    id: null,
    transaction_id: "",
    utc_offset: moment().utcOffset(),
  },
  regular_count: 0,
  bonus_count: 0,
  reviews: [],

  reviews_filter: {
    limit: 10,
    skip: 0,
    status: "",
  },
  reviews_count: 0,

  waitlists: [],
  waitlist_count: 0,
  waitlistFilter: {
    limit: 10,
    skip: 0,
  },
  referrals: [],
  referrals_filter: {
    limit: 10,
    skip: 0,
  },
  referral_count: 0,

  partner_offers: [],
  partner_offers_filter: {
    limit: 10,
    skip: 0,
  },
  partner_offers_count: 0,

  active_plans: [],
  payment_link: "",
  courses_filter: {
    // statuses: [],
    // booking_statuses: ["Attended", "Booked", "Cancelled"],
    // finished: true,
    utc_offset: moment().utcOffset(),
    limit: 10,
    skip: 0,
  },

  posts: [],
  posts_count: 0,
  post_filter: {
    status: "",
    created_at: {
      from: "",
      to: "",
    },
    roles: [],
    caption: "",
    user_id: null,
    name: "",
    mobile_number: "",
    eg_tagged: "",
    reported: false,
    event_title: "",
    poll_question: "",
    hashtag: "",
    limit: 10,
    skip: 0,
  },

  stories: [],
  stories_filter: {
    limit: 10,
    skip: 0,
  },
  stories_count: 0,

  friends: [],
  friends_filter: {
    limit: 10,
    skip: 0,
    text: "",
  },
  friends_count: 0,

  privacy_settings: [],
  privacy_settings_filter: {
    privacy_type: "PROFILE",
    //  or ‘POST’
  },
  notifications: [],
  notifications_filter: { limit: 10, skip: 0, filter: "All" },
  notifications_count: 0,

  instructors: [],
  instructors_filter: {
    text: "",
    limit: 10,
    skip: 0,
  },
  instructors_count: 0,

  reports: [],
  reports_filter: {
    limit: 10,
    skip: 0,
  },
  reports_count: 0,

  blocked_to_users: [],
  blocked_to_users_filter: {
    limit: 10,
    skip: 0,
  },
  blocked_to_users_count: 0,
};

const userDetailsReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_INSTRUCTORS_LIST: {
      return {
        ...state,
        instructors: action.payload.followed_instructors,
        instructors_count: action.payload.count,
      };
    }

    case SET_INSTRUCTORS_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        instructors_filter: {
          ...state.instructors_filter,
          [key]: value,
        },
      };
    }

    case CLEAR_INSTRUCTORS_FILTER: {
      return {
        ...state,
        instructors_filter: initialList.instructors_filter,
      };
    }
    case SET_NOTIFICATIONS_LIST: {
      return {
        ...state,
        notifications: action.payload.notifications,
        notifications_count: action.payload.count,
      };
    }

    case SET_NOTIFICATIONS_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        notifications_filter: {
          ...state.notifications_filter,
          [key]: value,
        },
      };
    }

    case CLEAR_NOTIFICATIONS_FILTER: {
      return {
        ...state,
        notifications_filter: initialList.notifications_filter,
      };
    }
    case SET_PRIVACY_SETTINGS_LIST: {
      return {
        ...state,
        privacy_settings: action.payload.user_privacy_settings,
      };
    }
    case SET_PRIVACY_SETTINGS_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        privacy_settings_filter: {
          [key]: value,
        },
      };
    }

    case CLEAR_PRIVACY_SETTINGS_FILTER: {
      return {
        ...state,
        privacy_settings_filter: initialList.privacy_settings_filter,
      };
    }
    case SET_FRIENDS_LIST: {
      return {
        ...state,
        friends: action.payload.friends,
        friends_count: action.payload.count,
      };
    }

    case SET_FRIENDS_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        friends_filter: {
          ...state.friends_filter,
          [key]: value,
        },
      };
    }

    case CLEAR_FRIENDS_FILTER: {
      return {
        ...state,
        friends_filter: initialList.friends_filter,
      };
    }
    case SET_POST_LIST: {
      return {
        ...state,
        posts_count: action.payload.count,
        posts: action.payload.posts,
      };
    }

    case SET_POST_FILTER: {
      const { key, value } = action.payload;
      if (key === "user_id") {
        return {
          ...state,
          post_filter: {
            ...state.post_filter,
            [key]: value ? value : null,
          },
        };
      }
      return {
        ...state,
        post_filter: {
          ...state.post_filter,
          [key]: value,
        },
      };
    }

    case CLEAR_POST_FILTER: {
      return {
        ...state,
        post_filter: {
          ...initialList.post_filter,
          user_id: state?.post_filter?.user_id,
        },
      };
    }

    case SET_USER_STORIES: {
      return {
        ...state,
        stories: action.payload.user_stories?.stories,
        stories_count: action.payload?.stories_count || 0,
      };
    }

    case SET_STORIES_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        stories_filter: {
          ...state.stories_filter,
          [key]: value,
        },
      };
    }

    case CLEAR_STORIES_FILTER: {
      return {
        ...state,
        stories_filter: initialList.stories_filter,
      };
    }
    case SET_EVENT_FILTER: {
      const { key, value } = action.payload;
      if (key === "status") {
        return {
          ...state,
          event_filter: {
            ...state.event_filter,
            [key]: value,
            skip: 0,
          },
        };
      } else if (key === "booking_statuses") {
        return {
          ...state,
          event_filter: {
            ...state.event_filter,
            [key]: value ? [value] : ["Attended", "Booked", "Cancelled"],
            skip: 0,
          },
        };
      }

      if (key === "is_free") {
        return {
          ...state,
          event_filter: {
            ...state.event_filter,
            [key]: value === "" ? undefined : value === "Free",
          },
        };
      }
      return {
        ...state,
        event_filter: {
          ...state.event_filter,
          [key]: value,
        },
      };
    }
    case CLEAR_EVENT_FILTER:
      return { ...state, event_filter: initialList.event_filter };
    case SET_COURSE_LIST:
      return {
        ...state,
        courses: action.payload.courses,
        courses_count: action.payload.count,
      };
    case SET_COURSE_FILTER: {
      const { key, value } = action.payload;
      if (key === "booking_statuses") {
        return {
          ...state,
          courses_filter: {
            ...state.courses_filter,
            [key]: value ? [value] : ["Attended", "Booked", "Cancelled"],
            skip: 0,
          },
        };
      }
      return {
        ...state,
        courses_filter: {
          ...state.courses_filter,
          [key]: value,
        },
      };
    }
    case CLEAR_COURSE_FILTER:
      return { ...state, courses_filter: initialList.courses_filter };
    case SET_REFUND_DETAILS:
      return { ...state, refundDetails: action.payload };
    case SET_USER_EVENT_LIST:
      return {
        ...state,
        events: action.payload.event_schedules,
        event_count: action.payload.count,
      };
    case SET_PARTNER_OFFER_LIST:
      return {
        ...state,
        partner_offers: action.payload.partner_offers,
        partner_offers_count: action.payload.count,
      };
    case SET_TRANSACTION_FILTER: {
      const { key, value } = action.payload;
      if (key === "type") {
        return {
          ...state,
          transaction_filter: {
            ...state.transaction_filter,
            [key]: value,
            transaction_type: "",
            skip: 0,
          },
        };
      } else if (key === "transaction_type") {
        return {
          ...state,
          transaction_filter: {
            ...state.transaction_filter,
            [key]: value,
            skip: 0,
          },
        };
      } else if (key === "start" || key === "end") {
        return {
          ...state,
          transaction_filter: {
            ...state.transaction_filter,
            created_at: {
              ...state.transaction_filter.created_at,
              [key]: value,
            },
            skip: 0,
          },
        };
      }
      return {
        ...state,
        transaction_filter: {
          ...state.transaction_filter,
          [key]: value,
        },
      };
    }
    case SET_USER_TRANSACTION_LIST:
      return {
        ...state,
        transactions: action.payload.transaction_histories,
        transaction_count: action.payload.count,
      };
    case CLEAR_TRANSACTION_FILTER:
      return { ...state, transaction_filter: initialList.transaction_filter };
    case SET_PAYMENT_FILTER: {
      const { key, value } = action.payload;
      if (key === "id") {
        return {
          ...state,
          payment_filter: {
            ...state.payment_filter,
            [key]: parseInt(value),
            skip: 0,
          },
        };
      }
      return {
        ...state,
        payment_filter: {
          ...state.payment_filter,
          [key]: value,
        },
      };
    }
    case SET_USER_PAYMENT_LIST:
      return {
        ...state,
        payments: action.payload.payment_histories,
        payment_count: action.payload.count,
      };
    case CLEAR_PAYMENT_FILTER:
      return { ...state, payment_filter: initialList.payment_filter };
    case SET_SESSIONS_COUNT:
      return {
        ...state,
        regular_count: action.payload.regular.balance,
        bonus_count: action.payload.bonus.balance,
      };
    case SET_REVIEWS_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        reviews_filter: {
          ...state.reviews_filter,
          [key]: value,
        },
      };
    }
    case SET_REVIEWS_LIST:
      return {
        ...state,
        reviews: action.payload.reviews,
        reviews_count: action.payload.count,
      };

    case SET_EVENT_LIST:
      return {
        ...state,
        book_events_list: action.payload.events,
      };

    case SET_BOOKEVENT_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        book_event_filter: { ...state.book_event_filter, [key]: value },
      };
    }
    case SET_SCHEDULED_EVENT_TIMING:
      return {
        ...state,
        event_timeslots_list: action.payload.event_schedules,
      };
    case CLEAR_REVIEWS_FILTER:
      return { ...state, reviews_filter: initialList.reviews_filter };
    case SET_USER_WAITLIST:
      return {
        ...state,
        waitlists: action.payload.waitlists,
        waitlist_count: action.payload.count,
      };
    case SET_WAITLIST_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        waitlistFilter: {
          ...state.waitlistFilter,
          [key]: value,
        },
      };
    }
    case CLEAR_WAITLIST_FILTER:
      return { ...state, waitlistFilter: initialList.waitlistFilter };
    case SET_REFERAL_LIST:
      return {
        ...state,
        referrals: action?.payload?.referrals,
        referral_count: action?.payload?.count,
      };

    case SET_ACTIVE_PLANS:
      return { ...state, active_plans: action.payload.plans };
    case SET_PAYMENT_LINK:
      return { ...state, payment_link: action.payload };
    case CLEAR_PAYMENT_LINK:
      return { ...state, payment_link: initialList.payment_link };
    case SET_REFERRALS_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        referrals_filter: {
          ...state.referrals_filter,
          [key]: value,
        },
      };
    }

    case CLEAR_REFERRALS_FILTER:
      return { ...state, referrals_filter: initialList.referrals_filter };

    case SET_OFFER_LIST_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        partner_offers_filter: {
          ...state.partner_offers_filter,
          [key]: value,
        },
      };
    }
    case CLEAR_OFFER_LIST_FILTER:
      return {
        ...state,
        partner_offers_filter: initialList.partner_offers_filter,
      };

    case SET_REPORTS_LIST:
      return {
        ...state,
        reports: action.payload.reports,
        reports_count: action.payload.count,
      };

    case SET_REPORTS_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        reports_filter: {
          ...state.reports_filter,
          [key]: value,
        },
      };
    }

    case CLEAR_REPORTS_FILTER:
      return {
        ...state,
        reports_filter: initialList.reports_filter,
      };

    //users who have been blocked by this user
    case SET_BLOCKED_TO_LIST:
      return {
        ...state,
        blocked_to_users: action.payload.blocked_users,
        blocked_to_users_count: action.payload.count,
      };

    case SET_BLOCKED_TO_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        blocked_to_users_filter: {
          ...state.blocked_to_users_filter,
          [key]: value,
        },
      };
    }

    case CLEAR_BLOCKED_TO_FILTER:
      return {
        ...state,
        blocked_to_users_filter: initialList.blocked_to_users_filter,
      };
    default:
      return state;
  }
};

export default userDetailsReducer;
