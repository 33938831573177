import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../components/modal";
import DeleteConfirmation from "../../../components/delete-confirmation";
import { useDeleteFaq } from "./hooks";

const DeleteFaq = ({ faq }) => {
  const {
    handleDeleteFaq,
    isOpenModal,
    toggleModal,
    deleteFaqLoading,
  } = useDeleteFaq(faq);
  return (
    <>
      <Tooltip title="Delete" placement="bottom">
        <i
          className={`fa fa-trash icon-btn-action text-danger `}
          onClick={toggleModal}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeleteFaq}
              isLoading={deleteFaqLoading}
            />
          }
          title="Delete FAQ"
        />
      ) : null}
    </>
  );
};

export default DeleteFaq;
