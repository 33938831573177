import React from "react";
import { Button } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import DataTable from "../../components/table";
import { useCourses } from "./hooks";
import { columns, statusOptions } from "./constant";
import SelectFilter from "../../components/filters/select-filter";
import SearchFilter from "../../components/filters/search-filter";
import DeleteCourse from "./delete-course";
import moment from "moment";
import ShowReviews from "./reviews";
import DateFilter from "../../components/filters/date-filter";
import ExportComponent from "./export/index";
import { useUser } from "../../common-hooks/user";

const CourseList = () => {
  const {
    courses,
    loadingCourses,
    setFilter,
    filters,
    courseCount,
    handleEditClick,
    handleAddClick,
    handleViewClick,
    clearFilter,
  } = useCourses();

  const { isAdmin, isModerator } = useUser();

  const configureTableData = () => {
    try {
      let results = courses.map((course) => {
        let newCourse = Object.assign({}, course);
        newCourse["commence_at"] = moment(course.commence_at).format(
          "DD/MM/YYYY @ hh:mm A "
        );
        newCourse["action"] = (
          <div className="categories__list-action">
            <ShowReviews eventScheduleId={course?.id} />
            <Tooltip
              title="Schedules"
              placement="bottom"
              className="blog__pad_left10"
            >
              <i
                className={`fa fa-eye icon-btn-action`}
                onClick={() => handleViewClick(course?.id)}
              ></i>
            </Tooltip>
            {(isAdmin || isModerator) && (
              <>
                <Tooltip
                  title="Edit"
                  placement="bottom"
                  className="blog__pad_left10"
                >
                  <i
                    className={`fa fa-edit icon-btn-action`}
                    onClick={() => handleEditClick(course?.id)}
                  ></i>
                </Tooltip>
              </>
            )}
            <DeleteCourse course={course} />
          </div>
        );
        return newCourse;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const courseFilter = () => (
    <>
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        filterKey="status"
        options={statusOptions}
        defaultOption="All Statuses"
        filterTitle="Status"
      />

      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search Course"
        filterKey="course_title"
        filterTitle="Course"
        searchedValue={filters?.course_title}
      />

      <DateFilter
        handleChange={setFilter}
        selectedValue={filters?.commence_after}
        filterKey={"commence_after"}
        filterTitle="Commence After"
      />

      <DateFilter
        handleChange={setFilter}
        selectedValue={filters?.commence_before}
        filterKey={"commence_before"}
        filterTitle="Commence Before"
      />

      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={clearFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  return (
    <DataTable
      title="Courses"
      addBtn={
        <div className="row course__pad_left15 no__wrap">
          {(isAdmin || isModerator) && (
            <Button
              className="btn btn-success btn-sm btn-pad"
              onClick={handleAddClick}
            >
              <i className="fa fa-plus"></i> Add Course
            </Button>
          )}

          <ExportComponent />
        </div>
      }
      CreateTableData={configureTableData}
      withFilter={true}
      filter={courseFilter}
      handleSetFilter={setFilter}
      count={courseCount}
      skip={filters?.skip}
      isLoading={loadingCourses}
    />
  );
};

export default CourseList;
