import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubategory, getSubategoryList } from "../actions";
import { getSubcategoriesFilter } from "../selectors";

export const useDeleteSubcategory = (id, category_id) => {
  const dispatch = useDispatch();
  const filter = useSelector(getSubcategoriesFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteSubcategoryLoading, setDeleteSubcategoryLoading] =
    useState(false);

  const handleDeleteSubcategory = async () => {
    setDeleteSubcategoryLoading(true);
    const is_success = await dispatch(deleteSubategory(id));
    setDeleteSubcategoryLoading(false);
    if (is_success) {
      dispatch(getSubategoryList(category_id, filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteSubcategoryLoading,
    handleDeleteSubcategory,
    isOpenModal,
    toggleModal,
  };
};
