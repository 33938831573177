import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { trackableTypes } from "../../constants";
import {
  getCommentsReports,
  getPostReports,
  setReportFilter,
  getStoriesReports,
} from "./actions";
import { titleKeys } from "./constant";
import { getCount, getReports, getReportsFilters } from "./selectors";

export const useReports = () => {
  const filters = useSelector(getReportsFilters);
  const reports = useSelector(getReports);
  const count = useSelector(getCount);
  const location = useLocation();
  const { id } = useParams();
  const [loadingReports, setLoadingReports] = useState(false);
  const [trackableType, setTrackableType] = useState(false);
  const [trackableID, setTrackableID] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let fn = getPostReports;
    if (location.pathname.match(/\/post\/(0|[1-9]\d*)\/reports/)) {
      setTrackableType(trackableTypes.POST);
      fn = getPostReports;
    }

    if (
      location.pathname.match(/\/comments-and-replies\/(0|[1-9]\d*)\/reports/)
    ) {
      setTrackableType(trackableTypes.COMMENT);
      fn = getCommentsReports;
    }

    if (location.pathname.match(/\/stories\/(0|[1-9]\d*)\/reports/)) {
      setTrackableType(trackableTypes.STORY);
      fn = getStoriesReports;
    }

    const fetchReports = async () => {
      try {
        if (trackableType) {
          const data = await dispatch(fn(id, filters));
          if (data?.is_success) {
            setLoadingReports(false);
            setTrackableID(data?.reports?.[0][titleKeys[trackableType]]);
          }
        }
      } catch (error) {
        setLoadingReports(false);
      } finally {
        setLoadingReports(false);
      }
    };

    fetchReports();
  }, [location.pathname, dispatch, filters, id, trackableType]);

  const setFilter = (key, value) => {
    dispatch(setReportFilter({ key, value }));
  };

  const handleViewClick = (id) => {
    history.push(`/post/${id}`);
  };
  return {
    filters,
    reports,
    count,
    loadingReports,
    trackableType,
    setFilter,
    handleViewClick,
    trackableID,
  };
};
