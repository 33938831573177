import { api, apiEndPoints } from "../../../../api";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";

const namespace = "containers/labels";

export const SET_COMMENT_LIST = `${namespace}/SET_COMMENT_LIST`;
export const SET_COMMENT_FILTER = `${namespace}/SET_COMMENT_FILTER`;

export const setCommentList = (payload) => ({
  type: SET_COMMENT_LIST,
  payload,
});

export const setCommentFilter = (payload) => ({
  type: SET_COMMENT_FILTER,
  payload,
});


export const getCommentList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getComments, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setCommentList(data))
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getCommentRepliesData = (id) => async () => {
  try {
    const response = await api.get(apiEndPoints.getCommentReplies(id));
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const changeCommentStatusAction = (payload, id) => async (dispatch) => {
  try {
    const response = await api.patch(apiEndPoints.changeCommentStatus(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const replyToCommentAction = (payload, id) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.replyToComment(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};
