import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLanguage } from "../../utils/auth";
import { getEventList, setEventFilter } from "./actions";
import { getEventsFilter, getEvents, getEventsCount } from "./selectors";

export const useEvent = () => {
  const dispatch = useDispatch();
  const events = useSelector(getEvents);
  const filters = useSelector(getEventsFilter);
  const eventCount = useSelector(getEventsCount);

  const [loadingEvent, setLoadingEvent] = useState(false);

  useEffect(() => {
    clearLanguage();
    const fetchEvents = async () => {
      setLoadingEvent(true);
      await dispatch(getEventList(filters));
      setLoadingEvent(false);
    };
    fetchEvents();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setEventFilter({ key, value }));
  };

  return {
    events,
    loadingEvent,
    setFilter,
    filters,
    eventCount,
  };
};
