export const getInterests = ({ interestReducer: { interests = [] } = {} }) =>
  interests;
export const getInterestsFilter = ({ interestReducer: { filter = {} } = {} }) =>
  filter;
export const getInterestsCount = ({ interestReducer: { count = 0 } = {} }) =>
  count;
export const getCategorieList = ({
  interestReducer: { categories = [] } = {},
}) => categories;
export const getCategoryFilter = ({
  interestReducer: { categoryFilter = {} } = {},
}) => categoryFilter;
