import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import { FieldsName } from "../constant";
import { DataLoader } from "../../../../../components/loader";
import { formSchema } from "./validation-schema";
import { statusOptions } from "./../constant";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";

const formField = {
  title: "",
  status: "",
};

const PopularKeywordForm = ({
  fieldValues,
  handlePopularKeywordForm,
  btn,
  isLoading,
}) => {
  const {
    focusField,
    handleFocusField,
    handleBlurField,
  } = useHandleFieldFocus();

  const {
    title,
    status,
  } = FieldsName;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={formSchema}
      initialValues={{
        title: formValues?.title || "",
        status: formValues?.status || "",
      }}
      onSubmit={handlePopularKeywordForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div>
              <TextField
                className="material-form__field"
                name={title}
                placeholder="Title"
                label="Title"
                type="text"
                value={rest.values.title}
                onChange={(event) => {
                  handleFormValueChange(rest, title);
                  handleFormUpdate(title, event.target.value);
                }}
                onFocus={() => handleFocusField(title)}
                onBlur={() => handleBlurField()}
              />
              {focusField === title ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={title}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Select Status"
                select
                value={rest.values.status}
                onChange={(event) => {
                  handleFormValueChange(rest, status);
                  handleFormUpdate(status, event.target.value);
                }}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {statusOptions.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>



            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )
      }
    </Formik >
  );
};

export default PopularKeywordForm;
