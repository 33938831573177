import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

function TambolaTickets({ tickets }) {
  const { ticket: ticket1, id: id1 } = tickets[0];
  const { ticket: ticket2, id: id2 } = tickets[1];
  return (
    <div className="tambola__tickets">
      <div className="tambola__tickets-1">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-2">Ticket 1</h4>
          <h5 className="mb-2">Id: {id1}</h5>
        </div>
        {ticket1?.map((arr, i) => (
          <Grid key={i} container>
            {arr.map((val, j) => (
              <Grid key={i + j} item>
                <Paper className="tambola__paper">{val === 0 ? "" : val}</Paper>
              </Grid>
            ))}
          </Grid>
        ))}
      </div>

      <div className="tambola__tickets-2">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-2">Ticket 2</h4>
          <h5 className="mb-2">Id: {id2}</h5>
        </div>
        {ticket2.map((arr, i) => (
          <Grid key={i} container>
            {arr?.map((val, j) => (
              <Grid key={i + j} item>
                <Paper className="tambola__paper">{val === 0 ? "" : val}</Paper>
              </Grid>
            ))}
          </Grid>
        ))}
      </div>
    </div>
  );
}

export default TambolaTickets;
