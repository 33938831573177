import React from 'react'
import { useEditPage } from './hooks'
import PageForm from "../form/page-form";
import Modals from "../../../../../components/modal";
import Tooltip from "@material-ui/core/Tooltip";

const EditPage = ({ page }) => {
  const {
    handleEditPage,
    editPageLoading,
    selectedPage,
    selectedPageSections,
    sectionData,
    toggleModal,
    isOpenModal,
    handleEditClick,
  } = useEditPage(page);
  return (
    <>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action `}
          onClick={handleEditClick}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PageForm
              fieldValues={selectedPage}
              selectedPageSections={selectedPageSections}
              handlePageForm={handleEditPage}
              btn="Save"
              isLoading={editPageLoading}
              sectionData={sectionData}
            />
          }
          title="Edit Page"
        />
      ) : null}
    </>
  )
}

export default EditPage
