import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleBanner, updateBanner, getBannerList } from "../action";
import { getBannerFilter } from "../selectors";
import { clearFiles } from "../../../../utils/upload-file/actions";

export const useEditBanner = ({ banner }) => {
  const { platform, screen, position, status, id } = banner;
  const dispatch = useDispatch();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editBannerLoading, setEditBannerLoading] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState({});

  const filters = useSelector(getBannerFilter);

  const getBannerData = async () => {
    dispatch(clearFiles());
    setEditBannerLoading(true);
    const params = {
      platform,
      screen,
      position,
      status,
    };
    const singleBanner = await dispatch(getSingleBanner(params));

    if (singleBanner) {
      setSelectedBanner({
        platform,
        screen,
        position,
        status,
        banners: singleBanner?.banners,
      });
      toggleModal();
    }
    setEditBannerLoading(false);
  };
  const handleEditBanner = async (values) => {
    try {
      const { platform, screen, position } = values;
      const payload = {
        platform,
        screen,
        position,
        banners: [],
      };

      values.banners.map((banner, i) =>
        banner.event || banner.blog
          ? payload.banners.push({
              id,
              ...banner.file,
              sequence_no: i + 1,
              redirect_to: banner?.redirect_to,
              trackable_id: banner.event ? banner.event.id : banner.blog.id,
              trackable_type: banner.event
                ? "Event Schedule"
                : banner.blog
                ? "Blog"
                : "Other",
            })
          : banner.trackable_id
          ? payload.banners.push({
              id,
              ...banner.file,
              sequence_no: i + 1,
              redirect_to: banner?.redirect_to,
              trackable_id: banner.trackable_id,
              trackable_type: banner.trackable_type,
            })
          : payload.banners.push({
              id,
              ...banner.file,
              sequence_no: i + 1,
              redirect_to: banner?.redirect_to,
              trackable_type: "Other",
            })
      );

      setEditBannerLoading(true);
      const is_success = await dispatch(updateBanner(payload, id));
      setEditBannerLoading(false);
      if (is_success) {
        toggleModal();
        dispatch(getBannerList(filters));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    editBannerLoading,
    handleEditBanner,
    isOpenModal,
    toggleModal,
    getBannerData,
    selectedBanner,
  };
};
