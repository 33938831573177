import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPlan, getPartnersOfferList, getPlanList } from "../actions";
import { getPartnersOfferFilter, getPlansFilter } from "../selectors";

export const useAddPlan = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [addPlanLoading, setAddPlanLoading] = useState(false);
  const filter = useSelector(getPlansFilter);
  const partnerOfferFilter = useSelector(getPartnersOfferFilter);

  const handleAddPlan = async (values) => {
    const payload = {
      plan: {
        code: values.code,
        title: values.title,
        description: values.description,
        status: values.status,
        no_of_sessions: values.no_of_session,
        carry_forward: values.carry_forward,
        type: values.type,
        amount: values.type === "Free" ? 0 : values.plan_amount,
        is_unlimited: values.is_unlimited,
        partner_offer_text: values.partner_offer_text,
        card_bg_start_color: values.card_bg_start_color,
        card_bg_end_color: values.card_bg_end_color,
        card_text_color: values.card_text_color,
      },
      plan_variants: values.variants.map((variant) => {
        return {
          ...variant,
          gross_amount: parseInt(variant.gross_amount),
          partner_offer_ids: variant.partner_offer_ids.map((offer) => offer.id),
        };
      }),
    };
    setAddPlanLoading(true);
    const is_success = await dispatch(addPlan(payload));
    setAddPlanLoading(false);
    if (is_success) {
      dispatch(getPlanList(filter));
      toggleModal();
    }
  };

  const getData = async () => {
    const is_success = await dispatch(getPartnersOfferList(partnerOfferFilter));
    if (is_success) {
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    addPlanLoading,
    handleAddPlan,
    isOpenModal,
    toggleModal,
    getData,
  };
};
