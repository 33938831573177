import React from "react";
import { useDiscussion } from "./hooks";
import styles from "./styles.module.scss";

import DiscussionCard from "../common/discussion-card";
import Text from "../../../components/text";
import { colors } from "../../../constants/colors";
import Modals from "../../../components/modal";
import DiscussionForm from "./form/form";
import SearchFilter from "../../../components/filters/search-filter";
import DateRangeFilter from "../../../components/filters/date-range-filter";
import SelectFilter from "../../../components/filters/select-filter";
import { Tooltip } from "@material-ui/core";
import { postsFilterStatus, userRoles } from "../constant";
import { useUser } from "../../../common-hooks/user";

const Discussions = (props) => {
  const {
    handleFilter,
    session,
    toggleModal,
    isOpenModal,
    handleCreateDiscussion,
    discussionsList,
    totalDiscussions,
    handleUpdateDiscussion,
    handleDeleteDiscussion,
    handleDiscussionFilter,
    discussionFilter,
    clearDiscussionFilter,
  } = useDiscussion(props);

  const { isCoordinator } = useUser();

  return (
    <div className={styles.discussion_container}>
      <div className="d-flex">
        {" "}
        <DateRangeFilter
          handleChange={handleDiscussionFilter}
          selectedValue={discussionFilter?.created_at}
          filterKey="created_at"
          filterTitle="Published Date"
        />
        <SearchFilter
          handleSearch={handleDiscussionFilter}
          searchPlaceholder="User ID"
          filterKey="user_id"
          filterTitle="User ID"
          searchedValue={discussionFilter.user_id}
        />
        <SearchFilter
          handleSearch={handleDiscussionFilter}
          searchPlaceholder="Username"
          filterKey="name"
          filterTitle="Username"
          searchedValue={discussionFilter.name}
        />
        <SearchFilter
          handleSearch={handleDiscussionFilter}
          searchPlaceholder="Caption"
          filterKey="text"
          filterTitle="caption"
          searchedValue={discussionFilter.text}
        />
        <SearchFilter
          handleSearch={handleDiscussionFilter}
          searchPlaceholder="Mobile Number"
          filterKey="mobile_number"
          filterTitle="Mobile Number"
          searchedValue={discussionFilter.mobile_number}
        />
        <SelectFilter
          handleSelect={handleDiscussionFilter}
          selectedValue={discussionFilter.status}
          filterKey="status"
          options={postsFilterStatus}
          defaultOption="All"
          filterTitle="Status"
        />
        <SelectFilter
          handleSelect={handleDiscussionFilter}
          selectedValue={discussionFilter.roles}
          filterKey="roles"
          options={userRoles}
          defaultOption="All Roles"
          filterTitle="Roles"
        />
        <Tooltip title="Clear filters" placement="bottom">
          <div className="filter__reset-all" onClick={clearDiscussionFilter}>
            <i className="fa fa-filter filter-icon"></i>
            <i className="fa fa-times-circle remove-icon"></i>
          </div>
        </Tooltip>
      </div>

      {!isCoordinator && (
        <button
          onClick={toggleModal}
          className={`${styles.create_discussion_button} text-white`}
        >
          Start a new Discussion
        </button>
      )}

      <div>
        <Text
          size="35px"
          fontWeight={600}
        >{`${session?.id}: ${session?.title}`}</Text>
      </div>
      {discussionsList?.length ? (
        discussionsList?.map((discussion, i) => (
          <div key={discussion?.id}>
            <DiscussionCard
              handleUpdateDiscussion={handleUpdateDiscussion}
              discussion={discussion}
              handleDeleteDiscussion={handleDeleteDiscussion}
              index={i}
            />
          </div>
        ))
      ) : (
        <Text
          onClick={handleFilter}
          size="16px"
          lineheight="22px"
          color={colors.brand}
          fontWeight={400}
          className={styles.view_more}
        >
          Data Not Found
        </Text>
      )}

      {totalDiscussions > discussionsList?.length && (
        <Text
          onClick={handleFilter}
          size="16px"
          lineheight="22px"
          color={colors.brand}
          fontWeight={400}
          className={styles.view_more}
        >
          View More
        </Text>
      )}

      <Modals
        isOpenModal={isOpenModal}
        setIsOpenModal={toggleModal}
        title="Start Discussion"
        message={
          <DiscussionForm
            isLoading={false}
            handlePostForm={handleCreateDiscussion}
            btn="Add"
          />
        }
      />
    </div>
  );
};

export default Discussions;
