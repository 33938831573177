import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventReviews, publishUnpublishReview } from "../actions";
import { scheduleEventReviewStatus } from "../constant";
import { getScheduleEventReviewDetails } from "../selectors";

export const useShowReviews = (id) => {
  const dispatch = useDispatch();
  const [loadingReview, setLoadingReview] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { Published, Unpublished } = scheduleEventReviewStatus;

  const reviews = useSelector(getScheduleEventReviewDetails);

  const openReviewsList = async (tab) => {
    setLoadingReview(true);
    const payload = {
      trackable_type: tab === "event" ? "EventSchedule" : "User",
    };

    const reviews = await dispatch(getEventReviews(id, payload));
    if (reviews) {
      setReviewList(reviews);
      setLoadingReview(false);
      toggleModal();
    }
  };

  const changeReviewList = async (tab) => {
    setLoadingReview(true);
    const payload = {
      trackable_type: tab === "event" ? "EventSchedule" : "User",
    };

    const reviews = await dispatch(getEventReviews(id, payload));
    if (reviews) {
      setReviewList(reviews);
      setLoadingReview(false);
    }
  };

  const updateReviewStatus = async (tabData, tab) => {
    const payload = {
      status: tabData?.review?.status === Published ? Unpublished : Published,
    };
    const is_success = await dispatch(
      publishUnpublishReview(payload, tabData?.review?.id)
    );
    if (is_success) {
      const params = {
        trackable_type: tab === "event" ? "EventSchedule" : "User",
      };

      const reviews = await dispatch(getEventReviews(id, params));

      if (reviews) {
        setReviewList(reviews);
      }
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    loadingReview,
    reviewList,
    openReviewsList,
    toggleModal,
    updateReviewStatus,
    isOpenModal,
    scheduleEventReviewStatus,
    changeReviewList,
    reviews,
  };
};
