import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";
import * as moment from "moment";
import { setFiles } from "../../utils/upload-file/actions";

const namespace = "containers/schedule-events";

export const SET_SCHEDULE_EVENT_LIST = `${namespace}/SET_SCHEDULE_EVENT_LIST`;
export const SET_EVENT_LIST = `${namespace}/SET_EVENT_LIST`;
export const SET_EVENT_FILTER = `${namespace}/SET_EVENT_FILTER`;
export const CLEAR_EVENT_FILTER = `${namespace}/CLEAR_EVENT_FILTER`;
export const SET_USER_LIST = `${namespace}/SET_USER_LIST`;
export const SET_CATEGORIES_LIST = `${namespace}/SET_CATEGORIES_LIST`;
export const SET_SCHEDULE_EVENT_FILTER = `${namespace}/SET_SCHEDULE_EVENT_FILTER`;
export const RESET_SCHEDULE_EVENT_FILTER = `${namespace}/RESET_SCHEDULE_EVENT_FILTER`;
export const SET_SCHEDULE_EVENT_NOTE = `${namespace}/SET_EVENT_NOTE`;
export const CLEAR_SCHEDULE_EVENT_NOTE = `${namespace}/CLEAR_EVENT_NOTE`;
export const SET_SCHEDULE_EVENT_REVIEWS = `${namespace}/SET_SCHEDULE_EVENT_REVIEWS`;
export const CLEAR_SCHEDULE_EVENT_FILTER = `${namespace}/CLEAR_SCHEDULE_EVENT_FILTER`;
export const SET_DISCUSSION_LIST = `${namespace}/SET_DISCUSSION_LIST`;
export const SET_DISCUSSION_FILTER = `${namespace}/SET_DISCUSSION_FILTER`;

export const setScheduleEventList = (payload) => ({
  type: SET_SCHEDULE_EVENT_LIST,
  payload,
});

export const setEventList = (payload) => ({
  type: SET_EVENT_LIST,
  payload,
});

export const setEventFilter = (payload) => ({
  type: SET_EVENT_FILTER,
  payload,
});

export const clearEventFilter = () => ({
  type: CLEAR_EVENT_FILTER,
});

const setUserList = (payload) => ({
  type: SET_USER_LIST,
  payload,
});

const setCategoriesList = (payload) => ({
  type: SET_CATEGORIES_LIST,
  payload,
});

export const setScheduleEventFilter = (payload) => ({
  type: SET_SCHEDULE_EVENT_FILTER,
  payload,
});

export const resetScheduleEventFilter = (payload) => ({
  type: RESET_SCHEDULE_EVENT_FILTER,
  payload,
});

export const setScheduleEventNote = (payload) => ({
  type: SET_SCHEDULE_EVENT_NOTE,
  payload,
});

export const clearScheduleEventNote = () => ({
  type: CLEAR_SCHEDULE_EVENT_NOTE,
});

export const setScheduleEventReviews = (payload) => ({
  type: SET_SCHEDULE_EVENT_REVIEWS,
  payload,
});

export const clearScheduleEventFilter = (payload) => ({
  type: CLEAR_SCHEDULE_EVENT_FILTER,
  payload,
});

export const setDiscussionList = (payload) => ({
  type: SET_DISCUSSION_LIST,
  payload,
});

export const setDiscussionFilter = (payload) => ({
  type: SET_DISCUSSION_FILTER,
  payload,
});

export const getScheduleEventList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSchedulesEvents, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setScheduleEventList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getScheduleEvent = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSchedulesEvent(id));
    const {
      data,
      data: { is_success },
    } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addScheduleEvent = (payload) => async (dispatch) => {
  try {
    const response = await api.post(
      apiEndPoints.createSchedulesEvents,
      payload
    );
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );

      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getPrivateEventUrl = (id) => async () => {
  try {
    const response = await api.get(apiEndPoints.getPrivateUrl(id));
    const { data: { is_success, private_url } = {} } = response;
    if (is_success) {
      return private_url;
    }
    return "";
  } catch (error) {
    return "";
  }
};

export const copyPrivateLink = () => async (dispatch) => {
  dispatch(showSnackbar({ message: "Copied!", type: snackbarTypes.SUCCESS }));
  return true;
};

export const editScheduleEvent = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(
      apiEndPoints.updateSchedulesEvents(id),
      payload
    );
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteScheduleEvent = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteSchedulesEvents(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const cancelScheduleEvent = (id) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.cancelSchedulesEvents(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getEventList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEvents, {
      params: { params },
    });
    const { data: { is_success, events } = {} } = response;
    if (is_success) {
      dispatch(setEventList(events));
      return events;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserList = () => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUsers, {
      params: {
        params: {
          roles: ["Coordinator", "Moderator"],
          text: "",
          limit: 0,
          skip: 0,
          status: { is_active: true, is_deleted: false, deleted: false, is_blacklisted: false },
        },
      },
    });
    const { data: { is_success, users } = {} } = response;
    if (is_success) {
      dispatch(setUserList(users));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getCategoriesList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCategories, {
      params: { params },
    });
    const { data: { is_success, categories } = {} } = response;
    if (is_success) {
      dispatch(setCategoriesList(categories));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getEventAttendees = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEventAttendees(id));
    const { data: { is_success, attendees } = {} } = response;
    if (is_success) {
      return attendees;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadEventAttendees = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.exportEventAttendees(id), {
      responseType: "arraybuffer",
      params: { params: { utc_offset: moment().utcOffset() } },
    });
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const startEvent = (id) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.startEvent(id));
    const {
      data: { zoom_meeting, is_success },
    } = response;
    if (is_success) {
      return zoom_meeting;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getEventReviews = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEventReviews(id), {
      params: { params },
    });
    const {
      data: { reviews, is_success },
    } = response;
    if (is_success) {
      dispatch(setScheduleEventReviews(reviews));
      return reviews;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadScheduleEvents = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.exportSchedulesEvents, {
      params: { params },
    });
    const {
      data: { is_success, message },
    } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const addEventNote = (payload, id) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addEventNote(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Note not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getEventNote = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEventNote(id));
    const { data, data: { is_success } = {} } = response;

    if (is_success) {
      dispatch(setFiles(data?.note));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const publishUnpublishReview = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(
      apiEndPoints.changeReviewStatus(id),
      payload
    );
    const {
      data,
      data: { is_success },
    } = response;

    if (is_success) {
      return data;
    }
  } catch (error) {
    return false;
  }
};

export const getEvent = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEvent(id));
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getCommentList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getDiscussions, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getSingleComment = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCommentReplies(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getHashtags = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getHashtags, {
      params: { params },
    });
    const { data } = response;
    if (data) {
      return data;
    }
    // return false;
  } catch (error) {
    return false;
  }
};

export const getAllUsers = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUsers, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data?.users;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addComment = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addDiscussion, payload);
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const editComment = (payload, id) => async (dispatch) => {
  try {
    const response = await api.patch(apiEndPoints.editDiscussions(id), payload);
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
  } catch (error) {
    return false;
  }
};

export const startBroadcast = (id) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.startBroadcast(id));
    const {
      data: { is_success, message },
    } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteComment = (id, payload) => async (dispatch) => {
  try {
    const response = await api.patch(
      apiEndPoints.changeDiscussionStatus(id),
      payload
    );
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getReportOptionsList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getReportsOptions, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getScheduleEventDates = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.eventScheduleDates(id), {
      params: { params },
    });
    const {
      data,
      data: { is_success },
    } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
