import React from "react";
import DataTable from "../../components/table";
import { useCategory } from "./hooks";
import AddCategory from "./add-categories";
import EditCategory from "./edit-categories";
import DeleteCategory from "./delete-categories";
import { columns, categoryStatus, categoryType } from "./constant";
import SelectFilter from "../../components/filters/select-filter";
import { useHistory } from "react-router";
import ActionButton from "../../components/action-button";

const Categories = () => {
  let history = useHistory();
  const { categories, loadingCategory, setFilter, filters, categoryCount } =
    useCategory();
  const configureTableData = () => {
    try {
      let results = categories.map((category) => {
        let newCategory = Object.assign({}, category);
        newCategory["type"] = category?.type ? category?.type : "NA";
        newCategory["action"] = (
          <div className="categories__list-action">
            {category?.type === "Event" && (
              <ActionButton
                title="View"
                placement="bottom"
                className="view_user_details_pad_left10"
                iconClassName={`fa fa-eye icon-btn-action`}
                onClick={() =>
                  history.push({
                    pathname: `/category/${category?.id}`,
                  })
                }
              />
            )}

            <EditCategory category={category} />
            <DeleteCategory category={category} />
          </div>
        );
        return newCategory;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const categoryFilter = () => (
    <>
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.type}
        filterKey="type"
        options={categoryType}
        filterTitle="Type"
        defaultOption="All"
      />
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        filterKey="status"
        options={categoryStatus}
        filterTitle="Status"
      />
    </>
  );

  return (
    <DataTable
      title="Categories"
      addBtn={<AddCategory />}
      CreateTableData={configureTableData}
      withFilter={true}
      filter={categoryFilter}
      handleSetFilter={setFilter}
      count={categoryCount}
      skip={filters?.skip}
      isLoading={loadingCategory}
    />
  );
};

export default Categories;
