import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../../utils/upload-file/actions";
import { landingPageOptions } from "../constant";
import { fieldsName } from "../constant";
import {
  getScheduleEventList,
  getBlogList,
  setBlogFilter,
  setEventFilter,
} from "../action";
import { useEffect } from "react";
import { getScheduleEventFilter, getBlogFilter } from "../selectors";
import _ from "lodash";
const formField = {
  platform: "",
  screen: "",
  position: "",
  banners: [],
};

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const useBannerForm = ({ fieldValues, btn, isLoading }) => {
  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const [bannerError, setBannerError] = useState("");
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const scheduleEventFilter = useSelector(getScheduleEventFilter);
  const blogFilter = useSelector(getBlogFilter);
  const [events, setEvents] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const dispatch = useDispatch();
  const { landing_page, banners } = fieldsName;

  useEffect(() => {
    (async () => {
      const events = await dispatch(getScheduleEventList(scheduleEventFilter));
      if (events) {
        setEvents(events);
      }
    })();
  }, [dispatch, scheduleEventFilter]);

  useEffect(() => {
    (async () => {
      const blogs = await dispatch(getBlogList(blogFilter));
      if (blogs) {
        setBlogs(blogs);
      }
    })();
  }, [dispatch, blogFilter]);

  const handleFormUpdate = (key, value) => {
    if (key === "landing_page" || key === "banner_file") {
      setBannerError("");
    }

    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));

    return;
  };

  const updateDraggableData = (result) => {
    if (result.source && result.destination) {
      const items = reorder(
        formValues.banners,
        result.source.index,
        result.destination.index
      );
      handleFormUpdate(banners, items);
    }
  };

  const handleAddBanner = (rest) => {
    if (!rest.values.landing_page) {
      setBannerError("landing page is required");
    } else if (
      rest.values.landing_page === "EventDetail" &&
      !rest.values.event
    ) {
      setBannerError("Event is required");
    } else if (rest.values.landing_page === "BlogDetail" && !rest.values.blog) {
      setBannerError("Blog is required");
    } else if (!files[0]) {
      setBannerError("banner  is required");
    } else {
      let is_exist;

      if (!rest.values.event && !rest.values.blog) {
        const is_page_exist = formValues.banners.some(
          (banner) => banner.redirect_to === rest.values.landing_page
        );
        if (is_page_exist) {
          setBannerError("This page is already exist in list");
        }
        is_exist = is_page_exist;
      }
      if (rest.values.event) {
        const is_event_exist = formValues.banners.some(
          (banner) =>
            (banner?.event?.id || banner?.trackable_id) === rest.values.event.id
        );
        if (is_event_exist) {
          setBannerError("This event is already exist in list");
        }
        is_exist = is_event_exist;
      }

      if (rest.values.blog) {
        const is_blog_exist = formValues.banners.some(
          (banner) =>
            (banner?.blog?.id || banner?.trackable_id) === rest.values.blog.id
        );
        if (is_blog_exist) {
          setBannerError("This blog is already exist in list");
        }
        is_exist = is_blog_exist;
      }

      if (!is_exist) {
        const data = [
          ...formValues.banners,
          {
            redirect_to: rest.values.landing_page,
            file: files[0],
            event: rest.values.event || "",
            blog: rest.values.blog || "",
          },
        ];
        dispatch(clearFiles());
        handleFormUpdate(landing_page, "");
        handleFormUpdate("event", "");
        handleFormUpdate("blog", "");
        handleFormUpdate(banners, data);
        setBannerError("");
      }
    }
  };

  const handleDeleteBanner = (banner) => {
    let data = formValues.banners.filter(
      (item) => item.redirect_to !== banner.redirect_to
    );
    handleFormUpdate(banners, data);
  };

  const getScreenName = (screen) => {
    return landingPageOptions.find((item) => item.value === screen).displayText;
  };

  const setEventListFilter = (key, value) => {
    dispatch(setEventFilter({ key, value }));
  };

  const setSearchText = useCallback(
    _.debounce((key, value) => {
      dispatch(setBlogFilter({ key, value }));
    }, 600),
    []
  );

  return {
    formValues,
    updateDraggableData,
    handleFormUpdate,
    handleAddBanner,
    setEventListFilter,
    btn,
    isLoading,
    bannerError,
    handleDeleteBanner,
    getScreenName,
    setBannerError,
    events,
    blogs,
    setSearchText,
  };
};
