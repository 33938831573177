import React from "react";
import { Button } from "reactstrap";

import PolicyForm from "../form/policyForm";
import { useAddPolicy } from "./hooks";
import Modals from "../../../../components/modal";
import { useUser } from "../../../../common-hooks/user";

const AddPolicy = (props) => {
  const { addPolicyLoading, handleAddPolicy, isOpenModal, toggleModal } =
    useAddPolicy(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }
  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Add Policy
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PolicyForm
              handlePolicyForm={handleAddPolicy}
              btn="Add"
              isLoading={addPolicyLoading}
            />
          }
          title="Add Policy"
        />
      ) : null}
    </div>
  );
};

export default AddPolicy;
