import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bookUserEvent,
  getEvents,
  getEventTime,
  getUserEventScheduleAction,
  setBookEventFilter,
} from "../actions";
import {
  getBookEventFilter,
  getEventTimeSlots,
  getUserEventFilter,
  getEventList,
} from "../selectors";

export const useBookEvent = ({ user_id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [bookEventLoading, setBookEventLoading] = useState(false);
  const bookEventFilter = useSelector(getBookEventFilter);
  const eventTimeSlots = useSelector(getEventTimeSlots);
  const eventList = useSelector(getEventList);
  const userEventFilter = useSelector(getUserEventFilter);

  useEffect(() => {
    (async () => {
      isOpenModal
        ? await dispatch(getEvents(bookEventFilter))
        : Promise.resolve({});
    })();
  }, [dispatch, bookEventFilter, isOpenModal]);

  const getEventsTimeSlots = (event_id) => {
    dispatch(getEventTime(event_id));
  };

  const setSearchText = useCallback(
    _.debounce((searchValue) => {
      dispatch(setBookEventFilter({ key: "text", value: searchValue }));
    }, 600),
    []
  );

  const handleBookEventForm = async (values) => {
    setBookEventLoading(true);
    const is_success = await dispatch(bookUserEvent(user_id, values.time_slot));
    if (is_success) {
      dispatch(getUserEventScheduleAction(user_id, userEventFilter));
      toggleModal();
    }
    setBookEventLoading(false);
  };

  const toggleModal = () => {
    dispatch(setBookEventFilter({ key: "text", value: "" }));
    setIsOpenModal(!isOpenModal);
  };

  return {
    toggleModal,
    isOpenModal,
    eventList,
    handleBookEventForm,
    bookEventLoading,
    getEventsTimeSlots,
    eventTimeSlots,
    setSearchText,
  };
};
