import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editSubategory, getSubategoryList, getSubategory } from "../actions";
import { initialLanguageFieldValue } from "../../../../constants";
import { clearLanguage, getLanguage } from "../../../../utils/auth";

export const useEditSubcategory = (subcategory, category_id) => {
  const dispatch = useDispatch();
  const { filter } = useSelector(
    ({ subCategoryReducer }) => subCategoryReducer
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editSubcategoryLoading, setEditSubategoryLoading] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState();

  const getSubategoryFieldData = async () => {
    const selectedLanguage = getLanguage();
    setEditSubategoryLoading(true);
    const data = await dispatch(getSubategory(subcategory?.id));

    setEditSubategoryLoading(false);
    if (data && data?.is_success) {
      data.id = data?.sub_category?.id;
      data.created_at = data?.sub_category?.created_at;
      data.created_by = data?.sub_category?.created_by;
      data.description = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data?.sub_category?.description,
      };
      data.status = data?.sub_category?.status;
      data.title = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data?.sub_category?.title,
      };
      data.type = data?.sub_category?.type;
      data.updated_at = data?.sub_category?.updated_at;
      data.updated_by = data?.sub_category?.updated_by;
      data.language = selectedLanguage;
      data.is_used = data?.sub_category?.is_used;

      setSelectedSubcategory(data);
      toggleModal();
    }
  };

  const handleLanguge = async (rest, setFormvalues) => {
    const selectedLanguage = getLanguage();
    setEditSubategoryLoading(true);
    const data = await dispatch(getSubategory(subcategory?.id));

    setEditSubategoryLoading(false);
    if (data && data?.is_success) {
      data.id = data?.sub_category?.id;
      data.created_at = data?.sub_category?.created_at;
      data.created_by = data?.sub_category?.created_by;
      data.description = {
        ...rest.values.description,
        [selectedLanguage]:
          rest.values.description[selectedLanguage] ||
          data?.sub_category?.description ||
          "",
      };
      data.status = data?.sub_category?.status;
      data.title = {
        ...rest.values.title,
        [selectedLanguage]:
          rest.values.title[selectedLanguage] ||
          data?.sub_category?.title ||
          "",
      };
      data.type = data?.sub_category?.type;
      data.updated_at = data?.sub_category?.updated_at;
      data.updated_by = data?.sub_category?.updated_by;
      data.is_used = data?.sub_category?.is_used;
      data.language = selectedLanguage;
      setFormvalues(data);
    }
  };

  const handleEditSubategory = async (values) => {
    const payload = {
      sub_category: {
        category_id: parseInt(category_id),
        title: values.title,
        description: values.description,
        type: values.type,
        status: values.status,
      },
    };
    setEditSubategoryLoading(true);
    const is_success = await dispatch(editSubategory(payload, subcategory?.id));
    setEditSubategoryLoading(false);
    if (is_success) {
      clearLanguage();
      await dispatch(getSubategoryList(category_id, filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editSubcategoryLoading,
    handleEditSubategory,
    isOpenModal,
    toggleModal,
    getSubategoryFieldData,
    selectedSubcategory,
    handleLanguge,
  };
};
