import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCourseData, getCourseList } from "../actions";
import { getCourseFilter } from "../selectors";

export const useDeleteCourse = (id) => {
  const dispatch = useDispatch();
  const filter = useSelector(getCourseFilter);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteCourseLoading, setDeleteCourseLoading] = useState(false);

  const handleDeleteCourse = async () => {
    setDeleteCourseLoading(true);
    const is_success = await dispatch(deleteCourseData(id));
    setDeleteCourseLoading(false);
    if (is_success) {
      dispatch(getCourseList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteCourseLoading,
    handleDeleteCourse,
    isOpenModal,
    toggleModal,
  };
};
