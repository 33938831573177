import * as Yup from "yup";
import { checkFields } from "../../../../../utils/validation";

const requiredFields = ["title", "description"];

export const blogFormSchema = Yup.object().shape({
  title: Yup.mixed().test(
    "Title required",
    "Enter valid title for all languages",
    function () {
      const {
        title: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "title");
    }
  ),
  description: Yup.mixed().test(
    "Description required",
    "Enter valid description for all languages",
    function () {
      const {
        description: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "description");
    }
  ),
  author_id: Yup.string().required("Author is required").nullable(),
  tags: Yup.array().required("Tags are required"),
  category_id: Yup.string().required("Category is required").nullable(),
  interestIds: Yup.array(),
  label_id: Yup.string().nullable(),
  next_blog_id: Yup.string().nullable(),
  is_popular: Yup.boolean(),
  time_to_read: Yup.number().required("Time to Read is required"),
  status: Yup.string().required("Status is required").nullable(),
  fileBanner: Yup.array().required("File Banner is required"),
  fileBannerTile: Yup.array().required("File Banner Tile is required"),
  meta_title: Yup.string(),
  meta_description: Yup.string(),
  meta_keywords: Yup.string(),
  published_at: Yup.date().required("Published At is required"),
  slug: Yup.string().required("Slug is required").nullable(),
});
