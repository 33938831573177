export const getCategoriesSelector = ({
  videoReducer: { categories = [] } = {},
}) => categories;

export const getCommonFilter = ({
  videoReducer: { common_filter = {} } = {},
}) => common_filter;

export const getCategoryFilter = ({
  videoReducer: { categoryFilter = {} } = {},
}) => categoryFilter;

export const getVideoFilter = ({ videoReducer: { filter = {} } = {} }) =>
  filter;

export const getVideos = ({ videoReducer: { videos = {} } = {} }) => videos;

export const getVideoCount = ({ videoReducer: { count = {} } = {} }) => count;

export const getUserFilter = ({ videoReducer: { user_filter = {} } = {} }) =>
  user_filter;
export const getUsers = ({ videoReducer: { users = {} } = {} }) => users;
