import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";
import { setFiles } from "../../utils/upload-file/actions";

const namespace = "containers/partners";

export const SET_PARTNERS_LIST = `${namespace}/SET_PARTNERS_LIST`;
export const SET_PARTNERS_FILTER = `${namespace}/SET_PARTNERS_FILTER`;
export const RESET_PARTNERS_FILTER = `${namespace}/RESET_PARTNERS_FILTER`;

const setPartnersList = (payload) => ({
  type: SET_PARTNERS_LIST,
  payload,
});

export const setPartnersFilter = (payload) => ({
  type: SET_PARTNERS_FILTER,
  payload,
});

export const resetPartnersFilter = (payload) => ({
  type: RESET_PARTNERS_FILTER,
  payload,
});

export const getPartnersList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPartners, {
      params: { params },
    });
    const { data = {} } = response;
    if (data?.partners) {
      dispatch(setPartnersList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addPartner = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addPartners, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Partner not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getSinglePartner = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSinglePartner(id));
    const { data: { is_success, partner } = {} } = response;
    if (is_success) {
      dispatch(setFiles(partner.files));
      return partner;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editPartner = (payload, id) => async (dispatch) => {
  try {
    const response = await api.patch(apiEndPoints.editPartners(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};
