export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
    disableGlobalFilter: true,
  },

  {
    Header: "Status",
    accessor: "status",
    width: 60,
  },

  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
    width: 30,
  },
];

export const fieldNames = {
  title: "title",
  description: "description",
  category_id: "category_id",
  status: "status",
  author_id: "author_id",
  video_banner: "video_banner",
  published_at: "published_at",
  video: "video",
  url: "url",
  video_icon: "video_icon",
  language: "language",
};

export const statusOptions = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
  {
    id: 3,
    value: "In Active",
    displayText: "In Active",
  },
];
