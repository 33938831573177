import React from "react";
import styles from "./styles.module.scss";
import ReactPlayer from "react-player";
import { usePostImages } from "./hooks";
import LazyImage from "../../../../../components/lazy-image";
import CustomModal from "../../../../../components/custom-modal";
import {
  NextArrowLight,
  PrevArrowLight,
} from "../../../../../components/slider-arrow";
import { BackWhiteArrow } from "../../images";
import { fileContext } from "../../../../../constants";

const PostImages = (props) => {
  const {
    toggleImageModal,
    imageModal,
    current,
    handleNext,
    handlePrev,
    files,
  } = usePostImages(props);
  if (!files || files.length === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.image_container} onClick={toggleImageModal}>
        {files[0]?.file_context === fileContext.POST_VIDEO ? (
          <ReactPlayer
            playing
            muted
            className={styles.single_image}
            url={files[0]?.ts_url || files[0]?.url}
            loop
            // controls
          />
        ) : (
          <LazyImage
            src={files[0].url}
            alt="image"
            className={styles.single_image}
            containerClass={styles.single_image}
          />
        )}
      </div>
      <CustomModal
        paperClassName={styles.imageViewerContainer}
        isOpen={imageModal}
        onCloseCallback={toggleImageModal}
        width="80%"
      >
        <img
          src={BackWhiteArrow}
          alt="close"
          className={styles.close_video_modal}
          onClick={toggleImageModal}
        />
        <div className={styles.modal_video_container}>
          {files?.length > 1 && (
            <NextArrowLight
              className={styles.next_slider}
              onClick={handleNext}
            />
          )}
          <div className={styles.slider}>
            {files[current]?.file_context === fileContext.POST_VIDEO ? (
              <div>
                <ReactPlayer
                  className={styles.video_player}
                  url={
                    files[current]?.ts_url ||
                    files[current]?.url ||
                    files[current]?.public_url
                  }
                  controls
                  pip
                  stopOnUnmount={true}
                  width="inherit"
                  height="inheirt"
                  playing={false}
                  config={{
                    youtube: {
                      preload: true,
                    },
                  }}
                  playIcon={
                    <div>
                      <div>
                        <img src={"/img/play-open.svg"} alt="play" />
                      </div>
                    </div>
                  }
                />
              </div>
            ) : (
              <img
                className={styles.image_viewer}
                src={files[current]?.url || files[current]?.public_url}
                alt="img"
              />
            )}
          </div>
          {files?.length > 1 && (
            <PrevArrowLight
              className={styles.prev_slider}
              onClick={handlePrev}
            />
          )}{" "}
        </div>
      </CustomModal>
    </>
  );
};
export default PostImages;
