import * as Yup from "yup";

export const noticeSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  event_id: Yup.object().when("type", {
    is: "Event Schedule",
    then: Yup.object().required("Event is required"),
  }),
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  status: Yup.string().required("Status is required"),
  booking_status: Yup.array().when("type", {
    is: "Event Schedule",
    then: Yup.array().required("Booking Status is required"),
  }),
  short_description: Yup.string().required("Short description is required"),
  blog_id: Yup.object().when("redirect_to", {
    is: "BlogDetail",
    then: Yup.object().required("Blog is required"),
  }),
});
