import React from "react";
import { Route, Switch } from "react-router-dom";

import Categories from "../../../categories";
import Subcategories from "../../../categories/subcategories";

export default () => (
  <Switch>
    <Route path="/categories" component={Categories} />
    <Route exact path="/category/:category_id" component={Subcategories} />
  </Switch>
);
