import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../../utils/upload-file/actions";
import { addTestimonial, getTestimonialList } from "../actions";
import { getTestimonialsFilter } from "../selectors";

export const useAddTestimonial = () => {
  const dispatch = useDispatch();
  const filter = useSelector(getTestimonialsFilter);
  const { testimonials } = useSelector(
    ({ testimonialReducer }) => testimonialReducer
  );

  const files = useSelector(({ fileReducer }) => fileReducer.files);

  const [addTestimonialLoading, setAddTestimonialLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleAddTestimonial = async (values) => {
    try {
      const payload = {
        testimonial: {
          status: values.status,
          description: values.description,
        },
        files,
      };
      setAddTestimonialLoading(true);
      const is_success = await dispatch(addTestimonial(payload));
      setAddTestimonialLoading(false);
      if (is_success) {
        dispatch(getTestimonialList(filter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
  };

  return {
    addTestimonialLoading,
    testimonials,
    handleAddTestimonial,
    isOpenModal,
    toggleModal,
    filter,
  };
};
