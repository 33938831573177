import { api, apiEndPoints } from "../../../api";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";

const namespace = "containers/posts";

export const SET_STORY_LIST = `${namespace}/SET_STORY_LIST`;
export const SET_STORY_FILTER = `${namespace}/SET_STORY_FILTER`;
export const CLEAR_STORY_FILTER = `${namespace}/CLEAR_STORY_FILTER`;

export const setStoryList = (payload) => ({
  type: SET_STORY_LIST,
  payload,
});

export const setStoryFilter = (payload) => ({
  type: SET_STORY_FILTER,
  payload,
});

export const resetStoryFilter = (payload) => ({
  type: CLEAR_STORY_FILTER,
  payload,
});

export const getStoriesData = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getStories, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setStoryList(data));
      return is_success;
    }
  } catch (error) {
    return false;
  }
};

export const updateStoryStatus = (id, payload) => async (dispatch) => {
  try {
    const response = await api.patch(
      apiEndPoints.updateStoryStatus(id),
      payload
    );

    const { data, data: { is_success, message } = {} } = response;
    dispatch(
      showSnackbar({
        type: is_success ? snackbarTypes.SUCCESS : snackbarTypes.ERROR,
        message: message,
      })
    );
    if (is_success) {
      return data;
    }

    return false;
  } catch (error) {
    return false;
  }
};
