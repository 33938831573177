import { useState } from "react";

export const useImageViewer = (props) => {
  const { files, isOpen, handleIsOpen } = props;

  const [current, setCurrent] = useState(0);

  const handleNext = () => {
    if (files?.length > 1) {
      if (files[current + 1]) {
        setCurrent((prev) => prev + 1);
      } else {
        setCurrent(0);
      }
    }
  };

  const handlePrev = () => {
    if (files?.length > 1) {
      if (files[current - 1]) {
        setCurrent((prev) => prev - 1);
      } else {
        setCurrent(files.length - 1);
      }
    }
  };

  return {
    isOpen,
    handleIsOpen,
    current,
    handleNext,
    handlePrev,
    files,
  };
};
