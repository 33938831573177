import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../actions";
import {
  getSubategoryList,
  resetSubcategoryFilter,
  setSubcategoryFilter,
} from "./actions";
import { clearLanguage } from "../../../utils/auth";

import {
  getSubcategories,
  getSubcategoriesFilter,
  getSubategoriesCount,
} from "./selectors";

export const useSubcategory = ({
  match: {
    params: { category_id },
  },
}) => {
  const dispatch = useDispatch();
  const [loadingSubcategory, setLoadingSubcategory] = useState(false);
  const [category, setCategory] = useState({});
  const filters = useSelector(getSubcategoriesFilter);
  const subcategoryCount = useSelector(getSubategoriesCount);
  const subcategories = useSelector(getSubcategories);

  useEffect(() => {
    clearLanguage();
    async function fetchData() {
      setLoadingSubcategory(true);
      const data = await dispatch(getCategory(category_id));
      if (data) {
        setCategory(data?.category);
      }
      await dispatch(getSubategoryList(category_id, filters));

      setLoadingSubcategory(false);
    }
    fetchData();
  }, [category_id, dispatch, filters]);

  const setFilter = (key, value) => {
    dispatch(setSubcategoryFilter({ key, value }));
  };

  const resetFilter = useCallback(() => {
    dispatch(resetSubcategoryFilter());
  }, [dispatch]);

  useEffect(() => {
    resetFilter();
  }, [category_id, resetFilter]);

  return {
    loadingSubcategory,
    subcategories,
    category_id,
    category,
    subcategoryCount,
    filters,
    setFilter,
    resetFilter,
  };
};
