import React from "react";
import PropTypes, { number, string } from "prop-types";
import styles from "./styles.module.scss";
import { useComment, useCommentContent } from "./hooks";
import Text from "../../../../../components/text";
import ProfileImage from "../../../../../components/ProfileImage";
import { Button, MenuItem, TextField, Tooltip } from "@material-ui/core";
import Reaction from "../reaction";
import Replies from "../replies";
import Reactions from "../reactions";
import moment from "moment";
import UnpublishPost from "../../unpublish-post";
import { postByList, statuses } from "../../constant";
import ReactPlayer from "react-player";
import { fileContext, trackableTypes } from "../../../../../constants";
import ImageViewer from "../../../../../components/image-viewer";
import CaptionInput from "../caption-input-multiline";
import PostCaption from "../post-caption";
import { colors } from "../../../../../constants/colors";
import { useUser } from "../../../../../common-hooks/user";
import DeleteComment from "../delete-comment";
import ConfidenceScore from "../../../../../components/confidence-score";

const CommentContent = ({
  data,
  handleDeleteComment,
  trackableType,
  updateComment,
  handleTotalComments,
  isEgReaction,
  isPostUnpublished,
  handleDelete,
}) => {
  const {
    canReply,
    toggleReplyInput,
    reactionsCount,
    handleReactSuccess,
    handleRemoveReactSuccess,
    myReaction,
    stats,
    id,
    handleSubmit,
    user,
    isEdit,
    editText,
    profile_picture,
    handleChange,
    postBy,
    handleUpdateComment,
    imageViewer,
    handleImageViewer,
    handleIsEdit,
    selectedComment,
    clearInput,
    handleClearInput,
    files,
    egReaction,
    totalRepliesCount,
    handleDeleteReplySuccess,
    handleReplySuccess,
    disabled,
  } = useCommentContent({
    data,
    handleDeleteComment,
    updateComment,
    trackableType,
    handleTotalComments,
    isEgReaction,
    isPostUnpublished,
  });

  const { isAdmin } = useUser();
  const { PUBLISHED } = statuses;

  return (
    <>
      <div className={styles.comment_content}>
        <ProfileImage
          src={data?.commenter?.profile_picture?.url}
          title={data?.commenter?.name}
          width={40}
          height={40}
          className={styles.profile_image}
          initialsClassName={styles.profile_img_initials}
        />
        <div className={styles.comment_box}>
          <div className={styles.comment_detail}>
            <div className={styles.commenter}>
              <Text
                className={styles.comment_title}
                size="16px"
                fontWeight={600}
              >
                {data?.commenter?.name}
              </Text>

              <div className="d-flex flex-column align-items-end">
                <Text fontWeight={400} size="12px" color={"grey"}>
                  {moment(data?.created_at).fromNow()}
                </Text>
                <div className="mt-2 d-inline-flex">
                  {isAdmin && (
                    <>
                      <UnpublishPost
                        handleSubmit={handleSubmit}
                        data={data}
                        isDisabled={
                          isPostUnpublished || !data.commenter.is_active
                        }
                        className="mr-3"
                        status={data?.status}
                      />
                    </>
                  )}

                  <ConfidenceScore
                    data={data}
                    trackableType={trackableTypes.COMMENT}
                    className="mr-3"
                  />

                  {user?.id === data?.created_by && isAdmin && (
                    <Tooltip title="Edit" placement="bottom">
                      <i
                        className={`fa fa-edit icon-btn-action ${
                          disabled && styles.disabled_button
                        }`}
                        onClick={() => !disabled && handleIsEdit()}
                      ></i>
                    </Tooltip>
                  )}
                  <DeleteComment
                    handleDelete={handleDelete}
                    comment={data}
                    className="ml-3"
                    totalRepliesCount={totalRepliesCount}
                    isComment
                  />
                </div>
              </div>
            </div>
            <PostCaption captionText={data?.text} ranges={data?.ranges} />
            {data?.attachments?.length ? (
              <div
                className={"discussion_images_container bg-transparent mt-3"}
              >
                {data?.attachments?.map((img, i) => (
                  <div
                    onClick={handleImageViewer}
                    className={"img_container"}
                    key={i}
                  >
                    {img.file_context === fileContext.DISCUSSION_VIDEOS ? (
                      <ReactPlayer
                        width={80}
                        height={80}
                        url={img?.ts_url || img.url || img?.public_url}
                        playing={true}
                        muted={true}
                        loop={true}
                        style={{ display: "inline-block" }}
                      />
                    ) : (
                      <img alt="media" src={img.url || img?.public_url} />
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          {imageViewer && (
            <ImageViewer
              files={data?.attachments}
              handleViewer={handleImageViewer}
            />
          )}
          <div className={styles.comment_reaction_box}>
            {isAdmin && (
              <>
                <div className="mr-3">
                  <Reaction
                    trackableId={data.id}
                    trackableType={trackableTypes.COMMENT}
                    reactionId={egReaction?.id}
                    reactionName={egReaction?.name}
                    onReactSuccess={handleReactSuccess}
                    onRemoveReactSuccess={handleRemoveReactSuccess}
                    disabled={disabled}
                    isEgReaction
                  />
                </div>
                <div className={`${styles.dot} mr-3`} />
              </>
            )}
            <div className="mr-3">
              <Reaction
                trackableType={trackableTypes.COMMENT}
                trackableId={data.id}
                reactionId={myReaction?.id}
                reactionName={myReaction?.name}
                onReactSuccess={handleReactSuccess}
                onRemoveReactSuccess={handleRemoveReactSuccess}
                disabled={disabled}
              />
            </div>

            {isAdmin && (
              <>
                <div className={`${styles.dot} mr-3`} />
                <Text
                  className={`${
                    disabled ? styles.disabled_button : "cursor_pointer"
                  } mr-3`}
                  onClick={() => !disabled && toggleReplyInput(data)}
                  color={colors.grayDark2}
                  size="15px"
                  fontWeight={500}
                >
                  Reply
                </Text>
              </>
            )}

            {!!reactionsCount && (
              <>
                <div className={`${styles.dot} mr-3`} />
                <Reactions
                  stats={stats}
                  id={id}
                  trackableType={trackableTypes.COMMENT}
                  totalReactions={reactionsCount}
                />
              </>
            )}
          </div>

          {isEdit && (
            <div className={`mt-3`}>
              {files.length ? (
                <div className={"discussion_images_container mt-3"}>
                  {files
                    .filter(
                      (file) =>
                        file.file_context ===
                          `${fileContext.DISCUSSION_IMAGES}-${id}` ||
                        file.file_context ===
                          `${fileContext.DISCUSSION_VIDEOS}-${id}`
                    )
                    .map((img, i) => (
                      <div className={"img_container"} key={i}>
                        {img.file_context ===
                        `${fileContext.DISCUSSION_VIDEOS}-${id}` ? (
                          <ReactPlayer
                            width={80}
                            height={80}
                            url={img?.ts_url || img.public_url || img.url}
                            playing={true}
                            muted={true}
                            loop={true}
                            style={{ display: "inline-block" }}
                          />
                        ) : (
                          <img alt="media" src={img.public_url || img.url} />
                        )}
                      </div>
                    ))}
                </div>
              ) : null}
              <div className={`${styles.add_a_comment_div} mt-3`}>
                <ProfileImage
                  src={profile_picture?.url}
                  title={user?.name}
                  width={40}
                  height={40}
                  className={styles.profile_img}
                  initialsClassName={styles.profile_img_initials}
                />
                <div className={styles.comment_input_div}>
                  <div className={styles.input_field}>
                    <CaptionInput
                      onCaptionChange={handleChange}
                      caption={selectedComment?.text}
                      mentions={selectedComment?.ranges}
                      placeholder="Write a comment..."
                      trackableType={trackableType}
                      singleLine
                      clear={clearInput}
                      onClearSuccess={handleClearInput}
                    />
                  </div>
                </div>

                {isAdmin && (
                  <div className="material-form_inline-fields mr-4">
                    <TextField
                      style={{ width: "100px" }}
                      className="material-form__field"
                      name={"post_by"}
                      placeholder="Post By"
                      label="Post By"
                      select
                      value={postBy}
                      disabled
                      onChange={(event) => {
                        // handlePostBy(event.target.value);
                      }}
                      defaultValue={"postBy"}
                    >
                      {postByList.map((post_by) => (
                        <MenuItem
                          key={post_by.id}
                          className="material-form__option"
                          value={post_by.value}
                        >
                          {post_by.displayText}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}
                <div className={styles.comment_button_div}>
                  <Button disabled={!editText} onClick={handleUpdateComment}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}

          <Replies
            reply={canReply}
            commentId={data.id}
            replies={data.replies}
            trackableType={trackableType}
            isOpenReply={canReply}
            count={data?.stats?.replies_count}
            toggleReplyInput={toggleReplyInput}
            handleTotalComments={handleTotalComments}
            isPostUnpublished={isPostUnpublished}
            isCommentUnpublished={data?.status !== PUBLISHED || !data?.commenter?.is_active}
            totalRepliesCount={totalRepliesCount}
            handleDeleteReplySuccess={handleDeleteReplySuccess}
            handleReplySuccess={handleReplySuccess}
          />
        </div>
      </div>
    </>
  );
};

const Comments = (props) => {
  const {
    user,
    handleCommentChange,
    userProfileImg,
    handleSubmitComment,
    totalComments,
    comments,
    canAddComment,
    canViewComments,
    handleCommentParams,
    handleDeleteComment,
    postBy,
    handlePostBy,
    updateComment,
    handleClearInput,
    clearInput,
    isDisabled,
    isAdmin,
    handleDeleteAComment,
  } = useComment(props);

  return (
    <>
      {canAddComment && (
        <>
          <div className={`${styles.add_a_comment_div} mt-3`}>
            <ProfileImage
              src={userProfileImg}
              title={user?.name}
              width={40}
              height={40}
              className={styles.profile_img}
              initialsClassName={styles.profile_img_initials}
            />

            <div className={styles.comment_input_div}>
              <div className={styles.input_field}>
                <CaptionInput
                  placeholder="Write a comment..."
                  onCaptionChange={handleCommentChange}
                  isAttendee={false}
                  singleLine
                  trackableType={props?.trackableType}
                  clear={clearInput}
                  onClearSuccess={handleClearInput}
                />
              </div>
            </div>

            {isAdmin && (
              <div className="material-form_inline-fields mr-4">
                <TextField
                  style={{ width: "100px" }}
                  className="material-form__field"
                  name={"post_by"}
                  placeholder="Post By"
                  label="Post By"
                  select
                  value={postBy}
                  onChange={(event) => {
                    handlePostBy(event.target.value);
                  }}
                  defaultValue={postBy}
                >
                  {postByList.map((post_by) => (
                    <MenuItem
                      key={post_by.id}
                      className="material-form__option"
                      value={post_by.value}
                    >
                      {post_by.displayText}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}

            <div
              className={
                isDisabled ? styles.disabled_button : styles.comment_button_div
              }
            >
              <Button disabled={isDisabled} onClick={handleSubmitComment}>
                Post
              </Button>
            </div>
          </div>
        </>
      )}

      {canViewComments && (
        <div className={styles.comment_container}>
          {comments.map((data) => {
            return (
              <CommentContent
                key={data?.id}
                data={data}
                user={user}
                userProfileImg={userProfileImg}
                handleDeleteComment={handleDeleteComment}
                trackableType={props?.trackableType}
                updateComment={updateComment}
                handleTotalComments={props?.handleTotalComments}
                isPostUnpublished={props.isPostUnpublished}
                handleDelete={handleDeleteAComment}
              />
            );
          })}
          {comments?.length < totalComments && (
            <div
              onClick={handleCommentParams}
              className={`${styles.view_more_comments} mb-3`}
            >
              <Text color={"grey"} fontWeight={700} size="14px">
                View more comments
              </Text>
            </div>
          )}
        </div>
      )}
    </>
  );
};

Comments.propTypes = {
  trackableType: PropTypes.string.isRequired,
  trackableId: PropTypes.oneOfType([string, number]).isRequired,
  viewComment: PropTypes.bool.isRequired,
  addComment: PropTypes.bool.isRequired,
};

export default Comments;
