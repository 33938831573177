import React from "react";
import DataTable from "../../components/table";
import { useFaq } from "./hooks";
import AddFaq from "./add-faq";
import EditFaq from "./edit-faq";
import DeleteFaq from "./delete-faq";
import { columns, faqStatus } from "./constant";
import SelectFilter from "../../components/filters/select-filter";
import SearchFilter from "../../components/filters/search-filter";

const FAQ = () => {
  const { faq, loadingFaq, setFilter, filters, faqCount } = useFaq();
  const configureTableData = () => {
    try {
      let results = faq.map((faq) => {
        let newFaq = Object.assign({}, faq);
        newFaq["category"] = faq?.category?.title ? faq?.category?.title : "NA";
        newFaq["action"] = (
          <div className="categories__list-action">
            <EditFaq faq={faq} />
            <DeleteFaq faq={faq} />
          </div>
        );
        return newFaq;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const faqFilter = () => (
    <>
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search Category"
        filterKey="category_text"
        filterTitle="Category"
        searchedValue={filters?.category?.text}
      />
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search Faqs"
        filterKey="text"
        filterTitle="Faqs"
        searchedValue={filters?.text}
      />
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters.status}
        filterKey="status"
        options={faqStatus}
        filterTitle="Status"
      />
    </>
  );

  return (
    <DataTable
      title="FAQ"
      addBtn={<AddFaq />}
      CreateTableData={configureTableData}
      withFilter={true}
      filter={faqFilter}
      handleSetFilter={setFilter}
      count={faqCount}
      skip={filters?.skip}
      isLoading={loadingFaq}
    />
  );
};

export default FAQ;
