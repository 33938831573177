import React from "react";
import DataTable from "../../components/data-table";
import { columnsOffers } from "./constant";
import { useUnlockedOffers } from "./hooks";
import * as moment from "moment";
import SearchFilter from "../../components/filters/search-filter";
import Tooltip from "@material-ui/core/Tooltip";

const UnlockedOffers = () => {
  const {
    unlockedOffers,
    loadingUnlockedOffers,
    setFilter,
    filters,
    count,
    resetFilter,
  } = useUnlockedOffers();

  const configureTableData = () => {
    try {
      let results = unlockedOffers.map((offer) => {
        let newOffer = Object.assign({}, offer);
        newOffer["subscribed_at"] = offer?.plan?.subscribed_at
          ? moment(offer?.plan?.subscribed_at).format("DD/MM/YYYY")
          : "NA";

        newOffer["viewed_at"] = offer?.viewed_at
          ? moment(offer?.viewed_at).format("DD/MM/YYYY @ hh:mm A")
          : "NA";

        newOffer["copied_at"] = offer?.copied_at
          ? moment(offer?.copied_at).format("DD/MM/YYYY @ hh:mm A")
          : "NA";

        newOffer["redeemed_at"] = offer?.redeemed_at
          ? moment(offer?.redeemed_at).format("DD/MM/YYYY @ hh:mm A")
          : "NA";

        newOffer["duration"] = `${offer?.plan?.duration} days`;
        return newOffer;
      });

      const reactTableData = {
        tableHeaderData: columnsOffers,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const UnlockedOffersFilters = () => (
    <>
      <SearchFilter
        filterKey="user_id"
        handleSearch={setFilter}
        filterTitle="User ID"
        searchedValue={filters?.user_id}
        searchPlaceholder="User ID"
      />

      <SearchFilter
        filterKey="offer_id"
        handleSearch={setFilter}
        filterTitle="Offer ID"
        searchedValue={filters?.offer_id}
        searchPlaceholder="Offer ID"
      />

      <SearchFilter
        filterKey="partner_name"
        handleSearch={setFilter}
        filterTitle="Partner Name"
        searchedValue={filters?.partner_name}
        searchPlaceholder="Partner  Name"
      />

      <SearchFilter
        filterKey="offer_text"
        handleSearch={setFilter}
        filterTitle="Offer Text"
        searchedValue={filters?.offer_text}
        searchPlaceholder="Offer Text"
      />

      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={resetFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  return (
    <DataTable
      title="Unlocked Offers"
      CreateTableData={configureTableData}
      withFilter={true}
      filter={UnlockedOffersFilters}
      handleSetFilter={setFilter}
      count={count}
      skip={filters?.skip}
      isLoading={loadingUnlockedOffers}
    />
  );
};

export default UnlockedOffers;
