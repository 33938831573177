import { useState } from "react";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { getEventAttendees, downloadEventAttendees } from "../actions";

export const useShowAttendees = (id) => {
  const dispatch = useDispatch();
  const [loadingAttendees, setLoadingAttendees] = useState(false);
  const [attendeesList, setAttendeesList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showTicket, setShowTicket] = useState(false);
  const [tickets, setTickets] = useState({});

  const openAttendeesList = async () => {
    setLoadingAttendees(true);
    const attendees = await dispatch(getEventAttendees(id));
    if (attendees) {
      setAttendeesList(attendees);
      setLoadingAttendees(false);
      toggleModal();
    }
  };

  const downloadAttendeesList = async () => {
    const exportData = await dispatch(downloadEventAttendees(id));
    if (exportData) {
      var blob = new Blob([exportData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `Attendees.xlsx`);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleShowTicket = () => {
    setShowTicket(!showTicket);
  };

  const handleTicket = (tickets) => {
    setTickets(tickets);
    setShowTicket(!showTicket);
  };

  return {
    openAttendeesList,
    loadingAttendees,
    attendeesList,
    toggleModal,
    isOpenModal,
    downloadAttendeesList,
    handleShowTicket,
    showTicket,
    tickets,
    handleTicket,
  };
};
