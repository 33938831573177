import {
  SET_PAGE_LIST,
  SET_PAGE_FILTER
} from "./actions";

const initialState = {
  pages: [],
  count: 0,
  filter: { status: "", limit: 10, skip: 0, },
  common_filter: { text: "", limit: 0, skip: 0, },
};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_LIST:
      return {
        ...state,
        pages: action.payload.pages,
        count: action.payload.count,
      };
    case SET_PAGE_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    };
    default:
      return state;
  }
};

export default pageReducer;
