import React from "react";
import { Button } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import DataTable from "../../../../components/data-table";
import { useBlogs } from "./hooks";
import { columns, statusOptions } from "./constant";
import SelectFilter from "../../../../components/filters/select-filter";
import SearchFilter from "../../../../components/filters/search-filter";
import DeleteBlog from "./delete-blog";
import UploadBlogs from "./upload-blogs";
import DateRangeFilter from "../../../../components/filters/date-range-filter";
import { useUser } from "../../../../common-hooks/user";
import ActionButton from "../../../../components/action-button";

const BlogList = () => {
  const {
    blogs,
    loadingBlogs,
    setFilter,
    filters,
    blogCount,
    handleEditClick,
    handleAddClick,
    downloadBlogReport,
    handleCommentClick,
    resetFilter,
  } = useBlogs();

  const { isAdmin, isCSSpecialist } = useUser();

  const configureTableData = () => {
    try {
      let results = blogs.map((blog) => {
        let newBlog = Object.assign({}, blog);
        newBlog["action"] = (
          <div className="blogs__container">
            <ActionButton
              title="View"
              className="blog__pad_left10"
              onClick={() => handleEditClick(blog?.id)}
              iconClassName={`fa fa-edit icon-btn-action`}
            />

            <DeleteBlog blog={blog?.id} />
          </div>
        );
        return newBlog;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const blogFilter = () => (
    <>
      <DateRangeFilter
        handleChange={setFilter}
        selectedValue={filters.published_at}
        filterKey="published_at"
        filterTitle="Date"
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        filterKey="status"
        options={statusOptions}
        defaultOption="All Statuses"
        filterTitle="Status"
      />
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search ID"
        filterKey="id"
        filterTitle="ID"
        searchedValue={filters?.id}
      />
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search Title"
        filterTitle="Title"
        filterKey="title"
        searchedValue={filters?.title}
      />
      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={resetFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  return (
    <DataTable
      title="Blogs"
      addBtn={
        <>
          <Button className="btn btn-success btn-sm" onClick={handleAddClick}>
            <i className="fa fa-plus"></i> Add Blog
          </Button>

          <ActionButton
            title="Download Blogs"
            className="download__blog_report"
            onClick={downloadBlogReport}
            iconClassName={"fa fa-file-excel-o fa-2x icon-btn-action"}
          />

          <UploadBlogs />

          <ActionButton
            title="Comments"
            className="comments__blog_action"
            onClick={handleCommentClick}
            iconClassName="fa fa-comments fa-2x icon-btn-action"
            access={isAdmin || isCSSpecialist}
          />
        </>
      }
      CreateTableData={configureTableData}
      withFilter={true}
      filter={blogFilter}
      handleSetFilter={setFilter}
      count={blogCount}
      skip={filters?.skip}
      isLoading={loadingBlogs}
    />
  );
};

export default BlogList;
