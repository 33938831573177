import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

const DataSelectFilter = ({
  handleSelect,
  selectedValue,
  filterKey,
  options,
  defaultOption,
  filterTitle,
  displayText,
}) => {
  return (
    <div className="table__search">
      <Tooltip title={filterTitle} placement="bottom">
        <select
          className={`${
            selectedValue ? "table__active_filter" : ""
          } form-control form__field-table-filter`}
          onChange={(e) => handleSelect(filterKey, e.target.value)}
          value={selectedValue || ""}
          name="selectFilter"
          id="selectFilter"
        >
          {defaultOption ? <option>{defaultOption}</option> : null}
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option[displayText]}
            </option>
          ))}
        </select>
      </Tooltip>
    </div>
  );
};

DataSelectFilter.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  filterKey: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  displayText: PropTypes.string.isRequired,
  defaultOption: PropTypes.string,
  filterTitle: PropTypes.string,
};

DataSelectFilter.defaultProps = {
  selectedValue: "",
  defaultOption: "",
  filterTitle: "",
};

export default DataSelectFilter;
