import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ScheduleEventForm from "../form/schedule-form";
import { useEditScheduleEvent } from "./hooks";
import Modals from "../../../components/modal";
import { setLanguage } from "../../../utils/auth";
import { useUser } from "../../../common-hooks/user";

const EditScheduleEvent = ({
  scheduleEvent,
  isActionDisable,
  handleEventsFilter,
}) => {
  const {
    handleEditScheduleEvent,
    isOpenModal,
    toggleModal,
    events,
    users,
    getAutocompleteValues,
    selectedScheduleEvent,
    editScheduleEventLoading,
    handleLangugeData,
  } = useEditScheduleEvent(scheduleEvent);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }
  return (
    <div>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={
            "fa fa-edit icon-btn-action " +
            (isActionDisable ? " icon-btn-action-disable" : null)
          }
          onClick={() => {
            setLanguage("en");
            getAutocompleteValues();
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <ScheduleEventForm
              handleEventForm={handleEditScheduleEvent}
              btn="Save"
              events={events}
              users={users}
              fieldValues={selectedScheduleEvent}
              isLoading={editScheduleEventLoading}
              handleLangugeData={handleLangugeData}
              handleEventsFilter={handleEventsFilter}
              isEdit
            />
          }
          title="Edit Scheduled Event"
        />
      ) : null}
    </div>
  );
};

export default EditScheduleEvent;
