import React from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

const DateTimePicker = ({
  selectedDate,
  handleChange,
  name,
  customInput,
  minDate,
  ...rest
}) => {
  return (
    <div className="date-picker">
      <DatePicker
        {...rest}
        className="form__form-group-datepicker"
        selected={selectedDate}
        onChange={(date) => handleChange(date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/yyyy"
        minDate={minDate}
        customInput={customInput}
        autoComplete="off"
      />
    </div>
  );
};

DateTimePicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  customInput: PropTypes.element,
};

export default DateTimePicker;
