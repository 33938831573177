import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import { useUser } from "../../../common-hooks/user";
import CourseForm from "../form/course-form";
import { useAddCourse } from "./hooks";

const AddCourse = (props) => {
  const { handleAddCourse, addCourseLoading, users, courses, events } =
    useAddCourse(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="react-table__wrapper">
              <div className="card__title card__title__flex">
                <h3 className="bold-text">ADD COURSE</h3>
              </div>
            </div>
            <CourseForm
              handleCourseForm={handleAddCourse}
              btn="Add"
              users={users}
              courses={courses}
              events={events}
              isLoading={addCourseLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default AddCourse;
