import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import Modals from "../../../components/modal";
import { useShowReviews } from "./hooks";
import ReviewTabs from "./review-tabs";

const ShowReviews = ({ eventScheduleId }) => {
  const {
    openReviewsList,
    toggleModal,
    isOpenModal,
    updateReviewStatus,
    scheduleEventReviewStatus,
    reviews,
  } = useShowReviews(eventScheduleId);

  return (
    <div>
      <Tooltip title="Click to view Reviews" placement="bottom">
        <div
          className="action_review-schedule-event"
          onClick={() => {
            openReviewsList("event");
          }}
        >
          <i className="fa fa-user" aria-hidden="true"></i>
          <i className="fa fa-star-o user-icon" aria-hidden="true"></i>
        </div>
      </Tooltip>

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <ReviewTabs
              tabs={["event", "host"]}
              tabsData={reviews}
              updateReviewStatus={updateReviewStatus}
              status={scheduleEventReviewStatus}
              eventScheduleId={eventScheduleId}
            />
          }
          title="Reviews"
        />
      ) : null}
    </div>
  );
};

export default ShowReviews;
