import React from "react";
import DataTable from "../../../components/table";
import AddPolicy from "./add-policy";
import { usePolicy } from "./hooks";
import { columns, policyName } from "./constant";
import SelectFilter from "../../../components/filters/select-filter";
import EditPolicy from "./edit-policy";
import DeletePolicy from "./delete-policy";
import * as moment from "moment";
function Policy() {
  const { loadingPolicy, policies, setFilter, filters, policyCount } =
    usePolicy();

  const configureTableData = () => {
    try {
      let results = policies.map((policy) => {
        let newPolicy = Object.assign({}, policy);

        newPolicy["created_at"] = moment(policy.created_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );

        newPolicy["action"] = (
          <div className="categories__list-action">
            <EditPolicy policy={policy} />
            <DeletePolicy policy={policy} />
          </div>
        );

        newPolicy["description"] = (
          <div dangerouslySetInnerHTML={{ __html: policy.description }} />
        );
        return newPolicy;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const policyFilter = () => (
    <>
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.text}
        options={policyName}
        filterKey="text"
        defaultOption="All"
        filterTitle="Names"
      />
    </>
  );

  return (
    <DataTable
      title="Policies"
      addBtn={<AddPolicy />}
      CreateTableData={configureTableData}
      withFilter={true}
      filter={policyFilter}
      handleSetFilter={setFilter}
      skip={filters?.skip}
      count={policyCount}
      isLoading={loadingPolicy}
    />
  );
}

export default Policy;
