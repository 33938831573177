import React from "react";
import CourseCard from "../course-card";
import EventCard from "../event-card";

function EventCardStrip({ type, data }) {
  if (type === "course") {
    return <CourseCard data={data} />;
  }

  return <EventCard data={data} />;
}

export default EventCardStrip;
