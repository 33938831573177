import React from "react";
import { useEditReason } from "./hooks";
import Modals from "../../../../components/modal";
import ReasonForm from "../form/reason-form";

const AddUser = (props) => {
  const { editReasonLoading, handleEditReason, isOpenModal, toggleModal } =
    useEditReason(props);

  return (
    <div>
      <i className={`fa fa-edit icon-btn-action`} onClick={toggleModal}></i>

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <ReasonForm
              fieldValues={props.option}
              handleReasonForm={handleEditReason}
              btn="Save"
              isLoading={editReasonLoading}
            />
          }
          title="Edit Reason"
        />
      ) : null}
    </div>
  );
};

export default AddUser;
