import {
  SET_FAQ_LIST,
  SET_FAQ_FILTER,
  RESET_FAQ_FILTER,
  SET_CATEGORY_LIST,
} from "./actions";

const initialList = {
  faq: [],
  categories: [],
  count: 0,
  filter: {
    status: "Published",
    text: "",
    category: { text: "" },
    limit: 10,
    skip: 0,
  },
};

const faqReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_FAQ_LIST:
      return {
        ...state,
        faq: action.payload.faqs,
        count: action.payload.count || [],
      };

    case SET_CATEGORY_LIST:
      return {
        ...state,
        categories: action.payload.categories,
      };
    case SET_FAQ_FILTER: {
      const { key, value } = action.payload;
      if (key === 'category_text') {
        return {
          ...state,
          filter: {
            ...state.filter,
            category: { text: value },
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case RESET_FAQ_FILTER:
      return { ...state, filter: initialList.filter || [] };
    default:
      return state;
  }
};

export default faqReducer;
