import React from "react";
import UserForm from "../form/user-form";
import { useAddUser } from "./hooks";
import Modals from "../../../components/modal";
import { Button } from "reactstrap";
import { useUser } from "./../../../common-hooks/user";

const AddUser = (props) => {
  const { handleAddUser, isOpenModal, toggleModal, addUserLoading } =
    useAddUser(props);

  const { isCoordinator, isMember } = useUser();

  if (isMember || isCoordinator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          toggleModal(!isOpenModal);
        }}
      >
        <i className="fa fa-plus"></i> Add User
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <UserForm
              handleUserForm={handleAddUser}
              btn="Add"
              isLoading={addUserLoading}
            />
          }
          title="Add User"
        />
      ) : null}
    </div>
  );
};

export default AddUser;
