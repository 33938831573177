import { useState } from "react";
import { bookEventFieldsName } from "../../constant";

export const useBookEventForm = () => {
  const formField = {
    event: "",
    time_slot: "",
  };

  const [formValues, setFormvalues] = useState(formField);
  const { event, time_slot } = bookEventFieldsName;

  const handleFormUpdate = async (key, value) => {
    if (key === event) {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [key]: value,
        time_slot: "",
      }));
    } else {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [key]: value,
      }));
    }
  };

  return {
    formValues,
    handleFormUpdate,
    event,
    time_slot,
  };
};
