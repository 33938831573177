import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTestimonial, getTestimonialList } from "../actions";
import { getTestimonialsFilter } from "../selectors";

export const useDeleteTestimonial = ({ id }) => {
  const dispatch = useDispatch();
  const filters = useSelector(getTestimonialsFilter);

  const [deleteTestimonialLoading, setDeleteTestimonialLoading] =
    useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleDeleteTestimonial = async () => {
    setDeleteTestimonialLoading(true);
    const is_success = await dispatch(deleteTestimonial(id));
    setDeleteTestimonialLoading(false);
    if (is_success) {
      dispatch(getTestimonialList(filters));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteTestimonialLoading,
    handleDeleteTestimonial,
    isOpenModal,
    toggleModal,
  };
};
