import React from "react";
import { ClickAwayListener } from "@material-ui/core";
import Lottie from "react-lottie";
import styles from "./styles.module.scss";
import {
  SmilingFaceIcon,
  LaughingIcon,
  HeartIcon,
  ClappingHand,
  SurprisedIcon,
  LikeIcon,
  ThankfulIcon,
} from "../../images";
import { useReaction } from "./hooks";
import { REACTION_ICON_MAP } from "../../constant";
import LaughIcon from "../../../animations/laugh-icon.json";
import ClapIcon from "../../../animations/clap-icon.json";
import LoveIcon from "../../../animations/love-icon.json";
import SurpiseIcon from "../../../animations/suprise-icon.json";
import ReactNumber from "../../../animations/react-number.json";
import Text from "../../../../../components/text";
import { colors } from "../../../../../constants/colors";
import { useUser } from "../../../../../common-hooks/user";

const LoveAnimation = {
  loop: true,
  autoplay: true,
  animationData: LoveIcon,
};
const LaughAnimation = {
  loop: true,
  autoplay: true,
  animationData: LaughIcon,
};
const ClapAnimation = {
  loop: true,
  autoplay: true,
  animationData: ClapIcon,
};
const SurpriseAnimation = {
  loop: true,
  autoplay: true,
  animationData: SurpiseIcon,
};

const ReactionIcon = ({ name, onReact, animation, icon }) => {
  return (
    <div
      onClick={() => {
        onReact(name);
      }}
      className={`cursor_pointer ${styles.reaction_icon_container}`}
    >
      <div className={styles.reaction_icon}>
        {animation ? (
          <Lottie isClickToPauseDisabled={true} options={animation} />
        ) : (
          <img src={icon} alt="react" />
        )}
      </div>
      <div>
        <Text size={"11px"}>{name}</Text>
      </div>
    </div>
  );
};

const Reaction = (props) => {
  const {
    reactionPopover,
    togglePopover,
    handleReactionSelect,
    selectedReaction,
    reactionAnim,
    // trackableType,
    isEgReaction,
  } = useReaction(props);

  const { isCSSpecialist } = useUser();

  if (isCSSpecialist) {
    return null;
  }

  const reactionIcon = REACTION_ICON_MAP[selectedReaction] || SmilingFaceIcon;

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (reactionPopover) {
          togglePopover();
        }
      }}
    >
      <div>
        {reactionPopover && (
          <div className={styles.popover}>
            <ReactionIcon
              onReact={handleReactionSelect}
              name="Like"
              icon={LikeIcon}
            />
             <ReactionIcon
              onReact={handleReactionSelect}
              name="Love"
              icon={HeartIcon}
              animation={LoveAnimation}
            />
            <ReactionIcon
              onReact={handleReactionSelect}
              name="Clap"
              icon={ClappingHand}
              animation={ClapAnimation}
            />
            <ReactionIcon
              onReact={handleReactionSelect}
              name="Laugh"
              icon={LaughingIcon}
              animation={LaughAnimation}
            />
            <ReactionIcon
              onReact={handleReactionSelect}
              name="Surprise"
              icon={SurprisedIcon}
              animation={SurpriseAnimation}
            />
            <ReactionIcon
              onReact={handleReactionSelect}
              name="Thankful"
              icon={ThankfulIcon}
            />
          </div>
        )}
        <div
          onClick={togglePopover}
          className={`${styles.container} ${
            props.disabled ? styles.react_disabled : "cursor_pointer "
          }`}
        >
          {reactionAnim && (
            <div className={styles.number_animation_container}>
              <Lottie
                isClickToPauseDisabled={true}
                options={{ animationData: ReactNumber }}
              />
            </div>
          )}
          <>
            <img
              height="24px"
              width="24px"
              className={styles.smile_icon}
              src={reactionIcon}
              alt="smileIcon"
            />

            <Text
              className={`${
                props.trackableType === "POST" && styles.eg_reaction
              }`}
              color={colors.grayDark2}
              size="15px"
              fontWeight={500}
            >
              {isEgReaction && "EG"} React
            </Text>
          </>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default Reaction;
