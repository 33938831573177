import React from "react";
import Modals from "../../../../components/modal";
import { Button } from "reactstrap";
import GeneratePaymentLinkForm from "./form/generate-paymnet-link-form";
import { useGeneratePaymentLink } from "./hooks";

const GeneratePaymentLink = ({ user_id }) => {
  const {
    toggleModal,
    isOpenModal,
    generateLinkLoading,
    handleGenerateLink,
    copyLink,
  } = useGeneratePaymentLink(user_id);

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Generate Payment Link
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <GeneratePaymentLinkForm
              btn="Generate Link"
              isLoading={generateLinkLoading}
              user_id={user_id}
              handleGenerateLink={handleGenerateLink}
              copyLink={copyLink}
            />
          }
          title="Generate Payment Link"
        />
      ) : null}
    </div>
  );
};

export default GeneratePaymentLink;
