import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import Topbar from "./topbar/Topbar";
import Sidebar from "./sidebar/Sidebar";
import { useLayout } from "./hooks";
import { useDispatch, useSelector } from "react-redux";
import { getSideBarShowValue } from "./selectors";
import { setSideBarVisibility } from "./action";

const Layout = () => {
  const [sidebarShow, setSidebarShow] = useState(false);
  const sidebarCollapse = useSelector(getSideBarShowValue);
  const { loggedInUser } = useLayout();
  const dispatch = useDispatch()

  const changeSidebarVisibility = () => {
    dispatch(setSideBarVisibility(!sidebarCollapse))
    // setSidebarCollapse(!sidebarCollapse);
  };

  const changeMobileSidebarVisibility = () => {
    setSidebarShow(!sidebarShow);
  };

  const layoutClass = classNames({
    layout: true,
    "layout--collapse": sidebarCollapse,
  });

  return (
    <div className={layoutClass}>
      <Topbar
        loggedInUser={loggedInUser}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />
      <Sidebar
        loggedInUser={loggedInUser}
        sidebarShow={sidebarShow}
        sidebarCollapse={sidebarCollapse}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
    </div>
  );
};

export default withRouter(Layout);
