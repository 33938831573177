import React from "react";
import PropTypes from "prop-types";
//import ReactTableBodyDnD from './ReactTableBodyDnD';

const DraggableOffBodyReactTable = ({
  page,
  getTableBodyProps,
  prepareRow,
}) => (
  <tbody className="table table--bordered" {...getTableBodyProps()}>
    {page.length > 0 ? (
      page.map((row) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map((cell) => (
              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
            ))}
          </tr>
        );
      })
    ) : (
      <tr>
        <td className="table__no-result-found">
          No results found. Please try with different criteria.
        </td>
      </tr>
    )}
  </tbody>
);

DraggableOffBodyReactTable.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
  page,
  getTableBodyProps,
  prepareRow,
  withDragAndDrop,
}) => (
  <>
    {withDragAndDrop ? //   page={page} // <ReactTableBodyDnD
    //   getTableBodyProps={getTableBodyProps}
    //   prepareRow={prepareRow}
    //   updateData={updateData}
    //   theme={theme}
    // />
    null : (
      <DraggableOffBodyReactTable
        page={page}
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
      />
    )}
  </>
);

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
};

export default ReactTableBody;
