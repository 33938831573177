import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import {
  category_options,
  consumption_types_options,
  entity_options,
  purpose_options,
  event_options,
  validity_options,
  usage_options,
  discount_type_options,
  discountTypes,
  vc_sub_category_options,
  other_sub_category_options,
  voucher_type_options,
  discount_options,
  VOUCHER_LIMIT,
  fieldInfo,
} from "../constant";
import { DataLoader } from "../../../components/loader";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";
import DatePicker from "../../../components/date-picker";
import { voucherFormSchema } from "./validation-schema";
import { useVoucherForm } from "./hooks";
import ScrollToError from "../../../components/scroll-to-error";
import moment from "moment";
import Required from "../../../components/required-field-sign";
import { InputProps } from "../../../components/input-props";

const VoucherForm = ({ handleVoucherForm, btn, isLoading, ...props }) => {
  const { formValues, handleFormUpdate, setFormvalues, fieldsName } =
    useVoucherForm(props);

  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    category,
    consumption_type,
    count,
    entity,
    entity_name,
    event,
    purpose,
    sub_category,
    usage,
    validity,
    usage_count,
    voucher_prefix,
    voucher_postfix,
    discount_percentage,
    discount_sessions,
    discount_type,
    max_discount,
    discount_amount,
    valid_from,
    valid_till,
    title,
    description,
    pattern,
    valid_for_days,
    voucher_type,
    has_discount,
  } = fieldsName;

  return (
    <Formik
      validationSchema={voucherFormSchema}
      validateOnMount
      enableReinitialize
      initialValues={{
        consumption_type: formValues?.consumption_type || "",
        count: formValues?.count || "",
        purpose: formValues?.purpose || "",
        category: formValues?.category || "",
        sub_category: formValues?.sub_category || "",
        discount_type: formValues?.discount_type || "",
        discount_percentage: formValues?.discount_percentage || "",
        discount_sessions: formValues?.discount_sessions || "",
        max_discount: formValues?.max_discount || "",
        discount_amount: formValues?.discount_amount || "",
        entity: formValues?.entity || "",
        entity_name: formValues?.entity_name || "",
        status: formValues?.status || "",
        validity: formValues?.validity || false,
        validity_type: formValues?.validity_type || "",
        validity_date:
          (formValues?.validity_date && new Date(formValues.validity_date)) ||
          "",
        valid_for_days: formValues?.valid_for_days || "",
        usage: formValues?.usage || "",
        usage_count: formValues.usage_count || "",
        voucher_prefix: formValues?.voucher_prefix || "",
        voucher_postfix: formValues?.voucher_postfix || "",
        event: formValues?.event || "",
        valid_from: formValues.valid_from || "",
        valid_till: formValues.valid_till || "",
        title: formValues?.title || "",
        description: formValues?.description || "",
        pattern: formValues.pattern || "",
        voucher_type: formValues.voucher_type || "",
        has_discount: formValues.has_discount || false,
      }}
      onSubmit={handleVoucherForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          <ScrollToError />
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={title}
                placeholder="Title"
                label={<Required title="Title" />}
                type="test"
                value={rest.values.title}
                onChange={(event) => {
                  const { name, value } = event.target;
                  setFieldValue(name, value);
                  handleFormValueChange(rest, title);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(title)}
                onBlur={() => handleBlurField()}
                InputProps={InputProps(fieldInfo.title)}
              />
              {focusField === title ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={title}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={description}
                multiline
                placeholder="Description"
                label={<Required title="Description" />}
                type="test"
                value={rest.values.description}
                onChange={(event) => {
                  const { name, value } = event.target;
                  setFieldValue(name, value);
                  handleFormValueChange(rest, description);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(description)}
                onBlur={() => handleBlurField()}
                InputProps={InputProps(fieldInfo?.description)}
              />
              {focusField === description ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={description}
                />
              )}
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={purpose}
                  placeholder="Purpose"
                  label={<Required title="Purpose" />}
                  select
                  value={rest.values.purpose}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, purpose);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(purpose)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.purpose}
                >
                  {purpose_options.map((type) => (
                    <MenuItem
                      key={type.id}
                      className="material-form__option"
                      value={type.value}
                    >
                      {type.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === purpose ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={purpose}
                  />
                )}
              </div>

              {rest.values.purpose === "Event" ? (
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={event}
                    placeholder="Event"
                    label={<Required title="Event" />}
                    select
                    value={rest.values.event}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                      handleFormValueChange(rest, event);
                      handleFormUpdate(event);
                    }}
                    onFocus={() => handleFocusField(event)}
                    onBlur={() => handleBlurField()}
                    defaultValue={rest.values.event}
                    InputProps={InputProps(fieldInfo.event)}
                  >
                    {event_options.map((type) => (
                      <MenuItem
                        key={type.id}
                        className="material-form__option"
                        value={type.value}
                      >
                        {type.displayText}
                      </MenuItem>
                    ))}
                  </TextField>
                  {focusField === event ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={event}
                    />
                  )}
                </div>
              ) : null}
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={category}
                  placeholder="Category"
                  label={<Required title="Category" />}
                  select
                  value={rest.values.category}
                  onChange={(event) => {
                    handleFormValueChange(rest, category);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(category)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.category}
                  InputProps={InputProps(fieldInfo.category)}
                >
                  {category_options.map((type) => (
                    <MenuItem
                      key={type.id}
                      className="material-form__option"
                      value={type.value}
                    >
                      {type.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === category ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={category}
                  />
                )}
              </div>

              {rest.values.category ? (
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={sub_category}
                    placeholder="Sub Category"
                    label={<Required title="Sub Category" />}
                    select
                    value={rest.values.sub_category}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                      handleFormValueChange(rest, sub_category);
                      handleFormUpdate(event);
                    }}
                    onFocus={() => handleFocusField(sub_category)}
                    onBlur={() => handleBlurField()}
                    defaultValue={rest.values.sub_category}
                  >
                    {[
                      ...(rest.values.category === "Voucher Code"
                        ? vc_sub_category_options
                        : other_sub_category_options),
                    ].map((type) => (
                      <MenuItem
                        key={type.id}
                        className="material-form__option"
                        value={type.value}
                      >
                        {type.displayText}
                      </MenuItem>
                    ))}
                  </TextField>
                  {focusField === sub_category ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={sub_category}
                    />
                  )}
                </div>
              ) : null}
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={entity}
                  placeholder="Entity"
                  label={<Required title="Entity" />}
                  select
                  value={rest.values.entity}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, entity);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(entity)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.entity}
                  InputProps={InputProps(fieldInfo.entity)}
                >
                  {entity_options.map((type) => (
                    <MenuItem
                      key={type.id}
                      className="material-form__option"
                      value={type.value}
                    >
                      {type.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === entity ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={entity}
                  />
                )}
              </div>

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={entity_name}
                  placeholder="Entity Name"
                  label={
                    rest.values.entity === "Organization" ||
                    rest.values.entity === "Housing Society" ? (
                      <Required title="Entity Name" />
                    ) : (
                      "Entity Name"
                    )
                  }
                  type="text"
                  value={rest.values.entity_name}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, entity_name);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(entity_name)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === entity_name ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={entity_name}
                  />
                )}
              </div>
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={validity}
                  placeholder="Validity"
                  label={<Required title="Validity" />}
                  select
                  value={rest.values.validity}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, validity);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(validity)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.validity}
                  InputProps={InputProps(fieldInfo.validty)}
                >
                  {validity_options.map((type) => (
                    <MenuItem
                      key={type.id}
                      className="material-form__option"
                      value={type.value}
                    >
                      {type.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === validity ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={validity}
                  />
                )}
              </div>

              {rest.values.validity ? (
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={valid_for_days}
                    placeholder="Validity Days e.g. 5"
                    label="Validity Days"
                    type="number"
                    value={rest.values.valid_for_days}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                      handleFormValueChange(rest, valid_for_days);
                      handleFormUpdate(event);
                    }}
                    onFocus={() => handleFocusField(valid_for_days)}
                    onBlur={() => handleBlurField()}
                  />
                  {focusField === valid_for_days ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={valid_for_days}
                    />
                  )}
                </div>
              ) : null}
            </div>
            {rest.values.validity ? (
              <div className="material-form_inline-fields">
                <>
                  <div className="materila-form_inline-child">
                    <DatePicker
                      selectedDate={rest.values.valid_from}
                      minDate={new Date()}
                      handleChange={(value) => {
                        setFieldValue(valid_from, value);
                        setFormvalues((prev) => ({
                          ...prev,
                          [valid_from]: value,
                          [valid_till]: moment(value, "DD-MM-YYYY")
                            .add(
                              rest.values.valid_for_days
                                ? rest.values.valid_for_days - 1
                                : 0,
                              "days"
                            )
                            .toDate(),
                        }));
                      }}
                      id="valid_from"
                      name={valid_from}
                      label={<Required title="Valid From" />}
                      customInput={
                        <TextField
                          className="material-form__field"
                          id="valid_from"
                          label="Valid From"
                          autoComplete="off"
                        />
                      }
                    />
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={valid_from}
                    />
                  </div>

                  <div className="materila-form_inline-child">
                    <DatePicker
                      selectedDate={rest.values.valid_till}
                      minDate={new Date()}
                      handleChange={(value) => {
                        setFieldValue(valid_till, value);
                        setFormvalues((prev) => ({
                          ...prev,
                          [valid_till]: value,
                        }));
                      }}
                      id="valid_till"
                      name={valid_till}
                      label={<Required title="Valid till" />}
                      disabled={!!rest.values.valid_for_days}
                      customInput={
                        <TextField
                          className="material-form__field"
                          id="valid_till"
                          label="Valid till"
                          autoComplete="off"
                        />
                      }
                    />
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={valid_till}
                    />
                  </div>
                </>
              </div>
            ) : null}

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={usage}
                  placeholder="Usage Type"
                  label={<Required title="Usage Type" />}
                  select
                  value={rest.values.usage}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, usage);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(usage)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.usage}
                >
                  {usage_options.map((type) => (
                    <MenuItem
                      key={type.id}
                      className="material-form__option"
                      value={type.value}
                    >
                      {type.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === usage ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={usage}
                  />
                )}
              </div>

              {rest.values.usage === "Many" ? (
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={usage_count}
                    placeholder="Usage Count"
                    label={<Required title="Usage Count" />}
                    type="number"
                    value={rest.values.usage_count}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                      handleFormValueChange(rest, usage_count);
                      handleFormUpdate(event);
                    }}
                    onFocus={() => handleFocusField(usage_count)}
                    onBlur={() => handleBlurField()}
                  />
                  {focusField === usage_count ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={usage_count}
                    />
                  )}
                </div>
              ) : null}
            </div>

            <fieldset className="fieldset__box">
              <legend>
                <p className="bold-text">Discount Format</p>
              </legend>
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={has_discount}
                  placeholder="Discount"
                  label={<Required title="Discount" />}
                  select
                  value={rest.values.has_discount}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, has_discount);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(has_discount)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.has_discount}
                  InputProps={InputProps(fieldInfo?.discount)}
                >
                  {discount_options.map((type) => (
                    <MenuItem
                      key={type.id}
                      className="material-form__option"
                      value={type.value}
                    >
                      {type.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === has_discount ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={has_discount}
                  />
                )}
              </div>

              {rest.values.has_discount ? (
                <>
                  <div className="material-form_inline-fields">
                    <div className="materila-form_inline-child">
                      <TextField
                        className="material-form__field"
                        name={discount_type}
                        placeholder="Discount Type"
                        label={<Required title="Discount Type" />}
                        select
                        value={rest.values.discount_type}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFieldValue(name, value);
                          handleFormValueChange(rest, discount_type);
                          handleFormUpdate(event);
                        }}
                        onFocus={() => handleFocusField(discount_type)}
                        onBlur={() => handleBlurField()}
                        defaultValue={rest.values.discount_type}
                      >
                        {discount_type_options.map((type) => (
                          <MenuItem
                            key={type.id}
                            className="material-form__option"
                            value={type.value}
                          >
                            {type.displayText}
                          </MenuItem>
                        ))}
                      </TextField>
                      {focusField === discount_type ? null : (
                        <ErrorMessage
                          render={(msg) => (
                            <div className="material-form__error">{msg}</div>
                          )}
                          name={discount_type}
                        />
                      )}
                    </div>
                    {rest.values.discount_type === discountTypes.Flat && (
                      <div className="materila-form_inline-child">
                        <TextField
                          className="material-form__field"
                          name={discount_amount}
                          placeholder="Discount Amount (in Rs)"
                          label={<Required title="Discount Amount (in Rs)" />}
                          type="number"
                          value={rest.values.discount_amount}
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setFieldValue(name, value);
                            handleFormValueChange(rest, discount_amount);
                            handleFormUpdate(event);
                          }}
                          onFocus={() => handleFocusField(discount_amount)}
                          onBlur={() => handleBlurField()}
                        />
                        {focusField === discount_amount ? null : (
                          <ErrorMessage
                            render={(msg) => (
                              <div className="material-form__error">{msg}</div>
                            )}
                            name={discount_amount}
                          />
                        )}
                      </div>
                    )}

                    {rest.values.discount_type === discountTypes.Percentage && (
                      <div className="materila-form_inline-child">
                        <TextField
                          className="material-form__field"
                          name={discount_percentage}
                          placeholder="Discount Percentage"
                          label={<Required title="Discount Percentage" />}
                          type="number"
                          value={rest.values.discount_percentage}
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setFieldValue(name, value);
                            handleFormValueChange(rest, discount_percentage);
                            handleFormUpdate(event);
                          }}
                          onFocus={() => handleFocusField(discount_percentage)}
                          onBlur={() => handleBlurField()}
                        />
                        {focusField === discount_percentage ? null : (
                          <ErrorMessage
                            render={(msg) => (
                              <div className="material-form__error">{msg}</div>
                            )}
                            name={discount_percentage}
                          />
                        )}
                      </div>
                    )}

                    {rest.values.discount_type === discountTypes.Sessions && (
                      <div className="materila-form_inline-child">
                        <TextField
                          className="material-form__field"
                          name={discount_sessions}
                          placeholder="No of sessions"
                          label={<Required title="No of sessions" />}
                          type="number"
                          value={rest.values.discount_sessions}
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setFieldValue(name, value);
                            handleFormValueChange(rest, discount_sessions);
                            handleFormUpdate(event);
                          }}
                          onFocus={() => handleFocusField(discount_sessions)}
                          onBlur={() => handleBlurField()}
                          InputProps={InputProps(fieldInfo?.no_of_sessions)}
                        />
                        {focusField === discount_sessions ? null : (
                          <ErrorMessage
                            render={(msg) => (
                              <div className="material-form__error">{msg}</div>
                            )}
                            name={discount_sessions}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {rest.values.discount_type === discountTypes.Percentage && (
                    <div className="materila-form_inline-child">
                      <TextField
                        className="material-form__field"
                        name={max_discount}
                        placeholder="Max Discount Amount (in Rs)"
                        label={<Required title="Max Discount Amount (in Rs)" />}
                        type="number"
                        value={rest.values.max_discount}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFieldValue(name, value);
                          handleFormValueChange(rest, max_discount);
                          handleFormUpdate(event);
                        }}
                        onFocus={() => handleFocusField(max_discount)}
                        onBlur={() => handleBlurField()}
                        InputProps={InputProps(fieldInfo?.max_discount)}
                      />
                      {focusField === max_discount ? null : (
                        <ErrorMessage
                          render={(msg) => (
                            <div className="material-form__error">{msg}</div>
                          )}
                          name={max_discount}
                        />
                      )}
                    </div>
                  )}
                </>
              ) : null}
            </fieldset>

            <fieldset className="fieldset__box">
              <legend>
                <p className="bold-text">Voucher Format</p>
              </legend>
              <div className="material-form_inline-fields">
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={consumption_type}
                    placeholder="Single or Bulk"
                    label={<Required title="Single or Bulk" />}
                    select
                    value={rest.values.consumption_type}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                      handleFormValueChange(rest, consumption_type);
                      handleFormUpdate(event);
                    }}
                    onFocus={() => handleFocusField(consumption_type)}
                    onBlur={() => handleBlurField()}
                    defaultValue={rest.values.consumption_type}
                  >
                    {consumption_types_options.map((type) => (
                      <MenuItem
                        key={type.id}
                        className="material-form__option"
                        value={type.value}
                      >
                        {type.displayText}
                      </MenuItem>
                    ))}
                  </TextField>
                  {focusField === consumption_type ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={consumption_type}
                    />
                  )}
                </div>

                {rest.values.consumption_type === "Bulk" ? (
                  <div className="materila-form_inline-child">
                    <TextField
                      className="material-form__field"
                      name={count}
                      placeholder={`Count (upto ${VOUCHER_LIMIT.toLocaleString()})`}
                      label={<Required title="no. of codes" />}
                      type="number"
                      value={rest.values.count}
                      onChange={(event) => {
                        const { name, value } = event.target;
                        setFieldValue(name, value);
                        handleFormValueChange(rest, count);
                        handleFormUpdate(event);
                      }}
                      onFocus={() => handleFocusField(count)}
                      onBlur={() => handleBlurField()}
                      InputProps={InputProps(fieldInfo?.count)}
                    />
                    {focusField === count ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={count}
                      />
                    )}
                  </div>
                ) : null}
              </div>

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={voucher_prefix}
                  placeholder="Voucher Prefix e.g. ABC, 123, A@$"
                  label="Prefix"
                  type="text"
                  value={rest.values.voucher_prefix}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, voucher_prefix);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(voucher_prefix)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo?.prefix)}
                />
                {focusField === voucher_prefix ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={voucher_prefix}
                  />
                )}
              </div>

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={voucher_type}
                  placeholder="Pattern Type"
                  label={<Required title="Pattern Type" />}
                  select
                  value={rest.values.voucher_type}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, voucher_type);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(voucher_type)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.voucher_type}
                  InputProps={InputProps(fieldInfo?.pattern_type)}
                >
                  {voucher_type_options.map((type) => (
                    <MenuItem
                      key={type.id}
                      className="material-form__option"
                      value={type.value}
                    >
                      {type.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === voucher_type ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={voucher_type}
                  />
                )}
              </div>
              {rest.values.voucher_type ? (
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={pattern}
                    placeholder="Voucher Pattern e.g. ABC###123"
                    label={<Required title="Voucher Pattern" />}
                    type="text"
                    value={rest.values.pattern}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                      handleFormValueChange(rest, pattern);
                      handleFormUpdate(event);
                    }}
                    onFocus={() => handleFocusField(pattern)}
                    onBlur={() => handleBlurField()}
                    InputProps={InputProps(fieldInfo?.pattern)}
                  />
                  {focusField === pattern ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={pattern}
                    />
                  )}
                </div>
              ) : null}

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={voucher_postfix}
                  placeholder="Voucher Postfix e.g. ABC, 123, A@$"
                  label="Postfix"
                  type="text"
                  value={rest.values.voucher_postfix}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, voucher_postfix);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(voucher_postfix)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo?.postfix)}
                />
                {focusField === voucher_postfix ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={voucher_postfix}
                  />
                )}
              </div>
            </fieldset>

            <button
              type="submit"
              className="btn btn-success btn-right btn-sm mt-4"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default VoucherForm;
