import {
  CLEAR_STORY_FILTER,
  SET_STORY_FILTER,
  SET_STORY_LIST,
} from "./actions";

const initialList = {
  stories: [],
  count: 0,
  filter: {
    status: "",
    role: "",
    caption: "",
    user_id: null,
    name: "",
    mobile_number: "",
    reported: "",
    limit: 10,
    skip: 0,
  },
};

const storyReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_STORY_LIST:
      return {
        ...state,
        stories: action.payload.stories,
        count: action?.payload?.count,
      };

    case SET_STORY_FILTER: {
      const { key, value } = action.payload;
      if (key === "user_id") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value ? value : null,
          },
        };
      }

      if (key === "role") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value,
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }

    case CLEAR_STORY_FILTER:
      return { ...state, filter: initialList.filter || {} };

    default:
      return state;
  }
};

export default storyReducer;
