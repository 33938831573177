import React from "react";
import { Route, Switch } from "react-router-dom";

import ScheduleEvents from "../../../schedule-events";
import Discussions from "../../../schedule-events/discussions";

export default () => (
  <Switch>
    <Route path="/schedule-events" component={ScheduleEvents} />
    <Route path="/schedule-event/:event_id/discussions" component={Discussions} />
  </Switch>
);
