/* eslint-disable */

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";
import { addPost, getPosts } from "../action";
import { MENTION_TYPES } from "../constant";
import { getPostsFilters } from "../selectors";

export const useAddPost = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [addPostLoading, setAddPostLoading] = useState(false);
  const postFilter = useSelector(getPostsFilters);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleAddPost = async (values) => {
    if (values?.plainTextValue.length > 2000) {
      dispatch(
        showSnackbar({
          type: snackbarTypes.ERROR,
          message: "Max length for post caption is 2000 characters",
        })
      );

      return;
    }
    setAddPostLoading(true);
    let ranges = [];

    values?.ranges?.forEach((mention) => {
      if (mention?.type) {
        ranges.push({
          trackable_type:
            mention?.type === "#"
              ? MENTION_TYPES.hashtag
              : mention?.type === "@"
              ? MENTION_TYPES.user
              : "",
          trackable_id: mention.id,
          name: mention?.name?.slice(1, mention?.name?.length),
          offset: mention.offset,
          length: mention.length,
        });
      }
    });

    let payload = {
      post: {
        caption: values?.plainTextValue,
        ranges,
        files: values?.files,
        status: values?.status,
        on_behalf_of:
          values?.post_by === "EvergreenClub" ? values?.post_by : "",
      },
    };

    if (values?.event_tagged) {
      payload["post"] = {
        ...payload?.post,
        event_tags: [
          {
            trackable_type: "EVENT_SCHEDULE",
            trackable_id: values?.event_tagged?.id,
          },
        ],
      };
    }

    if (values?.course_tagged) {
      payload["post"] = {
        ...payload?.post,
        event_tags: [
          {
            trackable_type: "COURSE",
            trackable_id: values?.course_tagged?.id,
          },
        ],
      };
    }

    if (values.blog) {
      payload["post"] = {
        ...payload?.post,
        shared_item: {
          trackable_id: values?.blog?.id,
          trackable_type: "BLOG",
        },
      };
    }

    if (values.events) {
      payload["post"] = {
        ...payload?.post,
        shared_item: {
          trackable_id: values?.events?.id,
          trackable_type: "EVENT_SCHEDULE",
        },
      };
    }

    if (values?.poll_topic) {
      payload["post"] = {
        ...payload?.post,
        poll: {
          topic: values?.poll_topic,
          choices: values?.poll_options,
        },
      };
    }

    const is_success = await dispatch(addPost(payload));
    setAddPostLoading(false);
    if (is_success) {
      await dispatch(getPosts(postFilter));
      toggleModal();
    }
  };

  return {
    isOpenModal,
    toggleModal,
    addPostLoading,
    handleAddPost,
  };
};
