export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Page URL",
    accessor: "page_url_path",
    disableGlobalFilter: true,
    width: 300,
  },
  {
    Header: "Meta Title",
    accessor: "meta_title",
    disableGlobalFilter: true,
    width: 200,
  },
  {
    Header: "Meta Description",
    accessor: "meta_description",
    disableGlobalFilter: true,
    width: 200,
  },
  {
    Header: "Meta Keywords",
    accessor: "meta_keywords",
    disableGlobalFilter: true,
    width: 200,
  },
  {
    Header: "Created At",
    accessor: "created_at",
    disableGlobalFilter: true,
    width: 200,
  },
  {
    Header: "Updated At",
    accessor: "updated_at",
    disableGlobalFilter: true,
    width: 200,
  },
  {
    Header: "Created By",
    accessor: "created_by",
    disableGlobalFilter: true,
  },
  {
    Header: "Updated By",
    accessor: "updated_by",
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
  },
];

export const fieldsName = {
  landing_page: "landing_page",
  meta_title: "meta_title",
  meta_description: "meta_description",
  meta_keywords: "meta_keywords",
  page_url: "page_url",
};
