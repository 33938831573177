import React from "react";

const CancellationPolicy = () => {
  return (
    <>
      <section className="cancellation-section">
        <h4 className="cancellation-title">Rules:</h4>
        <div className="cancellation-content">
          <p>
            Seniors Club allows its members to cancel the event. However, the
            user must keep the following points in mind before canceling the
            event:
          </p>
          <p>
            - We allow user cancellations only before the registration of the
            session closes, or before the session begins.
          </p>
          <p>
            - In case, a customer could not register for an event due to the
            unavailability of slots, he/she will be notified about the
            availability if there is any cancellation received from a
            participant attending the session. To get the notification, the user
            must select the “Notify me in case of availability” option in the
            events section.
          </p>
          <p>
            - A flag will be displayed against the number of events that have
            been canceled by the user.
          </p>
          <p>- All cancellations will be displayed in the user’s history.</p>
          <p>
            - The user will be asked the reason for canceling the event, the
            same will be stored or displayed in the user’s profile.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="cancellation-title"> Limitations:</h4>
        <div className="cancellation-content">
          <p>
            - Every session will have a specific number of slots, in case all
            the slots for the session are booked, any new user will be
            restricted to book that session.
          </p>
          <p>
            - In the case of poor internet connection or unforeseen
            circumstances, any scheduled session might get canceled or
            postponed. During such situations, the user will be informed well in
            advance.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="cancellation-title"> Refund:</h4>
        <div className="cancellation-content">
          <p>
            - The user will get the refund as credit points in his/her account
            only if they cancel the session 6 hours prior to the scheduled
            time of the event.
          </p>
          <p>
            - In case, the cancellation takes place after the above mentioned
            time, the refund will not be credited.
          </p>
          <p>
            - If a particular event is canceled or rescheduled from our end, we
            will notify and refund the points to the user’s account.
          </p>
        </div>
      </section>
    </>
  );
};

export default CancellationPolicy;
