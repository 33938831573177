import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestOtp, userLogin } from "../actions";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../../utils/auth";

export const useLogin = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mobileNumber = useSelector(
    ({ authReducer }) => authReducer.mobileNumber
  );

  useEffect(() => {
    if (isAuthenticated()) {
      history.push("/");
    }
  }, [history]);

  const handleGenrateOtp = async ({
    mobileNumber = "",
    countryCode = "+91",
  }) => {
    const is_success = await dispatch(
      requestOtp({ country_code: countryCode, mobile_number: mobileNumber })
    );
    if (is_success) {
    }
  };

  const handleOtpSubmit = async (values) => {
    const payload = {
      otp: values.otp,
      mobile_number: values.mobileNumber,
    };
    const is_success = await dispatch(userLogin(payload));
    if (is_success) {
      history.push("/");
    }
  };

  return { handleGenrateOtp, handleOtpSubmit, mobileNumber };
};
