export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Background Color",
    accessor: "background_color",
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: "Text Color",
    accessor: "text_color",
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 30,
  },
];

export const labelFieldsName = {
  title: "title",
  bg_color_code: "bg_color_code",
  text_color_code: "text_color_code",
  language: "language",
};

export const statusOptions = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
  {
    id: 3,
    value: "In Active",
    displayText: "In Active",
  },
];
