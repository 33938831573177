import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePlan, getPlanList } from "../actions";
import { getPlansFilter } from "../selectors";

export const useDeletePlan = ({ id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [deletePlanLoading, setDeletePlanLoading] = useState(false);
  const filter = useSelector(getPlansFilter);

  const handleDeletePlan = async () => {
    setDeletePlanLoading(true);
    const is_success = await dispatch(deletePlan(id));
    setDeletePlanLoading(false);
    if (is_success) {
      dispatch(getPlanList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deletePlanLoading,
    handleDeletePlan,
    isOpenModal,
    toggleModal,
  };
};
