import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryFilter, getUserFilter, getVideoFilter } from "../selectors";
import { clearFiles } from "../../../../../utils/upload-file/actions";
import {
  addVideo,
  getCategoriesList,
  getUserList,
  getVideoList,
} from "../actions";

export const useAddVideo = () => {
  const dispatch = useDispatch();
  const [addVideoLoading, setAddVideoLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const videoFilter = useSelector(getVideoFilter);
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const userFilters = useSelector(getUserFilter);
  const categoryFilters = useSelector(getCategoryFilter);

  useEffect(() => {
    (async () => {
      dispatch(clearFiles());
      isOpenModal && (await dispatch(getUserList(userFilters)));
    })();
  }, [dispatch, userFilters, isOpenModal]);

  useEffect(() => {
    (async () => {
      dispatch(clearFiles());
      isOpenModal && (await dispatch(getCategoriesList(categoryFilters)));
    })();
  }, [dispatch, categoryFilters, isOpenModal]);

  const handleAddVideo = async (values) => {
    setAddVideoLoading(true);
    try {
      const payload = {
        video: {
          title: values.title,
          description: values.description,
          status: values.status,
          published_at: values.published_at,
          category_id: values.category_id?.id,
          author_id: values.author_id?.id,
          url: values.url,
        },
        files,
      };

      const is_success = await dispatch(addVideo(payload));
      if (is_success) {
        dispatch(getVideoList(videoFilter));
        toggleModal();
      }
      setAddVideoLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
  };

  return {
    addVideoLoading,
    handleAddVideo,
    toggleModal,
    isOpenModal,
  };
};
