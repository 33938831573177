import React from "react";
import { Button } from "reactstrap";

import PartnerForm from "../form/partner-form";
import { useAddPartner } from "./hooks";
import Modals from "../../../components/modal";
import { useUser } from "../../../common-hooks/user";

const AddPartner = (props) => {
  const { addPartnerLoading, handleAddPartner, isOpenModal, toggleModal } =
    useAddPartner(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Add Partner
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PartnerForm
              handlePartnerForm={handleAddPartner}
              btn="Add"
              isLoading={addPartnerLoading}
            />
          }
          title="Add Partner"
        />
      ) : null}
    </div>
  );
};

export default AddPartner;
