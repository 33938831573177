import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import userLogo from "../../../../assets/images/user-icon.png";
import * as moment from "moment";
import { useProfile } from "./hooks";
import Tooltip from "@material-ui/core/Tooltip";

const UserProfile = ({ selectedUser, fullAddress }) => {
  const { regular_count, bonus_count, getAgeGroup } = useProfile(
    selectedUser?.user?.id,
    selectedUser?.profile?.age_group
  );
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img
                src={
                  selectedUser?.profile_picture?.url
                    ? selectedUser?.profile_picture?.url
                    : userLogo
                }
                alt="avatar"
              />
            </div>
            <div className="profile__data">
              <p className="profile__name">{selectedUser?.user?.name}</p>
              <p className="profile__work">{selectedUser?.user?.role}</p>
              <p className="profile__contact">
                <i className="fa fa-envelope-o fa-fw"></i>{" "}
                {selectedUser?.user?.email}
              </p>
              <p className="profile__contact">
                <i className="fa fa-phone fa-fw"></i>{" "}
                {selectedUser?.user?.country_code}{" "}
                {selectedUser?.user?.mobile_number}
              </p>
              <p className="profile__contact">
                <i className="fa fa-birthday-cake fa-fw"></i>{" "}
                {selectedUser?.profile?.date_of_birth
                  ? moment(selectedUser?.profile?.date_of_birth).format(
                      "DD/MM/YYYY"
                    )
                  : null}
              </p>
              <p className="profile__contact">
                <i className="fa fa-calendar-plus-o"></i> {getAgeGroup()}
              </p>

              {selectedUser?.profile?.emergency_mobile_number && (
                <Tooltip title="Emergency Mobile Number" placement="bottom">
                  <p className="profile__contact">
                    <i className="fa fa-phone-square fa-fw"></i>{" "}
                    {selectedUser?.profile?.emergency_mobile_number}
                  </p>
                </Tooltip>
              )}

              {selectedUser?.profile?.gender && (
                <p className="profile__contact">
                  <i className="fa fa-user fa-fw"></i>{" "}
                  {selectedUser?.profile?.gender}
                </p>
              )}
            </div>
          </div>
          <div className="row">
            <Col md={12} sm={12} lg={12} xl={12}>
              <p className="profile__address">
                <i className="fa fa-map-marker fa-fw"></i>{" "}
                {fullAddress ? fullAddress : "NA"}
              </p>
            </Col>
          </div>

          <div className="profile__stats">
            <div className="profile__stat">
              <p className="profile__stat-number">{regular_count}</p>
              <p className="profile__stat-title">Regular Session</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{bonus_count}</p>
              <p className="profile__stat-title">Bonus Session</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">
                {regular_count + bonus_count}
              </p>
              <p className="profile__stat-title">Total Session</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserProfile;
