import React from "react";

export const Loader = () => {
  return (
    <div className={`load loaded"}`}>
      <div className="load__icon-wrap">
        <svg className="load__icon">
          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
      </div>
    </div>
  );
};

export const DataLoader = () => {
  return (
    <div className="data-loader__overlay">
      <div className="cv-spinner">
        <svg className="load__icon">
          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
      </div>
    </div>
  );
};

export const TableLoader = () => {
  return (
    <div className="table-loader__overlay">
      <div className="cv-spinner">
        <svg className="load__icon">
          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
      </div>
    </div>
  );
};
