import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";

import { DataLoader } from "../../../../../components/loader";
import { bookEventSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import * as moment from "moment";
import { useBookEventForm } from "./hooks";
import Autocomplete from "@material-ui/lab/Autocomplete";

const BookEventForm = ({
  handleBookEventForm,
  btn,
  isLoading,
  eventList,
  getEventsTimeSlots,
  eventTimeSlots,
  setSearchText,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const { handleFormUpdate, formValues, event, time_slot } = useBookEventForm();

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={bookEventSchema}
      initialValues={{
        event: formValues.event ? formValues?.event : "",
        time_slot: formValues?.time_slot || "",
      }}
      onSubmit={handleBookEventForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div>
              <Autocomplete
                options={eventList}
                getOptionSelected={(option, value) => option.title !== value}
                getOptionLabel={(event) => (event.title ? event.title : "")}
                value={rest.values.event}
                name={event}
                onChange={(e, value) => {
                  setFieldValue(event, value);
                  handleFormUpdate(event, value);
                  value && getEventsTimeSlots(value?.id);
                  !value && setSearchText("");
                }}
                onFocus={() => handleFocusField(event)}
                onBlur={() => handleBlurField()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={event}
                    placeholder="Select Event"
                    label="Select Event"
                    variant="standard"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                )}
              />
              {focusField === event ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={event}
                />
              )}
            </div>

            {rest.values.event && (
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={time_slot}
                  placeholder="Time"
                  label="Time"
                  select
                  value={rest.values.time_slot}
                  onChange={(event) => {
                    handleFormValueChange(rest, time_slot);
                    handleFormUpdate(time_slot, event.target.value);
                  }}
                  onFocus={() => handleFocusField(time_slot)}
                  onBlur={() => handleBlurField()}
                >
                  {eventTimeSlots.length === 0 ? (
                    <MenuItem value={null} className="material-form__option">
                      No time slots are available for this event.
                    </MenuItem>
                  ) : (
                    eventTimeSlots?.map((event) => (
                      <MenuItem
                        key={event.id}
                        className="material-form__option"
                        value={event.id}
                      >
                        {`${moment(event.starts_at).format(
                          "DD/MM/YYYY @ hh:mm A"
                        )} to ${moment(event.ends_at).format(
                          "DD/MM/YYYY @ hh:mm A"
                        )}`}
                      </MenuItem>
                    ))
                  )}
                </TextField>
                {focusField === time_slot ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={time_slot}
                  />
                )}
              </div>
            )}
            <button
              disabled={rest.values.event ? eventTimeSlots.length === 0 : false}
              type="submit"
              className="btn btn-success btn-right btn-sm"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default BookEventForm;
