import {
    RESET_NOTICE_FILTER,
    SET_NOTICE_FILTER,
    SET_NOTICE_LIST,
    SET_EVENT_LIST,
    SET_EVENT_FILTER,
    RESET_EVENT_FILTER,
    SET_BLOG_LIST,
} from "./actions";

import * as moment from "moment";

const initialList = {
    notices: [],
    events: [],
    count: 0,
    eventFilter: {
        event_date: moment(new Date()).format("DD-MM-yyyy"),
        status: "Published",
    },
    filter: {
        title: "",
        trackable_type: "",
        status: "",
        limit: 10,
        skip: 0,
    },
    blogs: [],
    blogFilter: {
        status: "Published",
        limit: 0,
        skip: 0,
    },
};

const noticeReducer = (state = initialList, action) => {
    switch (action.type) {
        case SET_NOTICE_LIST:
            return {
                ...state,
                notices: action.payload.notices,
                count: action.payload.count || [],
            };
        case SET_EVENT_LIST:
            return {
                ...state,
                events: action.payload.event_schedules,
            };
        case SET_NOTICE_FILTER: {
            const { key, value } = action.payload;
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [key]: value,
                },
            };
        }
        case SET_EVENT_FILTER: {
            const { key, value } = action.payload;
            return {
                ...state,
                eventFilter: {
                    ...state.eventFilter,
                    [key]: value,
                },
            };
        }
        case RESET_NOTICE_FILTER:
            return { ...state, filter: initialList.filter || [] };
        case RESET_EVENT_FILTER:
            return { ...state, eventFilter: initialList.eventFilter || [] };
        case SET_BLOG_LIST:
            return {
                ...state,
                blogs: action.payload,
            };
        default:
            return state;
    }
};

export default noticeReducer;
