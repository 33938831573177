import { useState, useEffect, useCallback } from "react";
import {
  getStats,
  getPaymentHistories,
  setPaymentHistoryFilter,
  clearPaymentHistoryFilter,
  downloadTransactions,
  downloadInvoice,
} from "./actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatsList,
  getStatsFilter,
  getPaymentHistoriesFilter,
  getPaymentHistoriesList,
  getCount,
} from "./selectors";
import * as moment from "moment";
import { saveAs } from "file-saver";
import { useHistory } from "react-router";

export const useFinanceDashboard = (props) => {
  const [loadingPaymenthistories, setLoadingPaymentHistories] = useState(false);
  const stats = useSelector(getStatsList);
  const statsFilter = useSelector(getStatsFilter);
  const paymentHistories = useSelector(getPaymentHistoriesList);
  const paymentHistoryFilter = useSelector(getPaymentHistoriesFilter);
  const count = useSelector(getCount);
  const {
    location: { pathname },
  } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setLoadingPaymentHistories(true);
      await dispatch(getPaymentHistories(paymentHistoryFilter));
      setLoadingPaymentHistories(false);
    })();
  }, [dispatch, paymentHistoryFilter]);

  useEffect(() => {
    (async () => {
      await dispatch(getStats(statsFilter));
    })();
  }, [dispatch, statsFilter]);

  const setFilter = (key, value) => {
    dispatch(setPaymentHistoryFilter({ key, value }));
  };

  const resetFilter = useCallback(() => {
    dispatch(clearPaymentHistoryFilter());
  }, [dispatch]);

  useEffect(() => {
    resetFilter();
  }, [pathname, resetFilter]);

  const downloadPaymentHistories = async () => {
    const exportData = await dispatch(
      downloadTransactions(paymentHistoryFilter)
    );
    if (exportData) {
      var blob = new Blob([exportData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `Transactions.xlsx`);
    }
  };

  const handleChangeCard = (start, end) => {
    setFilter("cards", {
      start: moment(new Date(start)).format("DD-MM-YYYY"),
      end: moment(new Date(end)).format("DD-MM-YYYY"),
    });
  };

  const downloadUserInvoice = async (id) => {
    const invoice = await dispatch(downloadInvoice(id));
    if (invoice) {
      var blob = new Blob([invoice], {
        type: "application/pdf",
      });
      saveAs(blob, `EG-Invoice-${id}.pdf`);
    }
  };

  return {
    handleChangeCard,
    stats,
    paymentHistories,
    count,
    setFilter,
    paymentHistoryFilter,
    resetFilter,
    loadingPaymenthistories,
    downloadPaymentHistories,
    downloadUserInvoice,
  };
};
