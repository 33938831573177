import React from "react";
import DataTable from "../../../../components/table";
import { usePages } from "./hooks";
import { columns } from "./constant";
import DeletePage from "./delete-page";
import EditPage from "./edit-page";
import AddPage from "./add-page";

const PageList = () => {
  const { pages, loadingPages, setFilter, filters, pageCount } = usePages();

  const configureTableData = () => {
    try {
      let results = pages.map((page) => {
        let newPage = Object.assign({}, page);
        newPage["action"] = (
          <div className="page__container">
            <EditPage page={page} />
            <DeletePage page={page?.id} />
          </div>
        );
        return newPage;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DataTable
      title="Pages"
      addBtn={<AddPage />}
      CreateTableData={configureTableData}
      withFilter={false}
      handleSetFilter={setFilter}
      count={pageCount}
      skip={filters?.skip}
      isLoading={loadingPages}
    />
  );
};

export default PageList;
