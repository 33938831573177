import React from "react";
import { Route, Switch } from "react-router-dom";
import Reports from "../../../reports-data";
import Stories from "../../../social/stories";

export default () => (
  <Switch>
    <Route exact path="/stories" component={Stories} />
    <Route exact path="/stories/:id/reports" component={Reports} />
  </Switch>
);
