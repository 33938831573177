import { SET_CONFIG } from "./actions";

const initialList = {
  configs: [],
  count: 0,
  filter: {
    limit: 10,
    skip: 0,
  },
};

const configReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        configs: action.payload?.configs,
        count: action.payload?.count || 0,
      };

    default:
      return state;
  }
};

export default configReducer;
