import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnersList,
  setPartnersFilter,
  resetPartnersFilter,
} from "./actions";
import { getPartnerCount, getPartnerFilter, getPartners } from "./selectors";

export const usePartners = () => {
  const dispatch = useDispatch();
  const partners = useSelector(getPartners);
  const filters = useSelector(getPartnerFilter);
  const partnersCount = useSelector(getPartnerCount);

  const [loadingPartner, setloadingPartners] = useState(false);

  useEffect(() => {
    const fetchPartners = async () => {
      setloadingPartners(true);
      await dispatch(getPartnersList(filters));
      setloadingPartners(false);
    };
    fetchPartners();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setPartnersFilter({ key, value }));
  };

  const resetFilter = () => {
    dispatch(resetPartnersFilter());
  };

  return {
    partners,
    loadingPartner,
    setFilter,
    filters,
    partnersCount,
    resetFilter,
  };
};
