import { api, apiEndPoints } from "../../../api";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";

const namespace = "containers/users-details";

export const SET_EVENT_FILTER = `${namespace}/SET_EVENT_FILTER`;
export const CLEAR_EVENT_FILTER = `${namespace}/CLEAR_EVENT_FILTER`;
export const SET_USER_EVENT_LIST = `${namespace}/SET_USER_EVENT_LIST`;
export const SET_TRANSACTION_FILTER = `${namespace}/SET_TRANSACTION_FILTER`;
export const CLEAR_TRANSACTION_FILTER = `${namespace}/CLEAR_TRANSACTION_FILTER`;
export const SET_USER_TRANSACTION_LIST = `${namespace}/SET_USER_TRANSACTION_LIST`;
export const SET_PAYMENT_FILTER = `${namespace}/SET_PAYMENT_FILTER`;
export const CLEAR_PAYMENT_FILTER = `${namespace}/CLEAR_PAYMENT_FILTER`;
export const SET_USER_PAYMENT_LIST = `${namespace}/SET_USER_PAYMENT_LIST`;
export const SET_SESSIONS_COUNT = `${namespace}/SET_SESSIONS_COUNT`;
export const SET_REVIEWS_LIST = `${namespace}/SET_REVIEWS_LIST`;
export const SET_REVIEWS_FILTER = `${namespace}/SET_REVIEWS_FILTER`;
export const CLEAR_REVIEWS_FILTER = `${namespace}/CLEAR_REVIEWS_FILTER`;
export const SET_USER_WAITLIST = `${namespace}/SET_USER_WAITLIST`;
export const SET_WAITLIST_FILTER = `${namespace}/SET_WAITLIST_FILTER`;
export const CLEAR_WAITLIST_FILTER = `${namespace}/CLEAR_WAITLIST_FILTER`;
export const SET_REFUND_DETAILS = `${namespace}/SET_REFUND_DETAILS`;
export const SET_EVENT_LIST = `${namespace}/SET_EVENT_LIST`;
export const SET_BOOKEVENT_FILTER = `${namespace}/SET_BOOKEVENT_FILTER`;
export const SET_SCHEDULED_EVENT_TIMING = `${namespace}/SET_SCHEDULED_EVENT_TIMING`;
export const SET_REFERAL_LIST = `${namespace}/SET_REFERAL_LIST`;
export const SET_REFERRALS_FILTER = `${namespace}/SET_REFERRALS_FILTER`;
export const CLEAR_REFERRALS_FILTER = `${namespace}/CLEAR_REFERRALS_FILTER`;
export const SET_ACTIVE_PLANS = `${namespace}/SET_ACTIVE_PLANS`;
export const SET_PAYMENT_LINK = `${namespace}/SET_PAYMENT_LINK`;
export const CLEAR_PAYMENT_LINK = `${namespace}/CLEAR_PAYMENT_LINK`;
export const SET_PARTNER_OFFER_LIST = `${namespace}/SET_PARTNER_OFFER_LIST`;
export const SET_OFFER_LIST_FILTER = `${namespace}/SET_OFFER_LIST_FILTER`;
export const CLEAR_OFFER_LIST_FILTER = `${namespace}/CLEAR_OFFER_LIST_FILTER`;
export const SET_COURSE_LIST = `${namespace}/SET_COURSE_LIST`;
export const SET_COURSE_FILTER = `${namespace}/SET_COURSE_FILTER`;
export const CLEAR_COURSE_FILTER = `${namespace}/CLEAR_COURSE_FILTER`;

export const SET_POST_LIST = `${namespace}/SET_POST_LIST`;
export const SET_POST_FILTER = `${namespace}/SET_POST_FILTER`;
export const CLEAR_POST_FILTER = `${namespace}/CLEAR_POST_FILTER`;

export const SET_USER_STORIES = `${namespace}/SET_USER_STORIES`;
export const SET_STORIES_FILTER = `${namespace}/SET_STORIES_FILTER`;
export const CLEAR_STORIES_FILTER = `${namespace}/CLEAR_STORIES_FILTER`;

export const SET_FRIENDS_LIST = `${namespace}/SET_FRIENDS_LIST`;
export const SET_FRIENDS_FILTER = `${namespace}/SET_FRIENDS_FILTER`;
export const CLEAR_FRIENDS_FILTER = `${namespace}/CLEAR_FRIENDS_FILTER`;

export const SET_PRIVACY_SETTINGS_LIST = `${namespace}/SET_PRIVACY_SETTINGS_LIST`;
export const SET_PRIVACY_SETTINGS_FILTER = `${namespace}/SET_PRIVACY_SETTINGS_FILTER`;
export const CLEAR_PRIVACY_SETTINGS_FILTER = `${namespace}/CLEAR_PRIVACY_SETTINGS_FILTER`;

export const SET_NOTIFICATIONS_LIST = `${namespace}/SET_NOTIFICATIONS_LIST`;
export const SET_NOTIFICATIONS_FILTER = `${namespace}/SET_NOTIFICATIONS_FILTER`;
export const CLEAR_NOTIFICATIONS_FILTER = `${namespace}/CLEAR_NOTIFICATIONS_FILTER`;

export const SET_INSTRUCTORS_LIST = `${namespace}/SET_INSTRUCTORS_LIST`;
export const SET_INSTRUCTORS_FILTER = `${namespace}/SET_INSTRUCTORS_FILTER`;
export const CLEAR_INSTRUCTORS_FILTER = `${namespace}/CLEAR_INSTRUCTORS_FILTER`;

export const SET_REPORTS_LIST = `${namespace}/SET_REPORTS_LIST`;
export const SET_REPORTS_FILTER = `${namespace}/SET_REPORTS_FILTER`;
export const CLEAR_REPORTS_FILTER = `${namespace}/CLEAR_REPORTS_FILTER`;

//users who have been blocked by this user.
export const SET_BLOCKED_TO_LIST = `${namespace}/SET_BLOCKED_TO_LIST`;
export const SET_BLOCKED_TO_FILTER = `${namespace}/SET_BLOCKED_TO_FILTER`;
export const CLEAR_BLOCKED_TO_FILTER = `${namespace}/CLEAR_BLOCKED_TO_FILTER`;

export const setEventFilter = (payload) => ({
  type: SET_EVENT_FILTER,
  payload,
});

export const clearEventFilter = (payload) => ({
  type: CLEAR_EVENT_FILTER,
  payload,
});

export const setCourseList = (payload) => ({
  type: SET_COURSE_LIST,
  payload,
});

export const setCourseFilter = (payload) => ({
  type: SET_COURSE_FILTER,
  payload,
});

export const clearCourseFilter = (payload) => ({
  type: CLEAR_COURSE_FILTER,
  payload,
});

const setUserEventList = (payload) => ({
  type: SET_USER_EVENT_LIST,
  payload,
});

export const setUserTransactionFilter = (payload) => ({
  type: SET_TRANSACTION_FILTER,
  payload,
});

export const clearUserTransactionFilter = (payload) => ({
  type: CLEAR_TRANSACTION_FILTER,
  payload,
});

export const setUserTransactionList = (payload) => ({
  type: SET_USER_TRANSACTION_LIST,
  payload,
});

export const setUserPaymentFilter = (payload) => ({
  type: SET_PAYMENT_FILTER,
  payload,
});

export const clearUserPaymentFilter = (payload) => ({
  type: CLEAR_PAYMENT_FILTER,
  payload,
});

export const setUserPaymentList = (payload) => ({
  type: SET_USER_PAYMENT_LIST,
  payload,
});

const setSessionsCount = (payload) => ({
  type: SET_SESSIONS_COUNT,
  payload,
});

export const setReviewsList = (payload) => ({
  type: SET_REVIEWS_LIST,
  payload,
});

export const setReviewsFilter = (payload) => ({
  type: SET_REVIEWS_FILTER,
  payload,
});

export const clearReviewsFilter = (payload) => ({
  type: CLEAR_REVIEWS_FILTER,
  payload,
});

export const setUserWaitlist = (payload) => ({
  type: SET_USER_WAITLIST,
  payload,
});

export const setWaitlistFilter = (payload) => ({
  type: SET_WAITLIST_FILTER,
  payload,
});

export const clearWaitlistFilter = (payload) => ({
  type: CLEAR_WAITLIST_FILTER,
});

export const setRefundDetails = (payload) => ({
  type: SET_REFUND_DETAILS,
  payload,
});

export const setBookEventList = (payload) => ({
  type: SET_EVENT_LIST,
  payload,
});

export const setBookEventFilter = (payload) => ({
  type: SET_BOOKEVENT_FILTER,
  payload,
});

export const setEventTimimg = (payload) => ({
  type: SET_SCHEDULED_EVENT_TIMING,
  payload,
});

export const setReferalList = (payload) => ({
  type: SET_REFERAL_LIST,
  payload,
});

export const setActivePlans = (payload) => ({
  type: SET_ACTIVE_PLANS,
  payload,
});

export const setReferralsFilter = (payload) => ({
  type: SET_REFERRALS_FILTER,
  payload,
});

export const clearReferralsFilter = (payload) => ({
  type: CLEAR_REFERRALS_FILTER,
});

export const setPaymentLink = (payload) => ({
  type: SET_PAYMENT_LINK,
  payload,
});

export const clearPaymentLink = () => ({
  type: CLEAR_PAYMENT_LINK,
});

export const setPartnerOfferList = (payload) => ({
  type: SET_PARTNER_OFFER_LIST,
  payload,
});

export const setOfferListFilters = (payload) => ({
  type: SET_OFFER_LIST_FILTER,
  payload,
});

export const clearOfferListFilter = (payload) => ({
  type: CLEAR_OFFER_LIST_FILTER,
  payload,
});

export const setPostList = (payload) => ({
  type: SET_POST_LIST,
  payload,
});

export const setPostFilter = (payload) => {
  return { type: SET_POST_FILTER, payload };
};

export const clearPostFilter = (payload) => ({
  type: CLEAR_POST_FILTER,
  payload,
});

export const setUserStories = (payload) => ({
  type: SET_USER_STORIES,
  payload,
});

export const setStoriesFilter = (payload) => {
  return { type: SET_STORIES_FILTER, payload };
};

export const clearStoriesFilter = (payload) => ({
  type: CLEAR_STORIES_FILTER,
  payload,
});

export const setFriendsList = (payload) => ({
  type: SET_FRIENDS_LIST,
  payload,
});

export const setFriendsFilter = (payload) => ({
  type: SET_FRIENDS_FILTER,
  payload,
});

export const clearFriendsFilter = (payload) => ({
  type: CLEAR_FRIENDS_FILTER,
  payload,
});

export const setPrivacySettingsList = (payload) => ({
  type: SET_PRIVACY_SETTINGS_LIST,
  payload,
});

export const setPrivacySettingsFilter = (payload) => ({
  type: SET_PRIVACY_SETTINGS_FILTER,
  payload,
});

export const clearPrivacySettingsFilter = (payload) => ({
  type: CLEAR_PRIVACY_SETTINGS_FILTER,
  payload,
});

export const setNotificationsList = (payload) => ({
  type: SET_NOTIFICATIONS_LIST,
  payload,
});

export const setNotificationsFilter = (payload) => ({
  type: SET_NOTIFICATIONS_FILTER,
  payload,
});

export const clearNotificationsFilter = (payload) => ({
  type: CLEAR_NOTIFICATIONS_FILTER,
  payload,
});

export const setInstructorsList = (payload) => ({
  type: SET_INSTRUCTORS_LIST,
  payload,
});

export const setInstructorsFilter = (payload) => ({
  type: SET_INSTRUCTORS_FILTER,
  payload,
});

export const clearInstructorsFilter = (payload) => ({
  type: CLEAR_INSTRUCTORS_FILTER,
  payload,
});

export const setUserReportsList = (payload) => ({
  type: SET_REPORTS_LIST,
  payload,
});

export const setUserReportsFilter = (payload) => ({
  type: SET_REPORTS_FILTER,
  payload,
});

export const clearUserReportsFilter = (payload) => ({
  type: CLEAR_REPORTS_FILTER,
  payload,
});

//users who have been blocked by this user.
export const setBlockedToList = (payload) => ({
  type: SET_BLOCKED_TO_LIST,
  payload,
});

export const setBlockedToFilter = (payload) => ({
  type: SET_BLOCKED_TO_FILTER,
  payload,
});

export const clearBlockedToFilter = (payload) => ({
  type: CLEAR_BLOCKED_TO_FILTER,
  payload,
});

export const getUser = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUser(id));
    const { data: { is_success, user_details } = {} } = response;
    if (is_success) {
      return user_details;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserEventScheduleAction =
  (user_id, params) => async (dispatch) => {
    try {
      const response = await api.get(
        apiEndPoints.getUserEventSchedules(user_id),
        {
          params: { params },
        }
      );
      const { data, data: { is_success } = {} } = response;
      if (is_success) {
        dispatch(setUserEventList(data));
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

export const getUserInterests = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUserInterests(id));
    const { data: { is_success, interests } = {} } = response;
    if (is_success) {
      return interests;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getTranasactionHistoriesAction =
  (user_id, params) => async (dispatch) => {
    try {
      const response = await api.get(
        apiEndPoints.getUserTransactionHistories(user_id),
        {
          params: { params },
        }
      );
      const { data, data: { is_success } = {} } = response;
      if (is_success) {
        dispatch(setUserTransactionList(data));
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

export const getPaymentHistoriesAction =
  (user_id, params) => async (dispatch) => {
    try {
      const response = await api.get(
        apiEndPoints.getUserPaymentHistories(user_id),
        {
          params: { params },
        }
      );
      const { data, data: { is_success } = {} } = response;
      if (is_success) {
        dispatch(setUserPaymentList(data));
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

export const downloadBookingHistory = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.dowloadBookingHistory(id), {
      params: { params },
      responseType: "arraybuffer",
    });
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadPaymentHistory = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.dowloadPaymentHistory(id), {
      params: { params },
      responseType: "arraybuffer",
    });
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadCreditUsageHistory = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.dowloadCreditUsageHistory(id), {
      params: { params },
      responseType: "arraybuffer",
    });
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addBonusSession = (user_id, data) => async (dispatch) => {
  try {
    const response = await api.post(
      apiEndPoints.addBonusSession(user_id),
      data
    );
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getSessionsCountAction = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSessionsCount(id));
    const { data: { is_success, stats } = {} } = response;
    if (is_success) {
      dispatch(setSessionsCount(stats));
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadInvoice = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.downloadInvoice(id), {
      responseType: "arraybuffer",
    });
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getReviewsAction = (user_id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUserReviews(user_id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setReviewsList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const publishUnpublishReview = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(
      apiEndPoints.changeReviewStatus(id),
      payload
    );
    const {
      data,
      data: { is_success },
    } = response;

    if (is_success) {
      return data;
    }
  } catch (error) {
    return false;
  }
};

export const getUserWaitList = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUserWaitlists(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setUserWaitlist(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const cancelScheduledEvent = (payload, values) => async (dispatch) => {
  try {
    const { scheduleEventId, userId } = payload;
    const response = await api.post(
      apiEndPoints.cancelScheduledEvent(scheduleEventId, userId),
      values
    );
    const {
      data: { is_success, message },
    } = response;

    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getRefundDetails = (payload) => async (dispatch) => {
  try {
    const { scheduleEventId, userId } = payload;
    const response = await api.get(
      apiEndPoints.getRefundDetails(userId, scheduleEventId)
    );
    const { data = {} } = response;

    if (data) {
      dispatch(setRefundDetails(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getEvents = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEvents, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setBookEventList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getCoursesData = (user_id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUserCourses(user_id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setCourseList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getEventTime = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEventTime(id));
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setEventTimimg(data));
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const bookUserEvent = (user_id, eventScheduleId) => async (dispatch) => {
  try {
    const response = await api.post(
      apiEndPoints.bookeUserEvent(user_id, eventScheduleId)
    );
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const copyRefLink = () => async (dispatch) => {
  dispatch(showSnackbar({ message: "Copied!", type: snackbarTypes.SUCCESS }));
  return true;
};

export const getReferalsAction = (user_id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getReferals(user_id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setReferalList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getActivePlans = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getActivePlans, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setActivePlans(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const copyPaymentLink = () => async (dispatch) => {
  dispatch(showSnackbar({ message: "Copied!", type: snackbarTypes.SUCCESS }));
  dispatch(clearPaymentLink());
  return true;
};

export const generatePaymentLink =
  (user_id, planVariantId) => async (dispatch) => {
    try {
      const response = await api.post(
        apiEndPoints.generatePaymentLink(user_id, planVariantId)
      );
      const { data: { is_success, message, payment_link } = {} } = response;
      if (is_success) {
        dispatch(
          showSnackbar({
            message: message,
            type: snackbarTypes.SUCCESS,
          })
        );
        dispatch(setPaymentLink(payment_link));
        return true;
      }
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.ERROR,
        })
      );
      return false;
    } catch (error) {
      return false;
    }
  };

export const getPlanDetails = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUserActivePlans(id));
    const { data: { is_success, plan } = {} } = response;
    if (is_success) {
      return plan;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getPartnerOffersList = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUserPartnerOffers(id));
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setPartnerOfferList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const cancelUserCourse = (payload, values) => async (dispatch) => {
  try {
    const { userId, courseId } = payload;
    const response = await api.post(
      apiEndPoints.canceUserCourse(userId, courseId),
      values
    );
    const {
      data: { is_success, message },
    } = response;

    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getPostsList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPosts, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setPostList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserStories = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUserStories(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setUserStories(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getStoryViewers = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getStoryViewer(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserFriends = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUserFriends(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setFriendsList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserPrivacySettings = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUserPrivacySettings(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setPrivacySettingsList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserNotifications = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUserNotification(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setNotificationsList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserFollwedInstructors = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUserFollwedInstructors(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setInstructorsList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUsersReports = (user_id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getReports(user_id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setUserReportsList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getBlockedToUsers = (user_id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getBlockedUser(user_id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setBlockedToList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
