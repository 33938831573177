import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fileContext,
  initialLanguageFieldValue,
  initialLanguageArrayValue,
} from "../../../constants";
import { updateFiles } from "../../../utils/upload-file/actions";
import { getEvent } from "../actions";
import { fieldNames } from "../constant";

const { requirements, event_schedules, attendees_limit, description, title } =
  fieldNames;

const formField = {
  status: "",
  language: "",
  mode: "Online",
  attendees_limit: "",
  current_attendees_limit: "",
  exceeds_percentage: "",
  increment_percentage: "",
  event_id: null,
  hostIds: null,
  cohostIds: [],
  form_language: "en",
  allow_booking_post_commencement: "",
  allow_seats_increment: "",
  requirements: initialLanguageArrayValue,
  event_schedules: [
    {
      btn: "Add",
      title: initialLanguageFieldValue,
      type: "",
      starts_at: "",
      ends_at: "",
      attendees_limit: "",
      current_attendees_limit: "",
      exceeds_percentage: "",
      increment_percentage: "",
      allow_booking_post_commencement: "",
      allow_seats_increment: "",
      chapters: initialLanguageArrayValue,
      requirements: [],
      pre_event_file_cta: "",
      pre_event_file: {},
      note: {},
    },
  ],
};

export const useCourseScheduleForm = ({
  fieldValues,
  btn,
  events,
  users,
  isLoading,
}) => {
  const [formValues, setFormValues] = useState(fieldValues || formField);
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  useEffect(() => {
    if (btn === "Add") {
      setFormValues({
        status: "",
        language: "",
        mode: "Online",
        attendees_limit: "",
        current_attendees_limit: "",
        exceeds_percentage: "",
        increment_percentage: "",
        event_id: null,
        form_language: "en",
        hostIds: null,
        cohostIds: [],
        requirements: initialLanguageArrayValue,
        event_schedules: [
          {
            btn: "Add",
            title: initialLanguageFieldValue,
            type: "",
            starts_at: "",
            ends_at: "",
            attendees_limit: "",
            current_attendees_limit: "",
            exceeds_percentage: "",
            increment_percentage: "",
            allow_booking_post_commencement: "",
            allow_seats_increment: "",
            chapters: initialLanguageArrayValue,
            requirements: [],
            pre_event_file_cta: "",
            pre_event_file: {},
            note: {},
          },
        ],
      });
    }
  }, [btn]);

  //to update only Requirements object field values
  const handleFormUpdateRequirements = (
    rest,
    setFieldValue,
    key,
    value,
    index
  ) => {
    let newRequirements = {
      ...rest.values.requirements,
    };

    newRequirements[rest.values.form_language][index][key] = value || "";
    setFormValues((prevFieldValue) => ({
      ...prevFieldValue,
      requirements: newRequirements,
    }));
    setFieldValue(requirements, newRequirements);
  };

  // handle click to remove requirement
  const handleRemoveClickRequirements = (rest, setFieldValue, index) => {
    const list = { ...rest.values.requirements };
    list[rest.values.form_language].splice(index, 1);
    setFieldValue(requirements, list);
    handleFormUpdate(requirements, list);
  };

  // handle click to Add new requirement
  const handleAddClickRequirements = (rest, setFieldValue) => {
    const list = {
      ...rest?.values?.requirements,
      [rest.values.form_language]: [
        ...rest?.values?.requirements[rest.values.form_language],
        { title: "", description: [] },
      ],
    };

    setFieldValue(requirements, list);
    handleFormUpdate(requirements, list);
  };

  // handle click event change to change default requirements of event
  const handleEventChange = (value, setFieldValue) => {
    let list = [];
    events.forEach((event) => {
      if (event?.id === value?.id) {
        list = event?.requirements?.length > 0 ? event?.requirements : [];
      }
      return list;
    });
    setFieldValue(requirements, list);
    handleFormUpdate(requirements, list);
  };

  const handleRegularEventChange = (name, value, rest, setFieldValue) => {
    let list = rest.values.event_schedules;
    if (name === "attendees_limit")
      list.forEach((ev, i) => {
        if (ev.type === "Regular") list[i].attendees_limit = parseInt(value);
      });

    if (name === "current_attendees_limit")
      list.forEach((ev, i) => {
        if (ev.type === "Regular")
          list[i].current_attendees_limit = parseInt(value);
      });
    if (name === "exceeds_percentage")
      list.forEach((ev, i) => {
        if (ev.type === "Regular") list[i].exceeds_percentage = parseInt(value);
      });

    if (name === "increment_percentage")
      list.forEach((ev, i) => {
        if (ev.type === "Regular")
          list[i].increment_percentage = parseInt(value);
      });

    if (name === "allow_booking_post_commencement")
      list.forEach((ev, i) => {
        if (ev.type === "Regular")
          list[i].allow_booking_post_commencement = value;
      });
    if (name === "allow_seats_increment")
      list.forEach((ev, i) => {
        if (ev.type === "Regular") list[i].allow_seats_increment = value;
      });

    setFieldValue(event_schedules, list);
  };

  const handleFormUpdate = (name, value) => {
    if (name === "event_id") {
      setFormValues((prevFieldValue) => ({
        ...prevFieldValue,
        [name]: value,
        attendees_limit: value?.attendees_limit,
        current_attendees_limit: value?.current_attendees_limit,
        exceeds_percentage: value?.exceeds_percentage,
        increment_percentage: value?.increment_percentage,
        requirements: {
          [prevFieldValue.form_language]: value?.requirements
            ? value?.requirements
            : [],
        },
        event_schedules: prevFieldValue?.event_schedules.map((ev) => {
          return {
            ...ev,
            attendees_limit: value?.attendees_limit,
            current_attendees_limit: value?.current_attendees_limit,
            exceeds_percentage: value?.exceeds_percentage,
            increment_percentage: value?.increment_percentage,
          };
        }),
      }));
    } else {
      setFormValues((prevFieldValue) => ({
        ...prevFieldValue,
        [name]: value,
      }));
    }
  };

  //to update only event_schedules object field values
  const handleFormUpdateEventSchedules = (
    rest,
    setFieldValue,
    key,
    value,
    index
  ) => {
    const list = [...rest.values.event_schedules];
    if (key === attendees_limit) {
      list[index][key] = parseInt(value);
    } else if (key === "type") {
      if (value === "Regular") {
        list[index][attendees_limit] = rest.values.attendees_limit
          ? parseInt(rest.values.attendees_limit)
          : "";
        list[index]["current_attendees_limit"] = rest.values
          .current_attendees_limit
          ? parseInt(rest.values.current_attendees_limit)
          : "";
        list[index]["increment_percentage"] = rest.values.increment_percentage
          ? parseInt(rest.values.increment_percentage)
          : "";
        list[index]["exceeds_percentage"] = rest.values.exceeds_percentage
          ? parseInt(rest.values.exceeds_percentage)
          : "";
        list[index]["allow_booking_post_commencement"] = rest.values
          .allow_booking_post_commencement
          ? rest.values.allow_booking_post_commencement
          : "";
        list[index]["allow_seats_increment"] = rest.values.allow_seats_increment
          ? rest.values.allow_seats_increment
          : "";

        list[index][key] = value;
      } else {
        list[index][key] = value;
      }
    } else if (key === title || key === "chapters") {
      list[index][key] = {
        ...rest.values.event_schedules[index][key],
        [rest.values.form_language]: value,
      };
    } else {
      list[index][key] = value;
    }
    setFieldValue(event_schedules, list);
    handleFormUpdate(event_schedules, list);
  };

  // handle click to remove event_schedules
  const handleRemoveClickEventSchedules = (rest, setFieldValue, index) => {
    const list = [...rest.values.event_schedules];
    list.splice(index, 1);
    setFieldValue(event_schedules, list);
    files.forEach((file, i) => {
      let slugs = file.file_context.split("_");
      let slug1 = parseInt(slugs[1]);
      let slug0 = slugs[0];
      if (slug1 === index) {
        if (slug0 === fileContext.EVENT_ATTACHMENT) {
          files.splice(i, 1);
        }
        if (slug0 === fileContext.NOTE) {
          files.splice(i, 1);
        }
      }
    });
    files.forEach((file, i) => {
      let slugs = file.file_context.split("_");
      let slug1 = parseInt(slugs[1]);
      let slug0 = slugs[0];
      if (slug1 > index) {
        let finalValue = slug1 - 1;
        files[i].file_context = `${slug0}_${finalValue}`;
      }
    });
    dispatch(updateFiles(files));
  };

  // handle click to Add new event_schedules
  const handleAddClickEventSchedules = (rest, setFieldValue) => {
    const list = [
      ...rest?.values?.event_schedules,
      {
        btn: btn,
        title: initialLanguageFieldValue,
        type: "",
        starts_at: "",
        ends_at: "",
        attendees_limit: formValues?.attendees_limit || "",
        current_attendees_limit: formValues?.current_attendees_limit || "",
        exceeds_percentage: formValues?.event_id?.exceeds_percentage || "",
        increment_percentage: formValues?.event_id?.increment_percentage || "",
        allow_booking_post_commencement: "",
        allow_seats_increment: "",
        chapters: initialLanguageArrayValue,
        requirements: [],
        pre_event_file_cta: "",
        pre_event_file: {},
        note: {},
      },
    ];
    setFieldValue(event_schedules, list);
    handleFormUpdate(event_schedules, list);
  };

  const handleTextfieldChange = (event, data, setFieldValue, name, rest, i) => {
    if (["Enter", "Tab"].includes(event.key)) {
      event.preventDefault();
      var value = event.target.value.trim();

      if (data.includes(value)) {
        setValue("");
        event.target.value = "";
        return;
      }

      if (value) {
        setFieldValue(name, [...data, value]);
        const list = { ...rest.values.requirements };
        list[rest.values.form_language][i][description] = [
          ...list[rest.values.form_language][i][description],
          value,
        ];
        setFieldValue(requirements, list);
        setFormValues((prev) => ({
          ...prev,
          requirements: list,
        }));

        setValue("");
        event.target.value = "";
      }
    } else {
      setValue(event.target.value);
    }
  };

  const handleRemoveDescription = (setFieldValue, value, rest, i) => {
    const list = { ...rest.values.requirements };
    const filteredData = list[rest.values.form_language][i][description].filter(
      (desc) => desc !== value
    );

    list[rest.values.form_language][i][description] = filteredData;
    setFieldValue(requirements, list);
    setFormValues((prev) => ({
      ...prev,
      requirements: list,
    }));
  };

  const handleDescriptionField = (rest, setFieldValue, i, j, value) => {
    const list = { ...rest.values.requirements };
    list[rest.values.form_language][i][description][j] = value;
    setFieldValue(requirements, list);
    setFormValues((prev) => ({
      ...prev,
      requirements: list,
    }));
  };

  const updateRequirement = async (event_id, language) => {
    const { event } = await dispatch(getEvent(event_id?.id));
    if (event) {
      setFormValues((prevFieldValue) => ({
        ...prevFieldValue,
        requirements: {
          ...prevFieldValue.requirements,
          [language]: prevFieldValue?.requirements[language]?.length
            ? prevFieldValue?.requirements[language]
            : event.requirements
            ? event?.requirements
            : [],
        },
      }));
    }
  };

  return {
    fieldValues,
    handleFormUpdateRequirements,
    handleRemoveClickRequirements,
    handleAddClickRequirements,
    handleEventChange,
    btn,
    events,
    users,
    isLoading,
    handleFormUpdate,
    formValues,
    handleFormUpdateEventSchedules,
    handleRemoveClickEventSchedules,
    handleAddClickEventSchedules,
    handleRegularEventChange,
    value,
    handleTextfieldChange,
    handleRemoveDescription,
    setFormValues,
    handleDescriptionField,
    updateRequirement,
  };
};
