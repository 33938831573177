import React from "react";
import styles from "./styles.module.scss";
import ReactPlayer from "react-player";
import { useImageViewer } from "./hooks";
import { fileContext } from "../../../../constants";
import { BackWhiteArrow } from "../../../social/posts/images";
import {
  NextArrowLight,
  PrevArrowLight,
} from "../../../../components/slider-arrow";
import CustomModal from "../../../../components/custom-modal";

const ImageViewer = (props) => {
  const { isOpen, handleIsOpen, current, handleNext, handlePrev, files } =
    useImageViewer(props);

  if (!files || files.length === 0) {
    return null;
  }

  return (
    <>
      <CustomModal
        paperClassName={styles.imageViewerContainer}
        isOpen={isOpen}
        onCloseCallback={handleIsOpen}
        width="80%"
      >
        <img
          src={BackWhiteArrow}
          alt="close"
          className={styles.close_video_modal}
          onClick={handleIsOpen}
        />
        <div className={styles.modal_video_container}>
          {files?.length > 1 && (
            <NextArrowLight
              className={styles.next_slider}
              onClick={handleNext}
            />
          )}
          <div className={styles.slider}>
            {files[current]?.file_context === fileContext.DISCUSSION_VIDEOS ? (
              <div>
                <ReactPlayer
                  className={styles.video_player}
                  url={
                    files[current]?.ts_url ||
                    files[current]?.url ||
                    files[current]?.public_url
                  }
                  controls
                  pip
                  stopOnUnmount={true}
                  width="inherit"
                  height="inheirt"
                  playing={false}
                  config={{
                    youtube: {
                      preload: true,
                    },
                  }}
                  playIcon={
                    <div>
                      <div>
                        <img src={"/img/play-open.svg"} alt="play" />
                      </div>
                    </div>
                  }
                />
              </div>
            ) : (
              <img
                className={styles.image_viewer}
                src={files[current]?.url || files[current]?.public_url}
                alt="img"
              />
            )}
          </div>
          {files?.length > 1 && (
            <PrevArrowLight
              className={styles.prev_slider}
              onClick={handlePrev}
            />
          )}{" "}
        </div>
      </CustomModal>
    </>
  );
};
export default ImageViewer;
