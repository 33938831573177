import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";
import { clearFiles } from "../../utils/upload-file/actions";

const namespace = "containers/categories";

export const SET_INTEREST_LIST = `${namespace}/SET_INTEREST_LIST`;
export const SET_INTEREST_FILE = `${namespace}/SET_INTEREST_FILE`;
export const CLEAR_INTEREST_FILE = `${namespace}/CLEAR_INTEREST_FILE`;
export const SET_CATEGORY_LIST = `${namespace}/SET_CATEGORY_LIST`;
export const SET_INTEREST_FILTER = `${namespace}/SET_INTEREST_FILTER`;

const setInterestList = (payload) => ({
  type: SET_INTEREST_LIST,
  payload,
});

export const setInterestFilter = (payload) => ({
  type: SET_INTEREST_FILTER,
  payload,
});

// const setInterestFile = (payload) => ({
//   type: SET_INTEREST_FILE,
//   payload,
// });

export const clearInterestFile = () => ({
  type: CLEAR_INTEREST_FILE,
});

export const setCategoryList = (payload) => ({
  type: SET_CATEGORY_LIST,
  payload,
});

export const getInterestList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getInterests, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setInterestList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getInterest = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getInterest(id));
    const { data: { is_success, interest, category_ids, files } = {} } =
      response;
    if (is_success) {
      return { interest, category_ids, files };
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addInterest = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createInterests, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(clearFiles());
      dispatch(getInterestList());
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Interest not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const editInterest = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateInterests(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(clearFiles());
      dispatch(getInterestList());
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Interest not edited, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteInterest = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteInterests(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(getInterestList());
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getCategoryList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCategories, {
      params: { params },
    });
    const { data: { is_success, categories } = {} } = response;
    if (is_success) {
      dispatch(setCategoryList(categories));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
