import React from "react";
import { Formik, ErrorMessage } from "formik";
import TextField from "@material-ui/core/TextField";

import { useLogin } from "./hooks";
import { otpFormSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";

const OtpForm = (props) => {
  const { handleOtpSubmit, mobileNumber } = useLogin(props);
  const {
    focusField,
    handleFocusField,
    handleBlurField,
  } = useHandleFieldFocus();
  return (
    <Formik
      validateOnMount
      validationSchema={otpFormSchema}
      initialValues={{ mobileNumber: mobileNumber }}
      onSubmit={handleOtpSubmit}
    >
      {({ handleSubmit, ...rest }) => (
        <form
          className="material-form"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div>
            <TextField
              className="material-form__field"
              name="mobileNumber"
              placeholder="Mobile Number"
              label="Mobile Number"
              type="text"
              onChange={handleFormValueChange(rest, "mobileNumber")}
              value={rest.values.mobileNumber}
              disabled
            />
          </div>
          <div>
            <TextField
              className="material-form__field"
              name="otp"
              placeholder="Enter 6 digit OTP"
              label="OTP"
              type="text"
              onChange={handleFormValueChange(rest, "otp")}
              onFocus={() => handleFocusField("otp")}
              onBlur={() => handleBlurField()}
            />
            {focusField === "otp" ? null : (
              <ErrorMessage
                render={(msg) => (
                  <div className="material-form__error">{msg}</div>
                )}
                name="otp"
              />
            )}
          </div>
          <button
            type="submit"
            className="btn btn-success account__btn account__btn--small"
          >
            LOGIN
          </button>
        </form>
      )}
    </Formik>
  );
};

export default OtpForm;
