import React from 'react'
import { Button } from "reactstrap";
import { useAddPage } from './hooks'
import PageForm from "../form/page-form";
import Modals from '../../../../../components/modal';

const AddPage = (props) => {
  const {
    handleAddPage,
    addPageLoading,
    sectionData,
    handleAddClick,
    toggleModal,
    isOpenModal,
  } = useAddPage(props);
  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          handleAddClick();
        }}
      >
        <i className="fa fa-plus"></i> Add Page
</Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PageForm
              handlePageForm={handleAddPage}
              btn="Add"
              isLoading={addPageLoading}
              sectionData={sectionData}
            />
          }
          title="Add Page"
        />
      ) : null}
    </div>
  )
}

export default AddPage
