import React from "react";
import Chip from "@material-ui/core/Chip";
import userLogo from "../../../assets/images/user-icon.png";
import Accordion from "../../../components/Accordion";
import Tooltip from "@material-ui/core/Tooltip";
import { useUser } from "../../../common-hooks/user";
const Review = ({ data, updateReviewStatus }) => {
  const { isAdmin, isModerator } = useUser();

  const accordionTitle = (data, index) => (
    <div
      className="attendees__list user-list user-list"
      key={`${data.id}+${index}`}
    >
      <div className="attendees__contact-avatar">
        <img src={data?.profile_picture?.url || userLogo} alt="profile" />
      </div>
      <div className="attendees__contact-preview">
        <p className="attendees__contact-name">{data?.name}</p>
        <div>
          {Array.from(Array(5).keys()).map((index) => (
            <span
              key={index}
              className={`fa fa-star ${
                data?.review?.rating > index ? "review-star-checked" : null
              }`}
            ></span>
          ))}
        </div>
      </div>

      {(isAdmin || isModerator) && (
        <div>
          <Tooltip
            onClick={(e) => {
              e.stopPropagation();
              updateReviewStatus(data);
            }}
            title={`${
              data?.review?.status === "Published"
                ? "Click to Unpublish"
                : "Click to Publish"
            }`}
            placement="bottom"
          >
            <i
              className={`fa fa-${
                data?.review?.status === "Published" ? "check" : "times"
              }-circle table__icon-${
                data?.review?.status === "Published" ? "active" : "deactive"
              } icon-btn-action`}
            ></i>
          </Tooltip>
        </div>
      )}
    </div>
  );

  return (
    <div className="tabs tabs--justify tabs--bordered-bottom review-schedule-event-container">
      <div className="tabs__wrap">
        <>
          {data.length > 0 ? (
            data.map((data, index) => (
              <div key={`${data.id}+${index}`}>
                <Accordion
                  title={accordionTitle(data, index)}
                  className="with-shadow"
                >
                  <div className="schedule-event__accordion-conatiner">
                    <p className="scheedule-event__review-text">
                      {data?.review?.text}
                    </p>
                    {data?.review?.feedback?.qna?.length > 0 && (
                      <div className="scheedule-event__qna-container">
                        {data?.review?.feedback?.qna?.map((qna, index) => (
                          <div
                            className="qna-sub-container"
                            key={`${qna?.question}+${index}`}
                          >
                            <span className="question">{qna?.question}</span> -
                            <span className="answer">{qna.answer}</span>
                          </div>
                        ))}
                      </div>
                    )}
                    {data?.review?.feedback?.tags?.length > 0 && (
                      <div className="scheedule-event__tags-container">
                        {data?.review?.feedback?.tags?.map((tag, index) => (
                          <div className="tags" key={`${tag}+${index}`}>
                            <Chip label={tag} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Accordion>
              </div>
            ))
          ) : (
            <div className="schedule-event__no-review">No Reviews </div>
          )}
        </>
      </div>
    </div>
  );
};

export default Review;
