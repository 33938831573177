import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";

const namespace = "containers/users";

export const SET_USER_LIST = `${namespace}/SET_USER_LIST`;
export const SET_USER_FILTER = `${namespace}/SET_USER_FILTER`;
export const CLEAR_USER_FILTER = `${namespace}/CLEAR_USER_FILTER`;
export const SET_USER_HISTORY = `${namespace}/SET_USER_HISTORY`;


const setUserList = (payload) => ({
  type: SET_USER_LIST,
  payload,
});

export const setUserFilter = (payload) => ({
  type: SET_USER_FILTER,
  payload,
});

export const clearUserFilter = (payload) => ({
  type: CLEAR_USER_FILTER,
  payload,
});

export const setUserHistory = (payload) => ({
  type: SET_USER_HISTORY,
  payload,
});

export const getUserList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUsers, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setUserList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getReportedUserList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getReportedUsers, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setUserList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUser(id));
    const { data: { is_success, user_details } = {} } = response;
    if (is_success) {
      return user_details;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addUser = (user) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createUsers, user);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const editUser = (user, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateUsers(id), user);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadUsers = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.exportUsers, {
      params: { params },
    });
    const {
      data: { is_success, message },
    } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};
// set user history
export const getUserHistory = (params) => async (dispatch) => {
      dispatch(setUserHistory(params));
      return true;
}