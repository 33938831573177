import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../../../components/modal";
import DeleteConfirmation from "../../../../../components/delete-confirmation";
import { useDeletePage } from "./hooks";

const DeletePage = ({ page }) => {
  const {
    handleDeletePage,
    isOpenModal,
    toggleModal,
    deletePageLoading,
  } = useDeletePage(page);

  return (
    <>
      <Tooltip title="Delete" placement="bottom" className="pull-right">
        <i
          className={`fa fa-trash icon-btn-action text-danger`}
          onClick={toggleModal}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeletePage}
              isLoading={deletePageLoading}
            />
          }
          title="Delete Page"
        />
      ) : null}
    </>
  );
};

export default DeletePage;
