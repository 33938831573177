import React from 'react';
import { SketchPicker } from 'react-color';
import { Popover } from 'reactstrap';
import PropTypes from 'prop-types';
import { useColorPicker } from './hooks';

const SketchColorPickerField = (props) => {
  const {
    color,
    name,
    label,
    handleColorChange,
    handleSelectColor,
    active,
    displayColorPicker
  } = useColorPicker(props);

  return (
    <div className="color-picker">
      <p className="color-picker-title">{label}</p>
      <button
        type="button"
        className={`color-picker__button${active ? ' active' : ''}`}
        onClick={handleSelectColor}
        id={name}
      >
        <p className="color-picker__color">{color}</p>
        <div className="color-picker__color-view" style={{ backgroundColor: color }} />
      </button>
      <Popover
        isOpen={displayColorPicker}
        target={name}
        placement="bottom"
        className="color-picker__popover"
      >
        <SketchPicker
          color={color}
          onChange={handleColorChange}
        />
      </Popover>
    </div >
  );
};

SketchColorPickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default SketchColorPickerField;