import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialLanguageFieldValue } from "../../../../../constants";
import { clearLanguage, getLanguage } from "../../../../../utils/auth";
import { editSection, getSectionData, getSectionList } from "../actions";
import { getSectionFilter } from "../selectors";

export const useAddSection = ({ id }) => {
  const dispatch = useDispatch();
  const [editSectionLoading, setEditSectionLoading] = useState(false);
  const [selectedSection, setSelectedSection] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const filters = useSelector(getSectionFilter);

  const handleEditClick = async () => {
    const selectedLanguage = getLanguage();
    setEditSectionLoading(true);
    const { section } = await dispatch(getSectionData(id));
    if (section) {
      section.title = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: section.title,
      };
      setSelectedSection(section);
      toggleModal();
    }
    setEditSectionLoading(false);
  };

  const handleLanguageData = async (rest, setFormvalues) => {
    const selectedLanguage = getLanguage();
    setEditSectionLoading(true);
    const { section } = await dispatch(getSectionData(id));
    if (section) {
      section.title = {
        ...rest.values.title,
        [selectedLanguage]:
          rest.values.title[selectedLanguage] || section.title || "",
      };
      section.language = selectedLanguage;
      setSelectedSection(section);
      setFormvalues(section);
    }
    setEditSectionLoading(false);
  };

  const handleEditSection = async (values) => {
    try {
      setEditSectionLoading(true);
      const payload = {
        title: values.title,
        type: values.type,
        status: values.status,
        model: values.model,
        criteria: values.criteria,
      };
      const is_success = await dispatch(editSection(payload, id));
      if (is_success) {
        dispatch(getSectionList(filters));
        toggleModal();
      }
      setEditSectionLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editSectionLoading,
    handleEditSection,
    selectedSection,
    toggleModal,
    isOpenModal,
    handleEditClick,
    handleLanguageData,
  };
};
