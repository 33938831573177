import React from "react";
import { Button } from "reactstrap";
import { useAddVoucher } from "./hooks";
import Modals from "../../../components/modal";
import { useUser } from "./../../../common-hooks/user";
import VoucherForm from "../form/form";

const AddVoucher = (props) => {
  const { addVoucherLoading, handleAddVoucher, isOpenModal, toggleModal } =
    useAddVoucher(props);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Add Voucher
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <VoucherForm
              handleVoucherForm={handleAddVoucher}
              btn="Add"
              isLoading={addVoucherLoading}
            />
          }
          title="Add Voucher"
        />
      ) : null}
    </div>
  );
};

export default AddVoucher;
