import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNoticeList,
  editNotice,
  getEventList,
  getNotice,
  getBlogList,
} from "../actions";
import {
  getNoticesFilter,
  getEvents,
  getEventsFilter,
  getBlogs,
  getBlogsFilter,
} from "../selectors";

export const useEditNotice = ({ id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [editNoticeLoading, setEditNoticeLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState([]);
  const filter = useSelector(getNoticesFilter);
  const events = useSelector(getEvents);
  const eventsFilter = useSelector(getEventsFilter);
  const blogFilter = useSelector(getBlogsFilter);
  const blogs = useSelector(getBlogs);

  const handleNotice = async () => {
    const is_success = await dispatch(getEventList(eventsFilter));
    const is_blog_success = await dispatch(getBlogList(blogFilter));

    const notice = await dispatch(getNotice(id));
    setIsEdit(true);
    setSelectedNotice(notice);
    if (is_success && is_blog_success && notice) {
      toggleModal();
    }
  };

  const handleEditNotice = async (values) => {
    const payload = {
      title: values.title,
      description: values.description,
      status: values.status,
      redirect_to: values.redirect_to,
      short_description: values.short_description,
    };

    setEditNoticeLoading(true);
    const is_success = await dispatch(editNotice(payload, id));
    dispatch(getEventList(eventsFilter));
    setEditNoticeLoading(false);
    if (is_success) {
      dispatch(getNoticeList(filter));
      setIsEdit(true);
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    editNoticeLoading,
    handleEditNotice,
    isOpenModal,
    toggleModal,
    handleNotice,
    events,
    selectedNotice,
    isEdit,
    blogs,
  };
};
