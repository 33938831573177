import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import CourseForm from "../form/course-form";
import { useEditCourse } from "./hooks";
import { useRouteMatch } from "react-router-dom";
import { Loader } from "../../../components/loader";
import { useUser } from "../../../common-hooks/user";
const EditCourse = () => {
  const match = useRouteMatch("/edit-course/:course_id");
  const {
    handleEditCourse,
    editCourseLoading,
    users,
    courses,
    events,
    selectedCourse,
    handleLanguageData,
  } = useEditCourse({ match });

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      {editCourseLoading ? (
        <Loader />
      ) : (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title card__title__flex">
                  <h3 className="bold-text">EDIT COURSE</h3>
                </div>
              </div>
              {selectedCourse && (
                <CourseForm
                  fieldValues={selectedCourse}
                  handleCourseForm={handleEditCourse}
                  btn="Save"
                  users={users}
                  courses={courses}
                  events={events}
                  isLoading={editCourseLoading}
                  handleLanguageData={handleLanguageData}
                  isEdit
                />
              )}
            </CardBody>
          </Card>
        </Col>
      )}
    </div>
  );
};

export default EditCourse;
