export const columnsOffers = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "User Name",
    accessor: "user.name",
  },
  {
    Header: "User ID",
    accessor: "user.id",
    width: 50,
  },
  {
    Header: "Subscription Plan",
    accessor: "plan.title",
  },
  {
    Header: "Subscription Purchase Date",
    accessor: "subscribed_at",
    width: 185,
  },
  {
    Header: "Subscription Duration",
    accessor: "duration",
  },
  {
    Header: "Coupon Code",
    accessor: "code",
  },
  {
    Header: "Partner Name",
    accessor: "partner.name",
  },
  {
    Header: "Offer Text",
    accessor: "offer.offer_text",
  },
  {
    Header: "Partner ID",
    accessor: "partner.id",
    width: 50,
  },

  {
    Header: "Offer ID",
    accessor: "offer.id",
    width: 50,
  },
  {
    Header: "Viewed At",
    accessor: "viewed_at",
    width: 185,
  },

  {
    Header: "Copied At",
    accessor: "copied_at",
    width: 185,
  },

  {
    Header: "Redeemed At",
    accessor: "redeemed_at",
    width: 185,
  },
];
