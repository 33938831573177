import React from "react";
import { Button } from "reactstrap";
import { useAddOffer } from "./hooks";
import Modals from "../../../components/modal";
import OfferForm from "../form/offer-form";
import { useUser } from "../../../common-hooks/user";

const AddOffer = (props) => {
  const {
    addOfferLoading,
    handleAddOffer,
    isOpenModal,
    toggleModal,
    setPartnerFilters,
  } = useAddOffer(props);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }
  
  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Add Offer
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <OfferForm
              handleOfferForm={handleAddOffer}
              btn="Add"
              isLoading={addOfferLoading}
              setPartnerFilters={setPartnerFilters}
            />
          }
          title="Add Offer"
        />
      ) : null}
    </div>
  );
};

export default AddOffer;
