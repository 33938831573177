import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeNoticeStatus, getNoticeList } from "../actions";
import { getNoticesFilter } from "../selectors";

export const useChangeNoticeStatus = ({ id }) => {
  const dispatch = useDispatch();
  const [changeNoticeLoading, setchangeNoticeLoading] = useState(false);
  const filter = useSelector(getNoticesFilter);

  const handlechangeNotice = async (status) => {
    setchangeNoticeLoading(true);
    const payload = {
      status,
    };
    const is_success = await dispatch(changeNoticeStatus(id, payload));

    if (is_success) {
      setchangeNoticeLoading(false);
      dispatch(getNoticeList(filter));
    }
  };

  return {
    changeNoticeLoading,
    handlechangeNotice,
  };
};
