import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLanguage } from "../../utils/auth";
import { getFaqsList, setFaqFilter } from "./actions";
import { getFaqs, getFaqsCount, getFaqsFilter } from "./selectors";

export const useFaq = () => {
  const dispatch = useDispatch();
  const faq = useSelector(getFaqs);
  const filters = useSelector(getFaqsFilter);
  const faqCount = useSelector(getFaqsCount);

  const [loadingFaq, setLoadingFaq] = useState(false);

  useEffect(() => {
    clearLanguage();
    const fetchFaq = async () => {
      setLoadingFaq(true);
      await dispatch(getFaqsList(filters));
      setLoadingFaq(false);
    };
    fetchFaq();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setFaqFilter({ key, value }));
  };

  return {
    faq,
    loadingFaq,
    setFilter,
    filters,
    faqCount,
  };
};
