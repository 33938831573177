import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { useUser } from "../../../../common-hooks/user";
import Modals from "../../../../components/modal";
import { clearFiles } from "../../../../utils/upload-file/actions";
import PostForm from "../form/form";
import { useAddPost } from "./hooks";

const AddPost = (props) => {
  const { isOpenModal, toggleModal, addPostLoading, handleAddPost } =
    useAddPost(props);

  const dispatch = useDispatch();
  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          dispatch(clearFiles());
          toggleModal(!isOpenModal);
        }}
      >
        <i className="fa fa-plus"></i> Add Post
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PostForm
              isLoading={addPostLoading}
              handlePostForm={handleAddPost}
              btn="Add"
            />
          }
          title="Add Post"
        />
      ) : null}
    </div>
  );
};

export default AddPost;
