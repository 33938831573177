import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTestimonilaFilter, getTestimonialList } from "./actions";
import {
  getTestimonialsFilter,
  getTestimonials,
  getTestimonialsCount,
} from "./selectors";

export const useTestimonial = () => {
  const dispatch = useDispatch();
  const testimonials = useSelector(getTestimonials);
  const filters = useSelector(getTestimonialsFilter);
  const testimonialsCount = useSelector(getTestimonialsCount);

  const [loadingTestimonial, setLoadingTestimonial] = useState(false);

  useEffect(() => {
    const fetchTestimonials = async () => {
      setLoadingTestimonial(true);
      await dispatch(getTestimonialList(filters));
      setLoadingTestimonial(false);
    };
    fetchTestimonials();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setTestimonilaFilter({ key, value }));
  };

  return {
    testimonials,
    loadingTestimonial,
    setFilter,
    filters,
    testimonialsCount,
  };
};
