import { useState } from "react";

export const useDeleteComment = ({
  handleDelete,
  comment,
  handleDeleteReplySuccess,
  totalRepliesCount = 0,
  isComment,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteCommentLoading, setDeleteCommentLoading] = useState(false);

  const handleDeleteComment = async () => {
    setDeleteCommentLoading(true);
    const is_success = handleDelete(
      comment?.id,
      isComment ? totalRepliesCount : 0
    );
    setDeleteCommentLoading(false);
    if (is_success) {
      toggleModal();
      !isComment && handleDeleteReplySuccess && handleDeleteReplySuccess();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteCommentLoading,
    handleDeleteComment,
    isOpenModal,
    toggleModal,
    comment,
  };
};
