export const BEARER_TOKEN = "BEARER_TOKEN";
export const ADMIN = "Admin";
export const AUTH_HEADER_NAME = "Authorization";
export const LANGUAGE = "accept-language";
export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const fileContext = {
  BANNER: "Banner",
  ICON: "Icon",
  POPULAR_BANNER: "Popular Banner",
  POPULAR_BANNER_WEB: "Popular Banner - Web",
  PROFILE_PIC: "Profile Picture",
  PROFILE_BANNER: "Profile Banner",
  BANNER_TILE: "Banner - Tile",
  NOTE: "Note",
  VIDEO: "Video",
  VIDEO_ICON: "Video Icon",
  VIDEO_BANNER: "Video Banner",
  HOME_PAGE_BANNER: "Home Page Banner",
  EVENT_ATTACHMENT: "Pre-Event",
  LOGO: "Logo",
  MOBILE_BANNER: "Mobile Banner",
  DESKTOP_BANNER: "Desktop Banner",
  PROFILE_VIDEO: "Profile Video",
  POST_IMAGE: "Image",
  POST_VIDEO: "Video",
  DISCUSSION_IMAGES: "Image",
  DISCUSSION_VIDEOS: "Video",
};

export const snackbarTypes = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export const userRoles = {
  ADMIN: "Admin",
  CO_ORDINATOR: "Coordinator",
  MEMBER: "Member",
  MODERATOR: "Moderator",
  CS_SPECIALIST: "Customer Support Specialist",
};

export const languages = [
  {
    id: 1,
    value: "en",
    displayText: "English",
  },
  {
    id: 2,
    value: "hi",
    displayText: "Hindi",
  },
  {
    id: 3,
    value: "mr",
    displayText: "Marathi",
  },
];

export const initialLanguageFieldValue = {
  en: "",
};

export const initialLanguageArrayValue = {
  en: [],
};

export const imageDimensions = {
  event_banner: {
    width: 719,
    height: 412,
  },
  popular_event_web: {
    width: 324,
    height: 378,
  },
  popular_event_app: {
    width: 324,
    height: 378,
  },
  event_video_banner: {
    width: 719,
    height: 412,
  },
  blog_banner: {
    width: 1024,
    height: 512,
  },
  blog_video_banner: {
    width: 375,
    height: 300,
  },
  coordinator_banner: {
    width: 408,
    height: 249,
  },
  cms_web_banner: { width: 1440, height: 281 },
  cms_app_banner: { width: 646, height: 352 },
};

export const CONFIG_KEYS = {
  ageLimit: "AGE LIMIT",
  reviewCountLimit: "REVIEWS COUNT LIMIT",
  referralBonusSessions: "REFERRAL BONUS SESSIONS",
  reviewsListLimit: "REVIEWS LIST LIMIT",
  freePlanSessions: "FREE PLAN SESSIONS",
  postImageMaxSize: "POST IMAGE MAX SIZE",
  postVideoMaxSize: "POST VIDEO MAX SIZE",
  maxPostVideos: "MAX POST VIDEOS",
  maxPostImages: "MAX POST IMAGES",
  maxVideoLimit: "ADMIN VIDEO LENGTH LIMIT",
};

export const supportedPostImageFormat = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/gif",
];
export const supportedPostVideoFormat = ["video/mp4", "video/webm"];
export const trackableTypes = {
  DISCUSSION: "Discussion",
  DISCUSSION_REACTION: "DISCUSSION",
  POST: "Post",
  EVENTSCHEDULE: "EventSchedule",
  COMMENT: "COMMENT",
  STORY: "STORY",
  BLOG: "Blog",
};

export const TABLE_CAPTION_CHAR_LENGTH = 300;
