import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCodesList, setCodesFilter } from "../actions";
import { getCodes, getCodesCount, getCodesFilter } from "../selectors";

export const useCodes = () => {
  const dispatch = useDispatch();
  const [codesLoading, setCodesLoading] = useState(false);
  const filters = useSelector(getCodesFilter);
  const codes = useSelector(getCodes);
  const count = useSelector(getCodesCount);

  const { id } = useParams();
  useEffect(() => {
    (async () => {
      try {
        setCodesLoading(true);
        const codes = await dispatch(getCodesList(id, filters));
        if (codes) {
          setCodesLoading(false);
        }
      } catch (error) {
        setCodesLoading(false);
      } finally {
        setCodesLoading(false);
      }
    })();
  }, [id, filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setCodesFilter({ key, value }));
  };

  return {
    codesLoading,
    codes,
    count,
    filters,
    setFilter,
  };
};
