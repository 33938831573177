import React, { useCallback, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, Chip, MenuItem, Tooltip } from "@material-ui/core";
import { fieldNames, isPopularOptions, statusOptions } from "../constant";
import { DataLoader } from "../../../../../components/loader";
import TextfieldChips from "../../../../../components/text-field-chips";
import { blogFormSchema } from "./validation-schema";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextEditor from "../../../../../components/text-editor";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { useUploadFiles } from "../../../../../utils/upload-file/hooks";
import {
  fileContext,
  languages,
  initialLanguageFieldValue,
  imageDimensions,
} from "../../../../../constants";
import DatePicker from "../../../../../components/date-picker";
import ProgressChip from "../../../../../components/progress-chip";
import { setLanguage } from "../../../../../utils/auth";
import Croppier from "../../../../../components/image-croppier";
import _ from "lodash";
import { setUserFilter } from "../actions";
import { useDispatch } from "react-redux";

const formField = {
  title: initialLanguageFieldValue,
  description: initialLanguageFieldValue,
  time_to_read: null,
  category_id: "",
  tags: [],
  status: "",
  author_id: "",
  interestIds: [],
  label_id: "",
  meta_title: "",
  meta_description: "",
  meta_keywords: "",
  published_at: "",
  next_blog_id: "",
  language: "en",
  slug: "",
};

const BlogForm = ({
  fieldValues,
  handleBlogForm,
  btn,
  isLoading,
  categories,
  interests,
  users,
  blogs,
  labels,
  handleLangugeData,
  setSearchText,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    uploadError,
    fileBannerError,
    fileBannerTileError,
    handleDeleteAFile,
    files,
    handleBannerFile,
    handleBannerTileFile,
    uploadProgress,
    isFileUploadData,
    handleCropping,
  } = useUploadFiles();

  const {
    title,
    description,
    category_id,
    interestIds,
    time_to_read,
    label_id,
    is_popular,
    status,
    author_id,
    published_at,
    tags,
    fileBanner,
    fileBannerTile,
    next_blog_id,
    meta_title,
    meta_description,
    meta_keywords,
    language,
    slug,
  } = fieldNames;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const [blogBanner, setBlogBanner] = useState([]);
  const { blog_banner } = imageDimensions;
  const dispatch = useDispatch();

  const handleFormUpdate = async (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));
  };

  const handleLanguge = (key, language, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: {
        ...prevFieldValue[key],
        [language]: value,
      },
    }));
  };

  const setUsersFilter = useCallback(
    _.debounce((key, value) => {
      dispatch(setUserFilter({ key, value }));
    }, 600),
    []
  );

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={blogFormSchema}
      initialValues={{
        title: formValues?.title || initialLanguageFieldValue,
        description: formValues?.description || initialLanguageFieldValue,
        language: formValues?.language || "en",
        category_id:
          (formValues?.category_id &&
            categories.find(
              (category) => category.id === formValues?.category_id
            )) ||
          (formValues?.category_id?.id &&
            categories.find(
              (category) => category.id === formValues?.category_id?.id
            )) ||
          null,
        author_id:
          (formValues?.author_id &&
            users.find((user) => user.id === formValues?.author_id)) ||
          (formValues?.author_id?.id &&
            users.find((user) => user.id === formValues?.author_id?.id)) ||
          null,
        tags: formValues?.tags || [],
        label_id:
          (formValues?.label_id &&
            labels.find((label) => label.id === formValues?.label_id.id)) ||
          (formValues?.label_id &&
            labels.find((label) => label.id === formValues?.label_id)) ||
          null,
        interestIds:
          (formValues?.interestIds?.length > 0 &&
            interests.filter((interest) =>
              formValues.interestIds.find(
                (id) => typeof id == "object" && id.id === interest.id
              )
            )) ||
          (formValues?.interest_ids?.length > 0 &&
            interests.filter((interest) =>
              formValues.interest_ids.find(
                (id) => typeof id == "number" && id === interest.id
              )
            )) ||
          [],
        published_at:
          (formValues?.published_at && new Date(formValues.published_at)) || "",
        is_popular: formValues?.is_popular ? true : false,
        time_to_read: formValues?.time_to_read || "",
        status: formValues?.status || "",
        fileBanner: files.filter(
          (file) => file.file_context === fileContext.BANNER
        ),
        fileBannerTile: files.filter(
          (file) => file.file_context === fileContext.BANNER_TILE
        ),
        next_blog_id:
          (formValues?.next_blog_id &&
            blogs.find((blog) => blog.id === formValues?.next_blog_id)) ||
          (formValues?.next_blog_id?.id &&
            blogs.find((blog) => blog.id === formValues?.next_blog_id?.id)) ||
          null,
        meta_title: formValues?.meta_title || "",
        meta_description: formValues?.meta_description || "",
        meta_keywords: formValues?.meta_keywords || "",
        slug: formValues?.slug || "",
      }}
      onSubmit={handleBlogForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            {!isLoading && (
              <div className="materila-form_inline-child sticky__element">
                <TextField
                  className="material-form__field"
                  name={language}
                  placeholder="Language"
                  label="Language"
                  select
                  value={rest.values.language}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    if (!rest.values.title[value]) {
                      handleLanguge(title, value, "");
                    }

                    if (!rest.values.description[value]) {
                      handleLanguge(description, value, "");
                    }
                    handleFormValueChange(rest, language);
                    handleFormUpdate(name, value);
                    // setFieldValue(name, value);
                    if (btn === "Save") {
                      setLanguage(value);
                      handleLangugeData(rest, setFormvalues);
                    }
                  }}
                  onFocus={() => handleFocusField(language)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.language}
                >
                  {languages.map((language) => (
                    <MenuItem
                      key={language.id}
                      className="material-form__option"
                      value={language.value}
                    >
                      {language.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === language ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={language}
                  />
                )}
              </div>
            )}
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  type="text"
                  value={rest.values.title[rest.values.language]}
                  onChange={(event) => {
                    handleFormValueChange(rest, title);
                    handleLanguge(
                      "title",
                      rest.values.language,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>
              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="blogs__container">
              <div className="material-form_inline-fields d-flex align-items-center">
                <div className="materila-form_inline-child">
                  <p className="custom__title_blogs">Description</p>
                </div>
                <div className="col-md-2 form__language">
                  <Tooltip title="Language" placement="bottom">
                    <div>
                      {
                        languages.find(
                          (lang) => lang.value === rest.values.language
                        ).displayText
                      }
                    </div>
                  </Tooltip>
                </div>
              </div>

              <TextEditor
                initialValues={rest.values.description[rest.values.language]}
                onChange={(value) => {
                  let div = document.createElement("div");
                  div.innerHTML = value;
                  let text = div.textContent || div.innerText || "";
                  if (text.length === 1) {
                    handleLanguge("description", rest.values.language, "");
                  } else {
                    handleLanguge("description", rest.values.language, value);
                  }
                }}
                language={rest.values.language}
              />
              {focusField === description ? null : (
                <>
                  <br />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={description}
                  />
                </>
              )}
            </div>

            <div className="matrial-form material-form_inline-fields">
              <div className="materila-form_inline-child">
                <Autocomplete
                  options={users}
                  getOptionLabel={(user) => (user.name ? user.name : "")}
                  value={rest.values.author_id}
                  name={author_id}
                  onChange={(event, value) => {
                    setFieldValue(author_id, value);
                    handleFormUpdate(author_id, value);
                    !value && setUsersFilter("text", "");
                  }}
                  onFocus={() => handleFocusField(author_id)}
                  onBlur={() => {
                    handleBlurField();
                    !rest.values.author_id && setUsersFilter("text", "");
                    //rest.handleBlur();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      onChange={(e) => setUsersFilter("text", e.target.value)}
                      name={author_id}
                      placeholder="Select Author"
                      label="Select Author"
                      variant="standard"
                    />
                  )}
                />
                {focusField === author_id ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={author_id}
                  />
                )}
              </div>

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={time_to_read}
                  placeholder="Ex.5"
                  label="Time to Read (In Minutes)"
                  type="number"
                  value={rest.values.time_to_read}
                  onChange={(event) => {
                    handleFormValueChange(rest, time_to_read);
                    handleFormUpdate(time_to_read, event.target.value);
                  }}
                  onFocus={() => handleFocusField(time_to_read)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === time_to_read ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={time_to_read}
                  />
                )}
              </div>

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={status}
                  placeholder="Status"
                  label="Select Status"
                  select
                  value={rest.values.status}
                  onChange={(event) => {
                    handleFormValueChange(rest, status);
                    handleFormUpdate(status, event.target.value);
                  }}
                  onFocus={() => handleFocusField(status)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.status}
                >
                  {statusOptions.map((status) => (
                    <MenuItem
                      key={status.id}
                      className="material-form__option"
                      value={status.value}
                    >
                      {status.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === status ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={status}
                  />
                )}
              </div>
            </div>

            <div className="matrial-form material-form_inline-fields">
              <div className="materila-form_inline-child">
                <Autocomplete
                  options={categories}
                  getOptionLabel={(category) =>
                    category.title ? category.title : ""
                  }
                  value={rest.values.category_id}
                  name={category_id}
                  onChange={(event, value) => {
                    setFieldValue(category_id, value);
                    handleFormUpdate(category_id, value);
                  }}
                  onFocus={() => handleFocusField(category_id)}
                  onBlur={() => handleBlurField()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      name={category_id}
                      placeholder="Select Category"
                      label="Select Category"
                      variant="standard"
                    />
                  )}
                />
                {focusField === category_id ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={category_id}
                  />
                )}
              </div>

              <div className="materila-form_inline-child">
                <Autocomplete
                  options={labels}
                  getOptionLabel={(label) => (label.title ? label.title : "")}
                  value={rest.values.label_id}
                  name={label_id}
                  onChange={(event, value) => {
                    setFieldValue(label_id, value);
                    handleFormUpdate(label_id, value);
                  }}
                  onFocus={() => handleFocusField(label_id)}
                  onBlur={() => handleBlurField()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      name={label_id}
                      placeholder="Select Label"
                      label="Select Label"
                      variant="standard"
                    />
                  )}
                />
                {focusField === label_id ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={label_id}
                  />
                )}
              </div>

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={status}
                  placeholder="Select Is Popular"
                  label="Select Is Popular"
                  select
                  value={rest.values.is_popular}
                  onChange={(event) => {
                    handleFormValueChange(rest, is_popular);
                    handleFormUpdate(is_popular, event.target.value);
                  }}
                  onFocus={() => handleFocusField(is_popular)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.is_popular}
                >
                  {isPopularOptions.map((status) => (
                    <MenuItem
                      key={status.id}
                      className="material-form__option"
                      value={status.value}
                    >
                      {status.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === is_popular ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={is_popular}
                  />
                )}
              </div>
            </div>

            <div className="matrial-form material-form_inline-fields">
              <div className="materila-form_inline-child">
                <DatePicker
                  selectedDate={rest.values.published_at}
                  handleChange={(value) => {
                    setFieldValue(published_at, value);
                    handleFormUpdate(published_at, value);
                  }}
                  id="published_at"
                  name={published_at}
                  label="Published At"
                  customInput={
                    <TextField
                      className="material-form__field"
                      id="published_at"
                      label="Published At"
                      autoComplete="off"
                    />
                  }
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={published_at}
                />
              </div>
              <div className="materila-form_inline-child"></div>
              <div className="materila-form_inline-child"></div>
            </div>

            <div>
              <Autocomplete
                multiple
                options={interests}
                getOptionLabel={(interest) =>
                  interest.title ? interest.title : ""
                }
                value={rest.values.interestIds}
                name={interestIds}
                onChange={(event, value) => {
                  setFieldValue(interestIds, value);
                  handleFormUpdate(interestIds, value);
                }}
                onFocus={() => handleFocusField(interestIds)}
                onBlur={() => handleBlurField()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={interestIds}
                    placeholder="Select Interests"
                    label="Select Interests"
                    variant="standard"
                  />
                )}
              />
              {focusField === interestIds ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={interestIds}
                />
              )}
            </div>

            <div>
              <TextfieldChips
                initialChips={rest.values.tags}
                onDataChange={(data) => {
                  setFieldValue(tags, data);
                  handleFormUpdate(tags, data);
                }}
              />
              {focusField === tags ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={tags}
                />
              )}
            </div>

            <div className="matrial-form material-form_inline-fields">
              <div className="materila-form_inline-child">
                <Autocomplete
                  options={blogs}
                  getOptionLabel={(blog) => (blog.title ? blog.title : "")}
                  getOptionSelected={(blog) => (blog.title ? blog.title : "")}
                  value={rest.values.next_blog_id}
                  name={next_blog_id}
                  onChange={(event, value) => {
                    setFieldValue(next_blog_id, value);
                    handleFormUpdate(next_blog_id, value);
                    !value && setSearchText("title", "");
                  }}
                  onFocus={() => handleFocusField(next_blog_id)}
                  onBlur={() => handleBlurField()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      onChange={(e) => setSearchText("title", e.target.value)}
                      name={next_blog_id}
                      placeholder="Select Next Blog"
                      label="Select Next Blog"
                      variant="standard"
                    />
                  )}
                />
                {focusField === next_blog_id ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={next_blog_id}
                  />
                )}
              </div>
            </div>

            <div className="blogs__container">
              <p className="custom__title_blogs">Banner</p>
              <div className="form__form-group-field form__margin-bottom">
                <div className="form__form-group-input-wrap">
                  <div className="form__form-group-file">
                    <label htmlFor="fileBanner">Choose Banner</label>
                    <Tooltip
                      title="Preferred size is 1024px * 512px"
                      placement="bottom"
                    >
                      <i className="fa fa-info-circle icon-file-info mt-1"></i>
                    </Tooltip>
                    <br />
                    {files
                      .filter(
                        (file) => file.file_context === fileContext.BANNER
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}
                    {isFileUploadData
                      .filter((file) => file.fileContext === fileContext.BANNER)
                      .map((x, i) => {
                        return (
                          <>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </>
                        );
                      })}
                    <input
                      accept="image/*"
                      multiple
                      type="file"
                      name={fileBanner}
                      id="fileBanner"
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      onChange={(event) => {
                        handleCropping(
                          event.currentTarget.files,
                          setFieldValue,
                          fileBanner,
                          fileContext.BANNER,
                          handleBannerFile,
                          blog_banner,
                          setBlogBanner
                        );
                        // handleBannerFile(event.currentTarget.files);
                        // setFieldValue(
                        //   fileBanner,
                        //   files.filter(
                        //     (file) => file.file_context === fileContext.BANNER
                        //   )
                        // );
                      }}
                    />
                  </div>

                  {fileBannerError || uploadError ? (
                    <div className="material-form-error-image">
                      {fileBannerError || uploadError}
                    </div>
                  ) : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form-error-image">{msg}</div>
                      )}
                      name={fileBanner}
                    />
                  )}
                </div>
              </div>

              {blogBanner?.length > 0 &&
                blogBanner?.map((banner, i) => (
                  <div key={i}>
                    <Croppier
                      image={banner}
                      setFieldValue={setFieldValue}
                      name={fileBanner}
                      context={fileContext.BANNER}
                      dimensions={blog_banner}
                      handleUploadFile={handleBannerFile}
                      setCurrentBanner={setBlogBanner}
                      index={i}
                    />
                  </div>
                ))}

              <div className="form__form-group-field form__margin-bottom">
                <div className="form__form-group-input-wrap">
                  <div className="form__form-group-file">
                    <label htmlFor="fileBannerTile">Choose Banner Tile</label>
                    {files
                      .filter(
                        (file) => file.file_context === fileContext.BANNER_TILE
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}
                    {isFileUploadData
                      .filter(
                        (file) => file.fileContext === fileContext.BANNER_TILE
                      )
                      .map((x, i) => {
                        return (
                          <>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </>
                        );
                      })}
                    <input
                      accept="image/*"
                      multiple
                      type="file"
                      name={fileBannerTile}
                      id="fileBannerTile"
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      onChange={(event) => {
                        handleBannerTileFile(event.currentTarget.files);
                        setFieldValue(
                          fileBannerTile,
                          files.filter(
                            (file) =>
                              file.file_context === fileContext.BANNER_TILE
                          )
                        );
                      }}
                    />
                  </div>
                  {rest.errors.fileBannerTile ? (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form-error-image">{msg}</div>
                      )}
                      name={fileBannerTile}
                    />
                  ) : fileBannerTileError || uploadError ? (
                    <div className="material-form-error-image">
                      {fileBannerTileError || uploadError}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={slug}
                placeholder="Slug"
                label="Slug"
                type="text"
                value={rest.values.slug}
                onChange={(event) => {
                  handleFormValueChange(rest, slug);
                  handleFormUpdate(slug, event.target.value);
                }}
                onFocus={() => handleFocusField(slug)}
                onBlur={() => handleBlurField()}
              />
              {focusField === slug ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={meta_title}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={meta_title}
                placeholder="Meta Title"
                label="Meta Title"
                type="text"
                value={rest.values.meta_title}
                onChange={(event) => {
                  handleFormValueChange(rest, meta_title);
                  handleFormUpdate(meta_title, event.target.value);
                }}
                onFocus={() => handleFocusField(meta_title)}
                onBlur={() => handleBlurField()}
              />
              {focusField === title ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={meta_title}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={meta_description}
                placeholder="Meta Description"
                label="Meta Description"
                type="text"
                multiline
                value={rest.values.meta_description}
                onChange={(event) => {
                  handleFormValueChange(rest, meta_description);
                  handleFormUpdate(meta_description, event.target.value);
                }}
                onFocus={() => handleFocusField(meta_description)}
                onBlur={() => handleBlurField()}
              />
              {focusField === meta_description ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={meta_description}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={meta_keywords}
                placeholder="Ex. Trending,Popular,etc."
                label="Meta Keywords"
                type="text"
                value={rest.values.meta_keywords}
                onChange={(event) => {
                  handleFormValueChange(rest, meta_keywords);
                  handleFormUpdate(meta_keywords, event.target.value);
                }}
                onFocus={() => handleFocusField(meta_keywords)}
                onBlur={() => handleBlurField()}
              />
              {focusField === meta_keywords ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={meta_keywords}
                />
              )}
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default BlogForm;
