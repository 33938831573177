/* eslint-disable */

import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUser } from "../../../../../common-hooks/user";
import { fileContext, trackableTypes } from "../../../../../constants";
import { setFiles } from "../../../../../utils/upload-file/actions";
import { useUploadFiles } from "../../../../../utils/upload-file/hooks";
import { getAddedReactionList, getRemovedReactionList } from "../../../utils";
import {
  getCommentList,
  addComment,
  deleteComment,
  editComment,
  deleteAComment,
} from "../../action";
import { MENTION_TYPES, statuses } from "../../constant";

const RECORD_LIMIT = 4;

export const useComment = (props) => {
  const { trackableType } = props;
  const [comment, setComment] = useState("");
  const [canViewComments, setCanViewComments] = useState(false);
  const [canAddComment, setCanAddComment] = useState(false);
  const inputFile = useRef(null);
  const commentRef = useRef();
  const [clearInput, setClearInput] = useState();

  const { user, profile_picture } = useSelector(
    (state) => state?.authReducer?.loggedInUser
  );
  const [postBy, setPostBy] = useState("Me");
  const { isAdmin } = useUser();

  const dispatch = useDispatch();

  const [taggedMentions, setTaggedMentions] = useState([]);
  const [plainTextValue, setPlainTextValue] = useState("");

  const [commentsData, setCommentsData] = useState([]);

  const [commentsParams, setCommentsParams] = useState({});

  const {
    files,
    isFileUploadData,
    handleDiscussionFiles,
    handleDeleteAFile,
    clearAllFiles,
  } = useUploadFiles();

  const { PUBLISHED } = statuses;

  const getComments = async () => {
    let params = { ...commentsParams };
    if (props.viewComment) {
      params = {
        trackable_type: [props.trackableType],
        trackable_id: props.trackableId,
        parent_comment_id: null,
        limit: 4,
        skip: commentsData?.comments?.length || 0,
        replies: { limit: 2, skip: 0, order: ["created_at DESC"] },
      };

      setCommentsParams(params);

      const comments = await dispatch(getCommentList(params));
      setCommentsData((prev) => {
        if (prev?.comments?.length) {
          return {
            ...prev,
            count: comments?.count,
            comments: [...prev?.comments, ...comments?.comments].filter(
              (v, i, a) => a.findIndex((t) => t.id === v.id) === i
            ),
          };
        }
        return comments;
      });
    }
  };

  const handlePostBy = (val) => {
    setPostBy(val);
  };

  useEffect(() => {
    getComments();
    return () => {
      setCommentsData([]);
    };
  }, [props.viewComment]);

  useEffect(() => {
    setCanViewComments(props.viewComment);
  }, [props.viewComment]);

  useEffect(() => {
    clearAllFiles();
    setCanAddComment(props.addComment);
  }, [props.addComment]);

  const handleCommentParams = () => {
    setCommentsParams((prev) => ({
      ...prev,
      skip: prev?.skip + RECORD_LIMIT,
    }));
    getComments();
  };

  const { comments = [], totalComments = 0 } = useMemo(() => {
    if (!commentsData || commentsData?.comments?.length === 0) {
      return { comments: [], totalComments: 0 };
    }
    let totalRecords = commentsData?.count;
    let commentList = commentsData?.comments;

    return { comments: commentList, totalComments: totalRecords };
  }, [commentsData]);

  const userProfileImg = useMemo(() => {
    return profile_picture?.url ? profile_picture?.url : "";
  }, [profile_picture.url]);

  const handleCommentChange = (mentions, newPlainTextValue) => {
    setTaggedMentions(mentions);
    setPlainTextValue(newPlainTextValue);
  };

  const handleSubmitComment = async () => {
    const payload = {
      trackable_type: props.trackableType,
      trackable_id: props.trackableId,
      text: plainTextValue,
      ranges: taggedMentions.map((mention) => ({
        trackable_type:
          mention?.type === "#"
            ? MENTION_TYPES.hashtag
            : mention?.type === "@"
            ? MENTION_TYPES.user
            : "",
        trackable_id: mention.id,
        name: mention?.name?.slice(1, mention?.name?.length),
        offset: mention.offset,
        length: mention.length,
      })),
      on_behalf_of: postBy === "EvergreenClub" ? "EvergreenClub" : "",
      attachments: [],
    };

    const commentSuccess = await dispatch(addComment(payload));

    if (commentSuccess?.is_success) {
      setComment("");
      setPlainTextValue("");
      setTaggedMentions([]);
      setClearInput(true);
      let commenter;
      if (postBy === "EvergreenClub") {
        commenter = {
          name: "Evergreen Club",
          id: parseInt(process.env.REACT_APP_EGC_ID),
          profile_picture: {
            url: "/img/club-logo.png",
          },
        };
      } else {
        commenter = { ...user, profile_picture };
      }
      setCommentsData((prev) => {
        if (prev?.comments) {
          return {
            ...prev,
            count: prev?.count + 1,
            comments: [
              {
                ...commentSuccess?.comment,
                commenter,
                attachments: files,
                myReaction: {},
                replies: [],
              },
              ...prev?.comments,
            ],
          };
        }
      });
    }
    clearAllFiles();
    if (props?.handleTotalComments) {
      return props?.handleTotalComments("add");
    }
  };

  const handleDeleteComment = async (id, payload) => {
    let payloadCopy = {};
    if (payload.status === PUBLISHED) {
      payloadCopy.status = PUBLISHED;
    } else {
      payloadCopy = { ...payload };
    }

    const is_success = await dispatch(deleteComment(id, payloadCopy));
    if (is_success && payload.status === PUBLISHED) {
      setCommentsData((prev) => {
        prev.comments?.forEach((comment, i) => {
          if (comment?.id === id) {
            prev?.comments?.splice(i, 1, {
              ...comment,
              status: payload.status,
            });
          }
        });

        return {
          ...prev,
          comments: prev?.comments,
        };
      });
    } else if (is_success) {
      setCommentsData((prev) => {
        prev.comments?.forEach((comment, i) => {
          if (comment?.id === id) {
            prev?.comments?.splice(i, 1, {
              ...comment,
              status: payload.status,
            });
          }
        });

        return {
          ...prev,
          comments: prev?.comments,
        };
      });
    }

    return is_success;
  };

  const updateComment = async (id, payload) => {
    const { is_success } = await dispatch(
      editComment(id, {
        text: payload?.text,
        ranges: payload?.ranges,
        attachments: payload?.attachments,
        on_behalf_of: payload?.on_behalf_of,
      })
    );

    if (is_success) {
      setCommentsData((prev) => {
        prev.comments?.forEach((comment, i) => {
          if (comment?.id === id) {
            prev?.comments?.splice(i, 1, {
              ...payload,
            });
          }
        });

        return {
          ...prev,
          comments: prev?.comments,
        };
      });
    }

    return true;
  };

  const handleImage = (e) => {
    inputFile.current.click();
  };

  const handleClearInput = () => {
    setClearInput(false);
  };

  const isDisabled = useMemo(() => {
    if (props.trackableType === trackableTypes.DISCUSSION) {
      return !(plainTextValue || files.length);
    }

    return !plainTextValue;
  }, [props.trackableType, plainTextValue, files]);

  const handleDeleteAComment = async (id, totalRepliesCount) => {
    const is_success = await dispatch(deleteAComment(id));
    if (is_success) {
      setCommentsData((prev) => {
        prev.comments?.forEach((comment, i) => {
          if (comment?.id === id) {
            prev?.comments?.splice(i, 1);
          }
        });

        return {
          ...prev,
          count: prev?.count - 1,
          comments: prev?.comments,
        };
      });
      props?.handleTotalComments("delete", totalRepliesCount + 1);
      if (!commentsData.comments.length) {
        setCommentsParams((prev) => ({
          ...prev,
          count: prev?.count - 1,
          limit: 2,
          skip: 0,
        }));
        getComments();
      }
    }

    return is_success;
  };

  return {
    handleDeleteAComment,
    handleCommentChange,
    userProfileImg,
    comment,
    handleSubmitComment,
    comments,
    totalComments,
    canAddComment,
    canViewComments,
    handleCommentParams,
    user,
    handleDeleteComment,
    commentsData,
    trackableType,
    postBy,
    handlePostBy,
    updateComment,
    inputFile,
    handleImage,
    files,
    isFileUploadData,
    handleDiscussionFiles,
    handleDeleteAFile,
    commentRef,
    taggedMentions,
    plainTextValue,
    handleClearInput,
    clearInput,
    isDisabled,
    isAdmin,
  };
};

export const useCommentContent = ({
  data,
  handleDeleteComment,
  updateComment,
  trackableType,
  isPostUnpublished,
}) => {
  const { id } = data;
  const [reactionsCountList, setReactionsCountList] = useState([]);
  const [myReaction, setMyReaction] = useState({});
  const [egReaction, setEgReaction] = useState({});
  const [stats, setStats] = useState(null);
  const [canReply, setCanReply] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editText, setEditText] = useState("");
  const [plainTextValue, setPlainTextValue] = useState("");
  const [taggedMentions, setTaggedMentions] = useState([]);
  const [postBy, setPostBy] = useState("");
  const [imageViewer, setImageViewer] = useState(false);
  const [selectedComment, setSelectedComment] = useState({});
  const [clearInput, setClearInput] = useState();
  const [totalRepliesCount, setTotalRepliesCount] = useState(
    data?.stats?.replies_count || 0
  );

  const dispatch = useDispatch();

  const { user, profile_picture } = useSelector(
    (state) => state?.authReducer?.loggedInUser
  );

  const { handleDiscussionFiles, clearAllFiles, files, handleDeleteAFile } =
    useUploadFiles();
  const { isAdmin } = useUser();
  const { PUBLISHED } = statuses;

  const disabled = useMemo(() => {
    return (
      isPostUnpublished ||
      data?.status !== PUBLISHED ||
      !data?.commenter?.is_active
    );
  }, [isPostUnpublished, data.status, data.commenter.is_active]);

  useEffect(() => {
    if (data?.stats?.reactions_counts?.length > 0) {
      setReactionsCountList(data?.stats?.reactions_counts);
      setStats(data?.stats);
    }
  }, [data.stats]);

  useEffect(() => {
    if (data?.my_reaction?.id) {
      setMyReaction(data?.my_reaction);
    }
    if (data?.eg_reaction?.id) {
      setEgReaction(data?.eg_reaction);
    }
  }, [data, reactionsCountList]);

  const reactionsCount = useMemo(() => {
    if (reactionsCountList.length === 0) {
      return 0;
    }
    const { count } = reactionsCountList.reduce((a, b) => ({
      count: parseInt(a.count) + parseInt(b.count),
    }));
    return count;
  }, [reactionsCountList]);

  const toggleReplyInput = (info) => {
    if (canReply?.id) {
      setCanReply(null);
    } else {
      setCanReply(info);
    }
  };

  const handleRemoveReactSuccess = (
    _reactionId,
    selectedReaction,
    isEgReaction
  ) => {
    const reactionsList = getRemovedReactionList(
      reactionsCountList,
      selectedReaction
    );
    setReactionsCountList(reactionsList);
    isEgReaction ? setEgReaction({}) : setMyReaction({});
  };

  const handleReactSuccess = (submittedReaction, isEgReaction) => {
    const reaction = isEgReaction ? egReaction : myReaction;
    const reactionsList = getAddedReactionList(
      reactionsCountList,
      submittedReaction,
      reaction
    );
    setReactionsCountList(reactionsList, isEgReaction);

    isEgReaction
      ? setEgReaction(submittedReaction)
      : setMyReaction(submittedReaction);
  };

  const handleSubmit = async (id, payload) => {
    await handleDeleteComment(id, payload);
  };

  const handleIsEdit = () => {
    setPlainTextValue(data?.text);
    setIsEdit(!isEdit);
    setSelectedComment(data);
    trackableType === trackableTypes.DISCUSSION &&
      data?.attachments?.forEach((file) =>
        dispatch(
          setFiles({
            ...file,
            file_context:
              file.file_context === fileContext.DISCUSSION_VIDEOS
                ? `${fileContext.DISCUSSION_VIDEOS}-${id}`
                : `${fileContext.DISCUSSION_IMAGES}-${id}`,
          })
        )
      );

    setPostBy(
      data?.commenter?.id === parseInt(process.env.REACT_APP_EGC_ID)
        ? "EvergreenClub"
        : "Me"
    );
  };

  const handleChange = (mentions, newPlainTextValue) => {
    // setEditText(newValue);
    setEditText(newPlainTextValue);
    setTaggedMentions(mentions);
    setPlainTextValue(newPlainTextValue);
  };

  const handleUpdateComment = () => {
    const payload = {
      ...data,
      text: plainTextValue || editText,
      ranges: taggedMentions.map((mention) => ({
        trackable_type:
          mention?.type === "#"
            ? MENTION_TYPES.hashtag
            : mention?.type === "@"
            ? MENTION_TYPES.user
            : "",
        trackable_id: mention.id,
        name: mention?.name?.slice(1, mention?.name?.length),
        offset: mention.offset,
        length: mention.length,
      })),
      attachments:
        trackableType === trackableTypes.DISCUSSION
          ? files.map((file) =>
              file.file_context === `${fileContext.DISCUSSION_VIDEOS}-${id}`
                ? { ...file, file_context: fileContext.DISCUSSION_VIDEOS }
                : { ...file, file_context: fileContext.DISCUSSION_IMAGES }
            )
          : [],

      on_behalf_of: postBy === "EvergreenClub" ? "EvergreenClub" : "",
    };

    const is_success = updateComment(id, payload);
    if (is_success) {
      setClearInput(true);
      setIsEdit(!isEdit);
    }
  };

  const handleImageViewer = () => {
    setImageViewer(!imageViewer);
  };

  const handleClearInput = () => {
    setClearInput(false);
  };

  const handleDeleteReplySuccess = () => {
    setTotalRepliesCount(totalRepliesCount - 1);
  };

  const handleReplySuccess = () => {
    setTotalRepliesCount(totalRepliesCount + 1);
  };

  return {
    canReply,
    toggleReplyInput,
    reactionsCount,
    handleRemoveReactSuccess,
    handleReactSuccess,
    myReaction,
    stats,
    id,
    handleSubmit,
    editText,
    isEdit,
    handleIsEdit,
    user,
    profile_picture,
    handleChange,
    postBy,
    handleUpdateComment,
    imageViewer,
    handleImageViewer,
    taggedMentions,
    selectedComment,
    clearInput,
    handleClearInput,
    files,
    handleDiscussionFiles,
    clearAllFiles,
    handleDeleteAFile,
    isAdmin,
    egReaction,
    totalRepliesCount,
    handleDeleteReplySuccess,
    handleReplySuccess,
    disabled,
  };
};
