import { useState } from "react";
import { useDispatch } from "react-redux";
import { addConfig, getConfigs } from "../actions";

export const useAddSetting = () => {
  const dispatch = useDispatch();

  const [addConfigLoading, setAddConfigLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleAddConfig = async (values) => {
    try {
      const payload = {
        ...values,
        key: values.key?.toUpperCase(),
      };
      setAddConfigLoading(true);
      const is_success = await dispatch(addConfig(payload));
      setAddConfigLoading(false);
      if (is_success) {
        dispatch(getConfigs());
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    addConfigLoading,
    handleAddConfig,
    isOpenModal,
    toggleModal,
  };
};
