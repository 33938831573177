/* eslint-disable */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getStoryViewers } from "../actions";

const LIMIT_COUNT = 10;

export const useViewersList = ({ story }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [viewers, setViewers] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const [viewersParams, setViewersParams] = useState({
    limit: LIMIT_COUNT,
    skip: 0,
  });

  const handleViewersParams = () => {
    setViewersParams((prev) => ({
      ...prev,
      skip: prev?.skip + LIMIT_COUNT,
    }));
  };

  useEffect(() => {
    if (isOpenModal) {
      fetchData();
    }
  }, [viewersParams]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsOpenModal(true);
    const data = await dispatch(getStoryViewers(story?.id, viewersParams));
    if (data?.is_success) {
      setViewers((prev) => {
        if (prev?.length > 0) {
          return [...prev, ...data?.viewers];
        }

        return data?.viewers;
      });
      setTotalCount(data?.count || 0);

      // setIsOpenModal(true);
    }
    setIsLoading(false);
  };

  const toggleModal = () => {
    setViewers([]);
    setViewersParams({
      limit: LIMIT_COUNT,
      skip: 0,
    });
    setIsOpenModal(!isOpenModal);
  };

  const handleUserClick = (e) => {
    history.push(`/users/${e.currentTarget.id}`);
  };

  return {
    viewers,
    isLoading,
    fetchData,
    isOpenModal,
    toggleModal,
    totalCount,
    handleViewersParams,
    story,
    handleUserClick,
  };
};
