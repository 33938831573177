import moment from "moment";
import {
  CLEAR_EVENT_FILTER,
  SET_EVENT_FILTER,
  SET_EVENT_LIST,
  SET_BLOG_FILTER,
  CLEAR_BLOG_FILTER,
  SET_BLOG_LIST,
  CLEAR_POST_FILTER,
  SET_POST_FILTER,
  SET_POST_LIST,
  SET_COURSE_FILTER,
  SET_COURSE_LIST,
  CLEAR_COURSE_FILTER,
} from "./action";

const initialList = {
  posts: [],
  events: [],
  blogs: [],
  courses: [],
  filter: {
    status: "",
    created_at: {
      from: "",
      to: "",
    },
    roles: [],
    caption: "",
    hashtag: "",
    user_id: null,
    name: "",
    mobile_number: "",
    eg_tagged: "",
    reported: false,
    event_title: "",
    poll_question: "",
    limit: 10,
    skip: 0,
  },
  event_filter: {
    status: "Published",
    event_title: "",
    event_date: moment(new Date()).format("DD-MM-YYYY"),
    limit: 100,
    skip: 0,
    is_free: undefined,
    event_time: {
      start: "",
      end: "",
    },
    host_ids: [],
    category_ids: [],
    utc_offset: moment().utcOffset(),
    type: "Public",
    starts_after: "",
    course_title: "",
  },
  blog_filter: {
    id: null,
    title: "",
    status: "",
    limit: 10,
    skip: 0,
    published_at: {
      from: "",
      to: "",
    },
    utc_offset: moment().utcOffset(),
    posts_count: 0,
  },

  course_filter: {
    id: null,
    title: "",
    status: "Published",
    limit: 100,
    skip: 0,
    commence_after: moment(new Date()).format("DD/MM/YYYY"),
    commence_before: "",
    published_at: {
      from: "",
      to: "",
    },
    utc_offset: moment().utcOffset(),
  },
};

const postsReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_POST_LIST:
      return {
        ...state,
        posts: action.payload.posts,
        posts_count: action?.payload?.count,
      };

    case SET_POST_FILTER: {
      const { key, value } = action.payload;
      if (key === "user_id") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value ? value : null,
          },
        };
      }

      if (key === "roles") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value ? [value] : [],
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }

    case CLEAR_POST_FILTER:
      return { ...state, filter: initialList.filter || {} };

    case SET_EVENT_LIST:
      return {
        ...state,
        events: action.payload.event_schedules,
      };

    case SET_EVENT_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        event_filter: {
          ...state.event_filter,
          [key]: value,
        },
      };
    }

    case CLEAR_EVENT_FILTER:
      return { ...state, filter: initialList.event_filter || [] };

    case SET_BLOG_LIST:
      return {
        ...state,
        blogs: action.payload.blogs,
      };

    case SET_BLOG_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        blog_filter: {
          ...state.blog_filter,
          [key]: value,
        },
      };
    }

    case CLEAR_BLOG_FILTER:
      return { ...state, filter: initialList.blog_filter || [] };

    case SET_COURSE_LIST:
      return {
        ...state,
        courses: action.payload.courses,
      };

    case SET_COURSE_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        course_filter: {
          ...state.course_filter,
          [key]: value,
        },
      };
    }

    case CLEAR_COURSE_FILTER:
      return { ...state, filter: initialList.course_filter || [] };

    default:
      return state;
  }
};

export default postsReducer;
