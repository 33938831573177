import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMetaData, getMetaData } from "../actions";
import { getMetaDataFilter } from "../selectors";

export const useAddMetaData = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [addMetaDataLoading, setAddMetaDataLoading] = useState(false);
  const filters = useSelector(getMetaDataFilter);

  const dispatch = useDispatch();

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleAddMetaData = async (payload) => {
    setAddMetaDataLoading(true);
    const is_success = await dispatch(addMetaData(payload));
    setAddMetaDataLoading(false);
    if (is_success) {
      dispatch(getMetaData(filters));
      toggleModal();
    }
  };
  return { addMetaDataLoading, handleAddMetaData, isOpenModal, toggleModal };
};
