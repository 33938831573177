import { api, apiEndPoints } from "../../../../api";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";

const namespace = "containers/popular-keywords";

export const SET_POPULAR_KEYWORD_LIST = `${namespace}/SET_POPULAR_KEYWORD_LIST`;
export const SET_POPULAR_KEYWORD_FILTER = `${namespace}/SET_POPULAR_KEYWORD_FILTER`;

export const setPopularKeywordList = (payload) => ({
  type: SET_POPULAR_KEYWORD_LIST,
  payload,
});

export const setPopularKeywordFilter = (payload) => ({
  type: SET_POPULAR_KEYWORD_FILTER,
  payload,
});

export const addPopularKeyword = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createPopularKeyword, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Popular Keyword not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getPopularKeywordList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPopularKeywords, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setPopularKeywordList(data))
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getPopularKeywordData = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPopularKeyword(id));
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editPopularKeyword = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updatePopularKeyword(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const deletePopularKeywordData = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deletePopularKeyword(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};
