import React from "react";
import { Button } from "reactstrap";
import { useUser } from "../../../../common-hooks/user";
import Modals from "../../../../components/modal";
import MetaDataForm from "../form/form";
import { useAddMetaData } from "./hooks";

const AddMetaData = (props) => {
  const { addMetaDataLoading, handleAddMetaData, isOpenModal, toggleModal } =
    useAddMetaData(props);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }
  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Add Meta Data
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <MetaDataForm
              handleMetaDataForm={handleAddMetaData}
              btn="Add"
              isLoading={addMetaDataLoading}
            />
          }
          title="Add Meta Data"
        />
      ) : null}
    </div>
  );
};

export default AddMetaData;
