import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import * as moment from "moment";

const DateFilter = ({
  handleChange,
  selectedValue,
  filterKey,
  filterTitle,
}) => {
  const value = selectedValue
    ? moment(selectedValue, "DD-MM-yyyy").format("yyyy-MM-DD")
    : "";
  return (
    <div className="table__search">
      <Tooltip title={filterTitle} placement="bottom">
        <input
          className={`${
            value ? "table__active_filter" : ""
          } form-control form__field-table-filter table__date-input`}
          name="endsAt"
          type="date"
          size="small"
          value={value || ""}
          onChange={(event) => {
            if (event.target.value) {
              handleChange(
                filterKey,
                moment(event.target.value).format("DD-MM-yyyy")
              );
            } else {
              handleChange(filterKey, "");
            }
          }}
        />
      </Tooltip>
    </div>
  );
};

DateFilter.propTypes = {
  selectedValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  filterKey: PropTypes.string.isRequired,
  filterTitle: PropTypes.string,
};

DateFilter.defaultProps = {
  selectedValue: "",
  filterTitle: "",
};

export default DateFilter;
