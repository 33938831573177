import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../components/modal";
import { useDeleteCourse } from "./hooks";
import { DataLoader } from "../../../components/loader";
import { useUser } from "../../../common-hooks/user";

const DeleteCourse = ({ course: { id, status } }) => {
  const { handleDeleteCourse, isOpenModal, toggleModal, deleteCourseLoading } =
    useDeleteCourse(id);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  if (status === "Cancelled") return null;

  return (
    <>
      <Tooltip title="Cancel" placement="bottom" className="pull-right">
        <i
          className={`fa fa-times icon-btn-action text-danger `}
          onClick={toggleModal}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <div>
              {deleteCourseLoading ? <DataLoader /> : null}
              Are you sure you want to Cancel?
              <div className="categories__modal-delete-btn">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleDeleteCourse()}
                >
                  Cancel
                </button>
              </div>
            </div>
          }
          title="Cancel Course"
        />
      ) : null}
    </>
  );
};

export default DeleteCourse;
