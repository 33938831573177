import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearLanguage } from "../../utils/auth";
import {
  getCategoryList,
  setCategoryFilter,
  resetCategoryFilter,
} from "./actions";
import {
  getCategories,
  getCategoriesCount,
  getCategoriesFilter,
} from "./selectors";

export const useCategory = () => {
  const dispatch = useDispatch();
  const categories = useSelector(getCategories);
  const categoryCount = useSelector(getCategoriesCount);
  const filters = useSelector(getCategoriesFilter);
  const [loadingCategory, setLoadingCategory] = useState(false);

  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    clearLanguage();
    const fetchCategories = async () => {
      setLoadingCategory(true);
      await dispatch(getCategoryList(filters));
      setLoadingCategory(false);
    };
    fetchCategories();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setCategoryFilter({ key, value }));
  };

  const resetFilter = useCallback(() => {
    dispatch(resetCategoryFilter());
  }, [dispatch]);

  useEffect(() => {
    resetFilter();
  }, [pathname, resetFilter]);

  return {
    categories,
    loadingCategory,
    setFilter,
    filters,
    categoryCount,
  };
};
