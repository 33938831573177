import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventList, setEventFilter, getBlogList } from "../actions";
import {
    getEventsFilter,
    getEvents,
    getBlogsFilter,
    getBlogs,
} from "../selectors";

export const useNoticeForm = () => {
    const dispatch = useDispatch();
    const eventFilters = useSelector(getEventsFilter);
    const events = useSelector(getEvents);
    const blogFilter = useSelector(getBlogsFilter);
    const blogs = useSelector(getBlogs);

    useEffect(() => {
        const fetchEvents = async () => {
            await dispatch(getEventList(eventFilters));
        };
        fetchEvents();
    }, [eventFilters, dispatch]);

    useEffect(() => {
        const fetchBlogs = async () => {
            await dispatch(getBlogList(blogFilter));
        };
        fetchBlogs();
    }, [blogFilter, dispatch]);

    const setFilter = (key, value) => {
        dispatch(setEventFilter({ key, value }));
    };

    return {
        setFilter,
        eventFilters,
        events,
        blogs,
    };
};
