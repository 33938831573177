import * as Yup from "yup";

export const formSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  sectionList: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("Section is required").nullable(),
        limit: Yup.number().required("Limit is required"),
      })
    )
});
