/* eslint-disable */

import { useState, useMemo, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fileContext } from "../../../../../constants";
import { setFiles } from "../../../../../utils/upload-file/actions";
import { useUploadFiles } from "../../../../../utils/upload-file/hooks";
import { getAddedReactionList, getRemovedReactionList } from "../../../utils";
import {
  deleteAComment,
  deleteComment,
  editComment,
  getCommentList,
  replyToComment,
} from "../../action";
import { MENTION_TYPES, statuses } from "../../constant";
import { useUser } from "../../../../../common-hooks/user";

const RECORD_LIMIT = 2;

export const useReplies = (props) => {
  const {
    isOpenReply,
    toggleReplyInput,
    reply,
    handleTotalComments,
    totalRepliesCount,
    handleDeleteReplySuccess,
    handleReplySuccess,
    isPostUnpublished,
    isCommentUnpublished,
  } = props;
  const [replyText, setReplyText] = useState("");

  const [taggedMentions, setTaggedMentions] = useState([]);
  const [plainTextValue, setPlainTextValue] = useState("");
  const [taggedMentionsCopy, setTaggedMentionsCopy] = useState([]);

  const [postBy, setPostBy] = useState("Me");
  const [clearInput, setClearInput] = useState();
  const [repliesData, setRepliesData] = useState({});

  const inputFile = useRef(null);

  const { user = {}, profile_picture } = useSelector(
    (state) => state?.authReducer?.loggedInUser
  );

  const { PUBLISHED } = statuses;

  const disabled = useMemo(() => {
    return isPostUnpublished || isCommentUnpublished;
  }, [isPostUnpublished, isCommentUnpublished]);

  const {
    files,
    isFileUploadData,
    handleDiscussionFiles,
    handleDeleteAFile,
    clearAllFiles,
  } = useUploadFiles();

  const { isAdmin } = useUser();

  useEffect(() => {
    clearAllFiles();
  }, [props.reply]);

  const { replies = [], totalReplies = 0 } = useMemo(() => {
    if (!repliesData || repliesData?.comments?.length === 0) {
      return { replies: [], totalReplies: 0 };
    }

    let totalRecords = repliesData?.count || 0;
    let repliesList = repliesData?.comments || [];

    return { replies: repliesList, totalReplies: totalRecords };
  }, [repliesData]);

  const [replyVisible, setReplyVisible] = useState(true);
  const [params, setParams] = useState({
    trackable_type: [props.trackableType],
    parent_comment_id: props.commentId,
    limit: RECORD_LIMIT,
    skip: 0,
    order: ["created_at DESC"],
  });

  const dispatch = useDispatch();

  const userProfileImg = useMemo(() => {
    return profile_picture?.url;
  }, [profile_picture.url]);

  const handleViewMoreReplies = () => {
    setParams((prev) => ({
      ...prev,
      skip: replies.length,
    }));
  };

  useEffect(() => {
    if (params.skip !== 0) {
      getReplies();
    }
  }, [params.skip]);

  const handlePostBy = (val) => {
    setPostBy(val);
  };

  const getReplies = async () => {
    const replies = await dispatch(getCommentList(params));
    setRepliesData((prev) => {
      if (prev?.comments) {
        return {
          ...prev,
          comments: [...prev?.comments, ...replies?.comments].filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          ),
        };
      }
      return replies;
    });
  };

  useEffect(() => {
    setRepliesData((prev) => {
      return {
        count: parseInt(props?.count),
        comments: props?.replies,
      };
    });
  }, [props?.replies]);

  const updateComment = async (id, payload) => {
    const { is_success } = await dispatch(
      editComment(id, {
        text: payload?.text,
        ranges: payload?.ranges,
        attachments: payload?.attachments,
        on_behalf_of: payload?.on_behalf_of,
      })
    );

    if (is_success) {
      setRepliesData((prev) => {
        prev.comments?.forEach((comment, i) => {
          if (comment?.id === id) {
            prev?.comments?.splice(i, 1, {
              ...payload,
            });
          }
        });

        return {
          ...prev,
          comments: prev?.comments,
        };
      });
    }

    return true;
  };

  const submitReply = async (payload) => {
    const reply = await dispatch(replyToComment(props.commentId, payload));
    if (reply?.is_success) {
      setReplyText("");
      setClearInput(true);
      let commenter;
      if (postBy === "EvergreenClub") {
        commenter = {
          name: "Evergreen Club",
          id: parseInt(process.env.REACT_APP_EGC_ID),
          profile_picture: {
            url: "/img/club-logo.png",
          },
        };
      } else {
        commenter = { ...user, profile_picture };
      }
      handleReplySuccess();
      setRepliesData((prev) => {
        if (prev?.comments) {
          return {
            ...prev,
            count: prev?.count + 1,
            comments: [
              ...prev?.comments,
              { ...reply?.comment, commenter, attachments: files },
            ],
          };
        }
      });
    }
    if (handleTotalComments) {
      handleTotalComments("add");
    }
    clearAllFiles();
  };

  const handleReplyChange = (e) => {
    setReplyText(e.target.value);
  };

  const handleReplySubmit = () => {
    const payload = {
      text: plainTextValue,
      ranges: taggedMentions.map((mention) => ({
        trackable_type:
          mention?.type === "#"
            ? MENTION_TYPES.hashtag
            : mention?.type === "@"
            ? MENTION_TYPES.user
            : "",
        trackable_id: mention.id,
        name: mention?.name?.slice(1, mention?.name?.length),
        offset: mention.offset,
        length: mention.length,
      })),
      on_behalf_of: postBy === "EvergreenClub" ? "EvergreenClub" : "",
      attachments:
        props.trackableType === "EventSchedule"
          ? files.map((file) =>
              file.file_context ===
              `${fileContext.DISCUSSION_VIDEOS}-${reply?.id}`
                ? { ...file, file_context: fileContext.DISCUSSION_VIDEOS }
                : { ...file, file_context: fileContext.DISCUSSION_IMAGES }
            )
          : [],
    };
    submitReply(payload);
  };

  useEffect(() => {
    if (reply?.id && reply?.commenter?.id !== user?.id) {
      setTaggedMentionsCopy([
        {
          trackable_type: "USER",
          name: `${reply?.commenter?.name}`,
          trackable_id: reply?.commenter?.id,
          offset: 0,
          length: reply?.commenter?.name?.length + 1,
        },
      ]);
      setReplyText(`@${reply?.commenter?.name} `);
      setPlainTextValue(`@${reply?.commenter?.name} `);
    } else {
      setTaggedMentions([]);
      setTaggedMentionsCopy([]);
      setReplyText("");
      setPlainTextValue("");
    }
  }, [reply]);

  const handleDeleteComment = async (id, payload) => {
    let payloadCopy = {};
    if (payload.status === PUBLISHED) {
      payloadCopy.status = PUBLISHED;
    } else {
      payloadCopy = { ...payload };
    }

    const is_success = await dispatch(deleteComment(id, payloadCopy));
    if (is_success && payload.status === PUBLISHED) {
      handleDeleteReplySuccess();
      setRepliesData((prev) => {
        prev.comments?.forEach((comment, i) => {
          if (comment?.id === id) {
            prev?.comments?.splice(i, 1, {
              ...comment,
              status: payload.status,
            });
          }
        });

        return {
          ...prev,
          comments: prev?.comments,
        };
      });
    } else if (is_success) {
      handleDeleteReplySuccess();
      setRepliesData((prev) => {
        prev.comments?.forEach((comment, i) => {
          if (comment?.id === id) {
            prev?.comments?.splice(i, 1, {
              ...comment,
              status: payload.status,
            });
          }
        });

        return {
          ...prev,
          comments: prev?.comments,
        };
        // ...prev,
        // comments: prev?.comments.filter((comment) => comment?.id !== id),
      });
    }
  };

  const handleCommentChange = (mentions, newPlainTextValue) => {
    setTaggedMentions(mentions);
    setPlainTextValue(newPlainTextValue);
  };

  const handleImage = (e) => {
    inputFile.current.click();
  };

  const handleClearInput = () => {
    setClearInput(false);
  };

  const isDisabled = useMemo(() => {
    if (props.trackableType === "EventSchedule") {
      return !(plainTextValue || files.length);
    }

    return !plainTextValue;
  }, [props.trackableType, plainTextValue, files]);

  const handleDeleteAComment = async (id) => {
    const is_success = await dispatch(deleteAComment(id));
    if (is_success) {
      if (handleTotalComments) {
        handleTotalComments("delete", totalRepliesCount);
      }
      setRepliesData((prev) => {
        prev.comments?.forEach((comment, i) => {
          if (comment?.id === id) {
            prev?.comments?.splice(i, 1);
          }
        });

        return {
          ...prev,
          count: prev?.count - 1,
          comments: prev?.comments,
        };
      });
    }

    if (!repliesData.comments.length) {
      setParams((prev) => ({
        trackable_type: [props.trackableType],
        parent_comment_id: props.commentId,
        limit: 2,
        skip: replies.length,
      }));
      getReplies();
    }

    return is_success;
  };

  return {
    handleDeleteAComment,
    replyText,
    handleReplyChange,
    user,
    userProfileImg,
    handleReplySubmit,
    replies,
    totalReplies,
    replyVisible,
    toggleReplyInput,
    handleViewMoreReplies,
    handleDeleteComment,
    handleCommentChange,
    postBy,
    handlePostBy,
    inputFile,
    handleImage,
    files,
    isFileUploadData,
    handleDiscussionFiles,
    handleDeleteAFile,
    clearAllFiles,
    isOpenReply,
    reply,
    plainTextValue,
    taggedMentions,
    taggedMentionsCopy,
    clearInput,
    handleClearInput,
    updateComment,
    isDisabled,
    isAdmin,
    disabled,
  };
};

export const useReply = ({
  data,
  updateComment,
  trackableType,
  isEgReaction,
  isDisabled,
}) => {
  const [reactionsCountList, setReactionsCountList] = useState([]);
  const [myReaction, setMyReaction] = useState({});
  const [egReaction, setEgReaction] = useState({});
  const [imageViewer, setImageViewer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState("");
  const [taggedMentions, setTaggedMentions] = useState([]);
  const [postBy, setPostBy] = useState("Me");
  const [clearInput, setClearInput] = useState();

  const dispatch = useDispatch();

  const { handleDiscussionFiles, clearAllFiles, files, handleDeleteAFile } =
    useUploadFiles();

  const { PUBLISHED } = statuses;
  useEffect(() => {
    if (data?.stats?.reactions_counts?.length > 0) {
      setReactionsCountList(data?.stats?.reactions_counts);
    }
  }, [data.stats]);

  const { user, profile_picture } = useSelector(
    (state) => state?.authReducer?.loggedInUser
  );

  const { isAdmin } = useUser();

  const disabled = useMemo(() => {
    return (
      isDisabled || data?.status !== PUBLISHED || !data?.commenter?.is_active
    );
  }, [isDisabled, data?.status, data.commenter.is_active]);

  const handleIsEdit = () => {
    setPlainTextValue(data?.text);
    setIsEdit(!isEdit);
    trackableType === "EventSchedule" &&
      data?.attachments?.forEach((file) =>
        dispatch(
          setFiles({
            ...file,
            file_context:
              file.file_context === fileContext.DISCUSSION_VIDEOS
                ? `${fileContext.DISCUSSION_VIDEOS}-${data?.id}`
                : `${fileContext.DISCUSSION_IMAGES}-${data?.id}`,
          })
        )
      );
    setPostBy(
      data?.commenter?.id === parseInt(process.env.REACT_APP_EGC_ID)
        ? "EvergreenClub"
        : "Me"
    );
  };

  const handleChange = (mentions, newPlainTextValue) => {
    // setEditText(newValue);
    setTaggedMentions(mentions);
    setPlainTextValue(newPlainTextValue);
  };

  useEffect(() => {
    if (data?.my_reaction?.id) {
      setMyReaction(data?.my_reaction);
    }

    if (data?.eg_reaction?.id) {
      setEgReaction(data?.eg_reaction);
    }
  }, [data.my_reaction]);

  const userProfileImg = useMemo(() => {
    return data?.commenter?.profile_picture?.url
      ? data?.commenter?.profile_picture?.url
      : "";
  }, [data]);

  const reactionsCount = useMemo(() => {
    if (reactionsCountList.length === 0) {
      return 0;
    }
    const { count } = reactionsCountList.reduce((a, b) => ({
      count: parseInt(a.count) + parseInt(b.count),
    }));
    return count;
  }, [reactionsCountList]);

  const handleRemoveReactSuccess = (_reactionId, selectedReaction) => {
    const reactionsList = getRemovedReactionList(
      reactionsCountList,
      selectedReaction
    );
    setReactionsCountList(reactionsList);
    isEgReaction ? setEgReaction({}) : setMyReaction({});
  };

  const handleReactSuccess = (submittedReaction, isEgReaction) => {
    const reaction = isEgReaction ? egReaction : myReaction;

    const reactionsList = getAddedReactionList(
      reactionsCountList,
      submittedReaction,
      reaction
    );
    setReactionsCountList(reactionsList);
    isEgReaction
      ? setEgReaction(submittedReaction)
      : setMyReaction(submittedReaction);
  };

  const handleImageViewer = () => {
    setImageViewer(!imageViewer);
  };

  const handleUpdateComment = () => {
    const payload = {
      ...data,
      text: plainTextValue,
      ranges: taggedMentions.map((mention) => ({
        trackable_type:
          mention?.type === "#"
            ? MENTION_TYPES.hashtag
            : mention?.type === "@"
            ? MENTION_TYPES.user
            : "",
        trackable_id: mention.id,
        name: mention?.name?.slice(1, mention?.name?.length),
        offset: mention.offset,
        length: mention.length,
      })),
      attachments:
        trackableType === "EventSchedule"
          ? files.map((file) =>
              file.file_context ===
              `${fileContext.DISCUSSION_VIDEOS}-${data?.id}`
                ? { ...file, file_context: fileContext.DISCUSSION_VIDEOS }
                : { ...file, file_context: fileContext.DISCUSSION_IMAGES }
            )
          : [],

      on_behalf_of: postBy === "EvergreenClub" ? "EvergreenClub" : "",
    };

    const is_success = updateComment(data?.id, payload);
    if (is_success) {
      setClearInput(true);
      handleIsEdit();
    }
  };

  const handleClearInput = () => {
    setClearInput(false);
  };

  return {
    userProfileImg,
    reactionsCount,
    handleRemoveReactSuccess,
    handleReactSuccess,
    myReaction,
    imageViewer,
    handleImageViewer,
    user,
    plainTextValue,
    handleIsEdit,
    postBy,
    taggedMentions,
    isEdit,
    handleChange,
    profile_picture,
    handleUpdateComment,
    handleDiscussionFiles,
    clearAllFiles,
    files,
    handleDeleteAFile,
    clearInput,
    handleClearInput,
    isAdmin,
    egReaction,
    disabled,
  };
};
