export const usePagination = ({ skip, count, pageSize, handleSetFilter }) => {
  const pageIndex = Math.ceil(skip / pageSize);

  const pageOptions = Array.from(Array(Math.ceil(count / pageSize)).keys());

  const canNextPage = pageIndex < pageOptions.length -1 ;

  const canPreviousPage = pageIndex > 0;

  const arrayPageIndex =
    pageIndex - 2 < 0
      ? pageOptions.slice(0, pageIndex + 3)
      : pageOptions.slice(pageIndex - 2, pageIndex + 3);

  const handlePreviousPage = () => {
    handleSetFilter("skip", skip - pageSize);
  };

  const handleNextPage = () => {
    handleSetFilter("skip", skip + pageSize);
  };

  const handleLastPage = () => {
    handleSetFilter("skip", (pageOptions.length - 1) * 10);
  };

  const handleFirstPage = () => {
    handleSetFilter("skip", 0);
  };

  const handleMoveInBetween = (selectedPage) => {
    handleSetFilter("skip", selectedPage * pageSize);
  }

  return {
    pageIndex,
    pageOptions,
    canNextPage,
    canPreviousPage,
    arrayPageIndex,
    handlePreviousPage,
    handleNextPage,
    handleLastPage,
    handleFirstPage,
    handleMoveInBetween,
  };
};
