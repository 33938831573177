import React from "react";
import DataTable from "../../../../components/table";
import { usePopularKeywords } from "./hooks";
import { columns, statusOptions } from "./constant";
import DeletePopularKeyword from "./delete-popular-keyword";
import AddPopularKeyword from "./add-popular-keyword";
import EditPopularKeyword from "./edit-popular-keyword";
import SelectFilter from "../../../../components/filters/select-filter";

const PopularKeywordList = () => {
  const {
    popularKeywords,
    loadingPopularKeywords,
    setFilter,
    filters,
    popularKeywordCount,
  } = usePopularKeywords();

  const configureTableData = () => {
    try {
      let results = popularKeywords.map((popularKeyword) => {
        let newPopularKeyword = Object.assign({}, popularKeyword);
        newPopularKeyword["action"] = (
          <div className="label__container">
            <EditPopularKeyword popularKeyword={popularKeyword} />
            <DeletePopularKeyword popularKeyword={popularKeyword?.id} />
          </div>
        );
        return newPopularKeyword;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const popularKeywordFilter = () => (
    <>
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        filterKey="status"
        options={statusOptions}
        filterTitle="Status"
      />
    </>
  );

  return (
    <DataTable
      title="Popular Keywords"
      addBtn={<AddPopularKeyword />}
      CreateTableData={configureTableData}
      withFilter={true}
      filter={popularKeywordFilter}
      handleSetFilter={setFilter}
      count={popularKeywordCount}
      skip={filters?.skip}
      isLoading={loadingPopularKeywords}
    />
  );
};

export default PopularKeywordList;
