import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem, Tooltip } from "@material-ui/core";

import { subcategoryStatus, fieldsName } from "../constants";
import { categoriesFormSchema } from "./validation-schema";
import { initialLanguageFieldValue, languages } from "../../../../constants";
import { useHandleFieldFocus } from "../../../../utils/form";
import { DataLoader } from "../../../../components/loader";
import { useSubcategoryForm } from "./hook";

const SubcategoriesForm = (props) => {
  const {
    formValues,
    handleCategoryForm,
    btn,
    isLoading,
    category_type,
    handleOnChange,
  } = useSubcategoryForm(props);

  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();
  const { title, description, status, type, language } = fieldsName;

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={categoriesFormSchema}
      initialValues={{
        title: formValues?.title || initialLanguageFieldValue,
        description: formValues?.description || initialLanguageFieldValue,
        type: formValues?.type || category_type || "",
        status: formValues?.status || "",
        language: formValues?.language || "en",
      }}
      onSubmit={handleCategoryForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={language}
                placeholder="Language"
                label="Language"
                select
                value={rest.values.language}
                onChange={(event) => {
                  handleOnChange(event, rest, language);
                }}
                onFocus={() => handleFocusField(language)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.language}
              >
                {languages.map((language) => (
                  <MenuItem
                    key={language.id}
                    className="material-form__option"
                    value={language.value}
                  >
                    {language.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === language ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={language}
                />
              )}
            </div>
            <div>
              <TextField
                className="material-form__field"
                name={type}
                placeholder="Type"
                label="Type"
                disabled
                value={rest.values.type}
              />
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  type="text"
                  value={rest.values.title[rest.values.language]}
                  onChange={(event) => {
                    handleOnChange(event, rest, title);
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={description}
                  placeholder="Description"
                  label="Description"
                  type="text"
                  value={rest.values.description[rest.values.language]}
                  onChange={(event) => {
                    handleOnChange(event, rest, description);
                  }}
                  onFocus={() => handleFocusField(description)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === description ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={description}
                  />
                )}
              </div>

              <div className="col-md-2 mt-4 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={(event) => handleOnChange(event, rest, status)}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
                disabled={formValues?.is_used}
              >
                {subcategoryStatus.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default SubcategoriesForm;
