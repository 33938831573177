import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initialLanguageArrayValue,
  initialLanguageFieldValue,
  snackbarTypes,
} from "../../../constants";
import { clearLanguage, getLanguage } from "../../../utils/auth";
import { clearFiles, setFiles } from "../../../utils/upload-file/actions";
import { editUser, getUserList, getUser } from "../actions";
import { userRoles } from "../../../constants";
import { showSnackbar } from "../../../components/snackbar/actions";
import moment from "moment";

export const useEditUser = ({ id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(({ userReducer }) => userReducer.filter);
  const [editUserLoading, setEditUserLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const { CO_ORDINATOR } = userRoles;

  const getUserFieldData = async (rest, setFormValues) => {
    const selectedLanguage = getLanguage();

    setEditUserLoading(true);
    const userDetails = await dispatch(getUser(id));
    if (userDetails) {
      userDetails.mobile_number = userDetails?.user?.mobile_number;
      userDetails.name = userDetails?.user?.name;
      userDetails.email = userDetails?.user?.email;
      userDetails.role = userDetails?.user?.role;
      userDetails.is_active = userDetails?.user?.is_active;
      userDetails.about_me = {
        ...rest.values.about_me,
        [selectedLanguage]:
          rest.values.about_me[selectedLanguage] ||
          userDetails?.profile?.about_me ||
          "",
      };
      userDetails.short_about_me = {
        ...rest.values.short_about_me,
        [selectedLanguage]:
          rest.values.short_about_me[selectedLanguage] ||
          userDetails?.profile?.short_about_me ||
          "",
      };
      userDetails.address = userDetails?.profile?.address;
      userDetails.date_of_birth = userDetails?.profile?.date_of_birth;
      userDetails.designation = {
        ...rest.values.designation,
        [selectedLanguage]:
          rest.values.designation[selectedLanguage] ||
          userDetails?.profile?.designation ||
          "",
      };

      userDetails.gender = userDetails?.profile?.gender;
      userDetails.highlights = {
        ...rest.values.highlights,
        [selectedLanguage]: rest.values.highlights[selectedLanguage]?.length
          ? rest.values.highlights[selectedLanguage]
          : userDetails?.profile?.highlights || [],
      };
      userDetails.pincode = userDetails?.profile?.pincode;
      userDetails.specialities = {
        ...rest.values.specialities,
        [selectedLanguage]: rest.values.specialities[selectedLanguage]?.length
          ? rest.values.specialities[selectedLanguage]
          : userDetails?.profile?.specialities || [],
      };
      userDetails.title = userDetails?.profile?.title;
      userDetails.emergency_mobile_number =
        userDetails?.profile?.emergency_mobile_number;
      userDetails.is_popular = userDetails?.profile?.is_popular;
      userDetails.tag = userDetails?.profile?.tag;
      userDetails.age_group = userDetails?.profile?.age_group;
      userDetails.language = selectedLanguage;

      // setSelectedUser(userDetails);
      setFormValues(userDetails);
      setEditUserLoading(false);
      setIsOpenModal(true);
    }
  };

  const handleLangugeData = async () => {
    const selectedLanguage = getLanguage();

    setEditUserLoading(true);
    const userDetails = await dispatch(getUser(id));
    if (userDetails) {
      const { profile_picture, profile_banner, profile_video } = userDetails;
      dispatch(clearFiles());
      profile_picture?.original_file_name &&
        dispatch(setFiles(profile_picture));
      profile_banner?.original_file_name && dispatch(setFiles(profile_banner));
      profile_video?.original_file_name && dispatch(setFiles(profile_video));
      userDetails.mobile_number = userDetails?.user?.mobile_number;
      userDetails.name = userDetails?.user?.name;
      userDetails.email = userDetails?.user?.email;
      userDetails.role = userDetails?.user?.role;
      userDetails.country_code = userDetails?.user?.country_code || "+91";
      userDetails.is_active = userDetails?.user?.is_active;
      userDetails.is_blacklisted = userDetails?.user?.is_blacklisted;
      userDetails.is_deleted = userDetails?.user?.is_deleted;

      userDetails.about_me = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: userDetails?.profile?.about_me || "",
      };
      userDetails.short_about_me = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: userDetails?.profile?.short_about_me || "",
      };
      userDetails.address = userDetails?.profile?.address;
      userDetails.date_of_birth = userDetails?.profile?.date_of_birth;
      userDetails.designation = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: userDetails?.profile?.designation || "",
      };

      userDetails.gender = userDetails?.profile?.gender;
      userDetails.highlights = {
        ...initialLanguageArrayValue,
        [selectedLanguage]: userDetails?.profile?.highlights?.length
          ? userDetails?.profile?.highlights
          : [],
      };
      userDetails.pincode = userDetails?.profile?.pincode;
      userDetails.specialities = {
        ...initialLanguageArrayValue,
        [selectedLanguage]: userDetails?.profile?.specialities?.length
          ? userDetails?.profile?.specialities
          : [],
      };
      userDetails.title = userDetails?.profile?.title;
      userDetails.emergency_mobile_number =
        userDetails?.profile?.emergency_mobile_number;
      userDetails.language = selectedLanguage;
      userDetails.is_popular = userDetails?.profile?.is_popular;
      userDetails.age_group = userDetails?.profile?.age_group || "Other";
      userDetails.tag = userDetails?.profile?.tag;

      setSelectedUser(userDetails);
      setEditUserLoading(false);
      setIsOpenModal(true);
    }
  };

  const handleEditUser = async (values) => {
    if (values.is_blacklisted && (values.is_active && !values.is_deleted)) {
      dispatch(showSnackbar({
        message: 'To Blacklist, select Deactivate or Delete is mandatory',
        type: snackbarTypes.ERROR,
      }));
    } else if (values.is_blacklisted && !values.is_active && values.is_deleted) {
      dispatch(showSnackbar({
        message: 'Select either Delete or deactivate!',
        type: snackbarTypes.ERROR,
      }));
    }  else {
      setEditUserLoading(true);

      const payload = {
        user: {
          country_code: values.country_code,
          mobile_number: values.mobile_number,
          name: values.name,
          email: values.email,
          role: values.role,
          is_active: values.is_active,
          is_deleted: values.is_deleted,
          is_blacklisted: values.is_blacklisted,
        },
        profile: {
          title: values.title,
          about_me: values.about_me,
          designation: values.designation,
          specialities: values.specialities,
          highlights: values.highlights,
          date_of_birth: values.date_of_birth,
          gender: values.gender,
          address: values.address,
          pincode: values.pincode,
          emergency_mobile_number: values.emergency_mobile_number,
          is_popular: values.role === CO_ORDINATOR ? values.is_popular : false,
          tag: values.role === CO_ORDINATOR ? values.tag : "",
          short_about_me: values.short_about_me,
          age_group: values.age_group,
        },
        utc_offset: moment().utcOffset(),
        profile_pic:
          files.find((file) => file?.file_context === "Profile Picture") || {},
        profile_banner:
          files.find((file) => file?.file_context === "Profile Banner") || {},
        profile_video:
          files.find((file) => file?.file_context === "Profile Video") || {},
      };
      clearLanguage();
      const is_success = await dispatch(editUser(payload, id));
      setEditUserLoading(false);
      if (is_success) {
        dispatch(getUserList(filters));
        toggleModal();
      }
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editUserLoading,
    handleEditUser,
    isOpenModal,
    toggleModal,
    getUserFieldData,
    selectedUser,
    handleLangugeData,
  };
};
