import React from "react";
import BonusSesionForm from "../form/session-form";
import { useAddBonusSession } from "./hooks";
import Modals from "../../../../components/modal";
import { Button } from "reactstrap";
import { useUser } from "../../../../common-hooks/user";


const AddBonusSession = ({ user_id }) => {
  const {
    handleAddBonusSession,
    isOpenModal,
    toggleModal,
    addUserLoading,
  } = useAddBonusSession({ user_id });

  const { isAdmin, isCSSpecialist } = useUser();

  if (!isAdmin && !isCSSpecialist) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          toggleModal(!isOpenModal);
        }}
      >
        <i className="fa fa-plus"></i> Add Bonus Session
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <BonusSesionForm
              handleBonusSessionForm={handleAddBonusSession}
              btn="Add"
              isLoading={addUserLoading}
            />
          }
          title="Add Bonus Session"
        />
      ) : null}
    </div>
  );
};

export default AddBonusSession;
