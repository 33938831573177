import { SET_META_DATA_FILTER, SET_META_DATA_LIST } from "./actions";

const initialState = {
  meta_data: [],
  count: 0,
  filter: {
    text: "",
    limit: 10,
    skip: 0,
  },
};

const metaDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_META_DATA_LIST:
      return {
        ...state,
        meta_data: action.payload.pages_meta_data,
        count: action.payload.count,
      };

    case SET_META_DATA_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    default:
      return state;
  }
};

export default metaDataReducer;
