import React from "react";
import PropTypes from "prop-types";
import { usePostCaption } from "./hooks";
import styles from "./styles.module.scss";
import Text from "../../../../../components/text";
import { colors } from "../../../../../constants/colors";

const PostCaption = (props) => {
  const {
    caption,
    captionRef,
    handleReadMoreClick,
    showReadMore,
    handleReadLessClick,
    isPost,
  } = usePostCaption(props);
  return (
    <>
      <div
        className={`${
          props?.className ? props.className : styles.caption_text
        }`}
        ref={captionRef}
        dangerouslySetInnerHTML={{
          __html: showReadMore ? caption.slice(0, props.charLength) : caption,
        }}
      />

      {showReadMore ? (
        <Text
          onClick={handleReadMoreClick}
          className={"cursor_pointer"}
          color={isPost ? colors.brand : colors.black}
          fontWeight={isPost ? 600 : 400}
          size={isPost ? "16px" : "14px"}
        >
          read more
        </Text>
      ) : null}

      {!showReadMore && caption?.length > props?.charLength ? (
        <Text
          onClick={handleReadLessClick}
          className={"cursor_pointer"}
          color={isPost ? colors.brand : colors.black}
          fontWeight={isPost ? 600 : 400}
          size={isPost ? "16px" : "14px"}
        >
          read less
        </Text>
      ) : null}
    </>
  );
};

PostCaption.propTypes = {
  readOnly: PropTypes.bool,
  captionText: PropTypes.string.isRequired,
  ranges: PropTypes.array.isRequired,
};

PostCaption.defaultProps = {
  readOnly: false,
  charLength: 500,
};

export default PostCaption;
