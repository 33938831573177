import { useState } from "react";

export const handleFormValueChange = (rest, key) => (value) => {
  const touched = { ...rest.touched };
  touched[key] = true;
  rest.setTouched(touched);
  rest.handleChange(key)(value);
};

export const useHandleFieldFocus = () => {
  const [focusField, setFocusField] = useState();

  const handleFocusField = (key) => {
    setFocusField(key);
  };

  const handleBlurField = () => {
    setFocusField(null);
  };

  return {
    focusField,
    handleFocusField,
    handleBlurField,
  };
};
