import React from "react";
import { DataLoader } from "../../../../components/loader";
import ProfileImage from "../../../../components/ProfileImage";
import Text from "../../../../components/text";
import { colors } from "../../../../constants/colors";
import styles from "./style.module.scss";

const List = ({
  list,
  handleViewersParams,
  totalCount,
  isLoading,
  handleUserClick,
}) => {
  if (!list.length && !isLoading)
    return <div className="text-center">No viewers Found for this story</div>;
  return (
    <div className={styles.list_container}>
      {isLoading ? (
        <DataLoader />
      ) : (
        <>
          {list.map((viewer) => (
            <div
              onClick={handleUserClick}
              key={viewer?.id}
              className={styles.container}
              id={viewer?.id}
            >
              <ProfileImage
                src={viewer?.profile_pic?.url}
                title={viewer?.name}
                className={styles.profile_picture}
                initialsClassName={styles.profile_picture_initials}
              />
              <div className={styles.detail_container}>
                <Text
                  fontWeight={600}
                  size="14px"
                  lineheight="20px"
                  color={colors.lightBlack4}
                >{`${viewer?.name} `}</Text>
              </div>
            </div>
          ))}
          {list?.length < totalCount && (
            <Text
              className="text-center d-block "
              color={colors.brand}
              fontWeight={400}
              size="20px"
              onClick={handleViewersParams}
              role="button"
            >
              View More
            </Text>
          )}
        </>
      )}
    </div>
  );
};

export default List;
