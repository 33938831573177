import * as moment from "moment";
import { trackableTypes } from "../../constants";

import {
  SET_SCHEDULE_EVENT_LIST,
  SET_EVENT_LIST,
  SET_EVENT_FILTER,
  CLEAR_EVENT_FILTER,
  SET_USER_LIST,
  SET_SCHEDULE_EVENT_FILTER,
  SET_CATEGORIES_LIST,
  RESET_SCHEDULE_EVENT_FILTER,
  SET_SCHEDULE_EVENT_NOTE,
  CLEAR_SCHEDULE_EVENT_NOTE,
  SET_SCHEDULE_EVENT_REVIEWS,
  CLEAR_SCHEDULE_EVENT_FILTER,
  SET_DISCUSSION_FILTER,
  SET_DISCUSSION_LIST,
} from "./actions";

const initialList = {
  scheduleEvents: [],
  users: [],
  events: [],

  eventsFilter: {
    status: "Published",
    text: "",
    is_course: false,
    limit: 10,
    skip: 0,
  },
  discussions: [],
  discussion_count: 0,
  categories: [],
  categoryFilter: {
    type: "Event",
    status: "Published",
  },
  scheduleEventNote: {},
  scheduleEventsReview: [],
  count: 0,
  filter: {
    status: "Published",
    event_date: "",
    limit: 10,
    skip: 0,
    is_free: undefined,
    event_time: {
      start: "",
      end: "",
    },
    host_ids: [],
    category_ids: [],
    utc_offset: moment().utcOffset(),
    type: "Public",
    starts_after: moment(new Date()).format("DD/MM/YYYY @ hh:mm A "),
    course_title: "",
  },

  discussionFilter: {
    trackable_type: trackableTypes.EVENTSCHEDULE,
    trackable_id: null,
    limit: 10,
    skip: 0,
    replies: { limit: 1, skip: 0 },
  },
};

const scheduleEventReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_SCHEDULE_EVENT_LIST: {
      return {
        ...state,
        scheduleEvents: action.payload.event_schedules,
        count: action.payload.count || [],
      };
    }
    case SET_SCHEDULE_EVENT_REVIEWS:
      return {
        ...state,
        scheduleEventsReview: action.payload,
      };
    case SET_EVENT_LIST:
      return { ...state, events: action.payload || [] };

    case SET_EVENT_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        eventsFilter: {
          ...state.eventsFilter,
          [key]: value,
        },
      };
    }

    case CLEAR_EVENT_FILTER:
      return { ...state, eventsFilter: initialList.eventsFilter };

    case SET_USER_LIST:
      return { ...state, users: action.payload || [] };
    case SET_CATEGORIES_LIST:
      return { ...state, categories: action.payload || [] };
    case SET_SCHEDULE_EVENT_NOTE:
      return {
        ...state,
        scheduleEventNote: action.payload,
      };
    case CLEAR_SCHEDULE_EVENT_NOTE:
      return {
        ...state,
        scheduleEventNote: {},
      };
    case SET_SCHEDULE_EVENT_FILTER: {
      const { key, value } = action.payload;
      if (key === "host_ids" || key === "category_ids") {
        return +value
          ? {
              ...state,
              filter: {
                ...state.filter,
                [key]: [+value],
              },
            }
          : {
              ...state,
              filter: {
                ...state.filter,
                [key]: [],
              },
            };
      }

      if (key === "is_free") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value === "" ? undefined : value === "Free",
          },
        };
      }

      if (key === "event_date") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value,
            starts_after: "",
          },
        };
      }

      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case RESET_SCHEDULE_EVENT_FILTER:
      return { ...state, filter: initialList.filter };

    case CLEAR_SCHEDULE_EVENT_FILTER: {
      return {
        ...state,
        filter: {
          ...initialList.filter,
          starts_after: "",
        },
      };
    }

    case SET_DISCUSSION_LIST:
      return {
        ...state,
        discussions: state?.discussions?.length
          ? [...state?.discussions, ...action.payload.discussions].filter(
              (v, i, a) => a.findIndex((t) => t.id === v.id) === i
            )
          : action.payload.discussions,
        discussion_count: action.payload.count,
      };

    case SET_DISCUSSION_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        discussionFilter: {
          ...state.discussionFilter,
          [key]: value,
        },
      };
    }

    default:
      return state;
  }
};

export default scheduleEventReducer;
