import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addReportOption, getReportOptionsList } from "../actions";
import { getRepotOptionFilter } from "../selectors";

export const useAddReason = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(getRepotOptionFilter);
  const [addReasonLoading, setAddReasonLoading] = useState(false);

  const handleAddReason = async (values) => {
    setAddReasonLoading(true);
    let payload = {
      reason: values?.reason,
    };

    const is_success = await dispatch(addReportOption(payload));
    setAddReasonLoading(false);
    if (is_success) {
      dispatch(getReportOptionsList(filters));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    addReasonLoading,
    handleAddReason,
    isOpenModal,
    toggleModal,
  };
};
