import React from "react";
import styles from "./styles.module.scss";
import PostImages from "../post-images";
import ProfileImage from "../../../../../components/ProfileImage";
import Text from "../../../../../components/text";
import moment from "moment";
import { LogoGreenBorder } from "../../images";
import PollComponent from "../poll-component";
import PostCaption from "../post-caption";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    ss: "%ds",
    m: "a minute",
    mm: "%dm",
    h: "an hour",
    hh: "%dh",
    d: "a day",
    dd: "%dd",
    w: "a week",
    ww: "%dw",
    M: "a month",
    MM: "%dm",
    y: "a year",
    yy: "%dyrs",
  },
});
const PostContent = ({ data }) => {
  const postCreatorIcon = data?.owner?.profile_picture?.url;

  return (
    <>
      <div className={styles.blank_line} />
      <div className={styles.container}>
        <div className={styles.card_container}>
          <div className={styles.post_content}>
            <div className={styles.user_detail_container}>
              <ProfileImage
                src={postCreatorIcon}
                title={data?.owner?.name}
                height={48}
                width={48}
                className={styles.owner_img}
              />
              <div className={styles.user_detail_content}>
                <Text size="16px" fontWeight={600}>
                  {data?.owner?.name}
                </Text>
                <Text
                  size="12px"
                  fontWeight={400}
                  className={styles.time_format}
                >
                  {moment(data?.created_at).fromNow()}
                </Text>
              </div>
            </div>
            <div className={styles.caption}>
              <PostCaption captionText={data?.caption} ranges={data?.ranges} />
            </div>
          </div>
          <div>
            {data?.poll && data?.poll?.id ? (
              <PollComponent details={data?.poll} />
            ) : null}
            <PostImages images={data?.files} />
          </div>
        </div>
      </div>
    </>
  );
};

export const BlogCard = ({ data }) => {
  const blogBanner = data?.banner?.url ? data?.banner?.url : "";

  return (
    <div className={styles.blog_card_container}>
      <div className={styles.blog_card}>
        <div className={styles.blog_time_to_read}>
          <Text size="12px">{data?.time_to_read} mins read</Text>
        </div>
        <img src={blogBanner} className={styles.blog_banner} alt="banner" />
        <div className={styles.blog_detail}>
          <div className={styles.blog_title}>
            <Text fontWeight={600}>{data?.title}</Text>
          </div>
          <div className={styles.blog_desc}>
            <Text fontWeight={400} size="12px">
              {data?.description}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BlogContent = (props) => {
  const { blog: data } = props.data;

  return (
    <div className={styles.blog_container}>
      <div className={styles.blog_content}>
        <div className={styles.user_detail_container}>
          <ProfileImage
            src={LogoGreenBorder}
            title={data?.owner?.name}
            height={48}
            width={48}
          />
          <div className={styles.user_detail_content}>
            <Text size="16px" fontWeight={600}>
              Evergreen Club Blog
            </Text>
            <Text size="12px" fontWeight={400} className={styles.time_format}>
              {moment(props?.data?.created_at).fromNow()}
            </Text>
          </div>
        </div>
        <a
          className={styles.blog_link}
          target="_blank"
          href={`/blogs/${data.id}`}
          rel="noopener noreferrer"
        >
          <BlogCard data={data} />
        </a>
      </div>
    </div>
  );
};

const PostSharedContent = (props) => {
  const { post } = props?.data;

  if (!post?.id) {
    return null;
  }

  // if (blog.id) {
  //   return <BlogContent data={props.data} />;
  // }

  return <PostContent data={post} />;
};

export default PostSharedContent;
