import * as Yup from "yup";
import moment from "moment";

export const blogFormSchema = Yup.object().shape({
  event_id: Yup.string().required("Event is required").nullable(),
  status: Yup.string().required("Status is required"),
  language: Yup.string().required("Language is required"),
  mode: Yup.string().required("Mode is required"),
  // commence_at: Yup.date().required("Commence At is required"),
  per_session_cost: Yup.number().required("Per Session  Credits are required"),
  hostIds: Yup.object().required("Host is required").nullable(),
  cohostIds: Yup.array().required("Co-Host is required"),
  attendees_limit: Yup.number().required("Optimum Batch Size is required"),
  current_attendees_limit: Yup.number()
    .required("Displayed Seats Count is required")
    .test(
      "is-less-than",
      "Displayed Seats Count Size must be less than or equal to than Optimum Batch Size",
      function () {
        const { current_attendees_limit, attendees_limit } = this.parent;

        return current_attendees_limit <= attendees_limit;
      }
    )
    .test(
      "is-less-than",
      "Displayed Seats Count seats must be greater than booked seats",
      function () {
        const { current_attendees_limit, booked } = this.parent;
        if (booked) {
          return booked <= current_attendees_limit;
        }
        return true;
      }
    ),
  exceeds_percentage: Yup.number()
    .required("Threshold % for changing Displayed Seats Count is required")
    .min(1, "This field should have non zero value.")
    .test(
      "is-less-than",
      "Value should be less than 100",
      function (exceeds_percentage) {
        return exceeds_percentage <= 100;
      }
    ),
  increment_percentage: Yup.number()
    .required("% for updating new Displayed Seats Count is required")
    .min(1, "This field should have non zero value.")
    .test(
      "is-less-than",
      "Value should be less than 100",
      function (increment_percentage) {
        return increment_percentage <= 100;
      }
    ),
  allow_booking_post_commencement: Yup.date().required(
    "Last Date and Time to book seats is required"
  ),
  allow_seats_increment: Yup.date().required(
    "Last Date and Time to Increase Displayed Seat Count is required"
  ),

  requirements: Yup.object(),
  event_schedules: Yup.array().of(
    Yup.object().shape({
      btn: Yup.string(),
      title: Yup.mixed().test(
        "English language require",
        "Title is required for English language",
        function () {
          return this.parent?.title?.en;
        }
      ),
      type: Yup.string().required("Type is required"),
      attendees_limit: Yup.number().required("Optimum Batch Size is required"),
      current_attendees_limit: Yup.number()
        .typeError("Displayed Seats Count is required")
        .min(1, "Minimum Displayed Count is 1")
        .required("Displayed Seats Count is required")
        .test(
          "is-less-than",
          "Displayed Seats Count must be less than or eual to Optimum Batch Size",
          function () {
            const { current_attendees_limit, attendees_limit } = this.parent;

            return current_attendees_limit <= attendees_limit;
          }
        ),
      starts_at: Yup.date()
        .when("btn", {
          is: "Add",
          then: Yup.date().min(
            new Date(),
            "Start date and time must be greater than current date and time"
          ),
        })
        .required("Start date and time is required"),
      ends_at: Yup.date()
        .when("btn", {
          is: "Add",
          then: Yup.date().min(
            new Date(),
            "End date and time must be greater than current date and time"
          ),
        })
        .min(
          Yup.ref("starts_at"),
          "End date and time can't be equal or before start date time"
        )
        .required("End date and time is required")
        .test(
          "is-less-than",
          "Course must be of minimum 15 minutes",
          function () {
            const { starts_at, ends_at } = this.parent;

            const date1 = new Date(
              moment(starts_at).format("MM-DD-YYYY HH:mm:ss")
            );
            const date2 = new Date(
              moment(ends_at).format("MM-DD-YYYY HH:mm:ss")
            );
            const diffInMs = Math.abs(date2 - date1);
            const diff = diffInMs / (1000 * 60);

            return diff < 15 ? false : true;
          }
        )
        .test(
          "is-greater-than",
          "Course must be of maximum 6 hours",
          function () {
            const { starts_at, ends_at } = this.parent;

            const date1 = new Date(
              moment(starts_at).format("MM-DD-YYYY HH:mm:ss")
            );
            const date2 = new Date(
              moment(ends_at).format("MM-DD-YYYY HH:mm:ss")
            );
            const diffInMs = Math.abs(date2 - date1);
            const diff = diffInMs / (1000 * 60);

            return diff > 360 ? false : true;
          }
        ),
      allow_booking_post_commencement: Yup.mixed()
        .required("Allow booking till is required")
        .test("is-zero", "Allow booking till must not be 0", function () {
          const { allow_booking_post_commencement, type } = this.parent;
          if (type === "Trial") {
            return parseInt(allow_booking_post_commencement) > 0;
          }
          return true;
        })
        .test(
          "is-greater-than",
          "Allow booking till must be less than event duration",
          function () {
            const {
              allow_booking_post_commencement,
              starts_at,
              ends_at,
              type,
            } = this.parent;
            const date1 = new Date(
              moment(starts_at).format("MM-DD-YYYY HH:mm:ss")
            );
            const date2 = new Date(
              moment(ends_at).format("MM-DD-YYYY HH:mm:ss")
            );
            const diffInMs = Math.abs(date2 - date1);
            const diff = diffInMs / (1000 * 60);

            if (type === "Trial") {
              return diff > parseInt(allow_booking_post_commencement);
            }
            return true;
          }
        ),
      allow_seats_increment: Yup.mixed()
        .typeError("Seat increment till is required")
        .required("Seat increment till is required")
        .test("is-zero", "Seat increment till must not be 0", function () {
          const { allow_seats_increment, type } = this.parent;
          if (type === "Trial") {
            return parseInt(allow_seats_increment) > 0;
          }
          return true;
        })
        .test(
          "is-greater-than",
          "Allow booking till must be greater than Seat increment till",
          function () {
            const {
              allow_booking_post_commencement,
              allow_seats_increment,
              type,
            } = this.parent;
            if (type === "Trial") {
              return (
                parseInt(allow_booking_post_commencement) >
                parseInt(allow_seats_increment)
              );
            }
            return true;
          }
        ),
      exceeds_percentage: Yup.number()
        .typeError("Threshold % for changing Displayed Seats Count")
        .required("Threshold % for changing Displayed Seats Count")
        .min(1, "This field should have non zero value.")
        .test(
          "is-less-than",
          "Value should be less than 100",
          function (exceeds_percentage) {
            return exceeds_percentage <= 100;
          }
        ),
      increment_percentage: Yup.number()
        .typeError("% for updating new Displayed Seats Count is required")
        .required("% for updating new Displayed Seats Count is required")
        .min(1, "This field should have non zero value.")
        .test(
          "is-less-than",
          "Value should be less than 100",
          function (increment_percentage) {
            return increment_percentage <= 100;
          }
        ),

      // pre_event_file: Yup.array().required("File is required"),
    })
  ),
});
