import React from "react";
import Modals from "../../../../../components/modal";
import DeleteConfirmation from "../../../../../components/delete-confirmation";
import { useDeleteBlog } from "./hooks";
import ActionButton from "../../../../../components/action-button";

const DeleteBlog = ({ blog }) => {
  const { handleDeleteBlog, isOpenModal, toggleModal, deleteBlogLoading } =
    useDeleteBlog(blog);

  return (
    <>
      <ActionButton
        title="Delete"
        className="pull-right"
        onClick={toggleModal}
        iconClassName={`fa fa-trash icon-btn-action text-danger `}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeleteBlog}
              isLoading={deleteBlogLoading}
            />
          }
          title="Delete Blog"
        />
      ) : null}
    </>
  );
};

export default DeleteBlog;
