import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";

import {
  addScheduleEvent,
  getEventList,
  getScheduleEventList,
} from "../actions";
import {
  getScheduleEventsFilter,
  getUsersSelector,
  getEventsSelector,
  getEventsFilterSelector,
} from "../selectors";
import { clearFiles } from "../../../utils/upload-file/actions";
import { snackbarTypes } from "../../../constants";
import { showSnackbar } from "../../../components/snackbar/actions";
import { clearLanguage } from "../../../utils/auth";

export const useAddScheduleEvent = () => {
  const dispatch = useDispatch();
  const events = useSelector(getEventsSelector);
  const users = useSelector(getUsersSelector);
  const filter = useSelector(getScheduleEventsFilter);
  const eventsFilter = useSelector(getEventsFilterSelector);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [addScheduleEventLoading, setAddScheduleEventLoading] = useState(false);
  const files = useSelector(({ fileReducer }) => fileReducer.files);

  useEffect(() => {
    (async () => {
      isOpenModal && (await dispatch(getEventList(eventsFilter)));
    })();
  }, [eventsFilter, isOpenModal, dispatch]);

  const handleAddScheduleEvent = async (values) => {
    const { requirements } = values;
    let error = false;
    if (!values.is_course) {
      Object.keys(requirements).forEach((key) => {
        if (requirements[key].length) {
          requirements[key].forEach((val) => {
            const { title, description } = val;
            if (!title || !description) {
              dispatch(
                showSnackbar({
                  message: "Enter valid title or decription for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }
          });
        }
      });
    } else {
      Object.keys(requirements).forEach((key) => {
        if (requirements[key].length) {
          requirements[key].forEach((val) => {
            const { title, description } = val;
            if (!title) {
              dispatch(
                showSnackbar({
                  message: "Enter valid title or decription for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }

            description.forEach((desc) => {
              !desc &&
                dispatch(
                  showSnackbar({
                    message: "Enter valid  decription for requirements",
                    type: snackbarTypes.ERROR,
                  })
                );
            });
            error = true;
            return;
          });
        }
      });
    }

    if (!error) {
      setAddScheduleEventLoading(true);
      const { startsAt, endsAt, dates } = values;

      const startMins = startsAt.getHours() * 60 + startsAt.getMinutes();
      const endMins = endsAt.getHours() * 60 + endsAt.getMinutes();

      const diff =
        endMins >= startMins ? endMins - startMins : 1440 - startMins + endMins; //1440 === 24 * 60 (minutes in a day)

      const timing = {
        hour: startsAt.getHours(),
        minute: startsAt.getMinutes(),
        second: "00",
      };
      const timings = dates.map((date) => {
        const dt = new Date(date);

        return {
          starts_at: new Date(moment(dt).set(timing)).toString(),
          ends_at: new Date(
            moment(dt).set(timing).add(diff, "minutes")
          ).toString(),
          allow_booking_post_commencement: new Date(
            moment(dt)
              .set(timing)
              .add(parseInt(values.allow_booking_post_commencement), "minutes")
          ).toString(),
          allow_seats_increment: new Date(
            moment(dt)
              .set(timing)
              .add(parseInt(values.allow_seats_increment), "minutes")
          ).toString(),
        };
      });

      const payload = {
        event_schedule: {
          event_id: values.event_id.id,
          timings,
          repeat: values.repeat,
          status: values.status,
          language: values.language,
          mode: values.mode,
          attendees_limit: parseInt(values.attendeesLimit),
          current_attendees_limit: parseInt(values.current_attendees_limit),
          exceeds_percentage: parseInt(values.exceeds_percentage),
          increment_percentage: parseInt(values.increment_percentage),
          requirements: values.requirements,
          pre_event_file_cta: values.is_attachment ? values.cta_title : "",
          is_free: values?.type === "Free",
          type: values?.eventType,
          allow_discussions: values.allow_discussions,
          broadcast_mins: parseInt(
            values.broadcast_mins ? values.broadcast_mins : 0
          ),
          after_popup_broadcast_mins: parseInt(
            values.after_popup_broadcast_mins
              ? values.after_popup_broadcast_mins
              : 0
          ),
          max_join_broadcast_attempts: parseInt(
            values.max_join_broadcast_attempts
              ? values.max_join_broadcast_attempts
              : 0
          ),
          broadcast_platform: values.streaming_type === "Yes" ? "Youtube" : "",
        },
        pre_event_file: values.is_attachment ? files[0] : {},
        host_ids: [values.hostIds.id],
        cohost_ids: values.cohostIds.map((cohost) => cohost.id),
      };

      const is_success = await dispatch(addScheduleEvent(payload));
      setAddScheduleEventLoading(false);
      if (is_success) {
        dispatch(getScheduleEventList(filter));
        toggleModal();
      }
    }
  };

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    addScheduleEventLoading,
    handleAddScheduleEvent,
    isOpenModal,
    toggleModal,
    events,
    users,
  };
};
