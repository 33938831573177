export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 50,
  },
  {
    Header: "Commence At",
    accessor: "commence_at",
    disableGlobalFilter: true,
    // width: 60,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    // width: 70,
  },
];

export const fieldNames = {
  event_id: "event_id",
  mode: "mode",
  language: "language",
  commence_at: "commence_at",
  per_session_cost: "per_session_cost",
  status: "status",
  hostIds: "hostIds",
  cohostIds: "cohostIds",
  starred: "starred",
  allow_booking_post_commencement: "allow_booking_post_commencement",
  requirements: "requirements",
  event_schedules: "event_schedules",
  title: "title",
  attendees_limit: "attendees_limit",
  current_attendees_limit: "current_attendees_limit",
  exceeds_percentage: "exceeds_percentage",
  increment_percentage: "increment_percentage",
  allow_seats_increment: "allow_seats_increment",
  description: "description",
  pre_event_file_cta: "pre_event_file_cta",
  pre_event_file: "pre_event_file",
  type: "type",
  note: "note",
  form_language: "form_language",
};

export const statusOptions = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
  {
    id: 3,
    value: "Cancelled",
    displayText: "Cancelled",
  },
];

export const statusOptionsPublished = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
];

export const statusOptionsCancelled = [
  {
    id: 1,
    value: "Cancelled",
    displayText: "Cancelled",
  },
];

export const typeOptions = [
  {
    id: 1,
    value: "Trial",
    displayText: "Trial",
  },
  {
    id: 2,
    value: "Regular",
    displayText: "Regular",
  },
];

export const starredOptions = [
  {
    id: 1,
    value: "New",
    displayText: "New",
  },
  {
    id: 2,
    value: "Bestseller",
    displayText: "Bestseller",
  },
  {
    id: 3,
    value: "Featured",
    displayText: "Featured",
  },
];

export const columnsScheduleList = [
  {
    Header: "ID",
    accessor: "event_schedule_id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Type",
    accessor: "type",
    disableGlobalFilter: true,
    width: 60,
  },

  {
    Header: "Starts At",
    accessor: "starts_at",
    disableGlobalFilter: true,
    // width: 60,
  },
  {
    Header: "Ends At",
    accessor: "ends_at",
    disableGlobalFilter: true,
    // width: 60,
  },
  {
    Header: "Optimum Batch size",
    accessor: "attendees_limit",
    disableGlobalFilter: true,
  },

  {
    Header: "Displayed Seats count",
    accessor: "current_attendees_limit",
    disableGlobalFilter: true,
  },
  {
    Header: "Booked Seats",
    accessor: "booked",
    disableGlobalFilter: true,
  },

  {
    Header: "Last Date and Time to book seats",
    accessor: "allow_booking_post_commencement",
    disableGlobalFilter: true,
  },
  {
    Header: "Last Date and Time to Increase Displayed Seat Count",
    accessor: "allow_seats_increment",
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    // width: 60,
  },
];

export const scheduleEventReviewStatus = {
  Published: "Published",
  Unpublished: "Unpublished",
};

export const ExportFieldsName = {
  startsAt: "startsAt",
  endsAt: "endsAt",
  status: "status",
};

export const scheduleEventExportStatus = [
  {
    id: 1,
    value: "All",
    displayText: "All",
  },
  {
    id: 2,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 3,
    value: "Draft",
    displayText: "Draft",
  },
  {
    id: 4,
    value: "Cancelled",
    displayText: "Cancelled",
  },
];

export const fieldInfo = {
  attendees_limit: "Batch size as per Moderator/ Instructor",
  current_attendees_limit:
    "Count of seats available - displayed to user on App/ Web",
  exceeds_percentage: `When booked seats is XX % of displayed seats, then displayed seats count will be updated`,
  increment_percentage: `% of (optimum batch count - displayed seat count)`,
  allow_booking_post_commencement:
    "User cannot book seats after this date and time",
  allow_seats_increment:
    "Seat increment cannot be done after this date and time",
  allow_booking_till:
    "User can book event only till Nth minute within the session",
  seat_increment_till:
    "Moderator can increase seats only till Zth minute of the session",
};
