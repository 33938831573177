import React from "react";
import { Button } from "reactstrap";

import FaqForm from "../form/faq-form";
import { useAddFaq } from "./hooks";
import Modals from "../../../components/modal";

const AddFaq = (props) => {
  const {
    addFaqLoading,
    handleAddFaq,
    isOpenModal,
    toggleModal,
    categories,
    handleFetchCategory
  } = useAddFaq(props);
  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={handleFetchCategory}
      >
        <i className="fa fa-plus"></i> Add FAQ
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <FaqForm
              handleFaqForm={handleAddFaq}
              btn="Add"
              isLoading={addFaqLoading}
              categoryList={categories}
            />
          }
          title="Add FAQ"
        />
      ) : null}
    </div>
  );
};

export default AddFaq;
