import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPopularKeyword, getPopularKeywordList } from "../actions";
import { getPopularKeywordFilter } from "../selectors";

export const useAddPopularKeyword = () => {
  const dispatch = useDispatch();
  const [AddPopularKeywordLoading, setAddPopularKeywordLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const filters = useSelector(getPopularKeywordFilter)

  const handleAddPopularKeyword = async (values) => {
    try {
      setAddPopularKeywordLoading(true);
      const payload = {
        title: values.title,
        status: values.status,
      };
      setAddPopularKeywordLoading(false);
      const is_success = await dispatch(addPopularKeyword(payload));
      setAddPopularKeywordLoading(false);
      if (is_success) {
        dispatch(getPopularKeywordList(filters))
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    AddPopularKeywordLoading,
    handleAddPopularKeyword,
    toggleModal,
    isOpenModal
  };
};
