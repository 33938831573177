import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../components/modal";
import DeleteConfirmation from "../../../components/delete-confirmation";
import { useDeleteScheduleEvent } from "./hooks";
import { useUser } from "../../../common-hooks/user";

const DeleteScheduleEvent = ({ scheduleEvent, isActionDisable }) => {
  const {
    deleteScheduleEventLoading,
    handleDeleteScheduleEvent,
    isOpenModal,
    toggleModal,
  } = useDeleteScheduleEvent(scheduleEvent);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Tooltip title="Delete" placement="bottom">
        <i
          className={
            "fa fa-trash icon-btn-action text-danger " +
            (isActionDisable ? " icon-btn-action-disable" : null)
          }
          onClick={toggleModal}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeleteScheduleEvent}
              isLoading={deleteScheduleEventLoading}
            />
          }
          title="Delete Schedule Event"
        />
      ) : null}
    </>
  );
};

export default DeleteScheduleEvent;
