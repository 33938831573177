import { useState, useEffect } from "react";
import { initialLanguageFieldValue } from "../../../../../constants";
import {
  sectionFieldsName,
  modelBlogOptions,
  modelEventOptions,
  modelCategoryOptions,
  allOperatorOptions,
  onlyEqualOperatorOption,
  modelVideoOptions,
} from "../constant";

const formField = {
  title: initialLanguageFieldValue,
  type: "",
  status: "",
  model: "Blog",
  criteria: [{ field: "id", op: "in", value: "" }],
};

const { criteria } = sectionFieldsName;

export const useSectionForm = ({
  fieldValues,
  handleSectionForm,
  btn,
  isLoading,
  handleLanguageData,
}) => {
  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const [modelOptionData, setModelOptionData] = useState(modelBlogOptions);
  const [criteriaDynamicData, setCriteriaDynamicData] = useState([
    {
      operatorOptions: allOperatorOptions,
      placeHolder: "Ex. 1,2,3,4,5",
      isDisabled: false,
    },
  ]);

  useEffect(() => {
    if (btn === "Save") {
      let criteriaData = [];
      formValues.criteria.forEach((ctr, index) => {
        switch (ctr.field) {
          case "id":
            criteriaData[index] = {
              operatorOptions: allOperatorOptions,
              placeHolder: "Ex. 1,2,3,4,5",
              isDisabled: false,
            };
            break;
          case "published_at":
            criteriaData[index] = {
              operatorOptions: onlyEqualOperatorOption,
              placeHolder: "Ex. DD/MM/YYYY",
              isDisabled: false,
            };
            break;
          case "is_popular":
            criteriaData[index] = {
              operatorOptions: onlyEqualOperatorOption,
              placeHolder: "Yes / No",
              isDisabled: true,
            };
            break;
          case "related_event":
            criteriaData[index] = {
              operatorOptions: onlyEqualOperatorOption,
              placeHolder: "Ex. 0",
              isDisabled: true,
            };
            break;
          case "related_blog":
            criteriaData[index] = {
              operatorOptions: onlyEqualOperatorOption,
              placeHolder: "Ex. 0",
              isDisabled: true,
            };
            break;
          case "user_interest":
            criteriaData[index] = {
              operatorOptions: onlyEqualOperatorOption,
              placeHolder: "Ex. 0",
              isDisabled: true,
            };
            break;
          case "category_id":
            criteriaData[index] = {
              operatorOptions: allOperatorOptions,
              placeHolder: "Ex. 1,2,3,4,5",
              isDisabled: false,
            };
            break;
          case "exclude_id":
            criteriaData[index] = {
              operatorOptions: allOperatorOptions,
              placeHolder: "Ex. 1,2,3,4,5",
              isDisabled: false,
            };
            break;
          default:
        }
      });
      setCriteriaDynamicData(criteriaData);
    }
    switch (formValues.model) {
      case "Blog":
        setModelOptionData(modelBlogOptions);
        break;
      case "Event":
        setModelOptionData(modelEventOptions);
        break;
      case "Category":
        setModelOptionData(modelCategoryOptions);
        break;
      case "Video":
        setModelOptionData(modelVideoOptions);
        break;
      default:
        setModelOptionData(modelBlogOptions);
    }
  }, [btn, formValues.criteria, formValues.model]);

  const handleFormUpdate = (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));
  };

  //to update only criteria object field values
  const handleFormUpdateCriteria = (key, value, index) => {
    let newArr = [...formValues.criteria];
    newArr[index][key] = value;
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      criteria: newArr,
    }));
  };

  // handle click event of the Remove button
  const handleRemoveClickCriteria = (rest, setFieldValue, index) => {
    const list = [...rest.values.criteria];
    list.splice(index, 1);
    setFieldValue(criteria, list);
    handleFormUpdate(criteria, list);
  };

  // handle click event of the Add button
  const handleAddClickCriteria = (rest, setFieldValue) => {
    let criteriaValues;
    let criteriaData;
    switch (rest.values.model) {
      case "Blog":
        criteriaValues = [
          ...rest?.values?.criteria,
          { field: "id", op: "in", value: "" },
        ];
        criteriaData = [
          ...criteriaDynamicData,
          {
            operatorOptions: allOperatorOptions,
            placeHolder: "Ex. 1,2,3,4,5",
            isDisabled: false,
          },
        ];
        break;
      case "Category":
        criteriaValues = [
          ...rest?.values?.criteria,
          { field: "id", op: "in", value: "" },
        ];
        criteriaData = [
          ...criteriaDynamicData,
          {
            operatorOptions: allOperatorOptions,
            placeHolder: "Ex. 1,2,3,4,5",
            isDisabled: false,
          },
        ];
        break;
      case "Event":
        criteriaValues = [
          ...rest?.values?.criteria,
          { field: "related_event", op: "equals", value: "0" },
        ];
        criteriaData = [
          ...criteriaDynamicData,
          {
            operatorOptions: onlyEqualOperatorOption,
            placeHolder: "Ex. 0",
            isDisabled: true,
          },
        ];
        break;

      case "Video":
        criteriaValues = [
          ...rest?.values?.criteria,
          { field: "id", op: "in", value: "" },
        ];
        criteriaData = [
          ...criteriaDynamicData,
          {
            operatorOptions: allOperatorOptions,
            placeHolder: "Ex. 1,2,3,4,5",
            isDisabled: false,
          },
        ];
        break;
      default:
    }
    setCriteriaDynamicData(criteriaData);
    setFieldValue(criteria, criteriaValues);
    handleFormUpdate(criteria, criteriaValues);
  };

  const handleModelValueChange = (setFieldValue, model) => {
    let criteriaValues;
    switch (model) {
      case "Blog":
        criteriaValues = [{ field: "id", op: "in", value: "" }];
        setModelOptionData(modelBlogOptions);
        setCriteriaDynamicData([
          {
            operatorOptions: allOperatorOptions,
            placeHolder: "Ex. 1,2,3,4,5",
            isDisabled: false,
          },
        ]);
        break;
      case "Event":
        criteriaValues = [{ field: "related_event", op: "equals", value: "0" }];
        setModelOptionData(modelEventOptions);
        setCriteriaDynamicData([
          {
            operatorOptions: onlyEqualOperatorOption,
            placeHolder: "Ex. 0",
            isDisabled: true,
          },
        ]);
        break;
      case "Category":
        criteriaValues = [{ field: "id", op: "in", value: "" }];
        setModelOptionData(modelCategoryOptions);
        setCriteriaDynamicData([
          {
            operatorOptions: allOperatorOptions,
            placeHolder: "Ex. 1,2,3,4,5",
            isDisabled: false,
          },
        ]);
        break;

      case "Video":
        criteriaValues = [{ field: "id", op: "in", value: "" }];
        setModelOptionData(modelVideoOptions);
        setCriteriaDynamicData([
          {
            operatorOptions: allOperatorOptions,
            placeHolder: "Ex. 1,2,3,4,5",
            isDisabled: false,
          },
        ]);
        break;
      default:
        criteriaValues = [{ field: "", op: "", value: "" }];
        setModelOptionData(modelBlogOptions);
        setCriteriaDynamicData([
          {
            operatorOptions: allOperatorOptions,
            placeHolder: "Ex. 1,2,3,4,5",
            isDisabled: false,
          },
        ]);
    }

    setFieldValue(criteria, criteriaValues);
    handleFormUpdate(criteria, criteriaValues);
  };

  const handleFieldValueChange = (index, field) => {
    let criteriaValues = [...criteriaDynamicData];
    let criteriaArr = [...formValues.criteria];

    switch (field) {
      case "id":
        criteriaValues[index] = {
          operatorOptions: allOperatorOptions,
          placeHolder: "Ex. 1,2,3,4,5",
          isDisabled: false,
        };
        criteriaArr[index] = { field: field, op: "in", value: "" };
        break;
      case "published_at":
        criteriaValues[index] = {
          operatorOptions: onlyEqualOperatorOption,
          placeHolder: "Ex. DD/MM/YYYY",
          isDisabled: false,
        };
        criteriaArr[index] = { field: field, op: "equals", value: "" };
        break;
      case "is_popular":
        criteriaValues[index] = {
          operatorOptions: onlyEqualOperatorOption,
          placeHolder: "Yes / No",
          isDisabled: true,
        };
        criteriaArr[index] = { field: field, op: "equals", value: "true" };
        break;
      case "related_event":
        criteriaValues[index] = {
          operatorOptions: onlyEqualOperatorOption,
          placeHolder: "Ex. 0",
          isDisabled: true,
        };
        criteriaArr[index] = { field: field, op: "equals", value: "0" };
        break;
      case "related_blog":
        criteriaValues[index] = {
          operatorOptions: onlyEqualOperatorOption,
          placeHolder: "Ex. 0",
          isDisabled: true,
        };
        criteriaArr[index] = { field: field, op: "equals", value: "0" };
        break;
      case "user_interest":
        criteriaValues[index] = {
          operatorOptions: onlyEqualOperatorOption,
          placeHolder: "Ex. 0",
          isDisabled: true,
        };
        criteriaArr[index] = { field: field, op: "equals", value: "0" };
        break;
      case "category_id":
        criteriaValues[index] = {
          operatorOptions: allOperatorOptions,
          placeHolder: "Ex. 1,2,3,4,5",
          isDisabled: false,
        };
        criteriaArr[index] = { field: field, op: "in", value: "" };
        break;
      default:
    }
    setCriteriaDynamicData(criteriaValues);
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      criteria: criteriaArr,
    }));
  };

  const handleOperatorValueChange = (index, operator) => {
    let criteriaValues = [...criteriaDynamicData];
    switch (operator) {
      case "in":
        criteriaValues[index].placeHolder = "Ex. 1,2,3,4,5";
        setCriteriaDynamicData(criteriaValues);
        break;
      case "equals":
        criteriaValues[index].placeHolder = "Ex. 1";
        setCriteriaDynamicData(criteriaValues);
        break;
      default:
    }
  };

  const handleLanguage = (key, language, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: {
        ...prevFieldValue[key],
        [language]: value,
      },
    }));
  };

  return {
    formValues,
    handleFormUpdate,
    handleFormUpdateCriteria,
    handleRemoveClickCriteria,
    handleAddClickCriteria,
    handleModelValueChange,
    handleFieldValueChange,
    handleOperatorValueChange,
    modelOptionData,
    handleSectionForm,
    btn,
    isLoading,
    criteriaDynamicData,
    setFormvalues,
    handleLanguage,
    handleLanguageData,
  };
};
