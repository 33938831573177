export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
  },

  {
    Header: "Title",
    accessor: "title",
  },

  {
    Header: "Sent To",
    accessor: "sent_to",
  },

  {
    Header: "Sent At",
    accessor: "sent_at",
  },
  {
    Header: "Actions",
    accessor: "action",
    width: 80,
  },
];

export const bookingStatus = [
  {
    id: 1,
    value: "Booked",
    displayText: "Booked",
  },
  {
    id: 2,
    value: "Cancelled",
    displayText: "Cancelled",
  },
  {
    id: 3,
    value: "Attended",
    displayText: "Attended",
  },
];

export const noticeStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },

  {
    id: 3,
    value: "Unpublished",
    displayText: "Unpublished",
  },
];

export const trackable_type = [
  {
    id: 1,
    value: "General",
    displayText: "General",
  },
  {
    id: 2,
    value: "Event Schedule",
    displayText: "Event Schedule",
  },
];

export const fieldsName = {
  title: "title",
  description: "description",
  booking_status: "booking_status",
  status: "status",
  type: "type",
  event_id: "event_id",
  event_status: "event_status",
  event_date: "event_date",
  redirect_to: "redirect_to",
  short_description: "short_description",
  language: "language",
  blog_id: "blog_id",
};

export const scheduleEventStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Cancelled",
    displayText: "Cancelled",
  },
];

export const noticeFilterStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
  {
    id: 3,
    value: "Draft",
    displayText: "Draft",
  },
];

export const landingPageOptions = [
  {
    id: 1,
    value: "Home",
    displayText: "Home Page",
  },
  {
    id: 2,
    value: "MySessions",
    displayText: "My Session Page",
  },
  {
    id: 3,
    value: "Profile",
    displayText: "My Profile Page",
  },

  {
    id: 4,
    value: "Events",
    displayText: "Event Calendar Page",
  },
  {
    id: 5,
    value: "Notifications",
    displayText: "Notice Board Page",
  },
  {
    id: 6,
    value: "Blogs",
    displayText: "Blog Page",
  },
  {
    id: 7,
    value: "BlogDetail",
    displayText: "Blog Details Page",
  },
  {
    id: 8,
    value: "MyPlan",
    displayText: "My Plan Page",
  },
  {
    id: 9,
    value: "EventDetail",
    displayText: "Event Detail",
  },
  {
    id: 10,
    value: "MyNotes",
    displayText: "My Notes Page",
  },
  {
    id: 11,
    value: "MyCurriculam",
    displayText: "My Curriculam Page",
  },
  {
    id: 12,
    value: "MyLanguage",
    displayText: "My Language Page",
  },

  {
    id: 13,
    value: "Referral",
    displayText: "My Referral Page",
  },
  {
    id: 14,
    value: "MyInterest",
    displayText: "My Interest Page",
  },
  {
    id: 15,
    value: "Help",
    displayText: "Help Page",
  },
  {
    id: 16,
    value: "PlansAndTopUp",
    displayText: "Subscription Plans & TopUps",
  },
  {
    id: 17,
    value: "AllOffers",
    displayText: "All Offers",
  },
  {
    id: 18,
    value: "MyOffers",
    displayText: "My Offers",
  },
  {
    id: 19,
    value: "MyCourses",
    displayText: "My Courses",
  },
];

export const limit = {
  title_limit: 40,
  short_description_limit: 150,
  description_limit: 250,
};
