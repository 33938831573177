import { SET_REPORTS_FILTERS, SET_REPORTS_LIST } from "./actions";

const initialList = {
  reports: [],
  count: 0,
  filters: {
    limit: 10,
    skip: 0,
  },
};

const reportsReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_REPORTS_LIST:
      return {
        ...state,
        reports: action.payload.reports,
        count: action.payload.count || [],
      };

    case SET_REPORTS_FILTERS: {
      const { key, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [key]: value,
        },
      };
    }

    default:
      return state;
  }
};

export default reportsReducer;
