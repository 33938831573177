import {
  SET_COURSE_LIST,
  SET_COURSE_FILTER,
  CLEAR_COURSE_FILTER,
  SET_SCHEDULE_EVENT_REVIEWS,
  RESET_COURSE_FILTER,
} from "./actions";

import * as moment from "moment";

const initialState = {
  events: [],
  count: 0,
  filter: {
    id: null,
    title: "",
    status: "Published",
    limit: 10,
    skip: 0,
    commence_after: moment(new Date()).format("DD/MM/YYYY @ hh:mm A "),
    commence_before: "",
    published_at: {
      from: "",
      to: "",
    },
    utc_offset: moment().utcOffset(),
  },
  common_filter: { text: "", limit: 0, skip: 0 },
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_LIST:
      return {
        ...state,
        courses: action.payload.courses || [],
        count: action.payload.count || 0,
      };
    case SET_COURSE_FILTER: {
      const { key, value } = action.payload;
      if (key === "id") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value ? parseInt(value) : null,
          },
        };
      }
      if (key === "published_from") {
        return {
          ...state,
          filter: {
            ...state.filter,
            published_at: {
              ...state.filter.published_at,
              from: value,
            },
          },
        };
      }
      if (key === "published_to") {
        return {
          ...state,
          filter: {
            ...state.filter,
            published_at: {
              ...state.filter.published_at,
              to: value,
            },
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case RESET_COURSE_FILTER:
      return { ...state, filter: initialState.filter };

    case CLEAR_COURSE_FILTER:
      return {
        ...state,
        filter: {
          ...initialState.filter,
          commence_after: "",
        },
      };

    case SET_SCHEDULE_EVENT_REVIEWS:
      return {
        ...state,
        scheduleEventsReview: action.payload,
      };
    default:
      return state;
  }
};

export default courseReducer;
