import React from 'react';
import {useSelector} from 'react-redux';
import LoginForm from './login-form';
import OtpForm from './otp-form';

const Login = () => {
  const isOtpGenerated = useSelector(({authReducer}) => authReducer.isOtpGenerated);
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              Welcome to
              <span className="account__logo">
                {" "}
                Evergreen{" "}
                <span className="account__logo-sucess">Club</span>
              </span>
            </h3>
            <h4 className="account__subhead subhead">Admin Dashboard</h4>
          </div>
          {isOtpGenerated ? <OtpForm /> : <LoginForm />}
        </div>
      </div>
    </div>
  );
};

export default Login;
