import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addPostReaction, DeletePostReaction } from "../../action";

export const useReaction = (props) => {
  const {
    trackableType,
    trackableId,
    onReact,
    reactionName,
    onReactSuccess,
    onRemoveReactSuccess,
    isEgReaction,
    disabled,
  } = props;
  const [reactionPopover, setReactionPopover] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState("");
  const [reactionAnim, setReactionAnim] = useState(false);
  const [reactionId, setReactionId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.reactionId) {
      setReactionId(props.reactionId);
      setSelectedReaction(reactionName);
    }
  }, [props.reactionId, reactionName]);

  const addReaction = async (reactionType) => {
    let payload = {
      trackable_type: trackableType,
      trackable_id: trackableId,
      name: reactionType,
      on_behalf_of: isEgReaction ? "EvergreenClub" : "",
    };

    const data = await dispatch(addPostReaction(payload));
    if (data?.is_success) {
      setSelectedReaction(data?.reaction?.name);
      setReactionId(data?.reaction?.id);
      setReactionAnim(true);
      if (onReactSuccess) {
        onReactSuccess(data?.reaction, isEgReaction);
      }
      if (data?.data?.reaction?.id) {
        setReactionId(data?.data?.reaction?.id);
      }
      if (onReact) {
        onReact(1);
      }
      setTimeout(() => {
        setReactionAnim(false);
      }, 2000);
    }
  };

  const deleteReaction = async (id) => {
    const data = await dispatch(DeletePostReaction(id));
    if (data?.is_success) {
      setSelectedReaction("");
      if (onRemoveReactSuccess) {
        onRemoveReactSuccess(id, selectedReaction, isEgReaction);
      }
      if (onReact) {
        onReact(-1);
      }
    } else {
      return false;
    }
  };

  const togglePopover = () => {
    !disabled && setReactionPopover(!reactionPopover);
  };

  const handleReactionSelect = (reaction) => {
    if (selectedReaction === reaction) {
      // setSelectedReaction('');
      deleteReaction(reactionId);
    } else {
      // setSelectedReaction(reaction);
      addReaction(reaction);
    }
    setReactionPopover(false);
  };

  return {
    reactionPopover,
    togglePopover,
    selectedReaction,
    handleReactionSelect,
    reactionAnim,
    trackableType,
    isEgReaction,
  };
};
