import {
  RESET_UNLOCKED_OFFER_FILTER,
  SET_UNLOCKED_OFFER_FILTER,
  SET_UNLOCKED_OFFER_LIST,
} from "./actions";

const initialList = {
  unlocked_offers: [],
  count: 0,
  filter: {
    user_id: null,
    offer_text: "",
    partner_name: "",
    partner_id: null,
    offer_id: null,
    limit: 10,
    skip: 0,
  },
};

const unlockedOfferReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_UNLOCKED_OFFER_LIST:
      return {
        ...state,
        unlocked_offers: action.payload.coupon_codes,
        count: action.payload.count || [],
      };

    case SET_UNLOCKED_OFFER_FILTER: {
      const { key, value } = action.payload;
      if (key === "user_id" || key === "partner_id" || key === "offer_id") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value ? parseInt(value) : null,
            skip: 0,
          },
        };
      }

      if (key !== "skip") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value,
            skip: 0,
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }

    case RESET_UNLOCKED_OFFER_FILTER:
      return { ...state, filter: initialList.filter || [] };
    default:
      return state;
  }
};

export default unlockedOfferReducer;
