import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteReportOption, getReportOptionsList } from "../actions";
import { getRepotOptionFilter } from "../selectors";

export const useDeleteReportOption = ({ id }) => {
  const dispatch = useDispatch();
  const filter = useSelector(getRepotOptionFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteReportOptionLoading, setDeleteReportOptionLoading] =
    useState(false);

  const handledeleteReportOption = async () => {
    setDeleteReportOptionLoading(true);
    const is_success = await dispatch(deleteReportOption(id));
    setDeleteReportOptionLoading(false);
    if (is_success) {
      dispatch(getReportOptionsList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteReportOptionLoading,
    handledeleteReportOption,
    isOpenModal,
    toggleModal,
  };
};
