import React from "react";
import DataTable from "../../components/table";
import { useNotice } from "./hooks";
import AddNotice from "./add-notice";
import EditNotice from "./edit-notice";
import DeleteNotice from "./delete-notice";
import ChangeNoticeStatus from "./notice-status";
import { columns } from "./constant";
import * as moment from "moment";
import SearchFilter from "../../components/filters/search-filter";
import SelectFilter from "../../components/filters/select-filter";
import { trackable_type, noticeFilterStatus } from "./constant";

const Notices = () => {
  const { notices, noticeCount, filters, loadingNotice, setFilter } =
    useNotice();

  const configureTableData = () => {
    try {
      let results = notices.map((notice) => {
        let newNotice = Object.assign({}, notice);

        const notice_period = Math.floor(
          moment
            .duration(moment(new Date()).diff(moment(notice.created_at)))
            .asHours()
        );

        newNotice["action"] = (
          <div className="categories__list-action">
            {notice_period < 24 && <ChangeNoticeStatus notice={notice} />}
            {notice_period < 24 && <EditNotice notice={notice} />}
            <DeleteNotice notice={notice} />
          </div>
        );

        newNotice["sent_at"] = moment(notice.created_at).format(
          "DD/MM/YYYY @ hh:mm A "
        );

        newNotice["sent_to"] =
          notice.trackable_type !== "Event Schedule" ? (
            "All Users"
          ) : (
            <>
              <p>{notice?.trackable_details?.title}</p>
              <p>
                {moment(notice?.trackable_details?.starts_at).format(
                  "DD/MM/YYYY @ hh:mm A "
                )}
              </p>
              <p>
                {notice?.details?.user_event_schedule_statuses?.map(
                  (status, i) =>
                    `${status}${
                      i <
                      notice?.details?.user_event_schedule_statuses.length - 1
                        ? ", "
                        : ""
                    }`
                )}
              </p>
            </>
          );

        newNotice["status"] = "Booked";

        return newNotice;
      });

      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const noticeFilter = () => (
    <>
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search Notice"
        filterKey="title"
        filterTitle="Title"
        searchedValue={filters?.title}
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.trackable_type}
        filterKey="trackable_type"
        options={trackable_type}
        defaultOption="All Types"
        filterTitle="Types"
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        filterKey="status"
        options={noticeFilterStatus}
        defaultOption="All Statuses"
        filterTitle="Status"
      />
    </>
  );

  return (
    <DataTable
      title="Notices"
      addBtn={<AddNotice />}
      CreateTableData={configureTableData}
      withFilter={true}
      filter={noticeFilter}
      handleSetFilter={setFilter}
      count={noticeCount}
      skip={filters?.skip}
      isLoading={loadingNotice}
    />
  );
};

export default Notices;
