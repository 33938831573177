import React from "react";
import DataTable from "../../components/table";
import AddOffer from "./add-offer";
import { columns, offerStatus } from "./constant";
import SelectFilter from "../../components/filters/select-filter";
import SearchFilter from "../../components/filters/search-filter";
import Tooltip from "@material-ui/core/Tooltip";
import { useOffer } from "./hooks";
import EditOffer from "./edit-offer";
import AddCode from "./add-codes";

function Offers() {
  const { count, resetFilter, setFilter, offers, offerFilter, loadingOffers } =
    useOffer();

  const configureTableData = () => {
    try {
      let results = offers.map((offer) => {
        let newOffer = Object.assign({}, offer);
        newOffer["validity"] = `${offer.validity} days`;
        newOffer["amount"] = offer?.amount || "NA";
        newOffer["total_coupon_codes"] = offer?.stats?.total_coupon_codes;
        newOffer[
          "used_coupon_codes"
        ] = `${offer?.stats?.used_coupon_codes}/${offer?.stats?.total_coupon_codes}`;

        newOffer["partner_name"] = offer?.partner?.name || "NA";

        newOffer["action"] = (
          <div className="d-flex">
            <EditOffer offer={offer} />
            {offer?.is_coupon_code_required && <AddCode offer={offer} />}
          </div>
        );

        return newOffer;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const filter = () => (
    <>
      <SearchFilter
        filterKey="offer_text"
        handleSearch={setFilter}
        filterTitle="Offer Text"
        searchedValue={offerFilter?.offer_text}
        searchPlaceholder="Offer Text"
      />

      <SearchFilter
        filterKey="partner_name"
        handleSearch={setFilter}
        filterTitle="Partner Name"
        searchedValue={offerFilter?.partner_name}
        searchPlaceholder="Partner Name"
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={offerFilter?.status}
        filterKey="status"
        options={offerStatus}
        filterTitle="Status"
        defaultOption="All"
      />
      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={resetFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  return (
    <DataTable
      title="Offers"
      addBtn={<AddOffer />}
      CreateTableData={configureTableData}
      withFilter={true}
      filter={filter}
      handleSetFilter={setFilter}
      count={count}
      skip={offerFilter?.skip}
      isLoading={loadingOffers}
    />
  );
}

export default Offers;
