import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editReportOption, getReportOptionsList } from "../actions";
import { getRepotOptionFilter } from "../selectors";

export const useEditReason = ({ option }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(getRepotOptionFilter);
  const [editReasonLoading, setEditReasonLoading] = useState(false);

  const handleEditReason = async (values) => {
    setEditReasonLoading(true);
    let payload = {
      reason: values?.reason,
    };

    const is_success = await dispatch(editReportOption(payload, option?.id));
    setEditReasonLoading(false);
    if (is_success) {
      dispatch(getReportOptionsList(filters));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    editReasonLoading,
    handleEditReason,
    isOpenModal,
    toggleModal,
  };
};
