import axios from "axios";
import { apiEndPoints, api } from "../../api";

const namespace = "utils/upload-file";

export const SET_FILES = `${namespace}/SET_FILES`;
export const SET_SINGLE_FILE = `${namespace}/SET_SINGLE_FILE`;
export const CLEAR_FILES = `${namespace}/CLEAR_FILES`;
export const DELETE_A_FILE = `${namespace}/DELETE_A_FILE`;
export const CLEAR_FILE_CONTEXT = `${namespace}/CLEAR_FILE_CONTEXT`;
export const UPDATE_FILES = `${namespace}/UPDATE_FILES`;

export const setFiles = (payload) => ({
  type: SET_FILES,
  payload,
});

export const setSingleFile = (payload) => {
  return {
    type: SET_SINGLE_FILE,
    payload,
  };
};

export const clearFiles = () => ({
  type: CLEAR_FILES,
});

export const deleteAFile = (payload) => ({
  type: DELETE_A_FILE,
  payload,
});

export const clearFileContext = (payload) => ({
  type: CLEAR_FILE_CONTEXT,
  payload,
});

export const updateFiles = (payload) => ({
  type: UPDATE_FILES,
  payload,
});

export const getSignedUrl = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.uploadFiles, {
      original_file_name: payload,
    });
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const uploadFiles = (signedUrl, file, fileData, config) => async (dispatch) => {
  try {
    const response = await axios.put(signedUrl, file, config);
    if (response.status === 200) {
      dispatch(setFiles(fileData));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
