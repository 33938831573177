import { useEffect, useRef, useState } from "react";
import { initialLanguageArrayValue } from "../../../constants";
import { fieldsName } from "../constant";
import {
  copyPrivateLink,
  getEvent,
  getPrivateEventUrl,
  getScheduleEventDates,
} from "../actions";
import { useDispatch } from "react-redux";
import moment from "moment";

const { requirements, eventId, is_attachment, allow_discussions } = fieldsName;

const formField = {
  startsAt: "",
  endsAt: "",
  status: "",
  repeat: "Once",
  language: "",
  mode: "Online",
  attendeesLimit: "",
  current_attendees_limit: "",
  exceeds_percentage: 90,
  increment_percentage: 50,
  allow_booking_post_commencement: "",
  allow_seats_increment: "",
  eventId: null,
  hostIds: null,
  cohostIds: [],
  requirements: initialLanguageArrayValue,
  is_attachment: false,
  cta_title: "",
  type: "",
  languages: "en",
  eventType: "Public",
  broadcast_mins: "",
  after_popup_broadcast_mins: "",
  max_join_broadcast_attempts: "",
  streaming_type: "",
  streaming_platform: "",
  allow_discussions: true,
};

export const useEventScheduleForm = ({
  fieldValues,
  handleEventForm,
  btn,
  events,
  users,
  isLoading,
  handleLangugeData,
}) => {
  const dispatch = useDispatch();
  const ref = useRef();
  //to update only Requirements object field values
  const handleFormUpdateRequirements = (rest, key, value, index) => {
    let newRequirements = {
      ...rest.values.requirements,
    };

    newRequirements[rest.values.languages][index][key] = value || "";
    setFormValues((prevFieldValue) => ({
      ...prevFieldValue,
      requirements: newRequirements,
    }));
  };

  const [formValues, setFormValues] = useState(fieldValues || formField);
  const [dateParams, setDateParams] = useState({
    month_at: moment().format("MMMM"),
    year_at: moment().format("YYYY"),
    starts_at: new Date(),
  });
  const [scheduledDates, setSchedulesDates] = useState([]);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  // handle click to remove requirement
  const handleRemoveClickRequirements = (rest, setFieldValue, index) => {
    const list = { ...rest.values.requirements };
    list[rest.values.languages].splice(index, 1);
    setFieldValue(requirements, list);
    handleFormArrayUpdate(requirements, list);
  };

  // handle click to Add new requirement
  const handleAddClickRequirements = (rest, setFieldValue, language) => {
    const list = {
      ...rest?.values?.requirements,
      [language]: [
        ...rest?.values?.requirements[language],
        { title: "", description: "" },
      ],
    };

    setFieldValue(requirements, list);
    handleFormArrayUpdate(requirements, list);
  };

  // handle click event change to change default requirements of event
  const handleEventChange = (value, setFieldValue) => {
    let list = [];
    events.find((event) => {
      if (event.id === value?.id) {
        list = event?.requirements?.length > 0 ? event?.requirements : [];
      }
      return list;
    });
    setFieldValue(requirements, list);
  };

  const handleFormUpdate = (event) => {
    let { name, value } = event.target;
    if (name === is_attachment || name === allow_discussions) {
      value = event.target.checked;
    }
    if (name === "type") {
      if (value === "Paid") {
        setFormValues((prevFieldValue) => ({
          ...prevFieldValue,
          [name]: value,
          streaming_type: "No",
        }));
      } else {
        setFormValues((prevFieldValue) => ({
          ...prevFieldValue,
          [name]: value,
          streaming_type: "Yes",
        }));
      }
    } else
      setFormValues((prevFieldValue) => ({
        ...prevFieldValue,
        [name]: value,
      }));
  };

  const handleFormArrayUpdate = async (key, value) => {
    if (key === eventId) {
      setFormValues((prevFieldValue) => ({
        ...prevFieldValue,
        [key]: value,
      }));
    } else {
      setFormValues((prevFieldValue) => ({
        ...prevFieldValue,
        [key]: value,
      }));
    }
  };

  const updateRequirement = async (event_id, language) => {
    const { event } = event_id
      ? await dispatch(getEvent(event_id?.id))
      : Promise.resolve({});
    if (event) {
      setFormValues((prevFieldValue) => {
        return {
          ...prevFieldValue,
          requirements: {
            ...prevFieldValue.requirements,
            [language]: event.requirements || [],
          },
          attendeesLimit: event.attendees_limit || "",
          current_attendees_limit: event.current_attendees_limit || "",
          exceeds_percentage: event.exceeds_percentage || "",
          increment_percentage: event.increment_percentage || "",
          broadcast_mins: event.broadcast_mins || "",
          after_popup_broadcast_mins: event.after_popup_broadcast_mins || "",
          max_join_broadcast_attempts: event.max_join_broadcast_attempts || "",
        };
      });
    }
  };

  const copyLink = async (id) => {
    const link = await dispatch(getPrivateEventUrl(id));
    if (link) {
      navigator.clipboard.writeText(link);
      dispatch(copyPrivateLink());
    }
  };

  const handleOpenCalendar = () => {
    setIsOpenCalendar(!isOpenCalendar);
    if (isOpenCalendar) {
      setDateParams({
        month_at: moment().format("MMMM"),
        year_at: moment().format("YYYY"),
        starts_at: formValues?.startsAt
          ? moment(formValues?.startsAt).toDate().toString()
          : new Date(),
      });
    }
  };

  const { id = null } = formValues?.event_id || {};

  useEffect(() => {
    const getData = async () => {
      if (isOpenCalendar && id) {
        const { is_success = false, event_schedule_stats = [] } =
          await dispatch(getScheduleEventDates(id, dateParams));

        if (is_success) setSchedulesDates(event_schedule_stats);
      }
    };

    getData();
  }, [isOpenCalendar, dateParams, id, dispatch]);

  return {
    fieldValues,
    handleFormUpdateRequirements,
    handleRemoveClickRequirements,
    handleAddClickRequirements,
    handleEventForm,
    handleEventChange,
    btn,
    events,
    users,
    isLoading,
    handleFormUpdate,
    handleFormArrayUpdate,
    formValues,
    handleLangugeData,
    setFormValues,
    updateRequirement,
    copyLink,
    ref,
    dateParams,
    setDateParams,
    handleOpenCalendar,
    scheduledDates,
  };
};
