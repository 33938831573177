import React from "react";
import { useAddPopularKeyword } from "./hooks";
import PopularKeywordForm from "../form/popular-keyword-form";
import Modals from "../../../../../components/modal";
import ActionButton from "../../../../../components/action-button";

const EditPopularKeyword = ({ popularKeyword }) => {
  const {
    handleEditPopularKeyword,
    editPopularKeywordLoading,
    selectedPopularKeyword,
    getPopularKeywordById,
    toggleModal,
    isOpenModal,
  } = useAddPopularKeyword(popularKeyword);
  return (
    <>
      <ActionButton
        title="Edit"
        iconClassName={`fa fa-edit icon-btn-action `}
        onClick={getPopularKeywordById}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PopularKeywordForm
              fieldValues={selectedPopularKeyword}
              handlePopularKeywordForm={handleEditPopularKeyword}
              btn="Save"
              isLoading={editPopularKeywordLoading}
            />
          }
          title="Edit Popular Keyword"
        />
      ) : null}
    </>
  );
};

export default EditPopularKeyword;
