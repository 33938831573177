import React from "react";
import { Button } from "reactstrap";

import NoticeForm from "../form/notice-form";
import { useAddNotice } from "./hooks";
import Modals from "../../../components/modal";
import { useUser } from "../../../common-hooks/user";

const AddNotice = (props) => {
  const {
    addNoticeLoading,
    handleAddNotice,
    isOpenModal,
    toggleModal,
    events,
  } = useAddNotice(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Add Notice
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <NoticeForm
              handlenoticeForm={handleAddNotice}
              btn="Add"
              isLoading={addNoticeLoading}
              eventList={events}
            />
          }
          title="Add Notice"
        />
      ) : null}
    </div>
  );
};

export default AddNotice;
