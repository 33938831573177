import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField } from "@material-ui/core";
import { cancelFormFieldName } from "../../constant";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { cancelFormSchema } from "./validation-schema";

const CancelEventForm = ({ handleCancelForm, btn, is_refundable, message }) => {
  const {
    focusField,
    handleFocusField,
    handleBlurField,
  } = useHandleFieldFocus();

  const { cancellation_reason } = cancelFormFieldName;

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={cancelFormSchema}
      initialValues={{
        cancellation_reason: "",
      }}
      onSubmit={handleCancelForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          <div
            className={`${!is_refundable ? "text-danger" : "text-success"}`}
            dangerouslySetInnerHTML={{ __html: message }}
          />
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div>
              <TextField
                className="material-form__field"
                multiline
                name={cancellation_reason}
                placeholder="Cancellation Reason"
                label="Cancellation Reason"
                value={rest.values.cancellation_reason}
                onChange={handleFormValueChange(rest, cancellation_reason)}
                onFocus={() => handleFocusField(cancellation_reason)}
                onBlur={() => handleBlurField()}
              ></TextField>

              {focusField === cancellation_reason ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <>
                      <div className="material-form__error">{msg}</div>
                    </>
                  )}
                  name={cancellation_reason}
                />
              )}
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default CancelEventForm;
