import {
  RESET_CODES_FILTER,
  RESET_VOUCHER_FILTER,
  SET_CODES_FILTER,
  SET_CODES_LIST,
  SET_VOUCHER_FILTER,
  SET_VOUCHER_LIST,
} from "./actions";

const initialList = {
  vouchers: [],
  count: 0,
  filter: {
    limit: 10,
    skip: 0,
  },
  codes: [],
  code_count: 0,
  code_filter: {
    limit: 10,
    skip: 0,
  },
};

const voucherReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_VOUCHER_LIST:
      return {
        ...state,
        vouchers: action.payload.coupons,
        count: action.payload.count || [],
      };

    case SET_VOUCHER_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }

    case RESET_VOUCHER_FILTER:
      return { ...state, filter: initialList.filter || [] };

    case SET_CODES_LIST:
      return {
        ...state,
        codes: action.payload.coupon_codes,
        code_count: action.payload.count || [],
      };

    case SET_CODES_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        code_filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }

    case RESET_CODES_FILTER:
      return { ...state, code_filter: initialList.code_filter || [] };

    default:
      return state;
  }
};

export default voucherReducer;
