export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Category Id",
    accessor: "category_id",
    width: 50,
  },
  {
    Header: "Title",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Type",
    accessor: "type",
    disableGlobalFilter: true,
    width: 50,
  },
  {
    Header: "Description",
    accessor: "desc_html",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];

export const eventStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
];

export const typeOptions = [
  {
    id: 1,
    value: "All",
    displayText: "All",
  },
  {
    id: 2,
    value: false,
    displayText: "Event",
  },
  {
    id: 3,
    value: true,
    displayText: "Course",
  },
];

export const fieldsName = {
  category: "category",
  subcategory: "subcategory",
  title: "title",
  description: "description",
  short_description: "short_description",
  status: "status",
  fileIcon: "fileIcon",
  fileBanner: "fileBanner",
  is_popular: "is_popular",
  is_course: "is_course",
  filePopularBanner: "filePopularBanner",
  filePopularBannerWeb: "filePopularBannerWeb",
  requirements: "requirements",
  type: "type",
  videos: "videos",
  video_banners: "video_banners",
  language: "language",
  faq_category: "faq_category",
  slug: "slug",
  attendees_limit: "attendees_limit",
  current_attendees_limit: "current_attendees_limit",
  exceeds_percentage: "exceeds_percentage",
  increment_percentage: "increment_percentage",
  broadcast_mins: "broadcast_mins",
  after_popup_broadcast_mins: "after_popup_broadcast_mins",
  max_join_broadcast_attempts: "max_join_broadcast_attempts",
  meta_title: "meta_title",
  meta_description: "meta_description",
  meta_keywords: "meta_keywords",
};

export const eventType = [
  {
    id: 1,
    value: "Tambola",
    displayText: "Tambola",
  },
  {
    id: 2,
    value: "Other",
    displayText: "Other",
  },
];

export const fieldInfo = {
  attendees_limit: "Batch size as per Moderator/ Instructor",
  current_attendees_limit:
    "Count of seats available - displayed to user on App/ Web",
  exceeds_percentage: `When booked seats is XX % of displayed seats, then displayed seats count will be updated`,
  increment_percentage: `% of (optimum batch count - displayed seat count)`,
  broadcast_mins:
    "User can view live session for the minutes defined. Post this time, user will see the next step",
  after_popup_broadcast_mins:
    "User can re-view live session for the minutes defined. Post this time, user will see the next step ",
  max_join_broadcast_attempts:
    "User cannot see sign up pop-up video after he has viewed it for this count of times",
};
