import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import { fieldsName } from "../constant";
import { DataLoader } from "../../../../../components/loader";
import { formSchema } from "./validation-schema";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import { nameOptions } from "./../constant"
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { usePageForm } from "./hooks"

const PageForm = (props) => {
  const {
    focusField,
    handleFocusField,
    handleBlurField,
  } = useHandleFieldFocus();

  const {
    handlePageForm,
    btn,
    isLoading,
    sectionData,
    formValues,
    handleAddClickSectionList,
    handleFormUpdate,
    handleFormUpdateSectionList,
    handleRemoveClickSectionList,
  } = usePageForm(props);

  const {
    name,
    description,
    sectionList,
    id,
    limit,
  } = fieldsName;
  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={formSchema}
      initialValues={{
        name: formValues?.name || "",
        description: formValues?.description || "",
        sectionList: formValues?.sectionList?.length > 0 ?
          (formValues?.sectionList[0]?.id ||
            formValues?.sectionList[0]?.limit
            ?
            formValues?.sectionList
            :
            [{ id: "", limit: "", position: 0 }]) :
          [{ id: "", limit: "", position: 0 }],
      }}
      onSubmit={handlePageForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div>
              <TextField
                className="material-form__field"
                name={name}
                placeholder="Select Name"
                label="Name"
                select
                value={rest.values.name}
                onChange={(event) => {
                  handleFormValueChange(rest, name);
                  handleFormUpdate(name, event.target.value);
                }}
                onFocus={() => handleFocusField(name)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.name}
              >
                {nameOptions.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === name ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={name}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={description}
                placeholder="Description"
                label="Description"
                type="text"
                multiline
                value={rest.values.description}
                onChange={(event) => {
                  handleFormValueChange(rest, description);
                  handleFormUpdate(description, event.target.value);
                }}
                onFocus={() => handleFocusField(description)}
                onBlur={() => handleBlurField()}
              />
              {focusField === description ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={description}
                />
              )}
            </div>

            <div className="page__container">
              <p className="custom__title_pages">Sections</p>
              {rest?.values?.sectionList.map((x, i) => {
                return (
                  <div key={i} className="matrial-form material-form_inline-fields">
                    <div className="materila-form_inline-child">
                      <Autocomplete
                        options={sectionData}
                        getOptionLabel={(section) =>
                          section.title ? section.title : ""
                        }
                        // getOptionSelected={(section) =>
                        //   rest.values.sectionList[i].id.id === section.id ||
                        //   rest.values.sectionList[i].id === section.id ||
                        //   rest.values.sectionList[i].id === ""
                        // }
                        name={`${sectionList}.${i}.${id}`}
                        value={x?.id}
                        onChange={(event, value) => {
                          setFieldValue(`${sectionList}.${i}.${id}`, value);
                          handleFormUpdateSectionList(id, value, i);
                        }}
                        onFocus={(e) => {
                          const { name } = e.target;
                          handleFocusField(name)
                        }}
                        onBlur={() => handleBlurField()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="material-form__field"
                            name={`${sectionList}.${i}.${id}`}
                            placeholder="Select Section"
                            label="Select Section"
                            variant="standard"
                          />
                        )}
                      />
                      {focusField === `${sectionList}.${i}.${id}` ? null : (
                        <ErrorMessage
                          render={(msg) => (
                            <div className="material-form__error">{msg}</div>
                          )}
                          name={`${sectionList}.${i}.${id}`}
                        />
                      )}
                    </div>

                    <div className="materila-form_inline-child">
                      <TextField
                        className="material-form__field"
                        name={`${sectionList}.${i}.${limit}`}
                        label="Limit"
                        placeholder="Enter Limit"
                        value={x?.limit}
                        type="number"
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFieldValue(name, value);
                          handleFormUpdateSectionList(limit, value, i);
                        }}
                        onFocus={(e) => {
                          const { name } = e.target;
                          handleFocusField(name)
                        }}
                        onBlur={() => handleBlurField()}
                      />
                      {focusField === `${sectionList}.${i}.${limit}` ? null : (
                        <ErrorMessage
                          render={(msg) => (
                            <div className="material-form__error">{msg}</div>
                          )}
                          name={`${sectionList}.${i}.${limit}`}
                        />
                      )}
                    </div>

                    <div className="materila-form_inline-child">
                      {rest.values.sectionList.length !== 1 &&
                        <Tooltip title="Delete" placement="bottom" className="page__container">
                          <i className={`fa fa-trash icon-btn-action text-danger page__pad10`}
                            onClick={() => handleRemoveClickSectionList(rest, setFieldValue, i)}
                          ></i>
                        </Tooltip>
                      }

                      {rest?.values?.sectionList?.length - 1 === i &&
                        <Tooltip title="Add" placement="bottom" className="page__container">
                          <i className={`fa fa-plus icon-btn-action text-success page__pad10`}
                            onClick={() => handleAddClickSectionList(rest, setFieldValue, i)}
                          ></i>
                        </Tooltip>}
                    </div>

                  </div>

                );
              })}
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )
      }
    </Formik >
  );
};

export default PageForm;
