/* eslint-disable */

import { useEffect, useMemo, useRef, useState } from "react";
import { MENTION_TYPES } from "../../constant";
import styles from "./styles.module.scss";

export const usePostCaption = (props) => {
  const { ranges = [], captionText = "", isPost } = props;
  const [showReadMore, setShowReadMore] = useState(false);
  const captionRef = useRef();

  const caption = useMemo(() => {
    if (!captionText) {
      return "";
    }
    let captionCopy = captionText;
    let addedLength = 0;
    ranges?.forEach((range) => {
      if (
        [MENTION_TYPES.user, MENTION_TYPES.hashtag].includes(
          range.trackable_type
        )
      ) {
        const originalStringOffset = range.offset + addedLength;
        const originalString = captionCopy?.substr(
          originalStringOffset,
          range.length
        );

        let stringToReplaceWith = props.readOnly
          ? `<span class="${styles.caption}">${range.name}</span>`
          : "";

        if (range.trackable_type === MENTION_TYPES.user) {
          stringToReplaceWith = `<a target="_blank" class=${
            styles.caption
          } href=${`/users/${range.trackable_id}`}>${range.name}</a>`;
        } else if (range.trackable_type === MENTION_TYPES.hashtag) {
          stringToReplaceWith = `<span class="cursor_pointer ${styles.caption}" id="#${range.name}" >#${range.name}</span>`;
        }

        addedLength += stringToReplaceWith.length - originalString.length;
        captionCopy =
          captionCopy.substr(0, originalStringOffset) +
          stringToReplaceWith +
          captionCopy.substr(
            originalStringOffset + range.length,
            captionCopy.length
          );
      }
    });

    let validationRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    const linkedCaption = captionCopy.replace(validationRegex, function (url) {
      return `<a style="color:black; text-decoration:underline" href="${url}" target="_blank">${url}</a>`;
    });
    return linkedCaption.replaceAll("\u0001", "\n");
  }, [props?.data?.caption, props?.captionText]);

  useEffect(() => {
    if (caption?.length > props?.charLength) {
      setShowReadMore(true);
    }
  }, [caption]);

  const handleReadMoreClick = () => {
    setShowReadMore(false);
  };

  const handleReadLessClick = () => {
    setShowReadMore(true);
  };

  useEffect(() => {
    captionRef.current.addEventListener("click", handleCaptionClick);
    return () => {
      captionRef?.current?.removeEventListener("click", handleCaptionClick);
    };
  }, []);

  const handleCaptionClick = (e) => {
    if (props.hashtagClick) {
      props.hashtagClick(e);
    } else if (e?.target?.id?.includes("#") && !props.readOnly) {
      const taggedValue = ranges?.find((tag) => {
        return `#${tag.name}`.toLowerCase() === e.target.id.toLowerCase();
      });
    }
  };

  return {
    caption,
    captionRef,
    handleReadMoreClick,
    showReadMore,
    handleReadLessClick,
    isPost,
  };
};
