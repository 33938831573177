import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import MinusIcon from "mdi-react/MinusIcon";
import * as moment from "moment";
import { Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";

const DateRangeFilter = ({
  handleChange,
  filterKey,
  selectedValue,
  filterTitle,
}) => {
  const [state, setState] = useState({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    if (!selectedValue?.from && !selectedValue?.to) {
      setState({
        startDate: null,
        endDate: null,
      });
    }
  }, [selectedValue]);

  const handlePickerChange = ({ startDate, endDate }) => {
    const { startDate: stateStartDate, endDate: stateEndDate } = state;

    const startDateCopy = startDate || stateStartDate;
    const endDateCopy = endDate || stateEndDate;

    setState({ startDate: startDateCopy, endDate: endDateCopy });
  };

  const handleChangeStart = (startDate) => {
    const keys = Object.keys(selectedValue);
    handlePickerChange({ startDate });
    if (endDate) {
      handleChange(filterKey, {
        [keys[0]]: moment(startDate).format("DD-MM-YYYY"),
        [keys[1]]: moment(state.endDate).format("DD-MM-YYYY"),
      });
    } else if (selectedValue[keys[1]]) {
      handleChange(filterKey, {
        [keys[0]]: moment(startDate).format("DD-MM-YYYY"),
        [keys[1]]: selectedValue[keys[1]],
      });
    }
  };

  const handleChangeEnd = (endDate) => {
    const keys = Object.keys(selectedValue);
    handlePickerChange({ endDate });
    if (startDate) {
      handleChange(filterKey, {
        [keys[0]]: moment(state.startDate).format("DD-MM-YYYY"),
        [keys[1]]: moment(endDate).format("DD-MM-YYYY"),
      });
    } else if (selectedValue[keys[0]]) {
      handleChange(filterKey, {
        [keys[0]]: selectedValue[keys[0]],
        [keys[1]]: moment(endDate).format("DD-MM-YYYY"),
      });
    }
  };

  const { startDate, endDate } = state;

  const sDate = startDate ? moment(startDate).format("DD-MM-YYYY") : "";
  const eDate = endDate ? moment(endDate).format("DD-MM-YYYY") : "";

  const minDate = startDate
    ? moment(startDate).format("DD-MM-YYYY")
    : selectedValue[Object.keys(selectedValue)[0]]
    ? new Date(
        selectedValue[Object.keys(selectedValue)[0]]
          .split("-")
          .reverse()
          .join("-")
      )
    : new Date();
  const maxDate = endDate
    ? moment(endDate).format("DD-MM-YYYY")
    : selectedValue[Object.keys(selectedValue)[1]]
    ? new Date(
        selectedValue[Object.keys(selectedValue)[1]]
          .split("-")
          .reverse()
          .join("-")
      )
    : null;

  return (
    <Tooltip title={filterTitle} placement="bottom">
      <div className="date__range-picker date-picker date-picker--interval">
        <DatePicker
          className={`${
            selectedValue[Object.keys(selectedValue)[0]]
              ? "table__active_filter"
              : ""
          } form-control form__field-table-filter`}
          selected={startDate || minDate}
          selectsStart
          maxDate={endDate || maxDate}
          startDate={startDate}
          endDate={endDate || maxDate}
          value={selectedValue[Object.keys(selectedValue)[0]] || sDate}
          onChange={handleChangeStart}
          dateFormat="DD/MM/yyyy"
          placeholderText="From"
          dropDownMode="select"
        />

        <MinusIcon className="date-picker__svg" />
        <DatePicker
          className={`${
            selectedValue[Object.keys(selectedValue)[1]]
              ? "table__active_filter"
              : ""
          } form-control form__field-table-filter`}
          selected={endDate}
          selectsEnd
          startDate={startDate || minDate}
          minDate={startDate || minDate}
          value={selectedValue[Object.keys(selectedValue)[1]] || eDate}
          endDate={endDate}
          onChange={handleChangeEnd}
          dateFormat="DD/MM/yyyy"
          placeholderText="To"
          dropDownMode="select"
        />
      </div>
    </Tooltip>
  );
};

DateRangeFilter.propTypes = {
  selectedValue: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  filterKey: PropTypes.string.isRequired,
  filterTitle: PropTypes.string,
};

DateRangeFilter.defaultProps = {
  selectedValue: "",
  filterTitle: "",
};

export default DateRangeFilter;
