import { api, apiEndPoints } from "../../api";

const namespace = "containers/unlocked-offers";

export const SET_UNLOCKED_OFFER_LIST = `${namespace}/SET_UNLOCKED_OFFER_LIST`;
export const SET_UNLOCKED_OFFER_FILTER = `${namespace}/SET_UNLOCKED_OFFER_FILTER`;
export const RESET_UNLOCKED_OFFER_FILTER = `${namespace}/RESET_UNLOCKED_OFFER_FILTER`;

const setUnlokcedOfferList = (payload) => ({
  type: SET_UNLOCKED_OFFER_LIST,
  payload,
});

export const setUnlockedOfferFilter = (payload) => ({
  type: SET_UNLOCKED_OFFER_FILTER,
  payload,
});

export const resetUnlockedOfferFilter = () => ({
  type: RESET_UNLOCKED_OFFER_FILTER,
});

export const getUnlockedOfferList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints?.usedCouponCodes, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setUnlokcedOfferList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
