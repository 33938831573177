import React from "react";
import Modals from "../../../components/modal";
import CategoriesForm from "../form/categories-form";
import { useEditCategory } from "./hooks";
import { setLanguage } from "../../../utils/auth";
import { useUser } from "../../../common-hooks/user";
import ActionButton from "../../../components/action-button";

const EditCategory = ({ category }) => {
  const {
    handleEditCategory,
    isOpenModal,
    toggleModal,
    getCategoryFieldData,
    selectedCategory,
    editCategoryLoading,
    interests,
    handleLanguge,
  } = useEditCategory(category);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <>
      <ActionButton
        title="Edit"
        placement="bottom"
        onClick={() => {
          setLanguage("en");
          getCategoryFieldData();
        }}
        iconClassName={`fa fa-edit icon-btn-action `}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <CategoriesForm
              fieldValues={selectedCategory}
              handleCategoryForm={handleEditCategory}
              btn="Save"
              isLoading={editCategoryLoading}
              interests={interests}
              getCategoryData={handleLanguge}
            />
          }
          title="Edit Category"
        />
      ) : null}
    </>
  );
};

export default EditCategory;
