import { api, apiEndPoints } from "../../api";
import { setBearerToken, setUserData } from "../../utils/auth";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";

const namespace = "containers/auth/";

export const SET_IS_OTP_GENERATED = `${namespace}/SET_IS_OTP_GENERATED`;
export const SET_MOBILE_NUMBER = `${namespace}/SET_MOBILE_NUMBER`;
export const SET_LOGGED_IN_USER = `${namespace}/SET_LOGGED_IN_USER`;
export const SET_CONFIG_DATA = `${namespace}/SET_CONFIG_DATA`;

const setLoggedInUser = (payload) => ({
  type: SET_LOGGED_IN_USER,
  payload,
});

const setConfigData = (payload) => ({
  type: SET_CONFIG_DATA,
  payload,
});

export const setIsOtpGenerated = (payload) => ({
  type: SET_IS_OTP_GENERATED,
  payload,
});

export const setMobileNumber = (payload) => ({
  type: SET_MOBILE_NUMBER,
  payload,
});

export const requestOtp = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.requestOtp, payload);
    const { data: { is_success, message } = {} } = response;

    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      dispatch(setMobileNumber(payload.mobile_number));
      dispatch(setIsOtpGenerated(true));
      return true;
    }

    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    dispatch(setIsOtpGenerated(false));
    return false;
  }
};

export const userLogin = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.userLogin, payload);
    const { data: { is_success, role, token, message, id } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      setBearerToken(token);
      setUserData({ id, role });
      dispatch(setIsOtpGenerated(false));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getLoggedInUser = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getLoggedInUser(id));
    const { data: { is_success, user_details } = {} } = response;
    if (is_success) {
      dispatch(setLoggedInUser(user_details));
      return user_details;
    }
  } catch (error) {
    return false;
  }
};

export const getConfigData = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getConfigs);
    const { data: { is_success, configs } = {} } = response;
    if (is_success) {
      dispatch(setConfigData(configs));
    }
  } catch (error) {
    return false;
  }
};
