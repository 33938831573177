import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../components/modal";
import { useCancelScheduleEvent } from "./hooks";
import { DataLoader } from "../../../components/loader";
import { useUser } from "../../../common-hooks/user";

const CancelScheduleEvent = ({ scheduleEvent, isActionDisable }) => {
  const {
    cancelScheduleEventLoading,
    handleCancelScheduleEvent,
    isOpenModal,
    toggleModal,
  } = useCancelScheduleEvent(scheduleEvent);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Tooltip title="Cancel" placement="bottom">
        <i
          className={
            "fa fa-times icon-btn-action text-danger " +
            (isActionDisable ? " icon-btn-action-disable" : null)
          }
          onClick={toggleModal}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <div>
              {cancelScheduleEventLoading ? <DataLoader /> : null}
              {"Are you sure you want to Cancel?"}
              <div className="categories__modal-delete-btn">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleCancelScheduleEvent()}
                >
                  Cancel
                </button>
              </div>
            </div>
          }
          title="Cancel Schedule Event"
        />
      ) : null}
    </>
  );
};

export default CancelScheduleEvent;
