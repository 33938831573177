import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../../components/modal";
import { useEditSubcategory } from "./hooks";
import { setLanguage } from "../../../../utils/auth";
import SubcategoriesForm from "../form/subcategories-form";
import { useUser } from "../../../../common-hooks/user";

const EditSubcategory = ({ subcategory, category_id }) => {
  const {
    editSubcategoryLoading,
    handleEditSubategory,
    isOpenModal,
    toggleModal,
    getSubategoryFieldData,
    selectedSubcategory,
    handleLanguge,
  } = useEditSubcategory(subcategory, category_id);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action `}
          onClick={() => {
            setLanguage("en");
            getSubategoryFieldData();
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <SubcategoriesForm
              fieldValues={selectedSubcategory}
              handleCategoryForm={handleEditSubategory}
              btn="Save"
              isLoading={editSubcategoryLoading}
              getSubcategoryData={handleLanguge}
            />
          }
          title="Edit Subcategory"
        />
      ) : null}
    </>
  );
};

export default EditSubcategory;
