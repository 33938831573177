import React from "react";
import { Formik, ErrorMessage } from "formik";
import { MenuItem, TextField, Tooltip } from "@material-ui/core";

import { DataLoader } from "../../../components/loader";
import { useHandleFieldFocus } from "../../../utils/form";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { useManageOfferForm } from "./hooks";
import { fieldsName } from "../constant";
import { initialLanguageFieldValue, languages } from "../../../constants";
import { setLanguage } from "../../../utils/auth";
import { planFormSchema } from "./validation-schema";
const MangeOffersForm = (props) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    fieldValues,
    handleManageOffer,
    btn,
    isLoading,
    handleFormUpdateVariant,
    offers,
    handleLangugeData,
    isDisable,
  } = useManageOfferForm(props);

  const {
    variants,
    partner_offer_ids,
    partner_offer_text,
    title,
    description,
  } = fieldsName;

  const handleLanguge = (rest, key, value, setFieldValue) => {
    setFieldValue(key, {
      ...rest.values[key],
      [rest.values.language]: value,
    });
  };

  return (
    <Formik
      validateOnMount
      validationSchema={planFormSchema}
      initialValues={{
        variants: fieldValues?.plan_variants
          ? fieldValues?.plan_variants
          : [
              {
                partner_offer_ids: [],
              },
            ],
        partner_offer_text:
          fieldValues?.partner_offer_text || initialLanguageFieldValue,
        title: fieldValues?.title || initialLanguageFieldValue,
        description: fieldValues?.description || initialLanguageFieldValue,
        language: fieldValues?.language || "en",
      }}
      onSubmit={handleManageOffer}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            {!isLoading && (
              <div className="materila-form_inline-child sticky__element">
                <TextField
                  className="material-form__field"
                  name={"language"}
                  placeholder="Language"
                  label="Language"
                  select
                  value={rest.values.language}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    if (!rest.values.partner_offer_text[value]) {
                      setFieldValue(partner_offer_text, {
                        ...rest.values.partner_offer_text,
                        [value]: "",
                      });
                    }
                    if (!rest.values.title[value]) {
                      setFieldValue(title, {
                        ...rest.values.title,
                        [value]: "",
                      });
                    }
                    if (!rest.values.description[value]) {
                      setFieldValue(description, {
                        ...rest.values.description,
                        [value]: "",
                      });
                    }
                    setLanguage(value);
                    setFieldValue(name, value);
                    handleLangugeData(rest, setFieldValue);
                  }}
                  onFocus={() => handleFocusField("language")}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.language}
                >
                  {languages.map((language) => (
                    <MenuItem
                      key={language.id}
                      className="material-form__option"
                      value={language.value}
                    >
                      {language.displayText}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
            <div className="section__container">
              <div className="material-form_inline-fields d-flex align-items-center">
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={partner_offer_text}
                    placeholder="Partner Offer Text"
                    label="Partner Offer Text"
                    value={rest.values.partner_offer_text[rest.values.language]}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleLanguge(rest, name, value, setFieldValue);
                    }}
                    onFocus={() => handleFocusField(partner_offer_text)}
                    onBlur={() => handleBlurField()}
                  />
                </div>
                <div className="col-md-2 form__language">
                  <Tooltip title="Language" placement="bottom">
                    <div>
                      {
                        languages.find(
                          (lang) => lang.value === rest.values.language
                        ).displayText
                      }
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="material-form_inline-fields d-flex align-items-center">
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={title}
                    placeholder="Title"
                    label="Title"
                    disabled={isDisable}
                    value={rest.values.title[rest.values.language]}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleLanguge(rest, name, value, setFieldValue);
                    }}
                    onFocus={() => handleFocusField(title)}
                    onBlur={() => handleBlurField()}
                  />

                  {focusField === title ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={title}
                    />
                  )}
                </div>
                <div className="col-md-2 form__language">
                  <Tooltip title="Language" placement="bottom">
                    <div>
                      {
                        languages.find(
                          (lang) => lang.value === rest.values.language
                        ).displayText
                      }
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="material-form_inline-fields d-flex align-items-center">
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={description}
                    disabled={isDisable}
                    placeholder="Description"
                    label="Description"
                    value={rest.values.description[rest.values.language]}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleLanguge(rest, name, value, setFieldValue);
                    }}
                    onFocus={() => handleFocusField(description)}
                    onBlur={() => handleBlurField()}
                  />
                  {focusField === description ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={description}
                    />
                  )}
                </div>
                <div className="col-md-2 form__language">
                  <Tooltip title="Language" placement="bottom">
                    <div>
                      {
                        languages.find(
                          (lang) => lang.value === rest.values.language
                        ).displayText
                      }
                    </div>
                  </Tooltip>
                </div>
              </div>
              {rest?.values?.variants.map((x, i) => {
                return (
                  <div key={i}>
                    <div className="form__inline-items">
                      <div className="col-11 plan_left_pad0">
                        <div>
                          <Autocomplete
                            options={offers}
                            getOptionLabel={(offer) =>
                              offer.offer_text && offer?.partner?.name
                                ? `${offer?.partner?.name} - ${offer.offer_text}`
                                : ""
                            }
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            value={x?.partner_offer_ids}
                            multiple
                            onChange={(e, value) => {
                              handleFormUpdateVariant(
                                setFieldValue,
                                rest,
                                "partner_offer_ids",
                                value,
                                i
                              );
                            }}
                            onFocus={() =>
                              handleFocusField("partner_offer_ids")
                            }
                            onBlur={() => {
                              handleBlurField();
                              //rest.handleBlur();
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="material-form__field"
                                name={`${variants}.${i}.${partner_offer_ids}`}
                                placeholder={`Select Partner Offer for ${
                                  fieldValues?.code === "TopUp"
                                    ? `${x?.no_of_sessions} sessions`
                                    : `${x?.duration} days`
                                }`}
                                label={`Select Partner Offer for ${
                                  fieldValues?.code === "TopUp"
                                    ? `${x?.no_of_sessions} sessions`
                                    : `${x?.duration} days`
                                }`}
                                variant="standard"
                              />
                            )}
                          />

                          {focusField ===
                          `${variants}.${i}.${partner_offer_ids}` ? null : (
                            <ErrorMessage
                              render={(msg) => (
                                <div className="material-form__error">
                                  {msg}
                                </div>
                              )}
                              name={`${variants}.${i}.${partner_offer_ids}`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default MangeOffersForm;
