import React from "react";
import EventForm from "../form/event-form";
import { useAddEvent } from "./hooks";
import Modals from "../../../components/modal";
import { Button } from "reactstrap";
import { useUser } from "../../../common-hooks/user";

const AddEvent = (props) => {
  const {
    handleAddEvent,
    isOpenModal,
    toggleModal,
    categories,
    subcategories,
    getCategories,
    addEventLoading,
    faqCategories,
  } = useAddEvent(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          getCategories();
        }}
      >
        <i className="fa fa-plus"></i> Add Event
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <EventForm
              handleEventForm={handleAddEvent}
              btn="Add"
              categories={categories}
              subcategories={subcategories}
              faqCategories={faqCategories}
              isLoading={addEventLoading}
            />
          }
          title="Add Event"
        />
      ) : null}
    </div>
  );
};

export default AddEvent;
