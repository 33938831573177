import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";

const namespace = "containers/offers";

export const SET_PARTNER_LIST = `${namespace}/SET_PARTNER_LIST`;
export const SET_OFFER_FILTER = `${namespace}/SET_OFFER_FILTER`;
export const RESET_OFFER_FILTER = `${namespace}/RESET_OFFER_FILTER`;
export const SET_OFFER_LIST = `${namespace}/SET_OFFER_LIST`;
export const SET_PARTNER_FILTER = `${namespace}/SET_PARTNER_FILTER`;

export const setOfferList = (payload) => ({
  type: SET_OFFER_LIST,
  payload,
});

export const setOfferFilter = (payload) => ({
  type: SET_OFFER_FILTER,
  payload,
});

export const resetOfferFilter = (payload) => ({
  type: RESET_OFFER_FILTER,
  payload,
});

export const setPartnerList = (payload) => ({
  type: SET_PARTNER_LIST,
  payload,
});

export const setPartnerFilter = (payload) => ({
  type: SET_PARTNER_FILTER,
  payload,
});

export const getOfferList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPartnersOffers, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setOfferList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getSingleOffer = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSingleOffer(id));
    const { data: { is_success, partner_offer } = {} } = response;
    if (is_success) {
      return partner_offer;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getPartnersList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPartners, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setPartnerList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addOffer = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addPartnersOffers, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Offer not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const updateOffer = (payload, id) => async (dispatch) => {
  try {
    const response = await api.patch(
      apiEndPoints.editPartnersOffers(id),
      payload
    );
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Offer not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const uploadOfferCode = (payload, id) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.uploadOfferCode(id), payload, {
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      },
    });
    const {
      data,
      data: { is_success, message },
    } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return data;
  } catch (error) {
    return false;
  }
};
