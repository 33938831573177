import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import {
  sectionFieldsName,
  statusOptions,
  isPopularOptions,
  sectionTypeOptions,
  modelOptions,
} from "../constant";
import { DataLoader } from "../../../../../components/loader";
import { formSchema } from "./validation-schema";
import Tooltip from "@material-ui/core/Tooltip";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { useSectionForm } from "./hook";
import { initialLanguageFieldValue, languages } from "../../../../../constants";
import { setLanguage } from "../../../../../utils/auth";

const SectionForm = (props) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    formValues,
    handleFormUpdate,
    handleFormUpdateCriteria,
    handleRemoveClickCriteria,
    handleAddClickCriteria,
    handleModelValueChange,
    handleFieldValueChange,
    handleOperatorValueChange,
    modelOptionData,
    handleSectionForm,
    btn,
    isLoading,
    criteriaDynamicData,
    setFormvalues,
    handleLanguage,
    handleLanguageData,
  } = useSectionForm(props);

  const { title, type, status, model, criteria, field, op, value, language } =
    sectionFieldsName;

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={formSchema}
      initialValues={{
        title: formValues?.title || initialLanguageFieldValue,
        type: formValues?.type || "",
        status: formValues?.status || "",
        model: formValues?.model || "",
        language: formValues?.language || "en",
        criteria:
          formValues?.criteria[0].op ||
          formValues?.criteria[0].field ||
          formValues?.criteria[0].value
            ? formValues?.criteria
            : [{ field: "", op: "", value: "" }],
      }}
      onSubmit={handleSectionForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={language}
                placeholder="Language"
                label="Language"
                select
                value={rest.values.language}
                onChange={(event) => {
                  const { name, value } = event.target;
                  handleFormValueChange(rest, language);
                  handleFormUpdate(name, value);
                  if (!rest.values.title[value]) {
                    handleLanguage(title, value, "");
                  }
                  if (btn === "Save") {
                    setLanguage(value);
                    handleLanguageData(rest, setFormvalues);
                  }
                }}
                onFocus={() => handleFocusField(language)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.language}
              >
                {languages.map((language) => (
                  <MenuItem
                    key={language.id}
                    className="material-form__option"
                    value={language.value}
                  >
                    {language.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === language ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={language}
                />
              )}
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  type="text"
                  value={rest?.values?.title[rest.values.language]}
                  onChange={(event) => {
                    handleFormValueChange(rest, title);
                    handleLanguage(
                      title,
                      rest.values.language,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>
              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>
              <TextField
                className="material-form__field"
                name={type}
                placeholder="Select Section Type"
                label="Select Section Type"
                select
                value={rest?.values?.value}
                onChange={(event) => {
                  handleFormValueChange(rest, type);
                  handleFormUpdate(type, event.target.value);
                }}
                onFocus={() => handleFocusField(type)}
                onBlur={() => handleBlurField()}
                defaultValue={rest?.values?.type}
              >
                {sectionTypeOptions.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === type ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={type}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={(event) => {
                  handleFormValueChange(rest, status);
                  handleFormUpdate(status, event.target.value);
                }}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {statusOptions.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={model}
                placeholder="Model"
                label="Model"
                select
                value={rest.values.model}
                onChange={(event) => {
                  handleFormValueChange(rest, model);
                  handleFormUpdate(model, event.target.value);
                  handleModelValueChange(setFieldValue, event.target.value);
                }}
                onFocus={() => handleFocusField(model)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.model}
              >
                {modelOptions.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="section__container">
              <p className="custom__title_sections">Criteria</p>
              {rest?.values?.criteria.map((x, i) => {
                return (
                  <div
                    key={x + i}
                    className="matrial-form material-form_inline-fields"
                  >
                    <div className="materila-form_inline-child">
                      <TextField
                        className="material-form__field"
                        name={`${criteria}.${i}.${field}`}
                        placeholder="Select Field"
                        label="Select Field"
                        select
                        value={x.field}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFieldValue(name, value);
                          handleFormUpdateCriteria(field, value, i);
                          handleFieldValueChange(i, value);
                        }}
                        onFocus={(e) => {
                          const { name } = e.target;
                          handleFocusField(name);
                        }}
                        onBlur={() => handleBlurField()}
                      >
                        {modelOptionData.map((status) => (
                          <MenuItem
                            key={status.id}
                            className="material-form__option"
                            value={status.value}
                          >
                            {status.displayText}
                          </MenuItem>
                        ))}
                      </TextField>
                      {focusField === `${criteria}.${i}.${field}` ? null : (
                        <ErrorMessage
                          render={(msg) => (
                            <div className="material-form__error">{msg}</div>
                          )}
                          name={`${criteria}.${i}.${field}`}
                        />
                      )}
                    </div>

                    <div className="materila-form_inline-child">
                      <TextField
                        className="material-form__field"
                        name={`${criteria}.${i}.${op}`}
                        placeholder="Select Operator"
                        label="Select Operator"
                        select
                        value={x.op}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFieldValue(name, value);
                          handleFormUpdateCriteria(op, value, i);
                          handleOperatorValueChange(i, value);
                        }}
                        onFocus={(e) => {
                          const { name } = e.target;
                          handleFocusField(name);
                        }}
                        onBlur={() => handleBlurField()}
                      >
                        {criteriaDynamicData[i]?.operatorOptions.map(
                          (status) => (
                            <MenuItem
                              key={status.id}
                              className="material-form__option"
                              value={status.value}
                            >
                              {status.displayText}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                      {focusField === `${criteria}.${i}.${op}` ? null : (
                        <ErrorMessage
                          render={(msg) => (
                            <div className="material-form__error">{msg}</div>
                          )}
                          name={`${criteria}.${i}.${op}`}
                        />
                      )}
                    </div>

                    <div className="materila-form_inline-child">
                      {criteriaDynamicData[i]?.placeHolder === "Yes / No" ? (
                        <>
                          <TextField
                            className="material-form__field"
                            name={`${criteria}.${i}.${value}`}
                            placeholder="Select"
                            label="Select"
                            select
                            value={x.value}
                            onChange={(e) => {
                              const { name, value } = e.target;
                              setFieldValue(name, value);
                              handleFormUpdateCriteria("value", value, i);
                              handleFieldValueChange(setFieldValue, i, value);
                            }}
                            onFocus={(e) => {
                              const { name } = e.target;
                              handleFocusField(name);
                            }}
                            onBlur={() => handleBlurField()}
                          >
                            {isPopularOptions.map((status) => (
                              <MenuItem
                                key={status.id}
                                className="material-form__option"
                                value={status.value}
                              >
                                {status.displayText}
                              </MenuItem>
                            ))}
                          </TextField>
                          {focusField === `${criteria}.${i}.${value}` ? null : (
                            <ErrorMessage
                              render={(msg) => (
                                <div className="material-form__error">
                                  {msg}
                                </div>
                              )}
                              name={`${criteria}.${i}.${value}`}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            className="material-form__field"
                            name={`${criteria}.${i}.${value}`}
                            label="Value"
                            placeholder={criteriaDynamicData[i]?.placeHolder}
                            value={x?.value}
                            onChange={(e) => {
                              const { name, value } = e.target;
                              setFieldValue(name, value);
                              handleFormUpdateCriteria("value", value, i);
                            }}
                            onFocus={(e) => {
                              const { name } = e.target;
                              handleFocusField(name);
                            }}
                            onBlur={() => handleBlurField()}
                            disabled={criteriaDynamicData[i]?.isDisabled}
                          />
                          {focusField === `${criteria}.${i}.${value}` ? null : (
                            <ErrorMessage
                              render={(msg) => (
                                <div className="material-form__error">
                                  {msg}
                                </div>
                              )}
                              name={`${criteria}.${i}.${value}`}
                            />
                          )}
                        </>
                      )}
                    </div>

                    <div className="materila-form_inline-child">
                      {rest.values.criteria.length !== 1 && (
                        <Tooltip
                          title="Delete"
                          placement="bottom"
                          className="section__container"
                        >
                          <i
                            className={`fa fa-trash icon-btn-action text-danger section__pad10`}
                            onClick={() =>
                              handleRemoveClickCriteria(rest, setFieldValue, i)
                            }
                          ></i>
                        </Tooltip>
                      )}

                      {rest?.values?.criteria?.length - 1 === i && (
                        <Tooltip
                          title="Add"
                          placement="bottom"
                          className="section__container"
                        >
                          <i
                            className={`fa fa-plus icon-btn-action text-success section__pad10`}
                            onClick={() =>
                              handleAddClickCriteria(rest, setFieldValue)
                            }
                          ></i>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default SectionForm;
