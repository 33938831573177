import React from "react";
import { Route, Switch } from "react-router-dom";

import UnlockedOffers from "../../../Unlocked Offers";

export default () => (
  <Switch>
    <Route path="/unlocked-offers" component={UnlockedOffers} />
  </Switch>
);
