import React from "react";
import { Button } from "reactstrap";
import Modals from "../../../../components/modal";

import SubcategoriesForm from "../form/subcategories-form";
import { useAddSubcategory } from "./hooks";

const AddSubcategory = (props) => {
  const {
    category_type,
    handleAddSubcategory,
    addSubcategoryLoading,
    isOpenModal,
    toggleModal,
  } = useAddSubcategory(props);
  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Add Subcategory
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <SubcategoriesForm
              handleCategoryForm={handleAddSubcategory}
              btn="Add"
              category_type={category_type}
              isLoading={addSubcategoryLoading}
            />
          }
          title="Add Subcategory"
        />
      ) : null}
    </div>
  );
};

export default AddSubcategory;
