import React from 'react';
import PropTypes from 'prop-types';
import LazyImage from '../lazy-image';
import styles from './styles.module.scss';

const ProfileImage = (props) => {
  if (!props.src) {
    const nameLetters = props?.title
      ?.split(' ')
      .map((name) => name[0])
      .join('')
      .toUpperCase();

    return (
      <div
        style={{ height: props?.height, width: props?.width }}
        className={`${styles.letter_text} ${props.className}`}
      >
        <div className={`${styles.name_letter} ${props.initialsClassName}`}>
          {nameLetters?.slice(0, 2)}
        </div>
      </div>
    );
  }

  return (
    <div className={`${props.className} ${styles.image_container}`}>
      <LazyImage
        {...props}
        alt="profile"
        className={`${props.className} ${styles.profile_image_img}`}
      />
    </div>
  );
};

ProfileImage.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  initialsClassName: PropTypes.string,
};

export default ProfileImage;
