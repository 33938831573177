import { api, apiEndPoints } from "../../../api";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";

const namespace = "containers/posts";

export const SET_POST_LIST = `${namespace}/SET_POST_LIST`;
export const SET_POST_FILTER = `${namespace}/SET_POST_FILTER`;
export const CLEAR_POST_FILTER = `${namespace}/CLEAR_POST_FILTER`;

export const SET_EVENT_LIST = `${namespace}/SET_EVENT_LIST`;
export const SET_EVENT_FILTER = `${namespace}/SET_EVENT_FILTER`;
export const CLEAR_EVENT_FILTER = `${namespace}/CLEAR_EVENT_FILTER`;

export const SET_BLOG_LIST = `${namespace}/SET_BLOG_LIST`;
export const SET_BLOG_FILTER = `${namespace}/SET_BLOG_FILTER`;
export const CLEAR_BLOG_FILTER = `${namespace}/CLEAR_BLOG_FILTER`;

export const SET_COURSE_LIST = `${namespace}/SET_COURSE_LIST`;
export const SET_COURSE_FILTER = `${namespace}/SET_COURSE_FILTER`;
export const CLEAR_COURSE_FILTER = `${namespace}/CLEAR_COURSE_FILTER`;

export const setEventList = (payload) => ({
  type: SET_EVENT_LIST,
  payload,
});

export const setEventFilter = (payload) => ({
  type: SET_EVENT_FILTER,
  payload,
});

export const resetEventFilter = (payload) => ({
  type: CLEAR_EVENT_FILTER,
  payload,
});

export const setPostList = (payload) => ({
  type: SET_POST_LIST,
  payload,
});

export const setPostFilter = (payload) => ({
  type: SET_POST_FILTER,
  payload,
});

export const resetPostFilter = (payload) => ({
  type: CLEAR_POST_FILTER,
  payload,
});

export const setBlogList = (payload) => ({
  type: SET_BLOG_LIST,
  payload,
});

export const setBlogFilter = (payload) => ({
  type: SET_BLOG_FILTER,
  payload,
});

export const resetBlogFilter = (payload) => ({
  type: CLEAR_BLOG_FILTER,
  payload,
});

export const setCourseList = (payload) => ({
  type: SET_COURSE_LIST,
  payload,
});

export const setCourseFilter = (payload) => ({
  type: SET_COURSE_FILTER,
  payload,
});

export const resetCourseFilter = (payload) => ({
  type: CLEAR_COURSE_FILTER,
  payload,
});

export const addPost = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addPost, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const updatePost = (id, payload) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updatePost(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUsers, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data?.users;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getHashtags = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getHashtags, {
      params: { params },
    });
    const { data } = response;
    if (data) {
      return data;
    }
    // return false;
  } catch (error) {
    return false;
  }
};

export const getScheduleEventList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSchedulesEvents, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setEventList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getBlogList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getBlogs, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setBlogList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getReactions = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getReactions, {
      params: { params },
    });
    const { data } = response;
    if (data) {
      return data;
    }
    // return false;
  } catch (error) {
    return false;
  }
};

export const addPostReaction = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addReaction, payload);
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const DeletePostReaction = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteReaction(id));
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const addComment = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.comments, payload);
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getCommentList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getComments, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const replyToComment = (id, payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.replyToComment(id), payload);
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getCommentDetails = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCommentReplies(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getPosts = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPosts, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setPostList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getSinglePost = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSinglePost(id));
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getReportOptionsList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getReportsOptions, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteComment = (id, payload) => async (dispatch) => {
  try {
    const response = await api.patch(
      apiEndPoints.changeCommentStatus(id),
      payload
    );
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const editComment = (id, payload) => async (dispatch) => {
  try {
    const response = await api.patch(apiEndPoints.editComment(id), payload);
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const addVote = (poll_id, poll_choice_id) => async (dispatch) => {
  try {
    const response = await api.post(
      apiEndPoints.addVote(poll_id, poll_choice_id)
    );
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deletePost = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deletePost(id));
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getCourseList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCourses, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setCourseList(data));
      return data?.users;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updatePostStatus = (id, payload) => async (dispatch) => {
  try {
    const response = await api.patch(
      apiEndPoints.updatePostStatus(id),
      payload
    );
    const { data, data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getPostReports = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPostReports(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getSharedPostData = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSharedPostData(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadPostStatistics = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPostStatistics, {
      params: { params },
    });
    const { data: { is_success, message } = {} } = response;

    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
  } catch (error) {
    return false;
  }
};

export const getStoriesReports = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getStoriesReports(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getCommentsReports = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCommentReports(id), {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteAComment = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteComment(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return is_success;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};