import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import _ from "lodash";
import userLogo from "../../../assets/images/user-icon.png";
import Tooltip from "@material-ui/core/Tooltip";

const AttendeesTabs = ({ tabs, tabsData, handleTicket }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  let count = 1;
  const toggleTabs = (tab) => {
    count = 1;
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="tabs tabs--justify tabs--bordered-bottom review-schedule-event-container">
      <div className="tabs__wrap">
        <Nav tabs>
          {tabs.map((tab, index) => (
            <NavItem key={`${index}-${tab}`}>
              <NavLink
                className={activeTab === tab ? "active" : null}
                onClick={() => {
                  toggleTabs(tab);
                }}
              >
                {_.startCase(_.toLower(tab))}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {tabs.map((tab, index) => (
            <TabPane tabId={tab} key={tab}>
              {tabsData.length > 0 ? (
                tabsData.map((tabData, index) => {
                  if (
                    activeTab === "attended" &&
                    tabData.booking_status !== "Attended"
                  ) {
                    count++;
                    if (
                      activeTab === "attended" &&
                      count / 2 === tabsData.length
                    ) {
                      return (
                        <div className="schedule-event__no-attendees">
                          <i>Seems like nobody has attended this event yet.</i>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  }
                  return (
                    <div key={`${tabData.id}+${index}`}>
                      <div key={index} className="attendees__list">
                        <div className="attendees__contact-avatar">
                          <img
                            src={tabData?.profile_pic || userLogo}
                            alt="profile"
                          />
                        </div>
                        <div className="attendees__contact-preview">
                          <p className="attendees__contact-name">
                            {tabData.name}
                          </p>
                          <p className="attendees__contact-role">
                            {tabData.role}
                          </p>
                        </div>
                        {tabData?.details?.tickets && (
                          <Tooltip
                            title="View Ticket"
                            placement="bottom"
                            className="view_user_details_pad_left10"
                          >
                            <i
                              className={`fa fa-eye icon-btn-action`}
                              onClick={() => handleTicket(tabData?.details?.tickets)}
                            ></i>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="schedule-event__no-attendees">
                  No Attendees{" "}
                </div>
              )}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </div>
  );
};

export default AttendeesTabs;
