import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOffer, getOfferList } from "../actions";
import { getOfferFilters } from "../selectors";

export const useAddOffer = () => {
  const [addOfferLoading, setAddOfferLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const filter = useSelector(getOfferFilters);

  const dispatch = useDispatch();

  const handleAddOffer = async (values) => {
    setAddOfferLoading(true);
    const payload = {
      offer_text: values.offer_text,
      plan_offer_text: values.plan_offer_text,
      my_offer_text: values.my_offer_text,
      description: values.description,
      is_coupon_code_required: values.dynamic_coupon_code,
      amount: values?.amount || 0,
      validity: values?.validity,
      per_user_coupon_code_limit: values?.no_of_coupon_codes,
      terms_and_conditions: values?.terms_and_conditions,
      how_to_use: values?.how_to_use,
      settings: {
        cta_name: values.cta_name,
        cta_url: values.cta_url,
      },
      partner_id: values?.partner_id?.id,
      status: values.status,
    };
    const is_success = await dispatch(addOffer(payload));
    if (is_success) {
      dispatch(getOfferList(filter));
      toggleModal();
    }
    setAddOfferLoading(false);
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    addOfferLoading,
    handleAddOffer,
    isOpenModal,
    toggleModal,
  };
};
