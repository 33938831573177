import { trackableTypes } from "../../../constants";
import {
  CLEAR_COMMENT_AND_REPLY_FILTER,
  SET_COMMENT_AND_REPLY_FILTER,
  SET_COMMENT_AND_REPLY_LIST,
} from "./action";

const initialList = {
  comments_and_replies: [],
  count: 0,
  filter: {
    trackable_type: [trackableTypes.POST, trackableTypes.DISCUSSION],
    trackable_title: "",
    parent_comment_id: "",
    text: "",
    limit: 10,
    skip: 0,
    replies: { limit: 1, skip: 0 },
    created_at: { from: "", to: "" },
    user_id: null,
    name: "",
    mobile_number: "",
    status: "",
    roles: [],
    reported: "Yes",
    evergreen_club_tagged: "",
    hashtag: "",
    order: ["last_reported_at DESC"],
  },
};

const commentAndRepliesReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_COMMENT_AND_REPLY_LIST:
      return {
        ...state,
        comments_and_replies: action.payload.comments,
        count: action?.payload?.count,
      };

    case SET_COMMENT_AND_REPLY_FILTER: {
      const { key, value } = action.payload;
      if (key === "user_id") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value ? value : null,
          },
        };
      }

      if (key === "roles") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value ? [value] : [],
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }

    case CLEAR_COMMENT_AND_REPLY_FILTER:
      return { ...state, filter: initialList.filter || {} };

    default:
      return state;
  }
};

export default commentAndRepliesReducer;
