import React from "react";
import Text from "../../../../../components/text";
import { colors } from "../../../../../constants/colors";
import ProfileImage from "../../../../../components/ProfileImage";
import styles from "./style.module.scss";

const List = ({ list, handleDataParams, totalCount }) => {
  if (!list.length) return <div>No Data Found for this post</div>;
  return (
    <>
      {list.map((post) => (
        <div key={post?.id} className={styles.container}>
          <ProfileImage
            src={post?.profile_picture?.url}
            title={post?.name}
            className={styles.profile_picture}
            initialsClassName={styles.profile_picture_initials}
          />
          <div className={styles.detail_container}>
            <Text
              fontWeight={600}
              size="14px"
              lineheight="20px"
              color={colors.lightBlack4}
            >{`${post?.name} `}</Text>
          </div>
        </div>
      ))}
      {list?.length < totalCount && (
        <Text
          className="text-center d-block"
          color={colors.brand}
          fontWeight={400}
          size="20px"
          onClick={handleDataParams}
          role="button"
        >
          View More
        </Text>
      )}
    </>
  );
};

export default List;
