import React from "react";
import { useCancelEvent } from "./hooks";
import Modals from "../../../../../components/modal";
import Tooltip from "@material-ui/core/Tooltip";
import CancelForm from "../cancel-event-form/cancel-form";
function CancelEvent(props) {
  const {
    isOpenModal,
    toggleModal,
    handleCancelEvent,
    getRefund,
    refundDetails,
    is_course,
  } = useCancelEvent(props);

  const { is_refundable, message } = refundDetails;
  return (
    <div>
      <Tooltip title="Click to Cancel Event" placement="bottom">
        <i
          className="fa fa-times-circle table__icon-deactive"
          onClick={is_course ? toggleModal : getRefund}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <>
              <CancelForm
                btn="Cancel"
                handleCancelForm={handleCancelEvent}
                event={props}
                is_refundable={is_refundable}
                message={message}
              />
            </>
          }
          title="Cancel Event"
        />
      ) : null}
    </div>
  );
}

export default CancelEvent;
