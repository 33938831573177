import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../components/modal";
import { useChangePlanStatus } from "./hooks";
import { useUser } from "../../../common-hooks/user";
import { DataLoader } from "../../../components/loader";

const ChangePlanStatus = ({ plan }) => {
  const {
    handleChangePlanStatus,
    isOpenModal,
    toggleModal,
    changePlanStatusLoading,
    planStatus,
    planStatusAction,
  } = useChangePlanStatus(plan);

  const { isAdmin } = useUser();
  const { Published, Unpublished, publishBtn, unpublishBtn } = planStatusAction;

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      {planStatus === Published ? (
        <Tooltip title="Unpublish Status" placement="bottom">
          <i
            className={`fa fa-check-circle table__icon-active icon-btn-action`}
            onClick={toggleModal}
          ></i>
        </Tooltip>
      ) : (
        <Tooltip title="Publish Status" placement="bottom">
          <i
            className={`fa fa-times-circle table__icon-deactive icon-btn-action`}
            onClick={toggleModal}
          ></i>
        </Tooltip>
      )}
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <div>
              {changePlanStatusLoading ? <DataLoader /> : null}
              Are you sure you want to change the status to{" "}
              {planStatus === Published ? Unpublished : Published}?
              <div className="categories__modal-delete-btn">
                <button
                  type="button"
                  className="btn btn-danger mb-0 plan__status_button"
                  onClick={() => handleChangePlanStatus()}
                >
                  {planStatus === Published ? unpublishBtn : publishBtn}
                </button>
              </div>
            </div>
          }
          title="Change Plan Status"
        />
      ) : null}
    </>
  );
};

export default ChangePlanStatus;
