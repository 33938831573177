import {
  ClappingHand,
  HeartIcon,
  LaughingIcon,
  LikeIcon,
  SurprisedIcon,
  ThankfulIcon,
} from "./images";

export const columns = [
  {
    Header: "ID",
    accessor: "id",
    // width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Name",
    accessor: "name",
    // width: 30,
  },
  {
    Header: "Caption",
    accessor: "caption",
    disableGlobalFilter: true,
    // width: 350,
  },
  {
    Header: "Poll Question",
    accessor: "topic",
    // width: 150,
  },
  {
    Header: "Poll Options",
    accessor: "choices",
    width: 150,
    disableGlobalFilter: true,
  },
  {
    Header: "Reported Data",
    accessor: "reports",
    width: 150,
  },
  {
    Header: "Created At",
    accessor: "created_at",
    width: 200,
  },
  {
    Header: "Updated At",
    accessor: "updated_at",
    width: 200,
  },
  {
    Header: "Created By",
    accessor: "created_by",
    width: 80,
  },
  {
    Header: "Updated By",
    accessor: "updated_by",
    width: 80,
  },
  {
    Header: "Shares",
    accessor: "share_count",
    width: 50,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 50,
  },
  {
    Header: "Unpublish Reason",
    accessor: "reason",
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 200,
  },
];

export const fieldsName = {
  type: "type",
  name: "name",
  caption: "caption",
  poll_options: "poll_options",
  poll_topic: "poll_topic",
  status: "status",
  event_tagged: "event_tagged",
  course_tagged: "course_tagged",
  files: "files",
  youtube_links: "youtube_links",
  blog: "blog",
  events: "events",
  ranges: "ranges",
  post_by: "post_by",
};

export const postType = [
  { id: 1, displayText: "Event", value: "event" },
  { id: 2, displayText: "Course", value: "course" },
];

export const postStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
];

export const userRoles = [
  {
    id: 1,
    value: "Admin",
    displayText: "Admin",
  },
  {
    id: 2,
    value: "Member",
    displayText: "Member",
  },
  {
    id: 3,
    value: "Coordinator",
    displayText: "Coordinator",
  },
  {
    id: 4,
    value: "Moderator",
    displayText: "Moderator",
  },
  {
    id: 5,
    value: "Moderator",
    displayText: "Moderator",
  },
  {
    id: 6,
    value: "Customer Support Specialist",
    displayText: "Customer Support Specialist",
  },
];

export const postsFilterStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
  {
    id: 3,
    value: "Draft",
    displayText: "Draft",
  },
  // {
  //   id: 4,
  //   value: "Delete",
  //   displayText: "Delete",
  // },
];

export const egTaggedFilter = [
  {
    id: 1,
    value: "All",
    displayText: "All Posts",
  },
  {
    id: 2,
    value: "Yes",
    displayText: "EG Tagged Posts",
  },
];

export const reportsFilterOption = [
  {
    id: 1,
    value: "All",
    displayText: "All Posts",
  },
  {
    id: 2,
    value: "Yes",
    displayText: "Reported Posts",
  },
];

export const postByList = [
  {
    id: 1,
    value: "Me",
    displayText: "Me",
  },
  {
    id: 2,
    value: "EvergreenClub",
    displayText: "Evergreen Club",
  },
];

export const REACTION_CODE = {
  like: "Like",
  clap: "Clap",
  laugh: "Laugh",
  love: "Love",
  surprise: "Surprise",
  thankful: "Thankful",
  all: "",
};

export const REACTION_ICON_MAP = {
  [REACTION_CODE.like]: LikeIcon,
  [REACTION_CODE.clap]: ClappingHand,
  [REACTION_CODE.laugh]: LaughingIcon,
  [REACTION_CODE.love]: HeartIcon,
  [REACTION_CODE.surprise]: SurprisedIcon,
  [REACTION_CODE.thankful]: ThankfulIcon,
};

export const POST_CREATION_POINTS = {
  poll: "poll",
  media: "media",
  text: "text",
  eventSchedule: "eventSchedule",
  blog: "blog",
};

export const eventLanguages = {
  Hindi: "हिंदी",
  Marathi: "मराठी",
  English: "ENG",
  Tamil: "தமிழ்",
  Gujarati: "ગુજરાતી",
};

export const MENTION_TYPES = {
  user: "USER",
  hashtag: "HASHTAG",
};

export const ExportFieldsName = {
  from: "from",
  to: "to",
};

export const statuses = {
  PUBLISHED: "Published",
  UNPUBLISHED: "Unpublished",
  PENDING: "Pending",
  IN_REVIEW: "In Review",
};
