import React from "react";
import Modals from "../../../../components/modal";
import SettingsForm from "../form/settings-form";
import { useEditForm } from "./hooks";
import Tooltip from "@material-ui/core/Tooltip";
import { useUser } from "../../../../common-hooks/user";

function EditConfig({ config }) {
  const { handleForm, isOpenModal, toggleModal } = useEditForm(config);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Tooltip title="Edit" placement="bottom">
        <i className={`fa fa-edit icon-btn-action `} onClick={toggleModal}></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <SettingsForm
              btn="save"
              handleSetting={handleForm}
              fieldValues={config}
              isEdit={true}
            />
          }
          title="Edit Config"
        />
      ) : null}
    </>
  );
}

export default EditConfig;
