import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearLanguage } from "../../utils/auth";
import {
  getCourseList,
  setCourseFilter,
  deleteCourseData,
  clearCourseFilter,
  resetCourseFilter,
} from "./actions";
import { getCourses, getCoursesCount, getCourseFilter } from "./selectors";

export const useCourses = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const courses = useSelector(getCourses);
  const filters = useSelector(getCourseFilter);
  const courseCount = useSelector(getCoursesCount);
  const [loadingCourses, setLoadingCourses] = useState(false);

  useEffect(() => {
    dispatch(resetCourseFilter());
  }, [dispatch]);

  useEffect(() => {
    clearLanguage();
    const fetchCourses = async () => {
      setLoadingCourses(true);

      await dispatch(getCourseList(filters));
      setLoadingCourses(false);
    };
    fetchCourses();
  }, [filters, dispatch]);

  const deleteCourse = async (course_id) => {
    setLoadingCourses(true);
    await dispatch(deleteCourseData(course_id));
    setLoadingCourses(false);
  };

  const handleEditClick = (course_id) => {
    history.push({ pathname: `/edit-course/${course_id}` });
  };

  const handleAddClick = () => {
    history.push("/add-course");
  };

  const setFilter = (key, value) => {
    dispatch(setCourseFilter({ key, value }));
  };

  const clearFilter = () => {
    dispatch(clearCourseFilter());
  };

  const handleViewClick = (course_id) => {
    history.push({ pathname: `/course/${course_id}` });
  };

  return {
    setFilter,
    loadingCourses,
    filters,
    courses,
    courseCount,
    deleteCourse,
    handleEditClick,
    handleAddClick,
    handleViewClick,
    clearFilter,
  };
};
