import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import Modals from "../../../components/modal";
import { useAddNote } from "./hooks";
import NoteUploadForm from "./upload-notes/form/upload-form";
import { useUser } from "../../../common-hooks/user";

const AddNote = (props) => {
  const {
    toggleModal,
    isOpenModal,
    handleAddNote,
    handleGetNote,
    files,
    handleClearScheduleEventNote,
    index,
    isUpdating,
  } = useAddNote(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Tooltip title="Add/Update Note" placement="bottom">
        <div
          className="action_review-schedule-event"
          onClick={() => {
            handleClearScheduleEventNote();
            handleGetNote();
            toggleModal();
          }}
        >
          <i className="fa fa-upload" aria-hidden="true"></i>
        </div>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <NoteUploadForm
              btn={`${isUpdating ? "Update" : "Add"} Note`}
              handleAddNote={handleAddNote}
              files={files}
              index={index}
              isUpdating={isUpdating}
            />
          }
          title="Notes"
        />
      ) : null}
    </div>
  );
};

export default AddNote;
