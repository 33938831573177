import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";
import { clearFiles, setFiles } from "../../utils/upload-file/actions";
import * as moment from "moment";

const namespace = "containers/courses";

export const SET_COURSE_LIST = `${namespace}/SET_COURSE_LIST`;
export const SET_COURSE_FILTER = `${namespace}/SET_COURSE_FILTER`;
export const CLEAR_COURSE_FILTER = `${namespace}/CLEAR_COURSE_FILTER`;
export const SET_SCHEDULE_EVENT_REVIEWS = `${namespace}/SET_SCHEDULE_EVENT_REVIEWS`;
export const RESET_COURSE_FILTER = `${namespace}/RESET_COURSE_FILTER`;

const setCourseList = (payload) => ({
  type: SET_COURSE_LIST,
  payload,
});

export const setCourseFilter = (payload) => ({
  type: SET_COURSE_FILTER,
  payload,
});

export const clearCourseFilter = (payload) => ({
  type: CLEAR_COURSE_FILTER,
  payload,
});

export const setScheduleEventReviews = (payload) => ({
  type: SET_SCHEDULE_EVENT_REVIEWS,
  payload,
});

export const resetCourseFilter = (payload) => ({
  type: RESET_COURSE_FILTER,
  payload,
});

export const addCourse = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createCourse, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Course not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getCourseList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCourses, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setCourseList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getCourseData = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCourse(id), {
      params: { params: {} },
    });
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getCourseNotes = (id) => async () => {
  try {
    const response = await api.get(apiEndPoints.getCourseNotes(id), {
      params: { params: { event_schedule_types: ["Regular", "Trial"] } },
    });
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editCourse = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateCourse(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      dispatch(clearFiles());
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteCourseData = (id) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.cancelCourse(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUsers, {
      params: {
        params: {
          roles: ["Coordinator", "Moderator"],
          text: "",
          limit: 0,
          skip: 0,
          status: { is_active: true, is_deleted: false, deleted: false, is_blacklisted: false },
        },
      },
    });
    const { data: { is_success, users } = {} } = response;
    if (is_success) {
      return users;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getEventList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEvents, {
      params: { params: { is_course: true } },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const startEvent = (id) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.startEvent(id));
    const {
      data: { zoom_meeting, is_success },
    } = response;
    if (is_success) {
      return zoom_meeting;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getEventReviews = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCourseReviews(id), {
      params: { params: { limit: 10000, skip: 0, trackable_type: "Course" } },
    });
    const {
      data: { reviews, is_success },
    } = response;
    if (is_success) {
      dispatch(setScheduleEventReviews(reviews));
      return reviews;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const publishUnpublishReview = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(
      apiEndPoints.changeReviewStatus(id),
      payload
    );
    const {
      data,
      data: { is_success },
    } = response;

    if (is_success) {
      return data;
    }
  } catch (error) {
    return false;
  }
};

export const downloadEventAttendees = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.exportEventAttendees(id), {
      responseType: "arraybuffer",
      params: { params: { utc_offset: moment().utcOffset() } },
    });
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getEventAttendees = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEventAttendees(id));
    const { data: { is_success, attendees } = {} } = response;
    if (is_success) {
      return attendees;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getEvent = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEvent(id));
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadScheduledCourses = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.exportCourses, {
      params: { params },
    });
    const {
      data: { is_success, message },
    } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const addEventNote = (payload, id) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addEventNote(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Note not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getEventNote = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEventNote(id));
    const { data, data: { is_success } = {} } = response;

    if (is_success) {
      dispatch(setFiles(data?.note));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
