import React from "react";

export const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="/img/right-arrow-filled.svg" alt="" />
    </div>
  );
};

export const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="/img/left-arrow-filled.svg" alt="" />
    </div>
  );
};

export const PrevArrowLight = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="/img/slider-prev-arrow.png" alt="" />
    </div>
  );
};
export const NextArrowLight = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="/img/slider-next-arrow.png" alt="" />
    </div>
  );
};
