import { fieldsName } from "../constant";
import { getPartnerOffer } from "../selectors";
import { useSelector } from "react-redux";

const { variants } = fieldsName;

export const useManageOfferForm = ({
  fieldValues,
  handleManageOffer,
  btn,
  isLoading,
  handleLangugeData,
  isDisable,
}) => {
  const offers = useSelector(getPartnerOffer);

  const handleFormUpdateVariant = (setFieldValue, rest, key, value, index) => {
    let variantList = [...rest.values.variants];
    variantList[index][key] = value;
    setFieldValue(variants, variantList);
  };

  return {
    fieldValues,
    handleManageOffer,
    btn,
    isLoading,
    handleFormUpdateVariant,
    offers,
    handleLangugeData,
    isDisable,
  };
};
