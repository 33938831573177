import { api, apiEndPoints } from "../../../../api";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";

const namespace = "containers/sections";

export const SET_SECTION_LIST = `${namespace}/SET_SECTION_LIST`;
export const SET_SECTION_FILTER = `${namespace}/SET_SECTION_FILTER`;

export const setSectionList = (payload) => ({
  type: SET_SECTION_LIST,
  payload,
});

export const setSectionFilter = (payload) => ({
  type: SET_SECTION_FILTER,
  payload,
});

export const addSection = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createSection, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Section not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getSectionList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSections, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setSectionList(data))
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getLabelList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getLabels, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      // dispatch(setLabelList(data))
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getSectionData = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSection(id));
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editSection = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateSection(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteSectionData = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteSection(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getBlogList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getBlogs, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};