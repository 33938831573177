import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import {
  editScheduleEvent,
  getScheduleEventList,
  getScheduleEvent,
  getEvent,
  setEventList,
} from "../actions";
import {
  getScheduleEventsFilter,
  getUsersSelector,
  getEventsSelector,
} from "../selectors";
import { clearFiles, setFiles } from "../../../utils/upload-file/actions";
import { clearLanguage, getLanguage } from "../../../utils/auth";
import { initialLanguageArrayValue, snackbarTypes } from "../../../constants";
import { showSnackbar } from "../../../components/snackbar/actions";

export const useEditScheduleEvent = ({ id }) => {
  const dispatch = useDispatch();
  const events = useSelector(getEventsSelector);
  const activeUsers = useSelector(getUsersSelector);
  const filter = useSelector(getScheduleEventsFilter);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editScheduleEventLoading, setEditScheduleEventLoading] =
    useState(false);
  const [selectedScheduleEvent, setSelectedScheduleEvent] = useState([]);
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const [users, setUsers] = useState(activeUsers);

  const getAutocompleteValues = async () => {
    setEditScheduleEventLoading(true);
    const selectedLanguage = getLanguage();

    const data = await dispatch(getScheduleEvent(id));
    const { event_schedule } = data;

    if (event_schedule?.files?.pre_event_file?.original_file_name) {
      dispatch(setFiles(event_schedule?.files?.pre_event_file));
    }

    data.startsAt = event_schedule?.starts_at;
    data.endsAt = event_schedule?.ends_at;
    data.status = event_schedule?.status;
    data.language = event_schedule?.language;
    data.mode = event_schedule?.mode;
    data.attendeesLimit = event_schedule?.attendees_limit;
    data.hostIds = users.find((user) => user.id === event_schedule?.host.id);
    data.is_edit = true;

    if (!data.hostIds) {
      setUsers([...activeUsers, event_schedule?.host]);
      data.hostIds = event_schedule?.host;
    }

    data.cohostIds = activeUsers.filter((user) =>
      event_schedule?.cohosts?.find((cohost) => cohost.id === user.id)
    );

    if (data.cohostIds.length === 0) {
      data.cohostIds = event_schedule?.cohosts;
      const cohosts = event_schedule?.cohosts.filter((coHost) => {
        if (coHost.id !== event_schedule?.host.id) {
          return setUsers([...activeUsers, coHost, event_schedule?.host]);
        }
      });
    } else if (data.cohostIds.length !== event_schedule?.cohosts) {
      data.cohostIds = event_schedule?.cohosts.filter((cohostIds) => {
        const ids = data.cohostIds.find((host) => {
          if (host.id !== cohostIds.id) {
            if (cohostIds.id !== event_schedule?.host.id) {
              setUsers([...activeUsers, cohostIds, event_schedule?.host]);
            }
            return cohostIds;
          } else {
            return host;
          }
        });
        return ids;
      });
    }
    data.is_attachment = event_schedule.pre_event_file_cta ? true : false;
    data.cta_title = event_schedule.pre_event_file_cta;
    data.booked = event_schedule.booked;
    data.type = event_schedule?.is_free ? "Free" : "Paid";
    data.languages = selectedLanguage;
    data.requirements = {
      ...initialLanguageArrayValue,
      [selectedLanguage]: event_schedule.requirements,
    };
    data.eventType = event_schedule.type;
    data.allow_discussions = event_schedule.allow_discussions ? true : false;
    data.current_attendees_limit = event_schedule.current_attendees_limit;
    data.exceeds_percentage = event_schedule.exceeds_percentage;
    data.increment_percentage = event_schedule.increment_percentage;
    data.allow_booking_post_commencement = handleMinuteConversion(
      event_schedule.allow_booking_post_commencement,
      event_schedule.starts_at
    );
    data.allow_seats_increment = handleMinuteConversion(
      event_schedule.allow_seats_increment,
      event_schedule.starts_at
    );
    data.broadcast_mins = event_schedule.broadcast_mins;
    data.after_popup_broadcast_mins = event_schedule.after_popup_broadcast_mins;
    data.max_join_broadcast_attempts =
      event_schedule.max_join_broadcast_attempts;
    data.streaming_type = event_schedule?.broadcast_platform ? "Yes" : "No";

    const eventData = await dispatch(getEvent(event_schedule?.event_id));

    if (eventData?.is_success) {
      data.event_id = eventData?.event;
      dispatch(setEventList([eventData?.event, ...events]));
    }

    setSelectedScheduleEvent(data);
    setEditScheduleEventLoading(false);
    toggleModal();
  };

  const handleMinuteConversion = (d1, d2) => {
    if (!d1 && !d2) {
      return "";
    }
    const date1 = new Date(moment(d1).format("MM-DD-YYYY HH:mm:ss"));
    const date2 = new Date(moment(d2).format("MM-DD-YYYY HH:mm:ss"));
    return parseInt(Math.abs(date2 - date1) / (1000 * 60));
  };

  const handleLangugeData = async (rest, setFormvalues) => {
    const selectedLanguage = getLanguage();

    const data = await dispatch(getScheduleEvent(id));
    const { event_schedule } = data;

    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      languages: selectedLanguage,
      requirements: {
        ...rest.values.requirements,
        [selectedLanguage]: rest.values.requirements[selectedLanguage]?.length
          ? rest.values.requirements[selectedLanguage]
          : event_schedule?.requirements,
      },
    }));
  };

  const handleEditScheduleEvent = async (values) => {
    const { requirements } = values;
    let error = false;
    if (!values.is_course) {
      Object.keys(requirements).forEach((key) => {
        if (requirements[key].length) {
          requirements[key].forEach((val) => {
            const { title, description } = val;
            if (!title || !description) {
              dispatch(
                showSnackbar({
                  message: "Enter valid title or decription for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }
          });
        }
      });
    } else {
      Object.keys(requirements).forEach((key) => {
        if (requirements[key].length) {
          requirements[key].forEach((val) => {
            const { title, description } = val;
            if (!title) {
              dispatch(
                showSnackbar({
                  message: "Enter valid title or decription for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }

            description.forEach((desc) => {
              !desc &&
                dispatch(
                  showSnackbar({
                    message: "Enter valid  decription for requirements",
                    type: snackbarTypes.ERROR,
                  })
                );
            });
            error = true;
            return;
          });
        }
      });
    }

    if (!error) {
      const payload = {
        event_schedule: {
          event_id: values.event_id.id,
          starts_at: moment(values.startsAt).toDate().toString(),
          ends_at: moment(values.endsAt).toDate().toString(),
          repeat: values.repeat,
          status: values.status,
          language: values.language,
          mode: values.mode,
          attendees_limit: parseInt(values.attendeesLimit),
          current_attendees_limit: parseInt(values.current_attendees_limit),
          exceeds_percentage: parseInt(values.exceeds_percentage),
          increment_percentage: parseInt(values.increment_percentage),
          allow_booking_post_commencement: moment(values.startsAt)
            .add(parseInt(values.allow_booking_post_commencement), "minutes")
            .toString(),
          allow_seats_increment: moment(values.startsAt)
            .add(parseInt(values.allow_seats_increment), "minutes")
            .toString(),
          requirements: values.requirements,
          pre_event_file_cta: values.is_attachment ? values.cta_title : "",
          is_free: values?.type === "Free",
          type: values?.eventType,
          allow_discussions: values.allow_discussions,
          broadcast_mins: parseInt(values.broadcast_mins) || 0,
          after_popup_broadcast_mins:
            parseInt(values.after_popup_broadcast_mins) || 0,
          max_join_broadcast_attempts:
            parseInt(values.max_join_broadcast_attempts) || 0,
          broadcast_platform: values.streaming_type === "Yes" ? "Youtube" : "",
        },
        pre_event_file: values.is_attachment ? files[0] : {},
        host_ids: [values.hostIds.id],
        cohost_ids: values.cohostIds.map((cohost) => cohost.id),
      };
      setEditScheduleEventLoading(true);
      const is_success = await dispatch(editScheduleEvent(payload, id));
      setEditScheduleEventLoading(false);
      if (is_success) {
        dispatch(getScheduleEventList(filter));
        dispatch(clearFiles());
        toggleModal();
      }
    }
  };

  const toggleModal = () => {
    if (isOpenModal) {
      dispatch(setEventList([]));
    }
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editScheduleEvent,
    handleEditScheduleEvent,
    isOpenModal,
    toggleModal,
    events,
    users,
    getAutocompleteValues,
    editScheduleEventLoading,
    selectedScheduleEvent,
    handleLangugeData,
  };
};
