import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLanguage } from "../../utils/auth";
import { getInterestList, setInterestFilter } from "./actions";
import {
  getInterests,
  getInterestsCount,
  getInterestsFilter,
} from "./selectors";

export const useInterest = () => {
  clearLanguage();
  const dispatch = useDispatch();
  const interests = useSelector(getInterests);
  const interestCount = useSelector(getInterestsCount);
  const filters = useSelector(getInterestsFilter);
  const [loadingInterest, setLoadingInterest] = useState(false);

  useEffect(() => {
    const fetchInterests = async () => {
      setLoadingInterest(true);
      await dispatch(getInterestList(filters));
      setLoadingInterest(false);
    };
    fetchInterests();
  }, [dispatch, filters]);

  const setFilter = (key, value) => {
    dispatch(setInterestFilter({ key, value }));
  };

  return {
    interests,
    loadingInterest,
    setFilter,
    interestCount,
    filters,
  };
};
