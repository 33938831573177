export const getEvents = ({ eventReducer: { events = [] } = {} }) => events;
export const getEventsFilter = ({ eventReducer: { filter = {} } = {} }) =>
  filter;
export const getEventsCount = ({ eventReducer: { count = 0 } = {} }) => count;
export const getCategoriesSelector = ({
  eventReducer: { categories = [] } = {},
}) => categories;
export const getSubcategoriesSelector = ({
  eventReducer: { subcategories = [] } = {},
}) => subcategories;
export const getFaqCategorySelector = ({
  eventReducer: { faqCategories = [] } = {},
}) => faqCategories;
export const getReviews = ({ eventReducer: { eventReviews = {} } = {} }) =>
  eventReviews;
export const getReviewFilter = ({ eventReducer: { reviewFilter = [] } = {} }) =>
  reviewFilter;
export const getCategoryFilter = ({
  eventReducer: { categoryFilter = {} } = {},
}) => categoryFilter;
export const getSubcategoryFilter = ({
  eventReducer: { subcategoryFilter = {} } = {},
}) => subcategoryFilter;
export const getFaqCategoryFilter = ({
  eventReducer: { faqCategoryFilter = {} } = {},
}) => faqCategoryFilter;
