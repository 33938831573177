import { Dialog } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserHistory } from "../actions";
import { useUserHistory } from "./hooks";
const UserHistory = (props) => {
    const { isShowUserHistory } = useUserHistory();
    const data = useSelector(state => state?.userReducer?.userHistory)
    const dispatch = useDispatch();
    return ( 
        <Dialog open={isShowUserHistory} >
            <div onClick={()=> dispatch(getUserHistory([])) } style={{position:'absolute',right:'10px',top:'10px', cursor:'pointer'}} >
                <p>X</p>
            </div>
            <div style={{padding:'50px'}} >
                <div>
                Latest Timestamp : {moment(data[0]?.updated_at).format('DD MM YYYY')}
                </div>
                <div>
                BlackListed : {data[0]?.is_blacklisted ? 'Yes' : 'No'}
                </div>
                <div>
                UserId: {data[0]?.user_id}
                </div>
           </div>
        </Dialog>
     );
}
 
export default UserHistory;