import React from "react";
import Modals from "../../../components/modal";
import DeleteConfirmation from "../../../components/delete-confirmation";
import { useDeleteVoucher } from "./hooks";
import ActionButton from "../../../components/action-button";

const DeleteVoucher = ({ voucher }) => {
  const {
    deleteVoucherLoading,
    handleDeleteVoucher,
    isOpenModal,
    toggleModal,
  } = useDeleteVoucher(voucher);

  return (
    <>
      <ActionButton
        title="Delete"
        onClick={toggleModal}
        iconClassName={`fa fa-trash icon-btn-action text-danger `}
      />
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeleteVoucher}
              isLoading={deleteVoucherLoading}
            />
          }
          title="Delete Voucher"
        />
      ) : null}
    </>
  );
};

export default DeleteVoucher;
