import React from "react";
import { Formik, ErrorMessage } from "formik";
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Chip,
  Radio,
  RadioGroup,
  FormLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import { scheduleEventFormSchema } from "./validation-schema";
import { DataLoader } from "../../../components/loader";
import DateTimePicker from "../../../components/date-time-picker";
import {
  scheduleEventStatus,
  scheduleEventLanguage,
  scheduleEventMode,
  fieldsName,
  eventTypes,
  fieldInfo,
} from "../constant";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";
import { useEventScheduleForm } from "./hook";
import {
  fileContext,
  languages as langs,
  initialLanguageArrayValue,
} from "../../../constants";
import { useUploadFiles } from "../../../utils/upload-file/hooks";
import ProgressChip from "../../../components/progress-chip";
import { setLanguage } from "../../../utils/auth";
import moment from "moment";
import { InputProps } from "../../../components/input-props";
import MultiDatePicker from "../../../components/multi-date-picker";
import TimePicker from "../../../components/time-picker";
import ScrollToError from "../../../components/scroll-to-error";

const ScheduleEventForm = (props) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    events,
    handleFormUpdate,
    handleFormArrayUpdate,
    formValues,
    users,
    handleEventForm,
    isLoading,
    btn,
    handleAddClickRequirements,
    handleRemoveClickRequirements,
    handleFormUpdateRequirements,
    handleLangugeData,
    setFormValues,
    updateRequirement,
    copyLink,
    ref,
    setDateParams,
    handleOpenCalendar,
    scheduledDates,
  } = useEventScheduleForm(props);

  const {
    startsAt,
    endsAt,
    status,
    language,
    mode,
    booked,
    attendeesLimit,
    current_attendees_limit,
    exceeds_percentage,
    increment_percentage,
    allow_booking_post_commencement,
    allow_seats_increment,
    eventId,
    hostIds,
    cohostIds,
    requirements,
    title,
    description,
    attachment_file,
    is_attachment,
    cta_title,
    type,
    languages,
    eventType,
    allow_discussions,
    broadcast_mins,
    after_popup_broadcast_mins,
    max_join_broadcast_attempts,
    streaming_type,
    streaming_platform,
    dates,
  } = fieldsName;

  const {
    uploadError,
    files,
    handleDeleteAFile,
    isFileUploadData,
    handleAttachmentFile,
    uploadProgress,
  } = useUploadFiles();

  return (
    <Formik
      validateOnMount
      validationSchema={scheduleEventFormSchema}
      enableReinitialize
      initialValues={{
        startsAt:
          (formValues?.startsAt && new Date(formValues.startsAt)) || new Date(),
        endsAt:
          (formValues?.endsAt && new Date(formValues.endsAt)) || new Date(),
        status: formValues?.status || "",
        repeat: formValues?.repeat || "Once",
        languages: formValues?.languages || "en",
        mode: formValues?.mode || "Online",
        language: formValues?.language || "",
        allow_discussions: formValues?.allow_discussions || false,
        attendeesLimit: formValues?.attendeesLimit || "",
        current_attendees_limit: formValues?.current_attendees_limit || "",
        exceeds_percentage: formValues?.exceeds_percentage || "",
        increment_percentage: formValues?.increment_percentage || "",
        allow_booking_post_commencement:
          formValues?.allow_booking_post_commencement || "",
        allow_seats_increment: formValues?.allow_seats_increment || "",
        event_id: formValues?.event_id ? formValues?.event_id : null,
        hostIds:
          (formValues?.hostIds &&
            users.find((user) => user.id === formValues?.hostIds.id)) ||
          null,
        cohostIds:
          (formValues?.cohostIds &&
            users.filter((user) =>
              formValues.cohostIds?.find((cohost) => cohost.id === user.id)
            )) ||
          [],
        requirements: formValues?.requirements || initialLanguageArrayValue,
        is_attachment: formValues?.is_attachment ? true : false,
        attachment_file: files.filter(
          (file) => file.file_context === fileContext.EVENT_ATTACHMENT
        ),
        cta_title: formValues?.cta_title || "",
        type: formValues?.type || "",
        booked: formValues?.booked || 0,
        eventType: formValues?.eventType || "Public",
        broadcast_mins: formValues?.broadcast_mins || "",
        after_popup_broadcast_mins:
          formValues?.after_popup_broadcast_mins || "",
        max_join_broadcast_attempts:
          formValues?.max_join_broadcast_attempts || "",
        streaming_type: formValues?.streaming_type || "Yes",
        streaming_platform: "Youtube",
        dates: formValues.dates || [],
        is_edit: formValues.is_edit || false,
      }}
      onSubmit={handleEventForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          <ScrollToError refer={ref} />
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
            ref={ref}
          >
            {!isLoading && (
              <div className="materila-form_inline-child sticky__element">
                <TextField
                  className="material-form__field"
                  name={languages}
                  placeholder="Language"
                  label="Language"
                  select
                  value={rest.values.languages}
                  onChange={(event) => {
                    const { value } = event.target;
                    handleFormValueChange(rest, languages);
                    handleFormUpdate(event);
                    if (!rest.values.requirements[value]) {
                      setFieldValue(requirements, {
                        ...rest.values.requirements,
                        [value]: [],
                      });
                      setFormValues((prevFieldValue) => ({
                        ...prevFieldValue,
                        requirements: {
                          ...prevFieldValue.requirements,
                          [value]: [],
                        },
                      }));
                    }
                    if (btn === "Save") {
                      setLanguage(value);
                      handleLangugeData(rest, setFormValues);
                    }
                    if (btn === "Schedule") {
                      setLanguage(`${value}`);
                      rest.values.event_id &&
                        updateRequirement(rest.values.event_id, value);
                    }
                  }}
                  onFocus={() => handleFocusField(languages)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.languages}
                >
                  {langs.map((language) => (
                    <MenuItem
                      key={language.id}
                      className="material-form__option"
                      value={language.value}
                    >
                      {language.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === languages ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={languages}
                  />
                )}
              </div>
            )}
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={type}
                placeholder="Type"
                label="Type"
                disabled={formValues?.booked > 0}
                select
                onChange={(e) => {
                  handleFormUpdate(e);
                  handleFormValueChange(rest, type);
                }}
                onFocus={() => handleFocusField(type)}
                onBlur={() => handleBlurField()}
                value={rest.values.type}
              >
                {eventTypes.map((type) => (
                  <MenuItem
                    key={type.id}
                    className="material-form__option"
                    value={type.value}
                  >
                    {type.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === type ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={type}
                />
              )}
            </div>
            <div>
              <FormLabel component="fieldset" className="material-form__label">
                Event Type
              </FormLabel>

              <RadioGroup
                aria-label="eventType"
                defaultValue={rest.values.eventType}
                value={rest.values.eventType}
                name={eventType}
                row
                onChange={(e) => {
                  handleFormUpdate(e);
                  handleFormValueChange(rest, eventType);
                }}
              >
                <FormControlLabel
                  value="Public"
                  control={<Radio color="default" />}
                  label="Public"
                  className="material-form__radiogroup"
                />
                <FormControlLabel
                  value="Private"
                  control={<Radio className="label" color="default" />}
                  label="Private"
                  className="material-form__radiogroup"
                />
              </RadioGroup>
            </div>
            {rest.values.is_edit && rest?.values.eventType === "Private" && (
              <div className="schedule__copyUrl">
                <div>Private Event URL</div>
                <div>
                  <Tooltip
                    title="Click to copy"
                    placement="bottom"
                    onClick={() => copyLink(formValues?.event_schedule?.id)}
                  >
                    <i className="fa fa-copy fa-fw icon-btn-action"></i>
                  </Tooltip>
                </div>
              </div>
            )}
            <div>
              <Autocomplete
                options={events}
                getOptionLabel={(event) => (event.title ? event.title : "")}
                value={rest.values.event_id}
                onChange={(event, value) => {
                  setFieldValue(eventId, value);
                  setLanguage(`${rest.values.languages}`);
                  updateRequirement(value, rest.values.languages);
                  setFormValues((prev) => ({
                    ...prev,
                    event_id: value,
                  }));
                  // eslint-disable-next-line
                  !value && props?.handleEventsFilter("");
                }}
                onFocus={() => handleFocusField(eventId)}
                onBlur={() => {
                  handleBlurField();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={eventId}
                    placeholder="Select Event"
                    label="Select Event"
                    variant="standard"
                    onChange={(e) => props?.handleEventsFilter(e.target.value)}
                  />
                )}
              />
              {focusField === eventId ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={eventId}
                />
              )}
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                {rest.values.is_edit ? (
                  <DateTimePicker
                    selectedDate={rest.values.startsAt}
                    handleChange={(value) => {
                      setFieldValue(startsAt, value);
                      handleFormArrayUpdate(startsAt, value);
                    }}
                    id="startsAt"
                    name={startsAt}
                    label="Starts At"
                    customInput={
                      <TextField
                        className="material-form__field"
                        label="Starts At"
                        autoComplete="off"
                      />
                    }
                  />
                ) : (
                  <TimePicker
                    placeholder="Starts At"
                    label="Starts At"
                    time={rest.values.startsAt}
                    name={startsAt}
                    customInput={
                      <TextField
                        className="material-form__field"
                        id="startsAt"
                        label="Starts At"
                        autoComplete="off"
                      />
                    }
                    onChange={(time) => {
                      setFormValues((prev) => ({
                        ...prev,
                        startsAt: time,
                      }));
                      setDateParams((prev) => ({
                        ...prev,
                        starts_at: moment(time).toDate().toString(),
                      }));
                    }}
                  />
                )}
                {focusField === startsAt ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={startsAt}
                  />
                )}
              </div>
              <div className="materila-form_inline-child">
                {rest.values.is_edit ? (
                  <DateTimePicker
                    selectedDate={rest.values.endsAt}
                    handleChange={(value) => {
                      setFieldValue(endsAt, value);
                      handleFormArrayUpdate(endsAt, value);
                    }}
                    id="endsAt"
                    name={endsAt}
                    label="Ends At"
                    customInput={
                      <TextField
                        className="material-form__field"
                        label="Ends At"
                        autoComplete="off"
                      />
                    }
                  />
                ) : (
                  <TimePicker
                    placeholder="Ends At"
                    label="Ends At"
                    name={endsAt}
                    time={rest.values.endsAt}
                    customInput={
                      <TextField
                        className="material-form__field"
                        label="Ends At"
                        autoComplete="off"
                      />
                    }
                    onChange={(time) => {
                      setFormValues((prev) => ({
                        ...prev,
                        endsAt: time,
                      }));
                    }}
                  />
                )}
                {focusField === endsAt ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={endsAt}
                  />
                )}
              </div>
            </div>
            {!rest.values.is_edit && rest.values.event_id && (
              <div>
                <MultiDatePicker
                  name={dates}
                  className="custom-calendar"
                  dates={rest.values.dates}
                  onChange={(dts) => {
                    setFieldValue(dates, dts);
                    setFormValues((prev) => ({
                      ...prev,
                      dates: dts,
                    }));
                  }}
                  minDate={new Date()}
                  placeholder="Select Dates"
                  label="Select Dates"
                  onOpen={handleOpenCalendar}
                  onClose={handleOpenCalendar}
                  onYearChange={(dt) => {
                    setDateParams((prev) => ({
                      ...prev,
                      year_at: dt?.year,
                      month_at: dt?.month?.name,
                    }));
                  }}
                  onMonthChange={(dt) => {
                    setDateParams((prev) => ({
                      ...prev,
                      month_at: dt?.month?.name,
                      year_at: dt?.year,
                    }));
                  }}
                  mapDays={(dt) => {
                    const { date } = dt;
                    const is_exist = scheduledDates.find((d) => {
                      if (d) {
                        return (
                          new Date(d?.starts_at)?.getMonth() ===
                            new Date(date)?.getMonth() &&
                          new Date(d?.starts_at)?.getDate() ===
                            new Date(date)?.getDate()
                        );
                      }

                      return null;
                    });

                    let props = {
                      children: (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0 10px",
                            fontSize: "11px",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ textAlign: "start" }}>
                            {date.format("D")}
                          </div>
                          {is_exist ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {parseInt(is_exist?.count) > 2 ? (
                                <>
                                  {[...new Array(2)].map((item, i) => (
                                    <div
                                      key={i}
                                      className="dot"
                                      style={{
                                        textAlign: "center",
                                        background: "rgb(70, 196, 243)",
                                        width: "0.375em",
                                        height: "0.375em",
                                        borderRadius: "0.375em",
                                        margin: "0 0.0625em",
                                        display: "inline-block",
                                        verticalAlign: "top",
                                      }}
                                    />
                                  ))}
                                  <div>+</div>
                                </>
                              ) : (
                                [...new Array(parseInt(is_exist?.count))].map(
                                  (item, i) => (
                                    <div
                                      key={i}
                                      className="dot"
                                      style={{
                                        textAlign: "center",
                                        background: "rgb(70, 196, 243)",
                                        width: "0.375em",
                                        height: "0.375em",
                                        borderRadius: "0.375em",
                                        margin: "0 0.0625em",
                                        display: "inline-block",
                                        verticalAlign: "top",
                                      }}
                                    />
                                  )
                                )
                              )}
                            </div>
                          ) : null}
                        </div>
                      ),
                      title: parseInt(is_exist?.count)
                        ? `${parseInt(is_exist?.count)} ${
                            parseInt(is_exist?.count) > 1
                              ? "events are"
                              : "event is"
                          } scheduled`
                        : "",
                    };

                    return props;
                  }}
                />

                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={dates}
                />
              </div>
            )}

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={mode}
                  placeholder="Mode"
                  label="Mode"
                  select
                  value={rest.values.mode}
                  onChange={handleFormValueChange(rest, mode)}
                  onFocus={() => handleFocusField(mode)}
                  onBlur={() => handleBlurField()}
                >
                  {scheduleEventMode.map((mode) => (
                    <MenuItem
                      key={mode.id}
                      className="material-form__option"
                      value={mode.value}
                    >
                      {mode.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === mode ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={mode}
                  />
                )}
              </div>
            </div>
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={language}
                  placeholder="Language"
                  label="Language"
                  select
                  onChange={(e) => {
                    handleFormUpdate(e);
                    handleFormValueChange(rest, language);
                  }}
                  onFocus={() => handleFocusField(language)}
                  onBlur={() => handleBlurField()}
                  value={rest.values.language}
                >
                  {scheduleEventLanguage.map((language) => (
                    <MenuItem
                      key={language.id}
                      className="material-form__option"
                      value={language.value}
                    >
                      {language.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === language ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name="language"
                  />
                )}
              </div>
              {rest.values.is_edit && (
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={booked}
                    placeholder="Booked Seats"
                    label="Booked Seats"
                    value={rest.values.booked}
                    disabled
                  />
                </div>
              )}
            </div>
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={attendeesLimit}
                  placeholder="Optimum Batch Size"
                  label="Optimum Batch Size"
                  type="number"
                  value={rest.values.attendeesLimit}
                  onChange={(e) => {
                    handleFormUpdate(e);
                    handleFormValueChange(rest, attendeesLimit);
                  }}
                  onFocus={() => handleFocusField(attendeesLimit)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo.attendees_limit)}
                />
                {focusField === attendeesLimit ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={attendeesLimit}
                  />
                )}
              </div>
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={current_attendees_limit}
                  placeholder="Displayed Seats Count"
                  label="Displayed Seats Count"
                  type="number"
                  value={rest.values.current_attendees_limit}
                  onChange={(e) => {
                    handleFormUpdate(e);
                    handleFormValueChange(rest, current_attendees_limit);
                  }}
                  onFocus={() => handleFocusField(current_attendees_limit)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo.current_attendees_limit)}
                />
                {focusField === current_attendees_limit ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={current_attendees_limit}
                  />
                )}
              </div>
            </div>
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={exceeds_percentage}
                  placeholder="Threshold % for changing Displayed Seats Count"
                  label="Threshold % for changing Displayed Seats Count (in %)"
                  type="number"
                  value={rest.values.exceeds_percentage}
                  onChange={(e) => {
                    handleFormUpdate(e);
                    handleFormValueChange(rest, exceeds_percentage);
                  }}
                  onFocus={() => handleFocusField(exceeds_percentage)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo.exceeds_percentage)}
                />
                {focusField === exceeds_percentage ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={exceeds_percentage}
                  />
                )}
              </div>
            </div>
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={increment_percentage}
                  placeholder="% for updating new Displayed Seats Count"
                  label="% for updating new Displayed Seats Count (in %)"
                  type="number"
                  value={rest.values.increment_percentage}
                  onChange={(e) => {
                    handleFormUpdate(e);
                    handleFormValueChange(rest, increment_percentage);
                  }}
                  onFocus={() => handleFocusField(increment_percentage)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo.increment_percentage)}
                />
                {focusField === increment_percentage ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={increment_percentage}
                  />
                )}
              </div>
            </div>
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={allow_booking_post_commencement}
                  placeholder="Allow Booking Till"
                  label="Allow Booking Till (in minutes)"
                  type="number"
                  value={rest.values.allow_booking_post_commencement}
                  onChange={(e) => {
                    handleFormUpdate(e);
                    handleFormValueChange(
                      rest,
                      allow_booking_post_commencement
                    );
                  }}
                  onFocus={() =>
                    handleFocusField(allow_booking_post_commencement)
                  }
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo.allow_booking_till)}
                />
                {focusField === allow_booking_post_commencement ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={allow_booking_post_commencement}
                  />
                )}
              </div>
              {rest.values.is_edit ? (
                <div className="commencement__date">
                  {rest?.values?.startsAt &&
                  rest?.values?.allow_booking_post_commencement ? (
                    moment(rest.values.startsAt)
                      .add(
                        rest.values.allow_booking_post_commencement,
                        "minutes"
                      )
                      .format("DD/MM/YYYY, hh:mm A ")
                  ) : (
                    <div>mm/dd/yyyy, --:--</div>
                  )}
                </div>
              ) : null}
            </div>
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={allow_seats_increment}
                  placeholder="Seat Increment Till"
                  label="Seat Increment Till (in minutes)"
                  type="number"
                  value={rest.values.allow_seats_increment}
                  onChange={(e) => {
                    handleFormUpdate(e);
                    handleFormValueChange(rest, allow_seats_increment);
                  }}
                  onFocus={() => handleFocusField(allow_seats_increment)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo.seat_increment_till)}
                />
                {focusField === allow_seats_increment ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={allow_seats_increment}
                  />
                )}
              </div>
              {rest.values.is_edit ? (
                <div className="commencement__date">
                  {rest?.values?.startsAt &&
                  rest?.values?.allow_booking_post_commencement &&
                  rest?.values?.allow_seats_increment ? (
                    moment(rest.values.startsAt)
                      .add(rest.values.allow_seats_increment, "minutes")

                      .format("DD/MM/YYYY, hh:mm A ")
                  ) : (
                    <div>mm/dd/yyyy, --:--</div>
                  )}
                </div>
              ) : null}
            </div>

            <div className="materila-form_inline-child">
              <FormLabel component="fieldset" className="material-form__label">
                Broadcast
              </FormLabel>
              <RadioGroup
                aria-label="eventType"
                defaultValue={rest.values.streaming_type}
                value={rest.values.streaming_type}
                name={streaming_type}
                onChange={(e) => {
                  handleFormUpdate(e);
                  handleFormValueChange(rest, streaming_type);
                }}
                row
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio color="default" />}
                  label="Yes"
                  className="material-form__radiogroup"
                  disabled={rest?.values?.type === "Paid"}
                />
                <FormControlLabel
                  value="No"
                  control={<Radio className="label" color="default" />}
                  label="No"
                  className="material-form__radiogroup"
                />
              </RadioGroup>
            </div>
            {rest?.values?.streaming_type === "Yes" && (
              <div className="materila-form_inline-child">
                <FormLabel
                  component="fieldset"
                  className="material-form__label"
                >
                  Streaming Platform
                </FormLabel>
                <RadioGroup
                  aria-label="eventType"
                  defaultValue={rest.values.streaming_platform}
                  value={rest.values.streaming_platform}
                  name={streaming_platform}
                  onChange={(e) => {
                    handleFormUpdate(e);
                    handleFormValueChange(rest, streaming_platform);
                  }}
                  row
                >
                  <FormControlLabel
                    value="Youtube"
                    control={<Radio color="default" />}
                    label="You Tube"
                    className="material-form__radiogroup"
                  />
                </RadioGroup>
              </div>
            )}

            {rest?.values?.streaming_type === "Yes" && (
              <div className="material-form_inline-fields">
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={broadcast_mins}
                    placeholder="View Live Session"
                    label="View Live Session (in minutes)"
                    type="number"
                    value={rest.values.broadcast_mins}
                    onChange={(e) => {
                      handleFormUpdate(e);
                      handleFormValueChange(rest, broadcast_mins);
                    }}
                    onFocus={() => handleFocusField(broadcast_mins)}
                    onBlur={() => handleBlurField()}
                    InputProps={InputProps(fieldInfo.broadcast_mins)}
                  />
                  {focusField === broadcast_mins ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={broadcast_mins}
                    />
                  )}
                </div>
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={after_popup_broadcast_mins}
                    placeholder="Rewatch Live Session"
                    label="Rewatch Live Session (in minutes)"
                    value={rest.values.after_popup_broadcast_mins}
                    onChange={(e) => {
                      handleFormUpdate(e);
                      handleFormValueChange(rest, after_popup_broadcast_mins);
                    }}
                    onFocus={() => handleFocusField(after_popup_broadcast_mins)}
                    onBlur={() => handleBlurField()}
                    InputProps={InputProps(
                      fieldInfo.after_popup_broadcast_mins
                    )}
                  />
                  {focusField === after_popup_broadcast_mins ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={after_popup_broadcast_mins}
                    />
                  )}
                </div>
              </div>
            )}

            {rest?.values?.streaming_type === "Yes" && (
              <div className="material-form_inline-fields">
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={max_join_broadcast_attempts}
                    placeholder="Number of Times Second Pop-Up Shows"
                    label="Number of Times Second Pop-Up Shows"
                    type="number"
                    value={rest.values.max_join_broadcast_attempts}
                    onChange={(e) => {
                      handleFormUpdate(e);
                      handleFormValueChange(rest, max_join_broadcast_attempts);
                    }}
                    onFocus={() =>
                      handleFocusField(max_join_broadcast_attempts)
                    }
                    onBlur={() => handleBlurField()}
                    InputProps={InputProps(
                      fieldInfo.max_join_broadcast_attempts
                    )}
                  />
                  {focusField === max_join_broadcast_attempts ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={max_join_broadcast_attempts}
                    />
                  )}
                </div>
              </div>
            )}

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <Autocomplete
                  options={users}
                  getOptionLabel={(user) => (user.name ? user.name : "")}
                  value={rest.values.hostIds}
                  onChange={(event, value) => {
                    setFieldValue(hostIds, value);
                    handleFormArrayUpdate(hostIds, value);
                  }}
                  onFocus={() => handleFocusField(hostIds)}
                  onBlur={() => {
                    handleBlurField();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      name={hostIds}
                      placeholder="Select Host"
                      label="Select Host"
                      variant="standard"
                    />
                  )}
                />
                {focusField === hostIds ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={hostIds}
                  />
                )}
              </div>
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={status}
                  placeholder="Status"
                  label="Status"
                  select
                  onChange={(e) => {
                    handleFormUpdate(e);
                    handleFormValueChange(rest, status);
                  }}
                  onFocus={() => handleFocusField(status)}
                  onBlur={() => handleBlurField()}
                  value={rest.values.status}
                >
                  {scheduleEventStatus.map((status) => (
                    <MenuItem
                      key={status.id}
                      className="material-form__option"
                      value={status.value}
                    >
                      {status.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === status ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={status}
                  />
                )}
              </div>
            </div>
            <div>
              <Autocomplete
                multiple
                options={users}
                getOptionLabel={(user) => (user.name ? user.name : "")}
                value={rest.values.cohostIds}
                onChange={(event, value) => {
                  setFieldValue(cohostIds, value);
                  handleFormArrayUpdate(cohostIds, value);
                }}
                onFocus={() => handleFocusField(cohostIds)}
                onBlur={() => {
                  handleBlurField();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={cohostIds}
                    placeholder="Select Co-Host"
                    label="Select Co-Host"
                    variant="standard"
                  />
                )}
              />
              {focusField === cohostIds ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={cohostIds}
                />
              )}
            </div>
            {rest.values.event_id && (
              <div className="section__container">
                <div className="form__inline-items">
                  <div className="col-11 schedule__event_left_pad0">
                    <p className="bold-text">Requirements</p>
                  </div>

                  <div className="col-1 schedule__event_right_pad0">
                    <Tooltip title="Add" placement="bottom" className="">
                      <i
                        className={`fa fa-plus icon-btn-action text-success`}
                        onClick={() =>
                          handleAddClickRequirements(
                            rest,
                            setFieldValue,
                            rest.values.languages
                          )
                        }
                      ></i>
                    </Tooltip>
                  </div>
                </div>

                {rest?.values?.requirements[rest.values.languages].map(
                  (x, i) => {
                    return (
                      <>
                        <div
                          key={x + i}
                          className="form__inline-items align-items-center"
                        >
                          <div className="col-9 schedule__event_left_pad0">
                            <TextField
                              className="material-form__field"
                              name={`${requirements}.${i}.${title}`}
                              label="Title"
                              placeholder="Title"
                              value={x?.title}
                              onChange={(e) => {
                                const { value } = e.target;
                                handleFormUpdateRequirements(
                                  rest,
                                  "title",
                                  value,
                                  i
                                );
                              }}
                              onFocus={(e) => {
                                const { name } = e.target;
                                handleFocusField(name);
                              }}
                              onBlur={() => handleBlurField()}
                            />
                            {focusField ===
                            `${requirements}.${i}.${title}` ? null : (
                              <ErrorMessage
                                render={(msg) => (
                                  <div className="material-form__error">
                                    {msg}
                                  </div>
                                )}
                                name={`${requirements}.${i}.${title}`}
                              />
                            )}
                          </div>
                          <div className="col-md-2 form__language">
                            <Tooltip title="Language" placement="bottom">
                              <div>
                                {
                                  langs.find(
                                    (lang) =>
                                      lang.value === rest.values.languages
                                  ).displayText
                                }
                              </div>
                            </Tooltip>
                          </div>

                          <div className="col-1 schedule__event_right_pad0">
                            <Tooltip
                              title="Delete"
                              placement="bottom"
                              className="schedule__event_top_pad10"
                            >
                              <i
                                className={`fa fa-trash icon-btn-action text-danger`}
                                onClick={() =>
                                  handleRemoveClickRequirements(
                                    rest,
                                    setFieldValue,
                                    i
                                  )
                                }
                              ></i>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="form__inline-items align-items-center">
                          <div className="col-9 schedule__event_left_pad0">
                            <TextField
                              className="material-form__field"
                              multiline
                              name={`${requirements}.${i}.${description}`}
                              label="Description"
                              placeholder="Description"
                              value={x?.description}
                              onChange={(e) => {
                                const { value } = e.target;
                                handleFormUpdateRequirements(
                                  rest,
                                  "description",
                                  value,
                                  i
                                );
                              }}
                              onFocus={(e) => {
                                const { name } = e.target;
                                handleFocusField(name);
                              }}
                              onBlur={() => handleBlurField()}
                            />
                            {focusField ===
                            `${requirements}.${i}.${description}` ? null : (
                              <ErrorMessage
                                render={(msg) => (
                                  <div className="material-form__error">
                                    {msg}
                                  </div>
                                )}
                                name={`${requirements}.${i}.${description}`}
                              />
                            )}
                          </div>
                          <div className="col-md-2 form__language">
                            <Tooltip title="Language" placement="bottom">
                              <div>
                                {
                                  langs.find(
                                    (lang) =>
                                      lang.value === rest.values.languages
                                  ).displayText
                                }
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rest.values.is_attachment}
                    onChange={(event) => {
                      handleFormUpdate(event);
                    }}
                    name={is_attachment}
                    color="default"
                  />
                }
                label="Attachment"
              />
            </div>

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rest.values.allow_discussions}
                    onChange={(event) => {
                      handleFormUpdate(event);
                    }}
                    name={allow_discussions}
                    color="default"
                  />
                }
                label="Allow DIscussions"
              />
            </div>

            {rest.values.is_attachment && (
              <>
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={cta_title}
                    placeholder="CTA Title"
                    label="CTA Title"
                    type="text"
                    value={rest.values.cta_title}
                    onChange={(event) => {
                      handleFormValueChange(rest, cta_title);
                      handleFormUpdate(event);
                    }}
                    onFocus={() => handleFocusField(cta_title)}
                    onBlur={() => handleBlurField()}
                  />
                  {focusField === cta_title ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={cta_title}
                    />
                  )}
                </div>

                <div className="form__form-group-field mt-1">
                  <div className="form__form-group-input-wrap">
                    <div className="form__form-group-file">
                      <label htmlFor="attachment_file">Upload Attachment</label>
                      <span>
                        {files
                          .filter(
                            (file) =>
                              file.file_context === fileContext.EVENT_ATTACHMENT
                          )
                          .map((x) => {
                            return (
                              <Chip
                                className="selected-file"
                                key={x.uuid_file_name}
                                label={x.original_file_name}
                                onDelete={() => handleDeleteAFile(x)}
                                variant="outlined"
                              />
                            );
                          })}

                        {isFileUploadData
                          .filter(
                            (file) =>
                              file.fileContext === fileContext.EVENT_ATTACHMENT
                          )
                          .map((x, i) => {
                            return (
                              <>
                                {x.isUpload && (
                                  <ProgressChip
                                    fileName={x.fileName}
                                    uploadProgress={uploadProgress}
                                  />
                                )}
                              </>
                            );
                          })}
                      </span>
                      <input
                        accept="application/pdf"
                        onBlur={rest.handleBlur}
                        type="file"
                        name={attachment_file}
                        id="attachment_file"
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        onChange={(event) => {
                          handleAttachmentFile(event.currentTarget.files[0]);
                          setFieldValue(
                            attachment_file,
                            files.filter(
                              (file) =>
                                file.file_context ===
                                fileContext.EVENT_ATTACHMENT
                            )
                          );
                        }}
                      />
                    </div>
                    {rest.errors.attachment_file ? (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error mt-1">{msg}</div>
                        )}
                        name={attachment_file}
                      />
                    ) : (
                      <div className="material-form__error">{uploadError}</div>
                    )}
                  </div>
                </div>
              </>
            )}
            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default ScheduleEventForm;
