import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../../../components/modal";
import DeleteConfirmation from "../../../../../components/delete-confirmation";
import { useDeleteVideo } from "./hooks";
const DeletePlan = ({ video }) => {
  const {
    deleteLoading,
    handleDelete,
    isOpenModal,
    toggleModal,
  } = useDeleteVideo(video);

  return (
    <>
      <Tooltip title="Delete" placement="bottom">
        <i
          className={`fa fa-trash icon-btn-action text-danger `}
          onClick={toggleModal}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDelete}
              isLoading={deleteLoading}
            />
          }
          title="Delete Plan"
        />
      ) : null}
    </>
  );
};

export default DeletePlan;
