import { useDispatch, useSelector } from "react-redux";
import { setPartnerFilter } from "../actions";
import { getPartners } from "../selectors";
import _ from "lodash";
import { useCallback } from "react";

export const useOfferForm = (props) => {
  const partners = useSelector(getPartners);
  const dispatch = useDispatch();

  const setPartnerFilters = useCallback(
    _.debounce((key, value) => {
      dispatch(setPartnerFilter({ key, value }));
    }, 600),
    []
  );

  const handleLanguage = (rest, key, value, setFieldValue) => {
    let val = { ...rest.values[key], [rest.values.language]: value };
    setFieldValue(key, val);
  };

  return {
    partners,
    setPartnerFilters,
    handleLanguage,
  };
};
