import { api, apiEndPoints } from "../../../api";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";

const namespace = "containers/CMS/settings";

export const SET_CONFIG = `${namespace}/SET_CONFIG`;

const setConfigList = (payload) => ({
  type: SET_CONFIG,
  payload,
});

export const addConfig = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addConfig, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getConfigs = () => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getConfigs);
    const { data, data: { is_success, configs } = {} } = response;
    if (is_success) {
      dispatch(setConfigList(data));
      return configs;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updateConfig = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateConfig(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};
