import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useUser } from "../../../common-hooks/user";

const SidebarLink = ({ title, icon, newLink, route, onClick, access }) => {
  const { role } = useUser();
  if (!access.includes(role)) {
    return null;
  }
  return (
    <NavLink
      to={route}
      onClick={onClick}
      activeClassName="sidebar__link-active"
      exact
    >
      <li className="sidebar__link">
        {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ""}
        <p className="sidebar__link-title">
          {title}
          {newLink ? (
            <Badge className="sidebar__link-badge">
              <span>New</span>
            </Badge>
          ) : (
              ""
            )}
        </p>
      </li>
    </NavLink>
  );
};
SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
  access: PropTypes.array.isRequired,
};

SidebarLink.defaultProps = {
  icon: "",
  newLink: false,
  route: "/",
  onClick: () => { },
};

export default SidebarLink;
