import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Chip from "@material-ui/core/Chip";
import _ from "lodash";
import userLogo from "../../../assets/images/user-icon.png";
import Accordion from "../../../components/Accordion";
import { useShowReviews } from "./hooks";

const ReviewTabs = ({
  tabs,
  tabsData,
  status,
  eventScheduleId,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const { changeReviewList } = useShowReviews(eventScheduleId);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      changeReviewList(tab);
    }
  };

  const accordionTitle = (tabData, tab, tabIndex) => (
    <div
      className="attendees__list user-list user-list"
      key={`${tabData.id}+${tabIndex}`}
    >
      <div className="attendees__contact-avatar">
        <img src={tabData?.profile_picture?.url || userLogo} alt="profile" />
      </div>
      <div className="attendees__contact-preview">
        <p className="attendees__contact-name">{tabData?.name}</p>
        <div>
          {Array.from(Array(5).keys()).map((index) => (
            <span
              key={index}
              className={`fa fa-star ${tabData?.review?.rating > index ? "review-star-checked" : null
                }`}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="tabs tabs--justify tabs--bordered-bottom review-schedule-event-container">
      <div className="tabs__wrap">
        <Nav tabs>
          {tabs.map((tab, index) => (
            <NavItem key={`${index}-${tab}`}>
              <NavLink
                className={activeTab === tab ? "active" : null}
                onClick={() => {
                  toggle(tab);
                }}
              >
                {_.startCase(_.toLower(tab))}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {tabs.map((tab, index) => (
            <TabPane tabId={tab} key={tab}>
              {tabsData.length > 0 ? (
                tabsData.map((tabData, index) => (
                  <div key={`${tabData.id}+${index}`}>
                    <Accordion
                      title={accordionTitle(tabData, tab, index)}
                      className="with-shadow"
                    >
                      <div className="schedule-event__accordion-conatiner">
                        <p className="scheedule-event__review-text">
                          {tabData?.review?.text}
                        </p>
                        {tabData?.review?.feedback?.qna?.length > 0 && (
                          <div className="scheedule-event__qna-container">
                            {tabData?.review?.feedback?.qna?.map(
                              (qna, index) => (
                                <div
                                  className="qna-sub-container"
                                  key={`${qna.question}+${index}`}
                                >
                                  <span className="question">
                                    {qna.question}
                                  </span>{" "}
                                  -<span className="answer">{qna.answer}</span>
                                </div>
                              )
                            )}
                          </div>
                        )}
                        {tabData?.review?.feedback?.tags?.length > 0 && (
                          <div className="scheedule-event__tags-container">
                            {tabData?.review?.feedback?.tags?.map(
                              (tag, index) => (
                                <div className="tags" key={`${tag}+${index}`}>
                                  <Chip label={tag} />
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </Accordion>
                  </div>
                ))
              ) : (
                <div className="schedule-event__no-review">No Reviews </div>
              )}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </div>
  );
};

export default ReviewTabs;
