import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editCategory,
  getCategory,
  getCategoryList,
  getInterestList,
} from "../actions";
import { clearFiles, setFiles } from "../../../utils/upload-file/actions";
import { initialLanguageFieldValue } from "../../../constants";
import { clearLanguage, getLanguage } from "../../../utils/auth";

export const useEditCategory = ({ id }) => {
  const dispatch = useDispatch();
  const { filter, interests } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );
  const files = useSelector(({ fileReducer }) => fileReducer.files);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editCategoryLoading, setEditCategoryLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();

  const getCategoryFieldData = async () => {
    const selectedLanguage = getLanguage();
    setEditCategoryLoading(true);
    dispatch(clearFiles());
    const data = await dispatch(getCategory(id));
    data?.icon?.original_file_name && dispatch(setFiles(data?.icon));

    const is_success = await dispatch(getInterestList());

    setEditCategoryLoading(false);
    if (data && is_success) {
      data.id = data?.category?.id;
      data.created_at = data?.category?.created_at;
      data.created_by = data?.category?.created_by;
      data.description = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data?.category?.description,
      };
      data.status = data?.category?.status;
      data.title = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data?.category?.title,
      };
      data.type = data?.category?.type;
      data.updated_at = data?.category?.updated_at;
      data.updated_by = data?.category?.updated_by;
      data.is_popular = data?.category?.is_popular;
      data.language = selectedLanguage;
      data.is_used = data?.category?.is_used;

      setSelectedCategory(data);
      toggleModal();
    }
  };

  const handleLanguge = async (rest, setFormvalues) => {
    const selectedLanguage = getLanguage();
    setEditCategoryLoading(true);
    const data = await dispatch(getCategory(id));

    setEditCategoryLoading(false);
    if (data) {
      data.id = data?.category?.id;
      data.created_at = data?.category?.created_at;
      data.created_by = data?.category?.created_by;
      data.description = {
        ...rest.values.description,
        [selectedLanguage]:
          rest.values.description[selectedLanguage] ||
          data?.category?.description ||
          "",
      };
      data.status = data?.category?.status;
      data.title = {
        ...rest.values.title,
        [selectedLanguage]:
          rest.values.title[selectedLanguage] || data?.category?.title || "",
      };
      data.type = data?.category?.type;
      data.updated_at = data?.category?.updated_at;
      data.updated_by = data?.category?.updated_by;
      data.is_popular = data?.category?.is_popular;
      data.language = selectedLanguage;
      data.is_used = data?.category?.is_used;

      setFormvalues(data);
    }
  };

  const handleEditCategory = async (values) => {
    try {
      const payload = {
        category: {
          title: values.title,
          description: values.description,
          type: values.type,
          status: values.status,
          is_popular: values.is_popular,
        },
        interest_ids: values.interestIds.map((interest) => interest.id),
        file: values.type === "Blog" || values.type === "Event" ? files[0] : {},
      };
      setEditCategoryLoading(true);
      const is_success = await dispatch(editCategory(payload, id));
      setEditCategoryLoading(false);
      if (is_success) {
        dispatch(getCategoryList(filter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editCategoryLoading,
    handleEditCategory,
    isOpenModal,
    toggleModal,
    getCategoryFieldData,
    selectedCategory,
    interests,
    handleLanguge,
  };
};
