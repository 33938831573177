import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPolicy, getPoliciesList } from "../actions";
import { getPoliciesFilter } from "../selectors";

export const useAddPolicy = () => {
  const dispatch = useDispatch();
  const filter = useSelector(getPoliciesFilter);
  const { policies } = useSelector(({ policyReducer }) => policyReducer);

  const [addPolicyLoading, setAddPolicyLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleAddPolicy = async (values) => {
    try {
      const payload = {
        name: values.name,
        title: values.title,
        description: values.description,
      };
      setAddPolicyLoading(true);
      const is_success = await dispatch(addPolicy(payload));
      setAddPolicyLoading(false);
      if (is_success) {
        dispatch(getPoliciesList(filter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    addPolicyLoading,
    policies,
    handleAddPolicy,
    isOpenModal,
    toggleModal,
    filter,
  };
};
