import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCategory, getCategoryList, getInterestList } from "../actions";
import { clearFiles } from "../../../utils/upload-file/actions";
import { clearLanguage } from "../../../utils/auth";

export const useAddCategory = () => {
  const dispatch = useDispatch();

  const [addCategoryLoading, setAddCategoryLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const files = useSelector(({ fileReducer }) => fileReducer.files);

  const { filter, interests } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );

  const getInterests = async () => {
    setAddCategoryLoading(true);
    const is_success = await dispatch(getInterestList());
    setAddCategoryLoading(false);
    if (is_success) {
      toggleModal();
    }
  };

  const handleAddCategory = async (values) => {
    try {
      const payload = {
        category: {
          title: values.title,
          description: values.description,
          type: values.type,
          status: values.status,
          is_popular: values.is_popular,
        },
        interest_ids: values.interestIds.map((interest) => interest.id),
        file: values.type === "Blog" || values.type === "Event" ? files[0] : {},
      };
      setAddCategoryLoading(true);
      const is_success = await dispatch(addCategory(payload));
      setAddCategoryLoading(false);
      if (is_success) {
        dispatch(getCategoryList(filter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    addCategoryLoading,
    handleAddCategory,
    isOpenModal,
    toggleModal,
    getInterests,
    interests,
  };
};
