import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteBlogData, getBlogList } from "../actions";
import { getBlogFilter } from "../selectors";

export const useDeleteBlog = (id) => {
  const dispatch = useDispatch();
  const filter = useSelector(getBlogFilter);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteBlogLoading, setDeleteBlogLoading] = useState(false);

  const handleDeleteBlog = async () => {
    setDeleteBlogLoading(true);
    const is_success = await dispatch(deleteBlogData(id));
    setDeleteBlogLoading(false);
    if (is_success) {
      dispatch(getBlogList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteBlogLoading,
    handleDeleteBlog,
    isOpenModal,
    toggleModal,
  };
};
