import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useSharedData } from "./hooks";
import Modals from "../../../../../components/modal";
import List from "./list";
import { Loader } from "../../../../../components/loader";

const ShareList = (props) => {
  const {
    data,
    isLoading,
    fetchData,
    isOpenModal,
    toggleModal,
    totalCount,
    handleDataParams,
    post,
  } = useSharedData(props);

  return (
    <div>
      <Tooltip title="Click to view" placement="bottom">
        {post?.stats?.shares_count > 0 ? (
          <span className="schedule__booked-info" onClick={fetchData}>
            {post?.stats?.shares_count} shares
          </span>
        ) : (
          <span>NA</span>
        )}
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            isLoading ? (
              <Loader />
            ) : (
              <List
                list={data}
                totalCount={totalCount}
                handleDataParams={handleDataParams}
              />
            )
          }
          title="Shares"
        />
      ) : null}
    </div>
  );
};

export default ShareList;
