export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Blog Title",
    accessor: "trackable_details.name",
    disableGlobalFilter: true,
  },
  {
    Header: "Commented By",
    accessor: "commenter.name",
    disableGlobalFilter: true,
  },
  {
    Header: "Comment",
    accessor: "text",
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 70,
  },
];

export const statusOptions = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
  {
    id: 3,
    value: "In Active",
    displayText: "In Active",
  },
];

export const commentsStatusOptions = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
  {
    id: 3,
    value: "Pending",
    displayText: "Pending",
  },
];

export const pageSize = 10;
