export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Description",
    accessor: "description",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 70,
  },
  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];

export const subcategoryStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
];

export const fieldsName = {
  title: "title",
  description: "description",
  type: "type",
  status: "status",
  language: "language",
};
