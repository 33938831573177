export const getStatsList = ({ financeReducer: { stats = {} } = {} }) => stats;
export const getStatsFilter = ({
  financeReducer: { stats_filter = {} } = {},
}) => stats_filter;

export const getPaymentHistoriesList = ({
  financeReducer: { payment_histories = {} } = {},
}) => payment_histories;

export const getPaymentHistoriesFilter = ({
  financeReducer: { payment_history_filter = {} } = {},
}) => payment_history_filter;

export const getCount = ({
    financeReducer: { count = {} } = {},
  }) => count;