import { useEffect, useState } from "react";

export const useTextfieldChips = ({
  initialChips,
  onDataChange,
  name = "Tags",
  maxLength,
  maxTextLength,
}) => {
  const [value, setValue] = useState("");
  const [chips, setChips] = useState(initialChips);
  useEffect(() => {
    setChips(initialChips);
  }, [initialChips]);

  const handleTextfieldChange = (event) => {
    if (["Enter", "Tab"].includes(event.key)) {
      event.preventDefault();
      var value = event.target.value.trim();

      if (value) {
        if (chips.includes(value)) {
          setValue("");
        } else if (!maxLength) {
          setChips([...chips, value]);
          setValue("");
          onDataChange([...chips, value]);
        } else if (chips.length < maxLength) {
          setChips([...chips, value]);
          setValue("");
          onDataChange([...chips, value]);
        } else {
          setValue("");
        }
      }
    } else {
      if (maxTextLength && event.target.value.length < maxTextLength) {
        setValue(event.target.value);
      } else if (maxTextLength && event.target.value.length > maxTextLength) {
      } else {
        setValue(event.target.value);
      }
    }
  };

  const handleRemove = (chip) => {
    setChips(chips.filter((i) => i !== chip));
    onDataChange(chips.filter((i) => i !== chip));
  };

  return {
    handleTextfieldChange,
    handleRemove,
    value,
    chips,
    name,
    maxTextLength,
  };
};
