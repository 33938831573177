import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePageData, getPageList } from "../actions";
import { getPageFilter } from "../selectors";

export const useDeletePage = (id) => {
  const dispatch = useDispatch();
  const filter = useSelector(getPageFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deletePageLoading, setDeletePageLoading] = useState(false);

  const handleDeletePage = async () => {
    setDeletePageLoading(true);
    const is_success = await dispatch(deletePageData(id));
    setDeletePageLoading(false);
    if (is_success) {
      dispatch(getPageList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deletePageLoading,
    handleDeletePage,
    isOpenModal,
    toggleModal,
  };
};
