import React from "react";
import { Route, Switch } from "react-router-dom";

import Policy from "../../../CMS/policy";

export default () => (
  <Switch>
    <Route path="/policy" component={Policy} />
  </Switch>
);
