import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Pages from '../../../CMS/blog/pages';
import AddPage from '../../../CMS/blog/pages/add-page';
import EditPage from '../../../CMS/blog/pages/edit-page';

export default () => (
  <Switch>
    <Route path="/pages" component={Pages} />
    <Route path="/add-page" component={AddPage} />
    <Route path="/edit-page/:page_id" component={EditPage} />
  </Switch>
);
