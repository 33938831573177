import {
  SET_EVENT_LIST,
  SET_EVENT_FILTER,
  SET_CATEGORIES_LIST,
  SET_SUBCATEGORIES_LIST,
  RESET_EVENT_FILTER,
  SET_EVENT_REVIEWS,
  SET_FAQ_CATEGORIES_LIST,
} from "./actions";

const initialState = {
  events: [],
  categories: [],
  subcategories: [],
  faqCategories: [],
  count: 0,
  eventReviews: [],
  categoryFilter: {
    type: "Event",
    status: "Published",
  },
  subcategoryFilter: {
    type: "Event",
    status: "Published",
  },
  faqCategoryFilter: {
    type: "Faq",
    status: "Published",
  },
  reviewFilter: {
    statuses: [],
    rating: [0, 1, 2, 3, 4, 5],
    order_by: ["created_at desc"],
    limit: 1000,
    skip: 0,
  },
  filter: {
    text: "",
    is_course: undefined,
    status: "Published",
    limit: 10,
    skip: 0,
  },
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENT_LIST:
      return {
        ...state,
        events: action.payload.events,
        count: action.payload.count || [],
      };
    case SET_CATEGORIES_LIST:
      return { ...state, categories: action.payload || [] };
    case SET_SUBCATEGORIES_LIST:
      return { ...state, subcategories: action.payload || [] };
    case SET_FAQ_CATEGORIES_LIST:
      return { ...state, faqCategories: action.payload || [] };
    case SET_EVENT_FILTER: {
      const { key, value } = action.payload;
      if (key === "is_course" && value === "All") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: undefined,
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case SET_EVENT_REVIEWS:
      return {
        ...state,
        eventReviews: action.payload,
      };
    case RESET_EVENT_FILTER:
      return { ...state, filter: initialState.filter || [] };
    default:
      return state;
  }
};

export default eventReducer;
