import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { useUserSettings } from "./hooks";
import * as moment from "moment";
import Accordion from "../../../../components/Accordion";

const UserSettings = ({ selectedUser }) => {
  const { copyLink } = useUserSettings();
  return (
    <Col md={12} lg={12} xl={12}>
      <Accordion
        className="user__tabs_collapse"
        title={"settings"}
        titleClassName="profile__current-tasks-title"
      >
        <Card>
          <CardBody className="profile__card">
            <div className="profile__current-task">
              <div className="col-7 user_details_padLeft0">
                Registration Date
              </div>
              <div className="col-5 user_details_pad0">
                {selectedUser?.user?.created_at
                  ? moment(new Date(selectedUser?.user?.created_at)).format(
                      "DD/MM/YYYY"
                    )
                  : "NA"}
              </div>
            </div>
            <div className="profile__current-tasks">
              <div className="profile__current-task">
                <div className="col-11 user_details_padLeft0">
                  WhatsApp Notification
                </div>

                <div className="col-1 user_details_pad0">
                  {selectedUser?.profile?.opted_for_whatsapp ? (
                    <Tooltip title="Opted" placement="bottom">
                      <p className="opt_in_whatsapp_green">
                        <i className="fa fa-whatsapp fa-fw"></i>
                      </p>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Not Opted" placement="bottom">
                      <p className="opt_in_whatsapp_red">
                        <i className="fa fa-whatsapp fa-fw icon-btn-action"></i>
                      </p>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="profile__current-task">
                <div className="col-9 user_details_padLeft0">Referal Code</div>
                <div className="col-3 user_details_pad0">
                  {selectedUser?.profile?.referral_code
                    ? selectedUser?.profile?.referral_code
                    : "NA"}
                </div>
              </div>
              <div className="profile__current-task">
                <div className="col-9 user_details_padLeft0">Referal Link</div>
                <div className="col-3 user_details_pad0">
                  <Tooltip title="Click to copy" placement="bottom">
                    <i
                      onClick={() =>
                        copyLink(selectedUser?.profile?.referral_link)
                      }
                      className="fa fa-copy fa-fw icon-btn-action"
                    ></i>
                  </Tooltip>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Accordion>
    </Col>
  );
};

export default UserSettings;
