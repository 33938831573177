import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import { fieldsName, policyName } from "../constant";
import { DataLoader } from "../../../../components/loader";
import { policyFormSchema } from "./validation-schema";
import TextEditor from "../../../../components/text-editor";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../utils/form";

const formField = {
  name: "",
  title: "",
  description: "",
};

const PolicyForm = ({ fieldValues, handlePolicyForm, btn, isLoading }) => {
  const {
    focusField,
    handleFocusField,
    handleBlurField,
  } = useHandleFieldFocus();

  const { name, title, description } = fieldsName;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = async (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={policyFormSchema}
      initialValues={{
        name: formValues?.name || "",
        title: formValues?.title || "",
        description: formValues?.description || "",
      }}
      onSubmit={handlePolicyForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={name}
                placeholder="Name"
                label="Name"
                select
                value={rest.values.name}
                onChange={(event) => {
                  handleFormValueChange(rest, name);
                  handleFormUpdate(name, event.target.value);
                }}
                onFocus={() => handleFocusField(name)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.name}
              >
                {policyName.map((name) => (
                  <MenuItem
                    key={name.id}
                    className="material-form__option"
                    value={name.value}
                  >
                    {name.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === name ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={name}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={title}
                placeholder="Title"
                label="Title"
                type="text"
                value={rest.values.title}
                onChange={(event) => {
                  handleFormValueChange(rest, title);
                  handleFormUpdate(title, event.target.value);
                }}
                onFocus={() => handleFocusField(title)}
                onBlur={() => handleBlurField()}
              />
              {focusField === title ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={title}
                />
              )}
            </div>

            <div className="policy__container">
              <p className="custom__title_policy">Description</p>
              <TextEditor
                initialValues={rest.values.description}
                onChange={(value) => {
                  setFieldValue(description, value);
                  handleFormUpdate(description, value);
                }}
              />
              {focusField === description ? null : (
                <>
                  <br />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={description}
                  />
                </>
              )}
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default PolicyForm;
