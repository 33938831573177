export const columns = [
  {
    Header: " Order ID",
    accessor: "id",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "User ID",
    accessor: "user_id",
    width: 80,
  },
  {
    Header: "Name",
    accessor: "user_name",
  },
  {
    Header: "Tranaction ID",
    width: 180,
    accessor: "transaction_id",
  },
  {
    Header: "Plan",
    accessor: "plan_variant.plan.title",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "Details",
    accessor: "plan_details",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "Gross Amount",
    accessor: "gross_amount",
    width: 120,
    disableGlobalFilter: true,
  },
  {
    Header: "GST",
    accessor: "gst",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "Amount",
    accessor: "amount",
    width: 80,
    disableGlobalFilter: true,
  },

  {
    Header: "Status",
    accessor: "status",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "Payment Gateway",
    accessor: "gateway",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "Transaction Date",
    accessor: "created_at",
    width: 180,
    disableGlobalFilter: true,
  },
  {
    Header: "Transaction Completed Date",
    accessor: "completed_at",
    width: 190,
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    width: 80,
    disableGlobalFilter: true,
  },
];

export const cardsConstant = {
  TODAY: "Today",
  THIS_MONTH: "This Month",
  THIS_QUARTER: "This Quarter",
  THIS_YEAR: "This Financial Year",
};

export const paymentStatus = [
  {
    id: 1,
    value: "Success",
    displayText: "Success",
  },
  {
    id: 2,
    value: "Failed",
    displayText: "Failed",
  },
  {
    id: 3,
    value: "Expired",
    displayText: "Expired",
  },
  {
    id: 4,
    value: "Pending",
    displayText: "Pending",
  },
];
