import React from "react";
import { useAddSection } from "./hooks";
import SectionForm from "../form/section-form";
import Modals from "../../../../../components/modal";
import Tooltip from "@material-ui/core/Tooltip";
import { setLanguage } from "../../../../../utils/auth";

const EditSection = ({ section }) => {
  const {
    handleEditSection,
    editSectionLoading,
    selectedSection,
    toggleModal,
    isOpenModal,
    handleEditClick,
    handleLanguageData,
  } = useAddSection(section);
  return (
    <>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action `}
          onClick={() => {
            setLanguage("en");
            handleEditClick();
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <SectionForm
              fieldValues={selectedSection}
              handleSectionForm={handleEditSection}
              btn="Save"
              isLoading={editSectionLoading}
              handleLanguageData={handleLanguageData}
            />
          }
          title="Edit Section"
        />
      ) : null}
    </>
  );
};

export default EditSection;
