import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteLabelData, getLabelList } from "../actions";
import { getLabelFilter } from "../selectors";

export const useDeleteLabel = (id) => {
  const dispatch = useDispatch();
  const filter = useSelector(getLabelFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteLabelLoading, setDeleteLabelLoading] = useState(false);

  const handleDeleteLabel = async () => {
    setDeleteLabelLoading(true);
    const is_success = await dispatch(deleteLabelData(id));
    setDeleteLabelLoading(false);
    if (is_success) {
      dispatch(getLabelList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteLabelLoading,
    handleDeleteLabel,
    isOpenModal,
    toggleModal,
  };
};
