export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
    disableGlobalFilter: true,
  },
  {
    Header: "Country Code",
    accessor: "country_code",
    disableGlobalFilter: true,
    width: 70,
  },
  {
    Header: "Mobile Number",
    accessor: "mobile_number",
    disableGlobalFilter: true,
  },
  {
    Header: "Age Group",
    accessor: "age_group",
    disableGlobalFilter: true,
  },

  {
    Header: "Role",
    accessor: "role",
    disableGlobalFilter: true,
    width: 100,
  },
  {
    Header: "Blacklisted",
    accessor: "is_blacklisted",
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: "Active",
    accessor: "is_active",
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: "Delete",
    accessor: "is_deleted",
    disableGlobalFilter: true,
    width: 60,
  },

  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];

export const ReportedUsercolumns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
    disableGlobalFilter: true,
  },
  {
    Header: "Country Code",
    accessor: "country_code",
    disableGlobalFilter: true,
    width: 70,
  },
  {
    Header: "Mobile Number",
    accessor: "mobile_number",
    disableGlobalFilter: true,
  },
  {
    Header: "Age Group",
    accessor: "age_group",
    disableGlobalFilter: true,
  },
  {
    Header: "Reported",
    accessor: "reports",
    disableGlobalFilter: true,
  },

  {
    Header: "Role",
    accessor: "role",
    disableGlobalFilter: true,
    width: 100,
  },
  {
    Header: "Active",
    accessor: "is_active",
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];

export const userRoles = [
  {
    id: 1,
    value: "Admin",
    displayText: "Admin",
  },
  {
    id: 2,
    value: "Member",
    displayText: "Member",
  },
  {
    id: 3,
    value: "Coordinator",
    displayText: "Coordinator",
  },
  {
    id: 4,
    value: "Moderator",
    displayText: "Moderator",
  },
  {
    id: 5,
    value: "Customer Support Specialist",
    displayText: "Customer Support Specialist",
  },
];

export const userTitles = [
  {
    id: 1,
    value: "Mr.",
    displayText: "Mr.",
  },
  {
    id: 2,
    value: "Ms.",
    displayText: "Ms.",
  },
  {
    id: 3,
    value: "Dr.",
    displayText: "Dr.",
  },
];

export const userGenders = [
  {
    id: 1,
    value: "Male",
    displayText: "Male",
  },
  {
    id: 2,
    value: "Female",
    displayText: "Female",
  },
  {
    id: 3,
    value: "Other",
    displayText: "Other",
  },
];

export const userAge = [
  {
    id: 1,
    value: "51-65",
    displayText: "51 to 65 yrs",
  },
  {
    id: 2,
    value: "66-80",
    displayText: "66 to 80 yrs",
  },
  {
    id: 3,
    value: "80+",
    displayText: "Above 80+ yrs",
  },

  {
    id: 4,
    value: "Other",
    displayText: "Other",
  },
];

export const fieldsName = {
  mobile_number: "mobile_number",
  name: "name",
  email: "email",
  role: "role",
  is_active: "is_active",
  about_me: "about_me",
  short_about_me: "short_about_me",
  profilePic: "profilePic",
  profileBanner: "propfileBanner",
  profileVideo: "profileVideo",
  title: "title",
  designation: "designation",
  specialities: "specialities",
  highlights: "highlights",
  date_of_birth: "date_of_birth",
  gender: "gender",
  address: "address",
  pincode: "pincode",
  emergency_mobile_number: "emergency_mobile_number",
  is_popular: "is_popular",
  tag: "tag",
  language: "language",
  age_group: "age_group",
  deactivation: "deactiavtion",
  is_deleted: 'is_deleted',
  is_blacklisted: 'is_blacklisted'
};

export const ExportFieldsName = {
  startsAt: "startsAt",
  endsAt: "endsAt",
  status: "status",
  role: "role",
  age_group: "age_group",
};

export const usersExportStatus = [
  {
    id: 1,
    value: "Active",
    displayText: "Active",
  },
  {
    id: 2,
    value: "Inactive",
    displayText: "Inactive",
  },
  {
    id: 3,
    value: "Delete",
    displayText: "Deleted",
  },
  {
    id: 4,
    value: "Deleted",
    displayText: "Permanently Deleted",
  },
  {
    id: 5,
    value: "Blacklisted",
    displayText: "Blacklisted",
  },
];

export const reportsFilterOption = [
  {
    id: 1,
    value: "All",
    displayText: "All Users",
  },
  {
    id: 2,
    value: "Yes",
    displayText: "Reported Users",
  },
];
