import React from "react";
import { Route, Switch } from "react-router-dom";
import Reports from "../../../reports-data";
import CommentsAndReplies from "../../../social/reported-comments-and-replies";

export default () => (
  <Switch>
    <Route exact path="/comments-and-replies" component={CommentsAndReplies} />
    <Route exact path="/comments-and-replies/:id/reports" component={Reports} />
  </Switch>
);
