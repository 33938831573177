import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSessionsCountAction } from "./../actions";
import { getRegularSessionsCount, getBonusSessionsCount } from "../selectors";

export const useProfile = (user_id, user_age) => {
  const dispatch = useDispatch();
  const regular_count = useSelector(getRegularSessionsCount);
  const bonus_count = useSelector(getBonusSessionsCount);
  useEffect(() => {
    user_id && dispatch(getSessionsCountAction(user_id));
  }, [user_id, dispatch]);

  const getAgeGroup = () => {
    if (!user_age) return "Other";
    return user_age === "51-65"
      ? "51 - 65 yrs"
      : user_age === "65-80"
      ? "65 - 80 yrs"
      : user_age === "80+"
      ? "Above 80 yrs"
      : "Other";
  };

  return {
    regular_count,
    bonus_count,
    getAgeGroup,
  };
};
