import React from "react";
import { Formik, ErrorMessage } from "formik";
import { Chip } from "@material-ui/core";
import { BlogUploadFieldsName } from "../../constant";
import { DataLoader } from "../../../../../../components/loader";
import { formSchema } from "./validation-schema";
import { useHandleFieldFocus } from "../../../../../../utils/form";

const BlogUploadForm = ({
  handleUploadBlogForm,
  btn,
  isLoading,
  errorData,
  downloadSampleBlogFile,
}) => {
  const { excel_file } = BlogUploadFieldsName;

  const {
    focusField,
    handleFocusField,
    handleBlurField,
  } = useHandleFieldFocus();

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={formSchema}
      initialValues={{
        excel_file: "",
      }}
      onSubmit={handleUploadBlogForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="form__form-group-field form__margin-bottom">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="excelFile">Choose Excel File</label>

                  {rest?.values?.excel_file?.name && (
                    <Chip
                      className="selected-file"
                      key={rest?.values?.excel_file?.name}
                      label={rest?.values?.excel_file?.name}
                      variant="outlined"
                    />
                  )}
                  <input
                    accept=".xlsx, .xls, .csv"
                    type="file"
                    name={excel_file}
                    id="excelFile"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      setFieldValue(excel_file, event.currentTarget.files[0]);
                    }}
                    onFocus={() => handleFocusField(excel_file)}
                    onBlur={() => handleBlurField()}
                  />
                  {focusField === excel_file ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error blog__margin__top5">
                          {msg}
                        </div>
                      )}
                      name={excel_file}
                    />
                  )}
                  {errorData.map((x) => {
                    return (
                      <div className="blog__error__message__container">
                        {x.message}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
          <a className="blog__margin__top40" href="/#" onClick={(e) => downloadSampleBlogFile(e)}><u>Download Sample Excel File</u></a>
        </>
      )}
    </Formik>
  );
};

export default BlogUploadForm;
