import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated, logOut } from "../../../../utils/auth";
import Layout from "../../../Layout/index";
import { useUser } from "../../../../common-hooks/user";

const PrivateRoute = ({ component, access = [], ...rest }) => {
  const { role, isCoordinator } = useUser();
  return isAuthenticated() ? (
    <div>
      <Layout />
      <div className="container__wrap">
        {access.includes(role) ? (
          <Route {...rest} component={component} />
        ) : isCoordinator ? (
          <Redirect to="/schedule-events" />
        ) : null}
      </div>
    </div>
  ) : (
    logOut()
  );
};

export default PrivateRoute;
