export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Description",
    accessor: "description",
    disableGlobalFilter: true,
  },
  {
    Header: "Consumption Type",
    accessor: "coupon_generation_type",
    disableGlobalFilter: true,
  },

  { Header: "Category", accessor: "category" },
  { Header: "Sub Category", accessor: "sub_category" },
  { Header: "Usage Type", accessor: "usage" },
  { Header: "Usage Count", accessor: "usage_count" },
  { Header: "Discount Type", accessor: "discount_type" },
  { Header: "Discount Amount", accessor: "discount_amount" },
  { Header: "Max Discount Amount", accessor: "max_discount" },

  { Header: "Valid From", accessor: "valid_from", width: 200 },
  { Header: "Valid Till", accessor: "valid_till", width: 200 },

  { Header: "No. of codes", accessor: "count" },
  { Header: "Created At", accessor: "created_at", width: 200 },
  { Header: "Created By", accessor: "created_by" },

  { Header: "Purpose", accessor: "purpose" },

  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
  },
  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
  },
];

export const codesColumns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Coupon ID",
    accessor: "coupon_id",
  },
  { Header: "Coupon Code", accessor: "coupon_code" },
  { Header: "Usage Per User", accessor: "uses_per_user" },
  { Header: "Created At", accessor: "created_at" },
];

export const fieldsName = {
  consumption_type: "consumption_type",
  count: "count",
  purpose: "purpose",
  event: "event",
  category: "category",
  sub_category: "sub_category",
  discount_type: "discount_type",
  max_discount: "max_discount",
  discount_amount: "discount_amount",
  discount_percentage: "discount_percentage",
  discount_sessions: "discount_sessions",
  entity: "entity",
  entity_name: "entity_name",
  status: "status",
  validity: "validity",
  validity_type: "validity_type",
  validity_date: "validity_date",
  valid_for_days: "valid_for_days",
  usage: "usage",
  usage_count: "usage_count",
  voucher_type: "voucher_type",
  voucher_prefix: "voucher_prefix",
  voucher_postfix: "voucher_postfix",
  valid_from: "valid_from",
  valid_till: "valid_till",
  title: "title",
  description: "description",
  pattern: "pattern",
  has_discount: "has_discount",
};

export const consumption_types_options = [
  {
    id: 1,
    value: "Single",
    displayText: "Single",
  },
  {
    id: 2,
    value: "Bulk",
    displayText: "Bulk",
  },
];

export const category_options = [
  {
    id: 1,
    value: "Voucher Code",
    displayText: "Voucher Code",
  },
  {
    id: 2,
    value: "Invitation Code",
    displayText: "Invitation Code",
  },
  {
    id: 3,
    value: "Others",
    displayText: "Others",
  },
];

export const vc_sub_category_options = [
  {
    id: 1,
    value: "Discount Coupons",
    displayText: "Discount Coupons",
  },
];

export const other_sub_category_options = [
  {
    id: 1,
    value: "Others",
    displayText: "Others",
  },
];

export const entity_options = [
  {
    id: 1,
    value: "Individual",
    displayText: "Individual",
  },
  {
    id: 2,
    value: "User Group",
    displayText: "User Group",
  },
  {
    id: 3,
    value: "Organization",
    displayText: "Organization",
  },
  {
    id: 4,
    value: "Housing Society",
    displayText: "Housing Society",
  },
  {
    id: 5,
    value: "Others",
    displayText: "Others",
  },
];

export const purpose_options = [
  {
    id: 1,
    value: "Event",
    displayText: "Event",
  },
  {
    id: 2,
    value: "Non Event",
    displayText: "Non Event",
  },
];

export const validity_type_options = [
  {
    id: 1,
    value: "Date",
    displayText: "Date",
  },
  {
    id: 2,
    value: "Days",
    displayText: "Days",
  },
];

export const event_options = [
  {
    id: 1,
    value: "Checkout",
    displayText: "Checkout",
  },
  {
    id: 2,
    value: "Signup",
    displayText: "Signup",
  },
  {
    id: 3,
    value: "Refer & Earn",
    displayText: "Refer & Earn",
  },
  {
    id: 4,
    value: "Others",
    displayText: "Others",
  },
];

export const validity_options = [
  {
    id: 1,
    value: true,
    displayText: "Yes",
  },
  {
    id: 2,
    value: false,
    displayText: "No",
  },
];

export const usage_options = [
  {
    id: 1,
    value: "Once",
    displayText: "Once",
  },
  {
    id: 2,
    value: "Many",
    displayText: "Many",
  },
];

export const voucher_type_options = [
  {
    id: 1,
    value: "Alphanumeric",
    displayText: "Alphanumeric",
  },
  {
    id: 2,
    value: "Alphabetic",
    displayText: "Alphabetic",
  },
  {
    id: 3,
    value: "Numeric",
    displayText: "Numeric",
  },
];

export const discount_type_options = [
  {
    id: 1,
    value: "Flat",
    displayText: "Flat",
  },
  {
    id: 2,
    value: "Percentage",
    displayText: "Percentage",
  },
  {
    id: 3,
    value: "Sessions",
    displayText: "Sessions",
  },
];

export const voucherTypes = {
  Alphanumeric: "Alphanumeric",
  Alphabetic: "Alphabetic",
  Numeric: "Numeric",
};

export const discount_options = [
  {
    id: 1,
    value: true,
    displayText: "Yes",
  },
  {
    id: 2,
    value: false,
    displayText: "No",
  },
];

export const discountTypes = {
  Flat: "Flat",
  Percentage: "Percentage",
  Sessions: "Sessions",
};

export const fieldInfo = {
  title:
    "Title of the codes to be generated. Will not reflect anywhere to front end user",
  description:
    "Description of the type for reference. Will not reflect anywhere to front end user",
  count: "Required number of unique codes",
  event: "Event type where code will be applicable",
  category: "Type of code where it will be applied",
  entity: "Define group or individual where code will be applied",
  validty: `If selected yes - add Number of days and  'Valid From' date e.g. If Number of days is 30 and 'Valid From' date is not entered then the coupon will be valid till 30 days from the current date. If Number of days is 30 and 'Valid From' date is entered then the coupon will be valid till 30 days from the 'Valid From' date entered.
  If selected no then the coupon will be valid till 31/12/9999 i.e. always valid from the current date.`,
  discount: "Is discount applicable to the code",
  max_discount: "Discount cannot exeed more than this value of discount in Rs",
  no_of_sessions: "Define the  number of sessions where code is applicable",
  prefix:
    "Your voucher code will start with this Prefix (Min 0, Max 5 characters)",
  pattern_type: "Define pattern type",
  pattern:
    "Define the pattern as per the pattern type selected. Example HDFC###123. (Min 3, Max 10). The # will be replaced by random characters based on the pattern type selected.",
  postfix:
    "Your voucher code will end with this Postfix (Min 0, Max 5 characters)",
};

export const VOUCHER_LIMIT = 100000;
