import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField } from "@material-ui/core";

import { reasonFormSchema } from "./validation-schema";
import { fieldsName } from "../constant";
import { DataLoader } from "../../../../components/loader";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../utils/form";

const formField = {
  reason: "",
};

const ReasonForm = ({ fieldValues, handleReasonForm, btn, isLoading }) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const { reason } = fieldsName;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = (event) => {
    let { name, value } = event.target;

    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  return (
    <Formik
      validationSchema={reasonFormSchema}
      validateOnMount
      enableReinitialize
      initialValues={{
        reason: formValues?.reason || "",
      }}
      onSubmit={handleReasonForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={reason}
                  placeholder="Reason"
                  label="Reason"
                  type="text"
                  value={rest.values.reason}
                  onChange={(event) => {
                    handleFormValueChange(rest, reason);
                    handleFormUpdate(event);
                    setFieldValue(reason, event.target.value);
                  }}
                  onFocus={() => handleFocusField(reason)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === reason ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={reason}
                  />
                )}
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-success btn-right btn-sm mt-4"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default ReasonForm;
