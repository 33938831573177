import {
  SET_LABEL_LIST,
  SET_LABEL_FILTER
} from "./actions";

const initialState = {
  labels: [],
  count: 0,
  filter: {
    status: "",
    limit: 10,
    skip: 0,
  },
};

const labelReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LABEL_LIST:
      return {
        ...state,
        labels: action.payload.labels,
        count: action.payload.count,
      };
    case SET_LABEL_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    };
    default:
      return state;
  }
};

export default labelReducer;
