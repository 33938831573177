import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../../utils/upload-file/actions";
import {
  getTestimonial,
  editTestimonial,
  getTestimonialList,
} from "../actions";

import { getTestimonialsFilter } from "../selectors";

export const useEditTestimonial = ({ id }) => {
  const dispatch = useDispatch();
  const filter = useSelector(getTestimonialsFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editTestimonialLoading, setEditTestimonialLoading] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState();
  const files = useSelector(({ fileReducer }) => fileReducer.files);

  const getTestimonialFieldData = async () => {
    dispatch(clearFiles());
    setEditTestimonialLoading(true);
    const data = await dispatch(getTestimonial(id));

    if (data) {
      setSelectedTestimonial(data);
      toggleModal();
    }
    setEditTestimonialLoading(false);
  };

  const handleEditTestimonial = async (values) => {
    try {
      const payload = {
        testimonial: {
          status: values.status,
          description: values.description,
        },
        files,
      };
      setEditTestimonialLoading(true);
      const is_success = await dispatch(editTestimonial(payload, id));
      setEditTestimonialLoading(false);
      if (is_success) {
        dispatch(getTestimonialList(filter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    editTestimonialLoading,
    handleEditTestimonial,
    isOpenModal,
    toggleModal,
    getTestimonialFieldData,
    selectedTestimonial,
  };
};
