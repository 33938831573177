import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageFilter, getPageList } from "./actions";
import { getPages, getPageFilter, getPagesCount } from "./selectors";

export const usePages = () => {
  const dispatch = useDispatch();
  const pages = useSelector(getPages);
  const filters = useSelector(getPageFilter);
  const pageCount = useSelector(getPagesCount);
  const [loadingPages, setLoadingPages,] = useState(false);

  useEffect(() => {
    const fetchPages = async () => {
      setLoadingPages(true);
      await dispatch(getPageList(filters));
      setLoadingPages(false);
    };
    fetchPages();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setPageFilter({ key, value }))
  }

  return {
    setFilter,
    loadingPages,
    filters,
    pages,
    pageCount,
  };
};
