export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 30,
  },
];

export const fieldNames = {
  title: "title",
  description: "description",
  category_id: "category_id",
  interestIds: "interestIds",
  time_to_read: "time_to_read",
  status: "status",
  tags: "tags",
  author_id: "author_id",
  label_id: "label_id",
  is_popular: "is_popular",
  fileBanner: "fileBanner",
  fileBannerTile: "fileBannerTile",
  next_blog_id: "next_blog_id",
  meta_title: "meta_title",
  meta_description: "meta_description",
  meta_keywords: "meta_keywords",
  published_at: "published_at",
  language: "language",
  slug: "slug",
};

export const statusOptions = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
  {
    id: 3,
    value: "In Active",
    displayText: "In Active",
  },
];

export const isPopularOptions = [
  {
    id: 1,
    value: true,
    displayText: "Yes",
  },
  {
    id: 2,
    value: false,
    displayText: "No",
  },
];

export const BlogUploadFieldsName = {
  excel_file: "excel_file",
};
