import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useTextfieldChips } from "./hooks";
const TextfieldChips = (props) => {
  const {
    handleTextfieldChange,
    handleRemove,
    value,
    chips,
    name,
    maxTextLength,
  } = useTextfieldChips(props);
  return (
    <div className="text-field-input">
      <TextField
        className="material-form__field"
        name={name}
        label={name}
        placeholder={`add ${name} here..`}
        type="text"
        value={value}
        onChange={(event) => handleTextfieldChange(event)}
        onKeyDown={(event) => handleTextfieldChange(event)}
        helperText={maxTextLength ? `${value.length} / ${maxTextLength}` : ""}
        {...props}
      />
      <div className="text-field-chips">
        {chips?.length > 0 && (
          <>
            {chips.map((chip, index) => {
              return (
                <div key={`${chip}+${index}`}>
                  <Tooltip title={chip} placement="bottom">
                    <Chip
                      className="MuiChip-root MuiAutocomplete-tag MuiChip-deletable text-field-chip"
                      key={`${chip}+${index}`}
                      label={`${index + 1}. ${chip}`}
                      onDelete={() => handleRemove(chip)}
                      variant="outlined"
                    />
                  </Tooltip>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default TextfieldChips;
