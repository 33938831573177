import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBannerList, setBannerFilter } from "./action";
import { getBannerFilter, getBanners, getBannerCount } from "./selectors";

export const useBanner = () => {
  const dispatch = useDispatch();
  const banners = useSelector(getBanners);
  const filters = useSelector(getBannerFilter);
  const bannerCount = useSelector(getBannerCount);

  const [loadingBanners, setLoadingBanners] = useState(false);

  useEffect(() => {
    (async () => {
      setLoadingBanners(true);
      await dispatch(getBannerList(filters));
      setLoadingBanners(false);
    })();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setBannerFilter({ key, value }));
  };

  return {
    banners,
    loadingBanners,
    setFilter,
    filters,
    bannerCount,
  };
};
