export const types = {
  TEXT: "Text",
  PHOTO: "PHOTO",
  VIDEO: "VIDEO",
};

export const languages = {
  HI: "Hindi",
  EN: "English",
  MR: "Marathi",
};
