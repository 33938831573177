import React, { useState } from "react";
import ReactPlayer from "react-player";
import { fileContext } from "../../constants";
import { BackWhiteArrow } from "../../containers/social/posts/images";
import CustomModal from "../custom-modal";
import { NextArrow, PrevArrow } from "../slider-arrow";
import styles from "./style.module.scss";

function ImageViewer(props) {
  const [current, setCurrent] = useState(0);

  const { files, handleViewer } = props;

  const handleNext = () => {
    if (files?.length > 1) {
      if (files[current + 1]) {
        setCurrent((prev) => prev + 1);
      } else {
        setCurrent(0);
      }
    }
  };

  const handlePrev = () => {
    if (files?.length > 1) {
      if (files[current - 1]) {
        setCurrent((prev) => prev - 1);
      } else {
        setCurrent(files.length - 1);
      }
    }
  };

  return (
    <CustomModal
      paperClassName={styles.imageViewerContainer}
      isOpen
      onCloseCallback={handleViewer}
      width="80%"
    >
      <img
        src={BackWhiteArrow}
        alt="close"
        className={styles.close_video_modal}
        onClick={handleViewer}
      />
      <div className={styles.modal_video_container}>
        {files?.length > 1 && (
          <NextArrow className={styles.next_slider} onClick={handleNext} />
        )}
        <div className={styles.slider}>
          {files[current]?.file_context.includes(
            fileContext.DISCUSSION_VIDEOS
          ) ? (
            <div>
              <ReactPlayer
                className={styles.video_player}
                url={
                  files[current]?.ts_url ||
                  files[current]?.url ||
                  files[current]?.public_url
                }
                controls
                pip
                stopOnUnmount={true}
                width="inherit"
                height="inheirt"
                playing={false}
                config={{
                  youtube: {
                    preload: true,
                  },
                }}
                playIcon={
                  <div>
                    <div>
                      <img src={"/img/play-open.svg"} alt="play" />
                    </div>
                  </div>
                }
              />
            </div>
          ) : (
            <img
              alt="media"
              className={styles.image_viewer}
              src={files[current]?.url || files[current]?.public_url}
            />
          )}
        </div>
        {files?.length > 1 && (
          <PrevArrow className={styles.prev_slider} onClick={handlePrev} />
        )}{" "}
      </div>
    </CustomModal>
  );
}

export default ImageViewer;
