import {
  SET_INTEREST_LIST,
  SET_INTEREST_FILE,
  CLEAR_INTEREST_FILE,
  SET_CATEGORY_LIST,
  SET_INTEREST_FILTER,
} from "./actions";

const initialList = {
  interests: [],
  files: [],
  categories: [],
  categoryFilter: {
    type: "Event",
    status: "Published",
  },
  count: 0,
  filter: {
    limit: 10,
    skip: 0,
  },
};

const interestReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_INTEREST_LIST:
      return {
        ...state,
        interests: action.payload.interests,
        count: action.payload.count || [],
      };
    case SET_INTEREST_FILE:
      return { ...state, files: [...action.payload] || [] };
    case CLEAR_INTEREST_FILE:
      return { ...state, files: [] };
    case SET_CATEGORY_LIST:
      return { ...state, categories: action.payload };
    case SET_INTEREST_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    default:
      return state;
  }
};

export default interestReducer;
