import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { MenuItem, TextField } from "@material-ui/core";

import { formSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { getReportOptionsList } from "../../action";
import { useDispatch } from "react-redux";
import { DataLoader } from "../../../../../components/loader";

const reason = "reason";

const UnpublishPostForm = ({ handleSubmit, btn, isLoading }) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const [reasons, setReasons] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const reasons = await dispatch(
        getReportOptionsList({ limit: 100, skip: 0 })
      );
      setReasons(reasons?.reasons);
    })();
  }, [dispatch]);

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={formSchema}
      initialValues={{
        reason: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
         {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div>
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={reason}
                  placeholder="Reason"
                  label="Reason"
                  select
                  value={rest.values.reason}
                  onChange={handleFormValueChange(rest, reason)}
                  onFocus={() => handleFocusField(reason)}
                  onBlur={() => handleBlurField()}
                >
                  {reasons.map((reason) => (
                    <MenuItem
                      key={reason.id}
                      className="material-form__option"
                      value={reason.reason}
                    >
                      {reason.reason}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === reason ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={reason}
                  />
                )}
              </div>
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default UnpublishPostForm;
