export const getUnlockedOffers = ({
  unlockedOfferReducer: { unlocked_offers = [] } = {},
}) => unlocked_offers;

export const getUnlockedOfferFilters = ({
  unlockedOfferReducer: { filter = {} } = {},
}) => filter;

export const getUnlockedOffersCount = ({
  unlockedOfferReducer: { count = 0 } = {},
}) => count;


