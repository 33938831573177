import * as Yup from "yup";
import { checkFields } from "../../../utils/validation";

const requiredFields = ["title", "description"];

export const categoriesFormSchema = Yup.object().shape({
  title: Yup.mixed().test(
    "Title required",
    "Enter valid title for all languages",
    function () {
      const {
        title: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "title");
    }
  ),
  description: Yup.mixed().test(
    "Description required",
    "Enter valid description for all languages",
    function () {
      const {
        description: { en },
      } = this.parent;
      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "description");
    }
  ),
  language: Yup.string().required("Language is required"),
  status: Yup.string().required("Status is required"),
  is_popular: Yup.boolean(),
  interestIds: Yup.array()
    .when("type", {
      is: "Faq",
      then: Yup.array(),
    })
    .when("type", {
      is: "Blog",
      then: Yup.array().required("Interests is required"),
    })
    .when("type", {
      is: "Event",
      then: Yup.array().required("Interests is required"),
    }),
  type: Yup.string().required("Type is required"),
  file: Yup.array()
    .when("type", {
      is: "Faq",
      then: Yup.array(),
    })
    .when("type", {
      is: "Blog",
      then: Yup.array().required("File is required"),
    })
    .when("type", {
      is: "Event",
      then: Yup.array().required("Image is required"),
    }),
});
