import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLanguage } from "../../../../utils/auth";
import { getLabelList, setLabelFilter } from "./actions";
import { getLabels, getLabelFilter, getLabelsCount } from "./selectors";

export const useLabels = () => {
  const dispatch = useDispatch();
  const labels = useSelector(getLabels);
  const filters = useSelector(getLabelFilter);
  const labelCount = useSelector(getLabelsCount);
  const [loadingLabels, setLoadingLabels] = useState(false);

  useEffect(() => {
    clearLanguage();
    const fetchLabels = async () => {
      setLoadingLabels(true);
      await dispatch(getLabelList(filters));
      setLoadingLabels(false);
    };
    fetchLabels();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setLabelFilter({ key, value }));
  };

  return {
    setFilter,
    loadingLabels,
    filters,
    labels,
    labelCount,
  };
};
