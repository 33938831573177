import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../../components/modal";
import DeleteConfirmation from "../../../../components/delete-confirmation";
import { useDeleteTestimonial } from "./hooks";

const DeleteTestimonial = ({ testimonial }) => {
  const {
    deleteTestimonialLoading,
    handleDeleteTestimonial,
    isOpenModal,
    toggleModal,
  } = useDeleteTestimonial(testimonial);
  return (
    <>
      <Tooltip title="Delete" placement="bottom">
        <i
          className={`fa fa-trash icon-btn-action text-danger `}
          onClick={toggleModal}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeleteTestimonial}
              isLoading={deleteTestimonialLoading}
            />
          }
          title="Delete Testimonial"
        />
      ) : null}
    </>
  );
};

export default DeleteTestimonial;
