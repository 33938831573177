import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField } from "@material-ui/core";
import { sessionFormSchema } from "./validation-schema";
import { sessionFieldsName } from "../constant";
import { DataLoader } from "../../../../components/loader";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../utils/form";

const formField = {
  reason: '',
  no_of_sessions: '',
};

const SessionForm = ({ fieldValues, handleBonusSessionForm, btn, isLoading }) => {
  const {
    focusField,
    handleFocusField,
    handleBlurField,
  } = useHandleFieldFocus();

  const {
    no_of_sessions,
    reason,
  } = sessionFieldsName;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = (event) => {
    let { name, value } = event.target;
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  return (
    <Formik
      validationSchema={sessionFormSchema}
      validateOnMount
      enableReinitialize
      initialValues={{
        no_of_sessions: formValues?.no_of_sessions || "",
        reason: formValues?.reason || "",
      }}
      onSubmit={handleBonusSessionForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div>
              <TextField
                className="material-form__field"
                name={no_of_sessions}
                placeholder="No of session"
                label="Sessions"
                type="number"
                value={rest.values.no_of_sessions}
                onChange={(event) => {
                  handleFormValueChange(rest, no_of_sessions);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(no_of_sessions)}
                onBlur={() => handleBlurField()}
              />
              {focusField === no_of_sessions ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={no_of_sessions}
                />
              )}
            </div>
            <div>
              <TextField
                className="material-form__field"
                name={reason}
                placeholder="reason"
                label="Reason"
                type="text"
                value={rest.values.reason}
                onChange={(event) => {
                  handleFormValueChange(rest, reason);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(reason)}
                onBlur={() => handleBlurField()}
              />
              {focusField === reason ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={reason}
                />
              )}
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default SessionForm;
