export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Offer Text",
    accessor: "offer_text",
  },
  {
    Header: "Validity",
    accessor: "validity",
    disableGlobalFilter: true,
  },

  {
    Header: "Amount",
    accessor: "amount",
    disableGlobalFilter: true,
  },

  {
    Header: "Description",
    accessor: "description",
    disableGlobalFilter: true,
  },
  {
    Header: "Partner Name",
    accessor: "partner_name",
    disableGlobalFilter: true,
  },
  {
    Header: "Total Coupon Codes",
    accessor: "total_coupon_codes",
    disableGlobalFilter: true,
  },
  {
    Header: "Used Coupon Codes",
    accessor: "used_coupon_codes",
    disableGlobalFilter: true,
  },

  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
  },
  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
  },
];

export const fieldsName = {
  offer_text: "offer_text",
  plan_offer_text: "plan_offer_text",
  my_offer_text: "my_offer_text",
  status: "status",
  validity: "validity",
  description: "description",
  no_of_coupon_codes: "no_of_coupon_codes",
  duration: "duration",
  plan: "plan",
  dynamic_coupon_code: "dynamic_coupon_code",
  how_to_use: "how_to_use",
  terms_and_conditions: "terms_and_conditions",
  amount: "amount",
  cta_name: "cta_name",
  cta_url: "cta_url",
  partner_id: "partner_id",
  language: "language",
};

export const offerStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
];

export const dynamicCoupounCodeList = [
  {
    id: 1,
    value: true,
    displayText: "Yes",
  },
  {
    id: 2,
    value: false,
    displayText: "No",
  },
];
