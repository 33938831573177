import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import ActionButton from "../../components/action-button";
import { Loader } from "../../components/loader";
import DataTable from "../../components/table";
import AddVoucher from "./add-voucher";
import { columns } from "./constant";
import DeleteVoucher from "./delete-voucher";
import { useVoucher } from "./hooks";

const Vouchers = () => {
  const {
    vouchers,
    filters,
    count,
    loadingVouchers,
    downloadCode,
    handleSetFilter,
  } = useVoucher();
  const history = useHistory();

  const configureTableData = () => {
    try {
      let results = vouchers.map((voucher) => {
        let newVoucher = Object.assign({}, voucher);
        newVoucher["created_at"] = moment(voucher?.created_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );
        newVoucher["valid_from"] = voucher?.valid_from
          ? moment(voucher?.valid_from).format("DD/MM/YYYY @ hh:mm A")
          : "NA";
        newVoucher["valid_till"] = voucher?.valid_till
          ? moment(voucher?.valid_till).format("DD/MM/YYYY @ hh:mm A")
          : "NA";

        newVoucher["title"] = voucher?.title || "NA";
        newVoucher["description"] = voucher?.description || "NA";
        newVoucher["usage"] = voucher?.usage || "NA";
        newVoucher["usage_count"] =
          voucher?.usage_count?.toLocaleString() || "NA";
        newVoucher["discount_type"] = voucher?.discount_type || "NA";
        newVoucher["discount_amount"] =
          voucher?.discount?.toLocaleString() || "NA";
        newVoucher["max_discount"] =
          voucher?.max_discount?.toLocaleString() || "NA";
        newVoucher["count"] = voucher?.count?.toLocaleString() || "NA";
        newVoucher["status"] = voucher?.status
          ? moment(voucher?.valid_till).isBefore(new Date())
            ? "In Active"
            : "Active"
          : "NA";

        newVoucher["action"] = (
          <div className="categories__list-action justify-content-start">
            <ActionButton
              className="mr-3"
              iconClassName="fa fa-eye icon-btn-action"
              title="View Codes"
              onClick={() =>
                history.push({
                  pathname: `/voucher/${voucher?.id}/codes`,
                })
              }
            />
            <ActionButton
              className="mr-3"
              iconClassName="fa fa-download icon-btn-action"
              title="Download Codes"
              onClick={() => downloadCode(voucher.id)}
            />

            <DeleteVoucher voucher={voucher} />
          </div>
        );
        return newVoucher;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return loadingVouchers ? (
    <Loader />
  ) : (
    <DataTable
      title="Vouchers"
      addBtn={<AddVoucher />}
      CreateTableData={configureTableData}
      withFilter={false}
      handleSetFilter={handleSetFilter}
      count={count}
      skip={filters?.skip}
    />
  );
};

export default Vouchers;
