import React from "react";
import Modals from "../../../../components/modal";
import DeleteConfirmation from "../../../../components/delete-confirmation";
import { useDeleteBanner } from "./hooks";
import { useUser } from "../../../../common-hooks/user";
import ActionButton from "../../../../components/action-button";

const DeleteBanner = ({ banner }) => {
  const { deleteBannerLoading, handleDeleteBanner, isOpenModal, toggleModal } =
    useDeleteBanner(banner);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <>
      <ActionButton
        title="Delete"
        onClick={toggleModal}
        iconClassName={"fa fa-trash icon-btn-action text-danger "}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeleteBanner}
              isLoading={deleteBannerLoading}
            />
          }
          title="Delete Banner"
        />
      ) : null}
    </>
  );
};

export default DeleteBanner;
