import {
  SET_IS_OTP_GENERATED,
  SET_MOBILE_NUMBER,
  SET_LOGGED_IN_USER,
  SET_CONFIG_DATA,
} from "./actions";

const initialState = {
  isOtpGenerated: false,
  mobileNumber: "",
  loggedInUser: "",
  configs: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_OTP_GENERATED:
      return { ...state, isOtpGenerated: action.payload || false };

    case SET_MOBILE_NUMBER:
      return { ...state, mobileNumber: action.payload };

    case SET_LOGGED_IN_USER:
      return { ...state, loggedInUser: action.payload };

    case SET_CONFIG_DATA:
      return { ...state, configs: action.payload };
    default:
      return state;
  }
};

export default authReducer;
