import React from "react";

const Required = ({ title }) => {
  return (
    <>
      {title} <span className="text-danger">*</span>
    </>
  );
};

export default Required;
