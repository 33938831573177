import React from "react";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useTabs } from "./hooks";
import SelectFilter from "../../../../components/filters/select-filter";
import SearchFilter from "../../../../components/filters/search-filter";
import {
  columnsEvent,
  statuses,
  booking_statuses,
  columnsTranactions,
  transaction_types,
  transaction_type_bonus,
  columnsPayments,
  session_types,
  reviewStatuses,
  columnsReviews,
  columnsWaitlists,
  columnsReferrals,
  columnsOffers,
  isCourseTypes,
  columnsCourse,
  columnsPosts,
  storyColumns,
  columnsFriends,
  columnsPrivacySettings,
  columnsNotification,
  postsFilterStatus,
  egTaggedFilter,
  reportsFilterOption,
  columnsInstructros,
  columnsReports,
  columnsBlocked,
} from "./../constant";
import DataTable from "../../../../components/table";
import Tooltip from "@material-ui/core/Tooltip";
import * as moment from "moment";
import AddBonusSession from "../add-bonus-session";
import CancelEvent from "./cancel-event";
import BookEvent from "../book-event";
import GeneratePaymentLink from "../generate-payment-link";
import { eventTypes } from "../../../schedule-events/constant";
import { useHistory } from "react-router-dom";
import Reactions from "../../../social/posts/components/reactions";
import ViewersList from "../viewers-list";
import DateRangeFilter from "../../../../components/filters/date-range-filter";
import PostCaption from "../../../social/posts/components/post-caption";
import {
  TABLE_CAPTION_CHAR_LENGTH,
  trackableTypes,
} from "../../../../constants";
import ConfidenceScore from "../../../../components/confidence-score";

const UserTabs = ({ user_id, user_role, selectedUser }) => {
  const {
    setFilter,
    event_filter,
    transactions,
    transaction_filter,
    transaction_count,
    events,
    event_count,
    setTransactionFilter,
    activeTab,
    toggleTab,
    clearTransactionFilters,
    clearEventFilters,
    payments,
    payment_count,
    payment_filter,
    setPaymentFilter,
    downloadUserInvoice,
    dowloadUserBookingHistory,
    downloadUserPaymentHistory,
    downloadUserCreditUsageHistory,
    reviews,
    reviews_filter,
    reviews_count,
    setUserReviewsFilter,
    updateReviewStatus,
    waitlists,
    waitlist_count,
    waitlists_filter,
    setUserWaitlistFilter,
    isAdmin,
    isCSSpecialist,
    referrals,
    referral_count,
    referralsFilter,
    setReferralsFilter,
    copyLink,
    partner_offer,
    partner_offer_count,
    partner_offer_filter,
    setOfferListFilters,
    isCourse,
    courses,
    courses_count,
    courses_filter,
    setCoursesFilter,
    posts,
    post_filter,
    posts_count,
    setUserPostFilter,
    stories,
    stories_count,
    friendsCount,
    friends,
    setUserFriendsFilter,
    friendsFilter,
    privacySetting,
    notificationsFilter,
    notifications,
    notificationsCount,
    setNotificationFilters,
    resetUserPostFilter,
    setStoriesFilters,
    stories_filter,
    instructorsCount,
    instructorsFilter,
    setInstructorsFilters,
    instructors,
    blockedToUsers,
    blockedToUsersCount,
    blockedToUsersFilter,
    setBlockedToFilters,
    reports,
    reportsCount,
    reportsFilter,
    setReportsFilter,
  } = useTabs({ user_id, user_role });

  const history = useHistory();

  const configureTableData = () => {
    try {
      let results = events.map((event) => {
        let newEvent = Object.assign({}, event);
        const isCancellable =
          moment(event.starts_at).isAfter(new Date()) &&
          event?.booking_status !== "Cancelled";

        if (isAdmin || isCSSpecialist) {
          newEvent["action"] = isCancellable && (
            <CancelEvent event={event} user_id={user_id} />
          );
        }

        newEvent["is_free"] = event?.is_free ? "Free" : "Paid";

        if (event.starts_at)
          newEvent.starts_at = moment(event.starts_at).format(
            "DD/MM/YYYY @ hh:mm A "
          );
        else newEvent.starts_at = "NA";
        if (event.cancelled_at)
          newEvent.cancelled_at = moment(event.cancelled_at).format(
            "DD/MM/YYYY @ hh:mm A "
          );
        else newEvent.cancelled_at = "NA";
        if (!event.cancellation_reason) newEvent.cancellation_reason = "NA";

        return newEvent;
      });

      const reactTableData = {
        tableHeaderData: columnsEvent,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureCoursesTableData = () => {
    try {
      let results = courses.map((course) => {
        let newCourse = Object.assign({}, course);
        const isCancellable =
          course?.booking_status !== "Cancelled" &&
          course?.is_course_cancellable &&
          Object.keys(course?.upcoming_schedule)?.length > 0;

        if ((isAdmin || isCSSpecialist) && isCancellable) {
          newCourse["action"] = (
            <CancelEvent event={course} user_id={user_id} is_course={true} />
          );
        }

        const status =
          course?.attended_schedule_ids?.length > 0
            ? "Attended"
            : course?.booking_status;

        newCourse.booking_status = status;

        if (course.commence_at)
          newCourse.commence_at = moment(course.commence_at).format(
            "DD/MM/YYYY @ hh:mm A "
          );
        else newCourse.starts_at = "NA";
        if (course.cancelled_at)
          newCourse.cancelled_at = moment(course.cancelled_at).format(
            "DD/MM/YYYY @ hh:mm A "
          );
        else newCourse.cancelled_at = "NA";
        if (!course.cancellation_reason) newCourse.cancellation_reason = "NA";

        return newCourse;
      });

      const reactTableData = {
        tableHeaderData: columnsCourse,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureTranactionTableData = () => {
    try {
      let results = transactions.map((trans) => {
        let newTrans = Object.assign({}, trans);
        newTrans["reason"] = trans?.details?.reason
          ? trans?.details?.reason
          : "NA";
        if (trans.created_at)
          newTrans.created_at = moment(trans.created_at).format(
            "DD/MM/YYYY @ hh:mm A "
          );
        else newTrans.created_at = "NA";
        return newTrans;
      });
      const reactTableData = {
        tableHeaderData: columnsTranactions,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configurePaymentTableData = () => {
    try {
      let results = payments.map((payment) => {
        let newTrans = Object.assign({}, payment);

        newTrans["action"] = (
          <>
            {payment.status === "Pending" &&
              payment?.payment_link &&
              moment(payment?.payment_link_valid_till).isAfter(new Date()) && (
                <div className="text-center">
                  <Tooltip
                    title="Click to copy payment link"
                    placement="bottom"
                  >
                    <i
                      onClick={() => {
                        copyLink(payment?.payment_link);
                      }}
                      className="fa fa-copy fa-fw icon-btn-action"
                    ></i>
                  </Tooltip>
                </div>
              )}
            {payment.status === "Success" &&
              (newTrans["action"] = (
                <div className="text-center">
                  <Tooltip title="Download Invoice" placement="bottom">
                    <i
                      onClick={() => {
                        payment.status === "Success" &&
                          downloadUserInvoice(payment.id);
                      }}
                      className="fa fa-file-pdf-o fa-2x icon-btn-action"
                    ></i>
                  </Tooltip>
                </div>
              ))}
          </>
        );

        newTrans["gateway"] = payment?.gateway || "NA";

        if (payment.created_at)
          newTrans.created_at = moment(payment.created_at).format(
            "DD/MM/YYYY @ hh:mm A "
          );
        else newTrans.created_at = "NA";

        if (payment.completed_at)
          newTrans.completed_at = moment(payment.completed_at).format(
            "DD/MM/YYYY @ hh:mm A "
          );
        else newTrans.completed_at = "NA";

        newTrans.plan_details =
          payment.plan_variant.plan.code === "TopUp"
            ? `${payment.plan_variant.no_of_sessions} Session`
            : `${payment.plan_variant.duration} Days`;
        newTrans.gross_amount = (
          Math.round(newTrans.gross_amount * 100) / 100
        ).toFixed(2);
        newTrans.gst = (Math.round(newTrans.gst * 100) / 100).toFixed(2);
        return newTrans;
      });

      const reactTableData = {
        tableHeaderData: columnsPayments,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureReviewsTableData = () => {
    try {
      let results = reviews.map((review) => {
        let newReviews = Object.assign({}, review);
        newReviews.text = review?.text ? review.text : "NA";
        newReviews.rating = (
          <div>
            {Array.from(Array(5).keys()).map((index) => (
              <span
                key={index}
                className={`fa fa-star ${
                  review?.rating > index ? "review-star-checked" : null
                }`}
              ></span>
            ))}
          </div>
        );
        newReviews.action = (
          <div className="text-center">
            <Tooltip
              onClick={() =>
                updateReviewStatus(
                  review?.status === "Published" ? "Unpublished" : "Published",
                  review?.id
                )
              }
              title={`${
                review?.status === "Published"
                  ? "Unpublish Status"
                  : "Publish Status"
              }`}
              placement="bottom"
            >
              <i
                className={`fa fa-${
                  review?.status === "Published" ? "check" : "times"
                }-circle table__icon-${
                  review?.status === "Published" ? "active" : "deactive"
                } icon-btn-action`}
              ></i>
            </Tooltip>
          </div>
        );
        return newReviews;
      });

      const reactTableData = {
        tableHeaderData: columnsReviews,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureWaitlistTableData = () => {
    try {
      let results = waitlists.map((list) => {
        let newList = Object.assign({}, list);
        if (list?.event_schedule?.starts_at)
          newList.starts_at = moment(list?.event_schedule?.starts_at).format(
            "DD/MM/YYYY @ hh:mm A "
          );
        else newList.starts_at = "NA";
        if (list?.event_schedule?.ends_at)
          newList.ends_at = moment(list?.event_schedule?.ends_at).format(
            "DD/MM/YYYY @ hh:mm A "
          );
        else newList.ends_at = "NA";

        if (list.created_at)
          newList.created_at = moment(list.created_at).format(
            "DD/MM/YYYY @ hh:mm A "
          );
        else newList.created_at = "NA";

        newList["mode"] = list?.event_schedule?.mode;
        newList["language"] = list?.event_schedule?.mode;

        return newList;
      });

      const reactTableData = {
        tableHeaderData: columnsWaitlists,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureReferalsTableData = () => {
    try {
      let results = referrals.map((referral) => {
        let newReferral = Object.assign({}, referral);
        newReferral.contact =
          referral.country_code + " " + referral.mobile_number;
        if (referral.created_at)
          newReferral.created_at = moment(referral.created_at).format(
            "DD/MM/YYYY @ hh:mm A "
          );
        else newReferral.created_at = "NA";
        return newReferral;
      });
      const reactTableData = {
        tableHeaderData: columnsReferrals,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureOffersData = () => {
    try {
      let results = partner_offer.map((offer) => {
        let newOffer = Object.assign({}, offer);
        newOffer["expires_at"] = moment(new Date(offer.expires_at)).format(
          "DD/MM/YYYY @ hh:mm A"
        );
        return newOffer;
      });
      const reactTableData = {
        tableHeaderData: columnsOffers,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configurePostTableData = () => {
    try {
      let results = posts.map((post) => {
        let newPost = Object.assign({}, post);
        newPost["caption"] = post?.caption ? (
          <PostCaption
            className="mentioned_data"
            captionText={post?.caption}
            ranges={post?.ranges}
            charLength={TABLE_CAPTION_CHAR_LENGTH}
          />
        ) : (
          "NA"
        );
        newPost["topic"] = post?.poll?.topic ? (
          <p className="poll_question">{post?.poll?.topic}</p>
        ) : (
          "NA"
        );

        newPost["share_count"] = post?.stats?.shares_count;
        newPost["created_at"] = post?.created_at
          ? moment(post?.created_at).format("DD-MM-YYYY @ hh:mm A")
          : "NA";
        newPost["updated_at"] = post?.updated_at
          ? moment(post?.updated_at).format("DD-MM-YYYY @ hh:mm A")
          : "NA";

        newPost["choices"] = post?.poll?.poll_choices?.length
          ? post?.poll?.poll_choices?.map((choice) => choice?.title)?.join(", ")
          : "NA";
        newPost["action"] = (
          <div className="d-flex justify-content-start">
            <Tooltip
              title="View"
              placement="bottom"
              className="view_user_details_pad_left10 mr-3"
            >
              <i
                className={`fa fa-eye icon-btn-action `}
                onClick={() =>
                  history.push({
                    pathname: `/post/${post?.id}`,
                  })
                }
              ></i>
            </Tooltip>
          </div>
        );

        return newPost;
      });
      const reactTableData = {
        tableHeaderData: columnsPosts,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureStoriesTableData = () => {
    try {
      let results = stories?.map((story) => {
        let newStory = Object.assign({}, story);
        newStory["caption"] = story?.caption ? story?.caption : "NA";
        newStory["reactions"] = story?.stats?.reactions ? (
          <span className="schedule__booked-info">
            <Reactions
              id={story?.id}
              trackableType="STORY"
              totalReactions={story?.stats?.reactions}
            />
          </span>
        ) : (
          "NA"
        );
        newStory["viewers"] = <ViewersList story={story} />;
        newStory["background_color"] = story?.background_color ? (
          <div
            style={{
              backgroundColor: story?.background_color,
              display: "inline-block",
              padding: "5px",
              color: "#111",
            }}
          >
            {story.background_color} :
          </div>
        ) : (
          "NA"
        );

        newStory["created_at"] = moment(story?.created_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );

        newStory["url"] = story?.content?.url ? (
          <a
            href={story?.content?.url || ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            {story?.content?.url}
          </a>
        ) : (
          "NA"
        );

        newStory["action"] = (
          <ConfidenceScore
            data={story}
            trackableType={trackableTypes.STORY}
            className="mr-3"
          />
        );

        return newStory;
      });
      const reactTableData = {
        tableHeaderData: storyColumns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureFriendsTableData = () => {
    try {
      let results = friends?.map((friend) => {
        let newFriend = Object.assign({}, friend);

        newFriend["action"] = (
          <div className="d-flex justify-content-start">
            <Tooltip
              title="View"
              placement="bottom"
              className="view_user_details_pad_left10 mr-3"
            >
              <i
                className={`fa fa-eye icon-btn-action `}
                onClick={() =>
                  history.push({
                    pathname: `/users/${friend?.user_id}`,
                  })
                }
              ></i>
            </Tooltip>
          </div>
        );

        return newFriend;
      });
      const reactTableData = {
        tableHeaderData: columnsFriends,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureNotificationTableData = () => {
    try {
      let results = notifications?.map((notification) => {
        let newNotification = Object.assign({}, notification);
        newNotification["short_description"] =
          notification?.short_description || "NA";
        newNotification["published_at"] = notification?.published_at
          ? moment(notification?.published_at).format("DD/MM/YYYY @ hh:mm A")
          : "NA";
        newNotification["read_at"] = notification?.read_at
          ? moment(notification?.read_at).format("DD/MM/YYYY @ hh:mm A")
          : "NA";

        newNotification["redirect_to"] = notification?.redirect_to?.url ? (
          <a
            href={notification?.redirect_to?.url || ""}
            target={"_blank"}
            rel="noopener noreferrer"
          >
            {notification?.redirect_to?.url}
          </a>
        ) : (
          "NA"
        );

        return newNotification;
      });
      const reactTableData = {
        tableHeaderData: columnsNotification,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };
  const configurePrivacySettingsTableData = () => {
    try {
      let results = privacySetting?.map((setting) => {
        let newSetting = Object.assign({}, setting);
        newSetting["user_permission"] = setting?.user_permission || "NA";
        newSetting["default_permission"] = setting?.default_permission || "NA";

        return newSetting;
      });
      const reactTableData = {
        tableHeaderData: columnsPrivacySettings,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureInstructorsData = () => {
    try {
      let results = instructors?.map((instructor) => {
        let newInstructor = Object.assign({}, instructor);
        newInstructor["action"] = (
          <Tooltip
            title="View"
            placement="bottom"
            className="view_user_details_pad_left10 mr-3"
          >
            <i
              className={`fa fa-eye icon-btn-action `}
              onClick={() =>
                history.push({
                  pathname: `/users/${instructor?.following_id}`,
                })
              }
            ></i>
          </Tooltip>
        );

        return newInstructor;
      });
      const reactTableData = {
        tableHeaderData: columnsInstructros,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureReportsTableData = () => {
    try {
      let results = reports?.map((report) => {
        let newReport = Object.assign({}, report);
        newReport["reported_at"] = report?.reported_on
          ? moment(report?.reported_on).format("DD/MM/YYYY @ hh:mm A")
          : "NA";
        newReport["name"] = report?.reported_by?.name || "NA";
        newReport["mobile_number"] = report?.reported_by?.mobile_number || "NA";
        newReport["reason"] = report?.report?.reason || "NA";
        newReport["detailed_reason"] = report?.detailed_reason || "NA";

        newReport["name"] = report?.reported_by?.name || "NA";
        newReport["id"] = report?.reported_by?.id || "NA";

        return newReport;
      });
      const reactTableData = {
        tableHeaderData: columnsReports,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const configureBlockedUsersTableData = () => {
    try {
      let results = blockedToUsers?.map((user) => {
        let newUser = Object.assign({}, user);
        newUser["blocked_at"] = user?.last_blocked_at
          ? moment(user?.last_blocked_at).format("DD/MM/YYYY @ hh:mm A")
          : "NA";
        newUser["mobile_number"] = user?.mobile_number || "NA";

        return newUser;
      });
      const reactTableData = {
        tableHeaderData: columnsBlocked,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const eventFilter = () => (
    <>
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={isCourse}
        filterKey="Type"
        options={isCourseTypes}
        // defaultOption="All"
        filterTitle="Type"
      />
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={
          event_filter?.booking_statuses.length > 1
            ? "All"
            : event_filter?.booking_statuses[0]
        }
        filterKey="booking_statuses"
        options={booking_statuses}
        defaultOption="All"
        filterTitle="Booking Status"
      />
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={event_filter?.status}
        filterKey="status"
        options={statuses}
        filterTitle="Status"
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={
          event_filter?.is_free === "" || event_filter?.is_free === undefined
            ? "All"
            : event_filter.is_free
            ? "Free"
            : "Paid"
        }
        filterKey="is_free"
        options={eventTypes}
        defaultOption="All"
        filterTitle="Event Type"
      />

      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={clearEventFilters}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  const coursesFilter = () => (
    <>
      <SelectFilter
        handleSelect={setCoursesFilter}
        selectedValue={isCourse}
        filterKey="Type"
        options={isCourseTypes}
        filterTitle="Type"
      />
    </>
  );

  const tranactionFilter = () => (
    <>
      <SelectFilter
        handleSelect={setTransactionFilter}
        selectedValue={transaction_filter?.type}
        filterKey="type"
        options={session_types}
        filterTitle="Status"
      />
      {transaction_filter?.type === "Bonus" ? (
        <SelectFilter
          handleSelect={setTransactionFilter}
          selectedValue={transaction_filter?.transaction_type}
          filterKey="transaction_type"
          options={transaction_type_bonus}
          defaultOption="All"
          filterTitle="Status"
        />
      ) : (
        <SelectFilter
          handleSelect={setTransactionFilter}
          selectedValue={transaction_filter?.transaction_type}
          filterKey="transaction_type"
          options={transaction_types}
          defaultOption="All"
          filterTitle="Status"
        />
      )}
      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={clearTransactionFilters}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  const paymentsFilter = () => (
    <>
      <SearchFilter
        handleSearch={setPaymentFilter}
        searchPlaceholder="Search Order ID"
        filterKey="id"
        filterTitle="Order ID"
        searchedValue={payment_filter.id}
      />
      <SearchFilter
        handleSearch={setPaymentFilter}
        searchPlaceholder="Search Tranaction ID"
        filterKey="transaction_id"
        filterTitle="Tranaction ID"
        searchedValue={payment_filter.transaction_id}
      />
    </>
  );

  const reviewsFilter = () => (
    <>
      <SelectFilter
        handleSelect={setUserReviewsFilter}
        selectedValue={reviews_filter?.status}
        filterKey="status"
        options={reviewStatuses}
        defaultOption="All"
        filterTitle="Status"
      />
    </>
  );

  const instructorsFilters = () => (
    <>
      <SearchFilter
        handleSearch={setInstructorsFilters}
        searchPlaceholder="Name"
        filterKey="text"
        filterTitle="Name"
        searchedValue={instructorsFilter.text}
      />
    </>
  );

  const postFilters = () => (
    <>
      <DateRangeFilter
        handleChange={setUserPostFilter}
        selectedValue={post_filter.created_at}
        filterKey="created_at"
        filterTitle="Published Date"
      />
      <SearchFilter
        handleSearch={setUserPostFilter}
        searchPlaceholder="Caption"
        filterKey="caption"
        filterTitle="caption"
        searchedValue={post_filter.caption}
      />
      <SearchFilter
        handleSearch={setUserPostFilter}
        searchPlaceholder="Poll Question"
        filterKey="poll_question"
        filterTitle="Poll Question"
        searchedValue={post_filter.poll_question}
      />

      <SearchFilter
        handleSearch={setUserPostFilter}
        searchPlaceholder="Hashtag"
        filterKey="hashtag"
        filterTitle="Hashtag"
        searchedValue={post_filter.hashtag}
      />
      <SelectFilter
        handleSelect={setUserPostFilter}
        selectedValue={post_filter.evergreen_club_tagged}
        filterKey="evergreen_club_tagged"
        options={egTaggedFilter}
        filterTitle="EG Tagged"
      />
      <SelectFilter
        handleSelect={setUserPostFilter}
        selectedValue={post_filter.reported}
        filterKey="reported"
        options={reportsFilterOption}
        filterTitle="Reported Posts"
        defaultOptionValue={false}
      />
      <SelectFilter
        handleSelect={setUserPostFilter}
        selectedValue={post_filter.status}
        filterKey="status"
        options={postsFilterStatus}
        defaultOption="All"
        filterTitle="Status"
      />
      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={resetUserPostFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  const friendsFilters = () => (
    <>
      <SearchFilter
        handleSearch={setUserFriendsFilter}
        searchPlaceholder="Username"
        filterKey="text"
        filterTitle="Username"
        searchedValue={friendsFilter.text}
      />
    </>
  );
  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggleTab("1");
                  }}
                >
                  BOOKING HISTORY
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggleTab("3");
                  }}
                >
                  PAYMENT HISTORY
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggleTab("2");
                  }}
                >
                  CREDIT USAGE
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => {
                    toggleTab("4");
                  }}
                >
                  Reviews
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "5" })}
                  onClick={() => {
                    toggleTab("5");
                  }}
                >
                  Waitlists
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "6" })}
                  onClick={() => {
                    toggleTab("6");
                  }}
                >
                  Referals
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "7" })}
                  onClick={() => {
                    toggleTab("7");
                  }}
                >
                  Partner Offers
                </NavLink>
              </NavItem>

              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "8" })}
                    onClick={() => {
                      toggleTab("8");
                    }}
                  >
                    Posts
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "9" })}
                    onClick={() => {
                      toggleTab("9");
                    }}
                  >
                    Stories
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "10" })}
                    onClick={() => {
                      toggleTab("10");
                    }}
                  >
                    Friends
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "11" })}
                    onClick={() => {
                      toggleTab("11");
                    }}
                  >
                    Followed Instructors
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "12" })}
                    onClick={() => {
                      toggleTab("12");
                    }}
                  >
                    Privacy Settings
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "13" })}
                    onClick={() => {
                      toggleTab("13");
                    }}
                  >
                    Notifications
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "14" })}
                    onClick={() => {
                      toggleTab("14");
                    }}
                  >
                    Reports
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "15" })}
                    onClick={() => {
                      toggleTab("15");
                    }}
                  >
                    Users Blocked by{" "}
                    <span className="bold-text">
                      {selectedUser?.user?.name}
                    </span>
                  </NavLink>
                </NavItem>
              </>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {isCourse === "true" ? (
                  <div className="user_details__events">
                    <DataTable
                      addBtn={<></>}
                      CreateTableData={configureCoursesTableData}
                      withFilter={true}
                      filter={coursesFilter}
                      handleSetFilter={setCoursesFilter}
                      count={courses_count}
                      skip={courses_filter?.skip}
                    />
                  </div>
                ) : (
                  <div className="user_details__events">
                    <DataTable
                      addBtn={
                        <div className="row user__pad_left15">
                          {(isAdmin || isCSSpecialist) && (
                            <BookEvent user_id={user_id} />
                          )}

                          <Tooltip
                            title="Download Booking History"
                            placement="bottom"
                            className="download__booking_history"
                          >
                            <i
                              className="fa fa-file-excel-o fa-2x icon-btn-action"
                              onClick={() => dowloadUserBookingHistory(user_id)}
                            ></i>
                          </Tooltip>
                        </div>
                      }
                      CreateTableData={configureTableData}
                      withFilter={true}
                      filter={eventFilter}
                      handleSetFilter={setFilter}
                      count={event_count}
                      skip={event_filter?.skip}
                    />
                  </div>
                )}
              </TabPane>
              <TabPane tabId="3">
                <div className="user_details__events">
                  <DataTable
                    addBtn={
                      <div className="row user__pad_left15">
                        <GeneratePaymentLink user_id={user_id} />
                        <Tooltip
                          title="Download Payment History"
                          placement="bottom"
                          className="download__payment_history"
                        >
                          <i
                            className="fa fa-file-excel-o fa-2x icon-btn-action"
                            onClick={() => downloadUserPaymentHistory(user_id)}
                          ></i>
                        </Tooltip>
                      </div>
                    }
                    CreateTableData={configurePaymentTableData}
                    withFilter={true}
                    filter={paymentsFilter}
                    handleSetFilter={setPaymentFilter}
                    count={payment_count}
                    skip={payment_filter?.skip}
                  />
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="user_details__events">
                  <DataTable
                    addBtn={
                      <div className="row user__pad_left15">
                        <AddBonusSession user_id={user_id} />
                        <Tooltip
                          title="Download Credit Usage"
                          placement="bottom"
                          className="download__credit_usage"
                        >
                          <i
                            className="fa fa-file-excel-o fa-2x icon-btn-action"
                            onClick={() =>
                              downloadUserCreditUsageHistory(user_id)
                            }
                          ></i>
                        </Tooltip>
                      </div>
                    }
                    CreateTableData={configureTranactionTableData}
                    withFilter={true}
                    filter={tranactionFilter}
                    handleSetFilter={setTransactionFilter}
                    count={transaction_count}
                    skip={transaction_filter?.skip}
                  />
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configureReviewsTableData}
                    withFilter={true}
                    filter={reviewsFilter}
                    handleSetFilter={setUserReviewsFilter}
                    count={reviews_count}
                    skip={reviews_filter?.skip}
                  />
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configureWaitlistTableData}
                    filter={waitlists_filter}
                    handleSetFilter={setUserWaitlistFilter}
                    count={waitlist_count}
                    skip={waitlists_filter?.skip}
                  />
                </div>
              </TabPane>
              <TabPane tabId="6">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configureReferalsTableData}
                    filter={referralsFilter}
                    handleSetFilter={setReferralsFilter}
                    count={referral_count}
                    skip={referralsFilter?.skip}
                  />
                </div>
              </TabPane>

              <TabPane tabId="7">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configureOffersData}
                    handleSetFilter={setOfferListFilters}
                    count={partner_offer_count}
                    skip={partner_offer_filter?.skip}
                  />
                </div>
              </TabPane>

              <TabPane tabId="8">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configurePostTableData}
                    handleSetFilter={setUserPostFilter}
                    count={posts_count}
                    skip={post_filter?.skip}
                    filter={postFilters}
                    withFilter={true}
                  />
                </div>
              </TabPane>

              <TabPane tabId="9">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configureStoriesTableData}
                    handleSetFilter={setStoriesFilters}
                    count={stories_count}
                    skip={stories_filter?.skip}
                  />
                </div>
              </TabPane>
              <TabPane tabId="10">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configureFriendsTableData}
                    handleSetFilter={setUserFriendsFilter}
                    count={friendsCount}
                    skip={friendsFilter.skip}
                    filter={friendsFilters}
                    withFilter={true}
                  />
                </div>
              </TabPane>
              <TabPane tabId="11">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configureInstructorsData}
                    handleSetFilter={setInstructorsFilters}
                    count={instructorsCount}
                    skip={instructorsFilter.skip}
                    filter={instructorsFilters}
                    withFilter={true}
                  />
                </div>
              </TabPane>
              <TabPane tabId="12">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configurePrivacySettingsTableData}
                    handleSetFilter={() => {}}
                    count={privacySetting?.length}
                    skip={0}
                    // filter={friendsFilters}
                    // withFilter={true}
                  />
                </div>
              </TabPane>

              <TabPane tabId="13">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configureNotificationTableData}
                    handleSetFilter={setNotificationFilters}
                    count={notificationsCount}
                    skip={notificationsFilter?.skip}
                    // filter={notificationsFilter}
                    // withFilter={true}
                  />
                </div>
              </TabPane>

              <TabPane tabId="14">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configureReportsTableData}
                    handleSetFilter={setReportsFilter}
                    count={reportsCount}
                    skip={reportsFilter?.skip}
                  />
                </div>
              </TabPane>

              <TabPane tabId="15">
                <div className="user_details__events">
                  <DataTable
                    CreateTableData={configureBlockedUsersTableData}
                    handleSetFilter={setBlockedToFilters}
                    count={blockedToUsersCount}
                    skip={blockedToUsersFilter?.skip}
                  />
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default UserTabs;
