import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import Modals from "../../../components/modal";
import { useAddCode } from "./hooks";
import CodeUploadForm from "./upload-codes/form/upload-form";
import { useUser } from "../../../common-hooks/user";

const AddCode = ({ offer }) => {
  const { id } = offer;
  const { isOpenModal, toggleModal, handleAddCode, files, addCodeLoading } =
    useAddCode(id);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }

  return (
    <div>
      <Tooltip title="Upload Codes" placement="bottom">
        <div
          className="action_offer-code  ml-4"
          onClick={() => {
            toggleModal();
          }}
        >
          <i className="fa fa-upload" aria-hidden="true"></i>
        </div>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <CodeUploadForm
              btn={`Add Code`}
              handleAddCode={handleAddCode}
              files={files}
              isLoading={addCodeLoading}
            />
          }
          title="Codes"
        />
      ) : null}
    </div>
  );
};

export default AddCode;
