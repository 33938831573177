import React from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../../components/data-table";
import { useUsers } from "./hooks";
import AddUser from "./add-user";
import EditUser from "./edit-user";
import {
  columns,
  userRoles,
  usersExportStatus,
  userAge,
  reportsFilterOption,
  ReportedUsercolumns,
} from "./constant";
import SearchFilter from "../../components/filters/search-filter";
import SelectFilter from "../../components/filters/select-filter";
import DateRangeFilter from "../../components/filters/date-range-filter";
import Tooltip from "@material-ui/core/Tooltip";
import ExportComponent from "./export";
import MultiSelectFilter from "./multi-select-filter";
import UserHistory from "./user-history";
import { getUserHistory } from "./actions";
import { useDispatch } from "react-redux";

const UserList = () => {
  let history = useHistory();
  const {
    users,
    loadingUsers,
    setFilter,
    filters,
    userCount,
    resetFilter,
    isReported,
  } = useUsers();
  const dispatch = useDispatch();
  const configureTableData = () => {
    try {
      let results = users.map((user) => {
        let newUser = Object.assign({}, user);
        newUser["email"] = user.email ? user.email : "NA";
        newUser["reports"] = user?.reported?.reported_count ? (
          <span
            onClick={() => {
              history.push({
                pathname: `/users/${user?.id}`,
                search: "?reported_tab=true",
              });
            }}
            className="schedule__booked-info"
          >
            {user?.reported?.reported_count} reports
          </span>
        ) : (
          "NA"
        );
        newUser["blocked"] = user?.blocked_count || "NA";
        newUser["action"] = (
          <>
            {!isReported && !user?.deleted ? <EditUser user={user} /> : null}
            <Tooltip
              title={user?.deleted || user?.is_deleted ? "View history" : "View"}
              placement="bottom"
              className="view_user_details_pad_left10"
            >
              <i
                className={`fa fa-eye icon-btn-action`}
                onClick={() => user?.deleted|| user?.is_deleted ? dispatch(getUserHistory([user?.user_status])) :
                  history.push({
                    pathname: `/users/${user?.id}`,
                  })
                }
              ></i>
            </Tooltip>
          </>
        );
        newUser.is_active = user.is_active ? (
          <i
            className="fa fa-check-circle table__icon-active"
            aria-hidden="true"
          ></i>
        ) : (
          <i
            className="fa fa-times-circle table__icon-deactive"
            aria-hidden="true"
          ></i>
        );
        newUser.is_deleted = user.is_deleted ? (
          <i
            className="fa fa-check-circle table__icon-active"
            aria-hidden="true"
          ></i>
        ) : (
          <i
            className="fa fa-times-circle table__icon-deactive"
            aria-hidden="true"
          ></i>
        );
        newUser.is_blacklisted = user.is_blacklisted ? (
          <i
            className="fa fa-check-circle table__icon-active"
            aria-hidden="true"
          ></i>
        ) : (
          <i
            className="fa fa-times-circle table__icon-deactive"
            aria-hidden="true"
          ></i>
        );
        newUser["age_group"] = user.age_group ? user.age_group : "Other";
        return newUser;
      });
      const reactTableData = {
        tableHeaderData: isReported ? ReportedUsercolumns : columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const userFilter = () => (
    <>
      <DateRangeFilter
        handleChange={setFilter}
        selectedValue={filters?.created_at}
        filterKey="created_at"
        filterTitle="Date"
      />
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search User"
        filterKey="text"
        filterTitle="User"
        searchedValue={filters?.text}
      />
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Country Code"
        filterKey="country_code"
        filterTitle="Country Code"
        searchedValue={filters?.country_code}
      />

      {!isReported ? (
        <SelectFilter
          handleSelect={setFilter}
          selectedValue={filters?.role}
          filterKey="role"
          options={userRoles}
          defaultOption="All Roles"
          filterTitle="Roles"
        />
      ) : null}

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.age_group}
        filterKey="age_group"
        options={userAge}
        defaultOption="All"
        filterTitle="Age"
      />

      {!isReported ? (
        <SelectFilter
          handleSelect={setFilter}
          selectedValue={filters?.reported}
          filterKey="reported"
          options={reportsFilterOption}
          filterTitle="Reported User"
        />
      ) : null}

        <MultiSelectFilter options={usersExportStatus}
          optionLabelFunc={(option) => option.displayText}
          filterKey="status"
          handleSelect={setFilter}
        />
      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={resetFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );
  return (
    <>
    <DataTable
      title={isReported ? "Reported Users" : "Users"}
      addBtn={
        <div className="row user__pad_left15">
          {!isReported ? (
            <>
              <AddUser />
              <ExportComponent />
            </>
          ) : null}
        </div>
      }
      searchPlaceholder="Search User"
      CreateTableData={configureTableData}
      withFilter={true}
      filter={userFilter}
      handleSetFilter={setFilter}
      count={userCount}
      skip={filters?.skip}
      loadingPaymenthistories
      isLoading={loadingUsers}
      />
      <UserHistory />
    </>
  );
};

export default UserList;
