import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";

const SearchFilter = ({
  filterTitle,
  searchPlaceholder,
  handleSearch,
  searchedValue,
  filterKey,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(searchedValue);
  }, [searchedValue]);

  const setSearchText = useCallback(
    _.debounce((searchValue) => {
      handleSearch(filterKey, searchValue);
    }, 600),
    []
  );

  return (
    <div className="table__search">
      <Tooltip title={filterTitle} placement="bottom">
        <Input
          className={`table__search table__search-input ${value ? 'table__active_filter' :''}`}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            setSearchText(e.target.value);
          }}
          placeholder={`${searchPlaceholder}`}
        />
      </Tooltip>
    </div>
  );
};

SearchFilter.propTypes = {
  searchPlaceholder: PropTypes.string,
  handleSearch: PropTypes.func,
  filterTitle: PropTypes.string,
  filterKey: PropTypes.string,
};

SearchFilter.defaultProps = {
  searchPlaceholder: "Search...",
  filterTitle: "Title",
};

export default SearchFilter;
