import { useState } from "react";

const formField = {
  landing_page: "",
  meta_title: "",
  meta_description: "",
  meta_keywords: "",
  page_url: "",
};

export const useMetaDataForm = ({ fieldValues, btn, isLoading }) => {
  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));

    return;
  };

  return {
    formValues,
    handleFormUpdate,
    btn,
    isLoading,
  };
};
