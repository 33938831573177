import { EditorState, Modifier } from "draft-js";

export const getAddedReactionList = (
  reactionsCountList,
  submittedReactionData,
  previousReaction
) => {
  let isCountAdded = false;

  let reactionsList = reactionsCountList.map((reaction) => {
    const newReactionObj = { ...reaction };
    if (
      newReactionObj.name?.toLowerCase() ===
      previousReaction.name?.toLowerCase()
    ) {
      newReactionObj.count = parseInt(newReactionObj.count) - 1;
    }
    return newReactionObj;
  });

  reactionsList = reactionsList.map((reaction) => {
    const newReactionObj = { ...reaction };
    if (
      newReactionObj.name?.toLowerCase() ===
      submittedReactionData.name?.toLowerCase()
    ) {
      newReactionObj.count = parseInt(newReactionObj.count) + 1;
      isCountAdded = true;
    }
    return newReactionObj;
  });
  if (!isCountAdded) {
    reactionsList.push({
      name: submittedReactionData.name?.toLowerCase(),
      count: 1,
      trackable_id: submittedReactionData.trackable_id,
    });
  }
  return reactionsList;
};

export const getRemovedReactionList = (
  reactionsCountList,
  selectedReaction
) => {
  const reactionsList = reactionsCountList.map((reaction) => {
    console.log(selectedReaction, reaction);
    const newReactionObj = { ...reaction };
    if (
      newReactionObj.name?.toLowerCase() === selectedReaction?.toLowerCase()
    ) {
      newReactionObj.count = parseInt(newReactionObj.count) - 1;
    }
    return newReactionObj;
  });
  return reactionsList;
};

export const _getLengthOfSelectedText = (editorState) => {
  const currentSelection = editorState.getSelection();
  const isCollapsed = currentSelection.isCollapsed();

  let length = 0;

  if (!isCollapsed) {
    const currentContent = editorState.getCurrentContent();
    const startKey = currentSelection.getStartKey();
    const endKey = currentSelection.getEndKey();
    const startBlock = currentContent.getBlockForKey(startKey);
    const isStartAndEndBlockAreTheSame = startKey === endKey;
    const startBlockTextLength = startBlock.getLength();
    const startSelectedTextLength =
      startBlockTextLength - currentSelection.getStartOffset();
    const endSelectedTextLength = currentSelection.getEndOffset();
    const keyAfterEnd = currentContent.getKeyAfter(endKey);

    if (isStartAndEndBlockAreTheSame) {
      length +=
        currentSelection.getEndOffset() - currentSelection.getStartOffset();
    } else {
      let currentKey = startKey;

      while (currentKey && currentKey !== keyAfterEnd) {
        if (currentKey === startKey) {
          length += startSelectedTextLength + 1;
        } else if (currentKey === endKey) {
          length += endSelectedTextLength;
        } else {
          length += currentContent.getBlockForKey(currentKey).getLength() + 1;
        }

        currentKey = currentContent.getKeyAfter(currentKey);
      }
    }
  }

  return length;
};

export const _removeSelection = (editorState) => {
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  const endKey = selection.getEndKey();
  const endOffset = selection.getEndOffset();
  if (startKey !== endKey || startOffset !== endOffset) {
    const newContent = Modifier.removeRange(
      editorState.getCurrentContent(),
      selection,
      "forward"
    );
    const tempEditorState = EditorState.push(
      editorState,
      newContent,
      "remove-range"
    );

    return tempEditorState;
  }
  return editorState;
};

export const _addPastedContent = (input, editorState) => {
  const inputLength = editorState.getCurrentContent().getPlainText().length;
  const remainingLength = 2000 - inputLength;

  const newContent = Modifier.insertText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    input.slice(0, remainingLength)
  );
  return EditorState.push(editorState, newContent, "insert-characters");
};