import {
  SET_SUBCATEGORY_LIST,
  SET_SUBCATEGORY_FILTER,
  RESET_SUBCATEGORY_FILTER,
} from "./actions";

const initialList = {
  subcategories: [],
  count: 0,
  filter: {
    type: "",
    status: "Published",
    limit: 10,
    skip: 0,
  },
};

const subCategoryReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_SUBCATEGORY_LIST: {
      return {
        ...state,
        subcategories: action.payload.sub_categories,
        count: action.payload.count || [],
      };
    }

    case SET_SUBCATEGORY_FILTER: {
      const { key, value } = action.payload;
      if (key === "status") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value,
            skip: 0,
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }

    case RESET_SUBCATEGORY_FILTER:
      return { ...state, filter: initialList.filter || {} };

    default:
      return state;
  }
};

export default subCategoryReducer;
