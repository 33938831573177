import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUser } from "../../../common-hooks/user";
import { getUsersFilter } from "../selectors";

export const useMultiSelectFilter = (filterKey, handleSelect) => {
    const [value, setValue] = useState([]);
    const { getFinalUserFilter } = useUser();
    const filters = useSelector(getUsersFilter);
    
    useEffect(() => {
        const finalFilter = getFinalUserFilter(value);
        handleSelect(filterKey,finalFilter)
    }, [value])

    return {value, setValue, filters};
}