import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteScheduleEvent, getScheduleEventList } from "../actions";
import { getScheduleEventsFilter } from "../selectors";

export const useDeleteScheduleEvent = ({ id }) => {
  const dispatch = useDispatch();
  const filter = useSelector(getScheduleEventsFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteScheduleEventLoading, setDeleteScheduleEventLoading] = useState(false);

  const handleDeleteScheduleEvent = async () => {
    setDeleteScheduleEventLoading(true);
    const is_success = await dispatch(deleteScheduleEvent(id));
    setDeleteScheduleEventLoading(false);
    if (is_success) {
      dispatch(getScheduleEventList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteScheduleEventLoading,
    handleDeleteScheduleEvent,
    isOpenModal,
    toggleModal,
  };
};
