import { useEffect, useRef, useState } from "react";

export const usePostImages = (props) => {
  const [imageModal, setImageModal] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [current, setCurrent] = useState(0);

  const slickRef = useRef();

  useEffect(() => {
    if (videoPlay) {
      setTimeout(() => {
        setVideoPlay(false);
      }, [1]);
    }
  }, [videoPlay]);

  const toggleImageModal = (mediaType, position) => {
    if (position) {
      setCurrent(position);
    }
    if (mediaType === "video" && !imageModal) {
      setVideoPlay(position || true);
    }
    setImageModal(!imageModal);
  };

  const handleCurrent = (index) => {
    setCurrent(index);
  };

  const handleBackClick = () => {
    setImageModal(false);
  };

  return {
    toggleImageModal,
    imageModal,
    videoPlay,
    slickRef,
    handleCurrent,
    current,
    handleBackClick,
    setVideoPlay
  };
};

export const useImageFullView = (props) => {
  const { slickRef, current, handleCurrent } = props;
  const [imageList, setImageList] = useState(props.images);

  const handleImageClick = (imageDetail) => {
    setImageList([imageDetail]);
  };

  const handleBackClick = () => {
    if (props.images.length !== imageList.length) {
      setImageList(props.images);
    } else {
      props.toggleImageModal();
    }
  };

  const handleNext = () => {
    if (imageList?.length > 1) {
      if (imageList[current + 1]) {
        handleCurrent(current + 1);
      } else {
        handleCurrent(0);
      }
    }
  };

  const handlePrev = () => {
    if (imageList?.length > 1) {
      if (imageList[current - 1]) {
        handleCurrent(current - 1);
      } else {
        handleCurrent(imageList.length - 1);
      }
    }
  };

  return {
    imageList,
    handleImageClick,
    handleBackClick,
    slickRef,
    handleNext,
    handlePrev,
    current,
  };
};
