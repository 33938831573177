import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommentList,
  changeCommentStatusAction,
  setCommentFilter,
  getCommentRepliesData,
  replyToCommentAction,
} from "../../comments/actions";
import {
  getComments,
  getCommentsCount,
  getCommentFilter,
} from "../../comments/selectors";
import { getUserData } from "../../../../../utils/auth";

export const useChats = () => {
  const dispatch = useDispatch();
  const comments = useSelector(getComments);
  const filters = useSelector(getCommentFilter);
  const labelCount = useSelector(getCommentsCount);
  const [loadingComments, setLoadingComments] = useState(false);
  const [replies, setReplies] = useState([]);
  const [mainComment, setMainComment] = useState({});
  const [currentUser, setCurrentUser] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [reason, setReason] = useState("");
  const [replyId, setReplyId] = useState();
  const [commentId, setCommentId] = useState();
  const [isRepliesLoading, setIsRepliesLoading] = useState(false);
  const [reply, setReply] = useState("");

  const [openContacts, setOpenContacts] = useState(false);

  const user = getUserData();
  const { id } = JSON.parse(user);

  const onOpenChat = (id) => {
    setReply("");
    getReplies(id);
    setCurrentUser(mainComment);
  };

  const onOpenContacts = () => {
    setOpenContacts((prevState) => ({ openContacts: !prevState.openContacts }));
  };

  const handleSendClick = (e) => {
    e.preventDefault();
    handleSendReply(reply, mainComment?.replyTo?.id || mainComment?.id);
  };

  const fetchComments = useCallback(async () => {
    setLoadingComments(true);
    await dispatch(getCommentList(filters));
    setLoadingComments(false);
  }, [dispatch, filters]);

  const getReplies = useCallback(async (id) => {
    setIsRepliesLoading(true);
    const { comment } = await dispatch(getCommentRepliesData(id));
    if (comment?.replies) {
      setReplies(comment.replies);
    } else {
      setReplies([]);
    }
    if (comment) {
      setMainComment(comment);
    }
    setCurrentUser(comment);
    setIsRepliesLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchComments();
  }, [filters, fetchComments]);

  useEffect(() => {
    if (comments[0]?.replyTo?.id || comments[0]?.id)
      getReplies(comments[0]?.replyTo?.id || comments[0]?.id);
  }, [comments, getReplies]);

  const setFilter = (key, value) => {
    dispatch(setCommentFilter({ key, value }));
    setCurrentUser();
    setReplies([]);
    setMainComment();
  };

  const updateCommentStatus = async (status, id, comment_id) => {
    const payload = { status, reason };
    setReplyId(id);
    setCommentId(comment_id);
    if (reason === "" && status === "Unpublished") {
      toggleModal();
    } else {
      const is_success = await dispatch(changeCommentStatusAction(payload, id));
      if (is_success) {
        getReplies(comment_id);
        if (status === "Unpublished") {
          toggleModal();
          setReason("");
        }
      }
    }
  };

  const handleSendReply = async (text, id) => {
    if (text && id) {
      const payload = {
        text,
      };
      const is_success = await dispatch(replyToCommentAction(payload, id));
      if (is_success) {
        getReplies(id);
        setReason("");
        setReply("");
      }
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleOnChange = (e) => {
    setReply(e.target.value);
  };

  return {
    setFilter,
    loadingComments,
    filters,
    comments,
    labelCount,
    updateCommentStatus,
    replies,
    mainComment,
    getReplies,
    handleSendReply,
    currentUser,
    setCurrentUser,
    toggleModal,
    isOpenModal,
    reason,
    setReason,
    replyId,
    commentId,
    openContacts,
    id,
    setReply,
    reply,
    onOpenChat,
    onOpenContacts,
    handleSendClick,
    isRepliesLoading,
    handleOnChange,
  };
};
