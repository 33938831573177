import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";

const namespace = "containers/categories";

export const SET_CATEGORY_LIST = `${namespace}/SET_CATEGORY_LIST`;
export const SET_CATEGORY_FILTER = `${namespace}/SET_CATEGORY_FILTER`;
export const RESET_CATEGORY_FILTER = `${namespace}/RESET_CATEGORY_FILTER`;
export const SET_INTERESTS_LIST = `${namespace}/SET_INTERESTS_LIST`;

const setCategoryList = (payload) => ({
  type: SET_CATEGORY_LIST,
  payload,
});

export const resetCategoryFilter = () => ({
  type: RESET_CATEGORY_FILTER,
});

export const setCategoryFilter = (payload) => ({
  type: SET_CATEGORY_FILTER,
  payload,
});

const setInterestList = (payload) => ({
  type: SET_INTERESTS_LIST,
  payload,
});

export const getCategoryList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCategories, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setCategoryList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getCategory = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCategory(id));
    const { data: { is_success, category, interest_ids, icon } = {} } =
      response;
    if (is_success) {
      return { category, interest_ids, icon };
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addCategory = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createCategories, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Category not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const editCategory = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateCategories(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Category not edited, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteCategory = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteCategories(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getInterestList = () => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getInterests);
    const { data: { is_success, interests } = {} } = response;
    if (is_success) {
      dispatch(setInterestList(interests));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
