import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNoticeList, setNoticeFilter } from "./actions";
import { getNotices, getNoticesCount, getNoticesFilter } from "./selectors";

export const useNotice = () => {
  const dispatch = useDispatch();
  const notices = useSelector(getNotices);
  const filters = useSelector(getNoticesFilter);
  const noticeCount = useSelector(getNoticesCount);

  const [loadingNotice, setloadingNotice] = useState(false);

  useEffect(() => {
    const fetchNotices = async () => {
      setloadingNotice(true);
      await dispatch(getNoticeList(filters));
      setloadingNotice(false);
    };
    fetchNotices();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setNoticeFilter({ key, value }));
  };

  return {
    notices,
    loadingNotice,
    setFilter,
    filters,
    noticeCount,
  };
};
