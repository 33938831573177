export const useEventCard = ({ data }) => {
  const {
    files: { banner: { url = "" } = {} },
    title = "",
    host = {},
    starts_at,
    slug = "",
    id = "",
  } = data;

  return {
    title,
    slug,
    host,
    starts_at,
    id,
    url,
  };
};
