import React from 'react'
import { Button } from "reactstrap";
import { useAddSection } from './hooks'
import SectionForm from "../form/section-form";
import Modals from '../../../../../components/modal';

const AddSection = (props) => {
  const {
    handleAddSection,
    addSectionLoading,
    toggleModal,
    isOpenModal,
  } = useAddSection(props);
  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          toggleModal();
        }}
      >
        <i className="fa fa-plus"></i> Add Section
</Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <SectionForm
              handleSectionForm={handleAddSection}
              btn="Add"
              isLoading={addSectionLoading}
            />
          }
          title="Add Section"
        />
      ) : null}
    </div>
  )
}

export default AddSection
