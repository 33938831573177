import React from "react";
import Modals from "../../../../../components/modal";
import DeleteConfirmation from "../../../../../components/delete-confirmation";
import { useDeleteLabel } from "./hooks";
import ActionButton from "../../../../../components/action-button";

const DeleteLabel = ({ label }) => {
  const { handleDeleteLabel, isOpenModal, toggleModal, deleteLabelLoading } =
    useDeleteLabel(label);

  return (
    <>
      <ActionButton
        title="Delete"
        iconClassName={`fa fa-trash icon-btn-action text-danger `}
        onClick={toggleModal}
        className="pull-right"
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeleteLabel}
              isLoading={deleteLabelLoading}
            />
          }
          title="Delete Label"
        />
      ) : null}
    </>
  );
};

export default DeleteLabel;
