import React from "react";
import { useEditVideo } from "./hooks";
import Modals from "../../../../../components/modal";
import VideoForm from "../form/video-form";
import { Tooltip } from "@material-ui/core";
import { setLanguage } from "../../../../../utils/auth";

const EditVideo = ({ video, users, categories, setUserFilters }) => {
  const {
    editVideoLoading,
    handleEditVideo,
    toggleModal,
    isOpenModal,
    getValues,
    selectedVideo,
    handleLanguageData,
  } = useEditVideo(video);

  return (
    <div>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action`}
          onClick={() => {
            setLanguage("en");
            getValues();
          }}
        ></i>
      </Tooltip>

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <VideoForm
              handleVideoForm={handleEditVideo}
              btn="Save"
              isLoading={editVideoLoading}
              fieldValues={selectedVideo}
              users={users}
              categories={categories}
              handleLanguageData={handleLanguageData}
              setUserFilters={setUserFilters}
            />
          }
          title="Edit Video"
        />
      ) : null}
    </div>
  );
};

export default EditVideo;
