import { api, apiEndPoints } from "../../../../api";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";
import { clearFiles } from "../../../../utils/upload-file/actions";

const namespace = "containers/blogs";

export const SET_BLOG_LIST = `${namespace}/SET_BLOG_LIST`;
export const SET_CATEGORIES_LIST = `${namespace}/SET_CATEGORIES_LIST`;
export const SET_INTERESTS_LIST = `${namespace}/SET_INTERESTS_LIST`;
export const SET_BLOG_FILTER = `${namespace}/SET_BLOG_FILTER`;
export const CLEAR_BLOG_FILTER = `${namespace}/CLEAR_BLOG_FILTER`;
export const SET_USER_FILTER = `${namespace}/SET_USER_FILTER`;
export const CLEAR_USER_FILTER = `${namespace}/CLEAR_USER_FILTER`;

const setBlogList = (payload) => ({
  type: SET_BLOG_LIST,
  payload,
});

const setCategoriesList = (payload) => ({
  type: SET_CATEGORIES_LIST,
  payload,
});

export const setBlogFilter = (payload) => ({
  type: SET_BLOG_FILTER,
  payload,
});

export const clearBlogFilter = (payload) => ({
  type: CLEAR_BLOG_FILTER,
  payload,
});

const setInterestList = (payload) => ({
  type: SET_INTERESTS_LIST,
  payload,
});

export const setUserFilter = (payload) => ({
  type: SET_USER_FILTER,
  payload,
});

export const clearUserFilter = (payload) => ({
  type: CLEAR_USER_FILTER,
  payload,
});

export const addBlog = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createBlog, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getBlogList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getBlogs, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setBlogList(data));
      return data?.blogs;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getBlogData = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getBlog(id));
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editBlog = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateBlog(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );

      dispatch(clearFiles());
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteBlogData = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteBlog(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getCategoriesList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCategories, {
      params: { params },
    });
    const { data: { is_success, categories } = {} } = response;
    if (is_success) {
      dispatch(setCategoriesList(categories));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getInterestList = () => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getInterests);
    const { data: { is_success, interests } = {} } = response;
    if (is_success) {
      dispatch(setInterestList(interests));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUsers, {
      params: { params },
    });
    const { data: { is_success, users } = {} } = response;
    if (is_success) {
      return users;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getLabelList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getLabels, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadBlogs = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.exportBlogs, {
      responseType: "arraybuffer",
      params: { params },
    });
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const uploadBlogs = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.importBlogs, payload, {
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      },
    });
    const {
      data,
      data: { is_success, message },
    } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return data;
  } catch (error) {
    return false;
  }
};

export const downloadSampleBlogs = () => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.exportSampleBlogs, {
      responseType: "arraybuffer",
    });
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getAuthorData = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUser(id));
    const { data } = response;
    if (data.is_success) {
      return data?.user_details?.user;
    }
    return false;
  } catch (error) {
    return false;
  }
};
