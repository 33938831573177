import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PopularKeywords from '../../../CMS/events/popular-keywords';
import AddPopularKeyword from '../../../CMS/events/popular-keywords/add-popular-keyword';
import EditPopularKeyword from '../../../CMS/events/popular-keywords/edit-popular-keyword';

export default () => (
  <Switch>
    <Route path="/popular-keywords" component={PopularKeywords} />
    <Route path="/add-popular-keyword" component={AddPopularKeyword} />
    <Route path="/edit-popular-keyword/:popular_keyword_id" component={EditPopularKeyword} />
  </Switch>
);
