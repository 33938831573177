import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Blogs from '../../../CMS/blog/blogs';
import AddBlog from '../../../CMS/blog/blogs/add-blog';
import EditBlog from '../../../CMS/blog/blogs/edit-blog';
import Comments from './../../../CMS/blog/chats'

export default () => (
  <Switch>
    <Route path="/blogs" component={Blogs} />
    <Route path="/add-blog" component={AddBlog} />
    <Route path="/edit-blog/:blog_id" component={EditBlog} />
    <Route path="/comments" component={Comments} />
  </Switch>
);
