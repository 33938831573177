import React from "react";
import { Route, Switch } from "react-router-dom";

import Notices from "../../../Notices";

export default () => (
  <Switch>
    <Route path="/notices" component={Notices} />
  </Switch>
);
