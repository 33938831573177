import React from "react";
import { useBookEvent } from "./hooks";
import Modals from "../../../../components/modal";
import { Button } from "reactstrap";
import BookEventForm from "./form/book-event-form";

const BookEvent = ({ user_id }) => {
  const {
    isOpenModal,
    toggleModal,
    eventList,
    setFilter,
    handleBookEventForm,
    bookEventLoading,
    getEventsTimeSlots,
    eventTimeSlots,
    setSearchText,
  } = useBookEvent({
    user_id,
  });

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Book Event
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <BookEventForm
              handleBookEventForm={handleBookEventForm}
              btn="Book"
              isLoading={bookEventLoading}
              eventList={eventList}
              setFilter={setFilter}
              getEventsTimeSlots={getEventsTimeSlots}
              eventTimeSlots={eventTimeSlots}
              setSearchText={setSearchText}
            />
          }
          title="Book Event"
        />
      ) : null}
    </div>
  );
};

export default BookEvent;
