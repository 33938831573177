import { api, apiEndPoints } from "../../../../api";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";

const namespace = "containers/sections";

export const SET_PAGE_LIST = `${namespace}/SET_PAGE_LIST`;
export const SET_PAGE_FILTER = `${namespace}/SET_PAGE_FILTER`;

export const setPageList = (payload) => ({
  type: SET_PAGE_LIST,
  payload,
});

export const setPageFilter = (payload) => ({
  type: SET_PAGE_FILTER,
  payload,
});

export const addPage = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createPage, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Page not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getPageList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPages, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setPageList(data))
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getPageData = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPage(id));
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editPage = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updatePage(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const deletePageData = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deletePage(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getSectionList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSections, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};