import { useEffect, useState } from "react";
import { fieldsName } from "../constant";
import { getPartnerOffer } from "../selectors";
import { useSelector } from "react-redux";
import { initialLanguageFieldValue } from "../../../constants";

const formField = {
  code: "",
  title: initialLanguageFieldValue,
  description: initialLanguageFieldValue,
  type: "",
  language: "en",
  no_of_session: "",
  carry_forward: "",
  plan_amount: "",
  status: "",
  is_unlimited: false,
  partner_offer_text: initialLanguageFieldValue,
  card_bg_end_color: "#7ed303",
  card_bg_start_color: "#d0021b",
  card_text_color: "#bd10e0",
  variants: [
    {
      no_of_sessions: "",
      duration: "",
      gross_amount: "",
      discount_type: "Flat",
      discount: 0,
      amount: "",
      partner_offer_ids: [],
    },
  ],
};

const {
  type,
  no_of_session,
  plan_amount,
  variants,
  no_of_sessions,
  duration,
  gross_amount,
  discount_type,
  discount,
  amount,
  carry_forward,
  partner_offer_ids,
  product_reference_id,
} = fieldsName;

export const usePlanForm = ({
  fieldValues,
  handlePlanForm,
  btn,
  isLoading,
  handleLangugeData,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const offers = useSelector(getPartnerOffer);
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  useEffect(() => {}, [btn]);

  //to update only variants object field values
  const handleFormUpdateVariant = (setFieldValue, rest, key, value, index) => {
    let variantList = [...rest.values.variants];
    if (key === plan_amount) {
      variantList.forEach((variant) => {
        if (rest.values.code === "TopUp") {
          if (
            !isNaN(value) &&
            variant.no_of_sessions &&
            variant.discount_type &&
            !isNaN(variant.discount)
          ) {
            if (variant.discount_type === "Flat") {
              variant.amount =
                value * variant.no_of_sessions - variant.discount;
              variant.gross_amount = value * variant.no_of_sessions;
            } else if (variant.discount_type === "Percent") {
              variant.amount = Math.round(
                value * variant.duration -
                  ((value * variant.duration) / 100) * variant.discount
              );
              variant.gross_amount = value * variant.no_of_sessions;
            }
          } else {
            variant.amount = "";
            variant.gross_amount = "";
          }
          setFieldValue(carry_forward, 0);
          handleFormUpdate(carry_forward, 0);
        } else {
          if (!isNaN(value) && variant.duration && !isNaN(variant.discount)) {
            if (variant.discount_type === "Flat") {
              variant.amount =
                (value * variant.duration) / 30 - variant.discount;
              variant.gross_amount = (value * variant.duration) / 30;
            } else if (variant.discount_type === "Percent") {
              variant.amount = Math.round(
                (value * variant.duration) / 30 -
                  ((value * variant.duration) / 30 / 100) * variant.discount
              );
              variant.gross_amount = (value * variant.duration) / 30;
            }
          } else {
            variant.amount = "";
            variant.gross_amount = "";
          }
        }
      });
    } else if (key === no_of_session) {
      variantList.forEach((variant) => {
        if (value && variant.duration) {
          variant.no_of_sessions = (value * variant.duration) / 30;
        } else {
          variant.no_of_sessions = "";
        }
      });
    } else {
      if (key === discount_type) {
        variantList[index][key] = value;
        if (value === "Flat") {
          variantList[index][amount] = Math.round(
            variantList[index][gross_amount] - variantList[index][discount]
          );
        } else if (value === "Percent") {
          variantList[index][amount] = Math.round(
            variantList[index][gross_amount] -
              (variantList[index][gross_amount] / 100) *
                variantList[index][discount]
          );
        }
      } else if (key === partner_offer_ids) {
        variantList[index][key] = value;
      } else if (key === product_reference_id) {
        variantList[index][key] = value;
      } else if (key === discount) {
        variantList[index][key] = parseInt(value);
        if (variantList[index][gross_amount]) {
          if (variantList[index][discount_type] === "Flat") {
            variantList[index][amount] = Math.round(
              variantList[index][gross_amount] - parseInt(value)
            );
          } else if (variantList[index][discount_type] === "Percent") {
            if (value) {
              variantList[index][amount] = Math.round(
                variantList[index][gross_amount] -
                  (variantList[index][gross_amount] / 100) * parseInt(value)
              );
            } else {
              variantList[index][amount] = Math.round(
                variantList[index][gross_amount] -
                  (variantList[index][gross_amount] / 100) * 0
              );
            }
          }
        }
      } else {
        variantList[index][key] = parseInt(value);
      }
      if (rest.values.code === "TopUp") {
        if (
          !isNaN(rest.values.plan_amount) &&
          variantList[index][no_of_sessions] &&
          variantList[index][discount_type] &&
          !isNaN(variantList[index][discount])
        ) {
          // if (variantList[index][discount_type] === "Flat") {
          //   variantList[index][amount] =
          //     rest.values.plan_amount * variantList[index][no_of_sessions] -
          //     variantList[index][discount];
          //   // variantList[index][gross_amount] =
          //   //   rest.values.plan_amount * variantList[index][no_of_sessions];
          // } else if (variantList[index][discount_type] === "Percent") {
          //   variantList[index][amount] = Math.round(
          //     rest.values.plan_amount * variantList[index][no_of_sessions] -
          //       ((rest.values.plan_amount *
          //         variantList[index][no_of_sessions]) /
          //         100) *
          //         variantList[index][discount]
          //   );

          //   // variantList[index][gross_amount] =
          //   //   rest.values.plan_amount * variantList[index][no_of_sessions];
          // }
          if (key === gross_amount) {
            variantList[index][gross_amount] = value;
            if (
              variantList[index][discount_type] &&
              !isNaN(rest.values.plan_amount) &&
              !isNaN(variantList[index][discount])
            ) {
              if (variantList[index][discount_type] === "Flat") {
                variantList[index][amount] = Math.round(
                  value - variantList[index][discount]
                );
              } else if (variantList[index][discount_type] === "Percent") {
                variantList[index][amount] = Math.round(
                  value - (value / 100) * variantList[index][discount]
                );
              }
            }
          } else if (key === discount) {
            variantList[index][key] = parseInt(value);
            if (variantList[index][gross_amount]) {
              if (variantList[index][discount_type] === "Flat") {
                variantList[index][amount] = Math.round(
                  variantList[index][gross_amount] - parseInt(value)
                );
              } else if (variantList[index][discount_type] === "Percent") {
                if (value) {
                  variantList[index][amount] = Math.round(
                    variantList[index][gross_amount] -
                      (variantList[index][gross_amount] / 100) * parseInt(value)
                  );
                } else {
                  variantList[index][amount] = Math.round(
                    variantList[index][gross_amount] -
                      (variantList[index][gross_amount] / 100) * 0
                  );
                }
              }
            }
          }
        } else {
          variantList[index][amount] = "";
          variantList[index][gross_amount] = "";
        }
        setFieldValue(carry_forward, 0);
        handleFormUpdate(carry_forward, 0);
      } else if (key === gross_amount) {
        if (
          variantList[index][duration] &&
          variantList[index][discount_type] &&
          !isNaN(rest.values.plan_amount) &&
          !isNaN(variantList[index][discount])
        ) {
          if (variantList[index][discount_type] === "Flat") {
            variantList[index][amount] = Math.round(
              value - variantList[index][discount]
            );
          } else if (variantList[index][discount_type] === "Percent") {
            variantList[index][amount] = Math.round(
              value - (value / 100) * variantList[index][discount]
            );
          }
        }
      }

      //  else {
      //   if (
      //     !isNaN(rest.values.plan_amount) &&
      //     variantList[index][duration] &&
      //     variantList[index][discount_type] &&
      //     !isNaN(variantList[index][discount])
      //   ) {
      //     if (variantList[index][discount_type] === "Flat") {
      //       variantList[index][amount] = Math.round(
      //         (rest.values.plan_amount * variantList[index][duration]) / 30 -
      //           variantList[index][discount]
      //       );
      //       variantList[index][gross_amount] =
      //         (rest.values.plan_amount * variantList[index][duration]) / 30;
      //     } else if (variantList[index][discount_type] === "Percent") {
      //       variantList[index][amount] = Math.round(
      //         (rest.values.plan_amount * variantList[index][duration]) / 30 -
      //           ((rest.values.plan_amount * variantList[index][duration]) /
      //             30 /
      //             100) *
      //             variantList[index][discount]
      //       );
      //       variantList[index][gross_amount] =
      //         (rest.values.plan_amount * variantList[index][duration]) / 30;
      //     }
      //   } else {
      //     variantList[index][amount] = "";
      //     variantList[index][gross_amount] = "";
      //   }
      //   // if (rest.values.no_of_session && variantList[index][duration]) {
      //   //   variantList[index][no_of_sessions] =
      //   //     (rest.values.no_of_session * variantList[index][duration]) / 30;
      //   // }
      // }
    }
    setFieldValue(variants, variantList);
    handleFormUpdate(variants, variantList);
  };

  // handle click Variant of the Remove button
  const handleRemoveClickVariant = (rest, setFieldValue, index) => {
    const variantList = [...rest.values.variants];
    variantList.splice(index, 1);
    setFieldValue(variants, variantList);
  };

  // handle click Variant of the Add button
  const handleAddClickVariant = (rest, setFieldValue) => {
    let variantValues;
    if (rest.values.code === "TopUp") {
      variantValues = [
        ...rest?.values?.variants,
        {
          no_of_sessions: "",
          duration: 0,
          gross_amount: "",
          discount_type: "Flat",
          discount: 0,
          amount: "",
          partner_offer_ids: [],
          product_reference_id: "",
        },
      ];
    } else {
      variantValues = [
        ...rest?.values?.variants,
        {
          no_of_sessions: "",
          duration: "",
          gross_amount: "",
          discount_type: "Flat",
          discount: 0,
          amount: "",
          partner_offer_ids: [],
          product_reference_id: "",
        },
      ];
    }
    setFieldValue(variants, variantValues);
    handleFormUpdate(variants, variantValues)
  };

  const handlePlanCodeChange = (rest, code, setFieldValue) => {
    if (code === "TopUp") {
      handleFormUpdate(no_of_session, 1);
      setFieldValue(no_of_session, 1);
      handleFormUpdate(plan_amount, "");
      handleFormUpdate(type, "Paid");
      handleFormUpdate(carry_forward, 0);
      let variantList = [
        {
          no_of_sessions: "",
          duration: 0,
          gross_amount: "",
          discount_type: "Flat",
          discount: 0,
          amount: "",
          partner_offer_ids: [],
          product_reference_id: "",
        },
      ];
      handleFormUpdate(variants, variantList);
    } else {
      handleFormUpdate(no_of_session, "");
      handleFormUpdate(type, code === "Free" ? "Free" : "Paid");
      handleFormUpdate(carry_forward, "");
      if (code === "Free") {
        handleFormUpdate(plan_amount, 0);
      } else {
        handleFormUpdate(plan_amount, "");
      }
      let variantList = [
        {
          no_of_sessions: "",
          duration: "",
          gross_amount: "",
          discount_type: "Flat",
          discount: 0,
          amount: "",
          partner_offer_ids: [],
          product_reference_id: "",
        },
      ];
      handleFormUpdate(variants, variantList);
    }
  };

  const handleLanguge = (key, language, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: {
        ...prevFieldValue[key],
        [language]: value,
      },
    }));
  };

  const handleFormUpdate = (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));
  };

  return {
    formValues,
    handlePlanForm,
    btn,
    isLoading,
    handleFormUpdateVariant,
    handleRemoveClickVariant,
    handleAddClickVariant,
    handlePlanCodeChange,
    isDisabled,
    setIsDisabled,
    offers,
    handleLanguge,
    handleFormUpdate,
    handleLangugeData,
    setFormvalues,
  };
};
