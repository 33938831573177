import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { fileContext } from "../../constants";

export const useImageCrop = ({
  image,
  setFieldValue,
  name,
  context,
  dimensions,
  handleUploadFile,
  setCurrentBanner,
  index,
}) => {
  const imageRef = useRef();

  const { height, width } = dimensions;

  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const [scale, setScale] = useState(1.5);

  const onClickSave = () => {
    if (imageRef.current) {
      // This returns a HTMLCanvasElement, it can be made into a data URL,

      let ext = "webp";
      const canvasScaled = imageRef.current.getImageScaledToCanvas();
      let images = new Image();
      let dataurl = canvasScaled.toDataURL("image/webp", 1.0);
      images.src = canvasScaled.toDataURL("image/webp", 1.0);

      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      if (image.name.endsWith("jpeg")) ext = "jpeg";
      if (image.name.endsWith("jpg")) ext = "jpg";
      if (image.name.endsWith("gif")) ext = "gif";
      if (image.name.endsWith("png")) ext = "png";

      let reg = new RegExp(`\\b${ext}\\b`);

      let c = new File([u8arr], image.name.replace(reg, "webp"), {
        type: mime,
      });

      if (context.includes(fileContext.VIDEO_BANNER)) {
        handleUploadFile(
          [c],
          `${fileContext.VIDEO_BANNER} ${
            files.filter((file) =>
              file.file_context?.includes(fileContext.VIDEO_BANNER)
            ).length + 1
          }`
        );
      } else {
        handleUploadFile([c]);
      }

      if (context.includes(fileContext.VIDEO_BANNER)) {
        setFieldValue(
          name,
          files.filter(
            (file) =>
              file.file_context ===
              `${fileContext.VIDEO} ${
                files.filter((file) =>
                  file.file_context?.includes(fileContext.VIDEO_BANNER)
                ).length + 1
              }`
          )
        );
        setCurrentBanner((banner) =>
          banner.filter((ban, i) => i !== index && ban)
        );
      } else {
        setFieldValue(
          name,
          files.filter((file) => file.file_context === context)
        );

        setCurrentBanner((banner) =>
          banner.filter((ban, i) => i !== index && ban)
        );
      }
    }
  };

  const onClickCancel = () => {
    setCurrentBanner((banner) => banner.filter((ban, i) => i !== index && ban));
  };

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  return {
    image,
    setFieldValue,
    name,
    context,
    dimensions,
    handleUploadFile,
    setCurrentBanner,
    index,
    onClickCancel,
    onClickSave,
    handleScale,
    height,
    width,
    scale,
    imageRef,
  };
};
