import React from "react";
import { Button } from "reactstrap";

import TestimonialForm from "../form/testimonial-form";
import { useAddTestimonial } from "./hooks";
import Modals from "../../../../components/modal";

const AddPolicy = (props) => {
  const {
    addTestimonialLoading,
    handleAddTestimonial,
    isOpenModal,
    toggleModal,
  } = useAddTestimonial(props);
  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Add Testimonial
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <TestimonialForm
              handleTestinialForm={handleAddTestimonial}
              btn="Add"
              isLoading={addTestimonialLoading}
            />
          }
          title="Add Testimonial"
        />
      ) : null}
    </div>
  );
};

export default AddPolicy;
