import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../utils/upload-file/actions";
import { getSinglePartner, getPartnersList, editPartner } from "../actions";
import { getPartnerFilter } from "../selectors";

export const useEditPartner = ({ id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(getPartnerFilter);
  const [editPartnerLoading, setEditPartnerLoading] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState();
  const files = useSelector(({ fileReducer }) => fileReducer.files);

  const getPartner = async () => {
    setEditPartnerLoading(true);
    dispatch(clearFiles());
    const partner = await dispatch(getSinglePartner(id));
    if (partner) {
      partner.brand_name = partner.name;
      partner.color_code = partner.strip_color_code;
      setSelectedPartner(partner);
    }
    toggleModal();
    setEditPartnerLoading(false);
  };

  const handleEditPartner = async (values) => {
    const payload = {
      name: values.brand_name,
      strip_color_code: values.color_code,
      status: values.status,
      files,
    };

    setEditPartnerLoading(true);
    const is_success = await dispatch(editPartner(payload, id));
    setEditPartnerLoading(false);
    if (is_success) {
      setEditPartnerLoading(false);
      dispatch(getPartnersList(filters));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    getPartner,
    handleEditPartner,
    isOpenModal,
    selectedPartner,
    editPartnerLoading,
    toggleModal,
  };
};
