import React from "react";

const TermsAndCondition = () => {
  return (
    <div className="tnc__container">
      <section className="tnc__section">
        <h3 className="tnc__heading">TERMS OF USE </h3>
        <div className="tnc-content">
          <p>
            These terms of use (“Terms of Use” or “Terms”) mandate the terms and
            conditions on which the users (“User”) access and register on the
            “SeniorsClub” through its mobile device application (the “App”) ,
            which provides audio and audio-visual content in the genre of inter
            alia wellness, meditation,educational programs, lifestyle and
            entertainment services (collectively referred to as the “Platform”)
            to end-users (hereinafter referred to as “End-User” or “you” or
            “your”), operated by Seniors Club Private Limited (“Company”) ,a
            private limited company established under the laws of India having
            its registered office at 463 Dr Annie Besant Road, Worli, Mumbai
            400030, Maharashtra .The terms “we”, “our” or “us” refer to the
            Company. The terms “you”, “your” or “yourself” refer to the User.
          </p>
          <p>
            Please read these Terms carefully before accepting as they contain
            provisions that define your limits, your exercise of legal rights
            and obligations with respect to your use of our Services. By
            accessing and using our Services, you agree to be bound, without
            limitation or qualification, by these Terms. If you do not agree to
            be bound by these Terms, do not use the Services in any manner
            whatsoever or authorize or permit any other person or entity to do
            so. Any person who is not legally eligible to be bound by these
            Terms shall not access and use the Services. If you are not willing
            to agree to the foregoing, you should not click to affirm your
            acceptance thereto, in which case you are prohibited from accessing
            or using the Services. Nothing in these Terms shall be deemed to
            confer any third-party rights or benefits.
          </p>
          <p>
            While certain aspects of the Services or using certain Services, you
            will be subject to any additional terms applicable to such services
            that may be posted on the Service from time to time. All such terms
            are hereby incorporated by reference into these Terms of Service.
            The Services would be availed through the platform wherein our
            experts / mentors (Guides) would take /teach through the sessions as
            scheduled and communicated to you .
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title"> 1. ELIGIBILITY:</h4>
        <div className="tnc-content">
          <p>
            a. You must be of 55 years or more in your province, territory, or
            country, and otherwise be fully able and competent to enter into,
            abide by, and comply with these Terms .The Platform Services are not
            available to minors i.e. persons under the age of 18 (eighteen)
            years or to any Users suspended or removed by the Company for any
            reason whatsoever. You represent that You are of legal age to form a
            binding contract and are not a person barred from receiving Platform
            Services under the applicable laws.{" "}
          </p>
          <p>
            b. At any time during your use of our Services, we reserve the right
            to terminate your access without prior warning (i) if any
            information provided proves to be inaccurate, not current or
            incomplete; (ii) if it is believed that your actions may cause legal
            liability for you or us; and/or (iii) if you are found to be
            non-compliant with these Terms.
          </p>
          <p>
            c. The Company reserves the right to refuse access to the Platform
            or Platform Services to new Users or to terminate access granted to
            existing User(s) at any time without according any reasons for doing
            so.
          </p>
          <p>
            d. You shall not have more than one active Account (as defined
            hereinafter) on the Platform. Additionally, You are prohibited from
            selling, trading, or otherwise transferring Your Account to another
            party or impersonating any other person for the purposing of
            creating an account with the Platform.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title"> 2. CHANGES OR MODIFICATIONS:</h4>
        <div className="tnc-content">
          <p>We may modify these Terms from time to time in our sole discretion. We
          will notify or let you know by posting the modified Terms on the
          Website. You shall, at all times, be responsible for regularly
          reviewing and noting the changes made to the Terms. Your continued use
          of our Services after the effective date of any change to the Terms
          will signify your assent to and will be deemed to be your acceptance
          of the modified Terms. If you don’t agree to be bound by the modified
          Terms, kindly discontinue accessing and using the Services
          immediately. We may change or discontinue all or any part of the
          Services, at any time and without notice, at our sole discretion.</p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title"> 3. SYSTEM REQUIREMENTS:</h4>
        <div className="tnc-content">
         <p> For using the services in our Platform, you would require one or more
          compatible devices (mobile handset ), Internet access (fees may
          apply), and certain software and may require obtaining updates or
          upgrades from time to time. Because use of the Services involves
          hardware, software, and Internet access, your ability to access and
          use the Services may be affected by the performance of these factors.
          High speed Internet access is recommended. Seniors Club shall also not
          be responsible for any reduction in speed or efficiency of the
          Services due to poor connectivity or any other such collateral
          requirements related to internet services. You acknowledge and agree
          that such collateral system requirements, which may be changed from
          time to time, are your responsibility, and we shall not, under any
          circumstances whatsoever, be responsible or liable for such costs or
          performance .</p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title"> 4. USE OF THE APPLICATION</h4>
        <div className="tnc-content">
          <p>
            You shall be solely responsible for accessing and using our Services
            and shall ensure that you comply with laws of the territory while
            using our Services including but not limited to intellectual
            property, privacy, cookies and third party rights. You will use the
            Services only for such purposes as is permitted by (a) these Terms;
            and (b) any law, regulation or generally accepted practices or
            guidelines applicable in the country of which you are a citizen, in
            which you are a resident or from where you use the Services. You
            shall not use the Services or any Content (as defined) for any
            purpose that is unlawful or prohibited by these Terms, or to solicit
            the performance of any illegal activity or other activity that
            infringes the rights of Ultrahuman and/or others.
          </p>
          <p>
            You shall be solely responsible and liable for your use of the
            Services, including without limitation for any and all Content
            transmitted, streamed, communicated, recorded, received, and/or
            stored to or through the Services by you. To the extent that you
            choose to use any software applications provided by us in relation
            to the Services, you agree that you will promptly install all
            upgrades, bug fixes, patches, and other corrections relating to the
            Services made available by us.
          </p>
          <p>
            We would be improving our Services and creating new ones all the
            time. We at our sole discretion, at any time, with or without
            notice, and without any obligation or liability to you or any other
            party may suspend, terminate, limit, change, modify, downgrade,
            and/or update the Services (in whole or in part), including without
            limitation, any feature, functionality, integration or component
            thereof.
          </p>
          <p>
            The Services shall not, and is not intended to be used for any
            application where failure, interruption or malfunction may
            reasonably be anticipated to result in bodily injury, loss of life,
            or substantial damage to property and we shall not be liable for any
            claims, damages or loss which arise from such limitation.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title"> 5. REGISTRATION AND LOGIN DETAILS</h4>
        <div className="tnc-content">
          <p>
            You need to register with Seniors Club to access the platform and
            actively access all features in the platform as a User, through your
            account (“User Account”) which can be created by filling your
            contact number to login . To create a User Account, you must submit
            the information requested for at the time of registration
            (“Registration Information”) through your contact number and OTP
            will be generated which needs to be filled accordingly for its
            activation.
          </p>
          <p>
            Our information collection and use policies with respect to the
            privacy of such information are set forth in the Privacy Policy. You
            acknowledge and agree that you are solely responsible for the
            accuracy and content of the Registration Information, and you agree
            to keep it up to date.
          </p>
          <p>
            At any time after the registration, we reserve the right to suspend
            or terminate your User Account (i) if any Registration Information
            or any information provided thereafter proves to be inaccurate, not
            current or incomplete; (ii) if it is believed that your actions may
            cause legal liability for you, other End-Users or us; and/or (iii)
            if you are found to be non-compliant with these Terms.
          </p>
          <p>
            You are responsible for safeguarding your User Account details and
            password. You agree that you will not disclose your password to any
            third party and that you will take sole responsibility for any
            activities or actions under your User Account. You shall notify
            Seniors Club immediately in case of any breach of security or any
            unauthorized use of your User Account. Similarly, you shall never
            use another person’s User Account without prior consent from Seniors
            Club. You agree that you will not misrepresent yourself or represent
            yourself on the platform. You hereby expressly acknowledge and agree
            that you will be liable for any losses, damages (whether direct or
            indirect) caused to you, Seniors Club or any others as a result of
            unauthorized use of your User Account.
          </p>
          <p>
            By agreeing to create a User Account, you may receive occasional
            special offer, marketing, and survey communication emails with
            respect to the Services/Content. You can unsubscribe from the
            Seniors Club commercial emails by following the opt-out instruction
            in these emails.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title"> 6. USER CONTENT </h4>
        <div className="tnc-content">
          <p>
            You acknowledge that you are responsible for any content you may
            submit through the Application, including the legality, reliability,
            appropriateness, originality and copyright of any such content. You
            may not upload to, host, display, modify, transmit, update, share,
            distribute or otherwise publish through this Application any content
            that:
          </p>
          <p>
            i. is confidential, proprietary, invasive of privacy or publicity
            rights, infringing on patents, trademarks, copyrights, or other
            intellectual property rights, unlawful, harmful, threatening, false,
            fraudulent, libellous, defamatory, obscene, vulgar, pornographic,
            paedophilic, profane, abusive, harassing, hateful, racially,
            ethnically or otherwise objectionable, disparaging, relating or
            encouraging money laundering or gambling, or otherwise unlawful in
            any manner whatever, including, but not limited to any content that
            encourages conduct that would constitute a criminal offence,
            violates the rights of any party or otherwise gives rise to civil
            liability or otherwise violates any applicable laws for the time
            being in force;
          </p>
          <p>
            ii. belongs to another person and to which you do not have any right
            to;
          </p>
          <p>iii. violates any law for the time being in force;</p>
          <p>iv. harms minors in any way;</p>
          <p>
            v. deceives or misleads the addressee about the origin of such
            messages or communicates any information which is grossly offensive
            or menacing in nature;
          </p>
          <p>
            vi. threatens the unity, integrity, defence, security or sovereignty
            of India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognisable offence of
            prevents investigation of any offence or is insulting to any other
            nation;
          </p>
          <p>
            vii. infringes upon or violates any third party's privacy rights
            (including without limitation unauthorized disclosure of a person's
            name, physical address, phone number or email address);
          </p>
          <p>
            viii. provides material that exploits people in a sexual, violent or
            otherwise inappropriate manner or solicits personal information from
            anyone;
          </p>
          <p>
            ix. interferes with another user's use and enjoyment of the Site or
            any other individual's user and enjoyment of similar services; or
          </p>
          <p>
            x. directly or indirectly, offer for trade, the dealing of any item
            which is prohibited or restricted in any manner under the provisions
            of any applicable law, rule, regulation or guideline for the time
            being in force;
          </p>
          <p>
            xi. may contain software viruses or malware or files or programs
            designed to interrupt, destroy or limit the functionality of any
            computer;
          </p>
          <p>
            xii. contains advertisements or solicitations of any kind, or other
            commercial content;
          </p>
          <p>xiii. is designed to impersonate others;</p>
          <p>
            xiv. contains messages that offer unauthorized downloads of any
            copyrighted, confidential or private information;
          </p>
          <p>
            xv. contains multiple messages placed within individual folders by
            the same user restating the same point;
          </p>
          <p>
            xvi. contains identical (or substantially similar) messages to
            multiple recipients advertising any product or service, expressing a
            political or other similar message, or any other type of unsolicited
            commercial message; this prohibition includes but is not limited to
            (a) using the invitation functionality that may be available on the
            Site to send messages to people who do not know you or who are
            unlikely to recognize you as a known contact; (b) using the Site to
            connect to people who do not know you and then sending unsolicited
            promotional messages to those direct connections without their
            permission; or (c) sending messages to distribution lists, newsgroup
            aliases or group aliases.
          </p>
          <p>
            You may not use a false email address or other identifying
            information, impersonate any person or entity or otherwise mislead
            as to the origin of any content. Some features that may be available
            on this Site require registration. By registering, you agree to
            provide true, accurate, current and complete information about
            yourself.
          </p>
          <p>
            With respect to any content you submit or make available through the
            Application (other than personal information, which is handled in
            accordance with the Privacy Notice, you grant Seniors Club a
            perpetual, irrevocable, non-terminable, worldwide, royalty-free and
            non-exclusive license to use, copy, distribute, publicly display,
            modify, create derivative works, and sublicense such content or any
            part of such content, in any media. You hereby represent, warrant
            and covenant that any content you provide does not include anything
            (including, but not limited to, text, images, music or video) to
            which you do not have the full right to grant such a license to
            Seniors Club.
          </p>
          <p>
            Seniors Club does not authorise or approve such unauthorised usage
            of your information but by using the Application, you acknowledge
            and agree that Seniors Club will not be responsible for any third
            party's use of any of your information which you may have publicly
            disclosed on the Application. We advise that you exercise caution in
            disclosing any information on the Application which may become
            publicly available.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title"> 7. DISCLAIMERS</h4>
        <div className="tnc-content">
          <p>
            EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THESE TERMS OF USE, OR
            REQUIRED BY APPLICABLE LAW, SENIORS CLUB MAKES NO REPRESENTATIONS,
            COVENANTS OR WARRANTIES AND OFFERS NO OTHER CONDITIONS, EXPRESS OR
            IMPLIED, REGARDING ANY MATTER, INCLUDING, WITHOUT LIMITATION, THE
            MERCHANTABILITY, SUITABILITY, FITNESS FOR A PARTICULAR USE OR
            PURPOSE, OR NON-INFRINGEMENT OF PRODUCTS, ANY CONTENT ON THE
            APPLICATION, OR ANY PRODUCTS OR SERVICES PURCHASED THROUGH THE
            APPLICATION AS WELL AS WARRANTIES IMPLIED FROM A COURSE OF
            PERFORMANCE OR COURSE OF DEALING.
          </p>
          <p>
            YOUR USE OF THIS PLATFORM IS AT YOUR SOLE RISK. THE PLATFORM IS
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE RESERVE THE
            RIGHT TO RESTRICT OR TERMINATE YOUR ACCESS TO THE APPLICATION OR ANY
            FEATURE OR PART THEREOF AT ANY TIME. SENIORS CLUB DISCLAIMS ANY
            WARRANTIES THAT ACCESS TO THE APPLICATION WILL BE UNINTERRUPTED OR
            ERROR-FREE; THAT THE APPLICATION WILL BE SECURE; THAT THE
            APPLICATION OR THE SERVER THAT MAKES THE APPLICATION AVAILABLE WILL
            BE VIRUS-FREE; OR THAT INFORMATION ON THE APPLICATION WILL BE
            CORRECT, ACCURATE, ADEQUATE, USEFUL, TIMELY, RELIABLE OR OTHERWISE
            COMPLETE. IF YOU DOWNLOAD ANY CONTENT FROM THIS APPLICATION, YOU DO
            SO AT YOUR OWN DISCRETION AND RISK. YOU WILL BE SOLELY RESPONSIBLE
            FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
            FROM THE DOWNLOAD OF ANY SUCH CONTENT. NO ADVICE OR INFORMATION
            OBTAINED BY YOU FROM THE APPLICATION SHALL CREATE ANY WARRANTY OF
            ANY KIND.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title"> 8. LIMITATION OF LIABILITY</h4>
        <div className="tnc-content">
          <p>
            IN NO EVENT WILL SENIORS CLUB BE LIABLE FOR (I) ANY INCIDENTAL,
            CONSEQUENTIAL, OR INDIRECT DAMAGES (INCLUDING, BUT NOT LIMITED TO,
            DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS
            OR INFORMATION, AND THE LIKE) ARISING OUT OF THE USE OF OR INABILITY
            TO USE THE PRODUCT OR SERVICE, OR ANY INFORMATION, OR TRANSACTIONS
            PROVIDED ON THE WEBAPPLICATION, OR DOWNLOADED FROM THE APPLICATION ,
            OR ANY DELAY OF SUCH INFORMATION OR SERVICE. EVEN IF SENIORS CLUB OR
            ITS AUTHORIZED REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES, OR (II) ANY CLAIM ATTRIBUTABLE TO ERRORS,
            OMISSIONS, OR OTHER INACCURACIES IN THE SERVICE AND/OR MATERIALS OR
            INFORMATION DOWNLOADED THROUGH THE WEBAPPLICATION (III) ANY PRODUCTS
            OR SERVICES MADE AVAILABLE OR PURCHASED THROUGH THE APPLICATION;
            (IV) ANY FRAUDULENT ACT COMMITTED BY ANY PERSON USING THE
            APPLICATION WHICH MAY OR MAY NOT INVOLVE FINANCIAL TRANSACTIONS,
            INCLUDING ANY DAMAGES OR INJURY ARISING FROM ANY USE OF SUCH
            PRODUCTS OR SERVICES; (V) ANY DELAY OR INABILITY TO USE THE
            APPLICATION OR ANY INFORMATION, PRODUCTS OR SERVICES ADVERTISED IN
            OR OBTAINED THROUGH THE APPLICATION; (VI) THE MODIFICATION, REMOVAL
            OR DELETION OF ANY CONTENT SUBMITTED OR POSTED ON THE APPLICATION;
            OR (7) ANY USE OF THE APPLICATION, WHETHER BASED ON CONTRACT, TORT,
            STRICT LIABILITY OR OTHERWISE, EVEN IF Seniors Club HAS BEEN ADVISED
            OF THE POSSIBILITY OF DAMAGES.THIS DISCLAIMER APPLIES, WITHOUT
            LIMITATION, TO ANY DAMAGES OR INJURY ARISING FROM ANY FAILURE OF
            PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY
            IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, FILE CORRUPTION,
            COMMUNICATION-LINE FAILURE, NETWORK OR SYSTEM OUTAGE, LOSS OF
            PROFITS BY YOU, OR THEFT, DESTRUCTION, UNAUTHORIZED ACCESS TO,
            ALTERATION OF, LOSS OR USE OF ANY RECORD OR DATA, AND ANY OTHER
            TANGIBLE OR INTANGIBLE LOSS. YOU SPECIFICALLY ACKNOWLEDGE AND AGREE
            THAT NEITHER SENIORS CLUB NOR ITS LICENSORS, SUPPLIERS, FRANCHISEES
            OR THIRD-PARTY CONTENT PROVIDERS SHALL BE LIABLE FOR ANY DEFAMATORY,
            OFFENSIVE OR ILLEGAL CONDUCT OF ANY USER OF THE APPLICATION. YOUR
            REMEDY FOR ANY OF THE ABOVE CLAIMS OR ANY DISPUTE WITH SENIORS CLUB
            IS TO DISCONTINUE YOUR USE OF THE APPLICATION. YOU AND SENIORS CLUB
            AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE
            APPLICATION MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
            ACTION ACCRUES OR THE CAUSE OF ACTION IS PERMANENTLY
            BARRED.NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED ELSEWHERE,
            SENIORS CLUB’S TOTAL LIABILITY TOWARDS YOU FOR ALL LIABILITIES
            ARISING FROM YOUR USE OF THE APPLICATION OR PURCHASING PRODUCTS
            USING THE APPLICATION SHALL NOT, BE IT IN TORT OR CONTRACT, EXCEED
            THE AMOUNT PAID BY YOU TO SENIORS CLUB AGAINST THE VALUE OF THE
            PRODUCTS ORDERED BY YOU USING THE APPLICATION.BECAUSE SOME STATES DO
            NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
            OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU, IN
            SUCH STATES SENIORS CLUB’S LIABILITY IS LIMITED TO THE GREATEST
            EXTENT PERMITTED BY LAW. SENIORS CLUB MAKES NO REPRESENTATIONS
            WHATSOEVER ABOUT ANY OTHER WEB APPLICATION WHICH YOU MAY ACCESS
            THROUGH THIS ONE OR WHICH MAY LINK TO THIS APPLICATION. WHEN YOU
            ACCESS A NON- SENIORS CLUB, PLEASE UNDERSTAND THAT IT IS INDEPENDENT
            FROM SENIORS CLUB, AND THAT SENIORS CLUB HAS NO CONTROL OVER THE
            CONTENT ON THAT APPLICATION. IN ADDITION, A LINK TO SENIORS CLUB
            APPLICATION DOES NOT MEAN THAT SENIORS CLUB ENDORSES OR ACCEPTS ANY
            RESPONSIBILITY FOR THE CONTENT, OR THE USE, OF SUCH WEB APPLICATION.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title"> 9. INDEMNITY</h4>
        <div className="tnc-content">
          <p>
            You agree to indemnify and hold harmless Seniors Club, its
            employees, directors, officers, agents, and their successors and
            assigns from and against any and all claims, liabilities, damages,
            losses, costs and expenses including attorney’s fees caused by or
            arising out of claims based upon your actions or inactions, which
            may result in any loss or liability to Seniors Club or any third
            party including but not limited to breach of any warranties,
            representations, undertakings, or any relation to non-fulfillment of
            any of your any obligations under this Contract arising out of your
            violation of any applicable laws, regulations including but not
            limited to intellectual property rights, payment of statutory dues
            and taxes, claim of libel, defamation, violation of rights of
            privacy or publicity, loss of service by other subscribers, and
            infringement of intellectual property or other rights. This clause
            shall survive the expiry or termination of this Contract.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title"> 10. INTELLECTUAL PROPERTY</h4>
        <div className="tnc-content">
          <p>
            You may not modify, copy, distribute, transmit, display, reproduce,
            publish, license, create derivative works from, transfer, or sell
            any information, Services obtained from Seniors Club’s Application -
            directly or indirectly in any medium. Neither these materials nor
            any portion thereof may be stored in a computer except for personal
            and non-commercial use.
          </p>
          <p>a. Copyrights</p>
          <p>
            All content included in Seniors Club’s Application , including but
            not limited to text, graphics, logos, button icons, images, audio
            clips, video clips, digital downloads, data compilations, and
            software, is/ are the sole property/ proprietary of Seniors Club and
            protected copyright.
          </p>
          <p>b. Trademarks</p>
          <p>
            The mark and the logo indicated in the Seniors Club Application is
            the sole exclusive trademark of Seniors Club or related entities.
            All graphics, logos, page headers, button icons, scripts and service
            names (collectively ‘Trademarks’) present and visible on this
            Application are trademarks of Seniority. The Trademarks mark
            ‘Seniors Club’ and the logo “Seniors Club” may not be used in
            connection with service that is not in any manner connected to or
            provided by Seniors Club and is likely to cause confusion among
            customers, or in any manner that disparages or discredits Seniors
            Club. All other trademarks not owned by Seniors Club that appear on
            this Application, are the property of their respective owners, who
            may or may not be affiliated with or connected to Seniors Club.
          </p>
          <p>c. No unlawful or prohibited use</p>
          <p>
            You warrant to Seniors Club that You will not use Seniors Club
            Application for any purpose that is unlawful, illegal or prohibited
            by these Terms. You shall not use the Seniors Club Websites in any
            manner, which could damage, disable, overburden, or impair the
            Seniors Club Websites or interfere with any other party's use and
            enjoyment of the Seniors Club Websites. You shall not obtain or
            attempt to obtain any materials through any means not intentionally
            made available or provided for through Seniors Club Websites.
          </p>
          <p>d. User Comments</p>
          <p>
            The Company does not assume any obligation to monitor the
            information that Users post / may post on the Application. You
            warrant that any and all information, projects, files or other
            attachments sent to us ('User Material') or comments other than
            personal data, which you may transmit to the Company through the
            Site, does not infringe intellectual property rights or any other
            applicable laws. Such information, material or comments, will be
            treated as non-confidential and non-proprietary. By submitting any
            information or material, you give the Company an unlimited and
            irrevocable license to use, execute, show, modify and transmit such
            information, material or comments, including any underlying idea,
            concept or know-how. The Company reserves the right to use such
            information, in any way in its sole discretion.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title">11. ASSIGNMENT</h4>
        <div className="tnc-content">
          <p>
            You may not assign these Terms of Use (or any rights, benefits or
            obligations hereunder) by operation of law or otherwise without the
            prior written consent of Seniority, which may be withheld at
            Seniority’s sole discretion. Any attempted assignment that does not
            comply with these Terms of Use shall be null and void. Seniors Club
            may assign these Terms of Use, in whole or in part, to any third
            party in its sole discretion.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title">12. ENTIRE AGREEMENT</h4>
        <div className="tnc-content">
          <p>
            These Terms of Use constitute the entire agreement between you and
            Seniors Club regarding the specific matters herein, and all prior
            agreements, letters, proposals, discussions and other documents
            regarding the matters herein are superseded and merged into these
            Terms of Use.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title">13. SEVERABILITY</h4>
        <div className="tnc-content">
          <p>
            Each of the provisions of these Terms of Use is severable. If any
            provision of these Terms of Use (or part of a provision) is found by
            any court of competent jurisdiction to be invalid, unenforceable or
            illegal, the other provisions shall remain in force. If any invalid,
            unenforceable or illegal provision would be valid, enforceable or
            legal if some part of it were deleted or modified, the provision
            shall apply with whatever modification is necessary to give effect
            to these Terms of Use.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title">14. TERMINATION</h4>
        <div className="tnc-content">
          <p>
            Seniors Club may suspend or terminate your use of application or any
            service if it believes, in its sole and absolute discretion that you
            have breached and of the terms of use. The provisions of paragraphs
            4 (General ), 5 (Use of the Website), 12 (Intellectual Property), 8
            (Links), 21 (Jurisdiction and Arbitration), 10 (Limitation of
            Liability), 11(Indemnity), and this Clause20 (Termination) shall
            survive any termination of this Contract.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title">15.JURISDICTION AND ARBITRATION</h4>
        <div className="tnc-content">
          <p>
            This Contract shall be construed in accordance with the applicable
            laws of India. Subject to the provisions below, the courts at Mumbai
            shall have exclusive jurisdiction in any proceeding arising out of
            this Contract. Any dispute or differences either in interpretation
            or otherwise of any terms of this Contract between the parties
            hereto, shall be referred to an independent arbitrator who will be
            appointed by Seniors Club and its decision shall be final and
            binding on the parties hereto. The above arbitration shall be in
            accordance with the Arbitration and Conciliation Act, 1996 as
            amended from time to time. The arbitration shall be held in Mumbai.
            The high court of judicature at Mumbai alone shall have the
            jurisdiction and the laws of India shall apply.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title">16. FEEDBACKS</h4>
        <div className="tnc-content">
          <p>
            As part of the Platform Services provided to you, after availing
            such services, you agree to provide honest feedback/review about the
            concerned Platform Service, if required by the Company.
          </p>
          <p>
            You acknowledge and agree that by accessing or using the Platform or
            Platform Services, you may be exposed to content from others that
            you may consider offensive, indecent or otherwise objectionable. The
            Company disclaims all liabilities arising in relation to such
            offensive content on the Platform.
          </p>
          <p>
            The Company may enable Users to publish, post and upload, distribute
            or disseminate any topic /material/information/data on the Platform.
            You hereby agree and undertake to ensure that all such
            topic/material/information/data is appropriate and decent, and is
            not offensive, profane, defamatory, infringing, obscene. All such
            topic/material/information/data added, created, published, uploaded,
            submitted, distributed, disseminated or posted to the Platform by
            you is your sole responsibility and the same shall be in accordance
            with applicable law. The Company reserves the right to review any
            topic/material/information provided/data uploaded, if any, by you on
            the Platform and delete any topic/material/information/data that is
            inconsistent with these Terms of Use.
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title">17. GRIEVANCE OFFICER</h4>
        <div className="tnc-content">
          <p>
            If you have any questions or comments about these Terms of Use,
            please contact us by email at grievanceofficer@seniority.in. If you
            notice any discrepancies in the information provided to us or have
            any grievance against us, you may contact our Grievance Officer. The
            details of the Grievance Officer are as follows:
          </p>
          <p>
            Grievance Redressal: In compliance with E-commerce rules 2020 and
            the rules made thereunder, the Grievance Officer of Seniors Club for
            the purpose of this Agreement shall be:
          </p>
          <p>Grievance Officer Name: Sovona Mandal</p>
          <p>Email Address:  grievance-seniorsclub@seniority.in</p>
          <p>
            (Please include the following subject line in the email –
            “Attention: Grievance Officer”)
          </p>
        </div>
      </section>
      <section className="cancellation-section">
        <h4 className="tnc__bold-title">18.CONTACT US</h4>
        <div className="tnc-content">
          <p>
            In the event You have any complaints or concerns with respect to the
            Seniors Club Website or our services or if You have any questions ,
            please feel free to contact us on help-seniorsclub@seniority.in
            (between 10am – 8 pm) or reach out to our customer support +91 87502
            39239/ +91-7447443533
          </p>
        </div>
      </section>
    </div>
  );
};

export default TermsAndCondition;
