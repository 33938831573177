import React from "react";
import { Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import { useReactions, useReactionsModal } from "./hooks";
import { LeftArrow, CanncelIcon } from "../../images";
import styles from "./styles.module.scss";
import { REACTION_CODE, REACTION_ICON_MAP } from "../../constant";
import Text from "../../../../../components/text";
import ProfileImage from "../../../../../components/ProfileImage";
import { colors } from "../../../../../constants/colors";
import CustomModal from "../../../../../components/custom-modal";

const ReactionList = (props) => {
  const {
    reactions,
    activeTab,
    handleTabChange,
    statsDetail,
    handleReactionParams,
    count,
  } = useReactions(props);
  const { toggleVisible } = props;

  return (
    <div className={styles.reaction_scroller} id="reaction_scroller">
      <div className={styles.header}>
        <div className={styles.back_container}>
          <img
            src={LeftArrow}
            onClick={toggleVisible}
            alt="reactiosn back arrow"
          />
          <Text className={styles.header_text} size="18px" fontWeight={600}>
            Reactions
          </Text>
        </div>
        <img
          className={styles.close_icon}
          src={CanncelIcon}
          onClick={toggleVisible}
          alt="cancel"
        />
      </div>
      <div className={styles.tab_container}>
        <Text
          onClick={() => {
            handleTabChange("");
          }}
          fontWeight={600}
          className={`cursor_pointer ${styles.tab_icon} ${styles.count} ${
            styles.tab
          } ${activeTab === "" ? styles.tab_active : ""}`}
        >
          All
          <Text className={styles.count} fontWeight={500}>
            {count || ""}
          </Text>
        </Text>
        {statsDetail.map((reaction) => {
          const isActive = activeTab === reaction.name;
          return (
            <div
              key={reaction.name}
              className={`cursor_pointer ${styles.tab} ${
                isActive ? styles.tab_active : ""
              }`}
            >
              {REACTION_ICON_MAP[reaction.name] ? (
                <>
                  <img
                    src={REACTION_ICON_MAP[reaction.name]}
                    onClick={() => {
                      handleTabChange(reaction.name);
                    }}
                    className={styles.tab_icon}
                    alt="reactionIcon"
                    height={18}
                    width={18}
                  />
                  <Text className={styles.count} fontWeight={500}>
                    {reaction.count || ""}
                  </Text>
                </>
              ) : (
                <Text fontWeight={600}>All {reaction.count || ""}</Text>
              )}
            </div>
          );
        })}
      </div>
      <Divider />
      <InfiniteScroll
        className={styles.reaction_list}
        dataLength={reactions?.reactions?.length || 0}
        next={handleReactionParams}
        hasMore={reactions?.count > 0}
        scrollableTarget="reaction_scroller"
      >
        {reactions?.reactions?.map((reaction) => {
          const useProfileImg = reaction?.user?.profile_picture?.url;
          return (
            <div key={reaction?.id}>
              <div className={styles.user_detail_container}>
                <ProfileImage
                  className={styles.user_pic}
                  src={
                    reaction?.user?.profile_picture?.url ? useProfileImg : ""
                  }
                  title={reaction?.user?.name}
                />
                <Text
                  fontWeight={600}
                  size="16px"
                  lineheight="22px"
                  color={colors.darkBlack}
                >
                  {reaction?.user?.name}
                </Text>
              </div>
              <Divider />
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

const Reactions = (props) => {
  const { visible, toggleVisible } = useReactionsModal();
  if (props.totalReactions > 0) {
    return (
      <div className="cursor_pointer">
        <div onClick={toggleVisible}>
          {props.children ? (
            props.children
          ) : (
            <div className={styles.reaction_count_info}>
              {props.trackableType === "POST" && (
                <>
                  {props?.reactions?.map((reaction) => (
                    <>
                      {reaction?.count ? (
                        <img
                          key={reaction?.name}
                          className={styles.clap_icon}
                          src={REACTION_ICON_MAP[REACTION_CODE[reaction?.name]]}
                          alt="reaction"
                        />
                      ) : null}
                    </>
                  ))}
                </>
              )}

              <Text color={colors.lightBlack}>
                {props.totalReactions}{" "}
                {props.totalReactions > 1 ? "reactions" : "reaction"}
              </Text>
            </div>
          )}
        </div>

        <CustomModal
          contentRootClassName={styles.container}
          isOpen={visible}
          onCloseCallback={toggleVisible}
        >
          <ReactionList {...props} toggleVisible={toggleVisible} />
        </CustomModal>
      </div>
    );
  }
  return null;
};

Reactions.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  totalReactions: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

Reactions.defaultProps = {
  totalReactions: 0,
};

export default Reactions;
