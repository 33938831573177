import React from "react";
import styles from "./poll-component.module.scss";
import Text from "../../../../../components/text";
import { colors } from "../../../../../constants/colors";
import { Button } from "@material-ui/core";
import { usePoll } from "./hooks";

const PollComponent = (props) => {
  const { details, handleVote, myVote, totalVotes, timeLeft, data } =
    usePoll(props);

  return (
    <div className={styles.poll_container}>
      <Text
        fontWeight={500}
        size="15px"
        lineheight="140%"
        color={colors.darkBlack}
      >
        {details?.topic}
      </Text>
      <div className={styles.button_div}>
        {data?.poll_choices?.map((data, index) => (
          <div key={index} className={styles.button_container}>
            <>
              <Button
                onClick={() => handleVote(data)}
                className={styles.poll_button_percentage}
                style={{
                  background: `linear-gradient(to right, ${
                    myVote?.id === data?.id
                      ? colors.whiteIce
                      : colors.lightgray6
                  } ${((data?.vote_count * 100) / totalVotes).toFixed(1)}%, ${
                    colors.white
                  } ${data.vote_count.toFixed(1)}%)`,
                }}
              >
                {data?.title}
              </Button>
              <Text
                fontWeight={500}
                size="13px"
                lineheight="19.5px"
                color={colors.black}
                className={styles.counter}
              >
                {totalVotes > 0
                  ? ((data?.vote_count * 100) / totalVotes).toFixed(1)
                  : 0.0}
                %
              </Text>
            </>
          </div>
        ))}
      </div>
      <div className={styles.poll_status_container}>
        <Text
          fontWeight={600}
          size="12px"
          lineheight="150%"
          color={colors.lightgray3}
        >
          {totalVotes} Votes
        </Text>
        <div className={styles.dot} />
        <Text
          fontWeight={600}
          size="12px"
          lineheight="150%"
          color={colors.lightgray3}
        >
          {timeLeft}
        </Text>
      </div>
    </div>
  );
};
export default PollComponent;
