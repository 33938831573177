import React from "react";
import { Route, Switch } from "react-router-dom";

import ReportOptions from "../../../social/reports-options";

export default () => (
  <Switch>
    <Route path="/report-options" component={ReportOptions} />
  </Switch>
);
