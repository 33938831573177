import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { useUser } from "../../../../common-hooks/user";
import {
  setEventFilter,
  clearEventFilter,
  setUserTransactionFilter,
  clearUserTransactionFilter,
  getUserEventScheduleAction,
  getTranasactionHistoriesAction,
  setUserPaymentFilter,
  clearUserPaymentFilter,
  getPaymentHistoriesAction,
  downloadInvoice,
  downloadBookingHistory,
  downloadPaymentHistory,
  downloadCreditUsageHistory,
  setReviewsFilter,
  clearReviewsFilter,
  getReviewsAction,
  publishUnpublishReview,
  setWaitlistFilter,
  clearWaitlistFilter,
  getUserWaitList,
  getReferalsAction,
  clearReferralsFilter,
  copyPaymentLink,
  setReferralsFilter,
  getPartnerOffersList,
  setOfferListFilters,
  clearOfferListFilter,
  getCoursesData,
  setCourseFilter,
  clearPostFilter,
  getPostsList,
  setPostFilter,
  getUserStories,
  getUserFriends,
  clearFriendsFilter,
  setFriendsFilter,
  getUserPrivacySettings,
  getUserNotifications,
  clearNotificationsFilter,
  setNotificationsFilter,
  setStoriesFilter,
  setInstructorsFilter,
  clearInstructorsFilter,
  getUserFollwedInstructors,
  clearUserReportsFilter,
  clearBlockedToFilter,
  getUsersReports,
  getBlockedToUsers as getBlockedToUsersList,
  setUserReportsFilter,
  setBlockedToFilter,
} from "../actions";
import {
  getUserEvents,
  getUserEventFilter,
  getUserTransactionFilter,
  getUserTransactions,
  getUserTransactionCount,
  getUserEventCount,
  getUserPayments,
  getUserPaymentCount,
  getUserPaymentFilter,
  getReviews,
  getReviewsFilter,
  getReviewsCount,
  getWaitlist,
  getWaitlistCount,
  getWaitlistFilter,
  getReferals,
  getReferalCount,
  getReferralsFilter,
  getPartnerOffers,
  getPartnerOfferCount,
  getPartnerOfferFilters,
  getCourses,
  getCoursesCount,
  getCoursesFilter,
  getPostFilter,
  getPostsCount,
  getPosts,
  getStories,
  getStoriesCount,
  getFriends,
  getFriendsCount,
  getFriendsFilter,
  getPrivacySetting,
  getPrivacySettingsFilter,
  getNotifications,
  getNotificationsFilter,
  getNotificationsCount,
  getStoriesFilter,
  getInstructors,
  getInstructorsCount,
  getInstructorsFilter,
  getReports,
  getReportsCount,
  getReportsFilter,
  getBlockedToUsers,
  getBlockedToUsersCount,
  getBlockedToUsersFilter,
} from "../selectors";
import { useHistory, useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const useTabs = ({ user_id, user_role }) => {
  let query = useQuery();
  const history = useHistory();

  const dispatch = useDispatch();
  const events = useSelector(getUserEvents);
  const event_count = useSelector(getUserEventCount);
  const event_filter = useSelector(getUserEventFilter);
  const transaction_filter = useSelector(getUserTransactionFilter);
  const transactions = useSelector(getUserTransactions);
  const transaction_count = useSelector(getUserTransactionCount);
  const [activeTab, setActiveTab] = useState("1");
  const [loadingEvents, setLoadingEvents] = useState(false);
  const payments = useSelector(getUserPayments);
  const payment_count = useSelector(getUserPaymentCount);
  const payment_filter = useSelector(getUserPaymentFilter);
  const reviews = useSelector(getReviews);
  const reviews_filter = useSelector(getReviewsFilter);
  const reviews_count = useSelector(getReviewsCount);
  const waitlist_count = useSelector(getWaitlistCount);
  const waitlists = useSelector(getWaitlist);
  const waitlists_filter = useSelector(getWaitlistFilter);
  const referrals = useSelector(getReferals);
  const referral_count = useSelector(getReferalCount);
  const referralsFilter = useSelector(getReferralsFilter);
  const partner_offer = useSelector(getPartnerOffers);
  const partner_offer_count = useSelector(getPartnerOfferCount);
  const partner_offer_filter = useSelector(getPartnerOfferFilters);
  const courses = useSelector(getCourses);
  const courses_count = useSelector(getCoursesCount);
  const courses_filter = useSelector(getCoursesFilter);

  //user posts
  const posts = useSelector(getPosts);
  const posts_count = useSelector(getPostsCount);
  const post_filter = useSelector(getPostFilter);

  //user stories
  const stories = useSelector(getStories);
  const stories_count = useSelector(getStoriesCount);
  const stories_filter = useSelector(getStoriesFilter);

  //user friends
  const friends = useSelector(getFriends);
  const friendsCount = useSelector(getFriendsCount);
  const friendsFilter = useSelector(getFriendsFilter);

  //privacy setting
  const privacySetting = useSelector(getPrivacySetting);
  const privacySettingsFilter = useSelector(getPrivacySettingsFilter);

  //user notification
  const notifications = useSelector(getNotifications);
  const notificationsCount = useSelector(getNotificationsCount);
  const notificationsFilter = useSelector(getNotificationsFilter);

  //user followed instructors

  const instructors = useSelector(getInstructors);
  const instructorsCount = useSelector(getInstructorsCount);
  const instructorsFilter = useSelector(getInstructorsFilter);

  //user reports

  const reports = useSelector(getReports);
  const reportsCount = useSelector(getReportsCount);
  const reportsFilter = useSelector(getReportsFilter);

  //users who have been blocked by this user.
  const blockedToUsers = useSelector(getBlockedToUsers);
  const blockedToUsersCount = useSelector(getBlockedToUsersCount);
  const blockedToUsersFilter = useSelector(getBlockedToUsersFilter);

  const [isCourse, setIsCourse] = useState("false");
  const { isAdmin, isModerator, isCSSpecialist } = useUser();

  useEffect(() => {
    dispatch(clearEventFilter(""));
    dispatch(clearUserTransactionFilter(""));
    dispatch(clearUserPaymentFilter(""));
    dispatch(clearReviewsFilter(""));
    dispatch(clearWaitlistFilter(""));
    dispatch(clearReferralsFilter(""));
    dispatch(clearOfferListFilter(""));
    dispatch(clearPostFilter(""));
    dispatch(clearFriendsFilter(""));
    dispatch(clearNotificationsFilter(""));
    dispatch(clearInstructorsFilter(""));
    dispatch(setPostFilter({ key: "user_id", value: user_id }));
    dispatch(clearUserReportsFilter(""));
    dispatch(clearBlockedToFilter(""));
  }, [activeTab, dispatch, user_id]);

  useEffect(() => {
    let isReports = query.get("reported_tab");
    let isBlocker = query.get("blocked_tab");
    if (isReports) setActiveTab("14");
    if (isBlocker) setActiveTab("15");
  }, [query]);

  useEffect(() => {
    (async () => {
      if (activeTab === "1") {
        setLoadingEvents(true);
        await dispatch(getUserEventScheduleAction(user_id, event_filter));
        setLoadingEvents(false);
      }
    })();
  }, [user_id, isCourse, event_filter, dispatch, activeTab]);

  useEffect(() => {
    (async () => {
      if (activeTab === "1") {
        setLoadingEvents(true);
        await dispatch(getCoursesData(user_id, courses_filter));
        setLoadingEvents(false);
      }
    })();
  }, [user_id, isCourse, courses_filter, dispatch, activeTab]);

  useEffect(() => {
    if (activeTab === "2") {
      (async () => {
        await dispatch(
          getTranasactionHistoriesAction(user_id, transaction_filter)
        );
      })();
    }
  }, [dispatch, user_id, transaction_filter, activeTab]);

  useEffect(() => {
    if (activeTab === "3") {
      (async () => {
        await dispatch(getPaymentHistoriesAction(user_id, payment_filter));
      })();
    }
  }, [user_id, activeTab, payment_filter, dispatch]);

  useEffect(() => {
    if (activeTab === "4") {
      const getReviewsData = async () => {
        await dispatch(getReviewsAction(user_id, reviews_filter));
      };
      getReviewsData();
    }
  }, [user_id, user_role, activeTab, reviews_filter, dispatch]);

  useEffect(() => {
    if (activeTab === "5") {
      (async () => {
        await dispatch(getUserWaitList(user_id, waitlists_filter));
      })();
    }
  }, [dispatch, user_id, waitlists_filter, activeTab]);

  useEffect(() => {
    if (activeTab === "6") {
      (async () => {
        await dispatch(getReferalsAction(user_id, referralsFilter));
      })();
    }
  }, [dispatch, activeTab, referralsFilter, user_id]);

  useEffect(() => {
    if (activeTab === "7") {
      (async () => {
        await dispatch(getPartnerOffersList(user_id));
      })();
    }
  }, [dispatch, activeTab, user_id]);

  //user posts API call
  useEffect(() => {
    if (activeTab === "8") {
      (async () => {
        if (post_filter?.user_id) {
          await dispatch(getPostsList(post_filter));
        }
      })();
    }
  }, [dispatch, activeTab, post_filter]);

  //user stories API call
  useEffect(() => {
    if (activeTab === "9") {
      (async () => {
        await dispatch(getUserStories(user_id, stories_filter));
      })();
    }
  }, [dispatch, activeTab, user_id, stories_filter]);

  //user friends API call
  useEffect(() => {
    if (activeTab === "10") {
      (async () => {
        await dispatch(getUserFriends(user_id, friendsFilter));
      })();
    }
  }, [dispatch, activeTab, user_id, friendsFilter]);

  //user followed mentors

  useEffect(() => {
    if (activeTab === "11") {
      (async () => {
        await dispatch(getUserFollwedInstructors(user_id, instructorsFilter));
      })();
    }
  }, [dispatch, activeTab, user_id, instructorsFilter]);

  //user privacy setting
  useEffect(() => {
    if (activeTab === "12") {
      (async () => {
        await dispatch(getUserPrivacySettings(user_id, privacySettingsFilter));
      })();
    }
  }, [dispatch, activeTab, user_id, privacySettingsFilter]);

  useEffect(() => {
    if (activeTab === "13") {
      (async () => {
        await dispatch(getUserNotifications(user_id, notificationsFilter));
      })();
    }
  }, [dispatch, activeTab, user_id, notificationsFilter]);

  //user reports
  useEffect(() => {
    if (activeTab === "14") {
      (async () => {
        await dispatch(getUsersReports(user_id, reportsFilter));
      })();
    }
  }, [dispatch, user_id, reportsFilter, activeTab]);

  //users who have been blocked by this user.
  useEffect(() => {
    if (activeTab === "15") {
      (async () => {
        await dispatch(getBlockedToUsersList(user_id, blockedToUsersFilter));
      })();
    }
  }, [dispatch, user_id, blockedToUsersFilter, activeTab]);

  const setFilter = (key, value) => {
    if (key === "Type") {
      setIsCourse(isCourse === "true" ? "false" : "true");
    } else {
      dispatch(setEventFilter({ key, value }));
    }
  };

  const setCoursesFilter = (key, value) => {
    if (key === "Type") {
      setIsCourse(isCourse === "true" ? "false" : "true");
    } else {
      dispatch(setCourseFilter({ key, value }));
    }
  };

  const setTransactionFilter = (key, value) => {
    dispatch(setUserTransactionFilter({ key, value }));
  };

  const setPaymentFilter = (key, value) => {
    dispatch(setUserPaymentFilter({ key, value }));
  };

  const setUserReviewsFilter = (key, value) => {
    dispatch(setReviewsFilter({ key, value }));
  };

  const setUserWaitlistFilter = (key, value) => {
    dispatch(setWaitlistFilter({ key, value }));
  };

  const setUserPostFilter = (key, value) => {
    dispatch(setPostFilter({ key, value }));
  };

  const resetUserPostFilter = () => {
    dispatch(clearPostFilter());
  };

  const setStoriesFilters = (key, value) => {
    dispatch(setStoriesFilter({ key, value }));
  };

  const setUserFriendsFilter = (key, value) => {
    dispatch(setFriendsFilter({ key, value }));
  };

  //reports filter
  const setReportsFilter = (key, value) => {
    dispatch(setUserReportsFilter({ key, value }));
  };

  const resetReportsFilter = () => {
    dispatch(clearUserReportsFilter(""));
  };

  //blocked to filter
  const setBlockedToFilters = (key, value) => {
    dispatch(setBlockedToFilter({ key, value }));
  };

  const resetBlockedToFilter = () => {
    dispatch(clearBlockedToFilter(""));
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      if (query.has("reported_tab")) {
        query.delete("reported_tab");
        history.replace({
          search: query.toString(),
        });
      }
      setActiveTab(tab);
    }
  };

  const clearTransactionFilters = () => {
    dispatch(clearUserTransactionFilter(""));
    dispatch(
      getTranasactionHistoriesAction(user_id, {
        ...transaction_filter,
        transaction_type: "",
        type: "Regular",
      })
    );
  };

  const clearEventFilters = () => {
    dispatch(clearEventFilter(""));
  };

  const clearPaymentFilters = () => {
    dispatch(clearUserPaymentFilter(""));
  };

  const clearReviewsFilters = () => {
    dispatch(clearReviewsFilter(""));
  };

  const clearUserWaitlistFilter = () => {
    dispatch(clearWaitlistFilter(""));
  };

  const clearOfferListFilters = () => {
    dispatch(clearOfferListFilter(""));
  };

  const setNotificationFilters = (key, value) => {
    dispatch(setNotificationsFilter({ key, value }));
  };

  const setInstructorsFilters = (key, value) => {
    dispatch(setInstructorsFilter({ key, value }));
  };

  const clearInstructorsFilters = () => {
    dispatch(clearInstructorsFilter(""));
  };

  const downloadUserInvoice = async (id) => {
    const invoice = await dispatch(downloadInvoice(id));
    if (invoice) {
      var blob = new Blob([invoice], {
        type: "application/pdf",
      });
      saveAs(blob, `EG-Invoice-${id}.pdf`);
    }
  };

  const dowloadUserBookingHistory = async (id) => {
    const exportData = await dispatch(downloadBookingHistory(id, event_filter));
    if (exportData) {
      var blob = new Blob([exportData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `bookingHistory.xlsx`);
    }
  };

  const downloadUserPaymentHistory = async (id) => {
    const exportData = await dispatch(
      downloadPaymentHistory(id, payment_filter)
    );
    if (exportData) {
      var blob = new Blob([exportData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `paymentHistory.xlsx`);
    }
  };

  const downloadUserCreditUsageHistory = async (id) => {
    const exportData = await dispatch(
      downloadCreditUsageHistory(id, transaction_filter)
    );
    if (exportData) {
      var blob = new Blob([exportData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `creditUsageHistory.xlsx`);
    }
  };

  const updateReviewStatus = async (status, review_id) => {
    const payload = {
      status,
    };
    const is_success = await dispatch(
      publishUnpublishReview(payload, review_id)
    );
    if (is_success) {
      await dispatch(getReviewsAction(user_id, reviews_filter));
    }
  };

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    dispatch(copyPaymentLink());
  };

  return {
    events,
    event_count,
    event_filter,
    setFilter,
    transactions,
    activeTab,
    toggleTab,
    loadingEvents,
    setTransactionFilter,
    transaction_filter,
    transaction_count,
    clearTransactionFilters,
    clearEventFilters,
    setPaymentFilter,
    clearPaymentFilters,
    payments,
    payment_filter,
    payment_count,
    downloadUserInvoice,
    dowloadUserBookingHistory,
    downloadUserPaymentHistory,
    downloadUserCreditUsageHistory,
    reviews,
    reviews_filter,
    reviews_count,
    setUserReviewsFilter,
    clearReviewsFilters,
    updateReviewStatus,
    clearEventFilter,
    waitlists_filter,
    waitlists,
    waitlist_count,
    clearUserWaitlistFilter,
    setUserWaitlistFilter,
    isAdmin,
    isModerator,
    isCSSpecialist,
    referrals,
    referral_count,
    referralsFilter,
    setReferralsFilter,
    copyLink,
    partner_offer,
    partner_offer_count,
    clearOfferListFilters,
    setOfferListFilters,
    partner_offer_filter,
    isCourse,
    setIsCourse,
    courses,
    courses_count,
    courses_filter,
    setCoursesFilter,
    posts,
    post_filter,
    posts_count,
    setPostFilter,
    setUserPostFilter,
    stories,
    stories_count,
    friendsCount,
    friends,
    setUserFriendsFilter,
    friendsFilter,
    privacySetting,
    notificationsFilter,
    notifications,
    notificationsCount,
    setNotificationFilters,
    resetUserPostFilter,
    setStoriesFilters,
    stories_filter,
    instructors,
    instructorsCount,
    instructorsFilter,
    setInstructorsFilters,
    clearInstructorsFilters,
    blockedToUsers,
    blockedToUsersCount,
    blockedToUsersFilter,
    resetBlockedToFilter,
    setBlockedToFilters,
    reports,
    reportsCount,
    reportsFilter,
    setReportsFilter,
    resetReportsFilter,
  };
};
