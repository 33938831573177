import { trackableTypes } from "../../../../constants";
import { SET_COMMENT_LIST, SET_COMMENT_FILTER } from "./actions";

const initialState = {
  comments: [],
  count: 0,
  filter: {
    trackable_type: [trackableTypes.BLOG],
    trackable_id: null,
    parent_comment_id: null,
    trackable_title: "",
    status: "",
    limit: 10,
    skip: 0,
  },
};

const commentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMENT_LIST:
      return {
        ...state,
        comments: action.payload.comments,
        count: action.payload.count,
      };
    case SET_COMMENT_FILTER: {
      const { key, value } = action.payload;
      if (key === "skip") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value,
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value ? value : null,
        },
      };
    }
    default:
      return state;
  }
};

export default commentReducer;
