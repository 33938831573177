import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import Accordion from "../../../../components/Accordion";

const UserDevice = ({ device }) => (
  <Col md={12} lg={12} xl={12}>
    <Accordion
      className="user__tabs_collapse"
      title={"device"}
      titleClassName="profile__current-tasks-title"
    >
      <Card>
        <CardBody className="profile__card">
          <div className="profile__current-task">
            <div className="col-7 user_details_padLeft0">Platform</div>
            <div className="col-5 user_details_pad0">
              {device?.platform || "NA"}
            </div>
          </div>
          <div className="profile__current-task">
            <div className="col-7 user_details_padLeft0">Manufacturer</div>
            <div className="col-5 user_details_pad0">
              {device?.manufacturer || "NA"}
            </div>
          </div>
          <div className="profile__current-task">
            <div className="col-7 user_details_padLeft0">Model</div>
            <div className="col-5 user_details_pad0">
              {device?.device_model || "NA"}
            </div>
          </div>
          <div className="profile__current-task">
            <div className="col-7 user_details_padLeft0">Device ID</div>
            <div className="col-5 user_details_pad0">
              {device?.device_id || "NA"}
            </div>
          </div>

          <div className="profile__current-task">
            <div className="col-7 user_details_padLeft0">App Version</div>
            <div className="col-5 user_details_pad0">
              {device?.app_version || "NA"}
            </div>
          </div>
        </CardBody>
      </Card>
    </Accordion>
  </Col>
);

export default UserDevice;
