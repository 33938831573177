export const columnsEvent = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Event",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Type",
    accessor: "is_free",
    disableGlobalFilter: true,
  },
  {
    Header: "Starts At",
    accessor: "starts_at",
  },
  {
    Header: "Host Name",
    accessor: "host.name",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "booking_status",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Cancelled At",
    accessor: "cancelled_at",
    disableGlobalFilter: true,
  },
  {
    Header: "Cancellation Reason",
    accessor: "cancellation_reason",
    disableGlobalFilter: true,
  },
  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
  },
];

export const eventTypes = [
  {
    id: 1,
    value: "Free",
    displayText: "Free",
  },
  {
    id: 2,
    value: "Paid",
    displayText: "Paid",
  },
];

export const columnsCourse = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Course",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Commence At",
    accessor: "commence_at",
  },
  {
    Header: "Host Name",
    accessor: "hosts[0].name",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "booking_status",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Cancelled At",
    accessor: "cancelled_at",
    disableGlobalFilter: true,
  },
  {
    Header: "Cancellation Reason",
    accessor: "cancellation_reason",
    disableGlobalFilter: true,
  },
  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
  },
];

export const fieldsName = {
  startsAt: "startsAt",
  endsAt: "endsAt",
  status: "status",
  repeat: "repeat",
  language: "language",
  mode: "mode",
  attendeesLimit: "attendeesLimit",
  eventId: "eventId",
  hostIds: "hostIds",
  cohostIds: "cohostIds",
};

export const sessionFieldsName = {
  no_of_sessions: "no_of_sessions",
  reason: "reason",
};

export const bookEventFieldsName = {
  event: "event",
  time_slot: "time_slot",
};

export const statuses = [
  {
    id: 1,
    value: "Past",
    displayText: "Past",
  },
  {
    id: 2,
    value: "Upcoming",
    displayText: "Upcoming",
  },
];

export const isCourseTypes = [
  {
    id: 1,
    value: "false",
    displayText: "Event Schedule",
  },
  {
    id: 2,
    value: "true",
    displayText: "Course",
  },
];

export const session_types = [
  {
    id: 1,
    value: "Regular",
    displayText: "Regular",
  },
  {
    id: 2,
    value: "Bonus",
    displayText: "Bonus",
  },
];

export const transaction_types = [
  {
    id: 1,
    value: "Subscribe",
    displayText: "Subscribe",
  },
  {
    id: 2,
    value: "Book",
    displayText: "Book",
  },
  {
    id: 3,
    value: "Refund",
    displayText: "Refund",
  },
  {
    id: 4,
    value: "Marketing",
    displayText: "Marketing",
  },
];

export const transaction_type_bonus = [
  {
    id: 1,
    value: "Bonus",
    displayText: "Bonus",
  },
  {
    id: 2,
    value: "Referral",
    displayText: "Referral",
  },
];

export const booking_statuses = [
  {
    id: 1,
    value: "Attended",
    displayText: "Attended",
  },
  { id: 2, value: "Booked", displayText: "Booked" },
  {
    id: 3,
    value: "Cancelled",
    displayText: "Cancelled",
  },
];

export const columnsTranactions = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "details.title",
    disableGlobalFilter: true,
  },
  {
    Header: "Tranaction Type",
    width: 130,
    accessor: "transaction_type",
  },
  {
    Header: "Session No.",
    accessor: "no_of_session",
    width: 70,
    disableGlobalFilter: true,
  },
  {
    Header: "Balance",
    accessor: "balance",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Reason",
    accessor: "reason",
    width: 110,
    disableGlobalFilter: true,
  },
  {
    Header: "Created At",
    accessor: "created_at",
    width: 180,
    disableGlobalFilter: true,
  },
];

export const columnsPayments = [
  {
    Header: " Order ID",
    accessor: "id",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "Tranaction ID",
    width: 180,
    accessor: "transaction_id",
  },
  {
    Header: "Plan",
    accessor: "plan_variant.plan.title",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "Details",
    accessor: "plan_details",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "Gross Amount",
    accessor: "gross_amount",
    width: 120,
    disableGlobalFilter: true,
  },
  {
    Header: "GST",
    accessor: "gst",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "Amount",
    accessor: "amount",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "Payment Gateway",
    accessor: "gateway",
  },
  {
    Header: "Status",
    accessor: "status",
    width: 80,
    disableGlobalFilter: true,
  },
  {
    Header: "Transaction Date",
    accessor: "created_at",
    width: 180,
    disableGlobalFilter: true,
  },
  {
    Header: "Transaction Completed Date",
    accessor: "completed_at",
    width: 190,
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    width: 80,
    disableGlobalFilter: true,
  },
];

export const columnsReviews = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Event",
    accessor: "event.title",
    disableGlobalFilter: true,
  },
  {
    Header: "User",
    accessor: "name",
    disableGlobalFilter: true,
  },
  {
    Header: "Review",
    accessor: "text",
    disableGlobalFilter: true,
  },
  {
    Header: "Rating",
    accessor: "rating",
    width: 60,
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    width: 50,
    disableGlobalFilter: true,
  },
];

export const reviewStatuses = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
];

export const columnsWaitlists = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Event",
    accessor: "event_schedule.title",
    disableGlobalFilter: true,
  },
  {
    Header: "Starts At",
    accessor: "starts_at",
  },

  {
    Header: "Ends At",
    accessor: "ends_at",
  },
  {
    Header: "Mode",
    accessor: "mode",
  },

  {
    Header: "Language",
    accessor: "language",
  },
  {
    Header: "Waitlisted On",
    accessor: "created_at",
  },
];

export const columnsReferrals = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Name",
    accessor: "name",
    disableGlobalFilter: true,
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Contact",
    accessor: "contact",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
  },
  {
    Header: "Created At",
    accessor: "created_at",
    disableGlobalFilter: true,
    width: 180,
  },
];

export const columnsPosts = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },

  {
    Header: "Caption",
    accessor: "caption",
    disableGlobalFilter: true,
    width: 350,
  },
  {
    Header: "Poll Question",
    accessor: "topic",
    width: 150,
  },
  {
    Header: "Poll Options",
    accessor: "choices",
    width: 150,
    disableGlobalFilter: true,
  },

  {
    Header: "Shares",
    accessor: "share_count",
    width: 50,
  },
  {
    Header: "Created At",
    accessor: "created_at",
    width: 50,
  },
  {
    Header: "Updated At",
    accessor: "updated_at",
    width: 50,
  },
  {
    Header: "status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 50,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];

export const storyColumns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },

  {
    Header: "Caption",
    accessor: "caption",
    disableGlobalFilter: true,
    width: 200,
  },
  {
    Header: "Media",
    accessor: "url",
  },

  {
    Header: "Created At",
    accessor: "created_at",
    width: 200,
  },

  {
    Header: "Reactions",
    accessor: "reactions",
  },
  {
    Header: "Views",
    accessor: "viewers",
  },
  {
    Header: "Background Color",
    accessor: "background_color",
  },
  {
    Header: "Actions",
    accessor: "action",
  },
];

export const columnsPrivacySettings = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },

  {
    Header: "Privacy Type",
    accessor: "privacy_type",
    disableGlobalFilter: true,
    width: 200,
  },
  {
    Header: "Question",
    accessor: "question",
  },

  {
    Header: "User Permission",
    accessor: "user_permission",
    width: 200,
  },

  {
    Header: "Default Permission",
    accessor: "default_permission",
  },
];

export const columnsInstructros = [
  {
    Header: "Instructor ID",
    accessor: "following_id",
  },
  {
    Header: "Name",
    accessor: "name",
    disableGlobalFilter: true,
    // width: 200,
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const columnsNotification = [
  {
    Header: "ID",
    accessor: "notification_id",
    width: 50,
    disableGlobalFilter: true,
  },

  {
    Header: "Title",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Published At",
    accessor: "published_at",
    disableGlobalFilter: true,
  },
  {
    Header: "Read At",
    accessor: "read_at",
    disableGlobalFilter: true,
  },
  {
    Header: "Short Description",
    accessor: "short_description",
    disableGlobalFilter: true,
  },
  {
    Header: "Redirect To",
    accessor: "redirect_to",
  },
];

export const columnsFriends = [
  {
    Header: "ID",
    accessor: "user_id",
    width: 50,
    disableGlobalFilter: true,
  },

  {
    Header: "Name",
    accessor: "name",
    disableGlobalFilter: true,
    width: 200,
  },

  {
    Header: "Action",
    accessor: "action",
  },
];

export const cancelFormFieldName = {
  cancellation_reason: "cancellation_reason",
};

export const columnsReports = [
  {
    Header: "User ID",
    accessor: "id",
    width: 100,
    disableGlobalFilter: true,
  },

  {
    Header: "Name",
    accessor: "name",
    disableGlobalFilter: true,
    width: 200,
  },
  {
    Header: "Mobile Number",
    accessor: "mobile_number",
  },
  {
    Header: "Reason",
    accessor: "reason",
  },
  {
    Header: "Detailed Reason",
    accessor: "detailed_reason",
  },
  {
    Header: "Reported At",
    accessor: "reported_at",
  },
];

export const columnsBlocked = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },

  {
    Header: "Name",
    accessor: "name",
    disableGlobalFilter: true,
    width: 200,
  },
  {
    Header: "Mobile Number",
    accessor: "mobile_number",
  },
  {
    Header: "Blocked At",
    accessor: "blocked_at",
  },
];

export const generatePaymentLinkFieldNames = {
  code: "code",
  plan_variants: "plan_variants",
  price: "price",
  type: "type",
};

export const codeTypes = [
  {
    id: 1,
    value: "Paid",
    displayText: "Paid",
  },
  {
    id: 2,
    value: "TopUp",
    displayText: "TopUp",
  },
];

export const columnsOffers = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Coupon Code",
    accessor: "coupon_code.code",
  },
  {
    Header: "Partner Name",
    accessor: "partner.name",
  },
  {
    Header: "Offer Text",
    accessor: "offer_text",
  },
  {
    Header: "Partner ID",
    accessor: "partner.id",
  },

  {
    Header: "Expiry Date",
    accessor: "expires_at",
  },
];

export const postsFilterStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
  {
    id: 3,
    value: "Draft",
    displayText: "Draft",
  },
  // {
  //   id: 4,
  //   value: "Delete",
  //   displayText: "Delete",
  // },
];

export const reportsFilterOption = [
  {
    id: 1,
    value: "All",
    displayText: "All Posts",
  },
  {
    id: 2,
    value: "Yes",
    displayText: "Reported Posts",
  },
];

export const egTaggedFilter = [
  {
    id: 1,
    value: "All",
    displayText: "All Posts",
  },
  {
    id: 2,
    value: "Yes",
    displayText: "EG Tagged Posts",
  },
];
