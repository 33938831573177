import { useState } from "react";
import { useDispatch } from "react-redux";
import * as moment from "moment";
import { downloadScheduledCourses } from "../actions";

export const useExport = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dateError, setDateError] = useState("");

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    setDateError("");
  };

  const downloadCourseReport = async (values) => {
    const startDate = moment(values.startsAt).startOf("day").toDate();
    const uptoDate = moment(startDate).add(1, "month").toDate();
    const endDate = moment(values.endsAt, "DD/MM/YYYY").toDate();
    if (endDate >= uptoDate) {
      setDateError("Cant select more than one month");
    } else if (startDate > endDate) {
      setDateError("End Date must be greater than Start Date");
    } else {
      setIsLoading(true);
      const filters = {
        status: values.status === "All" ? "" : values.status,
        utc_offset: moment().utcOffset(),
        event_schedule_date: {
          start: moment(values.startsAt).format("DD/MM/YYYY"),
          end: moment(values.endsAt).format("DD/MM/YYYY"),
        },
      };
      const exportData = await dispatch(downloadScheduledCourses(filters));
      if (exportData) {
        toggleModal();
      }
      setIsLoading(false);
    }
  };

  return {
    isOpenModal,
    toggleModal,
    isLoading,
    downloadCourseReport,
    dateError,
    setDateError,
  };
};
