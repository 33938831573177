import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {
  planStatus,
  planType,
  fieldsName,
  codeTypes,
  discountTypes,
  isUnlimited,
} from "../constant";
import { DataLoader } from "../../../components/loader";
import { planFormSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";
import { usePlanForm } from "./hook";
import { useUser } from "./../../../common-hooks/user";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SketchColorPickerField from "../../../components/color-picker";
import { languages, initialLanguageFieldValue } from "../../../constants";
import { setLanguage } from "../../../utils/auth";

const PlanForm = (props) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    formValues,
    handlePlanForm,
    btn,
    isLoading,
    handleFormUpdateVariant,
    handleRemoveClickVariant,
    handleAddClickVariant,
    handlePlanCodeChange,
    isDisabled,
    setIsDisabled,
    offers,
    handleLanguge,
    handleFormUpdate,
    handleLangugeData,
    setFormvalues,
  } = usePlanForm(props);

  const { isAdmin } = useUser();

  const {
    code,
    title,
    description,
    type,
    no_of_session,
    plan_amount,
    carry_forward,
    status,
    variants,
    no_of_sessions,
    duration,
    gross_amount,
    discount_type,
    discount,
    amount,
    is_unlimited,
    partner_offer_ids,
    card_bg_end_color,
    card_bg_start_color,
    card_text_color,
    partner_offer_text,
    product_reference_id,
    language,
  } = fieldsName;

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={planFormSchema}
      initialValues={{
        code: formValues?.code || "",
        title: formValues?.title || initialLanguageFieldValue,
        description: formValues?.description || initialLanguageFieldValue,
        type: formValues?.type || "",
        language: formValues?.language || "en",
        no_of_session:
          (formValues?.no_of_session && formValues?.no_of_session !== "") ||
          formValues?.no_of_session === 0
            ? formValues?.no_of_session
            : "",
        carry_forward:
          (formValues?.carry_forward && formValues?.carry_forward !== "") ||
          formValues?.carry_forward === 0
            ? formValues?.carry_forward
            : "",
        plan_amount:
          (formValues?.plan_amount && formValues?.plan_amount !== "") ||
          formValues?.plan_amount === 0
            ? formValues?.plan_amount
            : "",
        status: formValues?.status || "",
        is_unlimited: formValues?.is_unlimited || false,
        partner_offer_text:
          formValues?.partner_offer_text || initialLanguageFieldValue,
        card_bg_end_color: formValues?.card_bg_end_color || "#7ed303",
        card_bg_start_color: formValues?.card_bg_start_color || "#d0021b",
        card_text_color: formValues?.card_text_color || "#bd10e0",
        variants: formValues?.variants
          ? formValues?.variants
          : [
              {
                no_of_sessions: "",
                duration: "",
                gross_amount: "",
                discount_type: "Flat",
                discount: 0,
                amount: "",
                partner_offer_ids: [],
              },
            ],
      }}
      onSubmit={handlePlanForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            {!isLoading && (
              <div className="materila-form_inline-child sticky__element">
                <TextField
                  className="material-form__field"
                  name={language}
                  placeholder="Language"
                  label="Language"
                  select
                  value={rest.values.language}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, language);
                    handleFormUpdate(name, value);
                    if (!rest.values.title[value]) {
                      handleLanguge(title, value, "");
                    }
                    if (!rest.values.description[value]) {
                      handleLanguge(description, value, "");
                    }
                    if (!rest.values.partner_offer_text[value]) {
                      handleLanguge(partner_offer_text, value, "");
                    }
                    if (btn === "Save") {
                      setLanguage(value);
                      handleLangugeData(rest, setFormvalues);
                    }
                  }}
                  onFocus={() => handleFocusField(language)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.language}
                >
                  {languages.map((language) => (
                    <MenuItem
                      key={language.id}
                      className="material-form__option"
                      value={language.value}
                    >
                      {language.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === language ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={language}
                  />
                )}
              </div>
            )}
            <div>
              <TextField
                className="material-form__field"
                name={code}
                placeholder="Type"
                label="Type"
                select
                value={rest.values.code}
                onChange={(e) => {
                  const { name, value } = e.target;
                  handleFormValueChange(rest, code);
                  handlePlanCodeChange(rest, value, setFieldValue);
                  handleFormUpdate(name, value);
                }}
                onFocus={() => handleFocusField(code)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.code}
              >
                {codeTypes.map((code) => (
                  <MenuItem
                    key={code.id}
                    className="material-form__option"
                    value={code.value}
                  >
                    {code.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === code ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={code}
                />
              )}
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  type="text"
                  value={rest.values.title[rest.values.language]}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, title);
                    handleLanguge("title", rest.values.language, value);
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>
              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  rows={2}
                  rowsMax={4}
                  multiline
                  name={description}
                  placeholder="Description"
                  label="Description"
                  type="text"
                  value={rest.values.description[rest.values.language]}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, description);
                    handleLanguge(description, rest.values.language, value);
                  }}
                  onFocus={() => handleFocusField(description)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === description ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={description}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={type}
                  placeholder="Type"
                  label="Type"
                  select
                  value={rest.values.type}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, type);
                    handleFormUpdate(name, value);
                  }}
                  disabled
                  onFocus={() => handleFocusField(type)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.type}
                >
                  {planType.map((plan) => (
                    <MenuItem
                      key={plan.id}
                      className="material-form__option"
                      value={plan.value}
                    >
                      {plan.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === type ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={type}
                  />
                )}
              </div>
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={status}
                  placeholder="Status"
                  label="Status"
                  select
                  value={rest.values.status}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, status);
                    handleFormUpdate(name, value);
                  }}
                  onFocus={() => handleFocusField(status)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.status}
                >
                  {planStatus.map((status) => (
                    <MenuItem
                      key={status.id}
                      className="material-form__option"
                      value={status.value}
                    >
                      {status.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === status ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={status}
                  />
                )}
              </div>

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={is_unlimited}
                  placeholder="Is Unlimited"
                  label="Is Unlimited"
                  select
                  value={rest.values.is_unlimited}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                    handleFormUpdate(name, value);
                    handleFormValueChange(rest, is_unlimited);
                    if (value) {
                      setFieldValue(no_of_session, 1000);
                      handleFormUpdate(no_of_session, 1000);
                      setIsDisabled(true);
                    } else if (!value && rest.values.code === "TopUp") {
                      setFieldValue(no_of_session, 1);
                      handleFormUpdate(no_of_session, 1);
                      setIsDisabled(false);
                    } else {
                      if (rest.values.no_of_session) {
                        setFieldValue(no_of_session, rest.values.no_of_session);

                        handleFormUpdate(
                          no_of_session,
                          rest.values.no_of_session
                        );
                      } else {
                        setFieldValue(no_of_session, "");
                        handleFocusField(no_of_session, "");
                      }

                      setIsDisabled(false);
                    }
                  }}
                  onFocus={() => handleFocusField(status)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.is_unlimited}
                >
                  {isUnlimited.map((value) => (
                    <MenuItem
                      key={value.id}
                      className="material-form__option"
                      value={value.value}
                    >
                      {value.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === is_unlimited ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={is_unlimited}
                  />
                )}
              </div>
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={no_of_session}
                  placeholder="No Of Session"
                  label={
                    rest.values.code === "TopUp"
                      ? "No Of Session"
                      : "No Of Sessions per 30 days"
                  }
                  type="number"
                  value={isDisabled ? 1000 : rest.values.no_of_session}
                  InputProps={{ inputProps: { min: 1 } }}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value ? parseInt(value) : "");
                    handleFormUpdate(name, parseInt(value));
                    handleFormValueChange(rest, no_of_session);
                    handleFormUpdateVariant(
                      setFieldValue,
                      rest,
                      no_of_session,
                      value,
                      ""
                    );
                  }}
                  onFocus={() => handleFocusField(no_of_session)}
                  onBlur={() => handleBlurField()}
                  disabled={
                    rest.values.code === "TopUp"
                      ? true
                      : isDisabled
                      ? true
                      : false
                  }
                />
                {focusField === no_of_session ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={no_of_session}
                  />
                )}
              </div>

              {rest?.values?.code !== "TopUp" && (
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={carry_forward}
                    placeholder="Carry Forward"
                    label="Carry Forward"
                    type="number"
                    value={rest.values.carry_forward}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setFieldValue(name, value ? parseInt(value) : "");
                      handleFormValueChange(rest, carry_forward);
                      handleFormUpdate(name, parseInt(value));
                    }}
                    onFocus={() => handleFocusField(carry_forward)}
                    onBlur={() => handleBlurField()}
                  />
                  {focusField === carry_forward ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={carry_forward}
                    />
                  )}
                </div>
              )}
            </div>

            <div
            // style={{
            //   display: rest.values.type === "Free" ? "none" : "block",
            // }}
            >
              <TextField
                className="material-form__field"
                name={plan_amount}
                placeholder="Amount"
                label={
                  rest.values.code === "TopUp"
                    ? "Amount per Session"
                    : "Amount per 30 days"
                }
                type="number"
                value={rest?.values?.plan_amount}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setFieldValue(name, value ? parseInt(value) : "");
                  handleFormUpdate(name, parseInt(value));
                  handleFormValueChange(rest, plan_amount);
                  handleFormUpdateVariant(
                    setFieldValue,
                    rest,
                    plan_amount,
                    value,
                    ""
                  );
                }}
                onFocus={() => handleFocusField(plan_amount)}
                onBlur={() => handleBlurField()}
                disabled={rest.values.type === "Free"}
              />
              {focusField === plan_amount ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={plan_amount}
                />
              )}
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={partner_offer_text}
                  placeholder="Partner Offer Text"
                  label="Partner Offer Text"
                  value={rest.values.partner_offer_text[rest.values.language]}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, partner_offer_text);
                    handleLanguge(name, rest.values.language, value);
                  }}
                  onFocus={() => handleFocusField(partner_offer_text)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === partner_offer_text ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={partner_offer_text}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="material-form_inline-fields mb-3">
              <div className="materila-form_inline-child">
                <SketchColorPickerField
                  value={rest?.values?.card_bg_start_color}
                  onChange={(color) => {
                    handleFormValueChange(rest, card_bg_start_color);
                    setFieldValue(card_bg_start_color, color);
                    handleFormUpdate(card_bg_start_color, color);
                  }}
                  name={card_bg_start_color}
                  label={"Card Background Start Color"}
                />
              </div>
              <div className="materila-form_inline-child mb-3">
                <SketchColorPickerField
                  value={rest?.values?.card_bg_end_color}
                  onChange={(color) => {
                    handleFormValueChange(rest, card_bg_end_color);
                    setFieldValue(card_bg_end_color, color);
                    handleFormUpdate(card_bg_end_color, color);
                  }}
                  name={card_bg_end_color}
                  label={"Card Background End Color"}
                />
              </div>
            </div>

            <div className="materila-form_inline-child mb-3">
              <SketchColorPickerField
                value={rest?.values?.card_text_color}
                onChange={(color) => {
                  handleFormValueChange(rest, card_text_color);
                  setFieldValue(card_text_color, color);
                  handleFormUpdate(card_text_color, color);
                }}
                name={card_text_color}
                label={"Card Text Color"}
              />
            </div>

            {rest?.values?.code &&
              rest?.values?.no_of_session &&
              rest?.values?.plan_amount !== "" &&
              rest?.values?.carry_forward !== "" && (
                <div className="section__container">
                  <div className="form__inline-items">
                    <div className="col-11 plan_left_pad0">
                      <p className="bold-text">Variants</p>
                    </div>
                    <div className="col-1 plan_right_pad0">
                      <Tooltip title="Add" placement="bottom" className="">
                        <i
                          className={`fa fa-plus icon-btn-action text-success`}
                          onClick={() =>
                            handleAddClickVariant(rest, setFieldValue)
                          }
                        ></i>
                      </Tooltip>
                    </div>
                  </div>

                  {rest?.values?.variants.map((x, i) => {
                    return (
                      <div className="plan__variants" key={i}>
                        <div className="form__inline-items">
                          <div className="col-11 plan_left_pad0">
                            <div
                              key={x + i}
                              className="matrial-form material-form_inline-fields"
                            >
                              {rest.values.code === "TopUp" ? (
                                <div className="materila-form_inline-child">
                                  <TextField
                                    className="material-form__field"
                                    name={`${variants}.${i}.${no_of_sessions}`}
                                    label={"No. of Session"}
                                    placeholder="No of Session"
                                    type="number"
                                    value={x?.no_of_sessions}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      handleFormUpdateVariant(
                                        setFieldValue,
                                        rest,
                                        no_of_sessions,
                                        value,
                                        i
                                      );
                                    }}
                                    onFocus={(e) => {
                                      const { name } = e.target;
                                      handleFocusField(name);
                                    }}
                                    onBlur={() => handleBlurField()}
                                  />
                                  {focusField ===
                                  `${variants}.${i}.${no_of_sessions}` ? null : (
                                    <ErrorMessage
                                      render={(msg) => (
                                        <div className="material-form__error">
                                          {msg}
                                        </div>
                                      )}
                                      name={`${variants}.${i}.${no_of_sessions}`}
                                    />
                                  )}
                                </div>
                              ) : (
                                <div className="materila-form_inline-child">
                                  <TextField
                                    className="material-form__field"
                                    name={`${variants}.${i}.${duration}`}
                                    label="Duration"
                                    placeholder="in Days"
                                    type="number"
                                    value={x?.duration}
                                    InputProps={{
                                      inputProps: { min: 0, step: 30 },
                                    }}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      handleFormUpdateVariant(
                                        setFieldValue,
                                        rest,
                                        duration,
                                        value,
                                        i
                                      );
                                    }}
                                    onFocus={(e) => {
                                      const { name } = e.target;
                                      handleFocusField(name);
                                    }}
                                    onBlur={() => handleBlurField()}
                                  />
                                  {focusField ===
                                  `${variants}.${i}.${duration}` ? null : (
                                    <ErrorMessage
                                      render={(msg) => (
                                        <div className="material-form__error">
                                          {msg}
                                        </div>
                                      )}
                                      name={`${variants}.${i}.${duration}`}
                                    />
                                  )}
                                </div>
                              )}

                              <div className="materila-form_inline-child">
                                <TextField
                                  className="material-form__field"
                                  name={`${variants}.${i}.${discount_type}`}
                                  placeholder="Discount Type"
                                  label="Discount Type"
                                  type="text"
                                  select
                                  value={x.discount_type}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    handleFormUpdateVariant(
                                      setFieldValue,
                                      rest,
                                      discount_type,
                                      value,
                                      i
                                    );
                                  }}
                                  onFocus={(e) => {
                                    const { name } = e.target;
                                    handleFocusField(name);
                                  }}
                                  onBlur={() => handleBlurField()}
                                >
                                  {discountTypes.map((status) => (
                                    <MenuItem
                                      key={status.id}
                                      className="material-form__option"
                                      value={status.value}
                                    >
                                      {status.displayText}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                {focusField ===
                                `${variants}.${i}.${discount_type}` ? null : (
                                  <ErrorMessage
                                    render={(msg) => (
                                      <div className="material-form__error">
                                        {msg}
                                      </div>
                                    )}
                                    name={`${variants}.${i}.${discount_type}`}
                                  />
                                )}
                              </div>

                              <div className="materila-form_inline-child">
                                <TextField
                                  className="material-form__field"
                                  name={`${variants}.${i}.${discount}`}
                                  label="Discount"
                                  placeholder="Discount"
                                  type="number"
                                  value={x?.discount}
                                  InputProps={{ inputProps: { min: 0 } }}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    handleFormUpdateVariant(
                                      setFieldValue,
                                      rest,
                                      discount,
                                      value,
                                      i
                                    );
                                  }}
                                  onFocus={(e) => {
                                    const { name } = e.target;
                                    handleFocusField(name);
                                  }}
                                  onBlur={() => handleBlurField()}
                                />
                                {focusField ===
                                `${variants}.${i}.${discount}` ? null : (
                                  <ErrorMessage
                                    render={(msg) => (
                                      <div className="material-form__error">
                                        {msg}
                                      </div>
                                    )}
                                    name={`${variants}.${i}.${discount}`}
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-1 plan_right_pad0">
                            {i > 0 && (
                              <Tooltip
                                title="Delete"
                                placement="bottom"
                                className="plan_top_pad10"
                              >
                                <i
                                  className={`fa fa-trash icon-btn-action text-danger `}
                                  onClick={() =>
                                    handleRemoveClickVariant(
                                      rest,
                                      setFieldValue,
                                      i
                                    )
                                  }
                                ></i>
                              </Tooltip>
                            )}
                          </div>
                        </div>

                        <div className="form__inline-items">
                          <div className="col-11 plan_left_pad0">
                            <div
                              key={x + i + x}
                              className="matrial-form material-form_inline-fields"
                            >
                              {rest.values.code === "TopUp" ? null : (
                                <div className="materila-form_inline-child">
                                  <TextField
                                    className="material-form__field"
                                    name={`${variants}.${i}.${no_of_sessions}`}
                                    label={"Total Sessions"}
                                    placeholder="No of Session"
                                    type="number"
                                    value={x?.no_of_sessions}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      handleFormUpdateVariant(
                                        setFieldValue,
                                        rest,
                                        no_of_sessions,
                                        value,
                                        i
                                      );
                                    }}
                                    onFocus={(e) => {
                                      const { name } = e.target;
                                      handleFocusField(name);
                                    }}
                                    onBlur={() => handleBlurField()}
                                  />
                                  {focusField ===
                                  `${variants}.${i}.${no_of_sessions}` ? null : (
                                    <ErrorMessage
                                      render={(msg) => (
                                        <div className="material-form__error">
                                          {msg}
                                        </div>
                                      )}
                                      name={`${variants}.${i}.${no_of_sessions}`}
                                    />
                                  )}
                                </div>
                              )}

                              <div className="materila-form_inline-child">
                                <TextField
                                  className="material-form__field"
                                  name={`${variants}.${i}.${gross_amount}`}
                                  label="Gross Amount"
                                  placeholder="Amount"
                                  type="number"
                                  value={x?.gross_amount}
                                  onFocus={(e) => {
                                    const { name } = e.target;
                                    handleFocusField(name);
                                  }}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    handleFormUpdateVariant(
                                      setFieldValue,
                                      rest,
                                      gross_amount,
                                      value,
                                      i
                                    );
                                  }}
                                  onBlur={() => handleBlurField()}
                                />
                                {focusField ===
                                `${variants}.${i}.${gross_amount}` ? null : (
                                  <ErrorMessage
                                    render={(msg) => (
                                      <div className="material-form__error">
                                        {msg}
                                      </div>
                                    )}
                                    name={`${variants}.${i}.${gross_amount}`}
                                  />
                                )}
                              </div>

                              <div className="materila-form_inline-child">
                                <TextField
                                  className="material-form__field"
                                  name={`${variants}.${i}.${amount}`}
                                  label="Amount"
                                  placeholder="Amount"
                                  type="number"
                                  value={x?.amount}
                                  onFocus={(e) => {
                                    const { name } = e.target;
                                    handleFocusField(name);
                                  }}
                                  onBlur={() => handleBlurField()}
                                  disabled={true}
                                />
                                {focusField ===
                                `${variants}.${i}.${amount}` ? null : (
                                  <ErrorMessage
                                    render={(msg) => (
                                      <div className="material-form__error">
                                        {msg}
                                      </div>
                                    )}
                                    name={`${variants}.${i}.${amount}`}
                                  />
                                )}
                              </div>

                              {rest.values.code === "TopUp" && (
                                <div className="materila-form_inline-child"></div>
                              )}
                            </div>

                            <div className="materila-form_inline-child">
                              <TextField
                                className="material-form__field"
                                name={`${variants}.${i}.${product_reference_id}`}
                                label="Product reference ID"
                                placeholder="Product reference ID"
                                type="text"
                                value={x?.product_reference_id}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  handleFormUpdateVariant(
                                    setFieldValue,
                                    rest,
                                    product_reference_id,
                                    value,
                                    i
                                  );
                                }}
                                onFocus={(e) => {
                                  const { name } = e.target;
                                  handleFocusField(name);
                                }}
                                onBlur={() => handleBlurField()}
                              />
                              {focusField ===
                              `${variants}.${i}.${product_reference_id}` ? null : (
                                <ErrorMessage
                                  render={(msg) => (
                                    <div className="material-form__error">
                                      {msg}
                                    </div>
                                  )}
                                  name={`${variants}.${i}.${product_reference_id}`}
                                />
                              )}
                            </div>
                            <div>
                              <Autocomplete
                                options={offers}
                                getOptionLabel={(offer) =>
                                  offer.offer_text && offer?.partner?.name
                                    ? `${offer?.partner?.name} - ${offer.offer_text}`
                                    : ""
                                }
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                value={x?.partner_offer_ids}
                                multiple
                                onChange={(e, value) => {
                                  handleFormUpdateVariant(
                                    setFieldValue,
                                    rest,
                                    partner_offer_ids,
                                    value,
                                    i
                                  );
                                }}
                                onFocus={() =>
                                  handleFocusField(partner_offer_ids)
                                }
                                onBlur={() => {
                                  handleBlurField();
                                  //rest.handleBlur();
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="material-form__field"
                                    name={`${variants}.${i}.${partner_offer_ids}`}
                                    placeholder="Select Partner Offer"
                                    label="Select Partner Offer"
                                    variant="standard"
                                  />
                                )}
                              />

                              {focusField ===
                              `${variants}.${i}.${partner_offer_ids}` ? null : (
                                <ErrorMessage
                                  render={(msg) => (
                                    <div className="material-form__error">
                                      {msg}
                                    </div>
                                  )}
                                  name={`${variants}.${i}.${partner_offer_ids}`}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-1 plan_right_pad0"></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

            {isAdmin && (
              <button
                type="submit"
                className="btn btn-success btn-right btn-sm"
              >
                {btn}
              </button>
            )}
          </form>
        </>
      )}
    </Formik>
  );
};

export default PlanForm;
