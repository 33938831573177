import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FAQ from '../../../faq';

export default () => (
  <Switch>
    <Route path="/faq" component={FAQ} />
  </Switch>
);
