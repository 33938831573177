import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";

const namespace = "containers/notices";

export const SET_NOTICE_LIST = `${namespace}/SET_NOTICE_LIST`;
export const SET_NOTICE_FILTER = `${namespace}/SET_NOTICE_FILTER`;
export const RESET_NOTICE_FILTER = `${namespace}/RESET_NOTICE_FILTER`;
export const SET_EVENT_LIST = `${namespace}/SET_EVENT_LIST`;
export const RESET_EVENT_FILTER = `${namespace}/RESET_EVENT_FILTER`;
export const SET_EVENT_FILTER = `${namespace}/SET_EVENT_FILTER`;
export const SET_BLOG_LIST = `${namespace}/SET_BLOG_LIST`;

export const setNoticeList = (payload) => ({
    type: SET_NOTICE_LIST,
    payload,
});

export const setEventList = (payload) => ({
    type: SET_EVENT_LIST,
    payload,
});

export const setNoticeFilter = (payload) => ({
    type: SET_NOTICE_FILTER,
    payload,
});

export const setEventFilter = (payload) => ({
    type: SET_EVENT_FILTER,
    payload,
});

export const resetEventFilter = (payload) => ({
    type: RESET_EVENT_FILTER,
    payload,
});

export const setBlogList = (payload) => ({
    type: SET_BLOG_LIST,
    payload,
});

export const getNoticeList = (params) => async (dispatch) => {
    try {
        const response = await api.get(apiEndPoints.getNotices, {
            params: { params },
        });
        const { data, data: { is_success } = {} } = response;
        if (is_success) {
            dispatch(setNoticeList(data));
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};

export const getEventList = (params) => async (dispatch) => {
    try {
        const response = await api.get(apiEndPoints.getSchedulesEvents, {
            params: { params },
        });
        const { data, data: { is_success } = {} } = response;
        if (is_success) {
            dispatch(setEventList(data));
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};

export const addNotice = (payload) => async (dispatch) => {
    try {
        const response = await api.post(apiEndPoints.addNotice, payload);
        const { data: { is_success, message } = {} } = response;
        if (is_success) {
            dispatch(
                showSnackbar({
                    message: message,
                    type: snackbarTypes.SUCCESS,
                })
            );
            return true;
        }
        dispatch(
            showSnackbar({
                message: "Notice not added, Please try again!",
                type: snackbarTypes.ERROR,
            })
        );
        return false;
    } catch (error) {
        return false;
    }
};

export const getNotice = (id) => async (dispatch) => {
    try {
        const response = await api.get(apiEndPoints.getSingleNotice(id));
        const { data: { is_success, notice } = {} } = response;
        if (is_success) {
            return notice;
        }
        dispatch(
            showSnackbar({
                message: "Notice not added, Please try again!",
                type: snackbarTypes.ERROR,
            })
        );
        return false;
    } catch (error) {
        return false;
    }
};

export const editNotice = (payload, id) => async (dispatch) => {
    try {
        const response = await api.put(apiEndPoints.editNotice(id), payload);
        const { data: { is_success, message } = {} } = response;
        if (is_success) {
            dispatch(
                showSnackbar({
                    message: message,
                    type: snackbarTypes.SUCCESS,
                })
            );
            return true;
        }
        dispatch(
            showSnackbar({
                message: "Notice not added, Please try again!",
                type: snackbarTypes.ERROR,
            })
        );
        return false;
    } catch (error) {
        return false;
    }
};

export const deleteNotice = (id) => async (dispatch) => {
    try {
        const response = await api.delete(apiEndPoints.deleteNotice(id));
        const { data: { is_success, message } = {} } = response;
        if (is_success) {
            dispatch(
                showSnackbar({
                    message: message,
                    type: snackbarTypes.SUCCESS,
                })
            );
            return true;
        }
        dispatch(
            showSnackbar({
                message: message,
                type: snackbarTypes.ERROR,
            })
        );
        return false;
    } catch (error) {
        return false;
    }
};

export const changeNoticeStatus = (id, payload) => async (dispatch) => {
    try {
        const response = await api.put(apiEndPoints.changeNoticeStatus(id), payload);
        const { data: { is_success, message } = {} } = response;
        if (is_success) {
            dispatch(
                showSnackbar({
                    message: message,
                    type: snackbarTypes.SUCCESS,
                })
            );
            return true;
        }
        dispatch(
            showSnackbar({
                message: message,
                type: snackbarTypes.ERROR,
            })
        );
        return false;
    } catch (error) {
        return false;
    }
};

export const getBlogList = (params) => async (dispatch) => {
    try {
        const response = await api.get(apiEndPoints.getBlogs, {
            params: { params },
        });
        const { data: { is_success, blogs } = {} } = response;
        if (is_success) {
            dispatch(setBlogList(blogs));
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};
