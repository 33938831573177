import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem, Chip } from "@material-ui/core";
import { fieldsName, testimonialStatus } from "../constant";
import { DataLoader } from "../../../../components/loader";
import { testimonialFormSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../utils/form";
import { fileContext } from "../../../../constants";
import { useUploadFiles } from "../../../../utils/upload-file/hooks";
import ProgressChip from "../../../../components/progress-chip";

const formField = {
  status: "",
  description: "",
};

const TestimonialForm = ({
  fieldValues,
  handleTestinialForm,
  btn,
  isLoading,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    handleDeleteAFile,
    files,
    handleSingleBannerFile,
    isFileUploadData,
    uploadProgress,
  } = useUploadFiles();

  const { status, description, desktop_banner, mobile_banner } = fieldsName;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = async (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={testimonialFormSchema}
      initialValues={{
        status: formValues?.status || "",
        description: formValues?.description || "",
        mobile_banner: files.filter(
          (file) => file.file_context === fileContext.MOBILE_BANNER
        ),
        desktop_banner: files.filter(
          (file) => file.file_context === fileContext.DESKTOP_BANNER
        ),
      }}
      onSubmit={handleTestinialForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div>
              <TextField
                className="material-form__field"
                name={description}
                placeholder="Description"
                label="Description"
                type="text"
                multiline
                value={rest.values.description}
                onChange={(event) => {
                  handleFormValueChange(rest, description);
                  handleFormUpdate(description, event.target.value);
                }}
                onFocus={() => handleFocusField(description)}
                onBlur={() => handleBlurField()}
              />
              {focusField === description ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={description}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={(event) => {
                  handleFormValueChange(rest, status);
                  handleFormUpdate(status, event.target.value);
                }}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {testimonialStatus.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>

            <div className="form__form-group-field mt-1 mb-3">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="desktop_banner">Choose Desktop Banner</label>
                  <span>
                    {files
                      .filter(
                        (file) =>
                          file.file_context === fileContext.DESKTOP_BANNER
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}

                    {isFileUploadData
                      .filter(
                        (file) =>
                          file.fileContext === fileContext.DESKTOP_BANNER
                      )
                      .map((x, i) => {
                        return (
                          <>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </>
                        );
                      })}
                  </span>
                  <input
                    accept="image/*"
                    onBlur={rest.handleBlur}
                    type="file"
                    name={desktop_banner}
                    id={desktop_banner}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleSingleBannerFile(
                        event.currentTarget.files[0],
                        fileContext.DESKTOP_BANNER
                      );
                      setFieldValue(
                        desktop_banner,
                        files.filter(
                          (file) =>
                            file.file_context === fileContext.DESKTOP_BANNER
                        )
                      );
                    }}
                  />
                </div>
                {focusField === desktop_banner ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error mt-1">{msg}</div>
                    )}
                    name={desktop_banner}
                  />
                )}
              </div>
            </div>

            <div className="form__form-group-field mt-4">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="mobile_banner">Choose Mobile Banner</label>
                  <span>
                    {files
                      .filter(
                        (file) =>
                          file.file_context === fileContext.MOBILE_BANNER
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}

                    {isFileUploadData
                      .filter(
                        (file) => file.fileContext === fileContext.MOBILE_BANNER
                      )
                      .map((x, i) => {
                        return (
                          <>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </>
                        );
                      })}
                  </span>
                  <input
                    accept="image/*"
                    onBlur={rest.handleBlur}
                    type="file"
                    name={mobile_banner}
                    id={mobile_banner}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleSingleBannerFile(
                        event.currentTarget.files[0],
                        fileContext.MOBILE_BANNER
                      );
                      setFieldValue(
                        mobile_banner,
                        files.filter(
                          (file) =>
                            file.file_context === fileContext.MOBILE_BANNER
                        )
                      );
                    }}
                  />
                </div>
                {focusField === mobile_banner ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error mt-1">{msg}</div>
                    )}
                    name={mobile_banner}
                  />
                )}
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-success btn-right btn-sm mt-2"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default TestimonialForm;
