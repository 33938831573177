import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import {
  TextField,
  MenuItem,
  Chip,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { categoryStatus, fieldsName, categoryType } from "../constant";
import { DataLoader } from "../../../components/loader";
import { categoriesFormSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";
import { useUploadFiles } from "../../../utils/upload-file/hooks";
import {
  fileContext,
  initialLanguageFieldValue,
  languages,
} from "../../../constants";
import ProgressChip from "../../../components/progress-chip";
import { setLanguage } from "../../../utils/auth";

const formField = {
  title: initialLanguageFieldValue,
  description: initialLanguageFieldValue,
  type: "",
  status: "",
  interestIds: "",
  language: "en",
};

const CategoriesForm = ({
  fieldValues,
  handleCategoryForm,
  btn,
  isLoading,
  interests,
  getCategoryData,
}) => {
  const {
    handleSingleIconFile,
    uploadError,
    fileIconError,
    handleDeleteAFile,
    files,
    isFileUploadData,
    uploadProgress,
    clearAllFiles,
  } = useUploadFiles();

  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    title,
    description,
    status,
    interestIds,
    type,
    file,
    is_popular,
    language,
  } = fieldsName;
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = (event) => {
    let { name, value } = event.target;
    if (name === type) {
      clearAllFiles();
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        is_popular: false,
      }));
    }

    if (name === is_popular) {
      value = event.target.checked;
    }

    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  const handleInterestUpdate = (name, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  const handleLanguge = (key, language, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: {
        ...prevFieldValue[key],
        [language]: value,
      },
    }));
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={categoriesFormSchema}
      initialValues={{
        title: formValues?.title || initialLanguageFieldValue,
        description: formValues?.description || initialLanguageFieldValue,
        type: formValues?.type || "",
        status: formValues?.status || "",
        language: formValues?.language || "en",
        is_popular: formValues?.is_popular || false,
        interestIds:
          (formValues?.interestIds &&
            interests.filter((interest) =>
              formValues?.interestIds.find((field) => field.id === interest.id)
            )) ||
          (formValues?.interest_ids &&
            interests.filter((interest) =>
              formValues?.interest_ids.find((id) => id === interest.id)
            )) ||
          [],
        file: files,
      }}
      onSubmit={handleCategoryForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={language}
                placeholder="Language"
                label="Language"
                select
                value={rest.values.language}
                onChange={(event) => {
                  const { value } = event.target;
                  handleFormValueChange(rest, language);
                  handleFormUpdate(event);
                  if (!rest.values.title[value]) {
                    handleLanguge(title, value, "");
                  }
                  if (!rest.values.description[value]) {
                    handleLanguge(description, value, "");
                  }
                  if (btn === "Save") {
                    setLanguage(value);
                    getCategoryData(rest, setFormvalues);
                  }
                }}
                onFocus={() => handleFocusField(language)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.language}
              >
                {languages.map((language) => (
                  <MenuItem
                    key={language.id}
                    className="material-form__option"
                    value={language.value}
                  >
                    {language.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === language ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={language}
                />
              )}
            </div>
            <div>
              <TextField
                className="material-form__field"
                name={type}
                placeholder="Type"
                label="Type"
                disabled={fieldValues?.category?.type ? true : false}
                select
                value={rest.values.type}
                onChange={(event) => {
                  handleFormValueChange(rest, type);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(type)}
                onBlur={() => handleBlurField()}
              >
                {categoryType.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === type ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={type}
                />
              )}
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  type="text"
                  value={rest.values.title[rest.values.language]}
                  onChange={(event) => {
                    handleFormValueChange(rest, title);
                    handleLanguge(
                      "title",
                      rest.values.language,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={description}
                  placeholder="Description"
                  label="Description"
                  type="text"
                  value={rest.values.description[rest.values.language]}
                  onChange={(event) => {
                    handleFormValueChange(rest, description);
                    handleLanguge(
                      "description",
                      rest.values.language,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(description)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === description ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={description}
                  />
                )}
              </div>

              <div className="col-md-2 mt-4 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={(event) => {
                  handleFormValueChange(rest, status);
                  handleFormUpdate(event);
                }}
                disabled={formValues?.is_used}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {categoryStatus.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>
            {rest?.values?.type !== "Faq" && (
              <div>
                <Autocomplete
                  multiple
                  options={interests}
                  getOptionLabel={(interest) =>
                    interest.title ? interest.title : ""
                  }
                  value={rest.values.interestIds}
                  name={interestIds}
                  onChange={(event, value) => {
                    setFieldValue(interestIds, value);
                    handleInterestUpdate(interestIds, value);
                  }}
                  onFocus={() => handleFocusField(interestIds)}
                  onBlur={() => handleBlurField()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      name={interestIds}
                      placeholder="Select Interests"
                      label="Select Interests"
                      variant="standard"
                    />
                  )}
                />

                {focusField === interestIds ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={interestIds}
                  />
                )}
              </div>
            )}

            {(rest.values.type === "Event" || rest.values.type === "Blog") && (
              <>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rest.values.is_popular}
                        onChange={(event) => {
                          handleFormValueChange(rest, is_popular);
                          handleFormUpdate(event);
                        }}
                        name={is_popular}
                        color="default"
                      />
                    }
                    label="Is Popular"
                  />
                </div>

                <div>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <div className="form__form-group-file">
                        <label htmlFor="file">Choose Icon</label>
                        <span>
                          {files.map((x) => {
                            return (
                              Object.keys(x).length !== 0 && (
                                <Chip
                                  className="selected-file"
                                  key={x.uuid_file_name}
                                  label={x.original_file_name}
                                  onDelete={() => handleDeleteAFile(x)}
                                  variant="outlined"
                                />
                              )
                            );
                          })}
                          {isFileUploadData
                            .filter(
                              (file) => file.fileContext === fileContext.ICON
                            )
                            .map((x, i) => {
                              return (
                                <>
                                  {x.isUpload && (
                                    <ProgressChip
                                      fileName={x.fileName}
                                      uploadProgress={uploadProgress}
                                    />
                                  )}
                                </>
                              );
                            })}
                        </span>
                        <input
                          accept="image/x-png"
                          onBlur={rest.handleBlur}
                          type="file"
                          name={file}
                          id="file"
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          onChange={(event) => {
                            handleSingleIconFile(event.currentTarget.files[0]);
                            setFieldValue({ file }, files);
                          }}
                        />
                      </div>

                      {fileIconError || uploadError ? (
                        <div className="material-form__error mt-1">
                          {fileIconError || uploadError}
                        </div>
                      ) : (
                        <ErrorMessage
                          render={(msg) => (
                            <div className="material-form__error mt-1">
                              {msg}
                            </div>
                          )}
                          name={file}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default CategoriesForm;
