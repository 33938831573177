import moment from "moment";
import React from "react";
import SearchFilter from "../../../components/filters/search-filter";
import DataTable from "../../../components/table";
import AddMetaData from "./add-data";
import { columns } from "./constant";
import DeleteMetaData from "./delete-data";
import EditMetaData from "./edit-data";
import { useMetaData } from "./hooks";

const MetaData = (props) => {
  const { count, filters, metaData, handleSearch, isLoading } =
    useMetaData(props);
  const configureTableData = () => {
    try {
      let results = metaData.map((data) => {
        let newData = Object.assign({}, data);
        newData["page_url_path"] =
          data?.page_url_path === "/" ? (
            <a
              className="meta_link"
              href={process.env.REACT_APP_ORIGIN}
              target="_blank"
              rel="noopener noreferrer"
            >
              {process.env.REACT_APP_ORIGIN}
            </a>
          ) : (
            <a
              className="meta_link"
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_ORIGIN}${data?.page_url_path}`}
            >{`${process.env.REACT_APP_ORIGIN}${data?.page_url_path}`}</a>
          );
        newData["created_at"] = moment(data.created_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );
        newData["updated_at"] = moment(data.created_at).format(
          "DD/MM/YYYY @ hh:mm A"
        );
        newData["action"] = (
          <div className="label__container">
            <EditMetaData metaData={data} />
            <DeleteMetaData metaData={data} />
          </div>
        );
        return newData;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const metaDataFilters = () => (
    <>
      <SearchFilter
        filterKey="text"
        filterTitle="Page URL"
        searchedValue={filters?.text}
        searchPlaceholder="Page URL"
        handleSearch={handleSearch}
      />
    </>
  );

  return (
    <DataTable
      title="Meta Data"
      addBtn={<AddMetaData />}
      CreateTableData={configureTableData}
      withFilter={true}
      filter={metaDataFilters}
      handleSetFilter={handleSearch}
      count={count}
      skip={filters?.skip}
      isLoading={isLoading}
    />
  );
};

export default MetaData;
