import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPage, getPageList, getSectionList } from "../actions";
import { getCommonFilter, getPageFilter } from "../selectors";

export const useAddPage = () => {
  const dispatch = useDispatch();
  const [addPageLoading, setAddPageLoading] = useState(false);
  const [sectionData, setSections] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const filters = useSelector(getPageFilter)
  const commom_filters = useSelector(getCommonFilter);


  const handleAddClick = async () => {
    setAddPageLoading(true);
    const { sections } = await dispatch(getSectionList(commom_filters));
    if (sections) {
      setSections(sections)
      toggleModal()
    }
    setAddPageLoading(false);
  }

  const handleAddPage = async (values) => {
    try {
      let finalSectionList = values.sectionList.map(function (item, index) {
        const obj = Object.assign({}, { 'id': item.id.id, 'limit': parseInt(item.limit), 'position': item.position });
        return obj;
      });
      setAddPageLoading(true);
      const payload = {
        page: {
          name: values.name,
          description: values.description,
        },
        sections: finalSectionList,
      };
      setAddPageLoading(false);
      const is_success = await dispatch(addPage(payload));
      setAddPageLoading(false);
      if (is_success) {
        dispatch(getPageList(filters))
        toggleModal()
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    addPageLoading,
    handleAddPage,
    sectionData,
    handleAddClick,
    toggleModal,
    isOpenModal,
  };
};
