import React from "react";
import { Route, Switch } from "react-router-dom";

import Interests from "../../../Interests";

export default () => (
  <Switch>
    <Route path="/interests" component={Interests} />
  </Switch>
);
