import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, Chip, MenuItem, Checkbox } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { userFormSchema } from "./validation-schema";
import {
  userRoles,
  fieldsName,
  userTitles,
  userGenders,
  userAge,
} from "../constant";
import { DataLoader } from "../../../components/loader";
import { useUploadFiles } from "../../../utils/upload-file/hooks";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";
import { useUser } from "./../../../common-hooks/user";
import TextfieldChips from "../../../components/text-field-chips";
import DatePicker from "../../../components/date-picker";
import {
  fileContext,
  imageDimensions,
  initialLanguageArrayValue,
  initialLanguageFieldValue,
  userRoles as roles,
} from "../../../constants";
import ProgressChip from "../../../components/progress-chip";
import Tooltip from "@material-ui/core/Tooltip";
import { languages } from "../../../constants";
import { setLanguage } from "../../../utils/auth";
import Croppier from "../../../components/image-croppier";
import moment from "moment";

const formField = {
  mobile_number: "",
  name: "",
  email: "",
  role: "",
  is_active: "",
  about_me: initialLanguageFieldValue,
  short_about_me: initialLanguageFieldValue,
  title: "",
  designation: initialLanguageFieldValue,
  specialities: initialLanguageArrayValue,
  highlights: initialLanguageArrayValue,
  date_of_birth: "",
  gender: "",
  address: "",
  pincode: "",
  emergency_mobile_number: "",
  language: "en",
  age_group: "",
};

const UserForm = ({
  fieldValues,
  handleUserForm,
  btn,
  isLoading,
  getUserData,
}) => {
  const {
    handleProfilePictureFile,
    uploadError,
    fileProfilePictureError,
    files,
    handleProfileBannerFile,
    fileProfileBannerError,
    handleDeleteAFile,
    isFileUploadData,
    uploadProgress,
    handleCropping,
    handleIntroVideoFile,
  } = useUploadFiles();

  const { isAdmin } = useUser();

  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    mobile_number,
    name,
    email,
    role,
    is_active,
    about_me,
    profilePic,
    profileBanner,
    title,
    specialities,
    date_of_birth,
    age_group,
    designation,
    gender,
    highlights,
    address,
    pincode,
    emergency_mobile_number,
    tag,
    is_popular,
    language,
    short_about_me,
    profileVideo,
    deactivation,
    is_blacklisted,
    is_deleted,
  } = fieldsName;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const [coordinatorBanner, setCoordinatorBanner] = useState([]);
  const { coordinator_banner } = imageDimensions;
  const { CO_ORDINATOR } = roles;

  const handleFormUpdate = (event) => {
    let { name, value } = event.target;
    if (name === is_active || name === is_popular || name === is_blacklisted || name === is_deleted) {
      value = event.target.checked;
    }
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  const handleFormArrayUpdate = async (key, value) => {
    if (key === date_of_birth) {
      let ageGroup = "";
      const currentDate = moment();
      const birthYear = moment(value, "YYYY");
      const age = currentDate.diff(birthYear, "years");

      if (age >= 51 && age <= 65) {
        ageGroup = "51-65";
      } else if (age >= 66 && age <= 80) {
        ageGroup = "66-80";
      } else if (age >= 81) {
        ageGroup = "80+";
      } else {
        ageGroup = "Other";
      }
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [key]: value,
        age_group: ageGroup,
      }));
    } else
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [key]: value,
      }));
  };

  const handleLanguge = (key, language, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: {
        ...prevFieldValue[key],
        [language]: value,
      },
    }));
  };

  return (
    <Formik
      validationSchema={userFormSchema}
      validateOnMount
      enableReinitialize
      initialValues={{
        mobile_number: formValues?.mobile_number || "",
        name: formValues?.name || "",
        email: formValues?.email || "",
        role: formValues?.role || "",
        is_active: formValues?.is_active ? true : false,
        short_about_me: formValues?.short_about_me || initialLanguageFieldValue,
        about_me: formValues?.about_me || initialLanguageFieldValue,
        title: formValues?.title || "",
        specialities: formValues?.specialities || initialLanguageArrayValue,
        address: formValues?.address || "",
        date_of_birth:
          (formValues?.date_of_birth && new Date(formValues.date_of_birth)) ||
          "",
        age_group: formValues?.age_group || "",
        designation: formValues?.designation || initialLanguageFieldValue,
        highlights: formValues?.highlights || initialLanguageArrayValue,
        gender: formValues?.gender || "",
        pincode: formValues?.pincode || "",
        is_popular: formValues?.is_popular ? true : false,
        tag: formValues?.tag || "",
        profilePic: files.filter(
          (file) => file.file_context === fileContext.PROFILE_PIC
        ),
        emergency_mobile_number: formValues?.emergency_mobile_number || "",
        language: formValues?.language || "en",
        profileBanner: files.filter(
          (file) => file.file_context === fileContext.PROFILE_BANNER
        ),
        profileVideo: files.filter(
          (file) => file.file_context === fileContext.PROFILE_VIDEO
        ),
        is_blacklisted: formValues?.is_blacklisted || false,
        is_deleted: formValues?.is_deleted || false,
      }}
      onSubmit={handleUserForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            {!isLoading && (
              <div className="materila-form_inline-child sticky__element">
                <TextField
                  className="material-form__field"
                  name={language}
                  placeholder="Language"
                  label="Language"
                  select
                  value={rest.values.language}
                  onChange={(event) => {
                    const { value } = event.target;
                    handleFormValueChange(rest, language);
                    handleFormUpdate(event);
                    if (!rest.values.about_me[value]) {
                      handleLanguge(about_me, value, "");
                    }
                    if (!rest.values.designation[value]) {
                      handleLanguge(designation, value, "");
                    }
                    if (!rest.values.specialities[value]) {
                      handleLanguge(specialities, value, []);
                    }
                    if (!rest.values.highlights[value]) {
                      handleLanguge(highlights, value, []);
                    }
                    if (!rest.values.short_about_me[value]) {
                      handleLanguge(short_about_me, value, []);
                    }
                    if (btn === "Save") {
                      setLanguage(value);
                      getUserData(rest, setFormvalues);
                    }
                  }}
                  onFocus={() => handleFocusField(language)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.language}
                >
                  {languages.map((language) => (
                    <MenuItem
                      key={language.id}
                      className="material-form__option"
                      value={language.value}
                    >
                      {language.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === language ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={language}
                  />
                )}
              </div>
            )}

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  select
                  value={rest.values.title}
                  onChange={(event) => {
                    handleFormValueChange(rest, title);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.title}
                >
                  {userTitles.map((title) => (
                    <MenuItem
                      key={title.id}
                      className="material-form__option"
                      value={title.value}
                    >
                      {title.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={name}
                  placeholder="Name"
                  label="Name"
                  type="text"
                  value={rest.values.name}
                  onChange={(event) => {
                    handleFormValueChange(rest, name);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(name)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === name ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={name}
                  />
                )}
              </div>
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={mobile_number}
                  placeholder="Mobile Number"
                  label="Mobile Number"
                  type="text"
                  value={rest.values.mobile_number}
                  disabled={
                    isAdmin ? false : fieldValues?.mobile_number ? true : false
                  }
                  onChange={(event) => {
                    handleFormValueChange(rest, mobile_number);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(mobile_number)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === mobile_number ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={mobile_number}
                  />
                )}
              </div>
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={email}
                  placeholder="Email"
                  label="Email"
                  type="text"
                  value={rest.values.email}
                  onChange={(event) => {
                    handleFormValueChange(rest, email);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(email)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === email ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={email}
                  />
                )}
              </div>
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={role}
                  placeholder="Role"
                  label="Role"
                  select
                  value={rest.values.role}
                  onChange={(event) => {
                    handleFormValueChange(rest, role);
                    handleFormUpdate(event);
                    if (event.target.value === CO_ORDINATOR) {
                      files
                        .filter(
                          (file) =>
                            file.file_context === fileContext.PROFILE_BANNER
                        )
                        .map((f) => handleDeleteAFile(f));
                    }
                  }}
                  onFocus={() => handleFocusField(role)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.role}
                >
                  {userRoles.map((role) => (
                    <MenuItem
                      key={role.id}
                      className="material-form__option"
                      value={role.value}
                    >
                      {role.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === role ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={role}
                  />
                )}
              </div>

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={gender}
                  placeholder="Gender"
                  label="Gender"
                  select
                  value={rest.values.gender}
                  onChange={(event) => {
                    handleFormValueChange(rest, gender);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(gender)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.gender}
                >
                  {userGenders.map((gender) => (
                    <MenuItem
                      key={gender.id}
                      className="material-form__option"
                      value={gender.value}
                    >
                      {gender.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>
            </div>

            {rest.values.role !== "Member" && (
              <div className="material-form_inline-fields d-flex align-items-center">
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={designation}
                    placeholder="Designation"
                    label="Designation"
                    type="text"
                    value={rest.values.designation[rest.values.language]}
                    onChange={(event) => {
                      handleFormValueChange(rest, designation);
                      handleLanguge(
                        "designation",
                        rest.values.language,
                        event.target.value
                      );
                    }}
                    onFocus={() => handleFocusField(designation)}
                    onBlur={() => handleBlurField()}
                  />
                  {focusField === designation ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={designation}
                    />
                  )}
                </div>

                <div className="col-md-2 form__language">
                  <Tooltip title="Language" placement="bottom">
                    <div>
                      {
                        languages.find(
                          (lang) => lang.value === rest.values.language
                        ).displayText
                      }
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={about_me}
                  placeholder="About Me"
                  label="About Me"
                  type="text"
                  multiline
                  value={rest.values.about_me[rest.values.language]}
                  onChange={(event) => {
                    handleFormValueChange(rest, about_me);
                    handleLanguge(
                      "about_me",
                      rest.values.language,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(about_me)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === about_me ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={about_me}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={short_about_me}
                  placeholder="Short About Me"
                  label="Short About Me"
                  type="text"
                  inputProps={{
                    maxLength: 100,
                  }}
                  helperText={`${rest.values.short_about_me[rest.values.language].length
                    }/${100} characters`}
                  value={rest.values.short_about_me[rest.values.language]}
                  onChange={(event) => {
                    handleFormValueChange(rest, short_about_me);
                    handleLanguge(
                      short_about_me,
                      rest.values.language,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(short_about_me)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === short_about_me ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={short_about_me}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            {rest.values.role !== "Member" && (
              <div className="material-form_inline-fields d-flex">
                <div className="materila-form_inline-child">
                  <TextfieldChips
                    initialChips={
                      rest.values.specialities[rest.values.language]
                    }
                    name="Specialities"
                    onDataChange={(data) => {
                      setFieldValue(specialities, data);
                      handleLanguge("specialities", rest.values.language, data);
                    }}
                  />
                  {focusField === specialities ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={specialities}
                    />
                  )}
                </div>

                <div className="col-md-2 mt-4 form__language">
                  <Tooltip title="Language" placement="bottom">
                    <div>
                      {
                        languages.find(
                          (lang) => lang.value === rest.values.language
                        ).displayText
                      }
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}

            <div className="material-form_inline-fields d-flex">
              <div className="materila-form_inline-child">
                <TextfieldChips
                  initialChips={rest.values.highlights[rest.values.language]}
                  name="Highlights"
                  onDataChange={(data) => {
                    setFieldValue(highlights, data);
                    handleLanguge("highlights", rest.values.language, data);
                  }}
                />
                {focusField === highlights ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={highlights}
                  />
                )}
              </div>

              <div className="col-md-2 mt-4 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <DatePicker
                  selectedDate={rest.values.date_of_birth}
                  handleChange={(value) => {
                    setFieldValue(date_of_birth, value);
                    handleFormArrayUpdate(date_of_birth, value);
                  }}
                  id="date_of_birth"
                  name={date_of_birth}
                  label="Date of Birth"
                  customInput={
                    <TextField
                      className="material-form__field"
                      id="date_of_birth"
                      label="Date of Birth"
                      autoComplete="off"
                    />
                  }
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={date_of_birth}
                />
              </div>
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={age_group}
                  placeholder="Age Group"
                  label="Age Group"
                  select
                  value={rest.values.age_group}
                  onChange={(event) => {
                    handleFormValueChange(rest, age_group);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(age_group)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.age_group}
                >
                  {userAge.map((age_group) => (
                    <MenuItem
                      key={age_group.id}
                      className="material-form__option"
                      value={age_group.value}
                    >
                      {age_group.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={age_group}
                  />
                )}
              </div>
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={emergency_mobile_number}
                placeholder="Emegency Mobile Number"
                label="Emergency Mobile Number"
                type="text"
                value={rest.values.emergency_mobile_number}
                disabled={
                  isAdmin
                    ? false
                    : fieldValues?.emergency_mobile_number
                      ? true
                      : false
                }
                onChange={(event) => {
                  handleFormValueChange(rest, emergency_mobile_number);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(emergency_mobile_number)}
                onBlur={() => handleBlurField()}
              />
              {focusField === emergency_mobile_number ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={emergency_mobile_number}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={address}
                placeholder="Address"
                label="Address"
                type="text"
                multiline
                value={rest.values.address}
                onChange={(event) => {
                  handleFormValueChange(rest, address);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(address)}
                onBlur={() => handleBlurField()}
              />
              {focusField === address ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={address}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={pincode}
                placeholder="Pincode"
                label="Pincode"
                type="text"
                multiline
                value={rest.values.pincode}
                onChange={(event) => {
                  handleFormValueChange(rest, pincode);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(pincode)}
                onBlur={() => handleBlurField()}
              />
              {focusField === pincode ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={pincode}
                />
              )}
            </div>

            {rest.values.role === CO_ORDINATOR && (
              <div>
                <TextField
                  className="material-form__field"
                  name={tag}
                  placeholder="Tag"
                  label="Tag"
                  type="text"
                  value={rest.values.tag}
                  onChange={(event) => {
                    handleFormValueChange(rest, tag);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(tag)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === tag ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={tag}
                  />
                )}
              </div>
            )}

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rest.values.is_blacklisted}
                    onChange={(event) => {
                      handleFormValueChange(rest, is_blacklisted);
                      handleFormUpdate(event);
                    }}
                    name={is_blacklisted}
                    color="default"
                  />
                }
                label="Blacklist"
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rest.values.is_active}
                    onChange={(event) => {
                      handleFormValueChange(rest, is_active);
                      handleFormUpdate(event);
                    }}
                    name={is_active}
                    color="default"
                  />
                }
                label="Active"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rest.values.is_deleted}
                    onChange={(event) => {
                      handleFormValueChange(rest, is_deleted);
                      handleFormUpdate(event);
                    }}
                    name={is_deleted}
                    color="default"
                  />
                }
                label="Delete"
              />
            </div>

            {rest.values.role === CO_ORDINATOR && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rest.values.is_popular}
                      onChange={(event) => {
                        handleFormValueChange(rest, is_popular);
                        handleFormUpdate(event);
                      }}
                      name={is_popular}
                      color="default"
                    />
                  }
                  label="Is Popular"
                />
              </div>
            )}

            <div className="form__form-group-field mb-3">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="profilePic">Profile Picture</label>
                  <span>
                    {files
                      .filter(
                        (file) => file.file_context === fileContext.PROFILE_PIC
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}

                    {isFileUploadData
                      .filter(
                        (file) => file.fileContext === fileContext.PROFILE_PIC
                      )
                      .map((x, i) => {
                        return (
                          <>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </>
                        );
                      })}
                  </span>
                  <input
                    accept="image/*"
                    onBlur={rest.handleBlur}
                    type="file"
                    name={profilePic}
                    id="profilePic"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleProfilePictureFile(event.currentTarget.files);
                      setFieldValue(
                        profilePic,
                        files.filter(
                          (file) =>
                            file.file_context === fileContext.PROFILE_PIC
                        )
                      );
                    }}
                  />
                </div>
                {fileProfilePictureError || uploadError ? (
                  <div className="material-form__error">
                    {fileProfilePictureError || uploadError}
                  </div>
                ) : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={profilePic}
                  />
                )}
              </div>
            </div>

            <div className="form__form-group-field mt-4 form__margin-bottom">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="profileBanner">Profile Banner</label>
                  {rest.values.role === CO_ORDINATOR && (
                    <>
                      <Tooltip
                        title="Preferred size is 408px * 249px"
                        placement="bottom"
                      >
                        <i className="fa fa-info-circle icon-file-info mt-1"></i>
                      </Tooltip>
                      <br />
                    </>
                  )}
                  <span>
                    {files
                      .filter(
                        (file) =>
                          file.file_context === fileContext.PROFILE_BANNER
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}

                    {isFileUploadData
                      .filter(
                        (file) =>
                          file.fileContext === fileContext.PROFILE_BANNER
                      )
                      .map((x, i) => {
                        return (
                          <>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </>
                        );
                      })}
                  </span>
                  <input
                    accept="image/*"
                    onBlur={rest.handleBlur}
                    type="file"
                    name={profileBanner}
                    id="profileBanner"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      if (rest.values.role === CO_ORDINATOR) {
                        handleCropping(
                          event.currentTarget.files,
                          setFieldValue,
                          profileBanner,
                          fileContext.PROFILE_BANNER,
                          handleProfileBannerFile,
                          coordinator_banner,
                          setCoordinatorBanner
                        );
                      } else {
                        handleProfileBannerFile(event.currentTarget.files);
                        setFieldValue(
                          profileBanner,
                          files.filter(
                            (file) =>
                              file.file_context === fileContext.PROFILE_BANNER
                          )
                        );
                      }
                    }}
                  />
                </div>
                {rest.errors.profileBanner ? (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={profileBanner}
                  />
                ) : (
                  <div className="material-form__error">
                    {fileProfileBannerError || uploadError}
                  </div>
                )}
              </div>
            </div>

            {rest.values.role === CO_ORDINATOR &&
              coordinatorBanner?.length > 0 &&
              coordinatorBanner?.map((banner, i) => (
                <div key={i}>
                  <Croppier
                    image={banner}
                    setFieldValue={setFieldValue}
                    name={profileBanner}
                    context={fileContext.PROFILE_BANNER}
                    dimensions={coordinator_banner}
                    handleUploadFile={handleProfileBannerFile}
                    setCurrentBanner={setCoordinatorBanner}
                    index={i}
                  />
                </div>
              ))}

            <div className="form__form-group-field mt-5 form__margin-bottom">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="profileVideo">Profile Video</label>
                  <span>
                    {files
                      .filter(
                        (file) =>
                          file.file_context === fileContext.PROFILE_VIDEO
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}

                    {isFileUploadData
                      .filter(
                        (file) => file.fileContext === fileContext.PROFILE_VIDEO
                      )
                      .map((x, i) => {
                        return (
                          <>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </>
                        );
                      })}
                  </span>
                  <input
                    accept="video/*"
                    onBlur={rest.handleBlur}
                    type="file"
                    name={profileVideo}
                    id="profileVideo"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleIntroVideoFile(event.currentTarget.files[0]);
                      setFieldValue(
                        profileVideo,
                        files.filter(
                          (file) =>
                            file.file_context === fileContext.PROFILE_VIDEO
                        )
                      );
                    }}
                  />
                </div>
                {rest.errors.profileVideo && (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={profileVideo}
                  />
                )}
              </div>
            </div>

            <button
              disabled={isFileUploadData.length}
              type="submit"
              className="btn btn-success btn-right btn-sm mt-4"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default UserForm;
