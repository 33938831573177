import { api, apiEndPoints } from "../../../api";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";

const namespace = "containers/subcategories";

export const SET_SUBCATEGORY_LIST = `${namespace}/SET_SUBCATEGORY_LIST`;
export const SET_SUBCATEGORY_FILTER = `${namespace}/SET_SUBCATEGORY_FILTER`;
export const RESET_SUBCATEGORY_FILTER = `${namespace}/RESET_SUBCATEGORY_FILTER`;

const setSubcategoryList = (payload) => ({
  type: SET_SUBCATEGORY_LIST,
  payload,
});
export const setSubcategoryFilter = (payload) => ({
  type: SET_SUBCATEGORY_FILTER,
  payload,
});
export const resetSubcategoryFilter = () => ({
  type: RESET_SUBCATEGORY_FILTER,
});

export const getSubategoryList = (category_id, params) => async (dispatch) => {
  params.category_id = category_id;
  try {
    const response = await api.get(apiEndPoints.getSubategories, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setSubcategoryList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getSubategory = (id) => async () => {
  try {
    const response = await api.get(apiEndPoints.getSubcategory(id));
    const { data: { is_success, sub_category } = {} } = response;
    if (is_success) {
      return { is_success, sub_category };
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addSubategory = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createSubcategory, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Subcategory not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const editSubategory = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateSubcategory(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Subctegory not edited, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteSubategory = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteSubcategories(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};
