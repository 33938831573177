import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLabel, getLabelList } from "../actions";
import { getLabelFilter } from "../selectors";

export const useAddLabel = () => {
  const dispatch = useDispatch();
  const [AddLabelLoading, setAddLabelLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const filters = useSelector(getLabelFilter)
  
  const handleAddLabel = async (values) => {
    try {
      setAddLabelLoading(true);
      const payload = {
        title: values.title,
        bg_color_code: values.bg_color_code,
        text_color_code: values.text_color_code,
      };
      setAddLabelLoading(false);
      const is_success = await dispatch(addLabel(payload));
      setAddLabelLoading(false);
      if (is_success) {
        dispatch(getLabelList(filters))
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    AddLabelLoading,
    handleAddLabel,
    toggleModal,
    isOpenModal
  };
};
