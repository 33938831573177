import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePolicy, getPoliciesList } from "../actions";
import { getPoliciesFilter } from "../selectors";

export const useDeletePolicy = ({ id }) => {
  const dispatch = useDispatch();
  const filters = useSelector(getPoliciesFilter);

  const [deletePolicyLoading, setDeletePolicyLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleDeletePolicy = async () => {
    setDeletePolicyLoading(true);
    const is_success = await dispatch(deletePolicy(id));
    setDeletePolicyLoading(false);
    if (is_success) {
      dispatch(getPoliciesList(filters));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deletePolicyLoading,
    handleDeletePolicy,
    isOpenModal,
    toggleModal,
  };
};
