import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteSectionData, getSectionList } from "../actions";
import { getSectionFilter } from "../selectors";

export const useDeleteLabel = (id) => {
  const dispatch = useDispatch();
  const filter = useSelector(getSectionFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteSectionLoading, setDeleteSectionLoading] = useState(false);

  const handleDeleteSection = async () => {
    setDeleteSectionLoading(true);
    const is_success = await dispatch(deleteSectionData(id));
    setDeleteSectionLoading(false);
    if (is_success) {
      dispatch(getSectionList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteSectionLoading,
    handleDeleteSection,
    isOpenModal,
    toggleModal,
  };
};
