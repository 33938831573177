import React from "react";
import { Route, Switch } from "react-router-dom";
import Vouchers from "../../../vouchers";
import Codes from "../../../vouchers/codes";

export default () => (
  <Switch>
    <Route path="/vouchers" component={Vouchers} />
    <Route path="/voucher/:id/codes" component={Codes} />
  </Switch>
);
