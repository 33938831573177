/* eslint-disable */

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCommentDetails, getReactions } from "../../action";
import { REACTION_CODE } from "../../constant";

export const useReactionsModal = () => {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    setVisible(!visible);
  };

  return { visible, toggleVisible };
};

export const useReactions = (props) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [statsDetail, setStatsDetail] = useState([]);
  const [reactions, setReactions] = useState({});
  const [activeTab, setActiveTab] = useState(REACTION_CODE.all);
  const [count, setCount] = useState(0);
  const [reactionParams, setReactionParams] = useState({
    trackable_type: props?.trackableType,
    trackable_id: props.id,
    name: "",
    limit: 10,
    skip: 0,
  });

  useEffect(() => {
    (async () => {
      const reactions = await dispatch(getReactions(reactionParams));
      if (reactions?.is_success) {
        setReactions((prev) => {
          if (prev?.is_success && reactionParams?.skip !== 0) {
            return {
              ...prev,
              reactions: [...prev?.reactions, ...reactions?.reactions],
            };
          } else {
            return reactions;
          }
        });
        setStatsDetail(reactions?.stats);
        let count = 0;
        reactions?.stats?.forEach((reaction) => {
          count += parseInt(reaction.count);
        });

        setCount(count);
      }
    })();
  }, [reactionParams, dispatch]);

  const handleReactionParams = () => {
    setReactionParams((prev) => ({
      ...prev,
      skip: prev?.skip + 10,
    }));
  };

  const toggleVisible = () => {
    setVisible(!visible);
  };

  const handleTabChange = (tabValue) => {
    setActiveTab(tabValue);
    setReactionParams((prev) => ({
      ...prev,
      skip: 0,
      name: tabValue,
    }));
  };

  return {
    visible,
    toggleVisible,
    reactions,
    activeTab,
    handleTabChange,
    statsDetail,
    handleReactionParams,
    count,
  };
};
