export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Name",
    accessor: "name",
    disableGlobalFilter: true,
  },
  {
    Header: "Description",
    accessor: "description",
    disableGlobalFilter: true,
    // width: 60,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 30,
  },
];

export const fieldsName = {
  name: "name",
  description: "description",
  sectionList: "sectionList",
  id: "id",
  limit: "limit",
};

export const nameOptions = [
  {
    id: 1,
    value: "Detail",
    displayText: "Detail",
  },
  {
    id: 2,
    value: "Filter",
    displayText: "Filter",
  },
  {
    id: 3,
    value: "Home",
    displayText: "Home",
  },
  {
    id: 4,
    value: "Landing",
    displayText: "Landing",
  },
  {
    id: 5,
    value: "Listing",
    displayText: "Listing",
  },
  {
    id: 6,
    value: "Other",
    displayText: "Other",
  },
];
