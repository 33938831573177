import React from "react";
import { Button } from "reactstrap";
import { useAddVideo } from "./hooks";
import Modals from "../../../../../components/modal";
import VideoForm from "../form/video-form";

const AddVideo = ({ categories, users, setUserFilters }) => {
  const { addVideoLoading, handleAddVideo, toggleModal, isOpenModal } =
    useAddVideo();

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          toggleModal();
        }}
      >
        <i className="fa fa-plus"></i> Add Video
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <VideoForm
              handleVideoForm={handleAddVideo}
              btn="Add"
              isLoading={addVideoLoading}
              categories={categories}
              users={users}
              setUserFilters={setUserFilters}
            />
          }
          title="Add Video"
        />
      ) : null}
    </div>
  );
};

export default AddVideo;
