import React from "react";
import { useDeletePost } from "./hooks";
import Modals from "../../../../components/modal";
import DeleteConfirmation from "../../../../components/delete-confirmation";
import { useUser } from "../../../../common-hooks/user";
import ActionButton from "../../../../components/action-button";
const DeletePost = ({ post }) => {
  const { handleDeletePost, isOpenModal, toggleModal, deletePostLoading } =
    useDeletePost(post);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div className="ml-3">
      <ActionButton
        title="Delete"
        iconClassName={`fa fa-trash icon-btn-action text-danger `}
        onClick={toggleModal}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeletePost}
              isLoading={deletePostLoading}
            />
          }
          title="Delete Post"
        />
      ) : null}
    </div>
  );
};

export default DeletePost;
