import { api, apiEndPoints } from "../../../api";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";

const namespace = "containers/report-options";

export const SET_REPORT_OPTIONS_LIST = `${namespace}/SET_REPORT_OPTIONS_LIST`;
export const SET_REPORT_OPTIONS_FILTER = `${namespace}/SET_REPORT_OPTIONS_FILTER`;
export const CLEAR_REPORT_OPTIONS_FILTER = `${namespace}/CLEAR_REPORT_OPTIONS_FILTER`;

export const setReportOptionsList = (payload) => ({
  type: SET_REPORT_OPTIONS_LIST,
  payload,
});

export const setReportOptionsFilter = (payload) => ({
  type: SET_REPORT_OPTIONS_FILTER,
  payload,
});

export const clearReportOptionsFilter = (payload) => ({
  type: CLEAR_REPORT_OPTIONS_FILTER,
  payload,
});

export const getReportOptionsList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getReportsOptions, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setReportOptionsList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getReportOption = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.singleReportOption(id));
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addReportOption = (reason) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addReportOption, reason);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const editReportOption = (reason, id) => async (dispatch) => {
  try {
    const response = await api.patch(apiEndPoints.singleReportOption(id), reason);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteReportOption = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.singleReportOption(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(showSnackbar({ message: message, type: snackbarTypes.SUCCESS }));
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};
