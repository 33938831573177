import moment from "moment";
import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import Accordion from "../../../../components/Accordion";

const UserPlan = ({ plan }) => (
  <Col md={12} lg={12} xl={12}>
    <Accordion
      className="user__tabs_collapse"
      title={"plan Opted"}
      titleClassName="profile__current-tasks-title"
    >
      <Card>
        <CardBody className="profile__card">
          <div className="profile__current-task">
            <div className="col-7 user_details_padLeft0">Type</div>
            <div className="col-5 user_details_pad0">{plan?.type || "NA"}</div>
          </div>
          <div className="profile__current-task">
            <div className="col-7 user_details_padLeft0">Title</div>
            <div className="col-5 user_details_pad0">{plan?.title || "NA"}</div>
          </div>

          {plan?.is_expired ? (
            <div className="profile__current-task">
              <div className="col-7 user_details_padLeft0"></div>
              <div className="col-5 user_details_pad0 bold-text">
                PLAN EXPIRED
              </div>
            </div>
          ) : (
            <>
              <div className="profile__current-task">
                <div className="col-7 user_details_padLeft0">Subscribed On</div>
                <div className="col-5 user_details_pad0">
                  {plan?.subscribed_at
                    ? moment(new Date(plan?.subscribed_at)).format("DD/MM/YYYY")
                    : "NA"}
                </div>
              </div>

              <div className="profile__current-task">
                <div className="col-7 user_details_padLeft0">Valid Till</div>
                <div className="col-5 user_details_pad0">
                  {plan?.ends_at
                    ? moment(new Date(plan?.ends_at)).format("DD/MM/YYYY")
                    : "NA"}
                </div>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </Accordion>
  </Col>
);

export default UserPlan;
