export const colors = {
  brand: "#06B178",
  black: "#191919",
  darkBlack: "#000",
  gray: "#BDBDBD",
  grayDark: "#7D7D7D",
  grayDark3: "#AAAAAA",
  grayDark4: "#DDDDDD",
  grayLight: "#9D9D9D",
  gray2: "#555555",
  lightBlack3: "#444444",

  lightBlack: "#4F4F4F",
  lightBlack2: "#000000",
  lightBlack4: "#333333",
  black2: "#011935",
  black3: "#333333",
  smokeBlack: "#7b7e81",

  lightgray: "#828282",
  lightgray3: "#777777",
  lightgreen: "#06B178",
  lightgray4: "#666666",
  lightgray5: "#aaaaaa",
  lightgray6: "#EEEEEE",

  lightRed: "#D95C5C",
  lightRed2: "#FFD9D9",
  lightRed3: "#FD7070",
  lightRed4: "#FF3535",

  lightGreen2: "#31A13C",
  lightGreen4: "#0CB57C",
  lightGreen5: "#09BC80",

  white: "#FFFFFF",
  whiteIce: "#D9FFED",
  whiteSnow: "#FCFCFC",
  whiteSmoke: "#F4F4F4",
  whiteSmoke2: "#F7F7F7",
  lightWhite: "#828282",
  white1: "#FEFEFE",

  blue: "#538BFC",
  lightblue2: "#D9EDFF",
  gray8: "#999999",

  voilet: "#917AEB",
  voilet2: "#967ADC",
  orange: "#E66625",
  pink: "#D273B7",
  purple: "#8e75ee",
  yellow: "#F7B500",
  yellow2: "#eab361",
  red: "#ff4861",
  red2: "#FF3535",
  green: "#b8e986",
  icon: "#605f7b",
  grayIntenseDark: "#616161",
  redError: "#EB5757",
  lightgray2: "#E0E0E0",
  grey2: "#868686",
  grey3: "#838282",
  grey4: "#cccccc",
  grayDark2: "#4F4F4F",
  gray4: "#E0E0E0",
  gray5: "#979797",
  gray6: "#827E7E",
  gray7: "#999999",
  grey7: "#DADADA",
  blacknew: "#303030",
  lightred: "#d95c5c",
  grey: "#C4C4C4",
  blackdark: "#1d1d1d",
  primarygreen: "#06B178",
};
