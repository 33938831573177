import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import {
  getBlogList,
  setBlogFilter,
  deleteBlogData,
  clearBlogFilter,
  downloadBlogs,
} from "./actions";
import { getBlogs, getBlogsCount, getBlogFilter } from "./selectors";
import { clearLanguage } from "../../../../utils/auth";

export const useBlogs = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const blogs = useSelector(getBlogs);
  const filters = useSelector(getBlogFilter);
  const blogCount = useSelector(getBlogsCount);
  const [loadingBlogs, setLoadingBlogs] = useState(false);

  useEffect(() => {
    dispatch(clearBlogFilter());
  }, [dispatch]);

  useEffect(() => {
    clearLanguage();
    const fetchBlogs = async () => {
      setLoadingBlogs(true);
      await dispatch(getBlogList(filters));
      setLoadingBlogs(false);
    };
    fetchBlogs();
  }, [filters, dispatch]);

  const deleteBlog = async (blog_id) => {
    setLoadingBlogs(true);
    await dispatch(deleteBlogData(blog_id));
    setLoadingBlogs(false);
  };

  const handleEditClick = (blog_id) => {
    history.push({ pathname: `/edit-blog/${blog_id}` });
  };

  const handleAddClick = () => {
    history.push("/add-blog");
  };

  const setFilter = (key, value) => {
    dispatch(setBlogFilter({ key, value }));
  };
  const resetFilter = () => {
    dispatch(clearBlogFilter());
  };

  const downloadBlogReport = async () => {
    const exportData = await dispatch(downloadBlogs(filters));
    if (exportData) {
      var blob = new Blob([exportData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `Blogs.xlsx`);
    }
  };

  const handleCommentClick = () => {
    history.push({ pathname: `/comments` });
  };

  return {
    setFilter,
    loadingBlogs,
    filters,
    blogs,
    blogCount,
    deleteBlog,
    handleEditClick,
    handleAddClick,
    downloadBlogReport,
    handleCommentClick,
    resetFilter,
  };
};
