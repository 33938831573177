import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  fieldsName,
  trackable_type,
  bookingStatus,
  noticeStatus,
  scheduleEventStatus,
  landingPageOptions,
  limit,
} from "../constant";
import { DataLoader } from "../../../components/loader";
import { noticeSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";
import { useNoticeForm } from "./hooks";
import * as moment from "moment";
import DatePicker from "../../../components/date-picker";

const formField = {
  type: "",
  redirect_to: "",
  bookingStatus: [],
  title: "",
  description: "",
  status: "",
  event_id: "",
  event_status: "Published",
  event_date: new Date(),
  short_description: "",
  blog_id: "",
};

const NoticeForm = ({
  fieldValues,
  handlenoticeForm,
  btn,
  isLoading,
  isEdit,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    title,
    description,
    booking_status,
    status,
    type,
    event_id,
    event_date,
    event_status,
    redirect_to,
    short_description,
    blog_id,
  } = fieldsName;
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const { setFilter, events, blogs } = useNoticeForm();

  const handleFormUpdate = (event) => {
    const { name, value } = event.target;
    if (value === "General") {
      setFormvalues((prevFieldValue) => {
        return {
          ...prevFieldValue,
          event_id: "",
          booking_status: [],
          redirect_to: "",
          [name]: value,
        };
      });
    } else if (value === "Event Schedule") {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        redirect_to: "EventDetail",
        [name]: value,
      }));
    } else {
      setFormvalues((prevFieldValue) => {
        return {
          ...prevFieldValue,
          [name]: value,
        };
      });
    }
  };

  const handleStatusUpdate = (name, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  const { description_limit, short_description_limit, title_limit } = limit;

  return (
    <Formik
      validateOnMount
      enableReinitialize={true}
      validationSchema={noticeSchema}
      initialValues={{
        title: formValues?.title || "",
        redirect_to: formValues?.redirect_to || "",
        description: formValues?.description || "",
        type: formValues?.trackable_type || formValues?.type || "",
        status: formValues?.status || "",
        event_id:
          (formValues?.trackable_id && formValues?.trackable_details) ||
          formValues?.event_id ||
          "",
        event_date: (formValues?.event_date && formValues?.event_date) || "",
        event_status: formValues?.event_status ? formValues?.event_status : "",
        short_description: formValues?.short_description || "",
        booking_status:
          (formValues?.details &&
            bookingStatus.filter((booking_status) =>
              formValues?.details?.user_event_schedule_statuses?.find(
                (item) => item === booking_status.value
              )
            )) ||
          formValues?.booking_status ||
          [],
        blog_id:
          formValues?.blog_id ||
          (fieldValues?.trackable_id &&
            formValues?.redirect_to === "BlogDetail" &&
            blogs.length > 0 &&
            blogs.find((x) => x.id === formValues?.trackable_id)) ||
          "",
        blog_title:
          (fieldValues?.trackable_id &&
            formValues?.redirect_to === "BlogDetail" &&
            blogs.length > 0 &&
            blogs.find((x) => x.id === formValues?.trackable_id).title) ||
          "",
      }}
      onSubmit={handlenoticeForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            {!isEdit && (
              <div>
                <TextField
                  className="material-form__field"
                  name={type}
                  placeholder="Type"
                  label="Type"
                  select
                  value={rest.values.type}
                  onChange={(event) => {
                    handleFormValueChange(rest, type);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(type)}
                  onBlur={() => handleBlurField()}
                >
                  {trackable_type.map((type) => (
                    <MenuItem
                      key={type.id}
                      className="material-form__option"
                      value={type.value}
                    >
                      {type.displayText}
                    </MenuItem>
                  ))}
                </TextField>

                {focusField === type ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <>
                        <div className="material-form__error">{msg}</div>
                      </>
                    )}
                    name={type}
                  />
                )}
              </div>
            )}

            {rest.values.type && (
              <div>
                <TextField
                  className="material-form__field"
                  name={redirect_to}
                  disabled={
                    rest.values.type === "Event Schedule" ||
                    (rest?.values?.redirect_to === "BlogDetail" && isEdit)
                  }
                  placeholder="Landing Page"
                  label="Landing Page"
                  select
                  value={rest.values.redirect_to}
                  onChange={(event) => {
                    handleFormValueChange(rest, redirect_to);
                    handleFormUpdate(event);
                  }}
                  onFocus={() => handleFocusField(redirect_to)}
                  onBlur={() => handleBlurField()}
                >
                  {landingPageOptions.map((page) =>
                    rest.values.type === "General" ? (
                      page.displayText !== "Event Detail" && (
                        <MenuItem
                          key={page.id}
                          className="material-form__option"
                          value={page.value}
                        >
                          {page.displayText}
                        </MenuItem>
                      )
                    ) : (
                      <MenuItem
                        key={page.id}
                        className="material-form__option"
                        value={page.value}
                      >
                        {page.displayText}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </div>
            )}
            {rest?.values?.redirect_to === "BlogDetail" && (
              <div>
                <Autocomplete
                  options={blogs}
                  getOptionSelected={(option, value) => option.title !== value}
                  getOptionLabel={(blog) =>
                    blog.title ? `${blog.title}` : rest?.values?.blog_title
                  }
                  value={rest.values.blog_id}
                  name={blog_id}
                  onChange={(blog, value) => {
                    setFieldValue(blog_id, value);
                    handleStatusUpdate(blog_id, value);
                  }}
                  disabled={isEdit}
                  onFocus={() => handleFocusField(blog_id)}
                  onBlur={() => handleBlurField()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      name={blog_id}
                      placeholder="Select Blog"
                      label="Select Blog"
                      variant="standard"
                    />
                  )}
                />
                {focusField === blog_id ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={blog_id}
                  />
                )}
              </div>
            )}

            {!isEdit && rest?.values?.type === "Event Schedule" && (
              <div className="material-form_inline-fields">
                <div className="materila-form_inline-child">
                  <DatePicker
                    selectedDate={rest.values.event_date}
                    handleChange={(value) => {
                      setFieldValue(event_date, value);
                      setFilter(event_date, moment(value).format("DD-MM-YYYY"));
                      setFormvalues((prevFieldValue) => ({
                        ...prevFieldValue,
                        event_date: value,
                      }));
                    }}
                    id="event_date"
                    name={event_date}
                    label="Event Date"
                    customInput={
                      <TextField
                        className="material-form__field"
                        id="event_date"
                        label="Event Date"
                        autoComplete="off"
                      />
                    }
                  />
                </div>

                <div className="materila-form_inline-child">
                  <div>
                    <TextField
                      className="material-form__field"
                      name={event_status}
                      placeholder="Event Status"
                      label="Event Status"
                      select
                      value={rest.values.event_status}
                      onChange={(event) => {
                        handleFormValueChange(rest, event_status);
                        handleFormUpdate(event);
                        setFilter(status, event.target.value);
                      }}
                      onFocus={() => handleFocusField(status)}
                      onBlur={() => handleBlurField()}
                    >
                      {scheduleEventStatus.map((status) => (
                        <MenuItem
                          key={status.id}
                          className="material-form__option"
                          value={status.value}
                        >
                          {status.displayText}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
            )}

            {!isEdit && rest?.values?.type === "Event Schedule" && (
              <div>
                <Autocomplete
                  options={events}
                  getOptionSelected={(option, value) => option.title !== value}
                  getOptionLabel={(event) =>
                    event.title
                      ? `${event.title} ${moment(event?.starts_at).format(
                          "@ hh:mm A"
                        )}`
                      : ""
                  }
                  value={rest.values.event_id}
                  name={event_id}
                  onChange={(event, value) => {
                    setFieldValue(event_id, value);
                    handleStatusUpdate(event_id, value);
                  }}
                  onFocus={() => handleFocusField(event_id)}
                  onBlur={() => handleBlurField()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      name={event_id}
                      placeholder="Select Event"
                      label="Select Event"
                      variant="standard"
                    />
                  )}
                />
                {focusField === event_id ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={event_id}
                  />
                )}
              </div>
            )}

            {!isEdit && rest?.values?.type === "Event Schedule" && (
              <div>
                <Autocomplete
                  multiple
                  options={bookingStatus}
                  getOptionLabel={(status) =>
                    status.displayText ? status.displayText : ""
                  }
                  value={rest.values.booking_status}
                  name={booking_status}
                  onChange={(event, value) => {
                    setFieldValue(booking_status, value);
                    handleStatusUpdate(booking_status, value);
                  }}
                  onFocus={() => handleFocusField(booking_status)}
                  onBlur={() => handleBlurField()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      name={booking_status}
                      placeholder="Select BookingStatus"
                      label="Select Booking Status"
                      variant="standard"
                    />
                  )}
                />

                {focusField === booking_status ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={booking_status}
                  />
                )}
              </div>
            )}

            <div>
              <TextField
                className="material-form__field"
                name={title}
                placeholder="Title"
                label="Title"
                type="text"
                inputProps={{
                  maxlength: title_limit,
                }}
                helperText={`${rest.values.title.length}/${title_limit} characters`}
                value={rest.values.title}
                onChange={(event) => {
                  handleFormValueChange(rest, title);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(title)}
                onBlur={() => handleBlurField()}
              />
              {focusField === title ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={title}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={short_description}
                placeholder="Short Description"
                label="Short Description"
                type="text"
                inputProps={{
                  maxlength: short_description_limit,
                }}
                helperText={`${rest.values.short_description.length}/${short_description_limit} characters`}
                value={rest.values.short_description}
                onChange={(event) => {
                  handleFormValueChange(rest, short_description);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(short_description)}
                onBlur={() => handleBlurField()}
              />
              {focusField === short_description ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={short_description}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={description}
                placeholder="Description"
                label="Description"
                type="text"
                inputProps={{
                  maxlength: description_limit,
                }}
                helperText={`${rest.values.description.length}/${description_limit} characters`}
                value={rest.values.description}
                onChange={(event) => {
                  handleFormValueChange(rest, description);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(description)}
                onBlur={() => handleBlurField()}
              />
              {focusField === description ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={description}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={(event) => {
                  handleFormValueChange(rest, status);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
              >
                {noticeStatus.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default NoticeForm;
