const CommentIcon = "/img/comment.svg";
const ShareIcon = "/img/share.svg";
const OptionDots = "/img/more-vert.svg";
const BackWhiteArrow = "/img/back-white-arrow.svg";
const ClappingHand = "/img/clapping-hands-light-skin-tone.svg";
const HeartIcon = "/img/red-heart.svg";
const SmilingFaceIcon = "/img/smiling-face.svg";
const SurprisedIcon = "/img/hushed-face.svg";
const LaughingIcon = "/img/grinning-face-with-smiling-eyes.svg";
const CanncelIcon = "/img/close-icon-light.svg";
const LeftArrow = "/img/leftarrow.svg";
const CalenderShape = "/img/calender-shape.svg";
const DarkClockIcon = "/img/clock-black.svg";
const PhotoIcon = "/img/photo.svg";
const PollIcon = "/img/poll.svg";
const CreatePollIcon = "/img/create-poll.svg";
const CloseGreyIcon = "/img/close-filled-grey.svg";
const DeleteIcon = "/img/delete.svg";
const BackArrow = "/img/back-icon-arrow.svg";
const CrossIcon = "/img/cross.svg";
const LogoGreenBorder = "/img/logo-green-border.svg";
const ThankfulIcon = "/img/thankful.svg";
const LikeIcon = "/img/like.svg";

export {
  CommentIcon,
  ShareIcon,
  OptionDots,
  BackWhiteArrow,
  HeartIcon,
  SmilingFaceIcon,
  SurprisedIcon,
  ClappingHand,
  LaughingIcon,
  CanncelIcon,
  LeftArrow,
  CalenderShape,
  DarkClockIcon,
  PhotoIcon,
  CreatePollIcon,
  PollIcon,
  CloseGreyIcon,
  DeleteIcon,
  BackArrow,
  CrossIcon,
  LogoGreenBorder,
  LikeIcon,
  ThankfulIcon,
};
