import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { downloadSampleBlogs, getBlogList, uploadBlogs } from "../actions"
import { getBlogFilter } from "../selectors";
export const useUploadBlog = () => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [uploadBlogsLoading, setUploadBlogsLoading] = useState(false);
  const filters = useSelector(getBlogFilter);
  const [errorData, setErrorData] = useState([]);

  const handleUploadBlogs = async (values) => {
    setUploadBlogsLoading(true);
    var data = new FormData();
    data.append('excel_file', values.excel_file)
    const { is_success, errors } = await dispatch(uploadBlogs(data));
    if (is_success) {
      dispatch(getBlogList(filters))
      if (!errors.length > 0) {
        toggleModal();
      }
    }
    if (errors.length > 0) {
      setErrorData(errors)
    }
    setUploadBlogsLoading(false);
  };

  const toggleModal = () => {
    setErrorData([])
    setIsOpenModal(!isOpenModal);
  };

  const downloadSampleBlogFile = async (e) => {
    e.preventDefault();
    const exportData = await dispatch(downloadSampleBlogs());
    if (exportData) {
      var blob = new Blob([exportData], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `SampleBlogs.xlsx`);
    }
    return false
  };

  return {
    uploadBlogsLoading,
    handleUploadBlogs,
    isOpenModal,
    toggleModal,
    errorData,
    downloadSampleBlogFile,
  };
};
