import React from "react";
import { useChangeNoticeStatus } from "./hooks";
import Tooltip from "@material-ui/core/Tooltip";
import { useUser } from "../../../common-hooks/user";

const ChangeNoticeStatus = ({ notice }) => {
  const { handlechangeNotice } = useChangeNoticeStatus(notice);

  const { status } = notice;

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Tooltip
        title={`${
          status === "Published" ? "Click to Unpublish" : "Click to Publish"
        }`}
        placement="bottom"
      >
        <i
          className={`fa fa-${
            status === "Published"
              ? "check-circle table__icon-active"
              : "times-circle table__icon-deactive"
          } icon-btn-action`}
          onClick={() =>
            handlechangeNotice(
              status === "Published" ? "Unpublished" : "Published"
            )
          }
        ></i>
      </Tooltip>
    </div>
  );
};

export default ChangeNoticeStatus;
