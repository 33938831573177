import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSignedUrl,
  uploadFiles,
  deleteAFile,
  clearFileContext,
  clearFiles,
} from "./actions";
import { CONFIG_KEYS, fileContext, snackbarTypes } from "../../constants";
import {
  checkIfFileIsCorrectSize,
  checkIfFileIsCorrectType,
  checkIfFilesAreCorrectSize,
  checkIfFilesAreCorrectType,
  getDuration,
} from "../validation";
import { showSnackbar } from "../../components/snackbar/actions";
import { configFindSelector } from "../../containers/Layout/selectors";

export const useUploadFiles = () => {
  const [fileIconError, setFileIconError] = useState("");
  const [noteUploadError, setNoteUploadError] = useState("");
  const [videoIconFileError, setVideoIconFileError] = useState("");
  const [fileBannerError, setFileBannerError] = useState("");
  const [fileProfileBannerError, setFileProfileBannerError] = useState("");
  const [fileHomePageBannerError, setFileHomePageBannerError] = useState("");
  const [filePopularBannerError, setFilePopularBannerError] = useState("");
  const [filePopularBannerWebError, setFilePopularBannerWebError] =
    useState("");
  const [fileBannerTileError, setFileBannerTileError] = useState("");
  const [videoBannerError, setVideoBannerError] = useState("");
  const [fileProfilePictureError, setFileProfilePictureError] = useState("");
  const [uploadError, setUploadError] = useState("");
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isFileUploadData, setIsFileUploadData] = useState([]);
  const [videoUploadError, setVideoUploadError] = useState("");
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);

  const { value: maxVideosLength = 0 } = useSelector(
    configFindSelector(CONFIG_KEYS.maxPostVideos)
  );
  const { value: maxImagesLength = 0 } = useSelector(
    configFindSelector(CONFIG_KEYS.maxPostImages)
  );

  const { value: maxVideoSize = 0 } = useSelector(
    configFindSelector(CONFIG_KEYS.postVideoMaxSize)
  );

  const { value: maxImageSize = 0 } = useSelector(
    configFindSelector(CONFIG_KEYS.postImageMaxSize)
  );

  const { value: maxVideoLength = 0 } = useSelector(
    configFindSelector(CONFIG_KEYS.maxVideoLimit)
  );

  const handleIconFile = (file) => {
    if (file.type !== "image/png") {
      setFileIconError("Upload only .png file for Icon");
      return;
    }
    dispatch(clearFileContext(fileContext.ICON));
    handleFileUpload(file, fileContext.ICON);
  };

  const handleVideoFile = (file) => {
    dispatch(clearFileContext(fileContext.VIDEO));
    handleFileUpload(file, fileContext.VIDEO);
  };

  const handleIntroVideoFile = (file) => {
    dispatch(clearFileContext(fileContext.PROFILE_VIDEO));
    handleFileUpload(file, fileContext.PROFILE_VIDEO);
  };

  const handleVideoIconFile = (file) => {
    if (file.type !== "image/png") {
      setVideoIconFileError("Upload only .png file for Icon");
      return;
    }
    dispatch(clearFileContext(fileContext.VIDEO_ICON));
    handleFileUpload(file, fileContext.VIDEO_ICON);
  };

  const handleSingleIconFile = (file) => {
    dispatch(clearFiles());
    if (file.type !== "image/png") {
      setFileIconError("Upload only .png file for Icon");
      return;
    }
    handleFileUpload(file, fileContext.ICON);
  };

  const handleSingleBannerFile = (file, context) => {
    dispatch(clearFileContext(context));
    handleFileUpload(file, context);
  };

  const handleBannerFile = (files) => {
    if (!checkIfFilesAreCorrectType(files)) {
      setFileBannerError("Upload valid image file");
      return;
    }
    Array.from(files).forEach((file) => {
      handleFileUpload(file, fileContext.BANNER);
    });
  };

  const handleEventVideoFile = (files, context) => {
    setVideoUploadError("");
    if (!checkIfFilesAreCorrectSize(files, 50)) {
      setVideoUploadError(
        "File is too large please upload a file less than 50 mb"
      );
      return;
    }

    Array.from(files).forEach((file) => {
      handleFileUpload(file, context);
    });
  };

  const handleEventVideoBannerFile = (files, context) => {
    Array.from(files).forEach((file) => {
      handleFileUpload(file, context);
    });
  };

  const handleProfileBannerFile = (files) => {
    if (!checkIfFilesAreCorrectType(files)) {
      setFileProfileBannerError("Upload valid image file");
      return;
    }
    Array.from(files).forEach((file) => {
      handleFileUpload(file, fileContext.PROFILE_BANNER);
    });
  };

  const handleHomePageBannerFile = (files) => {
    if (!checkIfFilesAreCorrectType(files)) {
      setFileHomePageBannerError("Upload valid image file");
      return;
    }
    dispatch(clearFileContext(fileContext.HOME_PAGE_BANNER));

    Array.from(files).forEach((file) => {
      handleFileUpload(file, fileContext.HOME_PAGE_BANNER);
    });
  };

  const handleVideoBannerFile = (files) => {
    if (!checkIfFilesAreCorrectType(files)) {
      setVideoBannerError("Upload valid image file");
      return;
    }
    Array.from(files).forEach((file) => {
      handleFileUpload(file, fileContext.VIDEO_BANNER);
    });
  };

  const handlePopularBannerFile = (files) => {
    if (!checkIfFilesAreCorrectType(files)) {
      setFilePopularBannerError("Upload valid image file");
      return;
    }
    Array.from(files).forEach((file) => {
      handleFileUpload(file, fileContext.POPULAR_BANNER);
    });
  };

  const handlePopularBannerWebFile = (files) => {
    if (!checkIfFilesAreCorrectType(files)) {
      setFilePopularBannerWebError("Upload valid image file");
      return;
    }
    Array.from(files).forEach((file) => {
      handleFileUpload(file, fileContext.POPULAR_BANNER_WEB);
    });
  };

  const handlePartnerLogoFile = (files, context) => {
    dispatch(clearFileContext(context));
    Array.from(files).forEach((file) => {
      handleFileUpload(file, context);
    });
  };

  const handleProfilePictureFile = (files) => {
    if (!checkIfFilesAreCorrectType(files)) {
      setFileProfilePictureError("Upload valid image file");
      return;
    }
    dispatch(clearFileContext(fileContext.PROFILE_PIC));
    handleFileUpload(files[0], fileContext.PROFILE_PIC);
  };

  const handleBannerTileFile = (files) => {
    if (!checkIfFilesAreCorrectType(files)) {
      setFileBannerTileError("Upload valid image file");
      return;
    }
    Array.from(files).forEach((file) => {
      handleFileUpload(file, fileContext.BANNER_TILE);
    });
  };

  const handleNoteFile = (file) => {
    if (file.type !== "application/pdf") {
      setNoteUploadError("Upload only .pdf file for Note");
      return false;
    }
    dispatch(clearFileContext(fileContext.NOTE));
    handleFileUpload(file, fileContext.NOTE);
  };

  const handleAttachmentFile = (file) => {
    dispatch(clearFileContext(fileContext.EVENT_ATTACHMENT));
    handleFileUpload(file, fileContext.EVENT_ATTACHMENT);
  };

  const handleAttachmentFileCourse = async (file, i) => {
    dispatch(clearFileContext(`${fileContext.EVENT_ATTACHMENT}_${i}`));
    await handleFileUpload(file, `${fileContext.EVENT_ATTACHMENT}_${i}`);
  };

  const handleAttachmentCourseNote = async (file, i) => {
    dispatch(clearFileContext(`${fileContext.NOTE}_${i}`));
    await handleFileUpload(file, `${fileContext.NOTE}_${i}`);
  };

  const handlePostFiles = async (filesData) => {
    const fileList = Array.from(filesData);
    const imageListLength = [...files, ...filesData].filter(
      (file) => file.type?.includes("image") || file?.file_context === "Image"
    ).length;

    const videos = [...files, ...filesData].filter(
      (file) => file.type?.includes("video") || file?.file_context === "Video"
    );

    const videoListLength = videos.length;
    let isValidDuration = true;

    if (videoListLength) {
      const videoCopy = await getDuration(videos);
      videoCopy.find((video) => {
        const isExist = video?.duration > parseInt(maxVideoLength);
        if (isExist) {
          isValidDuration = !isExist;
          return isValidDuration;
        }
        return true;
      });
    }

    if (!isValidDuration) {
      const duration =
        parseInt(maxVideoLength) < 60
          ? `${Math.floor(maxVideoLength)} seconds`
          : `${Math.floor(maxVideoLength) / 60} minutes`;
      const message = `Please upload a video less than ${duration}`;
      dispatch(
        showSnackbar({
          type: snackbarTypes.ERROR,
          message,
        })
      );
    } else if (
      imageListLength > parseInt(maxImagesLength) ||
      videoListLength > parseInt(maxVideosLength)
    ) {
      dispatch(
        showSnackbar({
          type: snackbarTypes.ERROR,
          message: `Oops! You cannot upload more than ${maxImagesLength} photos & ${maxVideosLength} videos at once.`,
        })
      );
    } else {
      let isMediaInvalid = "";

      fileList.forEach(async (file) => {
        const isValidType = checkIfFileIsCorrectType(file);
        const isVideo = file.type?.includes("video");

        if (isValidType) {
          const isValidSize = await checkIfFileIsCorrectSize(
            file,
            isVideo ? maxVideoSize : maxImageSize
          );

          if (!isValidSize) {
            isMediaInvalid = `Oops! Upload failed. Please choose an image below ${maxImageSize}MB & a video below ${maxVideoSize}MB`;
          } else {
            handleFileUpload(
              file,
              isVideo ? fileContext.POST_VIDEO : fileContext.POST_IMAGE
            );
          }
        } else {
          isMediaInvalid = `Only images and videos are accepted.`;
        }
      });

      setTimeout(() => {
        if (isMediaInvalid) {
          dispatch(
            showSnackbar({
              type: snackbarTypes.ERROR,
              message: isMediaInvalid,
            })
          );
        }
      }, 1000);
    }
  };

  const handleFileUpload = async (file, fileContext) => {
    // setIsFileUploadData([...isFileUploadData,{ isUpload: true, fileName: file.name, fileContext: fileContext }])
    setIsFileUploadData((previousState) => [
      ...previousState,
      {
        isUpload: true,
        fileName: file.name,
        fileContext: fileContext,
        uploadProgress: 0,
      },
    ]);
    const config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      },
    };

    setIsUploading(true);
    const response = await dispatch(getSignedUrl(file.name));
    const { uuid_file_name, original_file_name, signed_url, public_url } =
      response;
    if (!signed_url) {
      setUploadError(
        "Something went wrong while uploading file, please try again"
      );
      setIsUploading(false);
      return;
    }
    const fileData = {
      uuid_file_name,
      original_file_name,
      public_url,
      file_context: fileContext,
    };
    const sucess = await dispatch(
      uploadFiles(signed_url, file, fileData, config)
    );
    if (!sucess) {
      setUploadError(
        "Something went wrong while uploading file, please try again"
      );
      setIsUploading(false);
      return;
    }
    let arr = isFileUploadData;
    var index = arr
      .map(function (e) {
        return e.fileName;
      })
      .indexOf(file.name);
    arr.splice(index, 1);
    setIsFileUploadData(arr);
    setIsUploading(false);
    setUploadProgress(0);
  };

  const handleDeleteAFile = ({ uuid_file_name }) => {
    dispatch(deleteAFile(uuid_file_name));
  };

  const clearAllFiles = () => {
    dispatch(clearFiles());
  };

  const handleCropping = (
    file,
    setFieldValue,
    name,
    context,
    callback,
    dimensions,
    setBannerState
  ) => {
    setBannerState([]);
    const { width, height } = dimensions;

    Array.from(file).forEach((f) => {
      let img = new Image();
      let imgCopy = [f];

      img.onload = () => {
        const iwidth = img.naturalWidth,
          iheight = img.naturalHeight;

        window.URL.revokeObjectURL(img.src);
        if (iwidth === width && iheight === height) {
          if (context.includes(fileContext.VIDEO_BANNER)) {
            callback(
              imgCopy,
              `${fileContext.VIDEO_BANNER} ${
                files.filter((file) =>
                  file.file_context?.includes(fileContext.VIDEO_BANNER)
                ).length + 1
              }`
            );

            setBannerState([]);
          } else {
            callback(imgCopy);
            setFieldValue(
              name,
              files.filter((file) => file.file_context === context)
            );
          }
        } else if (iwidth < width || iheight < height) {
          dispatch(
            showSnackbar({
              message: "Please upload images in preferred dimesions.",
              type: snackbarTypes.ERROR,
            })
          );
        } else {
          setBannerState((prev) => [...prev, ...imgCopy]);
        }
      };

      img.src = window.URL.createObjectURL(f);
    });
  };

  const handleDiscussionFiles = async (filesData, id) => {
    const fileList = Array.from(filesData);
    const imageListLength = [...files, ...filesData].filter(
      (file) => file.type?.includes("image") || file?.file_context === "Image"
    ).length;

    const videos = [...files, ...filesData].filter(
      (file) => file.type?.includes("video") || file?.file_context === "Video"
    );

    const videoListLength = videos.length;
    let isValidDuration = true;

    if (videoListLength) {
      const videoCopy = await getDuration(videos);
      videoCopy.find((video) => {
        const isExist = video?.duration > parseInt(maxVideoLength);
        if (isExist) {
          isValidDuration = !isExist;
          return isValidDuration;
        }
        return true;
      });
    }

    if (!isValidDuration) {
      const duration =
        parseInt(maxVideoLength) < 60
          ? `${Math.floor(maxVideoLength)} seconds`
          : `${Math.floor(maxVideoLength) / 60} minutes`;
      const message = `Please upload a video less than ${duration}`;
      dispatch(
        showSnackbar({
          type: snackbarTypes.ERROR,
          message,
        })
      );
    } else if (
      imageListLength > parseInt(maxImagesLength) ||
      videoListLength > parseInt(maxVideosLength)
    ) {
      dispatch(
        showSnackbar({
          type: snackbarTypes.ERROR,
          message: `Oops! You cannot upload more than ${maxImagesLength} photos & ${maxVideosLength} videos at once.`,
        })
      );
    } else {
      let isMediaInvalid = "";

      fileList.forEach(async (file) => {
        const isValidType = checkIfFileIsCorrectType(file);
        const isVideo = file.type?.includes("video");
        let isValidDuration = true;

        if (isVideo) {
          const duration = await getDuration(file);
          if (duration > parseInt(maxVideoLength)) {
            isValidDuration = false;
          }
        }

        if (isValidType) {
          const isValidSize = checkIfFileIsCorrectSize(
            file,
            isVideo ? maxVideoSize : maxImageSize
          );

          if (!isValidSize) {
            isMediaInvalid = `Oops! Upload failed. Please choose an image below ${maxImageSize}MB & a video below ${maxVideoSize}MB`;
          }
          if (!isValidDuration) {
            isMediaInvalid = `Please upload a video less than ${
              Math.floor(maxVideoLength) / 60
            } minutes`;
          } else {
            handleFileUpload(
              file,
              isVideo
                ? `${
                    id
                      ? `${fileContext.DISCUSSION_VIDEOS}-${id}`
                      : `${fileContext.DISCUSSION_VIDEOS}`
                  }`
                : `${
                    id
                      ? `${fileContext.DISCUSSION_IMAGES}-${id}`
                      : `${fileContext.DISCUSSION_IMAGES}`
                  }`
            );
          }
        } else {
          isMediaInvalid = `Only images and videos are accepted.`;
        }
      });

      setTimeout(() => {
        if (isMediaInvalid) {
          dispatch(
            showSnackbar({
              type: snackbarTypes.ERROR,
              message: isMediaInvalid,
            })
          );
        }
      }, 1000);
    }
  };

  return {
    handleIconFile,
    handleSingleIconFile,
    handleBannerFile,
    handleProfileBannerFile,
    handleFileUpload,
    uploadError,
    fileBannerError,
    fileProfileBannerError,
    fileIconError,
    files,
    handleDeleteAFile,
    handlePopularBannerFile,
    filePopularBannerError,
    handlePopularBannerWebFile,
    filePopularBannerWebError,
    handleProfilePictureFile,
    fileProfilePictureError,
    handleBannerTileFile,
    fileBannerTileError,
    uploadProgress,
    isFileUploadData,
    handleNoteFile,
    isUploading,
    handleVideoFile,
    handleVideoIconFile,
    videoIconFileError,
    handleVideoBannerFile,
    videoBannerError,
    noteUploadError,
    handleHomePageBannerFile,
    fileHomePageBannerError,
    handleAttachmentFile,
    handleEventVideoFile,
    videoUploadError,
    handlePartnerLogoFile,
    handleAttachmentFileCourse,
    handleEventVideoBannerFile,
    handleAttachmentCourseNote,
    clearAllFiles,
    handleSingleBannerFile,
    handleCropping,
    handleIntroVideoFile,
    handlePostFiles,
    handleDiscussionFiles,
  };
};
