import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadCodes, getVouchersList, setVoucherFilter } from "./actions";
import { getVouchers, getVouchersCount, getVouchersFilter } from "./selectors";
import { saveAs } from "file-saver";

export const useVoucher = () => {
  const [loadingVouchers, setLoadingVouchers] = useState(false);
  const vouchers = useSelector(getVouchers);
  const filters = useSelector(getVouchersFilter);
  const count = useSelector(getVouchersCount);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        setLoadingVouchers(true);
        const stories = await dispatch(getVouchersList(filters));
        if (stories) {
          setLoadingVouchers(false);
        }
      } catch (error) {
        setLoadingVouchers(false);
      } finally {
        setLoadingVouchers(false);
      }
    })();
  }, [filters, dispatch]);

  const downloadCode = async (id) => {
    const codes = await dispatch(downloadCodes(id), {
      utc_offset: moment().utcOffset(),
    });

    if (codes) {
      var blob = new Blob([codes], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `codes.xlsx`);
    }
  };

  const handleSetFilter = (key, value) => {
    dispatch(setVoucherFilter({ key, value }));
  };

  return {
    vouchers,
    filters,
    count,
    loadingVouchers,
    downloadCode,
    handleSetFilter
  };
};
