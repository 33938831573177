import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../components/modal";
import PartnerForm from "../form/partner-form";
import { useEditPartner } from "./hooks";
import { useUser } from "../../../common-hooks/user";

const EditPartner = ({ partner }) => {
  const {
    getPartner,
    handleEditPartner,
    isOpenModal,
    selectedPartner,
    editPartnerLoading,
    toggleModal,
  } = useEditPartner(partner);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <>
      <Tooltip title="Edit" placement="bottom">
        <i className={`fa fa-edit icon-btn-action`} onClick={getPartner}></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PartnerForm
              fieldValues={selectedPartner}
              handlePartnerForm={handleEditPartner}
              btn="Save"
              isLoading={editPartnerLoading}
            />
          }
          title="Edit Partner"
        />
      ) : null}
    </>
  );
};

export default EditPartner;
