import { useState } from 'react';

export const useColorPicker = ({ onChange, name, value, label }) => {
    const [colorValue, setColorValue] = useState({
        color: value || '#ff4861',
        rgb: {
            r: 246, g: 129, b: 110, a: 1,
        },
    });

    const [sketchColorActive, setSketchColorActive] = useState({
        displayColorPicker: false,
        active: false,
    });

    const { color } = colorValue;
    const { displayColorPicker, active } = sketchColorActive;

    const handleSelectColor = (e) => {
        e.preventDefault();
        setSketchColorActive(
            { displayColorPicker: !displayColorPicker, active: !active },
        );
    };

    const handleColorChange = (changeColor) => {
        onChange(changeColor.hex);
        setColorValue({ color: changeColor.hex, rgb: changeColor.rgb });
        setSketchColorActive(
            { displayColorPicker: !displayColorPicker, active: !active },
        );
    };


    return {
        active,
        displayColorPicker,
        color,
        handleColorChange,
        handleSelectColor,
        name,
        label,
    }

}