export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Title",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Created At",
    accessor: "created_at",
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    width: 60,
  },
];

export const fieldsName = {
  name: "name",
  title: "title",
  description: "description",
};

export const policyName = [
  {
    id: 1,
    value: "CancellationPolicy",
    displayText: "CancellationPolicy",
  },
  {
    id: 2,
    value: "PrivacyPolicy",
    displayText: "PrivacyPolicy",
  },
  {
    id: 3,
    value: "ReferAndEarnTnC",
    displayText: "ReferAndEarnTnC",
  },
  {
    id: 4,
    value: "SubscriptionCancellationPolicy",
    displayText: "SubscriptionCancellationPolicy",
  },
  {
    id: 5,
    value: "TermsAndConditions",
    displayText: "TermsAndConditions",
  },
];
