import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import Accordion from "../../../../components/Accordion";

const UserSource = ({ source }) => (
  <Col md={12} lg={12} xl={12}>
    <Accordion
      className="user__tabs_collapse"
      title={"source"}
      titleClassName="profile__current-tasks-title"
    >
      <Card>
        <CardBody className="profile__card">
          <div className="profile__current-task">
            <div className="col-7 user_details_padLeft0">Type</div>
            <div className="col-5 user_details_pad0">
              {source?.type || "NA"}
            </div>
          </div>

          <div className="profile__current-task">
            <div className="col-7 user_details_padLeft0">Code</div>
            <div className="col-5 user_details_pad0">
              {source?.code || "NA"}
            </div>
          </div>

          <div className="profile__current-tasks">
            <div className="profile__current-task">
              <div className="col-7 user_details_padLeft0">Name</div>

              <div className="col-5 user_details_pad0">
                {source?.name || "NA"}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Accordion>
  </Col>
);

export default UserSource;
