export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 30,
  },
];

export const FieldsName = {
  title: "title",
  status: "status",
};

export const statusOptions = [
  {
    id: 1,
    value: "Published",
    displayText: "Published"
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft"
  },
];