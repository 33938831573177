import { api, apiEndPoints } from "../../../api";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";

const namespace = `/containers/CMS/banners`;

export const SET_BANNER_LIST = `${namespace}/SET_BANNER_LIST`;
export const SET_BANNER_FILTER = `${namespace}/SET_BANNER_FILTER`;
export const SET_BLOG_FILTER = `${namespace}/SET_BLOG_FILTER`;
export const SET_EVENT_FILTER = `${namespace}/SET_EVENT_FILTER`;
export const CLEAR_BLOG_FILTER = `${namespace}/CLEAR_BLOG_FILTER`;

export const setBannerList = (payload) => ({
  type: SET_BANNER_LIST,
  payload,
});

export const setBannerFilter = (payload) => ({
  type: SET_BANNER_FILTER,
  payload,
});

export const setBlogFilter = (payload) => ({
  type: SET_BLOG_FILTER,
  payload,
});

export const clearBlogFilter = (payload) => ({
  type: CLEAR_BLOG_FILTER,
  payload,
});

export const setEventFilter = (payload) => ({
  type: SET_EVENT_FILTER,
  payload,
});

export const getBannerList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getBanners, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setBannerList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addBanner = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addBanner, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getSingleBanner = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSingleBanner, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updateBanner = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateBanner(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteBanner = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteBanner(id));
    const { data: { is_success, message } = {} } = response;

    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getScheduleEventList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSchedulesEvents, {
      params: { params },
    });
    const { data: { is_success, event_schedules } = {} } = response;
    if (is_success) {
      return event_schedules;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getBlogList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getBlogs, {
      params: { params },
    });
    const { data: { is_success, blogs } = {} } = response;
    if (is_success) {
      return blogs;
    }
    return false;
  } catch (error) {
    return false;
  }
};
