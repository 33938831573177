import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../../scss/app.scss";
import Router from "./Router";
import { store } from "../../redux/configure-store";
import { Loader } from "../../components/loader";
import SnackBar from "../../components/snackbar";
import "../../scss/containers/social/drafjs-mentions-plugin-override.css";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.addEventListener("load", () => {
      setLoading(false);
    });
  });

  return (
    <Provider store={store}>
      <BrowserRouter>
        <SnackBar />
        {loading && <Loader />}
        <Router />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
