import moment from "moment";
import { useState } from "react";
import { fieldsName } from "../constant";

const formField = {
  consumption_type: "",
  count: "",
  purpose: "",
  event: "",
  category: "",
  sub_category: "",
  entity: "",
  entity_name: "",
  validity: false,
  validity_type: "",
  validity_date: "",
  valid_for_days: "",
  usage: "",
  usage_count: "",
  voucher_type: "",
  voucher_length: "",
  voucher_prefix: "",
  voucher_postfix: "",
  discount_percentage: "",
  discount_sessions: "",
  discount_type: "",
  max_discount: "",
  discount_amount: "",
  entity_name_digits: "",
  valid_from: "",
  valid_till: "",
  pattern: "",
  has_discount: false,
};

export const useVoucherForm = ({ fieldValues }) => {
  const {
    event,
    purpose,
    usage,
    validity,
    usage_count,
    discount_percentage,
    discount_sessions,
    discount_type,
    max_discount,
    discount_amount,
    valid_from,
    valid_till,
    category,
    sub_category,
    valid_for_days,
    count,
    consumption_type,
    has_discount,
  } = fieldsName;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = (e) => {
    let { name, value } = e.target;

    if (name === valid_for_days && value) {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [name]: value,
        valid_from: prevFieldValue?.valid_from || new Date(),
        valid_till: moment(
          prevFieldValue?.valid_from || new Date(),
          "DD-MM-YYYY"
        )
          .add(value - 1, "days")
          .toDate(),
      }));
    }

    if (name === category) {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [name]: value,
        [sub_category]: "",
      }));
    }

    if (name === purpose) {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [event]: "",
      }));
    }

    if (name === validity) {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [valid_for_days]: "",
        [valid_from]: "",
        [valid_till]: "",
      }));
    }

    if (name === usage) {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [usage_count]: "",
      }));
    }

    if (name === discount_type) {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [discount_amount]: "",
        [discount_percentage]: "",
        [discount_sessions]: "",
        [max_discount]: "",
      }));
    }

    if (name === consumption_type) {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [name]: value,
        [count]: value === "Bulk" ? "" : 1,
      }));
    }

    if (name === has_discount) {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [name]: value,
        [discount_amount]: "",
        [discount_percentage]: "",
        [discount_sessions]: "",
        [discount_type]: "",
      }));
    }

    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  return {
    formValues,
    handleFormUpdate,
    setFormvalues,
    fieldsName,
  };
};
