import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import Modals from "../../../../components/modal";
import { useEditMetaData } from "./hooks";
import { useUser } from "../../../../common-hooks/user";
import MetaDataForm from "../form/form";

const EditMetaData = ({ metaData }) => {
  const {
    editMetaDataLoading,
    handleEditMetaData,
    isOpenModal,
    toggleModal,
    getMetaFieldData,
    selectedMetaData,
  } = useEditMetaData(metaData);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Tooltip title="Edit" placement="bottom" className="mr-3">
        <i
          className={`fa fa-edit icon-btn-action `}
          onClick={getMetaFieldData}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <MetaDataForm
              fieldValues={selectedMetaData}
              handleMetaDataForm={handleEditMetaData}
              btn="Save"
              isLoading={editMetaDataLoading}
            />
          }
          title="Edit Policy"
        />
      ) : null}
    </>
  );
};

export default EditMetaData;
