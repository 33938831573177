/* eslint-disable */

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";
import { clearFiles, setFiles } from "../../../../utils/upload-file/actions";
import { updatePost, getPosts, getSinglePost } from "../action";
import { MENTION_TYPES } from "../constant";
import { getPostsFilters } from "../selectors";

export const useEditPost = (post) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editPostLoading, setEditPostLoading] = useState(false);
  const postFilter = useSelector(getPostsFilters);
  const [selectedPost, setSelectedPost] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    setIsEdit(!isEdit);
  };

  const getPostData = async () => {
    const postData = await dispatch(getSinglePost(post?.id));
    dispatch(clearFiles());

    if (postData?.is_success) {
      let data = postData?.post;

      data["plainTextValue"] = data?.caption;

      if (data?.blog?.id) {
        data["is_blog"] = true;
      }
      if (data?.poll?.topic) {
        data["is_poll"] = true;
        data["poll_options"] = data?.poll?.poll_choices?.map(
          (choice) => choice?.title
        );
        data["poll_topic"] = data?.poll?.topic;
      }

      if (data?.owner?.id === parseInt(process.env.REACT_APP_EGC_ID)) {
        data["post_by"] = "EvergreenClub";
      } else {
        data["post_by"] = "Me";
      }

      if (data?.event_schedules?.length > 0) {
        data["is_event_or_course"] = true;
        data["type"] = "event";
        data["event_tagged"] = data?.event_schedules[0];
        data["date"] = new Date(data?.event_schedules[0]?.starts_at);
      }

      if (data?.courses?.length > 0) {
        data["is_event_or_course"] = true;
        data["type"] = "course";
        data["course_tagged"] = data?.courses[0];
        data["date"] = new Date(data?.courses[0]?.commence_at);
      }

      setSelectedPost(data);
      toggleModal();
      if (postData?.post?.files) {
        return postData?.post?.files?.forEach((file) => {
          dispatch(setFiles(file));
        });
      }
    }
  };

  const handleEditPost = async (values) => {
    if (values?.plainTextValue.length > 2000) {
      dispatch(
        showSnackbar({
          type: snackbarTypes.ERROR,
          message: "Max length for post caption is 2000 characters",
        })
      );

      return;
    }
    setEditPostLoading(true);

    let ranges = [];

    values?.ranges?.forEach((mention) => {
      if (mention?.type) {
        ranges.push({
          trackable_type:
            mention?.type === "#"
              ? MENTION_TYPES.hashtag
              : mention?.type === "@"
              ? MENTION_TYPES.user
              : "",
          trackable_id: mention.id,
          name: mention?.name?.slice(1, mention?.name?.length),
          offset: mention.offset,
          length: mention.length,
        });
      }
    });

    let payload = {
      post: {
        caption: values?.plainTextValue,
        ranges,
        files: values?.files,
        on_behalf_of:
          values?.post_by === "EvergreenClub" ? values?.post_by : "",
      },
    };

    if (values?.event_tagged) {
      payload["post"] = {
        ...payload?.post,
        event_tags: [
          {
            trackable_type: "EVENT_SCHEDULE",
            trackable_id: values?.event_tagged?.id,
          },
        ],
      };
    }

    if (values?.course_tagged) {
      payload["post"] = {
        ...payload?.post,
        event_tags: [
          {
            trackable_type: "COURSE",
            trackable_id: values?.course_tagged?.id,
          },
        ],
      };
    }

    if (values.blog?.id) {
      payload["post"] = {
        ...payload?.post,
        shared_item: {
          trackable_id: values?.blog?.id,
          trackable_type: "BLOG",
        },
      };
    }

    if (values?.poll_topic) {
      payload["post"] = {
        ...payload?.post,
        poll: {
          topic: values?.poll_topic,
          choices: values?.poll_options,
        },
      };
    }
    const is_success = await dispatch(updatePost(post?.id, payload));
    setEditPostLoading(false);
    if (is_success) {
      await dispatch(getPosts(postFilter));
      toggleModal();
    }
  };

  return {
    isOpenModal,
    toggleModal,
    editPostLoading,
    handleEditPost,
    getPostData,
    selectedPost,
    isEdit,
  };
};
