import React from "react";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { userRoles } from "../../../constants";
import { useUser } from "../../../common-hooks/user";

const SidebarContent = ({ onClick }) => {
  const { role } = useUser();
  const hideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink
          access={[userRoles.ADMIN]}
          title="Finance Dashboard"
          icon="pie-chart"
          route="/dashboard"
          onClick={hideSidebar}
        />

        <SidebarLink
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          title="Users"
          icon="users"
          route="/"
          onClick={hideSidebar}
        />

        <SidebarLink
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          title="Categories"
          icon="flag"
          route="/categories"
          onClick={hideSidebar}
        />

        <SidebarLink
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          title="Notices"
          icon="alarm"
          route="/notices"
          onClick={hideSidebar}
        />

        <SidebarLink
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          title="Events"
          icon="calendar-full"
          route="/events"
          onClick={hideSidebar}
        />
        <SidebarLink
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          title="Courses"
          icon="calendar-full"
          route="/courses"
          onClick={hideSidebar}
        />
        <SidebarLink
          access={[
            userRoles.ADMIN,
            userRoles.CO_ORDINATOR,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          title="Scheduled Events"
          icon="clock"
          route="/schedule-events"
          onClick={hideSidebar}
        />

        <SidebarLink
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          title="Interests"
          icon="star"
          route="/interests"
          onClick={hideSidebar}
        />

        <SidebarLink
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          title="Partners"
          icon="users"
          route="/partners"
          onClick={hideSidebar}
        />

        <SidebarLink
          access={[userRoles.ADMIN, userRoles.CS_SPECIALIST]}
          title="Offers"
          icon="gift"
          route="/offers"
          onClick={hideSidebar}
        />

        <SidebarLink
          access={[userRoles.ADMIN]}
          title="Vouchers"
          icon="gift"
          route="/vouchers"
          onClick={hideSidebar}
        />

        <SidebarLink
          access={[userRoles.ADMIN, userRoles.CS_SPECIALIST]}
          title="Unlocked Offers"
          icon="gift"
          route="/unlocked-offers"
          onClick={hideSidebar}
        />

        <SidebarCategory
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          role={role}
          title="CMS"
          icon="menu"
        >
          <SidebarCategory
            access={[
              userRoles.ADMIN,
              userRoles.MODERATOR,
              userRoles.CS_SPECIALIST,
            ]}
            role={role}
            title="Blog"
          >
            <SidebarLink
              access={[
                userRoles.ADMIN,
                userRoles.MODERATOR,
                userRoles.CS_SPECIALIST,
              ]}
              title="Blogs"
              route="/blogs"
              onClick={hideSidebar}
            />
            <SidebarLink
              access={[
                userRoles.ADMIN,
                userRoles.MODERATOR,
                userRoles.CS_SPECIALIST,
              ]}
              title="Videos"
              route="/videos"
            />
            <SidebarLink
              access={[
                userRoles.ADMIN,
                userRoles.MODERATOR,
                userRoles.CS_SPECIALIST,
              ]}
              title="Labels"
              route="/labels"
              onClick={hideSidebar}
            />
            <SidebarLink
              access={[
                userRoles.ADMIN,
                userRoles.MODERATOR,
                userRoles.CS_SPECIALIST,
              ]}
              title="Sections"
              route="/sections"
              onClick={hideSidebar}
            />
            <SidebarLink
              access={[
                userRoles.ADMIN,
                userRoles.MODERATOR,
                userRoles.CS_SPECIALIST,
              ]}
              title="Pages"
              route="/pages"
              onClick={hideSidebar}
            />
          </SidebarCategory>
          <SidebarCategory
            access={[
              userRoles.ADMIN,
              userRoles.MODERATOR,
              userRoles.CS_SPECIALIST,
            ]}
            role={role}
            title="Event"
          >
            <SidebarLink
              access={[
                userRoles.ADMIN,
                userRoles.MODERATOR,
                userRoles.CS_SPECIALIST,
              ]}
              title="Popular Keywords"
              route="/popular-keywords"
              onClick={hideSidebar}
            />
          </SidebarCategory>
          <SidebarLink
            access={[
              userRoles.ADMIN,
              userRoles.MODERATOR,
              userRoles.CS_SPECIALIST,
            ]}
            title="Policy"
            route="/policy"
            onClick={hideSidebar}
          />
          <SidebarLink
            access={[
              userRoles.ADMIN,
              userRoles.MODERATOR,
              userRoles.CS_SPECIALIST,
            ]}
            title="Banners"
            route="/banners"
            onClick={hideSidebar}
          />
          <SidebarLink
            access={[userRoles.ADMIN, userRoles.CS_SPECIALIST]}
            title="Settings"
            route="/settings"
            onClick={hideSidebar}
          />

          <SidebarLink
            access={[userRoles.ADMIN, userRoles.CS_SPECIALIST]}
            title="Testimonials"
            route="/testimonials"
            onClick={hideSidebar}
          />
          <SidebarLink
            access={[userRoles.ADMIN]}
            title="Meta Data"
            route="/meta-data"
            onClick={hideSidebar}
          />
        </SidebarCategory>

        <SidebarCategory
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          role={role}
          title="Social"
          icon="thumbs-up"
        >
          <SidebarLink
            access={[
              userRoles.ADMIN,
              userRoles.MODERATOR,
              userRoles.CS_SPECIALIST,
            ]}
            title="Posts"
            route="/posts"
            onClick={hideSidebar}
          />

          <SidebarLink
            access={[
              userRoles.ADMIN,
              userRoles.MODERATOR,
              userRoles.CS_SPECIALIST,
            ]}
            title="Stories"
            route="/stories"
            onClick={hideSidebar}
          />

          <SidebarCategory
            access={[
              userRoles.ADMIN,
              userRoles.MODERATOR,
              userRoles.CS_SPECIALIST,
            ]}
            role={role}
            title="Reported Data"
          >
            <SidebarLink
              access={[
                userRoles.ADMIN,
                userRoles.MODERATOR,
                userRoles.CS_SPECIALIST,
              ]}
              title="Reported Users"
              route="/users/reported"
              onClick={hideSidebar}
            />
            <SidebarLink
              access={[
                userRoles.ADMIN,
                userRoles.MODERATOR,
                userRoles.CS_SPECIALIST,
              ]}
              title="Reported Comments & Replies"
              route="/comments-and-replies"
              onClick={hideSidebar}
            />

            <SidebarLink
              access={[userRoles.ADMIN]}
              title="Report Options"
              route="/report-options"
              onClick={hideSidebar}
            />
          </SidebarCategory>
        </SidebarCategory>

        <SidebarLink
          access={[userRoles.ADMIN, userRoles.CS_SPECIALIST]}
          title="Plans"
          icon="license"
          route="/plans"
          onClick={hideSidebar}
        />

        <SidebarLink
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          title="Faq"
          icon="license"
          route="/faq"
          onClick={hideSidebar}
        />
      </ul>
    </div>
  );
};

export default SidebarContent;
