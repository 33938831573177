import React from "react";
import { Button } from "reactstrap";

import SettingsForm from "../form/settings-form";

import { useAddSetting } from "./hooks";
import Modals from "../../../../components/modal";
import { useUser } from "../../../../common-hooks/user";

const AddConfig = (props) => {
  const { handleAddConfig, isOpenModal, toggleModal, addConfigLoading } =
    useAddSetting(props);
  const { isAdmin, id } = useUser();

  if (
    isAdmin &&
    process.env.REACT_APP_CONFIG_ACCESS.split(",").includes(id.toString())
  ) {
    return (
      <>
        <Button
          className="btn btn-success btn-right btn-sm"
          onClick={toggleModal}
        >
          <i className="fa fa-plus"></i> Add Configuration
        </Button>
        {isOpenModal ? (
          <Modals
            isOpenModal
            setIsOpenModal={toggleModal}
            message={
              <SettingsForm
                handlePolicyForm={handleAddConfig}
                btn="Add"
                isLoading={addConfigLoading}
                handleSetting={handleAddConfig}
              />
            }
            title="Add Configuration"
          />
        ) : null}
      </>
    );
  } else {
    return null;
  }
};

export default AddConfig;
