import { SET_USER_LIST, SET_USER_FILTER, CLEAR_USER_FILTER, SET_USER_HISTORY } from "./actions";
import * as moment from "moment";

const initialList = {
  users: [],
  count: 0,
  filter: {
    role: "",
    text: "",
    limit: 10,
    skip: 0,
    status: {},
    country_code: "",
    reported: "All",
    created_at: {
      from: "",
      to: "",
    },
    utc_offset: moment().utcOffset(),
  },
  userHistory:[],
};

const userReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_USER_LIST:
      return {
        ...state,
        users: action.payload.users,
        count: action.payload.count || [],
      };
    case SET_USER_FILTER: {
      const { key, value } = action.payload;
      if (key === "role" || key === "text" || key === "country_code") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value,
            skip: 0,
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }

    case CLEAR_USER_FILTER:
      return {
        ...state,
        filter: initialList.filter,
      };
      case SET_USER_HISTORY:
        return {
          ...state,
         userHistory: action.payload
        };
    default:
      return state;
  }
};

export default userReducer;
