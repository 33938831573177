import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initialLanguageArrayValue,
  initialLanguageFieldValue,
} from "../../../constants";
import { clearLanguage, getLanguage } from "../../../utils/auth";
import { getSingleOffer, updateOffer, getOfferList } from "../actions";
import { fieldsName } from "../constant";
import { getOfferFilters } from "../selectors";

const {
  plan_offer_text,
  offer_text,
  my_offer_text,
  description,
  how_to_use,
  cta_name,
} = fieldsName;

export const useEditOffer = ({ id }) => {
  const dispatch = useDispatch();
  const filter = useSelector(getOfferFilters);

  const [selectedOffer, setSelectedOffer] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editOfferLoading, setEditOfferLoading] = useState(false);

  const getOfferFieldData = async () => {
    const selectedLanguage = getLanguage();
    setEditOfferLoading(true);
    const offer = await dispatch(getSingleOffer(id));

    if (offer) {
      offer.offer_text = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: offer.offer_text || "",
      };
      offer.plan_offer_text = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: offer.plan_offer_text || "",
      };
      offer.my_offer_text = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: offer.my_offer_text || "",
      };

      offer.description = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: offer.description || "",
      };

      offer.cta_name = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: offer.offer?.settings?.cta_name || "",
      };

      offer.how_to_use = {
        ...initialLanguageArrayValue,
        [selectedLanguage]: offer?.how_to_use || [],
      };
      offer.cta_url = offer?.settings?.cta_url;
      offer.dynamic_coupon_code = offer?.is_coupon_code_required;
      offer.no_of_coupon_codes = offer?.per_user_coupon_code_limit;
      setSelectedOffer(offer);
      setEditOfferLoading(false);
      toggleModal();
    }
  };

  const handleLanguageData = async (rest, setFieldValue) => {
    const selectedLanguage = getLanguage();
    setEditOfferLoading(true);
    const offer = await dispatch(getSingleOffer(id));

    if (offer) {
      offer.offer_text = {
        ...rest.values.offer_text,
        [selectedLanguage]:
          rest.values.offer_text[selectedLanguage] || offer?.offer_text || "",
      };

      setFieldValue(offer_text, offer.offer_text);

      offer.plan_offer_text = {
        ...rest.values.plan_offer_text,
        [selectedLanguage]:
          rest.values.plan_offer_text[selectedLanguage] ||
          offer?.plan_offer_text ||
          "",
      };

      setFieldValue(plan_offer_text, offer.plan_offer_text);

      offer.my_offer_text = {
        ...rest.values.my_offer_text,
        [selectedLanguage]:
          rest.values.my_offer_text[selectedLanguage] ||
          offer?.my_offer_text ||
          "",
      };

      setFieldValue(my_offer_text, offer.my_offer_text);

      offer.how_to_use = {
        ...rest.values.how_to_use,
        [selectedLanguage]: rest.values.how_to_use[selectedLanguage]?.length
          ? rest.values.how_to_use[selectedLanguage]
          : offer?.how_to_use || [],
      };

      setFieldValue(how_to_use, offer.how_to_use);

      offer.description = {
        ...rest.values.description,
        [selectedLanguage]:
          rest.values.description[selectedLanguage] || offer?.description || "",
      };

      setFieldValue(description, offer.description);

      offer.cta_name = {
        ...rest.values.cta_name,
        [selectedLanguage]:
          rest.values.cta_name[selectedLanguage] ||
          offer.offer?.settings?.cta_name ||
          "",
      };

      setFieldValue(cta_name, offer.cta_name);

      offer.cta_url = offer?.settings?.cta_url;
      offer.dynamic_coupon_code = offer?.is_coupon_code_required;
      offer.no_of_coupon_codes = offer?.per_user_coupon_code_limit;
      setSelectedOffer(offer);
      setEditOfferLoading(false);
    }
  };

  const handleEditOffer = async (values) => {
    setEditOfferLoading(true);
    try {
      const payload = {
        offer_text: values.offer_text,
        plan_offer_text: values.plan_offer_text,
        my_offer_text: values.my_offer_text,
        description: values.description,
        is_coupon_code_required: values.dynamic_coupon_code,
        amount: values?.amount || 0,
        validity: values?.validity,
        how_to_use: values?.how_to_use,
        per_user_coupon_code_limit: values?.no_of_coupon_codes,
        terms_and_conditions: values?.terms_and_conditions,
        settings: {
          cta_name: values.cta_name,
          cta_url: values.cta_url,
        },
        partner_id: values?.partner_id?.id,
        status: values.status,
      };
      const is_success = await dispatch(updateOffer(payload, id));

      if (is_success) {
        dispatch(getOfferList(filter));
        toggleModal();
      }
      setEditOfferLoading(false);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editOfferLoading,
    handleEditOffer,
    isOpenModal,
    toggleModal,
    selectedOffer,
    getOfferFieldData,
    handleLanguageData,
  };
};
