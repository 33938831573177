import { Tooltip } from "@material-ui/core";
import React from "react";
import AvatarEditor from "react-avatar-editor";
import PropTypes from "prop-types";

import { useImageCrop } from "./hooks";

function Croppier(props) {
  const {
    image,
    onClickCancel,
    onClickSave,
    handleScale,
    height,
    width,
    scale,
    imageRef,
  } = useImageCrop(props);
  return (
    <div className="croppier">
      <AvatarEditor
        scale={parseFloat(scale)}
        ref={imageRef}
        image={image}
        width={width}
        height={height}
        border={50}
        color={[255, 255, 255, 0.6]} // RGBA
        rotate={0}
        borderRadius={0}
        className="croppier__canvas"
      />

      <div>
        <div className="d-flex justify-content-center align-items-center mb-1">
          <>
            <label className="bold-text mt-2 mr-2" htmlFor="zoom">
              Zoom:
            </label>
          </>
          <input
            name="scale"
            className="slider"
            type="range"
            onChange={handleScale}
            min={"1"}
            max="3"
            step="0.01"
            defaultValue="1.5"
          />
        </div>
        <div className="d-flex justify-content-center mb-1">
          <Tooltip title="Save" placement="bottom">
            <i
              className={`fa fa-check icon-btn-action text-success mr-2`}
              onClick={onClickSave}
            ></i>
          </Tooltip>
          <Tooltip title="Cancel" placement="bottom">
            <i
              className={`fa fa-trash icon-btn-action text-danger ml-2`}
              onClick={onClickCancel}
            ></i>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

Croppier.propTypes = {
  index: PropTypes.number.isRequired,
  handleUploadFile: PropTypes.func.isRequired,
  setCurrentBanner: PropTypes.func.isRequired,
  dimensions: PropTypes.object.isRequired,
  context: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
};

export default Croppier;
