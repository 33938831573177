export const getNotices = ({ noticeReducer: { notices = [] } = {} }) => notices;
export const getNoticesFilter = ({ noticeReducer: { filter = {} } = {} }) =>
    filter;
export const getNoticesCount = ({ noticeReducer: { count = 0 } = {} }) => count;
export const getEvents = ({ noticeReducer: { events = 0 } = {} }) => events;
export const getEventsFilter = ({ noticeReducer: { eventFilter = {} } = {} }) =>
    eventFilter;
export const getBlogsFilter = ({ noticeReducer: { blogFilter = {} } = {} }) =>
    blogFilter;
export const getBlogs = ({ noticeReducer: { blogs = [] } = {} }) => blogs;
