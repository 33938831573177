import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelScheduleEvent, getScheduleEventList } from "../actions";
import { getScheduleEventsFilter } from "../selectors";

export const useCancelScheduleEvent = ({ id }) => {
  const dispatch = useDispatch();
  const filter = useSelector(getScheduleEventsFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [cancelScheduleEventLoading, setCancelScheduleEventLoading] = useState(false);

  const handleCancelScheduleEvent = async () => {
    setCancelScheduleEventLoading(true);
    const is_success = await dispatch(cancelScheduleEvent(id));
    setCancelScheduleEventLoading(false);
    if (is_success) {
      dispatch(getScheduleEventList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    cancelScheduleEventLoading,
    handleCancelScheduleEvent,
    isOpenModal,
    toggleModal,
  };
};
