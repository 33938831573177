import React from "react";
import { Button } from "reactstrap";

import PlanForm from "../form/plan-form";
import { useAddPlan } from "./hooks";
import Modals from "../../../components/modal";
import { useUser } from "./../../../common-hooks/user";

const AddPlan = (props) => {
  const { handleAddPlan, isOpenModal, toggleModal, addPlanLoading, getData } =
    useAddPlan(props);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }

  return (
    <div>
      <Button className="btn btn-success btn-right btn-sm" onClick={getData}>
        <i className="fa fa-plus"></i> Add Plan
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PlanForm
              handlePlanForm={handleAddPlan}
              btn="Add"
              isLoading={addPlanLoading}
            />
          }
          title="Add Plan"
        />
      ) : null}
    </div>
  );
};

export default AddPlan;
