import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarProfile from "./TopbarProfile";

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  loggedInUser,
}) => {

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <Link className="topbar__logo" to="/dashboard" />
        </div>
        <div className="topbar__right">
          <TopbarProfile loggedInUser={loggedInUser} />
        </div>
      </div>
    </div>
  );
};
Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
