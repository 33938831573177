import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoriesList,
  getInterestList,
  addBlog,
  getUserList,
  getLabelList,
  getBlogList,
  setBlogFilter,
  clearBlogFilter,
} from "../actions";
import _ from "lodash";
import { clearFiles } from "../../../../../utils/upload-file/actions";
import {
  getCategoriesSelector,
  getInterestsSelector,
  getCommonFilter,
  getCategoryFilter,
  getUserFilter,
  getBlogFilter,
} from "../selectors";
import { useHistory } from "react-router-dom";
import { clearLanguage } from "../../../../../utils/auth";

export const useAddBlog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addBlogLoading, setAddBlogLoading] = useState(false);
  const categories = useSelector(getCategoriesSelector);
  const interests = useSelector(getInterestsSelector);
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const [users, setUsers] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [labels, setLabels] = useState([]);
  const filters = useSelector(getCommonFilter);
  const categoryFilters = useSelector(getCategoryFilter);
  const blogFilter = useSelector(getBlogFilter);
  const userFilter = useSelector(getUserFilter);

  useEffect(() => {
    const getRequiredData = async () => {
      clearLanguage();
      setAddBlogLoading(true);
      dispatch(clearFiles());
      await dispatch(getCategoriesList(categoryFilters));
      await dispatch(getInterestList());
      const { labels } = await dispatch(getLabelList(filters));
      if (labels) {
        setLabels(labels);
      }
      setAddBlogLoading(false);
    };
    getRequiredData();
  }, [dispatch, filters, categoryFilters]);

  useEffect(() => {
    (async () => {
      const data = await dispatch(getUserList(userFilter));
      if (data) {
        setUsers(data);
      }
    })();
  }, [userFilter, dispatch]);

  useEffect(() => {
    (async () => {
      const blogs = await dispatch(getBlogList(blogFilter));
      if (blogs) {
        setBlogs(blogs);
      }
    })();
  }, [dispatch, blogFilter]);

  const handleAddBlog = async (values) => {
    try {
      setAddBlogLoading(true);
      let interestIDS = values.interestIds.map(function (item) {
        return item["id"];
      });
      const payload = {
        blog: {
          title: values.title,
          description: values.description,
          author_id: values.author_id.id,
          tags: values.tags,
          category_id: values.category_id.id,
          is_popular: values.is_popular,
          label_id: values.label_id ? values.label_id?.id : 0,
          time_to_read: parseInt(values.time_to_read),
          status: values.status,
          published_at: values.published_at,
          next_blog_id: values.next_blog_id ? values.next_blog_id?.id : 0,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          meta_keywords: values.meta_keywords,
          slug: values.slug,
        },
        interest_ids: interestIDS,
        files,
      };
      const is_success = await dispatch(addBlog(payload));
      setAddBlogLoading(false);
      if (is_success) {
        dispatch(clearFiles());
        dispatch(clearBlogFilter());
        history.push("/blogs");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setSearchText = useCallback(
    _.debounce((key, value) => {
      dispatch(setBlogFilter({ key, value }));
    }, 600),
    []
  );

  return {
    addBlogLoading,
    handleAddBlog,
    setSearchText,
    categories,
    interests,
    users,
    blogs,
    labels,
  };
};
