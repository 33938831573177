import React from "react";
import { useUnpublishPost } from "./hooks";
import Modals from "../../../../components/modal";
import UnpublishPostForm from "./form";
import ActionButton from "../../../../components/action-button";

function UnpublishPost(props) {
  const { isOpenModal, toggleModal, handleDelete } = useUnpublishPost(props);

  return (
    <>
      <ActionButton
        onClick={toggleModal}
        className={props?.className || ""}
        title={
          !props.isDisabled && props.status === "Published"
            ? "Click to Unpublish"
            : !props.isDisabled
            ? "Click to Publish"
            : ""
        }
        iconClassName={`fa fa-${
          props.status === "Published" ? "times" : "check"
        }-circle ${
          props.status === "Published"
            ? " table__icon-deactive"
            : "text-success"
        } ${props?.isDisabled ? "icon-btn-action-disable" : "icon-btn-action"}`}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <>
              <UnpublishPostForm btn="Confirm" handleSubmit={handleDelete} />
            </>
          }
          title="Unpublish"
        />
      ) : null}
    </>
  );
}

export default UnpublishPost;
