import React from "react";
import { DataLoader } from "../loader";

const DeleteConfirmation = ({ message, handleDelete, isLoading }) => {
  return (
    <div>
      {isLoading ? <DataLoader /> : null}
      {message}
      <div className="categories__modal-delete-btn">
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => handleDelete()}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
