import React from "react";
import { Divider } from "@material-ui/core";
import { Card, CardBody, Col } from "reactstrap";
import styles from "./styles.module.scss";
import { CommentIcon } from "../images";
import { usePost } from "./hooks";
import ProfileImage from "../../../../components/ProfileImage";
import Text from "../../../../components/text";
import Reactions from "../components/reactions";
import Reaction from "../components/reaction";
import Comments from "../components/comment";
import { colors } from "../../../../constants/colors";
import moment from "moment";
import PollComponent from "../components/poll-component";
import BlogCard from "../components/blog-card";
import PostSharedContent from "../components/post-shared-content";
import EventCardStrip from "../components/event-card-strip";
import PostCaption from "../components/post-caption";
import PhotoGrid from "../../../../components/image-grid";
import { useUser } from "../../../../common-hooks/user";
import ConfidenceScore from "../../../../components/confidence-score";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    ss: "%ds",
    m: "a minute",
    mm: "%dm",
    h: "an hour",
    hh: "%dh",
    d: "a day",
    dd: "%dd",
    w: "a week",
    ww: "%dw",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%dyrs",
  },
});

const PostDetails = (props) => {
  const {
    handleAddComment,
    isAddComment,
    isViewComments,
    postDetails,
    handleTotalComments,
    totalComments,
    reactionsCount,
    handleReactSuccess,
    handleRemoveReactSuccess,
    myReaction,
    moreOption,
    reactionsCountList,
    egReaction,
    disabled,
  } = usePost(props);

  const { isAdmin, isCSSpecialist } = useUser();
  const postCreatorIcon = postDetails?.owner?.profile_picture?.url;
  if (!postDetails?.id) return null;
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className={styles.container}>
            <div className={styles.profile_image_container}>
              <div className={styles.user_detail_container}>
                <ProfileImage
                  src={postCreatorIcon || ""}
                  title={postDetails?.owner?.name}
                  height={48}
                  width={48}
                  className={styles.owner_profile_picture}
                />
                <div className={styles.user_detail_content}>
                  <Text size="16px" fontWeight={600}>
                    {postDetails?.owner?.name}
                  </Text>
                  <Text
                    size="12px"
                    fontWeight={400}
                    className={styles.time_format}
                  >
                    {moment(postDetails?.created_at).fromNow()}
                  </Text>
                </div>
              </div>

              {moreOption && <div className={styles.more_options_drawer}></div>}
              <ConfidenceScore data={postDetails} trackableType={"POST"} />
            </div>

            <div className={styles.caption}>
              <PostCaption
                captionText={postDetails?.caption}
                ranges={postDetails?.ranges}
                isPost
              />
            </div>

            {postDetails?.blog?.id && <BlogCard blog={postDetails?.blog} />}

            {postDetails?.id && <PostSharedContent data={postDetails} />}

            {postDetails?.poll?.id && <PhotoGrid images={postDetails?.files} />}
            {postDetails?.poll && postDetails?.poll?.id ? (
              <PollComponent details={postDetails?.poll} />
            ) : null}
            {!postDetails?.poll?.id && (
              <PhotoGrid images={postDetails?.files} />
            )}

            {postDetails?.event_schedules?.length ? (
              <EventCardStrip data={postDetails?.event_schedules[0]} />
            ) : null}

            {postDetails?.courses?.length ? (
              <EventCardStrip type="course" data={postDetails?.courses[0]} />
            ) : null}

            <div className={styles.bottom_section}>
              <div className={styles.bottom_section_up}>
                {postDetails?.id && (
                  <Reactions
                    type="POST"
                    id={postDetails?.id}
                    totalReactions={reactionsCount}
                    trackableType="POST"
                    reactions={reactionsCountList}
                  />
                )}
                {totalComments ? (
                  <div>
                    <Text color={colors.lightBlack}>
                      {totalComments} comments
                    </Text>
                  </div>
                ) : null}
              </div>
              {!isCSSpecialist && <Divider className={styles.divider} />}
              <div className={styles.bottom_section_down}>
                {isAdmin && (
                  <Reaction
                    trackableId={postDetails?.id}
                    trackableType="POST"
                    reactionId={egReaction?.id}
                    reactionName={egReaction?.name}
                    onReactSuccess={handleReactSuccess}
                    onRemoveReactSuccess={handleRemoveReactSuccess}
                    disabled={disabled}
                    isEgReaction
                  />
                )}
                <div className={styles.bottom_section_down_block}>
                  <Reaction
                    trackableId={postDetails?.id}
                    trackableType="POST"
                    reactionId={myReaction?.id}
                    reactionName={myReaction?.name}
                    onReactSuccess={handleReactSuccess}
                    onRemoveReactSuccess={handleRemoveReactSuccess}
                    disabled={disabled}
                  />
                </div>

                {isAdmin && (
                  <div className={styles.bottom_section_down_block}>
                    <div
                      onClick={handleAddComment}
                      className={`${styles.block_clickable} ${
                        disabled && styles.comment_disabled
                      }`}
                    >
                      <img
                        src={CommentIcon}
                        className={styles.bottom_block_icon}
                        alt=""
                      />
                      <Text
                        fontWeight={500}
                        size="15px"
                        color={colors.grayDark2}
                      >
                        Add a comment
                      </Text>
                    </div>
                  </div>
                )}
              </div>
              <Divider className={styles.divider} />
            </div>
          </div>

          {postDetails?.id && (
            <Comments
              viewComment={isViewComments}
              addComment={isAddComment}
              trackableId={postDetails.id}
              trackableType="Post"
              handleTotalComments={handleTotalComments}
              isPostUnpublished={disabled}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default PostDetails;
