import { useState, useEffect } from "react";
import { fieldsName } from "../constant";

const formField = {
    name: "",
    description: "",
    sectionList: [{ id: "", limit: "", position: 0 }],
};

const {
    sectionList,
} = fieldsName;

export const usePageForm = ({
    fieldValues,
    handlePageForm,
    btn,
    isLoading,
    sectionData,
    selectedPageSections,
}) => {
    const [formValues, setFormvalues] = useState(fieldValues || formField);
    useEffect(() => {
        if (btn === 'Add') {
            setFormvalues({
                name: "",
                description: "",
                sectionList: [{ id: "", limit: "", position: 0 }],
            })
        }
        else if (btn === 'Save' && sectionData?.length > 0) {
            let sect = []
            sectionData.filter((section) => {
                if (selectedPageSections.length > 0) {
                    selectedPageSections.find((item) => {
                        if (section.id === item.id) {
                            sect.push({ id: section, limit: item.limit, position: item.position })
                        }
                        return null
                    })
                }
                return null
            })
            sect.sort(function (a, b) { return a.position - b.position })
            setFormvalues((prevFieldValue) => ({
                ...prevFieldValue,
                sectionList: sect,
            }));

        }
    }, [btn, sectionData, selectedPageSections])

    //To use it when form reinitialized
    const handleFormUpdate = (key, value) => {
        setFormvalues((prevFieldValue) => ({
            ...prevFieldValue,
            [key]: value,
        }));
    };

    //to update sectionList object values
    const handleFormUpdateSectionList = (key, value, index) => {
        let newArr = [...formValues.sectionList];
        newArr[index][key] = value
        setFormvalues((prevFieldValue) => ({
            ...prevFieldValue,
            sectionList: newArr,
        }));
    };

    // handle click event of the Remove button
    const handleRemoveClickSectionList = (rest, setFieldValue, index) => {
        const list = [...rest.values.sectionList];
        list.splice(index, 1);
        setFieldValue(sectionList, list);
        handleFormUpdate(sectionList, list);

        // update position
        const data = list;
        data.map((item, index) =>
            data[index]["position"] = index
        )
        data.sort(function (a, b) { return a.position - b.position })
        setFieldValue(sectionList, data);
        handleFormUpdate(sectionList, data)
    }

    // handle click event of the Add button
    const handleAddClickSectionList = (rest, setFieldValue, index) => {
        const list = [...rest?.values?.sectionList, { id: "", limit: "", position: index + 1 }];
        list.sort(function (a, b) { return a.position - b.position })
        setFieldValue(sectionList, list);
        handleFormUpdate(sectionList, list);
    }

    return {
        formValues,
        handleFormUpdate,
        handleFormUpdateSectionList,
        handleRemoveClickSectionList,
        handleAddClickSectionList,
        handlePageForm,
        btn,
        isLoading,
        sectionData,
    };
};