import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPopularKeywordList, setPopularKeywordFilter } from "./actions";
import { getPopularKeywords, getPopularKeywordFilter, getPopularKeywordsCount } from "./selectors";

export const usePopularKeywords = () => {
  const dispatch = useDispatch();
  const popularKeywords = useSelector(getPopularKeywords);
  const filters = useSelector(getPopularKeywordFilter);
  const popularKeywordCount = useSelector(getPopularKeywordsCount);
  const [loadingPopularKeywords, setLoadingPopularKeywords] = useState(false);

  useEffect(() => {
    const fetchPopularKeywords = async () => {
      setLoadingPopularKeywords(true);
      await dispatch(getPopularKeywordList(filters));
      setLoadingPopularKeywords(false);
    };
    fetchPopularKeywords();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setPopularKeywordFilter({ key, value }))
  }

  return {
    setFilter,
    loadingPopularKeywords,
    filters,
    popularKeywords,
    popularKeywordCount,
  };
};
