import * as Yup from "yup";
import { validationRegex } from "../../../utils/validation";

export const loginFormSchema = Yup.object().shape({
  mobileNumber: Yup.mixed()
    .required("Mobile Number is required")
    .test({
      test: (value) => {
        if (!value) {
          return false;
        }
        return validationRegex.mobile.test(value);
      },
      message: "Invalid Mobile Number",
    }),
});

export const otpFormSchema = Yup.object().shape({
  otp: Yup.mixed()
    .required("OTP is required")
    .test({
      test: (value) => {
        if (!value) {
          return false;
        }
        return value.length === 6;
      },
      message: "Enter 6 digit OTP",
    }),
});
