import React from "react";
import { Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";

const ActionButton = ({
  onClick,
  className,
  title,
  placement,
  iconClassName,
  access,
}) => {
  if (!access) {
    return null;
  }
  return (
    <Tooltip title={title} placement={placement} className={className}>
      <i className={iconClassName} onClick={onClick}></i>
    </Tooltip>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.string.isRequired,
  iconClassName: PropTypes.string.isRequired,
  access: PropTypes.bool,
};

ActionButton.defaultProps = {
  onClick: () => {},
  className: "",
  placement: "bottom",
  title: "View",
  access: true,
};
export default ActionButton;
