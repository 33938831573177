import React from "react";
import Modals from "../../../../../components/modal";
import DeleteConfirmation from "../../../../../components/delete-confirmation";
import { useDeletePopularKeyword } from "./hooks";
import ActionButton from "../../../../../components/action-button";

const DeletePopularKeyword = ({ popularKeyword }) => {
  const {
    handleDeletePopularKeyword,
    isOpenModal,
    toggleModal,
    deletePopularKeywordLoading,
  } = useDeletePopularKeyword(popularKeyword);

  return (
    <>
      <ActionButton
        className="pull-right"
        title="Delete"
        iconClassName={`fa fa-trash icon-btn-action text-danger `}
        onClick={toggleModal}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeletePopularKeyword}
              isLoading={deletePopularKeywordLoading}
            />
          }
          title="Delete Popular Keyword"
        />
      ) : null}
    </>
  );
};

export default DeletePopularKeyword;
