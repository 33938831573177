import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";

import { faqStatus, fieldsName } from "../constant";
import { DataLoader } from "../../../components/loader";
import { faqFormSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";
import { initialLanguageFieldValue, languages } from "../../../constants";
import { Tooltip } from "@material-ui/core";
import { setLanguage } from "../../../utils/auth";

const FaqForm = ({
  fieldValues,
  handleFaqForm,
  btn,
  isLoading,
  categoryList,
  handleLangugeData,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const { question, answer, status, category, language } = fieldsName;

  const handleLanguage = (rest, key, value, setFieldValue) => {
    let val = { ...rest.values[key], [rest.values.language]: value };
    setFieldValue(key, val);
  };

  return (
    <Formik
      validateOnMount
      validationSchema={faqFormSchema}
      initialValues={{
        question: fieldValues?.question || initialLanguageFieldValue,
        answer: fieldValues?.answer || initialLanguageFieldValue,
        status: fieldValues?.status || "",
        category: fieldValues?.category_id || "",
        language: fieldValues?.language || "en",
      }}
      onSubmit={handleFaqForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={language}
                placeholder="Language"
                label="Language"
                select
                value={rest.values.language}
                onChange={(event) => {
                  const { name, value } = event.target;
                  handleFormValueChange(rest, language);
                  setFieldValue(name, value);
                  if (!rest.values.question[value]) {
                    setFieldValue(question, {
                      ...rest.values.question,
                      [value]: "",
                    });
                  }

                  if (!rest.values.answer[value]) {
                    setFieldValue(answer, {
                      ...rest.values.answer,
                      [value]: "",
                    });
                  }

                  if (btn === "Save") {
                    setLanguage(value);
                    handleLangugeData(rest, setFieldValue);
                  }
                }}
                onFocus={() => handleFocusField(language)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.language}
              >
                {languages.map((language) => (
                  <MenuItem
                    key={language.id}
                    className="material-form__option"
                    value={language.value}
                  >
                    {language.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === language ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={language}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={category}
                placeholder="Category"
                label="Category"
                select
                value={rest.values.category}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setFieldValue(name, value);
                  handleFormValueChange(rest, category);
                }}
                onFocus={() => handleFocusField(category)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.category}
              >
                {categoryList?.map((category) => (
                  <MenuItem
                    key={category.id}
                    className="material-form__option"
                    value={category.id}
                  >
                    {category.title}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === category ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={category}
                />
              )}
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={question}
                  placeholder="Question"
                  label="Question"
                  type="text"
                  multiline
                  value={rest.values.question[rest.values.language]}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleFormValueChange(rest, name);
                    handleLanguage(rest, name, value, setFieldValue);
                  }}
                  onFocus={() => handleFocusField(question)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === question ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={question}
                  />
                )}
              </div>
              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={answer}
                  placeholder="Answer"
                  label="Answer"
                  type="text"
                  multiline
                  value={rest.values.answer[rest.values.language]}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleFormValueChange(rest, name);
                    handleLanguage(rest, name, value, setFieldValue);
                  }}
                  onFocus={() => handleFocusField(answer)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === answer ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={answer}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={handleFormValueChange(rest, status)}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {faqStatus.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>
            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default FaqForm;
