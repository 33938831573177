/* eslint-disable */

import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addVote } from "../../action";

export const usePoll = (props) => {
  const { details } = props;
  const [myVote, setMyVote] = useState({});
  const [totalVotes, setTotalVotes] = useState(0);
  const [isClosed, setIsClosed] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const vote = details?.poll_choices?.find((poll) => poll?.voted_by_me);
    setMyVote(vote);
    setTotalVotes(details?.vote_count);
    setData(details);
  }, [details]);

  useEffect(() => {
    let time = details?.created_at;
    let diff = Math.floor(
      moment.duration(moment(moment(new Date()).diff(moment(time)))).asHours()
    );

    if (diff > 24) {
      setIsClosed(true);
      setTimeLeft("Poll Closed");
    } else {
      setTimeLeft(`${24 - diff} hours left`);
    }

    return () => setIsClosed(false);
  }, [details]);

  const handleVote = async (poll) => {
    if (!myVote?.id) {
      const is_success = await dispatch(addVote(details?.id, poll?.id));
      if (is_success) {
        if (!myVote) {
          setTotalVotes((prev) => prev + 1);
        }
        setMyVote({ ...poll, vote_count: poll?.vote_count + 1 });
        let arr = [];
        data?.poll_choices?.forEach((data) => {
          if (poll.id === data?.id) {
            data.vote_count += 1;
          }
          arr.push(data);
        });

        setData((prev) => ({
          ...prev,
          poll_choices: arr,
        }));
      }
    }
  };

  return { details, handleVote, myVote, totalVotes, isClosed, timeLeft, data };
};
