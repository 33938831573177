import * as Yup from "yup";
import { checkFields } from "../../../utils/validation";

const requiredFields = ["title", "description"];

export const planFormSchema = Yup.object().shape({
  title: Yup.mixed().test(
    "Title required",
    "Enter valid title for all languages",
    function () {
      if (!this?.parent?.title?.en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "title");
    }
  ),
  description: Yup.mixed().test(
    "Description required",
    "Enter valid description for all languages",
    function () {
      if (!this?.parent?.description?.en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "description");
    }
  ),
  partner_offer_text: Yup.string(),
});
