import React from "react";
import { Route, Switch } from "react-router-dom";
import Reports from "../../../reports-data";
import Posts from "../../../social/posts";
import PostDetails from "../../../social/posts/post-details/index";

export default () => (
  <Switch>
    <Route path="/posts" component={Posts} />
    <Route exact path="/post/:post_id" component={PostDetails} />
    <Route exact path="/post/:id/reports" component={Reports} />
  </Switch>
);
