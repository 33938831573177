import { BEARER_TOKEN, LANGUAGE } from "../constants";

export const getBearerToken = () => {
  return localStorage.getItem(BEARER_TOKEN);
};

export const setBearerToken = (token) => {
  return localStorage.setItem(BEARER_TOKEN, `Bearer ${token}`);
};

export const removeBearerToken = () => {
  return localStorage.removeItem(BEARER_TOKEN);
};

export const setUserData = (user) => {
  return localStorage.setItem("userData", JSON.stringify(user));
};

export const getUserData = () => {
  return localStorage.getItem("userData");
};

export const logOut = () => {
  return (localStorage.clear() (window.location = "/log_in"));
};

export const isAuthenticated = () => {
  const token = getBearerToken();
  if (token) {
    return true;
  } else {
    // browserHistory.push('/log_in');
  }
};

export const setLanguage = (language) => {
  return localStorage.setItem(LANGUAGE, language);
};

export const getLanguage = () => {
  return localStorage.getItem(LANGUAGE);
};

export const clearLanguage = () => {
  return localStorage.removeItem(LANGUAGE);
};
