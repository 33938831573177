import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import Accordion from "../../../../components/Accordion";

const UserIntrests = ({ data }) => (
  <Col md={12} lg={12} xl={12}>
    <Accordion
      className="user__tabs_collapse"
      title={
        <p className="profile__current-tasks-title">
          interests <span>({data?.length})</span>
        </p>
      }
    >
      <Card>
        <CardBody className="profile__card">
          <div className="profile__current-tasks">
            {data.length > 0
              ? data.map((interest, index) => {
                  return (
                    <div key={index} className="profile__current-task">
                      {interest.title}
                    </div>
                  );
                })
              : null}
          </div>
        </CardBody>
      </Card>
    </Accordion>
  </Col>
);

export default UserIntrests;
