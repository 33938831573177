import { useState } from "react";
import { useDispatch } from "react-redux";
import { getConfigs, updateConfig } from "../actions";

export const useEditForm = ({ id }) => {
  const dispatch = useDispatch();
  const [editConfigLoading, setEditConfigLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleForm = async (values) => {
    const payload = {
      ...values,
      key: values.key?.toUpperCase(),
    };

    setEditConfigLoading(true);

    const is_success = await dispatch(updateConfig(payload, id));
    if (is_success) {
      dispatch(getConfigs());
      toggleModal();
    }

    setEditConfigLoading(false);
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    handleForm,
    isOpenModal,
    editConfigLoading,
    toggleModal,
  };
};
