import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Courses from '../../../courses';
import AddCourse from '../../../courses/add-course';
import EditCourse from '../../../courses/edit-course';
import ScheduleList from '../../../courses/schedule-list';
export default () => (
  <Switch>
    <Route path="/courses" component={Courses} />
    <Route path="/add-course" component={AddCourse} />
    <Route path="/edit-course/:course_id" component={EditCourse} />
    <Route path="/course/:course_id" component={ScheduleList} />
  </Switch>
);
