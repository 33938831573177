import * as Yup from "yup";
import { checkFields } from "../../../utils/validation";

const requiredFields = ["question", "answer"];

export const faqFormSchema = Yup.object().shape({
  question: Yup.mixed().test(
    "English language required",
    "Enter valid question for all languages",
    function () {
      const {
        question: { en },
      } = this.parent;

      if (!en) {
        return false;
      }

      return checkFields(this.parent, requiredFields, "question");
    }
  ),
  answer: Yup.mixed().test(
    "English language required",
    "Enter valid answer for all languages",
    function () {
      const {
        answer: { en },
      } = this.parent;

      if (!en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "answer");
    }
  ),
  status: Yup.string().required("Status is required"),
  category: Yup.string().required("Category is required"),
});
