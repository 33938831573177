import { useState } from "react";
import { useDispatch } from "react-redux";
import * as moment from "moment";
import { downloadPostStatistics } from "../action";

export const useExport = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dateError, setDateError] = useState("");

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    setDateError("");
  };

  const downloadPostStatisticsData = async (values) => {
    const start = moment(values.from, "DD-MM-YYYY");
    const end = moment(values.to, "DD-MM-YYYY");
    const diff = end.diff(start, "days") + 1;

    if (diff > 7) {
      setDateError("You can download data upto 7 days");
    } else {
      setIsLoading(true);
      const filters = {
        utc_offset: moment().utcOffset(),
        created_at: {
          from: moment(values.from).format("DD/MM/YYYY"),
          to: moment(values.to).format("DD/MM/YYYY"),
        },
      };

      const exportData = await dispatch(downloadPostStatistics(filters));
      if (exportData) {
        toggleModal();
      }
      setIsLoading(false);
    }
  };

  return {
    isOpenModal,
    toggleModal,
    isLoading,
    downloadPostStatisticsData,
    dateError,
    setDateError,
  };
};
