import React from "react";
import DataTable from "../../components/table";
import { useInterest } from "./hooks";
import AddInterest from "./add-interest";
import EditInterest from "./edit-interest";
import DeleteInterest from "./delete-interest";
import { columns } from "./constant";

const Interests = () => {
  const { interests, loadingInterest, setFilter, interestCount, filters } =
    useInterest();

  const configureTableData = () => {
    try {
      let results = interests.map((interest) => {
        let newInterests = Object.assign({}, interest);
        newInterests["action"] = (
          <div className="categories__list-action">
            <EditInterest interest={interest} />
            <DeleteInterest interest={interest} />
          </div>
        );
        return newInterests;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DataTable
      title="Interests"
      addBtn={<AddInterest />}
      CreateTableData={configureTableData}
      withFilter={false}
      handleSetFilter={setFilter}
      count={interestCount}
      skip={filters?.skip}
      isLoading={loadingInterest}
    />
  );
};

export default Interests;
