import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useUserHistory = () => {
    const [isShowUserHistory, setIsShowUserHistory] = useState(false);
    const data = useSelector(state => state?.userReducer?.userHistory)
    
    useEffect(() => {
        if (data?.length > 0) {
            setIsShowUserHistory(true);
        } else {
            setIsShowUserHistory(false);
        }
    },[data])

    return {isShowUserHistory, setIsShowUserHistory};
}