import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMetaData, getMetaData } from "../actions";
import { getMetaDataFilter } from "../selectors";

export const useDeleteMetaData = ({ id }) => {
  const dispatch = useDispatch();
  const filters = useSelector(getMetaDataFilter);

  const [deleteMetaDataLoading, setDeleteMetaDataLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleDeleteMetaData = async () => {
    setDeleteMetaDataLoading(true);
    const is_success = await dispatch(deleteMetaData(id));
    setDeleteMetaDataLoading(false);
    if (is_success) {
      dispatch(getMetaData(filters));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteMetaDataLoading,
    handleDeleteMetaData,
    isOpenModal,
    toggleModal,
  };
};
