/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import ReactPlayer from "react-player";
import { fieldsName, postByList, postStatus, postType } from "../constant";
import { DataLoader } from "../../../../components/loader";
import { useUploadFiles } from "../../../../utils/upload-file/hooks";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../utils/form";
import TextfieldChips from "../../../../components/text-field-chips";
import {
  fileContext,
  supportedPostImageFormat,
  supportedPostVideoFormat,
} from "../../../../constants";
import ProgressChip from "../../../../components/progress-chip";
import {
  getBlogList,
  getCourseList,
  getHashtags,
  getScheduleEventList,
  getUserList,
  setBlogFilter,
  setEventFilter,
  setCourseFilter,
} from "../action";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getBlogs,
  getBlogsFilters,
  getCourses,
  getCoursesFilters,
  getEvents,
  getEventsFilters,
} from "../selectors";
import { postFormSchema } from "./validation-schema";
import DateTimePicker from "../../../../components/date-picker";
import moment from "moment";
// import CaptionInput from "../components/caption-input";
import styles from "./styles.module.scss";
import CaptionInput from "../components/caption-input-multiline";
import { useUser } from "../../../../common-hooks/user";

const formField = {
  caption: "",
  poll_topic: "",
  poll_options: [],
  status: "",
  events: null,
  event_tagged: null,
  course_tagged: null,
  blog: null,
  type: "",
  youtube_links: [],
  files: [],
  ranges: [],
  is_blog: false,
  is_event_or_course: false,
  is_poll: false,
  date: new Date(),
};

const PostForm = ({
  fieldValues,
  handlePostForm,
  btn,
  isLoading,
  isEdit,
  ...props
}) => {
  const {
    files: postFiles,
    handlePostFiles,
    handleDeleteAFile,
    isFileUploadData,
    clearAllFiles,
  } = useUploadFiles();

  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    caption,
    event_tagged,
    files,
    poll_options,
    status,
    poll_topic,
    type,
    blog,
    ranges,
    post_by,
    course_tagged,
  } = fieldsName;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const eventFilter = useSelector(getEventsFilters);
  const eventSchedules = useSelector(getEvents);
  const blogsFilter = useSelector(getBlogsFilters);
  const blogs = useSelector(getBlogs);
  const courses = useSelector(getCourses);
  const course_filter = useSelector(getCoursesFilters);
  const [disabled, setDisabled] = useState(false);
  const { isAdmin } = useUser();

  const dispatch = useDispatch();

  const handleFormUpdate = (event, setFieldValue) => {
    let { name, value } = event.target;

    if (name === type) {
      setFormvalues((prev) => ({
        ...prev,
        [type]: event.target.value,
        [event_tagged]: null,
        [course_tagged]: null,
      }));

      event.target.value === "event" && clearAllFiles();
    } else {
      setFormvalues((prevFieldValue) => ({
        ...prevFieldValue,
        [name]: value,
      }));
    }

    setFieldValue(name, value);
  };

  const handleFormArrayUpdate = async (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));
  };

  const handleMentions = (mentions, newPlainTextValue) => {
    setFormvalues((prev) => ({
      ...prev,
      // caption: newPlainTextValue,
      ranges: mentions,
      plainTextValue: newPlainTextValue,
    }));
  };

  const handlePreview = () => {
    setDisabled(!disabled);
  };

  useEffect(() => {
    (async () => {
      await dispatch(getScheduleEventList(eventFilter));
    })();
  }, [eventFilter, dispatch]);

  useEffect(() => {
    (async () => {
      await dispatch(getBlogList(blogsFilter));
    })();
  }, [blogsFilter, dispatch]);

  useEffect(() => {
    (async () => {
      await dispatch(getCourseList(course_filter));
    })();
  }, [course_filter, dispatch]);

  const setEventsFilters = useCallback(
    _.debounce((key, value) => {
      dispatch(setEventFilter({ key, value }));
    }, 600),
    []
  );

  const setBlogsFilter = useCallback(
    _.debounce((key, value) => {
      dispatch(setBlogFilter({ key, value }));
    }, 600),
    []
  );

  const setCoursesFilter = useCallback(
    _.debounce((key, value) => {
      dispatch(setCourseFilter({ key, value }));
    }, 600),
    []
  );

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={postFormSchema}
      initialValues={{
        caption: formValues?.caption || "",
        poll_options: formValues?.poll_options || [],
        status: formValues?.status || "",
        events: formValues?.events || null,
        post_by: formValues?.post_by || "Me",
        event_tagged: formValues?.event_tagged || null,
        course_tagged: formValues?.course_tagged || null,
        blog: formValues?.blog || null,
        type: formValues?.type || "",
        youtube_links: formValues?.youtube_links || [],
        poll_topic: formValues?.poll_topic || "",
        ranges: formValues?.ranges || [],
        files: postFiles,
        plainTextValue: formValues?.plainTextValue || "",
        is_blog: formValues?.is_blog || false,
        is_event_or_course: formValues?.is_event_or_course || false,
        is_poll: formValues?.is_poll || false,
        date: (formValues?.date && formValues?.date) || "",
      }}
      onSubmit={handlePostForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            {postFiles?.length ||
            rest.values.is_poll ||
            rest.values?.is_event_or_course ? null : (
              <div
                onClick={(e) => {
                  setFormvalues((prev) => ({
                    ...prev,
                    ["is_blog"]: true,
                  }));
                }}
                name="is_blog"
                role="button"
                className="btn btn-outline-success  btn-sm mt-4"
              >
                Add Blog
              </div>
            )}

            {rest.values.is_blog && (
              <img
                height={20}
                width={20}
                src={"/img/close-filled.svg"}
                alt="close"
                className={"img_close_icon w-auto cursor_pointer"}
                onClick={(e) => {
                  setFormvalues((prev) => ({
                    ...formField,
                    ["is_blog"]: false,
                    ["post_by"]: isEdit ? prev?.post_by : "",
                    [blog]: null,
                    [status]: isEdit ? prev?.status : "",
                  }));
                }}
              />
            )}
            {rest.values?.is_blog || rest.values.is_event_or_course ? null : (
              <div
                onClick={(e) => {
                  setFormvalues((prev) => ({
                    ...prev,
                    ["is_poll"]: true,
                  }));
                }}
                name="is_poll"
                role="button"
                className="btn btn-outline-success  btn-sm mt-4"
              >
                Create Poll
              </div>
            )}

            {rest.values.is_poll && (
              <img
                height={20}
                width={20}
                src={"/img/close-filled.svg"}
                alt="close"
                className={"img_close_icon w-auto cursor_pointer"}
                onClick={(e) => {
                  setFormvalues((prev) => ({
                    ...formField,
                    ["is_poll"]: false,
                    ["post_by"]: isEdit ? prev?.post_by : "",
                    [status]: isEdit ? prev?.status : "",
                    [poll_options]: [],
                    [poll_topic]: "",
                  }));
                }}
              />
            )}

            {rest.values.is_poll || rest.values?.is_blog ? null : (
              <div
                onClick={(e) => {
                  setFormvalues((prev) => ({
                    ...prev,
                    ["is_event_or_course"]: true,
                  }));
                }}
                name="is_event_or_course"
                role="button"
                className="btn btn-outline-success  btn-sm mt-4"
              >
                Add Session/Course
              </div>
            )}

            {rest.values.is_event_or_course && (
              <img
                height={20}
                width={20}
                src={"/img/close-filled.svg"}
                alt="close"
                className={"img_close_icon w-auto cursor_pointer"}
                onClick={(e) => {
                  setFormvalues((prev) => ({
                    ...formField,
                    ["is_event_or_course"]: false,
                    ["post_by"]: isEdit ? prev?.post_by : "",
                    [status]: isEdit ? prev?.status : "",
                  }));
                }}
              />
            )}
            {rest.values.is_event_or_course && (
              <>
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={type}
                    placeholder="Type"
                    label="Type"
                    disabled={disabled}
                    select
                    value={rest.values.type}
                    onChange={(event) => {
                      handleFormValueChange(rest, type);
                      handleFormUpdate(event, setFieldValue);
                    }}
                    onFocus={() => handleFocusField(type)}
                    onBlur={() => handleBlurField()}
                    defaultValue={rest.values.type}
                  >
                    {postType.map((type) => (
                      <MenuItem
                        key={type.id}
                        className="material-form__option"
                        value={type.value}
                      >
                        {type.displayText}
                      </MenuItem>
                    ))}
                  </TextField>
                  {focusField === type ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={type}
                    />
                  )}
                </div>

                {rest.values?.type && (
                  <div className="materila-form_inline-child">
                    <DateTimePicker
                      selectedDate={rest.values.date}
                      handleChange={(value) => {
                        setFieldValue(date, value);
                        rest.values.type === "event"
                          ? setEventsFilters(
                              "event_date",
                              moment(value).format("DD-MM-YYYY")
                            )
                          : setCoursesFilter(
                              "commence_after",
                              moment(value).format("DD/MM/YYYY @ hh:mm A ")
                            );
                        setFormvalues((prevFieldValue) => ({
                          ...prevFieldValue,
                          date: value,
                        }));
                      }}
                      id="date"
                      name={"date"}
                      label="Date"
                      customInput={
                        <TextField
                          className="material-form__field"
                          id="date"
                          label="Date"
                          autoComplete="off"
                        />
                      }
                    />
                  </div>
                )}

                {rest.values.date && rest.values.type === "event" && (
                  <div>
                    <Autocomplete
                      options={eventSchedules}
                      disabled={disabled}
                      getOptionLabel={(event) =>
                        event.title ? event.title : ""
                      }
                      getOptionSelected={(event) =>
                        event.title ? event.title : ""
                      }
                      value={rest.values.event_tagged}
                      onChange={(event, value) => {
                        setFieldValue(event_tagged, value);
                        setFormvalues((prev) => ({
                          ...prev,
                          [event_tagged]: value,
                        }));
                      }}
                      onFocus={() => handleFocusField(event_tagged)}
                      onBlur={() => {
                        handleBlurField();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="material-form__field"
                          name={event_tagged}
                          placeholder="Tag Event"
                          label="Tag Event"
                          variant="standard"
                        />
                      )}
                    />
                    {focusField === event_tagged ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={event_tagged}
                      />
                    )}
                  </div>
                )}

                {rest.values.date && rest.values.type === "course" && (
                  <div>
                    <Autocomplete
                      options={courses}
                      disabled={disabled}
                      getOptionLabel={(course) =>
                        course.title ? course.title : ""
                      }
                      getOptionSelected={(course) =>
                        course.title ? course.title : ""
                      }
                      value={rest.values.course_tagged}
                      onChange={(event, value) => {
                        setFieldValue(course_tagged, value);
                        setFormvalues((prev) => ({
                          ...prev,
                          [course_tagged]: value,
                        }));
                      }}
                      onFocus={() => handleFocusField(course_tagged)}
                      onBlur={() => {
                        handleBlurField();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="material-form__field"
                          name={course_tagged}
                          placeholder="Tag Course"
                          label="Tag Course"
                          variant="standard"
                        />
                      )}
                    />
                    {focusField === course_tagged ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={course_tagged}
                      />
                    )}
                  </div>
                )}
              </>
            )}

            <div className="material-form_inline-fields">
              <div
                style={{ width: "100%" }}
                className="materila-form_inline-child"
              >
                <div className={styles.comment_input_div}>
                  <div className={styles.input_field}>
                    <CaptionInput
                      placeholder="Caption"
                      caption={fieldValues?.caption}
                      onCaptionChange={handleMentions}
                      mentions={fieldValues?.ranges}
                      isAttendee={rest.values.type === "event" ? true : false}
                    />
                  </div>
                </div>

                {focusField === caption ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={caption}
                  />
                )}
              </div>
            </div>

            {rest.values.is_poll && (
              <>
                <div className="material-form_inline-fields">
                  <div className="materila-form_inline-child">
                    <TextField
                      className="material-form__field"
                      name={poll_topic}
                      disabled={disabled}
                      placeholder="Poll Topic"
                      label="Poll Topic"
                      type="text"
                      value={rest.values.poll_topic}
                      onChange={(event) => {
                        handleFormValueChange(rest, poll_topic);
                        handleFormUpdate(event, setFieldValue);
                      }}
                      onFocus={() => handleFocusField(poll_topic)}
                      onBlur={() => handleBlurField()}
                    />
                    {focusField === poll_topic ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={poll_topic}
                      />
                    )}
                  </div>
                </div>
                <div className="material-form_inline-fields d-flex">
                  <div className="materila-form_inline-child">
                    <TextfieldChips
                      initialChips={rest.values.poll_options}
                      disabled={disabled}
                      name="Poll Options"
                      maxLength={4}
                      maxTextLength={30}
                      onDataChange={(data) => {
                        setFieldValue(poll_options, data);
                        handleFormArrayUpdate(poll_options, data);
                      }}
                    />
                    {focusField === poll_options ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error mt-2">{msg}</div>
                        )}
                        name={poll_options}
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            {rest.values.is_blog && (
              <div>
                <Autocomplete
                  options={blogs}
                  getOptionLabel={(blog) => (blog.title ? blog.title : "")}
                  getOptionSelected={(blog) => (blog.title ? blog.title : "")}
                  value={rest.values.blog}
                  disabled={disabled}
                  onChange={(event, value) => {
                    setFieldValue(blog, value);
                    setFormvalues((prev) => ({
                      ...prev,
                      blog: value,
                    }));
                    !value && setBlogsFilter("title", "");
                  }}
                  onFocus={() => handleFocusField(blog)}
                  onBlur={() => {
                    handleBlurField();
                    !rest.values.blog && setBlogsFilter("title", "");
                    //rest.handleBlur();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      onChange={(e) => setBlogsFilter("title", e.target.value)}
                      name={blog}
                      placeholder="Select Blog"
                      label="Select Blog"
                      variant="standard"
                    />
                  )}
                />
                {focusField === blog ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={blog}
                  />
                )}
              </div>
            )}

            {isAdmin && (
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={post_by}
                  placeholder="Post By"
                  label="Post By"
                  disabled={isEdit || disabled}
                  select
                  value={rest.values.post_by}
                  onChange={(event) => {
                    handleFormValueChange(rest, post_by);
                    handleFormUpdate(event, setFieldValue);
                  }}
                  onFocus={() => handleFocusField(post_by)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.post_by}
                >
                  {postByList.map((post_by) => (
                    <MenuItem
                      key={post_by.id}
                      className="material-form__option"
                      value={post_by.value}
                    >
                      {post_by.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === post_by ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={post_by}
                  />
                )}
              </div>
            )}

            {!isEdit && (
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={status}
                  placeholder="Status"
                  label="Status"
                  disabled={disabled}
                  select
                  value={rest.values.status}
                  onChange={(event) => {
                    handleFormValueChange(rest, status);
                    handleFormUpdate(event, setFieldValue);
                  }}
                  onFocus={() => handleFocusField(status)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.status}
                >
                  {postStatus.map((status) => (
                    <MenuItem
                      key={status.id}
                      className="material-form__option"
                      value={status.value}
                    >
                      {status.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === status ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={status}
                  />
                )}
              </div>
            )}

            {rest.values.is_blog || rest.values.type === "event" ? null : (
              <div className="form__form-group-field form__margin-bottom">
                <div className="form__form-group-input-wrap">
                  <div className="form__form-group-file">
                    <label
                      className="border border-white pl-0 bg-white"
                      htmlFor="files"
                    >
                      <div className="btn btn-outline-success btn-sm mt-2">
                        Add Photo / Video
                      </div>
                    </label>

                    {isFileUploadData.length ? (
                      <DataLoader />
                    ) : (
                      <span>
                        {postFiles.filter(
                          (file) =>
                            file.file_context === fileContext.POST_IMAGE ||
                            file.file_context === fileContext.POST_VIDEO
                        ).length ? (
                          <div className={"discussion_images_container"}>
                            {postFiles
                              .filter(
                                (file) =>
                                  file.file_context ===
                                    fileContext.POST_IMAGE ||
                                  file.file_context === fileContext.POST_VIDEO
                              )
                              .map((img, i) => (
                                <div
                                  className={"img_container"}
                                  key={img?.uuid_file_name}
                                >
                                  {img.file_context ===
                                  fileContext.POST_VIDEO ? (
                                    <ReactPlayer
                                      width={93}
                                      height={62}
                                      url={
                                        img?.ts_url ||
                                        img?.url ||
                                        img.public_url
                                      }
                                      playing={true}
                                      muted={true}
                                      loop={true}
                                      style={{ display: "inline-block" }}
                                    />
                                  ) : (
                                    <img src={img?.url || img.public_url} />
                                  )}
                                  {!disabled && (
                                    <img
                                      src={"/img/close-filled.svg"}
                                      alt="close"
                                      id={i}
                                      className={"img_close_icon"}
                                      onClick={(e) => {
                                        handleDeleteAFile(img);
                                      }}
                                    />
                                  )}
                                </div>
                              ))}
                          </div>
                        ) : null}
                      </span>
                    )}

                    <input
                      accept={[
                        ...supportedPostImageFormat,
                        supportedPostVideoFormat,
                      ].join(", ")}
                      multiple
                      disabled={disabled}
                      onBlur={rest.handleBlur}
                      type="file"
                      name={files}
                      id="files"
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      onChange={(event) => {
                        handlePostFiles(event.target.files);
                        setFieldValue(
                          postFiles,
                          postFiles.filter(
                            (file) => file.file_context === fileContext.POST
                          )
                        );
                      }}
                    />
                  </div>
                  {rest.errors.files && (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={files}
                    />
                  )}
                </div>
              </div>
            )}

            <button
              type="submit"
              className="btn btn-success btn-right btn-sm mt-4"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default PostForm;
