import * as Yup from "yup";
import { VOUCHER_LIMIT } from "../constant";

export const voucherFormSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  consumption_type: Yup.string().required("Consumption Type is required"),
  count: Yup.mixed()
    .required("Count is required")
    .test({
      test: function (value) {
        let message = "";
        let error = false;
        if (!value) {
          message = "Minimum value is 1";
          error = true;
        }

        const { voucher_type, count, consumption_type, pattern } = this.parent;

        if (parseInt(count) > VOUCHER_LIMIT) {
          error = true;
          message = `You cannot create more than ${VOUCHER_LIMIT.toLocaleString()} vouchers`;
        } else if (consumption_type === "Bulk" && value <= 1) {
          error = true;
          message = "Count must be greater than 1";
        } else {
          const patternLimit = pattern
            ? pattern.match(/#/g)
              ? pattern.match(/#/g).length
              : 0
            : "";
          if (
            voucher_type === "Numeric" &&
            patternLimit &&
            parseInt(count) > Math.pow(10, patternLimit)
          ) {
            message = `You can create upto ${Math.pow(
              10,
              patternLimit
            ).toLocaleString()} vouchers with numeric type`;
            error = true;
          }

          if (
            voucher_type === "Alphabetic" &&
            patternLimit &&
            parseInt(count) > Math.pow(26, patternLimit)
          ) {
            message = `You can create upto ${Math.pow(
              26,
              patternLimit
            ).toLocaleString()} vouchers with alphabetic type`;
            error = true;
          }

          if (
            voucher_type === "Alphanumeric" &&
            patternLimit &&
            parseInt(count) > Math.pow(36, patternLimit)
          ) {
            message = `You can create upto ${Math.pow(
              36,
              patternLimit
            ).toLocaleString()} vouchers with alphanumeric type`;
            error = true;
          }
        }

        if (error) {
          return this.createError({
            message,
            path: "count", // Field name
          });
        }

        return true;
      },
    }),

  purpose: Yup.string().required("Purpose is required"),
  event: Yup.string().when("purpose", {
    is: "Event",
    then: Yup.string().required("Event is required"),
  }),
  category: Yup.string().required("Category is required"),
  sub_category: Yup.string().required("Sub Category is required"),
  entity: Yup.string().required("Entity is required"),
  entity_name: Yup.string().when("entity", {
    is: (val) =>
      val === "Organization" || val === "Housing Society" ? true : false,
    then: Yup.string().required("Entity name is required"),
  }),

  valid_from: Yup.string().when("validity", {
    is: true,
    then: Yup.string().required("Valid from is required"),
  }),

  valid_till: Yup.string().when("validity", {
    is: true,
    then: Yup.string().required("Valid till is required"),
  }),

  usage: Yup.string().required("Usage Type is required"),
  usage_count: Yup.number().when("usage", {
    is: "Many",
    then: Yup.number()
      .min(2, "Minimum value should be 2")
      .required("Usage Count is required"),
  }),
  voucher_prefix: Yup.string().max(5, "Maximum 5 characters are allowed."),
  voucher_postfix: Yup.string().max(5, "Maximum 5 characters are allowed."),
  has_discount: Yup.boolean().required("Has Discount is required"),

  discount_type: Yup.string().when("has_discount", {
    is: true,
    then: Yup.string().required("Discount Type is required"),
  }),
  max_discount: Yup.number()
    .when("discount_type", {
      is: "Percentage",
      then: Yup.number().required("Max Discount is required"),
    })
    .min(1, "Minumum value should be 1"),
  discount_amount: Yup.number()
    .when("discount_type", {
      is: "Flat",
      then: Yup.number().required("Discount amount is required"),
    })
    .min(1, "Minumum value should be 1"),
  discount_percentage: Yup.number()
    .when("discount_type", {
      is: "Percentage",
      then: Yup.number().required("Discount percentage is required"),
    })
    .min(1, "Minimum value should be 1"),
  discount_sessions: Yup.number()
    .when("discount_type", {
      is: "Sessions",
      then: Yup.number().required("Discount sessions is required"),
    })
    .min(1, "Minimum value should be 1"),
  valid_for_days: Yup.number()
    .positive("Validity days should be a positive number")
    .min(1, "Days cannot be less than or equal to 0"),
  pattern: Yup.mixed()
    .required("Pattern is required")
    .test({
      test: function (value) {
        let message = "";
        let error = false;
        if (!value) {
          message = "Pattern is required.";
          error = true;
        } else {
          const newPattern = /[#]+/g;
          const matches = value.replace(/\s/g, "").match(newPattern);

          if (matches?.length > 1) {
            error = true;
            message = "Please enter valid pattern.";
          } else if (
            value.replace(/\s/g, "").length < 3 ||
            value.replace(/\s/g, "").length > 10
          ) {
            error = true;
            message =
              "Pattern must include minimum 3 and maximum 10  characters";
          } else {
            const patterLimit = value.match(/#/g)
              ? value.match(/#/g).length
              : 0;
            error = !patterLimit;
            message = patterLimit ? "" : "Pattern must include '#' character";
          }
        }

        if (error) {
          return this.createError({
            message,
            path: "pattern", // Field name
          });
        }

        return true;
      },
    }),
});
