import React from 'react'
import { Card, CardBody, Col } from "reactstrap";
import BlogForm from "../form/blog-form";
import { useAddBlog } from './hooks';

const AddBlog = (props) => {

  const {
    handleAddBlog,
    addBlogLoading,
    categories,
    interests,
    users,
    blogs,
    labels,
    setSearchText,
  } = useAddBlog(props);

  return (
    <div>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="react-table__wrapper">
              <div className="card__title card__title__flex">
                <h3 className="bold-text">ADD BLOG</h3>
              </div>
            </div>
            <BlogForm
              handleBlogForm={handleAddBlog}
              btn="Add"
              categories={categories}
              interests={interests}
              users={users}
              blogs={blogs}
              labels={labels}
              isLoading={addBlogLoading}
              setSearchText={setSearchText}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}

export default AddBlog
