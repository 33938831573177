import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";

const namespace = "containers/dashoard";

export const SET_STATS_LIST = `${namespace}/SET_STATS_LIST`;
export const SET_PAYMENT_HISTORIES_FILTER = `${namespace}/SET_PAYMENT_HISTORIES_FILTER`;
export const SET_PAYMENT_HISTORIES = `${namespace}/SET_PAYMENT_HISTORIES`;
export const CLEAR_PAYMENT_HISTORIES_FILTER = `${namespace}/CLEAR_PAYMENT_HISTORIES_FILTER`;

export const setStatsList = (payload) => ({
  type: SET_STATS_LIST,
  payload,
});

export const setPaymentHistoryList = (payload) => ({
  type: SET_PAYMENT_HISTORIES,
  payload,
});

export const setPaymentHistoryFilter = (payload) => ({
  type: SET_PAYMENT_HISTORIES_FILTER,
  payload,
});

export const clearPaymentHistoryFilter = () => ({
  type: CLEAR_PAYMENT_HISTORIES_FILTER,
});

export const getStats = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getStats, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setStatsList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getPaymentHistories = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPaymenthistories, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setPaymentHistoryList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadTransactions = (params) => async (dispatch) => {
  const {
    created_at: { start, end },
  } = params;
  try {
    if (start && end) {
      const response = await api.get(apiEndPoints.downloadPaymentHistories, {
        responseType: "arraybuffer",
        params: { params },
      });
      const { data, status } = response;
      if (status === 200) {
        return data;
      }
    } else {
      dispatch(
        showSnackbar({
          message: "Start and End date is required",
          type: snackbarTypes.ERROR,
        })
      );
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const downloadInvoice = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.downloadInvoice(id), {
      responseType: "arraybuffer",
    });
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
