import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearLanguage } from "../../utils/auth";
import {
  getUserList,
  setUserFilter,
  clearUserFilter,
  getReportedUserList,
} from "./actions";
import { getUsers, getUsersFilter, getUsersCount } from "./selectors";

export const useUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const filters = useSelector(getUsersFilter);
  const userCount = useSelector(getUsersCount);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [isReported, setIsReported] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    pathname === "/users/reported" ? setIsReported(true) : setIsReported(false);
  }, [pathname]);

  useEffect(() => {
    clearLanguage();
    const fetchUsers = async () => {
      setLoadingUsers(true);
      await dispatch(
        pathname === "/users/reported"
          ? getReportedUserList(filters)
          : getUserList(filters)
      );
      setLoadingUsers(false);
    };
    fetchUsers();
  }, [filters, dispatch, pathname]);

  const setFilter = (key, value) => {
    dispatch(setUserFilter({ key, value }));
  };

  const resetFilter = async () => {
    dispatch(clearUserFilter());
  };

  return {
    users,
    loadingUsers,
    setFilter,
    filters,
    userCount,
    resetFilter,
    pathname,
    isReported,
  };
};
