import * as Yup from "yup";
import { validationRegex } from "../../../../../utils/validation";

export const videoFormSchema = Yup.object().shape({
  title: Yup.string().trim().required("Title is required"),
  description: Yup.string().trim().required("Description is required"),
  author_id: Yup.string().required("Author is required").nullable(),
  category_id: Yup.string().required("Category is required").nullable(),
  status: Yup.string().required("Status is required").nullable(),
  url: Yup.mixed().test({
    test: (value) => {
      if (!value) {
        return true;
      }
      return validationRegex.youtube_url.test(value);
    },
    message: "Invalid YouTube URL",
  }),
  video_banner: Yup.array().when("url", {
    is: (value) => !value,
    then: Yup.array().required("Banner is required"),
  }),
  video_icon: Yup.array().required("Icon is required"),
  published_at: Yup.date().required("Published At is required"),
  video: Yup.array().when("url", {
    is: (value) => !value,
    then: Yup.array().required("Video is required"),
  }),
});
