import React from "react";
import { Button } from "reactstrap";

import InterestForm from "../form/interest-form";
import { useAddInterest } from "./hooks";
import Modals from "../../../components/modal";
import { useUser } from "../../../common-hooks/user";

const AddInterest = (props) => {
  const {
    handleAddInterest,
    isOpenModal,
    toggleModal,
    addInterestLoading,
    getCategories,
    categories,
  } = useAddInterest(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          getCategories();
        }}
      >
        <i className="fa fa-plus"></i> Add Interest
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <InterestForm
              handleInterestForm={handleAddInterest}
              btn="Add"
              isLoading={addInterestLoading}
              categories={categories}
            />
          }
          title="Add Interest"
        />
      ) : null}
    </div>
  );
};

export default AddInterest;
