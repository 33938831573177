import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPolicy, editPolicy, getPoliciesList } from "../actions";

import { getPoliciesFilter } from "../selectors";

export const useEditPolicy = ({ id }) => {
  const dispatch = useDispatch();
  const filter = useSelector(getPoliciesFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editPolicyLoading, setEditPolicyLoading] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState();

  const getPolicyFieldData = async () => {
    setEditPolicyLoading(true);
    const data = await dispatch(getPolicy(id));

    if (data) {
      setSelectedPolicy(data);
      setEditPolicyLoading(false);
      toggleModal();
    }
  };

  const handleEditPolicy = async (values) => {
    try {
      const payload = {
        name: values.name,
        title: values.title,
        description: values.description,
      };
      setEditPolicyLoading(true);
      const is_success = await dispatch(editPolicy(payload, id));
      setEditPolicyLoading(false);
      if (is_success) {
        dispatch(getPoliciesList(filter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    editPolicyLoading,
    handleEditPolicy,
    isOpenModal,
    toggleModal,
    getPolicyFieldData,
    selectedPolicy,
  };
};
