import React, { memo } from "react";
import { Editor } from "react-draft-wysiwyg";
import PropTypes from "prop-types";
import { useTextEditor } from "./hooks";

const TextEditor = memo((props) => {
  const { ToolbarOptions, editorState, onEditorStateChange, handlePastedText } =
    useTextEditor(props);
  return (
    <div className="text-editor">
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={ToolbarOptions}
        handlePastedText={handlePastedText}
      />
    </div>
  );
});

TextEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TextEditor;
