import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialLanguageFieldValue } from "../../../../../constants";
import { clearLanguage, getLanguage } from "../../../../../utils/auth";
import {
  getSingleVideo,
  updateVideo,
  getVideoList,
  getSingleUser,
  setUserList,
  getCategoriesList,
} from "../actions";
import { getCategoryFilter, getVideoFilter } from "../selectors";
import { clearFiles, setFiles } from "../../../../../utils/upload-file/actions";

export const useEditVideo = ({ id }) => {
  const dispatch = useDispatch();
  const [editVideoLoading, setEditVideoLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});
  const videoFilter = useSelector(getVideoFilter);
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const categoryFilters = useSelector(getCategoryFilter);

  useEffect(() => {
    (async () => {
      isOpenModal && (await dispatch(getCategoriesList(categoryFilters)));
    })();
  }, [dispatch, categoryFilters, isOpenModal]);

  const getValues = async () => {
    setEditVideoLoading(true);
    const selectedLanguage = getLanguage();
    dispatch(clearFiles());
    const is_success = await dispatch(getSingleVideo(id));

    if (is_success) {
      const { video, files } = is_success;
      const author = await dispatch(getSingleUser(video?.author_id));
      if (author?.user?.id) {
        dispatch(setUserList({ users: [author?.user] }));
      }
      files.forEach((file) => {
        dispatch(setFiles(file));
      });
      video.title = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: video.title,
      };
      video.description = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: video.description,
      };
      setSelectedVideo(video);
      toggleModal();
    }
    setEditVideoLoading(false);
  };

  const handleLanguageData = async (rest, setFormvalues) => {
    setEditVideoLoading(true);
    const selectedLanguage = getLanguage();
    const is_success = await dispatch(getSingleVideo(id));
    if (is_success) {
      const { video, files } = is_success;
      files.forEach((file) => {
        dispatch(setFiles(file));
      });

      video.title = {
        ...rest.values.title,
        [selectedLanguage]:
          rest.values.title[selectedLanguage] || video.title || "",
      };

      video.description = {
        ...rest.values.description,
        [selectedLanguage]:
          rest.values.description[selectedLanguage] || video.description || "",
      };

      video.language = selectedLanguage;
      setSelectedVideo(video);
      setFormvalues(video);
    }
    setEditVideoLoading(false);
  };

  const handleEditVideo = async (values) => {
    setEditVideoLoading(true);
    try {
      const payload = {
        video: {
          title: values.title,
          description: values.description,
          status: values.status,
          published_at: values.published_at,
          category_id: values.category_id?.id,
          author_id: values.author_id?.id,
          url: values.url,
        },
        files,
      };

      const is_success = await dispatch(updateVideo(id, payload));
      if (is_success) {
        dispatch(getVideoList(videoFilter));
        toggleModal();
      }
      setEditVideoLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editVideoLoading,
    handleEditVideo,
    toggleModal,
    isOpenModal,
    selectedVideo,
    getValues,
    handleLanguageData,
  };
};
