import moment from "moment";
import React from "react";
import DataTable from "../../../components/data-table";
import { columns } from "./constant";
import { useReportOptions } from "./hooks";
import AddReason from "./add-report";
import DeleteReason from "./delete-option";
import EditReason from "./edit-option";
import SearchFilter from "../../../components/filters/search-filter";

const ReportsOptions = () => {
  const { list, loadingOptions, setFilter, filters, count } =
    useReportOptions();
  const configureTableData = () => {
    try {
      let results = list.map((reason) => {
        let newReason = Object.assign({}, reason);
        newReason["created_at"] = reason.created_at
          ? moment(reason.created_at).format("DD MM YYYY")
          : "NA";

        !reason?.reason?.toLowerCase()?.startsWith("other") &&
          (newReason["action"] = (
            <div className="d-flex justify-content-around">
              <EditReason option={reason} />
              <DeleteReason option={reason} />
            </div>
          ));

        return newReason;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const reasonFilter = () => (
    <>
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Reason"
        filterKey="title"
        filterTitle="Reason"
        searchedValue={filters?.title}
      />
    </>
  );
  return (
    <DataTable
      title="Reasons"
      addBtn={<AddReason />}
      searchPlaceholder="Search Report"
      CreateTableData={configureTableData}
      withFilter={true}
      filter={reasonFilter}
      handleSetFilter={setFilter}
      count={count}
      skip={filters?.skip}
      isLoading={loadingOptions}
    />
  );
};

export default ReportsOptions;
