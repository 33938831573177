import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useExport } from "./hooks";
import ExportForm from "./form/export-form";
import { useUser } from "./../../../../common-hooks/user";
import Modals from "../../../../components/modal";

const ExportComponent = (props) => {
  const {
    isOpenModal,
    toggleModal,
    isLoading,
    downloadPostStatisticsData,
    dateError,
    setDateError,
  } = useExport(props);

  const { isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }

  return (
    <div>
      <Tooltip
        title="Download Post Statistics"
        placement="bottom"
        className="download__schedule_event_report"
      >
        <i
          className="fa fa-file-excel-o fa-2x icon-btn-action"
          onClick={toggleModal}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <ExportForm
              isLoading={isLoading}
              downloadPostStatistics={downloadPostStatisticsData}
              dateError={dateError}
              setDateError={setDateError}
            />
          }
          title="Post Statistics"
        />
      ) : null}
    </div>
  );
};

export default ExportComponent;
