import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../utils/upload-file/actions";
import { getOfferList, uploadOfferCode } from "../actions";
import { getOfferFilters } from "../selectors";

export const useAddCode = (id) => {
  const dispatch = useDispatch();
  const [addCodeLoading, setAddCodeLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const filter = useSelector(getOfferFilters);

  const handleAddCode = async (values) => {
    try {
      setAddCodeLoading(true);
      var data = new FormData();
      data.append("file", values.file);

      const is_success = await dispatch(uploadOfferCode(data, id));

      if (is_success) {
        dispatch(clearFiles());
        dispatch(getOfferList(filter));
        toggleModal();
      }
      setAddCodeLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    isOpenModal,
    toggleModal,
    handleAddCode,
    files,
    addCodeLoading,
  };
};
