import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { socket } from "../../../api/socket";

import { getCourseData, startEvent } from "../actions";

export const useScheduleList = ({
  match: {
    params: { course_id },
  },
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [course_schedules, setCourseSchedules] = useState([]);
  const [course, setCourse] = useState({});
  const [isStartingEvent, setIsStartingEvent] = useState(false);

  const handleSocketEvent = useCallback(
    async (param) => {
      if (param) {
        const { id } = param;
        let NewCourseSchedules = course_schedules.map((event) => {
          if (event?.event_schedule_id === id && event?.type === "Trial") {
            Object.keys(param).map((key) => (event[key] = param[key]));
          }
          return event;
        });
        setCourseSchedules(NewCourseSchedules);
      }
    },
    [course_schedules]
  );

  const handleSocketCourse = useCallback(
    async (param) => {
      if (param) {
        const { id } = param;
        if (id === parseInt(course_id)) {
          let NewCourseSchedules = course_schedules.map((event) => {
            if (event?.type === "Regular") {
              Object.keys(param).map((key) => (event[key] = param[key]));
            }
            return event;
          });
          setCourseSchedules(NewCourseSchedules);
        }
      }
    },
    // eslint-disable-next-line
    [course_schedules]
  );

  useEffect(() => {
    socket.on("eventSchedule", handleSocketEvent);

    return () => {
      socket.off("eventSchedule", handleSocketEvent);
    };
  }, [handleSocketEvent]);

  useEffect(() => {
    socket.on("course", handleSocketCourse);

    return () => {
      socket.off("course", handleSocketCourse);
    };
  }, [handleSocketCourse]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const { course } = await dispatch(getCourseData(course_id));
      setCourse(course);
      const courseSchedules = [
        ...course.trial_event_schedules,
        ...course.regular_event_schedules,
      ];
      setCourseSchedules(courseSchedules);
      setIsLoading(false);
    }
    fetchData();
  }, [course_id, dispatch]);

  const joinMeeting = async (id) => {
    setIsStartingEvent(true);
    const zoom_meeting = await dispatch(startEvent(id));
    if (zoom_meeting) {
      let win = window.open(zoom_meeting.start_url, "_blank");
      win.focus();
      setIsStartingEvent(false);
    }
  };

  return {
    course_schedules,
    course,
    joinMeeting,
    isStartingEvent,
    isLoading,
  };
};
