import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../components/modal";
import UserForm from "../form/user-form";
import { useEditUser } from "./hooks";
import { useUser } from "./../../../common-hooks/user";
import { setLanguage } from "../../../utils/auth";

const EditUser = ({ user }) => {
  const {
    handleEditUser,
    isOpenModal,
    toggleModal,
    editUserLoading,
    selectedUser,
    getUserFieldData,
    handleLangugeData,
  } = useEditUser(user);

  const { isMember, isCoordinator } = useUser();

  if (isMember || isCoordinator) {
    return null;
  }

  return (
    <>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action`}
          onClick={() => {
            setLanguage("en");
            handleLangugeData();
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <UserForm
              fieldValues={selectedUser}
              handleUserForm={handleEditUser}
              btn="Save"
              isLoading={editUserLoading}
              getUserData={getUserFieldData}
            />
          }
          title="Edit User"
        />
      ) : null}
    </>
  );
};

export default EditUser;
