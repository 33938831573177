import { useState } from "react";
import { useDispatch } from "react-redux";
import * as moment from "moment";
import { downloadUsers } from "../actions";
import { useUser } from "../../../common-hooks/user";

export const useExport = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dateError, setDateError] = useState("");
  const { getFinalUserFilter } = useUser();
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    setDateError("");
  };

  const downloadUserReport = async (values) => {
    const startDate = moment(values.startsAt).startOf("day").toDate();
    const endDate = moment(values.endsAt, "DD/MM/YYYY").toDate();
    if (startDate > endDate) {
      setDateError("End Date must be greater than Start Date");
    } else {
      setIsLoading(true);
      const filters = {
        status: getFinalUserFilter(values?.status),
        roles: values.role.map((val) => val.value),
        utc_offset: moment().utcOffset(),
        created_at: {
          from: moment(values.startsAt).format("DD/MM/YYYY"),
          to: moment(values.endsAt).format("DD/MM/YYYY"),
        },
        age_group:
          values.age_group?.value === "All" ? "" : values.age_group?.value,
      };
      const exportData = await dispatch(downloadUsers(filters));
      if (exportData) {
        toggleModal();
      }
      setIsLoading(false);
    }
  };

  return {
    isOpenModal,
    toggleModal,
    isLoading,
    downloadUserReport,
    dateError,
    setDateError,
  };
};
