import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleMetaData, editMeta, getMetaData } from "../actions";

import { getMetaDataFilter } from "../selectors";

export const useEditMetaData = ({ id }) => {
  const dispatch = useDispatch();
  const filter = useSelector(getMetaDataFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editMetaDataLoading, setEditMetaDataLoading] = useState(false);
  const [selectedMetaData, setSelectedMetaData] = useState({});

  const getMetaFieldData = async () => {
    setEditMetaDataLoading(true);
    const data = await dispatch(getSingleMetaData(id));

    if (data) {
      data["page_url"] =
        data?.page_url_path === "/"
          ? process.env.REACT_APP_ORIGIN
          : `${process.env.REACT_APP_ORIGIN}${data?.page_url_path}`;
      setSelectedMetaData(data);
      setEditMetaDataLoading(false);
      toggleModal();
    }
  };

  const handleEditMetaData = async (payload) => {
    try {
      setEditMetaDataLoading(true);
      const is_success = await dispatch(editMeta(payload, id));
      setEditMetaDataLoading(false);
      if (is_success) {
        dispatch(getMetaData(filter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    editMetaDataLoading,
    handleEditMetaData,
    isOpenModal,
    toggleModal,
    getMetaFieldData,
    selectedMetaData,
  };
};
