import React from "react";
import { Route, Switch } from "react-router-dom";
import MainWrapper from "../MainWrapper";
import Login from "../../auth";
import CancellationPolicy from "../../cancellation-policy";
import TermsAndCondition from "../../terms-and-conditions";
import NotFound404 from "../../page-not-found";
import PrivateRoute from "./private-route";
import { userRoles } from "../../../constants";

import Dashboard from "./WrappedRoutes/dashboard";
import Users from "./WrappedRoutes/users";
import Categories from "./WrappedRoutes/categories";
import Events from "./WrappedRoutes/events";
import ScheduleEvents from "./WrappedRoutes/schedule-events";
import Interests from "./WrappedRoutes/interests";
import Plans from "./WrappedRoutes/plans";
import Faq from "./WrappedRoutes/faq";
import Blogs from "./WrappedRoutes/blogs";
import Labels from "./WrappedRoutes/label";
import Policy from "./WrappedRoutes/policy";
import Sections from "./WrappedRoutes/sections";
import Pages from "./WrappedRoutes/pages";
import PopularKeywords from "./WrappedRoutes/popular-keywords";
import Notices from "./WrappedRoutes/notices";
import Videos from "./WrappedRoutes/videos";
import Banners from "../../CMS/banners";
import Settings from "../../CMS/settings";
import Offers from "../../Offers";
import Partners from "../../offer-partners";
import Courses from "./WrappedRoutes/courses";
import UnlockedOffers from "./WrappedRoutes/unlocked-offers";
import Testimonials from "./WrappedRoutes/testimonials";
import Posts from "./WrappedRoutes/posts";
import ReportOptions from "./WrappedRoutes/report-option";
import Vouchers from "./WrappedRoutes/voucher";
import Stories from "./WrappedRoutes/stories";
import CommentsAndReplies from "./WrappedRoutes/comment-and-replies";
import MetaData from "./WrappedRoutes/meta-data";

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/log_in" component={Login} />
        <Route
          exact
          path="/cancellation_policy"
          component={CancellationPolicy}
        />
        <Route
          exact
          path="/terms_and_conditions"
          component={TermsAndCondition}
        />
        <PrivateRoute
          access={[userRoles.ADMIN, userRoles.CS_SPECIALIST]}
          exact
          path="/settings"
          component={Settings}
        />
        <PrivateRoute
          access={[userRoles.ADMIN]}
          exact
          path="/dashboard"
          component={Dashboard}
        />
        <PrivateRoute
          access={[userRoles.ADMIN, userRoles.CS_SPECIALIST]}
          exact
          path="/offers"
          component={Offers}
        />

        <PrivateRoute
          access={[userRoles.ADMIN]}
          exact
          path="/vouchers"
          component={Vouchers}
        />

        <PrivateRoute
          access={[userRoles.ADMIN]}
          exact
          path="/voucher/:id/codes"
          component={Vouchers}
        />

        <PrivateRoute
          access={[userRoles.ADMIN, userRoles.CS_SPECIALIST]}
          exact
          path="/unlocked-offers"
          component={UnlockedOffers}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/"
          component={Users}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/users/:user_id"
          component={Users}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/users/reported"
          component={Users}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/blogs"
          component={Blogs}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/partners"
          component={Partners}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/add-blog"
          component={Blogs}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/edit-blog/:blog_id"
          component={Blogs}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/courses"
          component={Courses}
        />
        <PrivateRoute
          access={[userRoles.ADMIN, userRoles.MODERATOR]}
          exact
          path="/add-course"
          component={Courses}
        />
        <PrivateRoute
          access={[userRoles.ADMIN, userRoles.MODERATOR]}
          exact
          path="/edit-course/:course_id"
          component={Courses}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/course/:course_id"
          component={Courses}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/videos"
          component={Videos}
        />

        <PrivateRoute
          access={[userRoles.ADMIN, userRoles.CS_SPECIALIST]}
          exact
          path="/testimonials"
          component={Testimonials}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/labels"
          component={Labels}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/comments"
          component={Blogs}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/sections"
          component={Sections}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/banners"
          component={Banners}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/policy"
          component={Policy}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/pages"
          component={Pages}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/popular-keywords"
          component={PopularKeywords}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/categories"
          component={Categories}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/category/:category_id"
          component={Categories}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/notices"
          component={Notices}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/events"
          component={Events}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.CO_ORDINATOR,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/schedule-events"
          component={ScheduleEvents}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/schedule-event/:event_id/discussions"
          component={ScheduleEvents}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/interests"
          component={Interests}
        />
        <PrivateRoute
          access={[userRoles.ADMIN, userRoles.CS_SPECIALIST]}
          exact
          path="/plans"
          component={Plans}
        />
        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/faq"
          component={Faq}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/posts"
          component={Posts}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/post/:post_id"
          component={Posts}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/post/:post_id/reports"
          component={Posts}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/stories"
          component={Stories}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/stories/:id/reports"
          component={Stories}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/comments-and-replies"
          component={CommentsAndReplies}
        />

        <PrivateRoute
          access={[
            userRoles.ADMIN,
            userRoles.MODERATOR,
            userRoles.CS_SPECIALIST,
          ]}
          exact
          path="/comments-and-replies/:id/reports"
          component={CommentsAndReplies}
        />

        <PrivateRoute
          access={[userRoles.ADMIN]}
          exact
          path="/report-options"
          component={ReportOptions}
        />

        <PrivateRoute
          access={[userRoles.ADMIN]}
          exact
          path="/meta-data"
          component={MetaData}
        />
        <PrivateRoute exact path="*" component={NotFound404} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
