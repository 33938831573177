import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import Modals from "../../../components/modal";
import PlanForm from "../form/plan-form";
import { useEditPlan } from "./hooks";
import { useUser } from "./../../../common-hooks/user";
import { setLanguage } from "../../../utils/auth";

const EditPlan = ({ plan }) => {
  const {
    handleEditPlan,
    isOpenModal,
    toggleModal,
    getPlanFieldData,
    selectedPlan,
    editPlanLoading,
    handleLangugeData,
  } = useEditPlan(plan);

  const { isModerator, isAdmin } = useUser();

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Tooltip title={isModerator ? "View" : "Edit"} placement="bottom">
        <i
          className={
            isModerator
              ? `fa fa-eye icon-btn-action`
              : `fa fa-edit icon-btn-action`
          }
          onClick={() => {
            setLanguage("en");
            getPlanFieldData();
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PlanForm
              fieldValues={selectedPlan}
              handlePlanForm={handleEditPlan}
              btn="Save"
              isLoading={editPlanLoading}
              handleLangugeData={handleLangugeData}
            />
          }
          title={isModerator ? "View Plan" : "Edit Plan"}
        />
      ) : null}
    </>
  );
};

export default EditPlan;
