/* eslint-disable */

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { trackableTypes } from "../../../constants";
import { clearFiles } from "../../../utils/upload-file/actions";
import { MENTION_TYPES } from "../../social/posts/constant";

import {
  addComment,
  deleteComment,
  editComment,
  getCommentList,
  getScheduleEvent,
} from "../actions";
import { getDiscussionsCount } from "../selectors";

export const useDiscussion = (props) => {
  const { event_id } = useParams();

  const count = useSelector(getDiscussionsCount);
  const [session, setSession] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const files = useSelector((state) => state.fileReducer);
  const [discussions, setDiscussions] = useState([]);
  const [discussionFilter, setDiscussionFilter] = useState({
    trackable_type: trackableTypes.EVENTSCHEDULE,
    trackable_id: null,
    limit: 10,
    skip: 0,
    replies: { limit: 2, skip: 0 },
    created_at: {
      from: "",
      to: "",
    },
    user_id: null,
    name: "",
    text: "",
    mobile_number: "",
    status: "",
    roles: [],
  });

  const user = useSelector(({ authReducer }) => authReducer?.loggedInUser);
  const history = useHistory();

  const dispatch = useDispatch();

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
  };

  useEffect(() => {
    (async () => {
      const session = await dispatch(getScheduleEvent(event_id));
      if (!session?.event_schedule?.allow_discussions) {
        history.push("/schedule-events");
      }
      if (session?.is_success) {
        setSession(session?.event_schedule);
      }
    })();
  }, [dispatch, event_id]);

  const fetchDiscussions = async () => {
    const data = await dispatch(getCommentList(discussionFilter));
    if (data?.is_success) {
      setDiscussions((prev) => {
        if (prev?.discussions) {
          return {
            ...prev,
            count: data?.count,
            discussions:
              discussionFilter?.skip > 0
                ? [...prev?.discussions, ...data?.discussions]
                : [...data.discussions],
          };
        }

        return data;
      });
    }
  };

  const { discussionsList = [], totalDiscussions = 0 } = useMemo(() => {
    if (!discussions || discussions?.discussions?.length === 0) {
      return { discussionsList: [], totalDiscussions: 0 };
    }
    let totalRecords = discussions?.count;
    let commentList = discussions?.discussions;

    return { discussionsList: commentList, totalDiscussions: totalRecords };
  }, [discussions]);

  useEffect(() => {
    (async () => {
      if (discussionFilter?.trackable_id) {
        await fetchDiscussions();
      }
    })();
  }, [discussionFilter, dispatch]);

  useEffect(() => {
    setDiscussionFilter((prev) => ({
      ...prev,
      ["trackable_id"]: event_id,
    }));
  }, [event_id]);

  const handleFilter = () => {
    setDiscussionFilter((prev) => ({
      ...prev,
      ["skip"]: prev?.skip + 10,
    }));
  };

  const handleDiscussionFilter = (key, value) => {
    if (key === "created_at") {
      setDiscussionFilter((prev) => ({
        ...prev,
        [key]: {
          from: value?.from,
          to: value?.to,
        },
      }));
    } else if (key === "user_id") {
      setDiscussionFilter((prev) => ({
        ...prev,
        [key]: value ? value : null,
      }));
    } else if (key === "roles") {
      setDiscussionFilter((prev) => ({
        ...prev,
        [key]: value ? [value] : [],
      }));
    } else {
      setDiscussionFilter((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const clearDiscussionFilter = () => {
    setDiscussionFilter((prev) => ({
      ...prev,
      trackable_type: trackableTypes.EVENTSCHEDULE,
      limit: 10,
      skip: 0,
      replies: { limit: 2, skip: 0 },
      created_at: {
        from: "",
        to: "",
      },
      user_id: null,
      name: "",
      text: "",
      mobile_number: "",
      status: "",
      roles: [],
    }));
  };

  const handleCreateDiscussion = async (values) => {
    let ranges = [];

    values?.ranges?.forEach((mention) => {
      if (mention?.type) {
        ranges.push({
          trackable_type:
            mention?.type === "#"
              ? MENTION_TYPES.hashtag
              : mention?.type === "@"
              ? MENTION_TYPES.user
              : "",
          trackable_id: mention.id,
          name: mention.name,
          offset: mention.offset,
          length: mention.length,
        });
      }
    });
    const payload = {
      attachments: files?.files || [],
      text: values?.plainTextValue || values?.caption,
      trackable_id: parseInt(event_id),
      ranges,
      trackable_type: trackableTypes.EVENTSCHEDULE,
      on_behalf_of: values?.post_by === "EvergreenClub" ? "EvergreenClub" : "",
    };

    const { is_success = false, discussion = {} } = await dispatch(
      addComment(payload)
    );
    if (is_success) {
      setDiscussions((prev) => ({
        ...prev,
        count: prev?.count + 1,
        discussions: [
          {
            ...discussion,
            attachments: files?.files,
            stats: {
              replies_count: 0,
              reactions_counts: [],
            },
          },
          ...prev?.discussions,
        ],
      }));
      toggleModal();
    }
  };

  const handleUpdateDiscussion = async (payload, id, index) => {
    const is_success = await dispatch(editComment(payload, id));
    if (is_success) {
      discussionsList?.splice(index, 1, payload);
      setDiscussions((prev) => ({
        ...prev,
        discussions: discussionsList,
      }));
      return true;
    }
  };

  const handleDeleteDiscussion = async (values, id) => {
    const payload = {
      reason: values?.reason,
      status: values.status,
    };
    let temp;
    discussionsList.forEach((d, index) => {
      if (d.id === id) {
        temp = { index, discussion: d };
        return;
      }
    });

    const is_success = await dispatch(deleteComment(id, payload));
    if (is_success) {
      discussionsList?.splice(temp.index, 1, {
        ...temp?.discussion,
        status: values.status,
      });
      setDiscussions((prev) => ({
        ...prev,
        count: prev?.count - 1,
        discussions: prev?.discussions,
      }));

      return true;
    }
  };

  return {
    discussions,
    count,
    handleFilter,
    session,
    isOpenModal,
    toggleModal,
    handleCreateDiscussion,
    discussionsList,
    totalDiscussions,
    handleUpdateDiscussion,
    handleDeleteDiscussion,
    handleDiscussionFilter,
    discussionFilter,
    clearDiscussionFilter,
  };
};
