import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generatePaymentLink,
  copyPaymentLink,
  clearPaymentLink,
  getPaymentHistoriesAction,
} from "../actions";
import { getActivePlansList, getUserPaymentFilter } from "../selectors";

export const useGeneratePaymentLink = (user_id) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [generateLinkLoading, setGenerateLinkLoading] = useState(false);
  const dispatch = useDispatch();
  const plans = useSelector(getActivePlansList);
  const payment_filter = useSelector(getUserPaymentFilter);

  const handleGenerateLink = async (values) => {
    setGenerateLinkLoading(true);
    const is_success = await dispatch(
      generatePaymentLink(user_id, values.plan_variants?.id)
    );

    if (is_success) {
      dispatch(getPaymentHistoriesAction(user_id, payment_filter));
    }

    setGenerateLinkLoading(false);
  };

  const copyLink = async (link) => {
    navigator.clipboard.writeText(link);
    dispatch(copyPaymentLink());
    toggleModal();
  };

  const toggleModal = () => {
    dispatch(clearPaymentLink());
    setIsOpenModal(!isOpenModal);
  };

  return {
    toggleModal,
    plans,
    handleGenerateLink,
    isOpenModal,
    generateLinkLoading,
    setGenerateLinkLoading,
    copyLink,
  };
};
