import React from "react";
import DataTable from "../../../../components/table";
import { columns } from "./constant";
import EditVideo from "./edit-video";
import DeleteVideo from "./delete-video";
import AddVideo from "./add-video";
import SearchFilter from "../../../../components/filters/search-filter";
import SelectFilter from "../../../../components/filters/select-filter";
import Tooltip from "@material-ui/core/Tooltip";
import { useVideos } from "./hooks";
import { statusOptions } from "./constant";
const Videos = () => {
  const {
    filters,
    resetFilter,
    setFilter,
    videos,
    videoCount,
    users,
    categories,
    loadingVideos,
    setUserFilters,
  } = useVideos();
  const configureTableData = () => {
    try {
      let results = videos.map((video) => {
        let newVideo = Object.assign({}, video);
        newVideo["action"] = (
          <div className="video-list__action">
            <EditVideo
              video={video}
              users={users}
              categories={categories}
              setUserFilters={setUserFilters}
            />
            <DeleteVideo video={video} users={users} categories={categories} />
          </div>
        );

        newVideo["author_id"] = users.find(
          (user) => user.id === video.author_id
        )?.name;

        newVideo["url"] = video.url ? (
          <a target="_blank" rel="noopener noreferrer" href={video.url}>
            {video.url}
          </a>
        ) : (
          "NA"
        );
        return newVideo;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const videoFilter = () => (
    <>
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search ID"
        filterKey="id"
        filterTitle="ID"
        searchedValue={filters?.id}
      />
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search Title"
        filterTitle="Title"
        filterKey="title"
        searchedValue={filters?.title}
      />
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        filterKey="status"
        options={statusOptions}
        filterTitle="Status"
      />
      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={resetFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  return (
    <DataTable
      title="Videos"
      addBtn={
        <AddVideo
          users={users}
          categories={categories}
          setUserFilters={setUserFilters}
        />
      }
      CreateTableData={configureTableData}
      withFilter={true}
      filter={videoFilter}
      handleSetFilter={setFilter}
      count={videoCount}
      skip={filters?.skip}
      isLoading={loadingVideos}
    />
  );
};

export default Videos;
