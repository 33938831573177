import * as Yup from "yup";

export const formSchema = Yup.object().shape({
  title: Yup.mixed().test(
    "English language required",
    "Title is required for english language",
    function () {
      const {
        title: { en },
      } = this.parent;

      return en;
    }
  ),
  bg_color_code: Yup.string().required("Background Color is required"),
  text_color_code: Yup.string().required("Text Color is required"),
});
