import React from "react";
import { Formik, ErrorMessage } from "formik";
import TextField from "@material-ui/core/TextField";

import { useLogin } from "./hooks";
import { loginFormSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";

const LoginForm = (props) => {
  const { handleGenrateOtp } = useLogin(props);
  const {
    focusField,
    handleFocusField,
    handleBlurField,
  } = useHandleFieldFocus();
  return (
    <Formik
      validateOnMount
      validationSchema={loginFormSchema}
      initialValues={{}}
      onSubmit={handleGenrateOtp}
    >
      {({ handleSubmit, ...rest }) => (
        <form
          className="material-form"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <TextField
            className="material-form__field"
            name="mobileNumber"
            placeholder="Mobile Number"
            label="Mobile Number"
            type="text"
            onChange={handleFormValueChange(rest, "mobileNumber")}
            onFocus={() => handleFocusField("mobileNumber")}
            onBlur={() => handleBlurField()}
          />
          {focusField === "mobileNumber" ? null : (
            <ErrorMessage
              render={(msg) => (
                <div className="material-form__error">{msg}</div>
              )}
              name={"mobileNumber"}
            />
          )}
          <button
            type="submit"
            className="btn btn-success account__btn account__btn--small"
          >
            GET OTP
          </button>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
