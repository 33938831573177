import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editEvent,
  getCategoriesList,
  getSubcategoriesList,
  getEventList,
  getEvent,
  getFaqCategoryList,
} from "../actions";
import { clearFiles, setFiles } from "../../../utils/upload-file/actions";
import {
  getCategoriesSelector,
  getSubcategoriesSelector,
  getEventsFilter,
  getCategoryFilter,
  getSubcategoryFilter,
  getFaqCategorySelector,
  getFaqCategoryFilter,
} from "../selectors";
import { showSnackbar } from "../../../components/snackbar/actions";
import {
  initialLanguageArrayValue,
  initialLanguageFieldValue,
  snackbarTypes,
} from "../../../constants";
import { clearLanguage, getLanguage } from "../../../utils/auth";

export const useEditEvent = ({ id }) => {
  const dispatch = useDispatch();
  const categories = useSelector(getCategoriesSelector);
  const subcategories = useSelector(getSubcategoriesSelector);
  const filter = useSelector(getEventsFilter);
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const categoryFilter = useSelector(getCategoryFilter);
  const subcategoryFilter = useSelector(getSubcategoryFilter);
  const faqCategories = useSelector(getFaqCategorySelector);
  const faqFilter = useSelector(getFaqCategoryFilter);

  const [selectedEvent, setSelectedEvent] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editEventLoading, setEditEventLoading] = useState(false);

  const getEventFieldData = async () => {
    const selectedLanguage = getLanguage();
    dispatch(clearFiles());
    setEditEventLoading(true);
    await dispatch(getCategoriesList(categoryFilter));
    await dispatch(getSubcategoriesList(subcategoryFilter));
    const faqCategoriesData = await dispatch(getFaqCategoryList(faqFilter));
    const { event, files } = await dispatch(getEvent(id));

    if (event && faqCategoriesData) {
      event.faq_category = faqCategoriesData.filter((cat) =>
        event.faq_category_ids.find((id) => id === cat.id)
      );
      event.title = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: event.title || "",
      };
      event.description = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: event.description || "",
      };
      event.short_description = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: event.short_description || "",
      };
      event.requirements = {
        ...initialLanguageArrayValue,
        [selectedLanguage]: event.requirements || [],
      };
      event.language = selectedLanguage;
      files.forEach((file) => dispatch(setFiles(file)));

      setSelectedEvent(event);
      setEditEventLoading(false);
      toggleModal();
    }
  };

  const handleLangugeData = async (rest, setFormvalues) => {
    const selectedLanguage = getLanguage();
    const { event } = await dispatch(getEvent(id));
    if (event) {
      event.faq_category = faqCategories.filter((cat) =>
        event.faq_category_ids.find((id) => id === cat.id)
      );
      event.title = {
        ...rest.values.title,
        [selectedLanguage]:
          rest.values.title[selectedLanguage] || event?.title || "",
      };

      event.description = {
        ...rest.values.description,
        [selectedLanguage]:
          rest.values.description[selectedLanguage] || event?.description || "",
      };

      event.short_description = {
        ...rest.values.short_description,
        [selectedLanguage]:
          rest.values.short_description[selectedLanguage] ||
          event?.short_description ||
          "",
      };

      event.language = selectedLanguage;

      event.requirements = {
        ...rest.values.requirements,
        [selectedLanguage]: rest.values.requirements[selectedLanguage]?.length
          ? rest.values.requirements[selectedLanguage]
          : event?.requirements || [],
      };

      setFormvalues(event);
    }
  };

  const handleEditEvent = async (values) => {
    let requirements = values.requirements;
    let error = false;
    if (!values.is_course) {
      Object.keys(requirements).forEach((key) => {
        if (requirements[key].length) {
          requirements[key].forEach((val) => {
            const { title, description } = val;
            if (!title || !description) {
              dispatch(
                showSnackbar({
                  message: "Enter valid title or decription for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }
          });
        }
      });
    } else {
      Object.keys(requirements).forEach((key) => {
        if (requirements[key].length) {
          requirements[key].forEach((val) => {
            const { title, description } = val;
            if (!title) {
              dispatch(
                showSnackbar({
                  message: "Enter valid title or description for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }

            if (title && !description.length) {
              dispatch(
                showSnackbar({
                  message: "Enter valid title or description for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }
            description.forEach((desc) => {
              if (!desc) {
                dispatch(
                  showSnackbar({
                    message: "Enter valid  description for requirements",
                    type: snackbarTypes.ERROR,
                  })
                );
                error = true;
                return;
              }
            });
          });
        }
      });
    }

    if (!error) {
      try {
        const payload = {
          event: {
            category_id: values.category.id,
            sub_category_id: values?.subcategory?.id,
            title: values.title,
            description: values.description,
            short_description: values.short_description,
            status: values.status,
            is_course: values.is_course,
            is_popular: values.is_popular,
            requirements,
            type: values.type,
            faq_category_ids: values.faq_category.map(
              (category) => category.id
            ),
            slug: values.slug,
            attendees_limit: parseInt(values.attendees_limit),
            current_attendees_limit: parseInt(values.current_attendees_limit),
            exceeds_percentage: parseInt(values.exceeds_percentage),
            increment_percentage: parseInt(values.increment_percentage),
            broadcast_mins: parseInt(values.broadcast_mins),
            after_popup_broadcast_mins: parseInt(
              values.after_popup_broadcast_mins
            ),
            max_join_broadcast_attempts: parseInt(
              values.max_join_broadcast_attempts
            ),
            meta_title: values.meta_title,
            meta_description: values.meta_description,
            meta_keywords: values.meta_keywords,
          },
          files,
        };

        setEditEventLoading(true);
        const is_success = await dispatch(editEvent(payload, id));
        setEditEventLoading(false);
        if (is_success) {
          dispatch(getEventList(filter));
          toggleModal();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editEventLoading,
    handleEditEvent,
    isOpenModal,
    toggleModal,
    categories,
    subcategories,
    selectedEvent,
    getEventFieldData,
    faqCategories,
    handleLangugeData,
  };
};
