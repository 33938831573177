import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPolicyFilter, getPoliciesList } from "./actions";
import { getPoliciesFilter, getPolicies, getPoliciesCount } from "./selectors";

export const usePolicy = () => {
  const dispatch = useDispatch();
  const policies = useSelector(getPolicies);
  const filters = useSelector(getPoliciesFilter);
  const policyCount = useSelector(getPoliciesCount);

  const [loadingPolicy, setLoadingPolicy] = useState(false);

  useEffect(() => {
    const fetchPolicies = async () => {
      setLoadingPolicy(true);
      await dispatch(getPoliciesList(filters));
      setLoadingPolicy(false);
    };
    fetchPolicies();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setPolicyFilter({ key, value }));
  };

  return {
    policies,
    loadingPolicy,
    setFilter,
    filters,
    policyCount,
  };
};
