import { Card, MenuItem, MenuList } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import moment from "moment";

import React from "react";
import ReactPlayer from "react-player";
import Modals from "../../../../components/modal";
import ProfileImage from "../../../../components/ProfileImage";
import Text from "../../../../components/text";
import { fileContext } from "../../../../constants";
import { colors } from "../../../../constants/colors";
import Comments from "../../../social/posts/components/comment";
import PostCaption from "../../../social/posts/components/post-caption";
import Reaction from "../../../social/posts/components/reaction";
import Reactions from "../../../social/posts/components/reactions";
import { statuses } from "../../../social/posts/constant";
import { CommentIcon } from "../../../social/posts/images";
import UnpublishDiscussionForm from "../../discussions/delete-discussion/form";
import DiscussionForm from "../../discussions/form/form";
import ImageViewer from "../post-images";
import { useDiscussionCard } from "./hooks";
import classes from "./styles.module.scss";
import { useUser } from "../../../../common-hooks/user";
import { trackableTypes } from "../../../../constants";
import ConfidenceScore from "../../../../components/confidence-score";
function DiscussionCard(props) {
  const {
    handleReactSuccess,
    myReaction,
    handleRemoveReactSuccess,
    // reactionsCount,
    discussion,
    handleAddComment,
    isAddComment,
    handleViewComments,
    isViewComments,
    totalComments,
    handleTotalComments,
    reactionsCount,
    handleMoreOptions,
    moreOptions,
    ref,
    user,
    getSelectedDiscussion,
    selectedDiscussion,
    isOpenModal,
    toggleModal,
    handleUpdateDiscussions,
    handleDeleteDiscussions,
    isOpenDeleteModal,
    toggleDeleteModal,
    openViwer,
    handleOpenViewer,
    reactionsCountList,
    publishDiscussion,
    egReaction,
    disabled,
  } = useDiscussionCard(props);

  const { UNPUBLISHED, PUBLISHED, PENDING, IN_REVIEW } = statuses;
  const { isAdmin, isCSSpecialist } = useUser();

  return (
    <Card
      key={discussion?.id}
      className={`${classes.disussion_card_container}`}
    >
      <MuiDialogContent className={classes.user_details_card_container}>
        <div className={classes.discussion_confidence_score}>
          <ConfidenceScore
            data={discussion}
            trackableType={trackableTypes.DISCUSSION_REACTION}
            className="ml-3"
          />
        </div>
        {discussion?.user?.id &&
          !disabled &&
          (isAdmin ||
            (user?.user?.id === discussion?.created_by && !isCSSpecialist)) && (
            <div className={classes.more_options}>
              <img
                onClick={handleMoreOptions}
                src={"/img/more-vert.svg"}
                alt="more"
              />
            </div>
          )}

        <div className={classes.more_options_drawer}>
          {moreOptions && (
            <MenuList ref={ref}>
              {user?.user?.id === discussion?.created_by && (
                <MenuItem onClick={getSelectedDiscussion}>
                  Edit Discussion
                </MenuItem>
              )}

              {isAdmin && (
                <>
                  {(discussion?.status === PUBLISHED ||
                    discussion?.status === PENDING ||
                    discussion?.status === IN_REVIEW) && (
                    <MenuItem onClick={toggleDeleteModal}>
                      Unpublish Discussion
                    </MenuItem>
                  )}

                  {(discussion?.status === UNPUBLISHED ||
                    discussion?.status === PENDING ||
                    discussion?.status === IN_REVIEW) && (
                    <MenuItem onClick={publishDiscussion}>
                      Publish Discussion
                    </MenuItem>
                  )}
                </>
              )}
            </MenuList>
          )}
        </div>

        <Modals
          isOpenModal={isOpenModal}
          setIsOpenModal={toggleModal}
          title="Edit Discussion"
          message={
            <DiscussionForm
              isLoading={false}
              handlePostForm={handleUpdateDiscussions}
              btn="Save"
              fieldValues={selectedDiscussion}
              isEdit
            />
          }
        />

        <Modals
          isOpenModal={isOpenDeleteModal}
          setIsOpenModal={toggleDeleteModal}
          title="Delete Discussion"
          message={
            <UnpublishDiscussionForm
              btn="Unpublish"
              handleSubmit={handleDeleteDiscussions}
            />
          }
        />
        <div className={classes.user_details}>
          <div>
            <ProfileImage
              title={discussion?.user?.name}
              src={discussion?.user?.profile_picture?.url}
              alt="profile image"
              className={classes.user_image}
            />
          </div>
          <div className={classes.username}>
            <Text
              size="16px"
              fontWeight={600}
              color={colors.darkBlack}
              lineheight="140%"
            >
              {discussion?.user?.name}
            </Text>
            <Text
              className={classes.post_time}
              size="12px"
              lineheight="16px"
              fontWeight={400}
              color={colors.darkBlack}
            >
              {moment(discussion?.created_at).fromNow()}
            </Text>
          </div>
        </div>

        {/* Discussion title */}

        <div className={classes.post_title}>
          <PostCaption
            captionText={discussion?.text}
            ranges={discussion?.ranges}
            onTagClick={() => {}}
          />
        </div>

        {/* Discussion counts of comments and reactions */}

        <div
          className={`${classes.discussion_count} d-flex align-items-center`}
        >
          {reactionsCount > 0 ? (
            <Reactions
              type={trackableTypes.DISCUSSION_REACTION}
              id={discussion?.id}
              totalReactions={reactionsCount}
              trackableType={trackableTypes.DISCUSSION_REACTION}
              reactions={reactionsCountList}
            />
          ) : null}
          {totalComments > 0 ? (
            <Text color={colors.lightBlack} onClick={handleViewComments}>
              {`${totalComments}`} comments
            </Text>
          ) : null}{" "}
        </div>

        {/* DIscussion Image container */}

        {discussion?.attachments && discussion?.attachments?.length ? (
          <>
            <div className={classes.img_container}>
              {discussion?.attachments.map(
                ({ file_context, ts_url, url, public_url, uuid_file_name }) =>
                  file_context === fileContext.DISCUSSION_VIDEOS ? (
                    <div
                      key={uuid_file_name}
                      className={`cursor_pointer ${classes.video_container}`}
                      onClick={handleOpenViewer}
                    >
                      <ReactPlayer
                        width={93}
                        height={72}
                        url={ts_url || url || public_url}
                        playing={true}
                        muted={true}
                        loop={true}
                        // style={{ display: 'inline-block' }}
                      />
                    </div>
                  ) : (
                    <div
                      className="cursor_pointer"
                      onClick={handleOpenViewer}
                      key={uuid_file_name}
                    >
                      <img src={url || public_url} alt="img" />
                    </div>
                  )
              )}
            </div>

            <ImageViewer
              isOpen={openViwer}
              handleIsOpen={handleOpenViewer}
              files={discussion?.attachments}
            />
          </>
        ) : null}

        {!isCSSpecialist && <hr className={classes.card__hr} />}

        <div className={classes.action_container}>
          {isAdmin && (
            <div className={`${!isAdmin && "d-none"}`}>
              <Reaction
                trackableId={discussion?.id}
                trackableType={trackableTypes.DISCUSSION_REACTION}
                reactionId={egReaction?.id}
                reactionName={egReaction?.name}
                onReactSuccess={handleReactSuccess}
                onRemoveReactSuccess={handleRemoveReactSuccess}
                disabled={disabled}
                isEgReaction
              />
            </div>
          )}

          <Reaction
            trackableId={discussion?.id}
            trackableType={trackableTypes.DISCUSSION_REACTION}
            reactionId={myReaction?.id}
            reactionName={myReaction?.name}
            onReactSuccess={handleReactSuccess}
            onRemoveReactSuccess={handleRemoveReactSuccess}
            disabled={disabled}
          />

          {!isCSSpecialist && (
            <div className={classes.bottom_section_down_block}>
              <div
                onClick={handleAddComment}
                className={classes.block_clickable}
              >
                <img
                  src={CommentIcon}
                  className={classes.bottom_block_icon}
                  alt=""
                />
                <Text
                  className={disabled ? classes.disabled_button : ""}
                  fontWeight={500}
                  size="15px"
                  color={colors.lightBlack}
                >
                  Add a comment
                </Text>
              </div>
            </div>
          )}
        </div>
        <hr className={classes.card__hr} />

        {/* Discussion action container */}
      </MuiDialogContent>

      <div
        className={`${isViewComments || isAddComment ? "pb-5 px-5" : "px-5"}`}
      >
        <Comments
          viewComment={isViewComments}
          addComment={isAddComment}
          trackableId={discussion?.id}
          trackableType={trackableTypes.DISCUSSION}
          handleTotalComments={handleTotalComments}
          isPostUnpublished={disabled}
        />
      </div>
    </Card>
  );
}

export default DiscussionCard;
