import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../../../components/modal";
import DeleteConfirmation from "../../../../../components/delete-confirmation";
import { useDeleteLabel } from "./hooks";

const DeleteSection = ({ section }) => {
  const {
    handleDeleteSection,
    isOpenModal,
    toggleModal,
    deleteSectionLoading,
  } = useDeleteLabel(section);

  return (
    <>
      <Tooltip title="Delete" placement="bottom" className="pull-right">
        <i
          className={`fa fa-trash icon-btn-action text-danger `}
          onClick={toggleModal}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeleteSection}
              isLoading={deleteSectionLoading}
            />
          }
          title="Delete Section"
        />
      ) : null}
    </>
  );
};

export default DeleteSection;
