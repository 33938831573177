import { trackableTypes } from "../../constants";

export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Reportee Name",
    accessor: "name",
  },
  {
    Header: "Reportee ID",
    accessor: "user_id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Reason",
    accessor: "reason",
    disableGlobalFilter: true,
  },

  {
    Header: "Detailed Reason",
    accessor: "detailed_reason",
    disableGlobalFilter: true,
  },

  {
    Header: "Reported At",
    accessor: "reported_at",
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
  },
];

export const titleKeys = {
  [trackableTypes.COMMENT]: "comment_id",
  [trackableTypes.POST]: "post_id",
  [trackableTypes.STORY]: "story_id",
  [trackableTypes.DISCUSSION]: "discussion_id",
};
