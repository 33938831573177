import {
  SET_STATS_LIST,
  SET_PAYMENT_HISTORIES,
  SET_PAYMENT_HISTORIES_FILTER,
  CLEAR_PAYMENT_HISTORIES_FILTER,
} from "./actions";
import * as moment from "moment";

const initialList = {
  stats: [],
  stats_filter: {
    utc_offset: moment().utcOffset(),
  },
  payment_histories: [],
  payment_history_filter: {
    id: null,
    transaction_id: "",
    status: "",
    created_at: {
      start: "",
      end: "",
    },
    utc_offset: moment().utcOffset(),
    limit: 10,
    skip: 0,
  },
  count: 0,
};

const financeReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_STATS_LIST:
      return {
        ...state,
        stats: action.payload.stats,
      };

    case SET_PAYMENT_HISTORIES:
      return {
        ...state,
        payment_histories: action.payload.payment_histories,
        count: action.payload.count,
      };

    case SET_PAYMENT_HISTORIES_FILTER: {
      const { key, value } = action.payload;
      if (key === "cards") {
        return {
          ...state,
          payment_history_filter: {
            ...state.payment_history_filter,
            status: "Success",
            created_at: value,
          },
        };
      }

      if (key === "created_at") {
        return {
          ...state,
          payment_history_filter: {
            ...state.payment_history_filter,
            [key]: value,
          },
        };
      }

      return {
        ...state,
        payment_history_filter: {
          ...state.payment_history_filter,
          [key]: value ? value : null,
        },
      };
    }

    case CLEAR_PAYMENT_HISTORIES_FILTER:
      return {
        ...state,
        payment_history_filter: initialList.payment_history_filter,
      };

    default:
      return state;
  }
};

export default financeReducer;
