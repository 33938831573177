import React from "react";
import { Grid } from "@material-ui/core";
import styles from "./styles.module.scss";
import ReactPlayer from "react-player";
import { usePostImages, useImageFullView } from "./hooks";
import { NextArrowLight, PrevArrowLight } from "../slider-arrow";
import CustomModal from "../custom-modal";
import { BackWhiteArrow } from "../../containers/social/posts/images";
import { fileContext } from "../../constants";
import Text from "../text";
import { colors } from "../../constants/colors";

const GridMedia = (props) => {
  const { mediaContent, isSingle, position, isMore, onClick } = props;
  const isVideo = mediaContent?.file_context?.toLowerCase() === "video";
  return isVideo ? (
    <div
      onClick={() => onClick("video", position)}
      className={`cursor_pointer ${styles.media_size}`}
    >
      {!isMore && (
        <div className={styles.mweb_play_button}>
          <img src="/img/player.svg" alt="play" />
        </div>
      )}
      <ReactPlayer
        className={`${styles.media_size} ${
          isSingle ? styles.media_fit_contain : ""
        }`}
        style={{ maxHeight: "500px", objectFit: "cover" }}
        url={mediaContent?.ts_url || mediaContent.url}
        controls={false}
        pip
        width="100%"
        height="100%"
        config={{
          youtube: {
            preload: true,
          },
        }}
        playIcon={
          <div className={styles.video_player_banner}>
            <div className={styles.video_player_play_icon}>Play</div>
          </div>
        }
      />
    </div>
  ) : (
    <img
      onClick={() => onClick("image", position)}
      src={mediaContent.url}
      alt="not available"
      className={`cursor_pointer ${styles.media_size} ${
        isSingle ? styles.media_fit_contain : ""
      }`}
    />
  );
};

const Player = ({ video, width, height, videoPlay }) => {
  const isPlaying = videoPlay === video.url;

  return (
    <ReactPlayer
      className={styles.video_player}
      url={video?.ts_url || video?.url}
      style={{ maxHeight: "500px" }}
      controls
      pip
      width={width}
      height={height}
      playing={isPlaying}
      config={{
        youtube: {
          preload: true,
        },
      }}
      playIcon={
        <div className={styles.video_player_banner}>
          <div className={styles.video_player_play_icon}>Play</div>
        </div>
      }
    />
  );
};

const DisplayImage = ({ images, toggleImageModal }) => {
  if (!images || !images.length) {
    return null;
  }

  if (images.length === 1) {
    return (
      <Grid className={styles.container_max_height} container>
        <Grid
          className={`${styles.container_height_100_percent} ${styles.media_left_grid}`}
          item
          md={12}
          lg={12}
          xl={12}
          xs={12}
          sm={12}
        >
          <div
            style={{ height: "100%", width: "100%" }}
            className={styles.media_container}
          >
            <GridMedia
              position={0}
              onClick={toggleImageModal}
              isSingle
              mediaContent={images[0]}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  if (images.length === 2) {
    return (
      <Grid className={styles.container_max_height} container>
        <Grid
          className={`${styles.container_height_100_percent} ${styles.media_left_grid}`}
          item
          md={6}
          lg={6}
          xl={6}
          xs={6}
          sm={6}
        >
          <div
            style={{ height: "100%", width: "100%" }}
            className={styles.media_container}
          >
            <GridMedia
              position={0}
              onClick={toggleImageModal}
              mediaContent={images[0]}
            />
          </div>
        </Grid>
        <Grid
          className={styles.container_height_100_percent}
          item
          md={6}
          lg={6}
          xl={6}
          xs={6}
          sm={6}
        >
          <div
            style={{ height: "100%", width: "100%" }}
            className={`${styles.media_container}`}
          >
            <GridMedia
              position={1}
              onClick={toggleImageModal}
              mediaContent={images[1]}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  if (images.length === 3) {
    return (
      <Grid className={styles.container_max_height} container>
        <Grid
          className={`${styles.container_height_100_percent} ${styles.media_left_grid}`}
          item
          md={6}
          lg={6}
          xl={6}
          xs={6}
          sm={6}
        >
          <div
            style={{ height: "100%", width: "100%" }}
            className={styles.media_container}
          >
            <GridMedia
              position={0}
              onClick={toggleImageModal}
              mediaContent={images[0]}
            />
          </div>
        </Grid>
        <Grid
          className={styles.container_height_100_percent}
          item
          md={6}
          lg={6}
          xl={6}
          xs={6}
          sm={6}
        >
          <div
            style={{ height: "50%", width: "100%" }}
            className={`${styles.media_container} ${styles.media_padding_bottom}`}
          >
            <GridMedia
              position={1}
              onClick={toggleImageModal}
              mediaContent={images[1]}
            />
          </div>
          <div
            style={{ height: "50%", width: "100%" }}
            className={styles.media_container}
          >
            <GridMedia
              position={2}
              onClick={toggleImageModal}
              mediaContent={images[2]}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  if (images.length === 4) {
    return (
      <Grid className={styles.container_max_height} container>
        <Grid
          className={`${styles.container_height_100_percent} ${styles.media_left_grid}`}
          item
          md={6}
          lg={6}
          xl={6}
          xs={6}
          sm={6}
        >
          <div
            style={{ height: "100%", width: "100%" }}
            className={styles.media_container}
          >
            <GridMedia
              position={0}
              onClick={toggleImageModal}
              mediaContent={images[0]}
            />
          </div>
        </Grid>
        <Grid
          className={styles.container_height_100_percent}
          item
          md={6}
          lg={6}
          xl={6}
          xs={6}
          sm={6}
        >
          <div
            style={{ height: "33%", width: "100%" }}
            className={`${styles.media_container} ${styles.media_padding_bottom}`}
          >
            <GridMedia
              position={1}
              onClick={toggleImageModal}
              mediaContent={images[1]}
            />
          </div>
          <div
            style={{ height: "33%", width: "100%" }}
            className={`${styles.media_container} ${styles.media_padding_bottom}`}
          >
            <GridMedia
              position={2}
              onClick={toggleImageModal}
              mediaContent={images[2]}
            />
          </div>
          <div
            style={{ height: "34%", width: "100%" }}
            className={styles.media_container}
          >
            <GridMedia
              position={3}
              onClick={toggleImageModal}
              mediaContent={images[3]}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid className={styles.container_max_height} container>
      <Grid
        className={`${styles.container_height_100_percent} ${styles.media_left_grid}`}
        item
        md={6}
        lg={6}
        xl={6}
        xs={6}
        sm={6}
      >
        <div
          style={{ height: "50%", width: "100%" }}
          className={`${styles.media_container} ${styles.media_padding_bottom}`}
        >
          <GridMedia
            position={0}
            onClick={toggleImageModal}
            mediaContent={images[0]}
          />
        </div>
        <div
          style={{ height: "50%", width: "100%" }}
          className={styles.media_container}
        >
          <GridMedia
            position={1}
            onClick={toggleImageModal}
            mediaContent={images[1]}
          />
        </div>
      </Grid>
      <Grid
        className={styles.container_height_100_percent}
        item
        md={6}
        lg={6}
        xl={6}
        xs={6}
        sm={6}
      >
        <div
          style={{ height: "33%", width: "100%" }}
          className={`${styles.media_container} ${styles.media_padding_bottom}`}
        >
          <GridMedia
            position={2}
            onClick={toggleImageModal}
            mediaContent={images[2]}
          />
        </div>
        <div
          style={{ height: "33%", width: "100%" }}
          className={`${styles.media_container} ${styles.media_padding_bottom}`}
        >
          <GridMedia
            position={3}
            onClick={toggleImageModal}
            mediaContent={images[3]}
          />
        </div>
        <div
          style={{ height: "34%", width: "100%" }}
          className={`${styles.media_container} ${
            images?.length > 5 ? styles.more_parent_relative : ""
          }`}
        >
          {images?.length > 5 ? (
            <div
              onClick={() => {
                toggleImageModal(images[4], 4, true);
              }}
              className={`cursor_pointer ${styles.more_img_box}`}
            >
              <Text color={colors.white} size="36px" fontWeight={600}>
                +{images.length - 5}
              </Text>
            </div>
          ) : null}
          <GridMedia
            isMore={images?.length > 5}
            position={4}
            onClick={() => {
              toggleImageModal(images[4], 4, images?.length > 5);
            }}
            mediaContent={images[4]}
          />
        </div>
      </Grid>
    </Grid>
  );
};

const ImageFullViewModal = (props) => {
  const { imageList, handleBackClick, handleNext, handlePrev, current } =
    useImageFullView(props);

  return (
    <CustomModal
      isFullscreen
      isOpen
      onCloseCallback={handleBackClick}
      paperClassName={styles.imageViewerContainer}
      width="0px"
    >
      <img
        src={BackWhiteArrow}
        alt="close"
        className={styles.close_video_modal}
        onClick={handleBackClick}
      />
      <div className={styles.modal_video_container}>
        {imageList?.length > 1 && current !== imageList?.length - 1 && (
          <NextArrowLight className={styles.next_slider} onClick={handleNext} />
        )}
        <div className={styles.slider}>
          {imageList[current]?.file_context === fileContext.POST_VIDEO ? (
            <div>
              <ReactPlayer
                className={styles.video_player}
                url={
                  imageList[current]?.ts_url ||
                  imageList[current]?.url ||
                  imageList[current]?.public_url
                }
                controls
                pip
                stopOnUnmount={true}
                width="inherit"
                height="inheirt"
                playing={false}
                config={{
                  youtube: {
                    preload: true,
                  },
                }}
                playIcon={
                  <div>
                    <div>
                      <img src={"/img/play-open.svg"} alt="play" />
                    </div>
                  </div>
                }
              />
            </div>
          ) : (
            <img
              className={styles.image_viewer}
              src={imageList[current]?.url || imageList[current]?.public_url}
              alt="img"
            />
          )}
        </div>
        {imageList?.length > 1 && current !== 0 && (
          <PrevArrowLight className={styles.prev_slider} onClick={handlePrev} />
        )}{" "}
      </div>
    </CustomModal>
  );
};

const PhotoGrid = (props) => {
  const { toggleImageModal, imageModal, slickRef, handleCurrent, current } =
    usePostImages(props);

  if (!props.images.length) return null;
  return (
    <div
      className={styles.images_container}
      style={{
        width: props.width,
        maxWidth: props.maxWidth,
      }}
    >
      <DisplayImage
        images={props.images}
        toggleImageModal={toggleImageModal}
        current={current}
        handleCurrent={handleCurrent}
      />
      {imageModal && (
        <ImageFullViewModal
          toggleImageModal={toggleImageModal}
          images={props.images}
          setImageOpen={() => {}}
          slickRef={slickRef}
          current={current}
          handleCurrent={handleCurrent}
        />
      )}
    </div>
  );
};

export default PhotoGrid;

Player.defaultProps = {
  width: "inherit",
  height: "inherit",
};
