import * as Yup from "yup";

export const postFormSchema = Yup.object().shape({
  caption: Yup.string(),
  status: Yup.string().required("Status is required"),
  post_by: Yup.string().required("Post By is required"),
  poll_topic: Yup.string().when("is_poll", {
    is: true,
    then: Yup.string().required("Poll Topic is required"),
  }),
  poll_options: Yup.mixed().when("is_poll", {
    is: true,
    then: Yup.mixed()
      .required("Poll Options is required")
      .test(
        "is-less-charaters",
        "Max Character limit for options is 30",
        function () {
          const { poll_options } = this.parent;
          let valid = poll_options.find((options) => options.length > 30);
          return !valid;
        }
      )
      .test("is_less-length", "Minimum 2 options are required", function () {
        const { poll_options } = this.parent;

        return poll_options.length >= 2 ? true : false;
      }),
  }),

  event_tagged: Yup.object()
    .when("type", {
      is: "event",
      then: Yup.object().required("Event is required"),
    })

    .nullable(),
  course_tagged: Yup.object()
    .when("type", {
      is: "course",
      then: Yup.object().required("Course is required"),
    })
    .nullable(),
  type: Yup.string().when("is_event_or_course", {
    is: true,
    then: Yup.string().required("Type is required"),
  }),
  blog: Yup.object()
    .when("is_blog", {
      is: true,
      then: Yup.object().required("Blog is required").nullable(),
    })
    .nullable(),
});
