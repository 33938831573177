import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConfigs } from "./actions";
import { getConfigFilters, getConfigsList, getCount } from "./selectors";

export const useSettings = () => {
  const [loadingConfig, setLoadingConfig] = useState(false);

  const dispatch = useDispatch();
  const configs = useSelector(getConfigsList);

  const count = useSelector(getCount);
  const filters = useSelector(getConfigFilters);

  useEffect(() => {
    (async () => {
      setLoadingConfig(true);
      await dispatch(getConfigs());
      setLoadingConfig(false);
    })();
  }, [dispatch]);

  return {
    configs,
    loadingConfig,
    count,
    filters,
  };
};
