/* eslint-disable */
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getFieldErrorNames } from "../../utils/helpers";

const ScrollToError = ({ refer }) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;
    let element;
    if (refer) {
      element = refer?.current?.querySelector(
        `input[name='${fieldErrorNames[0]}']`
      );
    } else {
      element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);
    }

    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [submitCount]);

  return null;
};

export default ScrollToError;
