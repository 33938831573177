export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Reason",
    accessor: "reason",
  },
  {
    Header: "Created At",
    accessor: "created_at",
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];

export const fieldsName = {
  reason: "reason",
};
