import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReportOptionsList,
  clearReportOptionsFilter,
  setReportOptionsFilter,
} from "./actions";

import {
  getReasons,
  getRepotOptionFilter,
  getReportOptionCount,
} from "./selectors";

export const useReportOptions = () => {
  const dispatch = useDispatch();
  const list = useSelector(getReasons);
  const filters = useSelector(getRepotOptionFilter);
  const count = useSelector(getReportOptionCount);
  const [loadingOptions, setLoadingOptions] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      setLoadingOptions(true);
      await dispatch(getReportOptionsList(filters));
      setLoadingOptions(false);
    };
    fetchOptions();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setReportOptionsFilter({ key, value }));
  };

  const resetFilter = async () => {
    dispatch(clearReportOptionsFilter());
  };

  return {
    list,
    loadingOptions,
    setFilter,
    filters,
    count,
    resetFilter,
  };
};
