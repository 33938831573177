import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategory, getCategoryList } from "../actions";
import { getCategoriesFilter } from "../selectors";

export const useDeleteCategory = ({ id }) => {
  const dispatch = useDispatch();
  const filter = useSelector(getCategoriesFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteCategoryLoading, setDeleteCategoryLoading] = useState(false);

  const handleDeleteCategory = async () => {
    setDeleteCategoryLoading(true);
    const is_success = await dispatch(deleteCategory(id));
    setDeleteCategoryLoading(false);
    if (is_success) {
      dispatch(getCategoryList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteCategoryLoading,
    handleDeleteCategory,
    isOpenModal,
    toggleModal,
  };
};
