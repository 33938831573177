import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField } from "@material-ui/core";
import { formSchema } from "./validation-schema";
import { ExportFieldsName } from "../../constant";
import { DataLoader } from "../../../../../components/loader";
import DateTimePicker from "../../../../../components/date-picker";

const ExportForm = ({
  downloadPostStatistics,
  isLoading,
  dateError,
  setDateError,
}) => {
  const { from, to } = ExportFieldsName;

  return (
    <Formik
      validateOnMount
      validationSchema={formSchema}
      initialValues={{
        from: "",
        to: "",
      }}
      onSubmit={downloadPostStatistics}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <DateTimePicker
                  selectedDate={rest.values.from}
                  handleChange={(value) => {
                    setFieldValue(from, value);
                    setDateError("");
                  }}
                  id="from"
                  name={from}
                  label="From"
                  customInput={
                    <TextField
                      className="material-form__field"
                      id="from"
                      label="From"
                      autoComplete="off"
                    />
                  }
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={from}
                />
              </div>
              <div className="materila-form_inline-child">
                <DateTimePicker
                  selectedDate={rest.values.to}
                  handleChange={(value) => {
                    setFieldValue(to, value);
                    setDateError("");
                  }}
                  id="to"
                  name={to}
                  label="To"
                  customInput={
                    <TextField
                      className="material-form__field"
                      id="to"
                      label="To"
                      autoComplete="off"
                    />
                  }
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={to}
                />
              </div>
            </div>
            {dateError ? (
              <div className="material-form__error">{dateError}</div>
            ) : null}

            <button
              type="submit"
              disabled={dateError ? true : false}
              className="btn btn-success btn-right btn-sm"
            >
              Export
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default ExportForm;
