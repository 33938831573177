import { api, apiEndPoints } from "../../../../api";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";

const namespace = "containers/video";

export const SET_CATEGORIES_LIST = `${namespace}/SET_CATEGORIES_LIST`;
export const SET_VIDEO_FILTER = `${namespace}/SET_VIDEO_FILTERS`;
export const CLEAR_VIDEO_FILTER = `${namespace}/CLEAR_VIDEO_FILTER`;
export const SET_VIDEO_LIST = `${namespace}/SET_VIDEO_LIST`;
export const SET_USER_LIST = `${namespace}/SET_USER_LIST`;
export const SET_USER_FILTER = `${namespace}/SET_USER_FILTERS`;
export const CLEAR_USER_FILTER = `${namespace}/CLEAR_USER_FILTER`;

const setCategoriesList = (payload) => ({
  type: SET_CATEGORIES_LIST,
  payload,
});

export const setVideoFilter = (payload) => ({
  type: SET_VIDEO_FILTER,
  payload,
});

export const clearVideoFilter = (payload) => ({
  type: CLEAR_VIDEO_FILTER,
  payload,
});

export const setVideoList = (payload) => ({
  type: SET_VIDEO_LIST,
  payload,
});

export const setUserFilter = (payload) => ({
  type: SET_USER_FILTER,
  payload,
});

export const clearUserFilter = (payload) => ({
  type: CLEAR_USER_FILTER,
  payload,
});

export const setUserList = (payload) => ({
  type: SET_USER_LIST,
  payload,
});

export const getCategoriesList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCategories, {
      params: { params },
    });
    const { data: { is_success, categories } = {} } = response;
    if (is_success) {
      dispatch(setCategoriesList(categories));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUsers, {
      params: { params },
    });
    const { data, data: { is_success, users } = {} } = response;
    if (is_success) {
      dispatch(setUserList(data));
      return users;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getVideoList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getVideos, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setVideoList(data));
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addVideo = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addVideo, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Video not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const updateVideo = (id, payload) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateVideo(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Video not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteVideo = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteVideo(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Video not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getSingleVideo = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSingleVideo(id));
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getSingleUser = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getUser(id));
    const { data: { is_success, user_details } = {} } = response;
    if (is_success) {
      return user_details;
    }
    return false;
  } catch (error) {
    return false;
  }
};