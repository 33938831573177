import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBonusSession, getSessionsCountAction } from "../actions";
import { getTranasactionHistoriesAction } from "../actions";
import { getUserTransactionFilter } from "../selectors";

export const useAddBonusSession = ({ user_id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const transaction_filter = useSelector(getUserTransactionFilter);
  const [addUserLoading, setAddUserLoading] = useState(false);

  const handleAddBonusSession = async (values) => {
    setAddUserLoading(true);
    const payload = {
      no_of_sessions: values.no_of_sessions,
      reason: values.reason,
    };
    const is_success = await dispatch(addBonusSession(user_id, payload));
    if (is_success) {
      await dispatch(getTranasactionHistoriesAction(user_id, transaction_filter));
      await dispatch(getSessionsCountAction(user_id));
      toggleModal();
    }
    setAddUserLoading(false);
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    handleAddBonusSession,
    addUserLoading,
    isOpenModal,
    toggleModal,
  };
};
