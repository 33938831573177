import { useState } from "react";
import { statuses } from "../constant";
const { PUBLISHED, IN_REVIEW, UNPUBLISHED, PENDING } = statuses;

export const useUnpublishPost = (props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, data, status } = props;

  const handleDelete = async (values) => {
    const payload = {
      reason: values?.reason,
      status: status === "Published" ? "Unpublished" : "Published",
    };

    try {
      setIsLoading(true);
      await handleSubmit(data?.id, payload);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }

    toggleModal();
  };

  const toggleModal = async () => {
    if (status === UNPUBLISHED || status === IN_REVIEW || status === PENDING) {
      try {
        setIsLoading(true);
        await handleSubmit(data?.id, {
          status: status === PUBLISHED ? UNPUBLISHED : PUBLISHED,
        });
      } catch (error) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    } else {
      !props?.isDisabled && setIsOpenModal(!isOpenModal);
    }
  };

  return {
    isOpenModal,
    toggleModal,
    handleDelete,
    status,
    isLoading,
  };
};
