import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLanguage } from "../../../utils/auth";
import {
  getNoticeList,
  addNotice,
  getEventList,
  resetEventFilter,
} from "../actions";
import { getNoticesFilter } from "../selectors";

export const useAddNotice = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [addNoticeLoading, setAddNoticeLoading] = useState(false);
  const filter = useSelector(getNoticesFilter);

  const handleAddNotice = async (values) => {
    const payload = {
      trackable_type: values.type,
      status: values.status,
      trackable_id: values.event_id.id || values.blog_id.id || 0,
      title: values.title,
      description: values.description,
      short_description: values.short_description,
      redirect_to: values.redirect_to,
      details: {
        user_event_schedule_statuses: values.booking_status.map(
          (item) => item.value
        ),
      },
    };

    setAddNoticeLoading(true);
    const is_success = await dispatch(addNotice(payload));
    dispatch(getEventList(filter));
    setAddNoticeLoading(false);
    if (is_success) {
      dispatch(getNoticeList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    dispatch(resetEventFilter());
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    addNoticeLoading,
    handleAddNotice,
    isOpenModal,
    toggleModal,
  };
};
