import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editPopularKeyword, getPopularKeywordData, getPopularKeywordList } from "../actions";
import { getPopularKeywordFilter } from "../selectors";

export const useAddPopularKeyword = ({ id }) => {
  const dispatch = useDispatch();
  const [EditPopularKeywordLoading, setEditPopularKeywordLoading] = useState(false);
  const [selectedPopularKeyword, setSelectedPopularKeyword] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const filters = useSelector(getPopularKeywordFilter)

  const getPopularKeywordById = async () => {
    setEditPopularKeywordLoading(true);
    const { popular_keyword } = await dispatch(getPopularKeywordData(id));
    if (popular_keyword) {
      setSelectedPopularKeyword(popular_keyword)
      toggleModal();
    }
    setEditPopularKeywordLoading(false);
  };

  const handleEditPopularKeyword = async (values) => {
    try {
      setEditPopularKeywordLoading(true);
      const payload = {
        title: values.title,
        status: values.status,
      };
      setEditPopularKeywordLoading(false);
      const is_success = await dispatch(editPopularKeyword(payload, id));
      if (is_success) {
        dispatch(getPopularKeywordList(filters));
        toggleModal();
      }
      setEditPopularKeywordLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    EditPopularKeywordLoading,
    handleEditPopularKeyword,
    selectedPopularKeyword,
    getPopularKeywordById,
    toggleModal,
    isOpenModal,
  };
};
