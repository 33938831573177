import React from "react";
import PostForm from "../form/form";
import { useEditPost } from "./hooks";
import Modals from "../../../../components/modal";
import { useUser } from "../../../../common-hooks/user";
import ActionButton from "../../../../components/action-button";

const EditPost = ({ post }) => {
  const {
    isOpenModal,
    toggleModal,
    editPostLoading,
    handleEditPost,
    getPostData,
    selectedPost,
    isEdit,
  } = useEditPost(post);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <>
      <ActionButton
        title="Edit"
        iconClassName="fa fa-edit icon-btn-action"
        onClick={getPostData}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PostForm
              fieldValues={selectedPost}
              handlePostForm={handleEditPost}
              btn="Save"
              isLoading={editPostLoading}
              isEdit={isEdit}
            />
          }
          title="Edit Post"
        />
      ) : null}
    </>
  );
};

export default EditPost;
