import React from "react";
import DataTable from "../../components/table";
import { useEvent } from "./hooks";
import { columns, eventStatus, typeOptions } from "./constant";
import AddEvent from "./add-event";
import SearchFilter from "../../components/filters/search-filter";
import SelectFilter from "../../components/filters/select-filter";
import EditEvent from "./edit-event";
import DeleteEvent from "./delete-event";
import Reviews from "./reviews";
import PostCaption from "../social/posts/components/post-caption";
import { TABLE_CAPTION_CHAR_LENGTH } from "../../constants";

const Events = () => {
  const { events, loadingEvent, setFilter, filters, eventCount } = useEvent();
  const configureTableData = () => {
    try {
      let results = events.map((event) => {
        let newEvent = Object.assign({}, event);
        newEvent["desc_html"] = (
          <PostCaption
            className="mentioned_data"
            captionText={event.description}
            ranges={[]}
            charLength={TABLE_CAPTION_CHAR_LENGTH}
          />
        );

        newEvent["type"] = event.is_course ? "Course" : "Event";
        newEvent["action"] = (
          <div className="categories__list-action">
            <EditEvent event={event} />
            <DeleteEvent event={event} />
            <Reviews event={event} />
          </div>
        );
        return newEvent;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results || [],
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const userFilter = () => (
    <>
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters.is_course}
        filterKey="is_course"
        options={typeOptions}
        filterTitle="Type"
      />
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search Event"
        filterKey="text"
        filterTitle="Event"
        searchedValue={filters.text}
      />
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters.status}
        filterKey="status"
        options={eventStatus}
        filterTitle="Status"
      />
    </>
  );
  return (
    <div>
      <DataTable
        title="Events"
        addBtn={<AddEvent />}
        CreateTableData={configureTableData}
        withFilter={true}
        filter={userFilter}
        handleSetFilter={setFilter}
        count={eventCount}
        skip={filters?.skip}
        isLoading={loadingEvent}
      />
    </div>
  );
};

export default Events;
