import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSideBarVisibility } from "../../Layout/action";
import { getUser, getUserInterests, getPlanDetails } from "./actions";

export const useUserDetails = ({
  match: {
    params: { user_id },
  },
}) => {
  const dispatch = useDispatch();
  const [viewUserLoading, setViewUserLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [interests, setInterests] = useState([]);
  const [fullAddress, setFullAddress] = useState("");
  const [source, setSource] = useState({});
  const [plan, setPlan] = useState({});
  const [device, setDevice] = useState({});

  useEffect(() => {
    const getUserIntrestsData = async () => {
      const userIntrests = await dispatch(getUserInterests(user_id));
      if (userIntrests) {
        setInterests(userIntrests);
      }
    };

    const getUserFieldData = async () => {
      setViewUserLoading(true);
      const userDetails = await dispatch(getUser(user_id));
      if (userDetails) {
        setSelectedUser(userDetails);
        let user = userDetails.profile;

        let address = "";
        if (user.address) {
          address = user.address;
        }
        if (user.city && address) {
          address = address + ", " + user.city;
        } else if (user.city) {
          address = user.city;
        }
        if (user.state && address) {
          address = address + ", " + user.state;
        } else if (user.state) {
          address = user.state;
        }
        if (user.pincode && address) {
          address = address + ", " + user.pincode;
        } else if (user.pincode) {
          address = user.pincode;
        }
        setFullAddress(address);
        setViewUserLoading(false);
        setSource(userDetails?.source);
        setDevice(userDetails?.user);
      }
    };
    getUserIntrestsData();
    getUserFieldData();
    dispatch(setSideBarVisibility(true));
  }, [user_id, dispatch]);

  useEffect(() => {
    (async () => {
      const userPlan = await dispatch(getPlanDetails(user_id));
      if (userPlan) setPlan(userPlan);
    })();
  }, [user_id, dispatch]);

  return {
    selectedUser,
    viewUserLoading,
    interests,
    fullAddress,
    source,
    plan,
    device,
  };
};
