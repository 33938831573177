export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Category",
    accessor: "category",
  },
  {
    Header: "Question",
    accessor: "question",
  },
  {
    Header: "Answer",
    accessor: "answer",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];

export const faqStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
];

export const fieldsName = {
  question: "question",
  answer: "answer",
  status: "status",
  category: "category",
  language: "language",
};

export const categoryParams = {
  status: "Published",
  type: "Faq",
};
