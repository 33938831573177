import {
  RESET_PARTNERS_FILTER,
  SET_PARTNERS_FILTER,
  SET_PARTNERS_LIST,
} from "./actions";

const initialList = {
  partners: [],
  count: 0,
  filter: {
    status: "",
    name: "",
    limit: 10,
    skip: 0,
  },
};

const partnerReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_PARTNERS_LIST:
      return {
        ...state,
        partners: action.payload.partners,
        count: action.payload.count || 0,
      };

    case SET_PARTNERS_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case RESET_PARTNERS_FILTER:
      return { ...state, filter: initialList.filter || [] };
    default:
      return state;
  }
};

export default partnerReducer;
