/* eslint-disable */
import { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useDispatch, useSelector } from "react-redux";
import { getSignedUrl, uploadFiles } from "../../utils/upload-file/actions";

export const useTextEditor = ({ initialValues, onChange, language }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [initialCopy, setInitialCopy] = useState(initialValues);
  const dispatch = useDispatch();

  useEffect(() => {
    const blocksFromHTML = htmlToDraft(initialValues);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditorState(EditorState.createWithContent(state));
    return () => setInitialCopy("");
  }, [initialCopy, language]);

  const onEditorStateChange = (items) => {
    setEditorState(items);
    if (onChange) {
      onChange(draftToHtml(convertToRaw(items.getCurrentContent())));
    }
  };

  const handlePastedText = (text, html, editorState, onChange) => {
    if (html) {
      let convertedHtml;
      if (html.indexOf("<figure") != -1) {
        convertedHtml = html.replace(/(<\/?)figure((?:\s+.*?)?>)/g, "");
      } else {
        convertedHtml = html;
      }
      const data = `<p><p>${convertedHtml}</p></p>`;

      let { contentBlocks, entityMap } = htmlToDraft(data);
      let contentState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        ).getBlockMap()
      );

      onChange(EditorState.push(editorState, contentState, "insert-fragment"));
      return true;
    } else if (text) {
      return false;
    }
    return false;
  };

  const uploadImageCallBack = async (file) => {
    const response = await dispatch(getSignedUrl(file.name));
    const { uuid_file_name, original_file_name, signed_url } = response;
    if (!signed_url) {
      return false;
    }
    const fileData = {
      uuid_file_name,
      original_file_name,
      file_context: "Blog Editor Image",
    };
    const sucess = await dispatch(uploadFiles(signed_url, file, fileData, {}));
    if (!sucess) {
      return false;
    }

    if (response.public_url) {
      return new Promise((resolve, reject) => {
        resolve({ data: { link: response.public_url } });
      });
    }
  };

  const ToolbarOptions = {
    options: [
      "colorPicker",
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "image",
      "textAlign",
      "link",
      "emoji",
      "embedded",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    image: {
      uploadCallback: uploadImageCallBack,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      previewImage: true,
      defaultSize: {
        height: "auto",
        width: "auto",
      },
    },
    fontFamily: {
      options: ["Arial", "Avenir Next"],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontSize: {
      options: [8, 10, 12, 14, 16, 18, 24],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
  };

  return {
    ToolbarOptions,
    initialValues,
    editorState,
    onEditorStateChange,
    handlePastedText,
  };
};
