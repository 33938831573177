import * as Yup from "yup";
import { get, isEmpty } from "lodash";
import { checkFields } from "../../../utils/validation";

const requiredFields = ["title", "description"];

const uniquePropertyTest = function (value, propertyName, message) {
  if (
    this.parent
      .filter((v) => v !== value)
      .some((v) => get(v, propertyName) === get(value, propertyName))
  ) {
    throw this.createError({
      path: `${this.path}.${propertyName}`,
      message,
    });
  }

  return true;
};

Yup.addMethod(Yup.object, "uniqueProperty", function (propertyName, message) {
  return this.test("unique", message, function (value) {
    return uniquePropertyTest.call(this, value, propertyName, message);
  });
});

Yup.addMethod(Yup.object, "uniqueProperties", function (propertyNames) {
  return this.test("unique", "", function (value) {
    const errors = propertyNames
      .map(([propertyName, message]) => {
        try {
          return uniquePropertyTest.call(this, value, propertyName, message);
        } catch (error) {
          return error;
        }
      })
      .filter((error) => error instanceof Yup.ValidationError);
    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }
    return true;
  });
});
export const planFormSchema = Yup.object().shape({
  code: Yup.string().required("Type is required"),
  title: Yup.mixed().test(
    "Title required",
    "Enter valid title for all languages",
    function () {
      if (!this?.parent?.title?.en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "title");
    }
  ),
  description: Yup.mixed().test(
    "Description required",
    "Enter valid description for all languages",
    function () {
      if (!this?.parent?.description?.en) {
        return false;
      }
      return checkFields(this.parent, requiredFields, "description");
    }
  ),
  type: Yup.string().required("Type is required"),
  partner_offer_text: Yup.string(),
  card_bg_start_color: Yup.string(),
  card_bg_end_color: Yup.string(),
  card_text_color: Yup.string(),
  no_of_session: Yup.number()
    .required("No of session is required")
    .min(1, "Minimum value is 1"),
  plan_amount: Yup.number()
    .required("Amount is required")
    .when("code", {
      is: "Silver",
      then: Yup.number().min(1, "Minimum Amount is 1"),
    })
    .when("code", {
      is: "Bronze",
      then: Yup.number().min(1, "Minimum Amount is 1"),
    })
    .when("code", {
      is: "Gold",
      then: Yup.number().min(1, "Minimum Amount is 1"),
    })
    .when("code", {
      is: "TopUp",
      then: Yup.number().min(1, "Minimum Amount is 1"),
    })
    .when("code", {
      is: "Free",
      then: Yup.number().min(0, "Minimum Amount is 0"),
    }),
  carry_forward: Yup.number()
    .when("code", {
      is: "Silver",
      then: Yup.number()
        .min(0, "Minimum Amount is 0")
        .required("Carry Forward is required"),
    })
    .when("code", {
      is: "Bronze",
      then: Yup.number()
        .min(0, "Minimum Amount is 0")
        .required("Carry Forward is required"),
    })
    .when("code", {
      is: "Gold",
      then: Yup.number()
        .min(0, "Minimum Amount is 0")
        .required("Carry Forward is required"),
    })
    .when("code", {
      is: "TopUp",
      then: Yup.number(),
    })
    .when("code", {
      is: "Free",
      then: Yup.number()
        .min(0, "Minimum Amount is 0")
        .required("Carry Forward is required"),
    }),
  status: Yup.string().required("Status is required"),
  variants: Yup.array().of(
    Yup.object()
      .shape({
        no_of_sessions: Yup.number()
          .typeError("No of sessions is required")
          .required("No of sessions is required")
          .min(1, "Minimum value is 1"),
        partner_offer_ids: Yup.array(),
        duration: Yup.number()
          .typeError("Duration is required")
          .required("Duration is required")
          .min(0, "Minimum value is 0"),
        discount_type: Yup.string().required("Discount Type is required"),
        discount: Yup.number()
          .typeError("Discount is required")
          .required("Discount is required")
          .min(0, "Minimum value is 0"),
        amount: Yup.number()
          .required("Amount is required")
          .test(
            "Is positive?",
            "Amount must be greater than 0",
            (value) => value >= 0
          ),
        gross_amount: Yup.number()
          .required("Gross Amount is required")
          .test(
            "Is positive?",
            "Gross Amount must be greater than or equal to 0",
            (value) => value >= 0
          ),
      })
      .uniqueProperty("no_of_sessions", "No of sessions must be unique")
  ),
});
