import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField } from "@material-ui/core";
import { fieldsName } from "../constant";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../utils/form";
import { DataLoader } from "../../../../components/loader";
import { useMetaDataForm } from "./hooks";
import { metaFormSchema } from "./validation-schema";

const MetaDataForm = ({ fieldValues, handleMetaDataForm, btn, isLoading }) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const { formValues, handleFormUpdate } = useMetaDataForm({
    fieldValues,
    handleMetaDataForm,
  });

  const { page_url, meta_description, meta_keywords, meta_title } = fieldsName;

  return (
    <Formik
      validationSchema={metaFormSchema}
      validateOnMount
      enableReinitialize
      initialValues={{
        meta_title: formValues?.meta_title || "",
        meta_description: formValues?.meta_description || "",
        meta_keywords: formValues?.meta_keywords || "",
        page_url: formValues?.page_url || "",
      }}
      onSubmit={handleMetaDataForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <TextField
              className="material-form__field"
              name={page_url}
              placeholder="Page URL"
              label="Page URL"
              type="text"
              value={rest.values.page_url}
              onChange={(event) => {
                const { name, value } = event.target;
                handleFormUpdate(name, value);
                setFieldValue(name, value);
              }}
              onFocus={() => handleFocusField(page_url)}
              onBlur={() => handleBlurField()}
            />
            {focusField === page_url ? null : (
              <ErrorMessage
                render={(msg) => (
                  <div className="material-form__error">{msg}</div>
                )}
                name={page_url}
              />
            )}

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={meta_title}
                placeholder="Meta Title"
                label="Meta Title"
                type="text"
                value={rest.values.meta_title}
                onChange={(event) => {
                  const { name, value } = event.target;
                  handleFormValueChange(rest, name);
                  handleFormUpdate(name, value);
                }}
                onFocus={() => handleFocusField(meta_title)}
                onBlur={() => handleBlurField()}
              />
              {focusField === meta_title ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={meta_title}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={meta_description}
                placeholder="Meta Descripion"
                label="Meta Descripion"
                type="text"
                value={rest.values.meta_description}
                onChange={(event) => {
                  const { name, value } = event.target;
                  handleFormValueChange(rest, name);
                  handleFormUpdate(name, value);
                }}
                onFocus={() => handleFocusField(meta_description)}
                onBlur={() => handleBlurField()}
              />
              {focusField === meta_description ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={meta_description}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={meta_keywords}
                placeholder="Meta Keywords"
                label="Meta Keywords"
                type="text"
                value={rest.values.meta_keywords}
                onChange={(event) => {
                  const { name, value } = event.target;
                  handleFormValueChange(rest, name);
                  handleFormUpdate(name, value);
                }}
                onFocus={() => handleFocusField(meta_keywords)}
                onBlur={() => handleBlurField()}
              />
              {focusField === meta_keywords ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={meta_keywords}
                />
              )}
            </div>

            <button
              type="submit"
              className="btn btn-success btn-right btn-sm mt-4"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default MetaDataForm;
