import {
  SET_CATEGORY_LIST,
  SET_CATEGORY_FILTER,
  RESET_CATEGORY_FILTER,
  SET_INTERESTS_LIST,
} from "./actions";

const initialList = {
  categories: [],
  interests: [],
  count: 0,
  filter: {
    type: "",
    status: "Published",
    limit: 10,
    skip: 0,
  },
};

const categoryReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_CATEGORY_LIST:
      return { ...state, categories: action.payload.categories, count: action.payload.count || [] };
    case SET_CATEGORY_FILTER: {
      const { key, value } = action.payload;
      if (key === "status") {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value,
            skip: 0,
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case RESET_CATEGORY_FILTER:
      return { ...state, filter: initialList.filter || {} };

    case SET_INTERESTS_LIST:
      return { ...state, interests: action.payload || [] };
    default:
      return state;
  }
};

export default categoryReducer;
