import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePopularKeywordData, getPopularKeywordList } from "../actions";
import { getPopularKeywordFilter } from "../selectors";

export const useDeletePopularKeyword = (id) => {
  const dispatch = useDispatch();
  const filter = useSelector(getPopularKeywordFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deletePopularKeywordLoading, setDeletePopularKeywordLoading] = useState(false);

  const handleDeletePopularKeyword = async () => {
    setDeletePopularKeywordLoading(true);
    const is_success = await dispatch(deletePopularKeywordData(id));
    setDeletePopularKeywordLoading(false);
    if (is_success) {
      dispatch(getPopularKeywordList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deletePopularKeywordLoading,
    handleDeletePopularKeyword,
    isOpenModal,
    toggleModal,
  };
};
