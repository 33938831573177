import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteInterest } from "../actions";

export const useDeleteInterest = ({ id }) => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteInterestLoading, setDeleteInterestLoading] = useState(false);

  const handleDeleteInterest = async () => {
    setDeleteInterestLoading(true);
    const is_success = await dispatch(deleteInterest(id));
    setDeleteInterestLoading(false);
    if (is_success) {
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteInterestLoading,
    handleDeleteInterest,
    isOpenModal,
    toggleModal,
  };
};
