import { useSelector } from "react-redux";

import { userRoles } from "../constants";

export const useUser = () => {
  const loggedInUser = useSelector(
    ({ authReducer }) => authReducer.loggedInUser
  );
  const { user: { role = "", id } = {} } = loggedInUser;

  const isAdmin = role === userRoles.ADMIN;
  const isCoordinator = role === userRoles.CO_ORDINATOR;
  const isMember = role === userRoles.MEMBER;
  const isModerator = role === userRoles.MODERATOR;
  const isCSSpecialist = role === userRoles.CS_SPECIALIST;

  const getFinalUserFilter = (inpArray) => {
    let finalObject =  {}

    if (inpArray?.length < 1) {
        return finalObject;
    }
    
    inpArray.map((option) => {
        switch (option?.value) {
            case 'Active':
                finalObject['is_active'] = true;
                break;
            case 'Inactive':
                finalObject['is_active'] = false;
                break;
            case 'Delete':
                finalObject['is_deleted'] = true;
                finalObject['deleted'] = false;
                break;
            case 'Deleted':
                finalObject['deleted'] = true;
                break;
            case 'Blacklisted':
                finalObject['is_blacklisted'] = true;
                break;
            case 'All':
                finalObject = {}
                break;
            default:
                break;
        } 
    })

    return finalObject;
}

  return {
    isAdmin,
    isCoordinator,
    isMember,
    isModerator,
    isCSSpecialist,
    role,
    id,
    getFinalUserFilter
  };
};
