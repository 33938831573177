import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import Modals from "../../../../components/modal";
import PolicyForm from "../form/policyForm";
import { useEditPolicy } from "./hooks";
import { useUser } from "../../../../common-hooks/user";

const EditPolicy = ({ policy }) => {
  const {
    editPolicyLoading,
    getPolicyFieldData,
    handleEditPolicy,
    isOpenModal,
    selectedPolicy,
    toggleModal,
  } = useEditPolicy(policy);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action `}
          onClick={getPolicyFieldData}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <PolicyForm
              fieldValues={selectedPolicy}
              handlePolicyForm={handleEditPolicy}
              btn="Save"
              isLoading={editPolicyLoading}
            />
          }
          title="Edit Policy"
        />
      ) : null}
    </>
  );
};

export default EditPolicy;
