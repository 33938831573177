import { SNACKBAR_SHOW, SNACKBAR_CLEAR } from "./actions";

const initialState = {
  snackbarOpen: false,
  snackbarMessage: "",
  snackbarType: "success",
};
const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_SHOW:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.payload.message,
        snackbarType: action.payload.type,
      };
    case SNACKBAR_CLEAR:
      return {
        ...state,
        snackbarOpen: false,
      };
    default:
      return state;
  }
};

export default snackbarReducer;
