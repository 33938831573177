import React from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";

const Modals = ({
  color,
  title,
  message,
  colored,
  header,
  isOpenModal,
  setIsOpenModal,
  isDownloadFile,
  downloadFile,
  className
}) => {
  const toggle = () => {
    setIsOpenModal();
  };

  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggle}
      className={`modal-dialog--${color} ${modalClass} theme-light ${className}`}
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
        {isDownloadFile && (
          <Tooltip title="Download Attendees" placement="bottom">
            <i
              className="fa fa-download modal__download-btn"
              onClick={downloadFile}
            ></i>
          </Tooltip>
        )}
        <h4 className="text-modal  modal__title">{title}</h4>
      </div>
      <div className="modal__body">{message}</div>
    </Modal>
  );
};

Modals.propTypes = {
  title: PropTypes.string.isRequired,
  //message: PropTypes.isRequired,
  color: PropTypes.string,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  isDownloadFile: PropTypes.bool,
  downloadFile: PropTypes.func,
};

Modals.defaultProps = {
  title: "",
  message: "",
  colored: false,
  header: true,
  color: "dark-green",
  isDownloadFile: false,
};

export default Modals;
