import React from "react";
import { useEditBanner } from "./hooks";
import Modals from "../../../../components/modal";
import BannerForm from "../form/banner-form";
import { useUser } from "../../../../common-hooks/user";
import ActionButton from "../../../../components/action-button";

const EditBanner = (props) => {
  const {
    EditBannerLoading,
    handleEditBanner,
    isOpenModal,
    getBannerData,
    toggleModal,
    selectedBanner,
  } = useEditBanner(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <ActionButton
        title="Edit"
        onClick={() => {
          getBannerData();
        }}
        iconClassName={`fa fa-edit icon-btn-action`}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <BannerForm
              handleBannerForm={handleEditBanner}
              btn="Save"
              isLoading={EditBannerLoading}
              fieldValues={selectedBanner}
            />
          }
          title="Edit"
        />
      ) : null}
    </div>
  );
};

export default EditBanner;
