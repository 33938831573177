import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOfferFilters,
  getOffers,
  getOffersCount,
  getPartnerFilter,
} from "./selectors";
import {
  getOfferList,
  getPartnersList,
  resetOfferFilter,
  setOfferFilter,
} from "./actions";
import { clearLanguage } from "../../utils/auth";

export const useOffer = (props) => {
  const [loadingOffers, setLoadingOffers] = useState(false);
  const offerFilter = useSelector(getOfferFilters);
  const offers = useSelector(getOffers);
  const count = useSelector(getOffersCount);
  const dispatch = useDispatch();
  const partner_filter = useSelector(getPartnerFilter);

  useEffect(() => {
    clearLanguage();
    (async () => {
      setLoadingOffers(true);
      await dispatch(getOfferList(offerFilter));
      setLoadingOffers(false);
    })();
  }, [dispatch, offerFilter]);

  useEffect(() => {
    (async () => {
      await dispatch(getPartnersList(partner_filter));
    })();
  }, [partner_filter, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setOfferFilter({ key, value }));
  };

  const resetFilter = useCallback(() => {
    dispatch(resetOfferFilter());
  }, [dispatch]);

  return {
    offers,
    count,
    resetFilter,
    setFilter,
    loadingOffers,
    offerFilter,
  };
};