import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";
import { clearFiles } from "../../utils/upload-file/actions";

const namespace = "containers/events";

export const SET_EVENT_LIST = `${namespace}/SET_EVENT_LIST`;
export const SET_CATEGORIES_LIST = `${namespace}/SET_CATEGORIES_LIST`;
export const SET_SUBCATEGORIES_LIST = `${namespace}/SET_SUBCATEGORIES_LIST`;
export const SET_FAQ_CATEGORIES_LIST = `${namespace}/SET_FAQ_CATEGORIES_LIST`;
export const SET_EVENT_FILTER = `${namespace}/SET_EVENT_FILTER`;
export const RESET_EVENT_FILTER = `${namespace}/RESET_EVENT_FILTER`;
export const SET_EVENT_REVIEWS = `${namespace}/SET_EVENT_REVIEWS,`;

const setEventList = (payload) => ({
  type: SET_EVENT_LIST,
  payload,
});

const setCategoriesList = (payload) => ({
  type: SET_CATEGORIES_LIST,
  payload,
});

const setSubcategoriesList = (payload) => ({
  type: SET_SUBCATEGORIES_LIST,
  payload,
});

const setFAQCategoriesList = (payload) => ({
  type: SET_FAQ_CATEGORIES_LIST,
  payload,
});

export const setEventFilter = (payload) => ({
  type: SET_EVENT_FILTER,
  payload,
});

export const setEventReviews = (payload) => ({
  type: SET_EVENT_REVIEWS,
  payload,
});

export const resetEventFilter = () => ({
  type: RESET_EVENT_FILTER,
});

export const getEventList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEvents, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setEventList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getEvent = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEvent(id));
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addEvent = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createEvents, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      dispatch(clearFiles());
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );

    return false;
  } catch (error) {
    return false;
  }
};

export const editEvent = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateEvents(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      dispatch(clearFiles());
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );

    return false;
  } catch (error) {
    return false;
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteEvents(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getCategoriesList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCategories, {
      params: { params },
    });
    const { data: { is_success, categories } = {} } = response;
    if (is_success) {
      dispatch(setCategoriesList(categories));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getSubcategoriesList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSubategories, {
      params: { params },
    });
    const { data: { is_success, sub_categories } = {} } = response;
    if (is_success) {
      dispatch(setSubcategoriesList(sub_categories));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getFaqCategoryList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCategories, {
      params: { params },
    });
    const { data: { is_success, categories } = {} } = response;
    if (is_success) {
      dispatch(setFAQCategoriesList(categories));
      return categories;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getEventReviews = (id, params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEventReview(id), {
      params: { params },
    });
    const {
      data: { reviews, is_success },
    } = response;
    if (is_success) {
      dispatch(setEventReviews(reviews));
      return reviews;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const publishUnpublishReview = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(
      apiEndPoints.changeReviewStatus(id),
      payload
    );
    const {
      data,
      data: { is_success, message },
    } = response;

    if (is_success) {
      return data;
    }

    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};
