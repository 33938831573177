import React from "react";
import { Collapse } from "reactstrap";
import PropTypes from "prop-types";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { useAccordion } from "./hooks";

const Accordion = ({
  className,
  title,
  children,
  titleClassName,
  isOpen,
  onToggle,
}) => {
  const { toggle, collapse } = useAccordion();

  return (
    <div className={`collapse__wrapper ${className}`}>
      <button
        onClick={onToggle ? onToggle : toggle}
        className="collapse__title accordion_title-container"
        type="button"
      >
        <span className={titleClassName}>{title}</span>
        <p>{collapse ? <ChevronUpIcon /> : <ChevronDownIcon />}</p>
      </button>
      <Collapse isOpen={isOpen || collapse} className="collapse__content">
        <div className="accordion-content">{children}</div>
      </Collapse>
    </div>
  );
};

Accordion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.array.isRequired,
  ]),
  title: PropTypes.element.isRequired,
};

Accordion.defaultProps = {
  title: "",
  className: "",
  titleClassName: "accordion-title",
};

export default Accordion;
