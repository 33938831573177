import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteEvent, getEventList } from "../actions";
import { getEventsFilter } from "../selectors";

export const useDeleteEvent = ({ id }) => {
  const dispatch = useDispatch();
  const filter = useSelector(getEventsFilter);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteEventLoading, setDeleteEventLoading] = useState(false);

  const handleDeleteEvent = async () => {
    setDeleteEventLoading(true);
    const is_success = await dispatch(deleteEvent(id));
    setDeleteEventLoading(false);
    if (is_success) {
      dispatch(getEventList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteEventLoading,
    handleDeleteEvent,
    isOpenModal,
    toggleModal,
  };
};
