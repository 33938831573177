import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Chip } from "@material-ui/core";
import PropTypes from "prop-types";

function ProgressChip({ fileName, uploadProgress }) {
  return (
    <div className="progress-chip">
      <Chip
        className="circular-spinner selected-file"
        key={fileName}
        label={fileName}
        variant="outlined"
      />
      <CircularProgress
        style={{
          width: "22px",
          height: "22px",
        }}
        variant="determinate"
        value={uploadProgress}
      />
    </div>
  );
}

ProgressChip.propTypes = {
  fileName: PropTypes.string.isRequired,
  uploadProgress: PropTypes.number.isRequired,
};
export default ProgressChip;
