import React from "react";
import { Formik, ErrorMessage } from "formik";
import { MenuItem, TextField } from "@material-ui/core";
import { formSchema } from "./validation-schema";
import { DataLoader } from "../../../../components/loader";
import DateTimePicker from "../../../../components/date-picker";
import {
  usersExportStatus,
  ExportFieldsName,
  userRoles,
  userAge,
} from "../../constant";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../utils/form";
import Autocomplete from "@material-ui/lab/Autocomplete";

const ExportForm = ({
  downloadUserReport,
  isLoading,
  dateError,
  setDateError,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const { startsAt, endsAt, status, role, age_group } = ExportFieldsName;

  return (
    <Formik
      validateOnMount
      validationSchema={formSchema}
      initialValues={{
        startsAt: "",
        endsAt: "",
        status: "",
        role: [],
        age_group: [],
      }}
      onSubmit={downloadUserReport}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <DateTimePicker
                  selectedDate={rest.values.startsAt}
                  handleChange={(value) => {
                    setFieldValue(startsAt, value);
                    setDateError("");
                  }}
                  id="startsAt"
                  name={startsAt}
                  label="Starts At"
                  customInput={
                    <TextField
                      className="material-form__field"
                      id="startsAt"
                      label="From"
                      autoComplete="off"
                    />
                  }
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={startsAt}
                />
              </div>
              <div className="materila-form_inline-child">
                <DateTimePicker
                  selectedDate={rest.values.endsAt}
                  handleChange={(value) => {
                    setFieldValue(endsAt, value);
                    setDateError("");
                  }}
                  id="endsAt"
                  name={endsAt}
                  label="Ends At"
                  customInput={
                    <TextField
                      className="material-form__field"
                      id="endsAt"
                      label="To"
                      autoComplete="off"
                    />
                  }
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={endsAt}
                />
              </div>
            </div>
            {dateError ? (
              <div className="material-form__error">{dateError}</div>
            ) : null}

            <div className="materila-form_inline-child">
              <Autocomplete
                multiple
                options={userRoles}
                getOptionLabel={(role) =>
                  role.displayText ? role.displayText : ""
                }
                value={rest.values.role}
                onChange={(event, value) => {
                  setFieldValue(role, value);
                }}
                onFocus={() => handleFocusField(role)}
                onBlur={() => {
                  handleBlurField();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={role}
                    placeholder="Select Role"
                    label="Select Role"
                    variant="standard"
                  />
                )}
              />

              {focusField === role ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={role}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <Autocomplete
                options={userAge}
                getOptionLabel={(age_group) =>
                  age_group.displayText ? age_group.displayText : ""
                }
                value={rest.values.age_group}
                onChange={(event, value) => {
                  setFieldValue(age_group, value);
                }}
                onFocus={() => handleFocusField(age_group)}
                onBlur={() => {
                  handleBlurField();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={age_group}
                    placeholder="Select Age"
                    label="Select Age"
                    variant="standard"
                  />
                )}
              />

              {focusField === age_group ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={age_group}
                />
              )}
            </div>
          
            <Autocomplete
              size="small"
              multiple
              id="tags-outlined"
              options={usersExportStatus}
              getOptionLabel={option => option?.displayText}
              filterSelectedOptions
              onChange={(event, value) => {
                setFieldValue(status, value);
              }}
              className='users__status_multiselect'
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={rest?.values?.status?.length > 0 ? '' :"All Status"}  
                />
              )}
            />
            <button
              type="submit"
              disabled={dateError ? true : false}
              className="btn btn-success btn-right btn-sm"
            >
              Export
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default ExportForm;
