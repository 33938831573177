import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";

import { dynamicCoupounCodeList, fieldsName, offerStatus } from "../constant";
import { DataLoader } from "../../../components/loader";
import { offerFormSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";
import { useOfferForm } from "./hooks";
import TextfieldChips from "../../../components/text-field-chips";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  initialLanguageArrayValue,
  initialLanguageFieldValue,
  languages,
} from "../../../constants";
import { Tooltip } from "@material-ui/core";
import { setLanguage } from "../../../utils/auth";

const OfferForm = ({
  fieldValues,
  handleOfferForm,
  btn,
  isLoading,
  handleLanguageData,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const { partners, setPartnerFilters, handleLanguage } = useOfferForm();

  const {
    plan_offer_text,
    offer_text,
    my_offer_text,
    status,
    validity,
    description,
    no_of_coupon_codes,
    dynamic_coupon_code,
    how_to_use,
    terms_and_conditions,
    amount,
    cta_name,
    cta_url,
    partner_id,
    language,
  } = fieldsName;

  return (
    <Formik
      validateOnMount
      validationSchema={offerFormSchema}
      initialValues={{
        offer_text: fieldValues?.offer_text || initialLanguageFieldValue,
        plan_offer_text:
          fieldValues?.plan_offer_text || initialLanguageFieldValue,
        my_offer_text: fieldValues?.my_offer_text || initialLanguageFieldValue,
        status: fieldValues?.status || "",
        validity: fieldValues?.validity || "",
        description: fieldValues?.description || initialLanguageFieldValue,
        no_of_coupon_codes: fieldValues?.no_of_coupon_codes || 0,
        duration: fieldValues?.duration || "",
        dynamic_coupon_code: fieldValues?.dynamic_coupon_code || false,
        how_to_use: fieldValues?.how_to_use || initialLanguageArrayValue,
        terms_and_conditions: fieldValues?.terms_and_conditions || "",
        amount: fieldValues?.amount || "",
        cta_name: fieldValues?.cta_name || initialLanguageFieldValue,
        cta_url: fieldValues?.cta_url || "",
        language: fieldValues?.language || "en",
        partner_id: fieldValues?.partner_id
          ? partners.find((partner) => partner.id === fieldValues?.partner_id)
          : null,
      }}
      onSubmit={handleOfferForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            {!isLoading && (
              <div className="materila-form_inline-child sticky__element">
                <TextField
                  className="material-form__field"
                  name={language}
                  placeholder="Language"
                  label="Language"
                  select
                  value={rest.values.language}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    handleFormValueChange(rest, language);
                    setFieldValue(name, value);

                    if (!rest.values.offer_text[value]) {
                      setFieldValue(offer_text, {
                        ...rest.values.offer_text,
                        [value]: "",
                      });
                    }

                    if (!rest.values.how_to_use[value]) {
                      setFieldValue(how_to_use, {
                        ...rest.values.how_to_use,
                        [value]: [],
                      });
                    }
                    if (!rest.values.my_offer_text[value]) {
                      setFieldValue(my_offer_text, {
                        ...rest.values.my_offer_text,
                        [value]: "",
                      });
                    }
                    if (!rest.values.plan_offer_text[value]) {
                      setFieldValue(plan_offer_text, {
                        ...rest.values.plan_offer_text,
                        [value]: "",
                      });
                    }
                    if (!rest.values.description[value]) {
                      setFieldValue(description, {
                        ...rest.values.description,
                        [value]: "",
                      });
                    }
                    if (!rest.values.cta_name[value]) {
                      setFieldValue(cta_name, {
                        ...rest.values.cta_name,
                        [value]: "",
                      });
                    }

                    if (btn === "Save") {
                      setLanguage(value);
                      handleLanguageData(rest, setFieldValue);
                    }
                  }}
                  onFocus={() => handleFocusField(language)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.language}
                >
                  {languages.map((language) => (
                    <MenuItem
                      key={language.id}
                      className="material-form__option"
                      value={language.value}
                    >
                      {language.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === language ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={language}
                  />
                )}
              </div>
            )}

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={offer_text}
                  placeholder="Offer Text"
                  label="Offer Text"
                  type="text"
                  value={rest.values.offer_text[rest.values.language]}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleFormValueChange(rest, name);
                    handleLanguage(rest, name, value, setFieldValue);
                  }}
                  onFocus={() => handleFocusField(offer_text)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === offer_text ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={offer_text}
                  />
                )}
              </div>
              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={plan_offer_text}
                  placeholder="Plan Offer Text"
                  label="Plan Offer Text"
                  type="text"
                  value={rest.values.plan_offer_text[rest.values.language]}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleFormValueChange(rest, name);
                    handleLanguage(rest, name, value, setFieldValue);
                  }}
                  onFocus={() => handleFocusField(plan_offer_text)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === plan_offer_text ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={plan_offer_text}
                  />
                )}
              </div>
              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={my_offer_text}
                  placeholder="My Offer Text"
                  label="My Offer Text"
                  type="text"
                  value={rest.values.my_offer_text[rest.values.language]}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleFormValueChange(rest, name);
                    handleLanguage(rest, name, value, setFieldValue);
                  }}
                  onFocus={() => handleFocusField(my_offer_text)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === my_offer_text ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={my_offer_text}
                  />
                )}
              </div>
              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div>
              <Autocomplete
                options={partners}
                getOptionLabel={(partner) => (partner.name ? partner.name : "")}
                value={rest.values.partner_id}
                onChange={(event, value) => {
                  setFieldValue(partner_id, value);
                  !value && setPartnerFilters("name", "");
                }}
                onFocus={() => handleFocusField(partner_id)}
                onBlur={() => {
                  handleBlurField();
                  !rest.values.partner_id && setPartnerFilters("name", "");
                  //rest.handleBlur();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={partner_id}
                    onChange={(e) => setPartnerFilters("name", e.target.value)}
                    placeholder="Select Partner"
                    label="Select Partner"
                    variant="standard"
                  />
                )}
              />
              {focusField === partner_id ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={partner_id}
                />
              )}
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={description}
                  placeholder="Description"
                  multiline
                  label="Description"
                  type="text"
                  value={rest.values.description[rest.values.language]}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleFormValueChange(rest, name);
                    handleLanguage(rest, name, value, setFieldValue);
                  }}
                  onFocus={() => handleFocusField(description)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === description ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={description}
                  />
                )}
              </div>
              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={amount}
                placeholder="Amount"
                label="Amount"
                type="number"
                value={rest.values.amount}
                onChange={handleFormValueChange(rest, amount)}
                onFocus={() => handleFocusField(amount)}
                onBlur={() => handleBlurField()}
              />
              {focusField === amount ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={amount}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={validity}
                placeholder="Validity in days"
                label="Validity in days"
                InputProps={{ inputProps: { min: 1 } }}
                type="number"
                value={rest.values.validity}
                onChange={handleFormValueChange(rest, validity)}
                onFocus={() => handleFocusField(validity)}
                onBlur={() => handleBlurField()}
              />
              {focusField === validity ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={validity}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={dynamic_coupon_code}
                placeholder="Dynamic Coupon Code"
                label="Dynamic Coupon Code"
                select
                value={rest.values.dynamic_coupon_code}
                onChange={handleFormValueChange(rest, dynamic_coupon_code)}
                onFocus={() => handleFocusField(dynamic_coupon_code)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.dynamic_coupon_code}
              >
                {dynamicCoupounCodeList.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === dynamic_coupon_code ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={dynamic_coupon_code}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={no_of_coupon_codes}
                placeholder="Number of coupon codes per user"
                label="Number of coupon codes per user"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                value={rest.values.no_of_coupon_codes}
                onChange={handleFormValueChange(rest, no_of_coupon_codes)}
                onFocus={() => handleFocusField(no_of_coupon_codes)}
                onBlur={() => handleBlurField()}
              />

              {focusField === no_of_coupon_codes ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={no_of_coupon_codes}
                />
              )}
            </div>

            <div className="d-flex">
              <div className="offer__how_to_use">
                <TextfieldChips
                  initialChips={rest.values.how_to_use[rest.values.language]}
                  name="How To Use"
                  onDataChange={(data) => {
                    // setFieldValue(how_to_use, data);
                    handleLanguage(rest, how_to_use, data, setFieldValue);
                  }}
                />
                {focusField === how_to_use ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error mt-1">{msg}</div>
                    )}
                    name={how_to_use}
                  />
                )}
              </div>

              <div className="col-md-2 form__language mt-3">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div>
              <TextfieldChips
                initialChips={rest.values.terms_and_conditions}
                name={"Terms and Conditions"}
                onDataChange={(data) => {
                  setFieldValue(terms_and_conditions, data);
                }}
              />
              {focusField === terms_and_conditions ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={terms_and_conditions}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={handleFormValueChange(rest, status)}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {offerStatus.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={cta_name}
                  placeholder="CTA Name"
                  label="CTA Name"
                  type="text"
                  value={rest.values.cta_name[rest.values.language]}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleFormValueChange(rest, name);
                    handleLanguage(rest, name, value, setFieldValue);
                  }}
                  onFocus={() => handleFocusField(cta_name)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === cta_name ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={cta_name}
                  />
                )}
              </div>
              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={cta_url}
                placeholder="CTA URL"
                label="CTA URL"
                type="text"
                value={rest.values.cta_url}
                onChange={handleFormValueChange(rest, cta_url)}
                onFocus={() => handleFocusField(cta_url)}
                onBlur={() => handleBlurField()}
              />
              {focusField === cta_url ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={cta_url}
                />
              )}
            </div>
            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default OfferForm;
