import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addEvent,
  getCategoriesList,
  getSubcategoriesList,
  getEventList,
  getFaqCategoryList,
} from "../actions";
import { clearFiles } from "../../../utils/upload-file/actions";
import {
  getCategoriesSelector,
  getSubcategoriesSelector,
  getEventsFilter,
  getCategoryFilter,
  getSubcategoryFilter,
  getFaqCategoryFilter,
  getFaqCategorySelector,
} from "../selectors";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";

export const useAddEvent = () => {
  const dispatch = useDispatch();
  const categories = useSelector(getCategoriesSelector);
  const subcategories = useSelector(getSubcategoriesSelector);
  const faqCategories = useSelector(getFaqCategorySelector);
  const filter = useSelector(getEventsFilter);
  const faqFilter = useSelector(getFaqCategoryFilter);
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const categoryFilter = useSelector(getCategoryFilter);
  const subcategoryFilter = useSelector(getSubcategoryFilter);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [addEventLoading, setAddEventLoading] = useState(false);

  const getCategories = async () => {
    setAddEventLoading(true);
    await dispatch(getCategoriesList(categoryFilter));
    await dispatch(getSubcategoriesList(subcategoryFilter));
    await dispatch(getFaqCategoryList(faqFilter));
    setAddEventLoading(false);
    dispatch(clearFiles());
    toggleModal();
  };

  const handleAddEvent = async (values) => {
    let requirements = values.requirements;
    let error = false;

    if (!values.is_course) {
      Object.keys(requirements).forEach((key) => {
        if (requirements[key].length) {
          requirements[key].forEach((val) => {
            const { title, description } = val;
            if (!title || !description) {
              dispatch(
                showSnackbar({
                  message: "Enter valid title or decription for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }
          });
        }
      });
    } else {
      Object.keys(requirements).forEach((key) => {
        if (requirements[key].length) {
          requirements[key].forEach((val) => {
            const { title, description } = val;
            if (!title) {
              dispatch(
                showSnackbar({
                  message: "Enter valid title or description for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }

            if (title && !description.length) {
              dispatch(
                showSnackbar({
                  message: "Enter valid title or description for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }

            description.forEach((desc) => {
              if (!desc) {
                dispatch(
                  showSnackbar({
                    message: "Enter valid  description for requirements",
                    type: snackbarTypes.ERROR,
                  })
                );
                error = true;
                return;
              }
            });
          });
        }
      });
    }

    if (!error) {
      try {
        setAddEventLoading(true);
        const payload = {
          event: {
            category_id: values.category.id,
            sub_category_id: values?.subcategory?.id,
            title: values.title,
            description: values.description,
            short_description: values.short_description,
            status: values.status,
            is_course: values.is_course,
            is_popular: values.is_popular,
            requirements: values.requirements,
            type: values.type,
            faq_category_ids: values.faq_category.map(
              (category) => category.id
            ),
            slug: values.slug,
            attendees_limit: parseInt(values.attendees_limit),
            current_attendees_limit: parseInt(values.current_attendees_limit),
            exceeds_percentage: parseInt(values.exceeds_percentage),
            increment_percentage: parseInt(values.increment_percentage),
            broadcast_mins: parseInt(values.broadcast_mins),
            after_popup_broadcast_mins: parseInt(
              values.after_popup_broadcast_mins
            ),
            max_join_broadcast_attempts: parseInt(
              values.max_join_broadcast_attempts
            ),
            meta_title: values.meta_title,
            meta_description: values.meta_description,
            meta_keywords: values.meta_keywords,
          },
          files,
        };

        const is_success = await dispatch(addEvent(payload));
        setAddEventLoading(false);
        if (is_success) {
          dispatch(getEventList(filter));
          toggleModal();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    getCategories,
    addEventLoading,
    handleAddEvent,
    isOpenModal,
    toggleModal,
    categories,
    subcategories,
    faqCategories,
  };
};
