// import { useState } from 'react';
import moment from "moment";

export const useCourseCard = (data) => {
  const handleCourseCardClick = (id, slug) => {
    // router.push(`/courses/${slug}?id=${id}`);
  };

  const formatTime = (time) => {
    let formatedTime = `${moment(time).format("DD MMM")} | ${moment(
      time
    ).format("hh:mm A")}`;
    return formatedTime;
  };

  return { handleCourseCardClick, formatTime };
};
