import React from 'react';
import { Container, Col, Card, CardBody, Row } from 'reactstrap';
import Chat from './list/index'
const Comments = () => {
  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <Chat />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Comments;
