import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventReviews, publishUnpublishReview } from "../actions";
import { getReviews, getReviewFilter } from "../selectors";

export const useShowReviews = (id) => {
  const dispatch = useDispatch();
  const [loadingReview, setLoadingReview] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const reviews = useSelector(getReviews);
  const filters = useSelector(getReviewFilter);

  const openReviewsList = async () => {
    setLoadingReview(true);

    const reviews = await dispatch(getEventReviews(id, filters));

    if (reviews) {
      toggleModal();
      setLoadingReview(false);
    }
  };

  const updateReviewStatus = async (data) => {
    const payload = {
      status:
        data?.review?.status === "Published" ? "Unpublished" : "Published",
    };
    const is_success = await dispatch(
      publishUnpublishReview(payload, data?.review?.id)
    );
    if (is_success) {
      await dispatch(getEventReviews(id, filters));
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    loadingReview,
    openReviewsList,
    toggleModal,
    reviews,
    isOpenModal,
    updateReviewStatus,
  };
};
