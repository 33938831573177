import { api, apiEndPoints } from "../../../api";
import { snackbarTypes } from "../../../constants";
import { showSnackbar } from "../../../components/snackbar/actions";

const namespace = "/containers/meta-data";

export const SET_META_DATA_LIST = `${namespace}/SET_META_DATA_LIST`;
export const SET_META_DATA_FILTER = `${namespace}/SET_META_DATA_FILTER`;

export const setMetaDataList = (payload) => ({
  type: SET_META_DATA_LIST,
  payload,
});

export const setMetaDataFilter = (payload) => ({
  type: SET_META_DATA_FILTER,
  payload,
});


export const getMetaData = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.metaData, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setMetaDataList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addMetaData = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.metaData, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getSingleMetaData = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.singleMetaData(id));
    const { data: { is_success, page_meta_data } = {} } = response;
    if (is_success) {
      return page_meta_data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editMeta = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.singleMetaData(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteMetaData = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.singleMetaData(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};
