import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UserList from '../../../users';
import UserDetails from '../../../users/user-details';

export default () => (
  <Switch>
    <Route exact path="/" component={UserList} />
    <Route exact path="/users/reported" component={UserList} />
    <Route exact path="/users/:user_id" component={UserDetails} />
  </Switch>
);
