import { useDispatch } from "react-redux";
import { copyRefLink } from "../actions";

export const useUserSettings = () => {
  const dispatch = useDispatch();
  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    dispatch(copyRefLink());
  };

  return {
    copyLink,
  };
};
