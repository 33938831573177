import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteVideo, getVideoList } from "../actions";
import { getVideoFilter } from "../selectors";

export const useDeleteVideo = ({ id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const filter = useSelector(getVideoFilter);

  const handleDelete = async () => {
    setDeleteLoading(true);
    const is_success = await dispatch(deleteVideo(id));
    setDeleteLoading(false);
    if (is_success) {
      dispatch(getVideoList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteLoading,
    handleDelete,
    isOpenModal,
    toggleModal,
  };
};
