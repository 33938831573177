export const getUserEvents = ({ userDetailsReducer: { events = [] } = {} }) =>
  events;
export const getUserEventFilter = ({
  userDetailsReducer: { event_filter = {} } = {},
}) => event_filter;
export const getUserEventCount = ({
  userDetailsReducer: { event_count = 0 } = {},
}) => event_count;
export const getUserTransactions = ({
  userDetailsReducer: { transactions = [] } = {},
}) => transactions;
export const getUserTransactionFilter = ({
  userDetailsReducer: { transaction_filter = {} } = {},
}) => transaction_filter;
export const getUserTransactionCount = ({
  userDetailsReducer: { transaction_count = 0 } = {},
}) => transaction_count;
export const getUserPayments = ({
  userDetailsReducer: { payments = [] } = {},
}) => payments;
export const getUserPaymentFilter = ({
  userDetailsReducer: { payment_filter = {} } = {},
}) => payment_filter;
export const getUserPaymentCount = ({
  userDetailsReducer: { payment_count = 0 } = {},
}) => payment_count;
export const getRegularSessionsCount = ({
  userDetailsReducer: { regular_count = 0 } = {},
}) => regular_count;
export const getBonusSessionsCount = ({
  userDetailsReducer: { bonus_count = 0 } = {},
}) => bonus_count;
export const getReviews = ({ userDetailsReducer: { reviews = [] } = {} }) =>
  reviews;

export const getWaitlist = ({ userDetailsReducer: { waitlists = {} } = {} }) =>
  waitlists;
export const getWaitlistFilter = ({
  userDetailsReducer: { waitlistFilter = {} } = {},
}) => waitlistFilter;
export const getWaitlistCount = ({
  userDetailsReducer: { waitlist_count = {} } = {},
}) => waitlist_count;
export const getReviewsFilter = ({
  userDetailsReducer: { reviews_filter = {} } = {},
}) => reviews_filter;
export const getReviewsCount = ({
  userDetailsReducer: { reviews_count = 0 } = {},
}) => reviews_count;
export const getRefundInfo = ({
  userDetailsReducer: { refundDetails = {} } = {},
}) => refundDetails;
export const getSchedulesEvents = ({
  userDetailsReducer: { scheduled_events = {} } = {},
}) => scheduled_events;
export const getScheduleEventFilter = ({
  userDetailsReducer: { scheduled_events_filters = {} } = {},
}) => scheduled_events_filters;

export const getEventList = ({
  userDetailsReducer: { book_events_list = [] } = {},
}) => book_events_list;

export const getBookEventFilter = ({
  userDetailsReducer: { book_event_filter = [] } = {},
}) => book_event_filter;

export const getEventTimeSlots = ({
  userDetailsReducer: { event_timeslots_list = [] } = {},
}) => event_timeslots_list;

export const getReferals = ({ userDetailsReducer: { referrals = [] } = {} }) =>
  referrals;

export const getReferalCount = ({
  userDetailsReducer: { referral_count = 0 } = {},
}) => referral_count;

export const getReferralsFilter = ({
  userDetailsReducer: { referrals_filter = [] } = {},
}) => referrals_filter;

export const getActivePlansList = ({
  userDetailsReducer: { active_plans = [] } = {},
}) => active_plans;

export const getPaymentLink = ({
  userDetailsReducer: { payment_link = "" } = {},
}) => payment_link;

export const getPartnerOffers = ({
  userDetailsReducer: { partner_offers = [] } = {},
}) => partner_offers;

export const getPartnerOfferCount = ({
  userDetailsReducer: { partner_offers_count = 0 } = {},
}) => partner_offers_count;

export const getPartnerOfferFilters = ({
  userDetailsReducer: { partner_offers_filter = [] } = {},
}) => partner_offers_filter;
export const getCourses = ({ userDetailsReducer: { courses = [] } = {} }) =>
  courses;
export const getCoursesCount = ({
  userDetailsReducer: { courses_count = 0 } = {},
}) => courses_count;
export const getCoursesFilter = ({
  userDetailsReducer: { courses_filter = {} } = {},
}) => courses_filter;

//user post selectors

export const getPosts = ({ userDetailsReducer: { posts = [] } = {} }) => posts;
export const getPostsCount = ({
  userDetailsReducer: { posts_count = 0 } = {},
}) => posts_count;
export const getPostFilter = ({
  userDetailsReducer: { post_filter = {} } = {},
}) => post_filter;

//user stories selector

export const getStories = ({ userDetailsReducer: { stories = [] } = {} }) =>
  stories;
export const getStoriesCount = ({
  userDetailsReducer: { stories_count = 0 } = {},
}) => stories_count;
export const getStoriesFilter = ({
  userDetailsReducer: { stories_filter = {} } = {},
}) => stories_filter;

//user friends selector

export const getFriends = ({ userDetailsReducer: { friends = [] } = {} }) =>
  friends;
export const getFriendsCount = ({
  userDetailsReducer: { friends_count = 0 } = {},
}) => friends_count;
export const getFriendsFilter = ({
  userDetailsReducer: { friends_filter = {} } = {},
}) => friends_filter;

//user privacy setting selector

export const getPrivacySetting = ({
  userDetailsReducer: { privacy_settings = [] } = {},
}) => privacy_settings;

export const getPrivacySettingsFilter = ({
  userDetailsReducer: { privacy_settings_filter = {} } = {},
}) => privacy_settings_filter;

//user notifications
export const getNotifications = ({
  userDetailsReducer: { notifications = [] } = {},
}) => notifications;
export const getNotificationsCount = ({
  userDetailsReducer: { notifications_count = 0 } = {},
}) => notifications_count;
export const getNotificationsFilter = ({
  userDetailsReducer: { notifications_filter = {} } = {},
}) => notifications_filter;

//user followed instructors

export const getInstructors = ({
  userDetailsReducer: { instructors = [] } = {},
}) => instructors;
export const getInstructorsCount = ({
  userDetailsReducer: { instructors_count = 0 } = {},
}) => instructors_count;
export const getInstructorsFilter = ({
  userDetailsReducer: { instructors_filter = {} } = {},
}) => instructors_filter;

//user reports
export const getReports = ({ userDetailsReducer: { reports = [] } = {} }) =>
  reports;
export const getReportsCount = ({
  userDetailsReducer: { reports_count = 0 } = {},
}) => reports_count;
export const getReportsFilter = ({
  userDetailsReducer: { reports_filter = {} } = {},
}) => reports_filter;

//users who have been blocked by this user
export const getBlockedToUsers = ({
  userDetailsReducer: { blocked_to_users = [] } = {},
}) => blocked_to_users;
export const getBlockedToUsersCount = ({
  userDetailsReducer: { blocked_to_users_count = 0 } = {},
}) => blocked_to_users_count;
export const getBlockedToUsersFilter = ({
  userDetailsReducer: { blocked_to_users_filter = {} } = {},
}) => blocked_to_users_filter;
