import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserList,
  getCourseList,
  getCourseData,
  editCourse,
  getEventList,
  getCourseNotes,
} from "../actions";
import { clearFiles, setFiles } from "../../../utils/upload-file/actions";
import { getCourses, getCommonFilter } from "../selectors";
import { useHistory } from "react-router-dom";
import {
  fileContext,
  initialLanguageArrayValue,
  initialLanguageFieldValue,
} from "../../../constants";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";
import * as moment from "moment";
import { clearLanguage, getLanguage } from "../../../utils/auth";

export const useEditCourse = ({
  match: {
    params: { course_id },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [editCourseLoading, setEditCourseLoading] = useState(false);
  const courses = useSelector(getCourses);
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const filters = useSelector(getCommonFilter);

  useEffect(() => {
    clearLanguage();
    const getRequiredData = async () => {
      setEditCourseLoading(true);
      dispatch(clearFiles());
      clearLanguage();
      const { events } = await dispatch(getEventList(filters));
      if (events) setEvents(events);
      const data = await dispatch(getUserList(filters));
      if (data) setUsers(data);
      const { notes = [] } = await dispatch(getCourseNotes(course_id));
      const { course } = await dispatch(getCourseData(course_id));
      if (course) {
        course.event_id = events.find((event) => event.id === course?.event_id);
        course.hostIds = data.find((user) => user.id === course?.host.id);

        if (!course.hostIds) {
          setUsers([...data, course.host]);
          course.hostIds = course.host;
        }

        course.cohostIds = data.filter((user) =>
          course?.cohosts?.find((cohost) => cohost.id === user.id)
        );
        if (course.cohostIds.length === 0) {
          course.cohostIds = course?.cohosts;
          const cohosts = course?.cohosts.find((coHost) => {
            if (coHost.id !== course?.hostIds.id) {
              return setUsers([...data, coHost, course?.hostIds]);
            }
          });
        } else if (course.cohostIds.length !== course?.cohosts) {
          course.cohostIds = course?.cohosts.filter((cohostIds) => {
            const ids = course.cohostIds.find((host) => {
              if (host.id !== cohostIds.id) {
                if (course?.host.id !== cohostIds.id) {
                  setUsers([...data, cohostIds, course?.host]);
                }
                return cohostIds;
              } else {
                return host;
              }
            });
            return ids;
          });
        }
        course.event_schedules = [
          ...course.trial_event_schedules,
          ...course.regular_event_schedules,
        ];
        course.event_schedules.forEach((ev_sv, index) => {
          ev_sv.title = {
            ...initialLanguageFieldValue,
            en: ev_sv.title,
          };
          ev_sv.chapters = {
            en: ev_sv.chapters,
          };
          ev_sv.allow_booking_post_commencement =
            ev_sv?.type === "Regular"
              ? ev_sv.allow_booking_post_commencement
              : handleMinuteConversion(
                  ev_sv.allow_booking_post_commencement,
                  ev_sv.starts_at
                );
          ev_sv.allow_seats_increment =
            ev_sv?.type === "Regular"
              ? ev_sv.allow_seats_increment
              : handleMinuteConversion(
                  ev_sv.allow_seats_increment,
                  ev_sv.starts_at
                );
          ev_sv.pre_event_file.file_context = `${ev_sv.pre_event_file.file_context}_${index}`;
          dispatch(setFiles(ev_sv.pre_event_file));
          notes.length > 0 &&
            notes.forEach((note) => {
              if (note?.event_schedule_id === ev_sv.event_schedule_id) {
                note.file_context = `${note.file_context}_${index}`;
                dispatch(setFiles(note));
              }
            });
        });
        course.requirements = {
          ...initialLanguageArrayValue,
          en: course.course_requirements,
        };
        setSelectedCourse(course);
      }

      await dispatch(getCourseList(filters));
      setEditCourseLoading(false);
    };
    getRequiredData();
  }, [course_id, filters, dispatch]);

  const handleLanguageData = async (rest, setFormValues) => {
    const selectedLanguage = getLanguage();
    setEditCourseLoading(true);

    const { course } = await dispatch(getCourseData(course_id));
    if (course) {
      course.form_language = selectedLanguage;
      course.event_id = events.find((event) => event.id === course?.event_id);
      course.hostIds = users.find((user) => user.id === course?.host.id);
      if (!course.hostIds) {
        setUsers([...users, course.host]);
        course.hostIds = course.host;
      }
      course.cohostIds = users.filter((user) =>
        course?.cohosts?.find((cohost) => cohost.id === user.id)
      );

      if (course.cohostIds.length === 0) {
        course.cohostIds = course?.cohosts;
        const cohosts = course?.cohosts.find((coHost) => {
          if (coHost.id !== course?.hostIds.id) {
            return setUsers([...users, coHost, course?.hostIds]);
          }
        });
      } else if (course.cohostIds.length !== course?.cohosts) {
        course.cohostIds = course?.cohosts.filter((cohostIds) => {
          const ids = course.cohostIds.find((host) => {
            if (host.id !== cohostIds.id) {
              if (course?.host.id !== cohostIds.id) {
                setUsers([...users, cohostIds, course?.host]);
              }
              return cohostIds;
            } else {
              return host;
            }
          });
          return ids;
        });
      }

      course.event_schedules = [
        ...course.trial_event_schedules,
        ...course.regular_event_schedules,
      ];

      course.event_schedules.forEach((ev_sv, index) => {
        ev_sv.title = {
          ...rest.values.event_schedules[index]["title"],
          [selectedLanguage]:
            rest.values.event_schedules[index]["title"][selectedLanguage] ||
            ev_sv.title,
        };
        ev_sv.chapters = {
          ...rest.values.event_schedules[index]["chapters"],
          [selectedLanguage]: rest.values.event_schedules[index]["chapters"][
            selectedLanguage
          ]?.length
            ? rest.values.event_schedules[index]["chapters"][selectedLanguage]
            : ev_sv.chapters,
        };
        ev_sv.allow_booking_post_commencement =
          ev_sv?.type === "Regular"
            ? ev_sv.allow_booking_post_commencement
            : handleMinuteConversion(
                ev_sv.allow_booking_post_commencement,
                ev_sv.starts_at
              );
        ev_sv.allow_seats_increment =
          ev_sv?.type === "Regular"
            ? ev_sv.allow_seats_increment
            : handleMinuteConversion(
                ev_sv.allow_seats_increment,
                ev_sv.starts_at
              );
        ev_sv.pre_event_file.file_context = `${ev_sv.pre_event_file.file_context}_${index}`;
      });

      course.requirements = {
        ...rest.values.requirements,
        [selectedLanguage]: rest.values.requirements[selectedLanguage]?.length
          ? rest.values.requirements[selectedLanguage]
          : course?.course_requirements,
      };

      setSelectedCourse(course);
      setFormValues(course);
    }
    await dispatch(getCourseList(filters));
    setEditCourseLoading(false);
  };

  const handleMinuteConversion = (d1, d2) => {
    if (!d1 && !d2) {
      return "";
    }
    const date1 = new Date(moment(d1).format("MM-DD-YYYY HH:mm:ss"));
    const date2 = new Date(moment(d2).format("MM-DD-YYYY HH:mm:ss"));
    return parseInt(Math.abs(date2 - date1) / (1000 * 60));
  };

  const handleEditCourse = async (values) => {
    let { event_schedules, requirements } = values;
    let error = false;

    Object.keys(requirements).forEach((key) => {
      if (requirements[key].length) {
        requirements[key].forEach((val) => {
          const { title, description } = val;
          if (!title) {
            dispatch(
              showSnackbar({
                message: "Enter valid title or decription for requirements",
                type: snackbarTypes.ERROR,
              })
            );
            error = true;
            return;
          }

          description.forEach((desc) => {
            if (!desc) {
              dispatch(
                showSnackbar({
                  message: "Enter valid  decription for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }
          });
        });
      }
    });

    for (let i = 0; i < event_schedules.length; i++) {
      let current = event_schedules[i];
      for (let j = 0; j < event_schedules.length; j++) {
        if (i !== j) {
          if (
            moment(event_schedules[j].starts_at).isSame(
              moment(current.starts_at)
            ) ||
            moment(event_schedules[j].starts_at).isBetween(
              moment(current.starts_at),
              moment(current.ends_at)
            )
          ) {
            dispatch(
              showSnackbar({
                message: "Multiple events cannot be scheduled on same time",
                type: snackbarTypes.ERROR,
              })
            );
            return;
          }
        }
      }
    }

    if (!values.event_schedules.find((ev) => ev.type === "Regular")) {
      dispatch(
        showSnackbar({
          message: "Atleast One Regular Event Schedule is Required",
          type: snackbarTypes.ERROR,
        })
      );
      return;
    }

    if (
      values.allow_booking_post_commencement &&
      values.event_schedules.length > 1
    ) {
      let dates = [];
      values.event_schedules.forEach((e) => {
        dates.push(new Date(e.starts_at));
      });
      var min = dates.reduce(function (a, b) {
        return a < b ? a : b;
      });
      var max = dates.reduce(function (a, b) {
        return a > b ? a : b;
      });
      if (
        values.allow_booking_post_commencement < min ||
        values.allow_booking_post_commencement > max
      ) {
        dispatch(
          showSnackbar({
            message: "Allow Booking Post Commencement Should be Valid !",
            type: snackbarTypes.ERROR,
          })
        );
        return;
      }
    }

    if (
      values.allow_seats_increment < min ||
      values.allow_seats_increment > values.allow_booking_post_commencement
    ) {
      dispatch(
        showSnackbar({
          message:
            "Last Date and Time to Increase Displayed Seat Count Should be Valid !",
          type: snackbarTypes.ERROR,
        })
      );
      return;
    }

    if (!error) {
      try {
        setEditCourseLoading(true);
        let ev_sh = values.event_schedules;

        ev_sh.forEach((ele, i) => {
          ev_sh[i].pre_event_file = {};
          ev_sh[i].note = {};
          files.forEach((file) => {
            if (file.file_context === `${fileContext.EVENT_ATTACHMENT}_${i}`) {
              const { uuid_file_name, original_file_name } = file;
              ev_sh[i].pre_event_file = { uuid_file_name, original_file_name };
            }
            if (file.file_context === `${fileContext.NOTE}_${i}`) {
              const { uuid_file_name, original_file_name } = file;
              ev_sh[i].note = { uuid_file_name, original_file_name };
            }
          });
        });

        ev_sh.forEach((ev, i) => {
          ev_sh[i].current_attendees_limit = parseInt(
            ev.current_attendees_limit
          );
          ev_sh[i].exceeds_percentage = parseInt(ev.exceeds_percentage);
          ev_sh[i].increment_percentage = parseInt(ev.increment_percentage);
          ev_sh[i].allow_booking_post_commencement =
            ev.type === "Regular"
              ? ev.allow_booking_post_commencement
              : moment(ev.starts_at)
                  .add(parseInt(ev.allow_booking_post_commencement), "minutes")
                  .toString();
          ev_sh[i].allow_seats_increment =
            ev.type === "Regular"
              ? ev.allow_seats_increment
              : moment(ev.starts_at)
                  .add(parseInt(ev.allow_seats_increment), "minutes")
                  .toString();
        });

        ev_sh.forEach((ev) => {
          delete ev.btn;
        });
        const payload = {
          event_id: values.event_id?.id,
          event_schedules: ev_sh,
          // commence_at: values.commence_at,
          mode: values.mode,
          language: values.language,
          per_session_cost: parseInt(values.per_session_cost),
          attendees_limit: parseInt(values.attendees_limit),
          current_attendees_limit: parseInt(values.current_attendees_limit),
          exceeds_percentage: parseInt(values.exceeds_percentage),
          increment_percentage: parseInt(values.increment_percentage),
          allow_booking_post_commencement:
            values.allow_booking_post_commencement,
          allow_seats_increment: values.allow_seats_increment,
          starred: values.starred?.value,
          status: values.status,
          requirements: values.requirements,
          host_ids: [values.hostIds.id],
          cohost_ids: values.cohostIds.map((cohost) => cohost.id),
        };

        const is_success = await dispatch(editCourse(payload, course_id));
        setEditCourseLoading(false);
        if (is_success) {
          dispatch(clearFiles());
          history.push("/courses");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return {
    editCourseLoading,
    handleEditCourse,
    users,
    courses,
    events,
    selectedCourse,
    handleLanguageData,
  };
};
