import {
  SET_BANNER_LIST,
  SET_BANNER_FILTER,
  SET_BLOG_FILTER,
  SET_EVENT_FILTER,
  CLEAR_BLOG_FILTER
} from "./action";
import * as moment from "moment";

const initialState = {
  banners: [],
  count: 0,
  filter: {
    limit: 10,
    skip: 0,
  },
  schedule_event_filter: {
    status: "Published",
    event_date: "",
    skip: 0,
    limit: 10,
    event_time: {
      start: "",
      end: "",
    },
    host_ids: [],
    category_ids: [],
    utc_offset: moment().utcOffset(),
  },
  blog_filter: {
    id: null,
    title: "",
    status: "",
    skip: 0,
    limit: 10,
    published_at: {
      from: "",
      to: "",
    },
    utc_offset: moment().utcOffset(),
  },
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BANNER_LIST:
      return {
        ...state,
        banners: action.payload.banner_groups,
        count: action.payload.count || 0,
      };

    case SET_BANNER_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case SET_BLOG_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        blog_filter: {
          ...state.blog_filter,
          [key]: value,
        },
      };
    }
    case CLEAR_BLOG_FILTER:
      return { ...state, blog_filter: initialState.blog_filter };
    case SET_EVENT_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        schedule_event_filter: {
          ...state.schedule_event_filter,
          [key]: value,
        },
      };
    }
    default:
      return state;
  }
};

export default bannerReducer;
