import axios from "axios";
import { getBearerToken, getLanguage, logOut } from "../utils/auth";
import { AUTH_HEADER_NAME, API_BASE_URL, LANGUAGE } from "../constants";

const createAPI = () => {
  const apiHeader = {
    "Content-Type": "application/json",
    "Request-From": "admin-web",
  };

  const api = axios.create({
    baseURL: API_BASE_URL,
    headers: apiHeader,
  });

  api.interceptors.request.use(async (config) => {
    const bearerToken = await getBearerToken();
    const language = await getLanguage();

    if (bearerToken && window.location.pathname !== "/log_in") {
      config.headers[`${AUTH_HEADER_NAME}`] = bearerToken;
    }

    if (language) {
      config.headers[`${LANGUAGE}`] = `${language}`;
    } else {
      config.headers[`${LANGUAGE}`] = "en";
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (401 === error.response.status) {
        logOut();
      }
      throw error;
    }
  );
  return api;
};

export default createAPI();
