import React from "react";
import { useAddLabel } from "./hooks";
import LabelForm from "../form/label-form";
import Modals from "../../../../../components/modal";
import { setLanguage } from "../../../../../utils/auth";
import ActionButton from "../../../../../components/action-button";

const EditLabel = ({ label }) => {
  const {
    handleEditLabel,
    editLabelLoading,
    selectedLabel,
    getLableById,
    toggleModal,
    isOpenModal,
    handleLanguageData,
  } = useAddLabel(label);
  return (
    <>
      <ActionButton
        title="Edit"
        iconClassName={`fa fa-edit icon-btn-action `}
        onClick={() => {
          setLanguage("en");
          getLableById();
        }}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <LabelForm
              fieldValues={selectedLabel}
              handleLabelForm={handleEditLabel}
              btn="Save"
              isLoading={editLabelLoading}
              handleLanguageData={handleLanguageData}
            />
          }
          title="Edit Label"
        />
      ) : null}
    </>
  );
};

export default EditLabel;
