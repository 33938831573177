import _ from "lodash";
import {
  supportedPostImageFormat,
  supportedPostVideoFormat,
} from "../constants";

export const validationRegex = {
  // eslint-disable-next-line
  email: /^(?:[\w!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/,
  mobile: /^[2-9]\d{7,12}$/,
  alphaNumeric: /^[a-zA-Z0-9]{6,}$/,
  pincode: /^[1-9][0-9]{5}$/,
  text: /^[a-zA-Z ]*$/,
  numeric: /^(0|[1-9][0-9]*)$/,
  nameRegex: /^[A-Za-z .]{1,128}$/,
  urlRegex:
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
  partner_url:
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  iconFile: ["image/png"],
  bannerFile: [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/webp",
  ],
  validateSectionValueId: /^\s*\d+(\s*,+\s*\d+)*\s*$/,
  validateDate: /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/,
  youtube_url: /((http(s)?:\/\/)?)(www\.)?((youtube\.com\/)|(youtu.be\/))[\S]+/,
  postFile: [...supportedPostImageFormat, ...supportedPostVideoFormat],
};

export const checkIfFilesAreCorrectType = (files) => {
  let valid = true;
  if (files) {
    Array.from(files).forEach((file) => {
      if (!validationRegex.bannerFile.includes(file.type)) {
        valid = false;
      }
    });
  }
  return valid;
};

export const checkIfFileIsCorrectType = (file) => {
  let valid = true;
  if (file) {
    if (!validationRegex.postFile.includes(file.type)) {
      valid = false;
    }
  }
  return valid;
};

export const checkIfFilesAreCorrectSize = (files, sizeLimit) => {
  let valid = true;
  if (files) {
    Array.from(files).forEach((file) => {
      if (Math.ceil(file.size / (1024 * 1024)) > sizeLimit) {
        valid = false;
      }
    });
  }
  return valid;
};

export const checkIfFileIsCorrectSize = (file, sizeLimit) => {
  let valid = true;
  if (file) {
    if (Math.ceil(file.size / (1024 * 1024)) > sizeLimit) {
      valid = false;
    }
  }
  return valid;
};

export const checkFields = (_data, _requiredFields, _field) => {
  let languages = _requiredFields.map((field) => {
    return Object.keys(_.pickBy(_data[field], (v, k) => v));
  });
  languages = _.uniq(_.flatten(languages));
  return (
    Object.keys(_.pickBy(_data[_field], (v, k) => v)).length ===
    languages.length
  );
};

export const checkIfPostFilesAreCorrectType = (file) => {
  let valid = true;
  if (file) {
    if (!validationRegex.postFile.includes(file.type)) {
      valid = false;
    }
  }
  return valid;
};

const loadVideo = (file) => {
  return new Promise((resolve, reject) => {
    if (!file.type?.includes("video") || !window || !document) {
      resolve(file);
      return;
    }
    try {
      let video = document?.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        file.duration = this.duration;
        resolve(file);
      };

      video.onerror = function () {
        reject("Invalid video. Please select a video file.");
      };

      video.src = window?.URL?.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });
};

export const getDuration = (files) => {
  const fileList = [];
  Array.from(files).forEach(async (_current, index) => {
    const fileObj = files[index];
    const video = loadVideo(fileObj);
    fileList.push(video);
  });
  return new Promise((resolve, reject) => {
    Promise.all(fileList)
      .then((result) => {
        resolve(result);
      })
      .catch(() => {
        reject(files);
      });
  });
};
