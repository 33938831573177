import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useScheduleList } from "./hooks";
import DataTable from "../../../components/table";
import { DataLoader } from "../../../components/loader";
import moment from "moment";
import { columnsScheduleList } from "./../constant";
import Tooltip from "@material-ui/core/Tooltip";
import ShowAttendees from "../attendees-list";
import AddNote from "../add-notes";
import { useUser } from "../../../common-hooks/user";

const ScheduleList = (props) => {
  const match = useRouteMatch("/course/:course_id");
  const { course, course_schedules, joinMeeting, isStartingEvent, isLoading } =
    useScheduleList({ match });

  const { isAdmin, isModerator } = useUser();

  const configureTableData = () => {
    try {
      const currentDate = new Date();
      let results = course_schedules.map((cs_sc, i) => {
        let newCourse = Object.assign({}, cs_sc);
        newCourse["starts_at"] = moment(cs_sc.starts_at).format(
          "DD/MM/YYYY @ hh:mm A "
        );
        newCourse["ends_at"] = moment(cs_sc.ends_at).format(
          "DD/MM/YYYY @ hh:mm A "
        );
        newCourse["action"] =
          currentDate > new Date(cs_sc.ends_at) ? (
            <div className="categories__list-action">
              {moment(cs_sc.ends_at).isBefore(new Date()) && (
                <AddNote eventScheduleId={cs_sc.event_schedule_id} index={i} />
              )}
            </div>
          ) : (
            <div className="categories__list-action">
              {!isAdmin && !isModerator ? null : moment().isAfter(
                  moment(cs_sc.starts_at).subtract(15, "minute")
                ) && moment().isBefore(cs_sc.ends_at) ? (
                <Tooltip title="Join Meeting" placement="bottom">
                  <i
                    className={`fa fa-play-circle icon-btn-action ${
                      isStartingEvent ? "disabled-icon" : null
                    }`}
                    onClick={() => joinMeeting(cs_sc.event_schedule_id)}
                  ></i>
                </Tooltip>
              ) : null}
            </div>
          );
        newCourse.booked = (
          <ShowAttendees
            bookedInfo={`${cs_sc?.booked || 0}`}
            eventScheduleId={cs_sc?.event_schedule_id}
          />
        );
        newCourse.allow_booking_post_commencement =
          cs_sc?.allow_booking_post_commencement
            ? moment(cs_sc?.allow_booking_post_commencement).format(
                "DD/MM/YYYY @ hh:mm A "
              )
            : "";

        newCourse.allow_seats_increment = cs_sc?.allow_seats_increment
          ? moment(cs_sc?.allow_seats_increment).format("DD/MM/YYYY @ hh:mm A ")
          : "";
        return newCourse;
      });
      const reactTableData = {
        tableHeaderData: columnsScheduleList,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      {isLoading ? (
        <DataLoader />
      ) : (
        <DataTable
          title={`Course Schedules (${course?.id}. ${
            course?.event_title
          }, ${moment(course?.commence_at).format("DD/MM/YYYY")})`}
          addBtn={
            <>
              {/* <Button
                className="btn btn-success btn-sm"
                onClick={() => handleAddClick()}
              >
                <i className="fa fa-plus"></i> Add Course
              </Button> */}
            </>
          }
          CreateTableData={configureTableData}
          withFilter={false}
          handleSetFilter={() => {}}
          count={course_schedules.length}
          skip={0}
          manualPageSize={[course_schedules.length]}
        />
      )}
    </div>
  );
};

export default ScheduleList;
