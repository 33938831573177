import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Labels from '../../../CMS/blog/labels';
import AddLabel from '../../../CMS/blog/labels/add-label';
import EditLabel from '../../../CMS/blog/labels/edit-label';

export default () => (
  <Switch>
    <Route path="/labels" component={Labels} />
    <Route path="/add-label" component={AddLabel} />
    <Route path="/edit-label/:label_id" component={EditLabel} />
  </Switch>
);
