import * as Yup from "yup";
import { validationRegex } from "../../../../../utils/validation";

export const formSchema = Yup.object().shape({
  title: Yup.mixed().test(
    "English language required",
    "Title is required for english language",
    function () {
      const {
        title: { en },
      } = this.parent;

      return en;
    }
  ),
  type: Yup.string().required("Section Type is required").nullable(),
  status: Yup.string().required("Status is required").nullable(),
  criteria: Yup.array().of(
    Yup.object().shape({
      field: Yup.string().required("Field is required").nullable(),
      op: Yup.string().required("Operator is required").nullable(),
      value: Yup.mixed()
        .required("Value is required")
        .when("field", {
          is: "id",
          then: Yup.string()
            .when("op", {
              is: "in",
              then: Yup.string().test({
                test: (value) =>
                  validationRegex.validateSectionValueId.test(value),
                message: "Invalid format",
              }),
            })
            .when("op", {
              is: "equals",
              then: Yup.string().test({
                test: (value) => validationRegex.numeric.test(value),
                message: "Invalid format",
              }),
            }),
        })
        .when("field", {
          is: "published_at",
          then: Yup.string().test({
            test: (value) => validationRegex.validateDate.test(value),
            message: "Invalid date format",
          }),
        }),
    })
  ),
});
