import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearLanguage } from "../../utils/auth";
import {
  getPlanList,
  setPlanFilter,
  // getPartnersOfferList,
  resetPlanFilter,
} from "./actions";
import {
  getPlans,
  getPlansCount,
  getPlansFilter,
} from "./selectors";

export const usePlan = () => {
  const dispatch = useDispatch();
  const plans = useSelector(getPlans);
  const filters = useSelector(getPlansFilter);
  const planCount = useSelector(getPlansCount);

  const [loadingPlans, setLoadingPlans] = useState(false);
  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    clearLanguage();
    const fetchPlans = async () => {
      setLoadingPlans(true);
      await dispatch(getPlanList(filters));

      setLoadingPlans(false);
    };
    fetchPlans();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setPlanFilter({ key, value }));
  };

  const resetFilter = useCallback(() => {
    dispatch(resetPlanFilter());
  }, [dispatch]);

  useEffect(() => {
    resetFilter();
  }, [resetFilter, pathname]);

  return {
    plans,
    loadingPlans,
    setFilter,
    filters,
    planCount,
  };
};
