import { TextField, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useMultiSelectFilter } from "./hooks";

const MultiSelectFilter = ({ options, optionLabelFunc,filterKey,handleSelect }) => {
  const { value, setValue,filters } = useMultiSelectFilter(filterKey, handleSelect);
  
  return ( 
      <>
      <Tooltip title="Status" placement="bottom">
      <Autocomplete
        size="small"
        multiple
        id="tags-outlined"
        options={options}
        getOptionLabel={optionLabelFunc}
        filterSelectedOptions
        onChange={(event, value) => {
          setValue(value)
        }}
        value={Object.keys(filters?.status).length < 1 ? []: value}
        className='users__status_multiselect'
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={value.length > 0 && Object.keys(filters?.status).length > 0 ? '' :"All Status"}  
          />
        )}
      />
      </Tooltip>
      </>
     );
}
 
export default MultiSelectFilter;