import { api, apiEndPoints } from "../../api";
import { showSnackbar } from "../../components/snackbar/actions";
import { snackbarTypes } from "../../constants";

const namespace = "containers/plan";

export const SET_PLAN_LIST = `${namespace}/SET_PLAN_LIST`;
export const SET_PLAN_FILTER = `${namespace}/SET_PLAN_FILTER`;
export const RESET_PLAN_FILTER = `${namespace}/RESET_PLAN_FILTER`;
export const SET_PARTNER_OFFER_FILTER = `${namespace}/SET_PARTNER_OFFER_FILTER`;
export const SET_PARTNER_OFFER_LIST = `${namespace}/SET_PARTNER_OFFER_LIST`;

const setPlanList = (payload) => ({
  type: SET_PLAN_LIST,
  payload,
});

const setPartnerOfferList = (payload) => ({
  type: SET_PARTNER_OFFER_LIST,
  payload,
});

export const setPlanFilter = (payload) => ({
  type: SET_PLAN_FILTER,
  payload,
});

export const resetPlanFilter = () => ({
  type: RESET_PLAN_FILTER,
});

export const setPartnerOfferFilter = (payload) => ({
  type: SET_PARTNER_OFFER_FILTER,
  payload,
});

export const getPlanList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPlans, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setPlanList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getPlan = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getPlan(id));
    const { data: { is_success, plan } = {} } = response;
    if (is_success) {
      return plan;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addPlan = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createPlans, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Plan not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const editPlan = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updatePlans(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deletePlan = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deletePlans(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const changePlanStatusAction = (id, payload) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.changePlanStatus(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getPartnersOfferList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getAllOffers, {
      params: { params },
    });
    const { data, data: { is_success, partner_offers } = {} } = response;
    if (is_success) {
      dispatch(setPartnerOfferList(data));
      return partner_offers;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updatPlanOffers = (id, payload) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updatePlanOffers(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
