import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useViewersList } from "./hooks";
import List from "./list";
import Modals from "../../../../components/modal";

const ViewersList = (props) => {
  const {
    viewers,
    isLoading,
    fetchData,
    isOpenModal,
    toggleModal,
    totalCount,
    handleViewersParams,
    story,
    handleUserClick,
  } = useViewersList(props);

  return (
    <div>
      <Tooltip title="Click to see viewers" placement="bottom">
        {story?.stats?.viewers > 0 ? (
          <span className="schedule__booked-info" onClick={fetchData}>
            {story?.stats?.viewers}{" "}
            {story?.stats?.viewers > 1 ? "Viewers" : "Viewer"}
          </span>
        ) : (
          <span>NA</span>
        )}
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <List
              list={viewers}
              totalCount={totalCount}
              handleViewersParams={handleViewersParams}
              isLoading={isLoading}
              handleUserClick={handleUserClick}
            />
          }
          title="Viewers"
        />
      ) : null}
    </div>
  );
};

export default ViewersList;
