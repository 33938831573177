import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../components/modal";
import { useShowAttendees } from "./hooks";
import AttendeesTabs from "./attendees-tabs";

const ShowAttendees = ({ eventScheduleId, bookedInfo }) => {
  const {
    attendeesList,
    toggleModal,
    isOpenModal,
    openAttendeesList,
    downloadAttendeesList,
  } = useShowAttendees(eventScheduleId);

  return (
    <div>
      <Tooltip title="Click to view Attendees." placement="bottom">
        <span
          className="schedule__booked-info"
          onClick={() => openAttendeesList()}
        >
          {bookedInfo}
        </span>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <AttendeesTabs
              tabs={["booked", "attended"]}
              tabsData={attendeesList}
            />
          }
          title="Attendees"
          isDownloadFile={true}
          downloadFile={downloadAttendeesList}
        />
      ) : null}
    </div>
  );
};

export default ShowAttendees;
