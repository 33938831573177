import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import InterestForm from "../form/interest-form";
import { useEditInterest } from "./hooks";
import Modals from "../../../components/modal";
import { setLanguage } from "../../../utils/auth";
import { useUser } from "../../../common-hooks/user";

const EditInterest = ({ interest }) => {
  const {
    handleEditInterest,
    isOpenModal,
    toggleModal,
    selectedInterest,
    getInterestFieldData,
    editInterestLoading,
    categories,
    handleLangugeData,
  } = useEditInterest(interest);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action`}
          onClick={() => {
            setLanguage("en");
            getInterestFieldData();
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <InterestForm
              handleInterestForm={handleEditInterest}
              btn="Save"
              fieldValues={selectedInterest}
              isLoading={editInterestLoading}
              categories={categories}
              handleLangugeData={handleLangugeData}
            />
          }
          title="Edit Interest"
        />
      ) : null}
    </div>
  );
};

export default EditInterest;
