import React from "react";
import { ListItem } from "@material-ui/core";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin from "draft-js-mention-plugin";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { useCaptionInput } from "./hooks";
import editorStyles from "./editor.module.scss";
import "draft-js-mention-plugin/lib/plugin.css";
import Text from "../../../../../components/text";
import { colors } from "../../../../../constants/colors";
import ProfileImage from "../../../../../components/ProfileImage";
import positionSuggestions from "draft-js-mention-plugin/lib/utils/positionSuggestions";
import createSingleLinePlugin from "draft-js-single-line-plugin";

const UserSelectedMention = (props) => {
  const { decoratedText = "" } = props;
  return (
    <Text color={colors.brand}>
      {decoratedText}
      <span style={{ display: "none" }}>{props.children}</span>
    </Text>
  );
};

const HashtagSelectedMention = (props) => {
  const { mention = {} } = props;
  return (
    <Text color={colors.brand}>
      {mention.name}
      <span style={{ display: "none" }}>{props.children}</span>
    </Text>
  );
};

const UserSuggestionMention = (props) => {
  const { mention = {} } = props;
  const profilePic = mention?.profile_picture?.url || "";
  return (
    <div {...props} className={styles.mentionSuggestionsEntry}>
      <ListItem>
        <div>
          <ProfileImage
            src={profilePic}
            title={mention?.name.split("@")[1]}
            initialsClassName={styles.mentions_profile_initials}
            className={styles.mentions_profile_pic}
          />
        </div>
        <div className={styles.selected_session_details}>
          <Text
            size="14px"
            fontWeight={600}
            lineHeight="19px"
            color={colors.darkBlack}
            className={styles.topic_text2}
          >
            {mention?.name.split("@")[1]}
          </Text>
        </div>
      </ListItem>
    </div>
  );
};

const HashtagSuggestionMention = (props) => {
  const { mention = {} } = props;
  return (
    <div {...props} className={styles.mentionSuggestionsEntry}>
      <ListItem button>
        <div className={styles.selected_session_details}>
          <Text
            size="14px"
            fontWeight={600}
            lineHeight="19px"
            color={colors.darkBlack}
            className={styles.topic_text2}
          >
            {mention?.name}
          </Text>
          {mention?.count ? (
            <div className={styles.mf_count}>
              <Text
                size="12px"
                fontWeight={400}
                lineHeight="16px"
                color={colors.lightBlack2}
                className={styles.topic_text2}
              >
                {`${mention?.count} posts`}
              </Text>
            </div>
          ) : null}
        </div>
      </ListItem>
    </div>
  );
};

const _positionSuggestions = ({ decoratorRect, popover, state, props }) => {
  const popoverPosition =
    (decoratorRect.x || decoratorRect.left) + popover.offsetWidth;
  const { left, ...restProps } = positionSuggestions({
    decoratorRect,
    popover,
    state,
    props,
  });
  let adjustedLeft = null;
  if (window.innerWidth < 768) {
    if (popoverPosition > window.innerWidth) {
      adjustedLeft = `${
        parseFloat(left) - (popoverPosition % window.innerWidth)
      }px`;
    }
  } else {
    if (popoverPosition > 1060) {
      adjustedLeft = "300px";
    }
  }

  return {
    left: adjustedLeft || left,
    ...restProps,
  };
};

const mentionPlugin = createMentionPlugin({
  mentionTrigger: `@`,
  supportWhitespace: true,
  mentionComponent: UserSelectedMention,
  positionSuggestions: _positionSuggestions,
});

const mentionPluginHashtag = createMentionPlugin({
  mentionTrigger: `#`,
  // supportWhitespace: true,
  mentionComponent: HashtagSelectedMention,
  positionSuggestions: _positionSuggestions,
});

const { MentionSuggestions } = mentionPlugin;

const { MentionSuggestions: MentionHashSuggestions } = mentionPluginHashtag;

const options = {
  stripEntities: false,
};

const singleLinePlugin = createSingleLinePlugin(options);

const CaptionInput = (props) => {
  const {
    handleCaptionChange,
    users,
    hashtags,
    mentionsRef,
    handleMentionChange,
    handleHashtagChange,
    editorState,
    handlePastedText,
  } = useCaptionInput(props);

  if (!editorState) {
    return null;
  }

  const plugins = props.singleLine
    ? [mentionPlugin, mentionPluginHashtag, singleLinePlugin]
    : [mentionPlugin, mentionPluginHashtag];

  return (
    <div
      className={
        props.singleLine ? editorStyles.single_line_editor : editorStyles.editor
      }
    >
      <Editor
        ref={mentionsRef}
        editorState={editorState}
        onChange={handleCaptionChange}
        plugins={plugins}
        placeholder={props.placeholder}
        handlePastedText={handlePastedText}
      />
      <MentionSuggestions
        onSearchChange={handleMentionChange}
        suggestions={users}
        entryComponent={UserSuggestionMention}
      />
      <MentionHashSuggestions
        onSearchChange={handleHashtagChange}
        suggestions={hashtags}
        entryComponent={HashtagSuggestionMention}
      />
    </div>
  );
};

CaptionInput.propTypes = {
  onCaptionChange: PropTypes.func.isRequired,
  caption: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  singleLine: PropTypes.bool,
};

CaptionInput.defaultProps = {
  placeholder: "",
  singleLine: false,
};

export default CaptionInput;
