import { SET_SIDEBAR_VISIBILY } from "./action";

const initialList = {
  sidebarShow: false,
};

const layoutReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_SIDEBAR_VISIBILY:
      return {
        sidebarShow: action.payload,
      };

    default:
      return state;
  }
};

export default layoutReducer;
